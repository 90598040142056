// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginButton: {
      cursor: 'pointer',
      marginBottom: '10px',
      '& button': {
        cursor: 'pointer',
      },
      '& .facebook-login-button': {
        width: 'auto',
        fontSize: '16px',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px'
      },
      '& i': {
        marginRight: '10px',
        fontSize: '32px',
      }
    },
    googleButton: {
      display: 'flex',
      alignItems: 'center',
      border: 0,
      backgroundColor: '#4285f4',
      color: 'white',
      borderRadius: '2px',
      boxShadow: '0 3px 4px 0 rgba(0,0,0,.25)',
      height: '40px',
      paddingLeft: '50px',
      fontSize: '16px',
      fontWeight: 'bold',
      position: 'relative',
      paddingRight: '8px',
      '& .google-icon-wrapper': {
        position: 'absolute',
        top: '1px',
        left: '1px',
        width: '40px',
        height: '38px',
        borderRadius: '2px',
        backgroundColor: 'white',
        '& img': {
          marginTop: '8px',
          marginLeft: '7px',
          width: '24px'
        }
      }
    }
  }),
);

export default useStyles;
