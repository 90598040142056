// React.
import React, { Fragment, useEffect } from 'react';

// Material UI.
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

// Redux.
import { useDispatch } from "react-redux";
import { getUserInformation } from '../../../actions/authActions/AuthActions';

// Components.
import CheckoutAccordion from '../checkout/CheckoutAccordion';

// Styles.
import useStyles from './CheckoutStyles';

// Translations.
import { useTranslation } from 'react-i18next';


const Checkout = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserInformation());
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <CssBaseline />
      <Container maxWidth="md" className={classes.checkoutWrapper}>
        <Paper elevation={3} square={false} variant="elevation" className={classes.paperWrapper}>
          <Typography gutterBottom variant="h4" component="h1" color="primary">
            { t('checkout.checkout-title') }
          </Typography>
          <CheckoutAccordion />
        </Paper>
      </Container>
    </Fragment>
  )
}

export default Checkout;
