// React.
import React, { useState, Fragment, useEffect } from 'react';
import ShowMoreText from "react-show-more-text";

// Model Types.
import { ItemType } from '../models/ModelTypes';

//  JWT decode token.
import jwtDecode from 'jwt-decode';

// Moment.
import Moment from 'moment';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { 
  updateItem,
  deleteItem,
  updateItemPublished,
  updateItemSinglePrice,
  updateItemAutomaticPrice,
  updateDescriptionSingle,
  updateIndividualItemValues,
  updateIsForSale
} from "../../../actions/itemActions/ItemActions";
import { RootStore } from "../../../Store";

// Material UI.
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import { useStyles, Accordion, AccordionSummary, AccordionDetails } from './ItemSingleEditStyles';
import defaultImage from '../../../images/default-img.jpg';
import axios from 'axios';

// Components.
import NumberFormat from '../utils/NumberFormat';

interface ItemEditProps {
  item: ItemType;
  isExpanded: boolean;
};

type itemInfo = {
  item: ItemType;
  vendorName: string;
  automaticPrice?: boolean;
  price: number;
  additionalPrice?: number;
  quantity: number;
  totalQuantity: number;
  currency: any,
  isForSale:boolean
}

type SingleQuantity = {
  customReference: string;
  quantity: string;
}

type UpdateIndexes = {
  pIndex: number;
  cIndex: number;
  value: number;
}

const ItemSingleEdit = ({ item, isExpanded }: ItemEditProps) => {
  const [t] = useTranslation('global');
  const classes = useStyles();
  const dispatch = useDispatch();
  const decodedToken: any = localStorage.token !== undefined && localStorage.token !== '' ? jwtDecode(localStorage.token) : false;
  var loggedUserRole = '';
  if (localStorage.token !== undefined && localStorage.token !== '') {
    loggedUserRole = decodedToken.auth;
  }

  // Get item label ids.
  var labelIds: any = [];
  for (var i = 0; i < item.labelValues!.length; i++) {
    if (item.labelValues![i]) {
      labelIds.push(item.labelValues![i].id);
    }
  }

  // Local component state.
  const [itemState, setItemState] = useState<ItemType>(() => item);
  const [uniqueItems, setUniqueItems] = useState<ItemType[]>();
  const [fieldError, setFieldError] = useState<boolean | false>(false);
  const [itemLabels, setItemLabels] = React.useState<number[]>(labelIds);
  const [quantitiesAndPrices, setQuantitiesAndPrices] = useState<itemInfo[]>();
  const [editingDetails, setEditingDetails] = useState<boolean | false>(false);
  const [editingPriceQuantity, setEditingPriceQuantity] = useState<Array<boolean>>([]);
  const [cacheQuantities, setCacheQuantities] = useState<itemInfo[]>();
  const [loadingLocal, setLoadingLocal] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [automaticPrices, setAutomaticPrices] = useState<boolean>(item?.automaticPrice!);
  const [adjustQtyValues, setAdjustQtyValues] = useState<Array<SingleQuantity>>([]);
  const [loadingLocalQty, setLoadingLocalQty] = useState(false);
  const [pendingSaveQuantityIndex, setPendingSaveQuantityIndex] = useState<UpdateIndexes>({pIndex: -1, cIndex: -1, value: 0});
  const [isForSale, setIsForSale] = useState(false)

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  // Redux state.
  const reduxItem = useSelector((state: RootStore) => state.item);
  const loading = reduxItem.loading;
  const labelList = reduxItem.itemLabelList;
  const reduxVendors = useSelector((state: RootStore) => state.vendors);
  const { vendors } = reduxVendors;
  // Local state
  const [expanded, setExpanded] = useState<string | false>(false);
  const [uniqueItemsCount,setUniqueItemsCount]=useState(0)

  const getItemsByCode = async (code: string) => {
    const apiURL: string | undefined = process.env.REACT_APP_API_URL;
    const token: string | undefined = localStorage.token;

    const headers: object = {
      ContentType: 'application/json',
      Authorization: 'Bearer ' + token
    }

    const res = await axios.get(`${apiURL}/seller/getAllItems`, {
      params: { code: code },
      headers: headers
    });

    if (res.status === 200) {
      const queryData = res.data.data.content;
      const items: any = [];
      const discounts: any = [];
      for (var i = 0; i < queryData.length; i++) {
        const item = queryData[i];
        items.push({
          id: item.id,
          name: item.name,
          price: item.price,
          commission: item.commission,
          state: item.state,
          code: item.code,
          weightPerUnit: item.weightPerUnit,
          minimumQuantity: item.minimumQuantity,
          costPrice: item.costPrice,
          customPrices: item.customPrices,
          additionalPrice: item.additionalPrice,
          notes: item.notes,
          isDeleted: item.isDeleted,
          itemType: item.itemType,
          currency: item.currency,
          vendor: item.vendor,
          isForSale: item.isForSale,
          totalQuantity: item.totalQuantity,
          warehousesQuantities: item.warehousesQuantities,
          warehousesCustomQuantities: item.warehousesCustomQuantities,
          labelValues: item.labelValues,
          images: item.images,
          discounts: item.discount,
          priceWithTaxes: item.priceWithTaxes,
          creationDate: item.creationDate,
          isPublished: item.isPublished,
          category: item.category,
          automaticPrice: item.automaticPrice
        });

        if (item.discount !== null) {
          discounts.push({
            itemId: item.id,
            percentage: item.discount.pricePercentage,
            quantity: getDiscountQuantity(item)
          });
        }
      }
      setUniqueItems(items);
      setLoadingLocal(false);
    }
  }


  const getDiscountQuantity = (item: ItemType) => {
    var quantity = '0';
    item.warehousesQuantities.forEach((whQuantity) => {
      if (whQuantity.warehouse.isDiscount) {
        quantity = String(whQuantity.quantity);
      }
    });
    return quantity;
  };

  const updateIndividualItemPrice = async (updatedItem: itemInfo) => {
    const baseItem = cacheQuantities?.find(e => e.item.id === updatedItem.item.id);
    if (baseItem !== undefined) {
      // Save the automatic price value.
      if (!automaticPrices) {
        // Save the price value.
        let additionalPrice = (updatedItem.additionalPrice !== undefined) ? updatedItem.additionalPrice : 0;
        dispatch(updateItemSinglePrice(updatedItem.price, additionalPrice, updatedItem.item.id));
      }
    }
  }

  const getVendorNameById = (item: ItemType) => {
    const id = item.vendor?.id ?? 0;
    const vendor = vendors.find(vendor => vendor.id === id);
    if (vendor !== undefined) {
      return vendor?.name;
    } else {
      return item.vendor?.name;
    }
  }

  const getInitialPriceAndQty = (): itemInfo[] => uniqueItems?.map((item: ItemType): itemInfo => {
    const vendorName = getVendorNameById(item) ?? '';
    return {
      vendorName: vendorName,
      item: item,
      automaticPrice: item.automaticPrice,
      price: item.price,
      additionalPrice: item.additionalPrice,
      quantity: item.warehousesCustomQuantities![0]?.quantity!,
      totalQuantity: Number(item.totalQuantity),
      currency: item.currency,
      isForSale:item.isForSale
    }
  }) ?? [];

  const updateQuantitiesAndPrices = (event: React.ChangeEvent<{
    id?: string;
    name: string;
    value: string;
  }>) => {
    const re = /^\d*\.?\d*$/;
    if (re.test(event.target.value) || event.target.value === '') {
      const updated: itemInfo[] = quantitiesAndPrices?.map((item: itemInfo) => {
        if (`${item.item.id}` === event.target.id) {
          const updatedItem = {
            ...item,
            [event.target.name]: event.target.value !== '' ? Number(event.target.value) : 0
          }
          return updatedItem;
        }
        return item;
      }) ?? [];
      setQuantitiesAndPrices(updated);
    }
  }

  const updateAutomaticPrices = (event: React.ChangeEvent<{ checked: boolean; }>) => {
    // Update local state value.
    setAutomaticPrices(event.target.checked);

    // Update BE value.
    dispatch(updateItemAutomaticPrice(event.target.checked, item.id));

    // Reset the editing status.
    setEditingPriceQuantity([]);
  }

  useEffect(() => {
    const formPriceAndQty: itemInfo[] = getInitialPriceAndQty()
    if (formPriceAndQty.length !== 0) {
      setCacheQuantities(formPriceAndQty);
      setQuantitiesAndPrices(formPriceAndQty);
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueItems, vendors])

  useEffect(() => {
    if (isExpanded) {
      setLoadingLocal(true);
      getItemsByCode(itemState?.code ?? '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxItem, isExpanded])

  useEffect(() => {
    // Validations.
    if (itemState.name === '' || itemState.code === '' || itemState.price.toString() === '') {
      setFieldError(true);
    } else {
      setFieldError(false);
    }
  }, [itemState])

  useEffect(() => {
    trackLabelChanges();
    // eslint-disable-next-line
  }, [itemLabels])

  // Auto-save quantity adjustments.
  useEffect(() => {
    if (pendingSaveQuantityIndex.pIndex !== -1 && pendingSaveQuantityIndex.cIndex !== -1) {
      if (quantitiesAndPrices![pendingSaveQuantityIndex.pIndex] !== undefined) {
        const pItem = quantitiesAndPrices![pendingSaveQuantityIndex.pIndex];
        const cElememt = pItem.item.warehousesCustomQuantities![pendingSaveQuantityIndex.cIndex];
        let newData = {
          id: pItem.item.id,
          customReference: cElememt.id?.customReference,
          quantity: pendingSaveQuantityIndex.value
        };

        dispatch(updateIndividualItemValues(newData));
      }

      // Reset the value.
      setPendingSaveQuantityIndex({pIndex: -1, cIndex: -1, value: 0});
    }
    // eslint-disable-next-line
  }, [pendingSaveQuantityIndex])

  useEffect(() => {
    setLoadingLocalQty(false);

    // eslint-disable-next-line
  }, [quantitiesAndPrices]);

  // Handles the field changes to update the state.
  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof itemState;
    var currentValue = event.target.value;

    // Filter incorrect numbers
    if (name === 'price' || name === 'discounts'
      || name === 'weightPerUnit' || name === 'minimumQuantity' || name.includes('warehouse_')) {
      const re = /^[0-9\b]+$/;
      const newValue = String(currentValue);
      if (!re.test(newValue)) {
        currentValue = '';
      }
    }

    setItemState({
      ...itemState,
      [name]: currentValue,
    });
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setItemState({
      ...itemState,
      isPublished: event.target.checked,
    });

    // Save the published status right away.
    savePublishedStatus(`${event.target.checked}`, `${itemState.code}`);
  };

  const handleUploadClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesList = event.target.files as File[] | null;
    const imgs = itemState.images;
    const files = itemState.files ? itemState.files : [];
    for (var i = 0; i < filesList!.length; i++) {
      let tempURL = URL.createObjectURL(filesList![i]);
      const uniqueId = (new Date().getTime()) + i;
      const newImg = {
        id: uniqueId,
        url: tempURL,
        key: tempURL
      };
      imgs?.push(newImg);
      files?.push({ fid: uniqueId, file: filesList![i] });
    }
    setItemState({
      ...itemState,
      images: imgs,
      files: files
    });
  };

  const handleLabelChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setItemLabels(event.target.value as number[]);
  };
 //Handling isForSale Property
 const saveIsForSaleStatus = (status:string,code:string)=>{
  dispatch(updateIsForSale(status, code));
}
useEffect(()=>{
  if(uniqueItemsCount<1){   
    if(uniqueItems!==undefined){
      setIsForSale(uniqueItems[0].isForSale)
      setUniqueItemsCount(uniqueItemsCount+1);
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[uniqueItems])
const handleIsForSaleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setIsForSale(event.target.checked)
  saveIsForSaleStatus(`${event.target.checked}`, `${itemState.code}`); 
}
useEffect(()=>{
  setItemState({
    ...itemState,
    isForSale: isForSale
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[isForSale])

  const handleDeleteImg = (id: number) => {
    const newImages = itemState.images!.filter((image) => image.id !== id)
    const newFiles = itemState.files !== undefined ? itemState.files!.filter((file) => file.fid !== id) : [];
    const deletedImage = itemState.images!.filter((image) => image.id === id);
    if (newImages !== undefined) {
      var dIds = itemState.imagesIdToRemove;
      if (!deletedImage[0].url.includes('blob:')) {
        dIds = (itemState.imagesIdToRemove) ? (dIds + ',' + id) : ('' + id);
      }
      setItemState({
        ...itemState,
        images: newImages,
        files: newFiles,
        imagesIdToRemove: dIds
      });
    }
  };

  const trackLabelChanges = () => {
    let addedLabels = itemLabels.filter((id: number) => !labelIds.includes(id));
    let removedLabels = labelIds.filter((id: number) => !itemLabels.includes(id));

    setItemState({
      ...itemState,
      labelValuesIdsToAdd: addedLabels.toString(),
      labelValuesIdsToRemove: removedLabels.toString()
    });
  };

 
  const editOnClick = () => {
    setEditingDetails(true);
  };

  const editPriceQuantityOnClick = (index: number) => {
    var edittingCopy = [...editingPriceQuantity];
    edittingCopy[index] = true;
    setEditingPriceQuantity(edittingCopy);
  };

  const cancelOnClick = () => {
    setEditingDetails(false);
    // Set user state to original values.
    setQuantitiesAndPrices(getInitialPriceAndQty());
    setItemState(item);
    setIsForSale(uniqueItems? uniqueItems[0].isForSale:false)
  };

  const cancelPriceQuantityOnClick = (index: number) => {
    var edittingCopy = [...editingPriceQuantity];
    edittingCopy[index] = false;
    setEditingPriceQuantity(edittingCopy);

    // Set user state to original values.
    setQuantitiesAndPrices(getInitialPriceAndQty());
  };

  const isOtherQuantityEditing = (currentIndex: number) => {
    var result = false;
    editingPriceQuantity.forEach((value: boolean, i: number) => {
      if (value && currentIndex !== i) result = true;
    });
    return result;
  };

  const handleAdjustQtyOnChange = (event: React.ChangeEvent<{ value: unknown }>, index: number, cReference:string) => {
    var newValue = String(event.target.value);
    const re = /^[0-9\b]+$/;
    if (re.test(newValue) || newValue === '') {
      var copyAdjustQtyValues = [...adjustQtyValues];
      if (newValue !== '0') {
        // Removes leading zeros.
        newValue = String(Number(newValue));
      }
      copyAdjustQtyValues[index] = (newValue !== '') ? 
      { customReference: cReference, quantity: newValue } : 
      { customReference: cReference, quantity: '0' };

      setAdjustQtyValues(copyAdjustQtyValues);
    }
  };

  const adjustItemQuantity = (pIndex: number, cIndex: number, adjustValue: number, type: string, currentQty: number) => {
    setLoadingLocalQty(!loadingLocalQty);
    if (quantitiesAndPrices !== undefined) {
      setLoadingLocal(true);
      // Create a copy from the state.
      const copyQuantitiesAndPrices = [...quantitiesAndPrices];

      // Update the quantity.
      const currentItemInfo = { ...quantitiesAndPrices[pIndex] };
      var newValue = type === 'add' ? Number(currentQty) + Number(adjustValue) : Number(currentQty) - Number(adjustValue);
      if (newValue < 0) {
        newValue = 0;
      }

      if (currentItemInfo?.item?.warehousesCustomQuantities![cIndex]! !== undefined) {
        currentItemInfo.item.warehousesCustomQuantities![cIndex].quantity = newValue;
      }

      copyQuantitiesAndPrices[pIndex] = currentItemInfo;

      // Save the new state.
      setQuantitiesAndPrices(copyQuantitiesAndPrices);

      // Set the initial values for adjust fields.
      const copyAdjustQtyValues = [...adjustQtyValues];
      copyAdjustQtyValues.forEach((singleQty: SingleQuantity, index: number) => {
        if (copyAdjustQtyValues[index]!) copyAdjustQtyValues[index].quantity = '0';
      });
      setAdjustQtyValues(copyAdjustQtyValues);

      // Set the flag to save the changes.
      // Wait a white to make sure state is updated.
      setTimeout(function () {
        setPendingSaveQuantityIndex({ pIndex: pIndex, cIndex: cIndex, value: (newValue - currentQty) });
      }, 500);
    }
  };

  const savePublishedStatus = async (isPublished: string, itemCode: string) => {
    // Save the general item data.
    dispatch(updateItemPublished(isPublished, itemCode));
  };

  const saveOnClick = async () => {
    // Save the general item data.
    dispatch(updateItem(itemState));
    setEditingDetails(false);

    // Clear the images uploaded
    setItemState({
      ...itemState,
      files: [],
    });
  };

  const savePriceQuantityOnClick = async (index: number) => {
    // Then save the unique item info.
    if (quantitiesAndPrices !== undefined) {
      updateIndividualItemPrice(quantitiesAndPrices[index]);
    }

    // Update the editing state.
    var edittingCopy = [...editingPriceQuantity];
    edittingCopy[index] = false;
    setEditingPriceQuantity(edittingCopy);
  };

  useEffect(() => {
    if (reduxItem.item !== undefined) {
      // Update the images object with BE data.
      if (reduxItem.item.id === item.id) {
        setItemState({
          ...itemState,
          images: reduxItem.item.images,
        });
      }
    }
    // eslint-disable-next-line
  }, [reduxItem.item])

  const deleteUserOnClick = () => {
    if (itemState.code) {
      dispatch(deleteItem(itemState.code));
    }

    handleCloseModal();
  };

  const handleChangeAccordion = (panel: string, idAccordion: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    cancelPriceQuantityOnClick(idAccordion);

    // Remove editing status when accordion changes.
    var edittingCopy = [...editingPriceQuantity];
    edittingCopy.forEach((item, index) => {
      edittingCopy[index] = false;
    });
    setEditingPriceQuantity(edittingCopy);
  };

  const getCustomReferencePrice = (referenceName: string, item: ItemType) => {
    const priceObj = item.customPrices?.filter(customPrice => customPrice.customReference === referenceName);
    if (priceObj?.length! > 0) return priceObj![0]?.price;
    return 0;
  };

  const updateSingleDescriptionClick = () => {
    if (itemState?.code) {
      dispatch(updateDescriptionSingle(itemState?.code));
    }
  };

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <div className={classes.leftColumnWrapper}>
            <div className={classes.imgWrapper}>
              <div>
                {(editingDetails && itemState.images![0]) && <DeleteIcon color="secondary" className={classes.deleteIcon} onClick={() => handleDeleteImg(itemState.images![0].id)} />}
                <Avatar variant="rounded" alt="Item image" src={itemState.images![0] ? itemState.images![0].url : defaultImage} className={classes.mainImg} />
              </div>
              <div className={classes.smallImgWraper}>
                { // eslint-disable-next-line
                  itemState.images!.map(image => {
                    if (itemState.images![0].id !== image.id)
                      return (
                        <div className={classes.smallImg} key={image.url}>
                          {editingDetails && <DeleteIcon color="secondary" className={classes.deleteIcon} onClick={() => handleDeleteImg(image.id)} />}
                          <Avatar variant="rounded" alt="Item image" src={image.url} />
                        </div>
                      )
                  }
                  )}
              </div>
              {editingDetails && (
                <div className={classes.editImgWrapper}>
                  <input accept="image/*" id="icon-button-file" type="file" multiple onChange={handleUploadClick} />
                  <label htmlFor="icon-button-file">
                    <Button variant="contained" color="primary" component="span">
                      {t('items.add-image')}
                    </Button>
                  </label>
                </div>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={9}>
          <form>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="body2" color="initial">{t('items.item-details')}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl className={classes.formControl}>
                  <Typography variant="caption" color="initial">{t('items.sku')}</Typography>
                  <Typography variant="caption" color="primary">{itemState.code}</Typography>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Typography variant="caption" color="initial">{t('items.last-update')}</Typography>
                  <Typography variant="caption" color="primary">{Moment(itemState.lastUpdate).format('MM/DD/YYYY - HH:mm')}</Typography>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Typography variant="caption" color="initial">{t('items.name')}</Typography>
                  {editingDetails ?
                    <TextField
                      className={classes.formField}
                      id="i-name" name="name" color="primary" value={itemState.name}
                      onChange={handleChange} error={itemState.name === ''}
                      helperText={itemState.name === '' ? t('users.field-required') : ''}
                      autoFocus
                    /> :
                    <Typography variant="caption" color="primary">{itemState.name}</Typography>
                  }
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Typography variant="caption" color="initial">{t('items.weight')}</Typography>
                  {editingDetails ?
                    <TextField
                      className={classes.formField}
                      id="i-weight-per-unit" name="weightPerUnit" value={itemState.weightPerUnit}
                      type="number" color="primary" onChange={handleChange}
                      onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                    /> :
                    <Typography variant="caption" color="primary"><NumberFormat number={itemState.weightPerUnit} /> g</Typography>
                  }
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Typography variant="caption" color="initial">{t('items.minimum-quantity')}</Typography>
                  {editingDetails ?
                    <TextField
                      className={classes.formField}
                      id="i-minimum-quantity" name="minimumQuantity" value={itemState.minimumQuantity} type="number"
                      color="primary" onChange={handleChange}
                      onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                    /> :
                    <Typography variant="caption" color="primary"><NumberFormat number={itemState.minimumQuantity} /></Typography>
                  }
                </FormControl>
                <FormControl className={`${classes.formControl} ${classes.formControlMultiple}`}>
                  <Typography variant="caption" color="initial">{t('items.labels')}</Typography>
                  {editingDetails ?
                    <Select
                      labelId="mutiple-labels"
                      id="mutiple-labels"
                      multiple
                      value={itemLabels}
                      onChange={handleLabelChange}
                      input={<Input />}
                      name="labelValues"
                    >
                      {
                        labelList?.map(label =>
                          label.labelValues?.map(labelValue =>
                            <MenuItem key={labelValue.id} value={labelValue.id}>
                              {`${label.value}:${labelValue.value}`}
                            </MenuItem>
                          )
                        )
                      }
                    </Select> :
                    <div className="labelsWrapper">
                      {
                        labelList?.map(label =>
                          label.labelValues?.map(labelValue =>
                            <span key={labelValue.id}>
                              {itemLabels.some(id => id === labelValue.id) &&
                                <Chip avatar={<Avatar>{label.value}</Avatar>} label={labelValue.value} />}
                            </span>
                          )
                        )
                      }
                    </div>
                  }
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Typography variant="caption" color="initial">{t('items.notes')}</Typography>
                  <Typography className="extra-width" variant="caption" color="primary">
                    <ShowMoreText
                      lines={3}
                      more={t('items.see-more')}
                      less={false}
                      expanded={false}
                      truncatedEndingComponent={"... "}
                    >
                      <div dangerouslySetInnerHTML={{ __html: item.notes }}></div>
                    </ShowMoreText>
                  </Typography>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Typography variant="caption" color="initial">{t('items.state')}</Typography>
                  {editingDetails ?
                    <TextField
                      className={classes.formField}
                      id="i-state" name="state" value={itemState.state}
                      color="primary" onChange={handleChange}
                    /> :
                    <Typography variant="caption" color="primary">{itemState.state}</Typography>
                  }
                </FormControl>
                <FormControl className={classes.formControl}>
                  <FormControlLabel control={
                    <Checkbox 
                      checked={itemState.isPublished} 
                      onChange={handleChangeCheckbox} 
                      name="isPublished" color="primary"
                      disabled={(!loggedUserRole.includes('ROLE_ADMIN') && !loggedUserRole.includes('ROLE_SUPER_SELLER')) } 
                    />} 
                  label={t('items.published')} />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <FormControlLabel control={
                    <Checkbox 
                      checked={isForSale} 
                      onChange={handleIsForSaleChange} 
                      name="isForSale" color="primary"
                      disabled={(!loggedUserRole.includes('ROLE_ADMIN'))} 
                    />} 
                  label={t('items.is-for-sale')} />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.formControl}>
                  <Typography variant="body2" color="initial">
                    {t('items.vendor-stock-and-price')}
                  </Typography>
                </div>
                <div className={classes.formControl}>
                  {decodedToken.auth.includes('ROLE_SELLER_ASSOCIATE') &&
                    <FormControlLabel
                      className={classes.formField}
                      control={
                        <Switch
                          checked={automaticPrices}
                          name="automaticPrice"
                          id={`${item.id}`}
                          onChange={(e) => updateAutomaticPrices(e)}
                        />
                      }
                      label={t('items.automatic-price')}
                    />
                  }
                </div>
                <div className={classes.vendorsWrapper}>
                  {quantitiesAndPrices ? quantitiesAndPrices.map((itemInfo: itemInfo, pIndex: number) => {
                    if ((itemInfo.item.warehousesCustomQuantities?.length! > 0)) {
                      return <Accordion key={pIndex} className="vendor-item" expanded={expanded === `panel-u-${pIndex}`} onChange={handleChangeAccordion(`panel-u-${pIndex}`, pIndex as number)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography className={classes.vendorName}><span className={classes.vendorText}>{itemInfo.vendorName}</span> <span>ID: {itemInfo.item.vendor?.id}</span></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid item xs={12} sm={12} md={12} key={pIndex}>
                            <div className={`${classes.formControl} ${classes.formControlPrice}`}>
                              <Typography variant="caption" color="initial">{t('items.normal-price')}:</Typography>
                              {(editingPriceQuantity[pIndex]) ?
                                <Fragment>
                                  <TextField
                                    className={classes.formField} type="text" name="price" color="primary"
                                    id={`${itemInfo.item.id}`}
                                    onChange={(e) => updateQuantitiesAndPrices(e)}
                                    value={itemInfo.price}
                                    onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                                  />
                                </Fragment> :
                                <Typography className={classes.formField} variant="caption" color="initial">{itemInfo.currency.symbol}<NumberFormat number={itemInfo.price} /></Typography>
                              }
                              <Typography variant="caption" color="initial">
                                {`${t('items.foil-price')}:`}
                              </Typography>
                              {(editingPriceQuantity[pIndex]) ?
                                <Fragment>
                                  <TextField
                                    className={classes.formField} type="text" name="additionalPrice" color="primary"
                                    id={`${itemInfo.item.id}`}
                                    onChange={(e) => updateQuantitiesAndPrices(e)}
                                    value={itemInfo.additionalPrice}
                                    onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                                    disabled={itemInfo.automaticPrice}
                                  />
                                </Fragment> :
                                <Typography className={classes.formField} variant="caption" color="initial">{itemInfo.currency.symbol}<NumberFormat number={itemInfo.additionalPrice!} /></Typography>
                              }
                              <Typography variant="caption" color="initial">{`Cantidad Total:`}</Typography>
                              <Typography variant="caption" color="initial">{loadingLocal ? itemInfo.totalQuantity + '...' : itemInfo.totalQuantity}</Typography>
                            </div>
                            <div className={`${classes.formControl} ${classes.formControlPrice}`}>
                              {itemInfo.item.warehousesCustomQuantities?.map((customQuantity: any, cIndex: number) => (
                                <Fragment key={cIndex}>
                                  <Typography variant="caption" color="initial">{`${t('items.custom-reference')}:`}</Typography>
                                  <Typography variant="body2">
                                    {customQuantity.id?.customReference}
                                  </Typography>
                                  <Fragment>
                                    <Typography variant="caption" color="initial">{`${t('items.price')}:`}</Typography>
                                    <Typography className={classes.formField} variant="caption" color="initial">
                                      {itemInfo.currency.symbol}
                                      <NumberFormat number={getCustomReferencePrice(customQuantity.id?.customReference, itemInfo?.item)} />
                                    </Typography>
                                  </Fragment>
                                  <Typography variant="caption" color="initial">{`Cantidad:`}</Typography>
                                  <Typography variant="caption" color="initial">{customQuantity.quantity}</Typography>
                                  {(decodedToken.auth.includes('ROLE_ADMIN')) &&
                                  <Fragment>
                                    <FormControl className={`${classes.formControl} ${classes.formControlAdjustQty}`}>
                                      <Typography variant="caption" color="initial">{t('items.adjust-qty')}</Typography>
                                      <TextField
                                        type="text"
                                        id={`${itemInfo.item.id}-qty`}
                                        name="adjustQuantity"
                                        color="primary"
                                        onChange={(e) => handleAdjustQtyOnChange(e, cIndex, customQuantity.id?.customReference)}
                                        value={adjustQtyValues[cIndex] ? adjustQtyValues[cIndex].quantity : '0'}
                                        onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                                      />
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        startIcon={<AddCircleOutlineIcon />}
                                        disabled={String(adjustQtyValues[cIndex]?.quantity!) === '0' || adjustQtyValues[cIndex] === undefined}
                                        onClick={() => adjustItemQuantity(pIndex, cIndex, Number(adjustQtyValues[cIndex].quantity), 'add', customQuantity.quantity)}>
                                        {t('items.add')}
                                      </Button>
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        startIcon={<RemoveCircleOutlineIcon />}
                                        disabled={String(adjustQtyValues[cIndex]?.quantity!) === '0' || adjustQtyValues[cIndex] === undefined}
                                        onClick={() => adjustItemQuantity(pIndex, cIndex, Number(adjustQtyValues[cIndex].quantity), 'remove', customQuantity.quantity)}>
                                        {t('items.remove')}
                                      </Button>
                                    </FormControl>
                                  </Fragment>
                                  }

                                  {(cIndex < itemInfo?.item?.warehousesCustomQuantities?.length! - 1) &&
                                    <Divider />
                                  }
                                </Fragment>
                              ))}
                            </div>
                            <div className={`${classes.actionsWrapper} ${classes.actionsWrapperPrice}`}>
                              {(editingPriceQuantity[pIndex]) && (
                                <Fragment>
                                  {loading === 'ITEM_UPDATE' && <CircularProgress />}
                                  <Button variant="text" color="primary" size="small" onClick={() => savePriceQuantityOnClick(pIndex)} disabled={fieldError}>
                                    {t('items.save')}
                                  </Button>
                                </Fragment>
                              )}
                              {(editingPriceQuantity[pIndex]) && (
                                <Button variant="contained" color="secondary" size="small" onClick={() => cancelPriceQuantityOnClick(pIndex)}>
                                  {t('items.cancel')}
                                </Button>
                              )}
                            </div>
                            {(!editingPriceQuantity[pIndex] && decodedToken.auth.includes('ROLE_SELLER_ASSOCIATE')) && (
                              <div className={`${classes.actionsWrapper} ${classes.actionsWrapperPrice}`}>
                                <Button 
                                  variant="outlined" color="primary" 
                                  size="small" onClick={() => editPriceQuantityOnClick(pIndex)} 
                                  disabled={isOtherQuantityEditing(pIndex) || automaticPrices}
                                >
                                  {t('items.edit-item-price')}
                                </Button>
                              </div>
                            )}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    } else {
                      return null;
                    }
                  }) : <CircularProgress className={classes.vendorLoader}/>}
                </div>
              </Grid>
            </Grid>
            <FormControl className={classes.formControl}>
              {editingDetails && (
                <div className={classes.actionsWrapper}>
                  {loading === 'ITEM_UPDATE' && <CircularProgress />}
                  <Button variant="text" color="primary" onClick={saveOnClick} disabled={fieldError}>
                    {t('items.save')}
                  </Button>
                  <Button variant="contained" color="secondary" onClick={cancelOnClick}>
                    {t('items.cancel')}
                  </Button>
                  <Button variant="contained" color="primary" disabled>
                    {t('items.edit-item')}
                  </Button>
                </div>
              )}
              {(!editingDetails && decodedToken.auth.includes('ROLE_ADMIN')) && (
                <div className={classes.actionsWrapper}>
                  <Button variant="contained" color="primary" onClick={editOnClick}>
                    {t('items.edit-item')}
                  </Button>
                  <Button variant="outlined" color="primary" onClick={updateSingleDescriptionClick} disabled={loading !== ''}>
                    {t('items.edit-single-desc')}
                  </Button>
                  {(loggedUserRole.includes('ROLE_ADMIN') || loggedUserRole.includes('ROLE_SUPER_SELLER')) &&
                    <Button variant="contained" color="secondary" startIcon={<DeleteIcon />} onClick={handleOpenModal}>
                      {t('items.delete')}
                    </Button>
                  }
                </div>
              )}
            </FormControl>
          </form>
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="Confirm Modal"
      >
        <Paper elevation={3} className={classes.modalPaper}>
          <IconButton className={classes.closeModal} aria-label="close modal" component="span" onClick={() => handleCloseModal()}>
            <CloseIcon />
          </IconButton>
          <Typography>{t('items.delete-confirm-text', { 'itemName': itemState.name })}</Typography>
          <Button variant="contained" color="primary" onClick={() => handleCloseModal()}> {t('items.cancel')} </Button>
          <Button variant="contained" color="secondary" onClick={() => deleteUserOnClick()}> {t('items.delete')} </Button>
        </Paper>
      </Modal>
    </Fragment>
  )
}

export default ItemSingleEdit;
