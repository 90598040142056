// React.
import React, { useState, useEffect } from 'react';

// Material UI.
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import { MenuItem } from '@material-ui/core';

// Components.
import TextMaskPhone from '../utils/TextMaskPhone';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { addVendor } from '../../../actions/vendorActions/VendorActions';
import { getItemCategory } from '../../../actions/categoryActions/categoryActions';
import { RootStore } from "../../../Store";

// Global Types.
import { VendorTypeObject } from '../models/ModelTypes';
import { AddressType } from '../models/ModelTypes';

// Translations.
import { useTranslation } from 'react-i18next';


// Styles.
import useStyles from './AddVendorFormStyles';

type SwitchCategoryType = {
  enabled: boolean,
  name: string
};

const AddVendorForm = () => {

  const [t] = useTranslation('global');
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getItemCategory());
    // eslint-disable-next-line
  }, []);

  const defaultVendorValues:VendorTypeObject = {
    adminOnly: false,
    vendorCode: '',
    name: '',
    phoneNumber: '',
    type: '',
    vendorCategories: [],
    address: {
      canton: '',
      district: '',
      fullAddress: '',
      phoneNumber: '',
      postalCode: '',
      province: ''
    }
  };

   // Redux stage.
  const reduxVendorState = useSelector((state: RootStore) => state.vendors);
  const reduxItemState = useSelector((state: RootStore) => state.itemCategories);
  const loading = reduxVendorState.loading;
  const category = reduxItemState.categories;

  //Local component state
  const [vendorState, setVendorState] = useState<VendorTypeObject>(defaultVendorValues);
  const [vendorStateAddress, setVendorStateAddress] = useState<AddressType>(defaultVendorValues.address);
  const [errorArray, setErrorArray] = useState<Array<string>>([]);
  const [switchState, setSwitchState] = useState<Array<SwitchCategoryType>>([]);

  var names: string[] = [];
  category.map((item) =>(names.push(item.name)));


  const setSwitchStateChange = (event: any, index: number, name: string) => {
    var newArray: Array<SwitchCategoryType> = switchState;
    newArray[index] = { enabled: event.target.checked, name: name };
    setSwitchState(newArray);

    // Validate and add errors.
    const errors = [...errorArray];
    if (checkIfEnabledCategory(newArray)) {
      errors.push('vendorCategories');
      setErrorArray(errors);
    } else {
      const newErrors = errors.filter(function (value) {
        return value !== 'vendorCategories';
      });
      setErrorArray(newErrors);
    }
  };

  const checkIfEnabledCategory = (categories: Array<SwitchCategoryType> | undefined = undefined) => {
    var hasError = true;
    const list = categories !== undefined ? categories : switchState;
    list.forEach((item: SwitchCategoryType) => {
      if (item.enabled) return hasError = false;
    });
    return hasError;
  };

  const validateFields = () => {
    var result = true;
    const errors = [...errorArray];
    if (vendorState.name === '') {
      errors.push('name');
      result = false;
    }
    const isPhoneFormat = /^[0-9\s]+$/.test(vendorState.phoneNumber);
    if (vendorState.phoneNumber === '' || !isPhoneFormat) {
      errors.push('phoneNumber');
      result = false;
    }
    if (vendorState.vendorCode === '') {
      errors.push('vendorCode');
      result = false;
    }
    if (vendorState.type === '') {
      errors.push('type');
      result = false;
    }
    if (checkIfEnabledCategory()) {
      errors.push('vendorCategories');
      result = false;
    }
    if (vendorStateAddress.canton === '') {
      errors.push('canton');
      result = false;
    }
    if (vendorStateAddress.district === '') {
      errors.push('district');
      result = false;
    }
    if (vendorStateAddress.fullAddress === '') {
      errors.push('fullAddress');
      result = false;
    }
    if (vendorStateAddress.postalCode === '') {
      errors.push('postalCode');
      result = false;
    }
    if (vendorStateAddress.phoneNumber !== '') {
      const isPhoneFormat = /^[0-9\s]+$/.test(String(vendorStateAddress.phoneNumber));
      if (!isPhoneFormat) {
        errors.push('addressPhoneNumber');
        result = false;
      }
    }
    if (vendorStateAddress.province=== '') {
      errors.push('province');
      result = false;
    }

    setErrorArray(errors);

    return result;
  };

  const getCategoryValues = () => {
    var categories:Array<string> = [];
    switchState.forEach((item: SwitchCategoryType) => {
      if (item.enabled) {
        categories.push(item.name);
      }
    });
    return categories;
  };

  const saveOnClick = () => {
    // Save the vendor on the BE.
    const validation = validateFields();
    if (errorArray.length === 0 && validation) {
      let requestObject = {
        name: vendorState.name,
        phoneNumber: vendorState.phoneNumber,
        vendorCode: vendorState.vendorCode,
        adminOnly: vendorState.adminOnly,
        type: vendorState.type,
        vendorCategories: getCategoryValues(),
        address: {
          canton: vendorStateAddress.canton,
          district: vendorStateAddress.district,
          province: vendorStateAddress.province,
          phoneNumber: vendorStateAddress.phoneNumber,
          postalCode: vendorStateAddress.postalCode,
          fullAddress: vendorStateAddress.fullAddress,
        }
      };
      dispatch(addVendor(requestObject));
      handleClear();
    }
  }

  const clearSwitchState = () => {
    var categories: Array<SwitchCategoryType> = switchState;
    categories.forEach((item: SwitchCategoryType) => {
      item.enabled = false;
    });
    setSwitchState(categories);
  };

  const handleClear = () => {
    setVendorState(defaultVendorValues);
    setVendorStateAddress(defaultVendorValues.address);
    clearSwitchState();
  }

  const handleChangeValidation = (inputName: string) => {
    var result = false;
    errorArray.forEach((key) => {
      if (key === inputName) {
        result = true;
      }
    });

    return result;
  };
  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof vendorState;
    var currentValue = event.target.value;

    setVendorState({
      ...vendorState,
      [name]: currentValue
    });

    // Validate and add errors.
    const errors = [...errorArray];
    const nameString = '' + [name]
    if (currentValue === '') {
      errors.push(nameString);
      setErrorArray(errors);
    } else {
      const newErrors = errors.filter(function(value) {
        return value !== nameString;
      });
      setErrorArray(newErrors);
    }
  };
  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
      setVendorState({
        ...vendorState,
        adminOnly: event.target.checked
      });
  }
  const handleChangeAddress = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof vendorStateAddress;
    var currentValue = event.target.value;

    setVendorStateAddress({
      ...vendorStateAddress,
      [name]: currentValue
    });

    // Validate and add errors.
    const errors = [...errorArray];
    const nameString = '' + [name]
    if (currentValue === '' && nameString !== 'phoneNumber') {
      errors.push(nameString);
      setErrorArray(errors);
    } else {
      let compareName = nameString === 'phoneNumber' ? 'addressPhoneNumber' : nameString;
      const newErrors = errors.filter(function(value) {
        return value !== compareName;
      });
      setErrorArray(newErrors);
    }
  };

  return (
    <form noValidate autoComplete="off" className="add-form">
      <CssBaseline />
      <div>
        <TextField
          id="vendor-name" label={ t('vendors.vendor-name') }
          name="name"
          color="primary"
          value={ vendorState.name }
          fullWidth
          autoFocus
          onChange={ handleChange }
          error={ handleChangeValidation('name') }
          helperText={handleChangeValidation('name') ? t('vendors.field-required'): ''}
        />
        <FormControl className={`${classes.phoneField} ${handleChangeValidation('phoneNumber') ? 'error' : ''}`}>
          <InputLabel htmlFor="vendor-phoneNumber">{t('vendors.vendor-phone-number')}</InputLabel>
          <Input
            id="vendor-phoneNumber"
            name="phoneNumber"
            value={vendorState.phoneNumber}
            onChange={handleChange}
            inputComponent={TextMaskPhone as any}
            color="primary"
            fullWidth
            error={handleChangeValidation('phoneNumber')}
          />
          {handleChangeValidation('phoneNumber') &&
            <Typography className={classes.inputError}>{t('vendors.phone-error')}</Typography>
          }
        </FormControl>
        <TextField
          id="vendor-vendorCode"
          name="vendorCode"
          label={ t('vendors.vendor-code') }
          value={ vendorState.vendorCode }
          color="primary"
          fullWidth
          inputProps={{ maxLength: 5 }}
          onChange={ handleChange }
          error={ handleChangeValidation('vendorCode') }
          helperText={handleChangeValidation('vendorCode') ? t('vendors.field-required'): ''}
        />
        <FormControl fullWidth>
          <InputLabel 
            variant="standard" 
            htmlFor="uncontrolled-native"
            >
            {t('vendors.vendor-type')}
          </InputLabel>
          <Select
          className={classes.selectType}
          id="vendor-type"
          name="type"
          value={vendorState.type}
          onChange={ handleChange }
          error={ handleChangeValidation('type') }
          label={t('vendors.vendor-type')}
          >
            <MenuItem value="Tienda">Tienda</MenuItem>
            <MenuItem value="Asociado">Asociado</MenuItem>
          </Select> 
          {handleChangeValidation('type') &&
            <Typography className={classes.inputError}>{t('vendors.field-required')}</Typography>
          }
        </FormControl>
        <FormControl component="fieldset">
          <FormLabel component="legend">{t('vendors.items-type')}</FormLabel>
          <FormHelperText>{t('vendors.items-type-descrip') }</FormHelperText>
          <FormGroup>
            {names.map((item, index) => (
              <FormControlLabel key={index}
                control={
                <Switch 
                  checked={(switchState[index]?.enabled !== undefined ? switchState[index]?.enabled : false)} 
                  onChange={(e) => setSwitchStateChange(e, index, item)} 
                  name={item}
                />}
                label={item}
              />
            ))}
          </FormGroup>
          {handleChangeValidation('vendorCategories') &&
            <Typography className={classes.inputError}>{t('vendors.field-required-select')}</Typography>
          }
        </FormControl>
        <Typography color="inherit" align="inherit">
          {t('vendors.address-title')}:
        </Typography>
        <div className={classes.formAddress}>
          <TextField
            id="vendor-province"
            name="province"
            label={ t('vendors.vendor-province') }
            value={ vendorStateAddress.province }
            color="primary"
            fullWidth
            onChange={ handleChangeAddress }
            error={ handleChangeValidation('province') }
            helperText={handleChangeValidation('province') ? t('vendors.field-required'): ''}
          />
          <TextField
            id="vendor-canton"
            name="canton"
            label={ t('vendors.vendor-canton') }
            value={ vendorStateAddress.canton }
            color="primary"
            fullWidth
            onChange={ handleChangeAddress }
            error={ handleChangeValidation('canton') }
            helperText={handleChangeValidation('canton') ? t('vendors.field-required'): ''}
          />
          <TextField
            id="vendor-district"
            name="district"
            label={ t('vendors.vendor-district') }
            value={ vendorStateAddress.district }
            color="primary"
            fullWidth
            onChange={ handleChangeAddress }
            error={ handleChangeValidation('district') }
            helperText={handleChangeValidation('district') ? t('vendors.field-required'): ''}
          />
          <TextField
            id="vendor-fullAddress"
            multiline
            maxRows={8}
            name="fullAddress"
            label={ t('vendors.vendor-full-address') }
            value={ vendorStateAddress.fullAddress }
            color="primary"
            fullWidth
            onChange={ handleChangeAddress }
            error={ handleChangeValidation('fullAddress') }
            helperText={handleChangeValidation('fullAddress') ? t('vendors.field-required'): ''}
          />
          <TextField
            id="vendor-postalCode"
            name="postalCode"
            label={ t('vendors.vendor-postal-code') }
            value={ vendorStateAddress.postalCode}
            color="primary"
            fullWidth
            inputProps={{ maxLength: 5 }}
            onChange={ handleChangeAddress }
            error={ handleChangeValidation('postalCode') }
            helperText={handleChangeValidation('postalCode') ? t('vendors.field-required'): ''}
          />
          <FormControl className={classes.phoneField}>
            <InputLabel htmlFor="vendor-phoneNumberAd">{t('vendors.vendor-phone-number-address')}</InputLabel>
            <Input
              id="vendor-phoneNumberAd"
              name="phoneNumber"
              value={vendorStateAddress.phoneNumber}
              onChange={handleChangeAddress}
              inputComponent={TextMaskPhone as any}
              error={handleChangeValidation('addressPhoneNumber')}
              color="primary"
              fullWidth
            />
            {handleChangeValidation('addressPhoneNumber') &&
              <Typography className={classes.inputError}>{t('vendors.phone-error-format')}</Typography>
            }
          </FormControl>
        </div>
        <FormGroup>
          <FormControlLabel control={
              <Checkbox
                id='vendor-adminOnly'
                name='adminOnly'
                value={vendorState.adminOnly}
                onChange={handleChangeCheckBox}
              />
            }
            label={t('vendors.checkbox-admin-only')}
          />
        </FormGroup>
        <div className="form-controls">
          { (loading === 'ADD_VENDOR') && <CircularProgress /> }
          <Button variant="outlined" color="secondary" onClick={ handleClear } disabled={loading !== ''}> { t('items.clear') } </Button>
          <Button variant="contained" color="primary" onClick={ saveOnClick } disabled={ errorArray.length !== 0 || loading !== '' }> { t('items.save') } </Button>
        </div>
      </div>
    </form>
  )
}
export default AddVendorForm;
