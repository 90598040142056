import {
	EVENTS_LOADING,
	EVENTS_LIST,
	EVENTS_FAIL,
	EventDispatchType,
	EVENTS_UPDATEFILTER,
	EVENTS_UPDATEPAGINATION,
	EVENT_DETAIL,
	EVENT_PUT,
	EVENT_PLAYERS_LIST,
	EVENT_REGISTER_PLAYERS,
	EVENT_UPDATE_PLAYERS,
	EVENT_REGISTER_ANONYMOUS_PLAYERS,
	EventDatailResponse,
	EventInformationType,
	PlayerType,
    EventPlayerList,
    EventList,
	EVENT_CURRENT_ORDER_PLAYER,
	EventUpdateCurrentOrderPlayer,
	EventOrder,
	EVENT_GET_PLAYER_ORDER,
	EVENT_UPDATE_ORDER_ITEMS
} from "../actions/eventActions/eventActionTypes";

// Default state interface.
interface DefaultStateI {
	loading: string;
	eventList?: EventList;
	filter?: {};
	eventDetail?: EventDatailResponse;
	actionStatus?: any;
	event?: EventInformationType;
	eventPlayers?: EventPlayerList;
	playerUpdated?: PlayerType;
	currentPlayerOrderId?: EventUpdateCurrentOrderPlayer;
	currentPlayerOrder?:EventOrder;
}

const defaultState: DefaultStateI = {
	loading: ""
};

const EventsReducer = (state: DefaultStateI = defaultState, action: EventDispatchType) : DefaultStateI => {
	switch (action.type) {
		case EVENTS_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case EVENTS_LIST:
			return {
				...state,
				loading: "",
				eventList: action.payload,
			};
		case EVENT_DETAIL:
			return {
				...state,
				loading: "",
				eventDetail: action.payload,
			};
		case EVENT_PUT:
			return {
				...state,
				loading: "",
				event: action.payload.event,
				actionStatus: { message: action.payload.message, status: "success" },
			};
		case EVENT_PLAYERS_LIST:
			return {
				...state,
				loading: "",
				eventPlayers: action.payload,
			};
		case EVENT_REGISTER_PLAYERS:
			return {
				...state,
				loading: "",
				actionStatus: { message: action.payload, status: "success" },
			};
		case EVENT_UPDATE_PLAYERS:
			return {
				...state,
				loading: "",
				playerUpdated: action.payload.player,
				actionStatus: { message: action.payload.message, status: "success" },
			};
		case EVENTS_FAIL:
			return {
				...state,
				loading: "error",
				actionStatus: { message: action.payload.message, status: "error" },
			};
		case EVENTS_UPDATEFILTER:
			return {
				...state,
				loading:"",
				filter: action.payload,
			};
		case EVENTS_UPDATEPAGINATION:
			return {
				...state,
				loading:"",
				filter: action.payload,
			};
		case EVENT_REGISTER_ANONYMOUS_PLAYERS:
			return {
				...state,
				loading: "",
				actionStatus: { message: action.payload.message, status: "success" },
			};
		case EVENT_CURRENT_ORDER_PLAYER:
			return {
				...state,
				loading:"",
				currentPlayerOrderId: action.payload
			}
		case EVENT_GET_PLAYER_ORDER:
			return{
				...state,
				loading:"",
				currentPlayerOrder: action.payload
			}	
		case EVENT_UPDATE_ORDER_ITEMS:
			return{
				...state,
				loading:"",
				actionStatus:{ message: action.payload, status:"success"}
			}			
		default:
			return state;
	}
};

export default EventsReducer;
