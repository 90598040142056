// React,
import React, {Fragment, useEffect} from 'react';
import DocumentMeta from 'react-document-meta';

// Material UI.
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

// Redux
import { useSelector, useDispatch } from "react-redux";
import {RootStore} from "../../../Store";
import { getWishlist } from "../../../actions/wishlistActions/wishlistActions";

// Components.
import ItemWishList from '../items/ItemWishList';
import ItemSingleWishList from '../items/ItemSingleWishList';

// Styles.
import useStyles from './CartStyles';

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

// Translations.
import { useTranslation } from 'react-i18next';

const Wishlist = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();

  // Page metatags info.
  const meta = getProjectConfig().METATAGS.WISHLIST;

  // Redux state.
  const wishlist = useSelector((state: RootStore) => state.wishlist);

  useEffect( () => {
      dispatch(getWishlist());
     // eslint-disable-next-line 
  }, [wishlist.actionStatus]);

  return (
    <DocumentMeta {...meta}>
      <CssBaseline />
      <Container maxWidth="md" className={classes.cartWrapper}>
        <Grid container spacing={3} className={`${classes.cartWrapper} ${(wishlist.data?.length === 0) ? 'no-items' : ''}`}>
          <Grid item xs={12} sm={12}>
            <Paper elevation={3} square={false} variant="elevation" className={classes.paperWrapper}>
            <Typography gutterBottom variant="h4" component="h1" color="primary">
                {t('wishlist.title')}
              </Typography>
              {(wishlist.data?.length === 0) && <Typography>{t('wishlist.wishlist-empty')}</Typography>}
              <div>
                { 
                  wishlist.data?.length > 0 &&
                  (wishlist.data?.map((item: any) =>
                    <Fragment>
                      {(item.customReference !== "NA") ?
                        <ItemSingleWishList
                          key={item.itemCode}
                          wishlistItem={item}
                          customReference={item.customReference}
                        /> :
                        <ItemWishList
                          key={item.itemCode}
                          wishlistItem={item}
                          customReference={item.customReference}
                        />
                      }
                    </Fragment>
                  ))
                }
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </DocumentMeta>
  )
}

export default Wishlist;
