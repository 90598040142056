// Material UI.
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      display: 'block',
      '& > *': {
        display: 'block',
      }
    },
    formControlAddress: {
      margin: theme.spacing(0),
      '& .MuiFormLabel-root': {
        color: grey[500]
      }
    },
    addressWrapper: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      },
    },
    addressGroup: {
      borderRadius: '4px',
      border: '1px solid',
      borderColor: grey[400],
      padding: theme.spacing(2, 1),
      width: '48%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    addressLabel: {
      position: 'absolute',
      top: '-9px',
      background: 'white',
      padding: theme.spacing(0, 1)
    },
    userImg: {
      width: '100%',
      height: '168px',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '126px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '102px',
        height: '102px',
        marginBottom: 0,
      },
    },
    imgWrapper: {
      position: 'relative',
    },
    editImgWrapper: {
      position: 'absolute',
      top: 'calc(50% - 22px)',
      left: 'calc(50% - 22px)',
      '& #icon-button-file': {
        display: 'none'
      }
    },
    leftColumnWrapper: {
      textAlign: 'center',
      paddingTop: '50px',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 0,
      },
    },
    formField: {
      width: '100%',
      '& .Mui-disabled:before': {
        borderBottom: 0
      },
      '&.Mui-disabled:before': {
        borderBottom: 0
      },
      '& svg': {
        display: 'none'
      },
      '& > div': {
        width: '100%',
      },
    },
    noAddress: {
      margin: theme.spacing(2, 1)
    },
    addAddress: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      margin: theme.spacing(3, 1)
    },
    actionsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      '& button': {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          marginLeft: theme.spacing(1),
        },
      }
    },
    modalPaper: {
      padding: theme.spacing(6, 4),
      position: 'absolute',
      width: '400px',
      height: '200px',
      top: 'calc(50% - 100px)',
      left: 'calc(50% - 200px)',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(6, 2),
        width: '90%',
        left: '5%'
      },
      '& .MuiTypography-root': {
        marginBottom: theme.spacing(2),
      },
      '& .MuiButtonBase-root': {
        marginLeft: theme.spacing(2),
      }
    },
    closeModal: {
      position: 'absolute',
      right: '0',
      top: '0'
    }
  }),
);

export default useStyles;