// React.
import React from 'react';

// Translations.
import { useTranslation } from 'react-i18next'

const LoginFormText = () => {
  const [t] = useTranslation('global');

  return (
    <div>
      { t('authentication.by-continuing') }<br /> 
      <a href="/use-terms">{ t('authentication.terms-and-condition')}</a>
    </div>
  )
}

export default LoginFormText;
