// React.
import React from 'react';
import {useParams} from "react-router-dom";

// Material UI.
import { Container, Grid, Paper } from "@material-ui/core";

// Components.
import PrizesTop from '../prize/PrizesTop';
import TotalPrizes from '../prize/TotalPrizes';
import PrizeTable from '../prize/PrizeTable'; 
import AddParticipationPrize from '../prize/AddParticipationPrize';

type ParamsType = {
  eventId: string;
};

const PrizePoolCalculator = () => {
  // Page metatags info.
  const { eventId } = useParams<ParamsType>();
  
  return (
    <div>
      <Container style={{ marginBottom: '32px'}} maxWidth="lg">
        <Paper elevation={3} square={false} variant="elevation">
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <PrizesTop eventId={eventId}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <TotalPrizes/>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <AddParticipationPrize />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <PrizeTable eventId={eventId}/>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  )
}

export default PrizePoolCalculator;