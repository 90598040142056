// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkoutWrapper: {
      paddingTop: theme.spacing(4),
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(12),
      },
      '& .MuiGrid-container': {
        marginBottom: theme.spacing(3),
        textAlign: 'left'
      },
      '& strong': {
        fontFamily: 'VisbyCF-Bold'
      },
    },
    withBorders: {
      borderRadius: '6px',
      border: `1px solid ${theme.palette.primary.main}`,
      margin: '0 10px 28px',
      width: 'calc(100% - 20px)',
      '& a': {
        color: theme.palette.primary.main
      }
    },
    paperWrapper: {
      padding: theme.spacing(2),
      minHeight: '250px',
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(2),
      },
      '& .MuiButtonBase-root': {
        marginLeft: theme.spacing(2),
      }
    },
    detailsWrapper: {
      textAlign: 'left',
      padding: theme.spacing(2),
      borderTop: '1px solid',
      borderColor: process.env.REACT_APP_THEME_COLOR_EXTRA_LIGHT_GRAY,
      marginBottom: theme.spacing(2),
    },
    bottomBox: {
      padding: theme.spacing(2, 0),
    },
    divider: {
      marginTop: '30px'
    },
    centered: {
      display: 'flex',
      alignItems: 'center'
    }
  }),
);

export default useStyles;
