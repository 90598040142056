// React.
import React, {useEffect, useState, Fragment} from 'react';

// Material UI.
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';

// Components.
import NumberFormat from '../../admin/utils/NumberFormat';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import { addItemToCart, deleteItemFromCart, deleteItemToCartLocal, addItemToCartLocal } from "../../../actions/cartActions/CartActions";

// Styles.
import useStyles from './ItemCartStyles';
import defaultImage from '../../../images/default-img.jpg'; 

// Translations.
import { useTranslation } from 'react-i18next';

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

interface ItemCartProps {
  cartItem: any;
  customReference: string;
};
const ItemCart = ({ cartItem, customReference } : ItemCartProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();

  const utilityState = useSelector((state: RootStore) => state.utility);
  const dolarPrice = utilityState.dolarPrice;

  // Validate if the item is still available.
  useEffect(() => {
    if (getSingleQuantity() < 1) {
      deleteCartItem(cartItem.item.itemId, '0', customReference);
    }

  // eslint-disable-next-line
  }, [])

  const getNormalQuantity = () => {
    return cartItem.quantity;
  };

  const [quantity, setQuantity] = useState(getNormalQuantity());
  const [deleteQuantity, setDeleteQuantity] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setQuantity(getNormalQuantity());

  // eslint-disable-next-line
  }, [cartItem])

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = (deleteQty:number) => {
    setOpenModal(true);
    setDeleteQuantity(deleteQty);
  }
  
  const handleChangeSelect = (event: React.ChangeEvent<{ value: unknown }>, item: any) => {
    let value = event.target.value as string;
    setQuantity(value);

    // Update cart on BE.
    let totalQty = Number(value);
    updateCartItem(item, totalQty, customReference);
  };

  const updateCartItem = (item: any, qty: number, customReference:string = '') => {

    let newQty = cartItem.quantity - qty;
    if(localStorage.token){
      // If user is logged.
      if(newQty < 0) {
        // If the new quantity is less than 0, then add the difference.
        newQty = qty - cartItem.quantity;
        dispatch(addItemToCart(item.id, String(newQty), customReference));
      } else{
        // If the new quantity is greater than 0, then delete the difference.
        dispatch(deleteItemFromCart(item.id, String(newQty), customReference));
      }
    } else{
      // If user is anonimous.
      var cart = localStorage.anonymousCart !== undefined ? JSON.parse(localStorage.anonymousCart) : [];
      // validate if already exists.
      const index = cart.findIndex((obj: any) => obj.item.itemId === item.itemId && obj.item.itemQuantityData.customReference === customReference);
      if (index !== -1) {
        cart[index] = { item: item, quantity: qty, customReference: customReference, isFull: cartItem.isFull };
      } else {
        cart.push({ item: item, quantity: qty, customReference: customReference, isFull: cartItem.isFull });
      }
      localStorage.removeItem('anonymousCart');
      localStorage.setItem('anonymousCart', JSON.stringify(cart));
      dispatch(addItemToCartLocal());
    }
  };

  const deleteCartItem = (itemId: string, quantity: string, customReference: string = '') => {
    if (localStorage.token) {
      // If user is logged.
      dispatch(deleteItemFromCart(cartItem.item.id, quantity, customReference));
    } else {
      // If user is anonimous.
      var cart = localStorage.anonymousCart !== undefined ? JSON.parse(localStorage.anonymousCart) : [];
      const index = cart.findIndex((obj: any) => (obj.item.itemId === itemId && obj.item.itemQuantityData.customReference === customReference));
      if (index !== -1) {
        const currentQty = cart[index].quantity;
        const diff = currentQty - Number(quantity);
        if (diff < 1) {
          cart = cart.filter((obj: any) => !(obj.item.itemId === itemId && obj.item.itemQuantityData.customReference === customReference));
        } else {
          cart[index].quantity = diff;
        }
      }
      localStorage.removeItem('anonymousCart');
      localStorage.setItem('anonymousCart', JSON.stringify(cart));
      dispatch(deleteItemToCartLocal());
    }
    handleCloseModal();
  };

  // Gets the total quantity for a Single Item.
  const getSingleQuantity = () => {
    return cartItem.item.quantity;
  };

  const getSinglePrice = () => {
    if (localStorage.token || cartItem.isFull) {
      var price = 0;
      const priceObj = cartItem.item.customPrices?.filter((customPrice: any) => customPrice.customReference === customReference);
      if (priceObj?.length! > 0) price = priceObj![0]?.price;
      return price;
    }

    // If anonimous.
    return cartItem.item.itemQuantityData.finalPrice;
  };

  const getSingleWarehousesQuantity = () => {
    const warehousesQuantities = cartItem.item.warehousesCustomQuantities;
    var result = 0;
    warehousesQuantities?.forEach((whQuantity: any) => {
      if (customReference === whQuantity.id.customReference) {
        result = result + Number(whQuantity.quantity);
      }
    });
    return result;
  };

  // Quantities to render.
  const items:any = [];
  let totalQty = (localStorage.token || cartItem.isFull) ? getSingleWarehousesQuantity() : cartItem.item.itemQuantityData.quantity;
  for(var i = 1; i <= totalQty; i++) {
    items.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
  }

  const getTotalPerRow = (quantity: number, hasDiscount: boolean = false) => {
    if (localStorage.token || cartItem.isFull) {
      let singlePrice = getSinglePrice();
      return singlePrice * quantity;
    }
    // If anonimous.
    return cartItem.item.itemQuantityData.finalPrice * quantity;
  };

  const renderCustomReferences = (references: string) => {
    var tags: string[] = [];
    const firstArray = references.split('-');
    firstArray.forEach((firstValue) => {
      tags.push(firstValue);
    });
    let lastPos = tags.length - 1;
    return <Fragment>
      <Chip label={`${tags[1]} ${(tags[2] !== tags[lastPos]) ? tags[2] : ''}`} size="small" avatar={<Avatar>{tags[0]}</Avatar>} className={classes.chip} />
      <Chip label={getLangCode(tags[lastPos])} variant="outlined" size="small" className={classes.chip} />
    </Fragment>;
  };

  const getLangCode = (langName: string) => {
    switch (langName) {
      case 'English':
        return 'EN'
      case 'Spanish':
        return 'SP';
      default:
        return langName;
    }
  };

  const getItemDolarPrice = (price: number) => {
    if (dolarPrice !== undefined) {
      return (price / Number(dolarPrice));
    }
    return 0;
  };

  const findNumberonHtml = (html: string) => {
    // Trun string to HTML object.
    var htmlObject = document.createElement('div');
    htmlObject.innerHTML = html;

    // Find the element which contains the number.
    var match: string = '';
    for (var i = 0; i < htmlObject.querySelectorAll('span').length; i++) {
      const span = htmlObject.querySelectorAll('span')[i];
      if (span?.textContent?.includes('Number')) {
        match = span.innerHTML;
        break;
      }
    }
    return match;
  };

  const getImageURL = (imgObj:any) => {
    if (imgObj.url !== undefined) return imgObj.url;
    if (imgObj.imageURL !== undefined) return imgObj.imageURL;
    return defaultImage;
  };

  return (
    <Fragment>
      {quantity > 0 &&
        <Card className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={2} md={2} className={classes.colImg}>
              <Avatar variant="rounded" alt="" src={cartItem.item.images[0] !== undefined ? getImageURL(cartItem.item.images[0]) : defaultImage}/>
            </Grid>
            <Grid item xs={10} sm={8} md={8} className={classes.customPadding}>
              <Typography variant="caption" color="textSecondary">
                {(localStorage.token || cartItem.isFull) ? cartItem.item.name : cartItem.item.itemName }
              </Typography>
              <div>
                {renderCustomReferences(customReference)}
              </div>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={3}>
                  <FormControl fullWidth>
                    <InputLabel id="select-label">{ t('items.quantity') }</InputLabel>
                    <Select
                      labelId="select-label"
                      id="quantity-select"
                      value={ quantity }
                      label={ t('items.quantity') }
                      onChange={ (e) => handleChangeSelect(e, cartItem.item) }
                      className={ classes.select }
                    >
                      { items }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} md={4}>
                  <Typography variant="caption" color="textSecondary">{ t('items.seller') }</Typography>
                  <div>{(localStorage.token || cartItem.isFull) ? cartItem.item.vendor.name : cartItem.item.name }</div>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <Typography variant="caption" color="textSecondary">{ t('cart.init-price') }</Typography>
                  <div>
                    <Typography color="primary" className={classes.priceCol}>
                      {getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={getSinglePrice()}/>}
                      <span className={classes.dolarPrice}>(${<NumberFormat number={getItemDolarPrice(getSinglePrice())} decimal={true} />})</span>
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Typography variant="caption" color="textSecondary">Total</Typography>
                  <Typography color="primary" className={classes.priceCol}>
                    {getProjectConfig().CURRENCY_SYMBOL}<NumberFormat number={ getTotalPerRow(quantity) }/>
                    <span className={classes.dolarPrice}>(${<NumberFormat number={getItemDolarPrice(getTotalPerRow(quantity))} decimal={true} />})</span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                {findNumberonHtml(cartItem.item?.additionalInfo!) &&
                  <Typography variant="caption" color="textSecondary">
                    <div dangerouslySetInnerHTML={{ __html: findNumberonHtml(cartItem.item?.additionalInfo!) }}></div>
                  </Typography>
                }
              </Grid>
            </Grid>
            <Grid item xs={2} sm={2} className={classes.colButton}>
              <IconButton color="primary" aria-label="Delete item from cart" component="span" onClick={ () => handleOpenModal(quantity) }>
                <DeleteIcon color="secondary"/>
              </IconButton>
            </Grid>
          </Grid>
        </Card>
      }
      <Modal
        open={ openModal }
        onClose={ handleCloseModal }
        aria-labelledby="Confirm Modal"
        >
        <Paper elevation={3} className={ classes.modalPaper }>
          <IconButton className={ classes.closeModal } aria-label="close modal" component="span" onClick={ () => handleCloseModal() }>
            <CloseIcon/>
          </IconButton>
          <Typography>
            {t('cart.delete-confirm-text', { 'itemName': (localStorage.token) ? cartItem.item.name : cartItem.item.itemName }) }
          </Typography>
          <Button variant="contained" color="primary" onClick={ () => handleCloseModal() }> { t('cart.no') } </Button>
          <Button variant="contained" color="secondary" onClick={() => deleteCartItem(cartItem.item.itemId, String(deleteQuantity), customReference) }> { t('cart.yes') } </Button>
        </Paper>
      </Modal>
  </Fragment>
  )
}

export default ItemCart;
