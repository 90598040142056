// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 0,
      margin: 0,
      minHeight: 'auto',
      '& > *': {
        margin: theme.spacing(2, 1),
        padding: theme.spacing(4, 4, 1),
        width: '100%',
        height: 'auto',
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(4, 2, 1),
        },
      },
    },
    boxTop: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      '& > *': {
        marginRight: theme.spacing(2),
      },
    },
    boxForm: {
      display: 'flex',
      justifyContent: 'space-between',
      '& > *': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '48%',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap'
      },
    },
    radioButtons: {
      '& .MuiFormGroup-root': {
        flexDirection: 'row',
        justifyContent: 'end'
      }
    }
  }),
);

export default useStyles;
