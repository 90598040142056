// React.
import React, { useState, Fragment} from 'react';

// Material UI.
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Tooltip from '@mui/material/Tooltip';


// Redux
import { useDispatch } from "react-redux";
import { addToWishList, addToCartFromWishList } from "../../../actions/wishlistActions/wishlistActions";

// Styles.
import useStyles from './ItemCartStyles';
import defaultImage from '../../../images/default-img.jpg'; 

// Translations.
import { useTranslation } from 'react-i18next';


interface ItemWishlistProps {
  wishlistItem: any;
  customReference: string;
};

const ItemWishList = ({ wishlistItem, customReference } : ItemWishlistProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();


  const [openModal, setOpenModal] = useState(false);


  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  }


  const getItemImage = () => {
    if (wishlistItem.imageUrl === undefined) {
      return defaultImage
    }
    return wishlistItem.imageUrl;
  };

  const addToCart = (code: string, availableToCart: boolean) => {
    if(!availableToCart)
      return
    dispatch(addToCartFromWishList(code));
    handleCloseModal();
  }

  const deleteWishlistItem = (code: string) => {
    
    dispatch(addToWishList(code, '', true));//send flag for delelte element
    handleCloseModal();
  }

  const getTooltipString = () => {
    return t('wishlist.no-stock-wishlist-tooltip')
  }

  return (
    <Fragment>
      { <Card className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3} md={3} className={classes.colImg}>
              <Avatar variant="rounded" alt="" src={getItemImage()}/>
            </Grid>
            <Grid item xs={12} sm={5} md={5} className={classes.customPadding+" "+classes.wishlistNameContainer}>
              <Grid container spacing={3} className={classes.wishlistName}>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="caption" className={classes.wishlistNameLabel} color="textSecondary">{ wishlistItem.name }</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={2} className={classes.colButton}>
              {wishlistItem.availableToCart ? 
              <IconButton color="primary" aria-label="add item to wishlist" component="span" onClick={ () => addToCart(wishlistItem.itemCode, wishlistItem.availableToCart) }
              className={!wishlistItem.availableToCart ? classes.disabledButton : ''}
              >
              <AddShoppingCartIcon color="secondary" className={`${classes.iconAction} ${classes.fontActionIcon}`}/>
            </IconButton> :
              <Tooltip title={getTooltipString()}>
                <IconButton color="primary" aria-label="add item to wishlist" component="span" onClick={ () => addToCart(wishlistItem.itemCode, wishlistItem.availableToCart) }
                  className={`${!wishlistItem.availableToCart ? classes.disabledButton : classes.iconAction}`}
                >
                  <AddShoppingCartIcon color="secondary" className={classes.fontActionIcon}/>
                </IconButton>
              </Tooltip>
}
            </Grid>
            <Grid item xs={6} sm={2} className={classes.colButton}>
              <IconButton color="primary" aria-label="Delete item from wishlist" component="span" onClick={ () => handleOpenModal() }
                className={classes.iconAction}
              >
                <DeleteIcon color="secondary" className={classes.fontActionIcon}/>
              </IconButton>
            </Grid>
          </Grid>
        </Card>
      }
      <Modal
        open={ openModal }
        onClose={ handleCloseModal }
        aria-labelledby="Confirm Modal"
        >
        <Paper elevation={3} className={ classes.modalPaper }>
          <IconButton className={ classes.closeModal } aria-label="close modal" component="span" onClick={ () => handleCloseModal() }>
            <CloseIcon/>
          </IconButton>
          <Typography>
            {t('cart.delete-confirm-text', { 'itemName': wishlistItem.name }) }
          </Typography>
          <Button variant="contained" color="primary" onClick={ () => handleCloseModal() }> { t('cart.no') } </Button>
          <Button variant="contained" color="secondary" 
            onClick={() => deleteWishlistItem( wishlistItem.itemCode ) }
            > 
            { t('cart.yes') } 
          </Button>
        </Paper>
      </Modal>
  </Fragment>
  )
}

export default ItemWishList;
