import React, { Fragment, useState, useEffect } from 'react';

// Material UI.
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import { Carousel } from 'react-responsive-carousel';
import ListItemText from '@material-ui/core/ListItemText';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Components.
import NumberFormat from '../../admin/utils/NumberFormat';
import LeftArrow from '../utils/LeftArrow';
import RightArrow from '../utils/RightArrow';
import AddToWishList from '../utils/AddToWishlist';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { addItemToCart, addItemToCartLocal } from "../../../actions/cartActions/CartActions";
import { getUserInformation } from '../../../actions/authActions/AuthActions';
import { RootStore } from "../../../Store";

// Model Types.
import { ItemDatailResponse, ItemDatailSearchVendorData, ItemDatailQuantityData } from '../../admin/models/ModelTypes';

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

// Styles.
import { useStyles, Accordion, AccordionSummary, AccordionDetails} from './ItemSingleDetailStyles';

// Translations.
import { useTranslation } from 'react-i18next';
import defaultImage from '../../../images/default-img.jpg';

// Types interfase.
interface ItemCardProps {
  itemDetail: ItemDatailResponse;
};
const ItemDetail = ({ itemDetail }: ItemCardProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();
  const authToken: string | undefined = localStorage.token;

  const conditions = ['Near Mint', 'Played', 'Heavily Played'];
  const impresions = itemDetail.itemTypeId === 2 ? ['1st Edition', 'Unlimited', 'Limited', 'Duel Terminal'] : ['Foil', 'Normal'];
  const languages = ['English', 'Spanish', 'Japanese'];

  // Redux state.
  const userReduxState = useSelector((state: RootStore) => state.auth);
  const cartReduxState = useSelector((state: RootStore) => state.cart);
  const cartLoading = cartReduxState.loading;
  const actionStatus = cartReduxState.actionStatus;
  const dolarPrice = itemDetail.dolarValue;

  // Local state.
  const [expanded, setExpanded] = useState<string | false>(false);
  const [quantities, setQuantities] = useState<any>({});
  const [conditionsValues, setConditionsValues] = useState<string>('');
  const [impresionsValues, setImpresionsValues] = useState<string>('');
  const [languagesValues, setLanguagesValues] = useState<string>('');
  var countAvailable = -1;

  useEffect(() => {
    if (actionStatus?.menssage === 'status.add-cart') {
      if (authToken !== undefined && authToken !== '') dispatch(getUserInformation());
    }

    // eslint-disable-next-line
  }, [actionStatus]);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleConditionsChange = (event: any) => {
    setConditionsValues(event.target.value);
  };

  const handleImpresionsChange = (event: any) => {
    setImpresionsValues(event.target.value);
  };

  const handleLanguagesChange = (event: any) => {
    setLanguagesValues(event.target.value);
  };

  const handleQuantityChange = (id: string, value: unknown) => {
    if (id !== undefined && value !== undefined) {
      const newValues = quantities;
      newValues[id] = value;
      if (Number(value) >= 0) {
        setQuantities({ ...quantities, newValues });
      }
    }
  }

  // Gets the total quantity from all vendor warehouses.
  const getAllWarehousesQuantity = (vendor: ItemDatailSearchVendorData, customReferenceObj: any) => {
    var result = customReferenceObj.quantity;
    const customReference = (customReferenceObj !== undefined) ? customReferenceObj.customReference : '';

    // Check if there is already items added of this.
    if (userReduxState.loggedUser?.cart !== undefined) {
      userReduxState.loggedUser.cart.forEach((cartItem: any) => {
        if (cartItem.item.id === vendor.itemId && cartItem.id.customReference === customReference) {
          result = result - cartItem.quantity;
        }
      });
    }

    // Check items added for anonimous users.
    var cartLocal = localStorage.anonymousCart !== undefined ? JSON.parse(localStorage.anonymousCart) : [];
    if (cartLocal.length > 0) {
      const index = cartLocal.findIndex((obj: any) => obj.item.itemId === vendor.itemId && obj.customReference === customReference);
      if (index !== -1) {
        let currentQty = cartLocal[index].quantity;
        result = result - currentQty;
      }
    }

    // Check if there is already items of this bundle added.
    if (userReduxState.loggedUser?.bundleCart !== undefined) {
      userReduxState.loggedUser?.bundleCart.forEach((bundleItem: any) => {
        const matchedItems = bundleItem.bundle.items.filter((cartItem: any) => cartItem.item.id === vendor.itemId);
        if (matchedItems.length > 0) {
          result = result - (bundleItem.quantity * matchedItems[0].quantity);
        }
      });
    }

    // Check if there is already items of this bundle added for anonimous users.
    var bundleCartLocal = localStorage.anonymousBundleCart !== undefined ? JSON.parse(localStorage.anonymousBundleCart) : [];
    if (bundleCartLocal.length > 0) {
      bundleCartLocal.forEach((bundleItem: any) => {
        const matchedItems = bundleItem.bundle.items.filter((cartItem: any) => cartItem.item.id === vendor.itemId);
        if (matchedItems.length > 0) {
          result = result - (bundleItem.quantity * matchedItems[0].quantity);
        }
      });
    }

    if (result !== 0) {
      countAvailable = countAvailable + result;
    }

    return result;
  };

  const addToCart = (item: any, customReference: string, customReferenceObj: any) => {
    let quantity = quantities[item.itemId] > 0 ? quantities[item.itemId] : 1;
    if (localStorage.token) {
      // If user is logged.
      dispatch(addItemToCart(item.itemId, quantity, customReference));
    } else {
      // If user is anonimous.
      var cart = localStorage.anonymousCart !== undefined ? JSON.parse(localStorage.anonymousCart) : [];
      // validate if already exists.
      const index = cart.findIndex((obj: any) => (obj.item.itemId === item.itemId && obj.customReference === customReference));

      // Format data as cart page expect.
      let newItem = { 
        ...item,
        itemQuantityData: item.itemQuantityData[0],
        images: itemDetail.images, 
        itemName: itemDetail.name 
      }
      if (index !== -1) {
        let currentQty = cart[index].quantity;
        cart[index] = { item: newItem, quantity: (currentQty + quantity), customReference: customReference, isFull: false };
      } else {
        cart.push({ item: newItem, quantity: quantity, customReference: customReference, isFull: false });
      }
      localStorage.removeItem('anonymousCart');
      localStorage.setItem('anonymousCart', JSON.stringify(cart));
      dispatch(addItemToCartLocal());
    }

    // Re-Set the state value.
    const newValues = quantities;
    newValues[item.itemId] = getAllWarehousesQuantity(item, customReferenceObj) > 0 ? 1 : 0;
    setQuantities({ ...quantities, newValues });
  };

  // Quantities to render.
  const renderOptions = (quantity: number) => {
    const items = [];
    for (var i = 1; i <= quantity; i++) {
      items.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
    }
    if (items.length === 0) {
      items.push(<MenuItem key={1} value={1}>{1}</MenuItem>)
    }
    return items;
  };

  const getItemDolarPrice = (price: number) => {
    if (dolarPrice !== undefined) {
      return (price / Number(dolarPrice));
    }
    return 0;
  };

  const getCustomReferencePrice = (customRef: any) => {
    var price = customRef.finalPrice;
    return (
      <Fragment>
        {dolarPrice !== undefined && <Typography color="textSecondary">(${<NumberFormat number={getItemDolarPrice(price)} decimal={true} />}) </Typography>}
        <Typography color="primary">{getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={price} />}</Typography>
      </Fragment>
    );
  };

  const renderItemMedia = () => {
    if (itemDetail.images[0] !== undefined && itemDetail.images[0] !== null) {
      if (itemDetail.images.length > 1) {
        return <Carousel
          showArrows={true}
          interval={4500}
          transitionTime={700}
          autoPlay={true}
          showThumbs={false}
          showIndicators={true}
          emulateTouch={true}
          infiniteLoop={true}
          showStatus={false}
          renderArrowPrev={(onClickHandler, hasPrev, label) => hasPrev && (<RightArrow dark={true} onClickHandler={onClickHandler} />)}
          renderArrowNext={(onClickHandler, hasNext, label) => hasNext && (<LeftArrow dark={true} onClickHandler={onClickHandler} />)}
        >
          {itemDetail.images?.map((img: any, i: number) => <div key={i}><img src={img.imageURL} alt="Product media" loading="lazy" /></div>)}
        </Carousel>;
      } else {
        return <img src={itemDetail.images[0].imageURL} alt="Product media" loading="lazy" />;
      }
    }
    return <img src={defaultImage} alt="Product media" loading="lazy" />;
  };

  const getLangCode = (langName: string) => {
    switch (langName) {
      case 'English':
        return 'EN'
      case 'Spanish':
        return 'SP';
      case 'Japanese':
        return 'JP';
      default:
        return langName;
    }
  };

  const renderCustomReferences = (references: string) => {
    var tags: string[] = [];
    const firstArray = references.split('-');
    firstArray.forEach((firstValue) => {
      tags.push(firstValue);
    });
    let lastPos = tags.length - 1;
    return <Fragment>
      <Chip label={`${tags[1]} ${(tags[2] !== tags[lastPos]) ? tags[2] : ''}`} size="small" avatar={<Avatar>{tags[0]}</Avatar>} className={classes.chip} />
      <Chip label={getLangCode(tags[lastPos])} variant="outlined" size="small" className={classes.chip} />
    </Fragment>;
  };

  const getGlobalQty = () => {
    var total = 0;
    itemDetail.itemSearchVendorData.forEach(vendor => {
      vendor.itemQuantityData.forEach(vendorQty => {
        total += vendorQty.quantity;
      })
    });
    return total;
  };

  const processUIFilters = (vandorQtyObj: Array<ItemDatailQuantityData>) => {
    var newArray = vandorQtyObj;
    if (conditionsValues && conditionsValues !== 'all') {
      newArray = newArray.filter((item) => item.customReference.includes(conditionsValues));
    }
    if (impresionsValues && impresionsValues !== 'all') {
      newArray = newArray.filter((item) => item.customReference.includes(impresionsValues));
    }
    if (languagesValues && languagesValues !== 'all') {
      newArray = newArray.filter((item) => item.customReference.includes(languagesValues));
    }
    return newArray;
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.cardTop}>
          <div className={classes.cardLeft}>
            <div className={classes.cardImages}>
              {renderItemMedia()}
            </div>
            <div className={classes.wishlistWrapper}>
              <AddToWishList item={itemDetail}/>
            </div>
          </div>
          <div className={classes.cardRight}>
            <div className={classes.cardInfo}>
              <Typography gutterBottom className={classes.mainTitle} color="textSecondary" component="h1">
                <strong>{itemDetail.name}</strong>
              </Typography>
              <Typography gutterBottom variant="caption" color="textSecondary">
                <strong>{t('items.item-descrip')}</strong>
              </Typography>
              <Typography
                dangerouslySetInnerHTML={{ __html: itemDetail.notes }}
                variant="body2" color="textSecondary"
                component="div">
              </Typography><br />
              <Typography variant="body2" color="textSecondary" component="div" className={classes.marginBottom}>
                <div dangerouslySetInnerHTML={{ __html: itemDetail.additionalInfo }} />
              </Typography>
              {(itemDetail.labels.length > 0) &&
                <div className={classes.labelsWrapper}>
                  <Typography gutterBottom variant="caption" color="textSecondary">
                    <strong>{t('items.item-labels')}</strong>
                  </Typography>
                  <div className={classes.itemLabels}>
                    {
                      itemDetail.labels.map((label, pos) =>
                        <Fragment key={`wrapper-label-${pos}`}>
                          {label.labelValues?.map((labelValue, index) =>
                            <Fragment key={`label-${index}`}>
                              <div className='label-row'>
                                <div className='label-key'>{label.name}</div>
                                <div className='label-value'>{labelValue.name}</div>
                              </div>
                            </Fragment>
                          )}
                        </Fragment>
                      )
                    }
                  </div>
                </div>
              }
            </div>
          </div>
          <div className={classes.cardBottom}>
         
            <Paper elevation={2} square={false} variant="elevation" className={classes.filterRoot}>
              <Accordion key={itemDetail.code} square expanded={expanded === `panel-u-${itemDetail.code}`} onChange={handleChange(`panel-u-${itemDetail.code}`)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id={`panel-header-${itemDetail.code}`}>
                  <Typography gutterBottom variant="h6" component="h3" color="primary">
                    {t('items.search-by')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails id={`panel-details-${itemDetail.code}`}>
                  <div className={classes.filters}>
                    <FormControl>
                      <InputLabel id="conditions-checkbox-label">{t('items.condition')}</InputLabel>
                      <Select
                        labelId="conditions-checkbox-label"
                        id="conditions-checkbox"
                        input={<Input />}
                        value={conditionsValues}
                        onChange={handleConditionsChange}
                      >
                        <MenuItem value="all">
                          <ListItemText primary={t('items.all')} />
                        </MenuItem>
                        {conditions.map((condition, index) =>
                          <MenuItem key={index} value={condition}>
                            <ListItemText primary={condition} />
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <InputLabel id="impresions-checkbox-label">{t('items.impresion')}</InputLabel>
                      <Select
                        labelId="impresions-checkbox-label"
                        id="impresions-checkbox"
                        input={<Input />}
                        value={impresionsValues}
                        onChange={handleImpresionsChange}
                        MenuProps={{ classes: { ...(itemDetail.itemTypeId === 2) && { paper: classes.menuPaper } } }}
                      >
                        <MenuItem value="all">
                          <ListItemText primary={t('items.all')} />
                        </MenuItem>
                        {impresions.map((impresion, index) =>
                          <MenuItem key={index} value={impresion}>
                            <ListItemText primary={impresion} />
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <InputLabel id="languages-checkbox-label">{t('items.language')}</InputLabel>
                      <Select
                        labelId="languages-checkbox-label"
                        id="languages-checkbox"
                        input={<Input />}
                        value={languagesValues}
                        onChange={handleLanguagesChange}
                      >
                        <MenuItem value="all">
                          <ListItemText primary={t('items.all')} />
                        </MenuItem>
                        {languages.map((language, index) =>
                          <MenuItem key={index} value={language}>
                            <ListItemText primary={language} />
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                </AccordionDetails>
              </Accordion>
            </Paper><br />

            <Paper elevation={2} square={false} variant="elevation" className={`${classes.root} variationsTable`}>
              <div className={classes.customTable} aria-label="Variations table">
                <div className="header">
                  <div className="cell d28 m20">{t('items.seller')}</div>
                  <div className="cell d30 m25">{t('items.variation')}</div>
                  <div className="cell d20">{t('items.price')}</div>
                  <div className="cell d12">{t('items.quantity')}</div>
                  <div className="cell d10 right"></div>
                </div>
                {
                  itemDetail.itemSearchVendorData !== undefined &&
                  (itemDetail.itemSearchVendorData.map((vendor: any, i: number) =>
                    <Fragment key={`${i}-${getGlobalQty()}`}>
                      <div className="body">
                        {processUIFilters(vendor.itemQuantityData).map((customQty: ItemDatailQuantityData, index: number) =>
                          <Fragment key={index}>
                            {getAllWarehousesQuantity(vendor, customQty) > 0 &&
                              <div className="row">
                                <div className="cell d28 m20"><Link href={`/items/vendor/${vendor.id}/${vendor.name}`}>{vendor.name}</Link></div>
                                <div className="cell d30 m25">{renderCustomReferences(customQty.customReference)}</div>
                                <div className="cell d20 small priceCol">{getCustomReferencePrice(customQty)}</div>
                                <div className="cell d12 m30">
                                  <div className={classes.quantityWrapper}>
                                    <FormControl>
                                      <Select
                                        id={`${vendor.id}`}
                                        className={classes.inputQty}
                                        value={quantities[vendor.itemId] !== undefined ? quantities[vendor.itemId] : 1}
                                        label={t('items.quantity')}
                                        onChange={(e) => handleQuantityChange(vendor.itemId, e.target.value)}
                                        disabled={getAllWarehousesQuantity(vendor, customQty) < 1}
                                      >
                                        {renderOptions(getAllWarehousesQuantity(vendor, customQty))}
                                      </Select>
                                    </FormControl>
                                  </div>
                                </div>
                                <div className="cell d10 right">
                                  <IconButton color="primary" aria-label="Add to cart" component="span" onClick={() => addToCart(vendor, customQty.customReference, customQty)} disabled={getAllWarehousesQuantity(vendor, customQty) === 0 || cartLoading !== ''}>
                                    <AddShoppingCartIcon color={getAllWarehousesQuantity(vendor, customQty) > 0 ? 'secondary' : 'disabled'} />
                                  </IconButton>
                                </div>
                              </div>
                            }
                          </Fragment>
                        )}
                      </div>
                    </Fragment>
                  ))
                }
              </div>
              {(countAvailable < 1 && itemDetail !== undefined) &&
                <div className={classes.noStockMsg}>
                  {t('items.out-of-stock')}
                </div>
              }
            </Paper>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ItemDetail;
