import {
  Avatar,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import { ItemAddedType } from "../../../actions/orderActions/OrderActionsTypes"
import { ItemType } from "../models/ModelTypes"
import { useStyles } from "./CreateInventoryRequestStyles"

interface ViewItemModalProps {
  open: boolean
  onClose: () => void
  currentItem: ItemType | undefined
  itemList: ItemType[]
  loading: boolean
  itemsAdded: ItemAddedType[]
  itemQuantities: { [key: number]: number }
  quantityErrors: { [key: number]: boolean }
  handleChangeItemQuantity: (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number
  ) => void
  addItemToCart: () => void
}

const ViewItemModal: React.FC<ViewItemModalProps> = ({
  open,
  onClose,
  currentItem,
  itemList,
  loading,
  itemQuantities,
  quantityErrors,
  handleChangeItemQuantity,
  addItemToCart,
  itemsAdded,
}) => {
  const classes = useStyles()
  const { t } = useTranslation("global")
  const hasErrors = Object.values(quantityErrors).some((error) => error);
  const allQuantitiesZero = Object.values(itemQuantities).every((quantity) => quantity === 0 || quantity === undefined || isNaN(quantity));
  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modalProductInformation}
    >
      <Grid container direction="column" className={classes.containerModal}>
        <Paper elevation={3} className={classes.productInformationPaper}>
          <Avatar
            variant="rounded"
            src={
              currentItem?.images && currentItem.images.length > 0
                ? currentItem.images[0]?.url
                : ""
            }
            alt="The main item image."
            className={classes.imageProduct}
          />
          <Typography variant="h6" gutterBottom>
            {currentItem?.name}
          </Typography>

          <Typography variant="body1" gutterBottom>
            {t("inventoryRequests.sku")} {currentItem?.code}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t("inventoryRequests.category")} {currentItem?.category}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t("inventoryRequests.price")} {currentItem?.price}
          </Typography>
        </Paper>

        <TableContainer className={classes.tableContainer}>
          {loading ? (
            <div className={classes.progressContainer}>
              <CircularProgress />
            </div>
          ) : (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell}>
                    {t("inventoryRequests.store")}
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    {t("inventoryRequests.stock-available")}
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    {t("inventoryRequests.amount-to-request")}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {itemList?.map((item, index) => {
                  const maxQuantity = item?.totalQuantity ?? 0
                  const currentQuantity =
                    itemQuantities[index] !== undefined
                      ? itemQuantities[index]
                      : itemsAdded?.find(
                          (addedItem) => addedItem.item.id === item.id
                        )?.quantity ?? 0
                  return (
                    <TableRow key={index}>
                      <TableCell className={classes.tableCell}>
                        {item?.vendor?.name}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {item?.totalQuantity}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <FormControl
                          className={`${classes.fieldClass} ${classes.quantityInput}`}
                          size="small"
                        >
                          <TextField
                            id={`item-quantity-${index}`}
                            name="quantity"
                            type="number"
                            inputProps={{ min: 0, max: maxQuantity }}
                            value={currentQuantity}
                            onChange={(event) =>
                              handleChangeItemQuantity(event, index)
                            }
                            error={quantityErrors[index]}
                            disabled={currentItem?.category !== "Sellado"}
                          />
                          {quantityErrors[index] && (
                            <Typography variant="caption" color="error">
                              {t("orders.quantity-greater-error")}
                            </Typography>
                          )}
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <Grid item className={classes.buttonBottomModal}>
          <Button variant="contained" onClick={onClose}>
            {t("inventoryRequests.cancel")}
          </Button>
          <Button variant="contained" color="primary" onClick={addItemToCart} disabled={hasErrors || allQuantitiesZero}>
            {t("inventoryRequests.add-to-request")}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default ViewItemModal
