// React,
import React, {Fragment, useEffect, useState} from 'react';

// Material UI.
import Typography from '@material-ui/core/Typography';

// Redux
import { useSelector } from "react-redux";
import {RootStore} from "../../../Store";

// Components.
import ItemCart from '../items/ItemCart';
import ItemSingleCart from '../items/ItemSingleCart';
import BundleCart from '../bundles/BundleCart';

// Translations.
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

// Styles.
import useStyles from './CheckoutReviewStyles';

interface CheckoutReviewProps {
  isSummary: boolean;
  updateDisabledState: Function;
};

const CheckoutReview = ({isSummary,updateDisabledState} : CheckoutReviewProps) => {
  const [t] = useTranslation('global');
  const classes = useStyles();

  // Redux state.
  const reduxState = useSelector((state: RootStore) => state.auth);
  const loggedUser = reduxState.loggedUser;
  const cartState = useSelector((state: RootStore) => state.cart);

  // Local state.
  const [cart, setCart] = useState<Array<any>>([]);
  const [bundleCart, setBundleCart] = useState<Array<any>>([]);

  useEffect(() => {
    if (loggedUser !== undefined) {
      setCart(loggedUser.cart);
      setBundleCart(loggedUser.bundleCart);
    }
  // eslint-disable-next-line
  }, [loggedUser, cartState])

  const getTotalQuantity = () => {
    var total = 0;
    // Count bundle cart items.
    bundleCart.forEach((cartItem:any) => 
      total += cartItem.quantity
    );
    // Count normal cart items.
    cart.forEach((cartItem:any) => 
      total += cartItem.quantity
    );
    return total;
  };

  const nextStep = () => {
    updateDisabledState(2);
  };

  const renderSummaryContent = () => {
    return (
      <Fragment>
        <Typography variant="h6">{ t('checkout.step-2') }</Typography>
        <Typography>({ getTotalQuantity() } { t('cart.items') })</Typography>
      </Fragment>
    );
  };

  const renderFullContent = () => {
    return (
      <Fragment>        
        <Typography variant="h6">{ t('checkout.step-2') }</Typography><br/>
        { (cart.length === 0 && bundleCart.length === 0) && <Typography>{ t('cart.shopping-cart-empty') }</Typography> }
        <div className={ classes.itemsWrapper }>
          {
            bundleCart.length > 0 && 
            (bundleCart.map((bundle:any, index:number) => 
              <BundleCart key={index} cartBundle={ bundle }/>
            ))
          }
          {
            cart.length > 0 && 
            (cart.map((item:any, index:number) => 
              <Fragment key={index}>
                {(item.item.category === 'Single') ?
                  <ItemSingleCart cartItem={item} customReference={item.id.customReference} /> :
                  <ItemCart cartItem={item} customReference={item.id.customReference} />}
              </Fragment>
            ))
          }
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}>
          <Button variant="contained" color="primary" disabled={(cart.length === 0 && bundleCart.length === 0)} onClick={nextStep}>
            { t('checkout.next-step') }
          </Button>
        </div>
        
      </Fragment>
    )
  };

  return (
    <div>
      { isSummary ? renderSummaryContent() : renderFullContent() }
    </div>
  );
}

export default CheckoutReview;
