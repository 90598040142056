// React.
import React, { Fragment, useEffect, useState } from 'react';

// Components.
import Pagination from '../utils/ResultsPagination';
import ImageAvatar from '../utils/ImageAvatar';
import BundleEdit from './BundleEdit';

// Material UI
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

// Redux
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import { getBundles, refreshBundleView } from "../../../actions/bundleActions/BundleActions";

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

// Translations.
import { useTranslation } from 'react-i18next';

// Moment.
import Moment from 'moment';

// Styles.
import { useStyles, Accordion, AccordionSummary, AccordionDetails } from './BundleAccordionStyles';
import scrollToTop from '../../utils/scrollToTop';

const BundleAccordion = () => {
  const [t] = useTranslation('global');
  const classes = useStyles();
  const dispatch = useDispatch();

  // Redux state.
  const bundleState = useSelector((state: RootStore) => state.bundle);
  const { bundleList, refresh, bundleFilters: filters} = bundleState;

  // Local component state.
  const [currentPage, setCurrentPage] = useState<number>(1);
  const url = window.location.href;

  useEffect(() => {
    const statusMsg = bundleState.actionStatus !== undefined ? bundleState.actionStatus.menssage : '';
    const params = new URLSearchParams(window.location.search);
    let page = params.get('page');
    const pNumber = Number(page);
    if (page && page !== null) {
      const qParams = {
        ...filters,
        page: pNumber - 1,
        size: getProjectConfig().PAGES_SIZE,
        sort: `creationDate,desc`,
        isUniquePerBundleCode: true,
        paged: true
      };
      dispatch(getBundles(qParams));
      setCurrentPage(pNumber);

      if (statusMsg !== 'status.success-update' && statusMsg !== 'status.error-update') {
        scrollToTop();
      }
    } else {
      const qParams = {
        ...filters,
        page: 0,
        size: getProjectConfig().PAGES_SIZE,
        sort: `creationDate,desc`,
        isUniquePerBundleCode: true,
        paged: true
      };
      dispatch(getBundles(qParams));
      setCurrentPage(1);
      if (statusMsg !== 'status.success-update' && statusMsg !== 'status.error-update') {
        scrollToTop();
      }
    }

    dispatch(refreshBundleView(false));
    
    // eslint-disable-next-line
  }, [url, refresh]);

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (bundleList?.bundles.length === 0) {
    return (
      <div className={ classes.circularProgress }>
        <Typography color="textSecondary">{ t('bundles.no-bundles') }</Typography>
      </div>
    )
  }

  return (
    <Fragment>
        {
          bundleList?.bundles !== undefined && 
          (bundleList!.bundles!.map(bundle => 
            <Accordion key={ bundle.id } square expanded={expanded === `panel-u-${ bundle.id }`} onChange={handleChange(`panel-u-${ bundle.id }`)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={1} className={ classes.userImgSmall }>
                    <ImageAvatar src={ bundle.images[0]?.url ? bundle.images[0].url : '' } alt="Bundle image." />
                  </Grid>
                  <Grid item xs={10} sm={5}>
                    <Typography variant="subtitle1">{ bundle.name }</Typography>
                  </Grid>
                  <Grid item xs={10} sm={3}>
                    <Typography variant="caption" color="textSecondary">{ t('items.created') }: { Moment(bundle.creationDate).format('MM/DD/YYYY') }</Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} className={ classes.lastWrapper }>
                    <FiberManualRecordIcon className={ bundle.isPublished ? 'enabled' : 'disabled' } color="disabled"/>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <BundleEdit bundle={ bundle } isExpanded={expanded === `panel-u-${ bundle.id }`}/>
              </AccordionDetails>
            </Accordion>
          ))
        }
        {
          bundleList?.totalPages !== undefined && bundleList?.totalPages !== 1 
          ? (<Pagination current={currentPage} path="/admin/bundles" pagesNumber={ bundleList!.totalPages }/>) : ''
        }
    </Fragment>
  );
}

export default BundleAccordion;
