// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wishlistIcon: {
      cursor: 'pointer',
      transition: 'transform 0.3s',
      color: theme.palette.primary.main,
      '&:hover': {
        transform: 'scale(1.3)',
        transition: 'transform 0.3s',
      },
    },
    clicked: {
      animation: '$heartbeat 0.6s',
    },
    '@keyframes heartbeat': {
      '0%': {
        transform: 'scale(1)',
      },
      '50%': {
        transform: 'scale(1.2)',
      },
      '100%': {
        transform: 'scale(1)',
      },
    }
  }),
);

export default useStyles;
