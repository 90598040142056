
// Material UI
import ListAltIcon from '@material-ui/icons/ListAlt';
import Typography from '@material-ui/core/Typography';
// Translations.
import { useTranslation } from 'react-i18next';
// Styles.
import useStyles from './AddOrderDetailsModalStyles';
// Model types.
import AddOrderAccordionModal from './AddOrderAccordionModal';


const AddOrderDetailsModal = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  return (
    <div className={classes.root}>
        <div className={classes.boxTop}>
          <ListAltIcon color="primary" fontSize="medium" />
          <Typography color="primary" className={classes.sectionTitle}>
            {t('orders.order-details')}
          </Typography>
        </div>
        <div className={classes.boxBottom}>
          <AddOrderAccordionModal />
        </div>
    </div>
  )
}

export default AddOrderDetailsModal;
