import axios from "axios";
//Redux
import { Dispatch } from "redux";
//Types
import {
	EVENTS_FAIL,
	EVENTS_LIST,
	EVENTS_LOADING,
	EVENTS_UPDATEFILTER,
	EVENT_DETAIL,
	EVENT_PUT,
	EVENT_PLAYERS_LIST,
	EVENT_REGISTER_PLAYERS,
	EVENT_UPDATE_PLAYERS,
	EVENT_REGISTER_ANONYMOUS_PLAYERS,
	EventDispatchType,
	EVENT_CURRENT_ORDER_PLAYER,
	EVENT_GET_PLAYER_ORDER,
} from "./eventActionTypes";

const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;

export const updateFilter =
(filter: any) => async (dispatch: Dispatch<EventDispatchType>) => {
		if (apiURL !== "" && token !== "") {
			try {
				dispatch({
					type: EVENTS_UPDATEFILTER,
					payload: filter,
				});
			} catch (error : any) {
				if (error.response && error.response.data.status_code === 422) {
					//Showing the error message when something went wrong during the BE validation.
					dispatch({
						type: EVENTS_FAIL,
						payload: { message: error.response.data.message, description: "status.get-error" },
					});
				} else {
					dispatch({
						type: EVENTS_FAIL,
						payload: { message: "status.get-error", description: "status.get-error" },
					});
				}
			}
		}
	};

export const updatePagination =
	(filter: any) => async (dispatch: Dispatch<EventDispatchType>) => {
		if (apiURL !== "" && token !== "") {
			try {
				dispatch({
					type: EVENTS_UPDATEFILTER,
					payload: filter,
				});
				const headers: object = {
					ContentType: "application/json",
					Authorization: "Bearer " + token,
				};
				const requestPath = `${apiURL}/seller/getEventList`;
				const res = await axios.get(requestPath, {
					headers: headers,
					params: filter,
				});
				//Adding the Events data to Redux store
				if (res.status === 200) {
					dispatch({
						type: EVENTS_LIST,
						payload: res.data.data,
					});
				} else {
					dispatch({
						type: EVENTS_FAIL,
						payload: { message: "error", description: "status.error-update" },
					});
				}
			} catch (error : any) {
				if (error.response && error.response.data.status_code === 422) {
					//Showing the error message when something went wrong during the BE validation.
					dispatch({
						type: EVENTS_FAIL,
						payload: { message: error.response.data.message, description: "status.get-error" },
					});
				} else {
					dispatch({
						type: EVENTS_FAIL,
						payload: { message: "status.get-error", description: "status.get-error" },
					});
				}
			}
		}
	};

export const getEventList =
	(filter: any) => async (dispatch: Dispatch<EventDispatchType>) => {
		if (apiURL !== "" && token !== "") {
			try {
				dispatch({
					type: EVENTS_LOADING,
					payload: "loading",
				});
				const headers: object = {
					ContentType: "application/json",
					Authorization: "Bearer " + token,
				};
				const requestPath = `${apiURL}/seller/getEventList`;
				const res = await axios.get(requestPath, {
					headers: headers,
					params: filter,
				});
				//Adding the Events data to Redux store
				if (res.status === 200) {
					dispatch({
						type: EVENTS_LIST,
						payload: res.data.data,
					});
				} else {
					dispatch({
						type: EVENTS_FAIL,
						payload: { message: "error", description: "status.error-update" },
					});		
				}
			} catch (error : any) {
				if (error.response && error.response.data.status_code === 422) {
					//Showing the error message when something went wrong during the BE validation.
					dispatch({
						type: EVENTS_FAIL,
						payload: { message: error.response.data.message, description: "status.error-update" },
					});
				} else {
					dispatch({
						type: EVENTS_FAIL,
						payload: { message: "error", description: "status.error-update" },
					});
				}
			}
		}
	};
	//Update Current Player Orders
export const updateCurrentOrderPlayer=(body:any)=> async (dispatch: Dispatch<EventDispatchType>)=>{
	if (apiURL !== "" && token !== "") {
		try{
			dispatch({
				type: EVENT_CURRENT_ORDER_PLAYER,
				payload: body
			})
		}catch(error : any){
			if (error.response && error.response.data.status_code === 422) {
				//Showing the error message when something went wrong during the BE validation.
				dispatch({
					type: EVENTS_FAIL,
					payload: { message: error.response.data.message, description: "status.error-update" },
				});
			} else {
				dispatch({
					type: EVENTS_FAIL,
					payload: { message: "error", description: "status.error-update" },
				});
			}
		}
	}
}

export const getCurrentPlayerOrder=(body:any) =>async(dispatch: Dispatch<EventDispatchType>)=>{
	if(apiURL !=="" && token !== ""){
		try{
			dispatch({
				type: EVENTS_LOADING,
				payload: "loading",
			});
			const configPost = {
				headers: {
					Accept: "*/*",
					Authorization: "Bearer " + token,
					"Content-Type": "application/json",
				},
			};
			const requestPath = `${apiURL}/seller/getEventPlayerOrder/${body.id}`;
			const res = await axios.get(requestPath, configPost);

			if (res.status === 200) {
				dispatch({
					type: EVENT_GET_PLAYER_ORDER,
					payload: res.data.data
				})
			} else {
				dispatch({
					type: EVENTS_FAIL,
					payload: { message: "error", description: "status.error-update" },
				});
			}
		}catch(error : any){
			if (error.response && error.response.data.status_code === 422) {
				//Showing the error message when something went wrong during the BE validation.
				dispatch({
					type: EVENTS_FAIL,
					payload: { message: error.response.data.message, description: "status.error-update" },
				});
			} else {
				dispatch({
					type: EVENTS_FAIL,
					payload: { message: "error", description: "status.error-update" },
				});
			}
		}
	}
}

export const registerItem = (body:any)=>async (dispatch: Dispatch<EventDispatchType>)=>{
	if (apiURL !== "" && token !== "") {
		try {
			dispatch({
				type: EVENTS_LOADING,
				payload: "loading",
			});
			const configPost = {
				headers: {
					Accept: "*/*",
					Authorization: "Bearer " + token,
					"Content-Type": "application/json",
				},
			};
			const requestPath = `${apiURL}/seller/registerEventPlayerOrderItem`;
			const res = await axios.post(requestPath, body, configPost);

			if (res.status === 200) {
				dispatch({
					type: EVENT_PUT,
					payload: { message: "status.success-update", event: res.data.data },
				});
				const requestPath2 = `${apiURL}/seller/getEventPlayerOrder/${body.eventPlayerId}`;
				const res2 = await axios.get(requestPath2, configPost);
	
				if (res2.status === 200) {
					dispatch({
						type: EVENT_GET_PLAYER_ORDER,
						payload: res.data.data
					})
				} else {
					dispatch({
						type: EVENTS_FAIL,
						payload: { message: "error", description: "status.error-update" },
					});
				}
			} else {
				dispatch({
					type: EVENTS_FAIL,
					payload: { message: "error", description: "status.error-update" },
				});
			}
		} catch (error : any) {
			if (error.response && error.response.data.status_code === 422) {
				//Showing the error message when something went wrong during the BE validation.
				dispatch({
					type: EVENTS_FAIL,
					payload: { message: error.response.data.message, description: "status.error-update" },
				});
			}
			dispatch({
				type: EVENTS_FAIL,
				payload: { message: "error", description: "status.error-update" },
			});
		}
	}
}

export const registerPlayerEventOrder = (body:any)=>async (dispatch: Dispatch<EventDispatchType>)=>{
	if (apiURL !== "" && token !== "") {
		try {
			dispatch({
				type: EVENTS_LOADING,
				payload: "loading",
			});
			const configPost = {
				headers: {
					Accept: "*/*",
					Authorization: "Bearer " + token,
					"Content-Type": "application/json",
				},
			};

			const requestPath = `${apiURL}/seller/registerPlayerEventOrder`;
			const res = await axios.post(requestPath, body, configPost);

			if (res.status === 200) {
				dispatch({
					type: EVENT_PUT,
					payload: { message: "status.success-register-order", event: res.data.data },
				});
			} else {
				dispatch({
					type: EVENTS_FAIL,
					payload: { message: "error", description: "status.error-update" },
				});
			}
		} catch (error : any) {
			if (error.response && error.response.data.status_code === 422) {
				//Showing the error message when something went wrong during the BE validation.
				dispatch({
					type: EVENTS_FAIL,
					payload: { message: error.response.data.message, description: "status.error-update" },
				});
			} else {
				dispatch({
					type: EVENTS_FAIL,
					payload: { message: "error", description: "status.error-update" },
				});
			}
		}
	}
}

export const registerEvent =
	(body: any) => async (dispatch: Dispatch<EventDispatchType>) => {
		if (apiURL !== "" && token !== "") {
			try {
				dispatch({
					type: EVENTS_LOADING,
					payload: "loading",
				});
				const configPost = {
					headers: {
						Accept: "*/*",
						Authorization: "Bearer " + token,
						"Content-Type": "application/json",
					},
				};
				const requestPath = `${apiURL}/seller/registerEvent`;
				const res = await axios.post(requestPath, body, configPost);

				if (res.status === 200) {
					getEventList(null);
					window.location.reload();
				} else {
					dispatch({
						type: EVENTS_FAIL,
						payload: { message: "error", description: "status.error-update" },
					});
				}
			} catch (error : any) {
				if (error.response && error.response.data.status_code === 422) {
					//Showing the error message when something went wrong during the BE validation.
					dispatch({
						type: EVENTS_FAIL,
						payload: { message: error.response.data.message, description: "status.error-update" },
					});
				} else {
					dispatch({
						type: EVENTS_FAIL,
						payload: { message: "error", description: "status.error-update" },
					});
				}
			}
		}
	};

/**
 * Get an event details by id.
 * @param {string} eventId
 */
export const getEventById =
	(eventId: string) => async (dispatch: Dispatch<EventDispatchType>) => {
		if (apiURL !== "" && token !== "") {
			try {
				dispatch({
					type: EVENTS_LOADING,
					payload: "EVENT_DETAIL",
				});

				const headers: object = {
					ContentType: "application/json",
					Authorization: "Bearer " + token,
				};

				const requestPath = `${apiURL}/seller/getEventDetails/${eventId}`;

				const res = await axios.get(requestPath, {
					headers: headers,
				});

				if (res.status === 200) {
					const queryData = res.data.data;
					dispatch({
						type: EVENT_DETAIL,
						payload: queryData,
					});
				} else {
					dispatch({
						type: EVENTS_FAIL,
						payload: { message: "status.get-error", description: res.data.message },
					});
				}
			} catch (error : any) {
				if (error.response && error.response.data.status_code === 422) {
					//Showing the error message when something went wrong during the BE validation.
					dispatch({
						type: EVENTS_FAIL,
						payload: { message: error.response.data.message, description: "status.get-error" },
					});
				} else {
					dispatch({
						type: EVENTS_FAIL,
						payload: { message: "status.get-error", description: "status.get-error" },
					});
				}
			}
		} else {
			dispatch({
				type: EVENTS_FAIL,
				payload: { message: "status.get-error", description: "status.get-error" },
			});
		}
	};

/**
 * Update the given event on the BE.
 * @param {any} event
 * @param {string} eventId
 * @param {boolean | null} isDone
 */
export const updateEventInformation =
	(event: any, eventId: string, isDone: boolean = false) =>
	async (dispatch: Dispatch<EventDispatchType>) => {
		try {
			dispatch({
				type: EVENTS_LOADING,
				payload: isDone ? "EVENT_UPDATE_FINISHED" : "EVENT_UPDATE",
			});

			const configPUT = {
				headers: {
					Accept: "*/*",
					Authorization: "Bearer " + token,
					"Content-Type": "application/json",
				},
			};

			const requestPath = `${apiURL}/seller/updateEventInformation/${eventId}`;
			const res = await axios.put(requestPath, event, configPUT);

			if (res.status === 200) {
				dispatch({
					type: EVENT_PUT,
					payload: { message: "status.success-update", event: res.data.data },
				});
			} else {
				dispatch({
					type: EVENTS_FAIL,
					payload: { message: "status.error-update", description: res.data.message },
				});
			}
		} catch (error: any) {
			if (error.response && error.response.data.status_code === 422) {
				//Showing the error message when something went wrong during the BE validation.
				dispatch({
					type: EVENTS_FAIL,
					payload: { message: error.response.data.message, description: "status.error-update" },
				});
			} else {
				dispatch({
					type: EVENTS_FAIL,
					payload: { message: "status.error-update", description: "status.error-update" },
				});
			}
		}
	};

/**
 * Get a list of event players by event id.
 * @param {string} eventId
 */
export const getEventPlayers =
	(eventId: string) => async (dispatch: Dispatch<EventDispatchType>) => {
		if (apiURL !== "" && token !== "") {
			try {
				dispatch({
					type: EVENTS_LOADING,
					payload: "EVENT_PLAYERS_LIST",
				});

				const headers: object = {
					ContentType: "application/json",
					Authorization: "Bearer " + token,
				};

				const requestPath = `${apiURL}/seller/getEventPlayers/${eventId}`;

				const res = await axios.get(requestPath, {
					headers: headers,
				});

				if (res.status === 200) {
					const queryData = res.data.data;
					dispatch({
						type: EVENT_PLAYERS_LIST,
						payload: queryData,
					});
				} else {
					dispatch({
						type: EVENTS_FAIL,
						payload: { message: "status.error-update", description: res.data.message },
					});
				}
			} catch (e) {
				dispatch({
					type: EVENTS_FAIL,
					payload: { message: "status.error-update", description: "status.error-update" },
				});
			}
		} else {
			dispatch({
				type: EVENTS_FAIL,
				payload: { message: "status.error-update", description: "status.error-update" },
			});
		}
	};

/**
 * Add a player on event by id.
 * @param {any} playersList
 * @param {number} eventId
 */
export const registerEventPlayers =
	(playersList: any, eventId: number) => async (dispatch: Dispatch<EventDispatchType>) => {
		try {
			dispatch({
				type: EVENTS_LOADING,
				payload: "EVENT_REGISTER_PLAYERS",
			});

			const configPOST = {
				headers: {
					Accept: "*/*",
					Authorization: "Bearer " + token,
					"Content-Type": "application/json",
				},
			};

			const postBody = {
				eventId: eventId,
				playersList: playersList
			};

			const requestPath = `${apiURL}/seller/registerEventPlayers`;
			const res = await axios.post(requestPath, postBody, configPOST);

			if (res.status === 200) {
				dispatch({
					type: EVENT_REGISTER_PLAYERS,
					payload: res.data.data,
				});
			} else {
				dispatch({
					type: EVENTS_FAIL,
					payload: { message: "status.error-update", description: res.data.message },
				});
			}
		} catch (error : any) {
			if (error.response && error.response.data.status_code === 422) {
				//Showing the error message when something went wrong during the BE validation.
				dispatch({
					type: EVENTS_FAIL,
					payload: { message: error.response.data.message, description: "status.error-update" },
				});
			} else {
				dispatch({
					type: EVENTS_FAIL,
					payload: { message: "status.error-update", description: "status.error-update" },
				});
			}
		}
	};

/**
 * Update the given player on event.
 * @param {any} playerData
 * @param {string} eventId
 */
export const updateEventPlayer =
	(playerData: any, eventId: string) =>
	async (dispatch: Dispatch<EventDispatchType>) => {
		try {
			dispatch({
				type: EVENTS_LOADING,
				payload: "EVENT_UPDATE_PLAYERS",
			});

			const configPUT = {
				headers: {
					Accept: "*/*",
					Authorization: "Bearer " + token,
					"Content-Type": "application/json",
				},
			};

			const requestPath = `${apiURL}/seller/updateEventPlayer/${eventId}`;
			const res = await axios.put(requestPath, playerData, configPUT);

			if (res.status === 200) {
				dispatch({
					type: EVENT_UPDATE_PLAYERS,
					payload: { message: "status.success-update", player: res.data.data },
				});
			} else {
				dispatch({
					type: EVENTS_FAIL,
					payload:{ message: "status.error-update", description: res.data.message },
				});
			}
		} catch (error : any) {
			if (error.response && error.response.data.status_code === 422) {
				//Showing the error message when something went wrong during the BE validation.
				dispatch({
					type: EVENTS_FAIL,
					payload: { message: error.response.data.message, description: "status.error-update" },
				});
			} else {
				dispatch({
					type: EVENTS_FAIL,
					payload: { message: "status.error-update", description: "status.error-update" },
				});
			}
		}
	};

/**
 * Register a player on the system as a user by id.
 * @param {string} playerId
 */
export const registerAnonymousPlayer =
(playerId: string) => async (dispatch: Dispatch<EventDispatchType>) => {
	try {
		dispatch({
			type: EVENTS_LOADING,
			payload: "EVENT_REGISTER_ANONYMOUS_PLAYERS",
		});

		const headers: object = {
			Accept: "*/*",
			ContentType: "application/json",
			Authorization: "Bearer " + token,
		};

		const requestPath = `${apiURL}/seller/registerAnonymousPlayer/${playerId}`;

		const res = await axios.post(requestPath, {}, {
			headers: headers,
		});

		if (res.status === 200) {
			dispatch({
				type: EVENT_REGISTER_ANONYMOUS_PLAYERS,
				payload: { message: "status.success-update", data: res.data.data },
			});
		} else {
			dispatch({
				type: EVENTS_FAIL,
				payload: { message: "status.error-update", description: res.data.message },
			});
		}
	} catch (error : any) {
		if (error.response && error.response.data.status_code === 422) {
			//Showing the error message when something went wrong during the BE validation.
			dispatch({
				type: EVENTS_FAIL,
				payload: { message: error.response.data.message, description: "status.error-update" },
			});
		} else {
			dispatch({
				type: EVENTS_FAIL,
				payload: { message: "status.error-update", description: "status.error-update" },
			});
		}
	}
};
