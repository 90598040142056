// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& .MuiTextField-root': {
        width: '40px'
      },
      '& .MuiTableCell-head': {
        fontWeight: 'bold'
      },
      '& .MuiTableCell-root': {
        [theme.breakpoints.down('xs')]: {
          padding: '6px 0px 6px 12px'
        },
      },
      '& a': {
        color: theme.palette.primary.main
      },
      '& .item-description': {
        pointerEvents: 'none'
      }
    },
    cardTop: {
      display: 'flex',
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        minHeight: '160px',
      },
    },
    cardMedia: {
      width: '35%',
      display: 'block',
      alignSelf: 'center',
      '& img': {
        width: '100%',
        height: 'auto',
        maxHeight: '270px',
        objectFit: 'contain',
        borderRadius: '4px',
        paddingTop: '6px',
        [theme.breakpoints.down('xs')]: {
          maxHeight: '160px',
        },
      }
    },
    cardTitle: {
      fontFamily: 'VisbyCF-Bold',
      position: 'sticky',
      top: 0,
      display: 'block',
      background: theme.palette.primary.contrastText,
    },
    cardDescription: {
      width: '65%',
      padding: `0 ${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
      [theme.breakpoints.up('lg')]: {
        height: '265px',
        overflowY: 'auto',
      },
      '& .item-description': {
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 11,
        whiteSpace: 'pre-wrap'
      }
    },
    lineThrough: {
      textDecoration: 'line-through'
    },
    noStockMsg: {
      margin: theme.spacing(2, 0) 
    },
    vendorInfo: {
      padding: theme.spacing(1, 2),
      backgroundColor: theme.palette.grey[200],
      marginTop: theme.spacing(1),
      fontFamily: 'VisbyCF-Bold'
    },
    seeMoreBtn: {
      display: 'block',
      margin: '8px auto',
      width: 'fit-content',
      color: 'white !important'
    },
    customTable: {
      '& .header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        padding: '4px 4px',
        backgroundColor: theme.palette.grey[600],
        color: 'white',
        borderRadius: '6px',
      },
      '& .row': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        padding: '4px 0'
      },
      '& .cell': {
        textAlign: 'center',
        '&.d36': {
          width: '36%'
        },
        '&.d28': {
          width: '28%'
        },
        '&.d14': {
          width: '14%'
        },
        '&.d12': {
          width: '12%',
        },
        '&.d10': {
          width: '10%',
        },
        '&.d50': {
          width: '50%'
        },
        '&.d100': {
          width: '100%'
        },
        '&.right': {
          textAlign: 'right'
        },
        '&.priceCol': {
          padding: '0 5px',
          '& .MuiTypography-root': {
            display: 'inline-block',
            fontSize: '14px',
            marginRight: theme.spacing(1)
          }
        },
        [theme.breakpoints.down('sm')]: {
          '&.m22': {
            width: '22%'
          },
          '&.m23': {
            width: '23%'
          },
          '&.d10 .MuiButtonBase-root': {
            padding: '5px',
          },
          '&.small p': {
            fontSize: '14px'
          }
        }
      },
      '& .MuiPaper-root': {
        [theme.breakpoints.down('sm')]: {
          padding: '6px !important'
        }
      }
    },
    variation: {
      fontFamily: 'VisbyCF-Bold',
      fontSize: '11px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '9px',
      }
    },
    quantityWrapper: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiFormControl-root': {
        width: '100%',
        '& .MuiInputBase-root:before': {
          display: 'none'
        }
      },
      '& .totalQty': {
        backgroundColor: theme.palette.grey[100],
        padding: '6px 9px',
        width: '50%',
      },
    },
    inputQty: {
      border: `1px solid ${theme.palette.grey[300]}`,
      padding: '0 3px',
      maxWidth: '58px',
      '& .MuiSelect-icon': {
        right: '-3px'
      },
      '& .MuiSelect-select': {
        paddingRight: '8px'
      }
    },
    image:{
      width: '100%',
    },
    wishlistWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

export default useStyles;
