// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inOnDrag: {
      cursor: 'grabbing !important',
      '& *': {
        cursor: 'grabbing !important'
      }
    },
    bannerImg: {
      width: '150px',
      height: '50px',
      marginBottom:  0,
      [theme.breakpoints.down('xs')]: {
        marginBottom:  theme.spacing(1),
      },
    },
    marginBottom: {
      marginBottom:  theme.spacing(2),
    },
    rowWrapper: {
      '& .MuiListItemSecondaryAction-root': {
        [theme.breakpoints.down('xs')]: {
          right: '-25px'
        },
      },
    },
    imageRow: {
      backgroundColor: process.env.REACT_APP_THEME_COLOR_EXTRA_LIGHT_GRAY,
      borderRadius: '4px',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0
      },
      '& form': {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        flexWrap: 'wrap',
      },
      '& .row-image': {
        marginRight: theme.spacing(8)
      },
      '& .row-actions': {
        width: '50%',
        [theme.breakpoints.down('xs')]: {
          width: '90%',
        },
      },
      '& .row-text-inputs': {
        width: '85%',
        margin: theme.spacing(1, 0),
        border: `1px solid ${process.env.REACT_APP_THEME_COLOR_EXTRA_MEDIUM_GRAY}`,
        borderRadius: '6px',
        padding: theme.spacing(1),
        '& .MuiFormControlLabel-root': {
          width: '100%',
          marginLeft: 0
        },
        '& .MuiFormControl-root': {
          width: '50%',
          [theme.breakpoints.down('xs')]: {
            width: '100%',
          },
        },
        '& .MuiTextField-root': {
          width: '90%'
        }
      },
      '& .MuiButtonBase-root': {
        marginRight: theme.spacing(2)
      },
      '& .MuiFormControlLabel-root': {
        marginLeft: theme.spacing(4),
        [theme.breakpoints.down('xs')]: {
          marginLeft: 0,
        },
      }
    },
    fileInput: {
      display: 'none'
    }
  }),
);

export default useStyles;
