import {
    DELIVERY_FAIL,
    DELIVERY_LIST,
    DELIVERY_LOADING,
    DELIVERY_REFRESH,
    DELIVERY_SET_FILTER,
    UPDATE_DELIVERY,
    DeliveryDetails,
    DeliveryDispatchTypes,
    DeliveryList,
} from "../actions/deliveryActions/DeliveryActionsTypes"

// Default state interface.
interface DefaultStateI {
  loading: string
  delivery?: Array<DeliveryDetails>
  refresh?: boolean
  deliveryFilters?: object
  deliveryList?: DeliveryList
  pendingDeliveryList?: DeliveryList
  actionStatus?: any
}

// Default state.
const defaultState: DefaultStateI = {
  loading: "",
}

const DeliveryReducer = ( state: DefaultStateI = defaultState, action: DeliveryDispatchTypes
): DefaultStateI => {
  switch (action.type) {
    case DELIVERY_FAIL:
      return {
        ...state,
        loading: "",
        actionStatus: { menssage: action.payload, status: "error" }
      }
    case DELIVERY_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case DELIVERY_LIST:
      return {
        ...state,
        deliveryList: action.payload,
        loading: "",
      }
    case DELIVERY_SET_FILTER:
      return {
        ...state,
        deliveryFilters: action.payload,
      }
    case DELIVERY_REFRESH:
      return {
        ...state,
        refresh: action.payload,
      }
    case UPDATE_DELIVERY:
      return {
        ...state,
        loading: "",
        actionStatus: { menssage: action.payload, status: "success" }
      }
    default:
      return state
  }
}

export default DeliveryReducer
