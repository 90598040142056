// react.
import React, { useEffect } from 'react';
import DocumentMeta from 'react-document-meta';
import { useParams } from "react-router-dom";

// Material UI.
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// Components.
import ItemsList from '../items/ItemsList';

// Redux.
import {RootStore} from "../../../Store";
import {useSelector} from "react-redux";

// Styles.
import useStyles from './ItemSearchStyles';

// Translations.
import { useTranslation } from 'react-i18next';


// Config.
import { getProjectConfig } from '../../../getProjectConfig';
import scrollToTop from '../../utils/scrollToTop';

type ParamsType = {
  vendorId: string;
  vendorName: string;
};

const ItemSearch = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const params = new URLSearchParams(window.location.search);
  const onlyBundles = params.get('onlyBundles');
  const { vendorId, vendorName } = useParams<ParamsType>();

  // Redux state.
  const bundleState = useSelector((state: RootStore) => state.bundle);
  const loadingBundle = bundleState.loading;
  const itemState = useSelector((state: RootStore) => state.item);
  const loadingItem = itemState.loading;

  // Page metatags info.
  const meta = getProjectConfig().METATAGS.ITEMS_SEARCH;

  useEffect(() => {
    if (loadingBundle === '' && loadingItem === '') {
      scrollToTop();
    }
  // eslint-disable-next-line
  }, [loadingBundle, loadingItem])

  return (
    <DocumentMeta {...meta}>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.sectionWrapper}>
        { (loadingItem !== '') && <CircularProgress /> }
        {loadingItem === '' &&
          <Typography gutterBottom variant="h4" component="h1" className={classes.vendorName}>
            {t('items.seller')}: {vendorName}
          </Typography>
        }
        {loadingItem === '' &&
          <Typography gutterBottom variant="h4" component="h2" color="primary">
            {t('items.items-search-vendor', { 'vendor': vendorName }) }
          </Typography>
        }
        {!onlyBundles &&
          <ItemsList vendorId={Number(vendorId)}/>
        }
      </Container>
    </DocumentMeta>
  )
}

export default ItemSearch;
