// React.
import React from 'react';

// Components.
//import OrderSearch from '../orders/OrderSearch';
import OrdersToBeDeliveredList from '../orders/OrdersToBeDeliveredList';

// Styles
import useStyles from './OrdersStyles';

const OrdersToBeDelivered = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <OrdersToBeDeliveredList />
    </div>
  )
}

export default OrdersToBeDelivered;
