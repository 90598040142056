import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(3.75),
    },
    paper: {
      padding: theme.spacing(2.5),
      width: theme.spacing(100),
      marginBottom: theme.spacing(3.75),
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: theme.spacing(13),
      }
    }
  })
);