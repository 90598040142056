// React.
import React, { Fragment, useState, useEffect } from 'react';
import ShowMoreText from "react-show-more-text";

// Material UI.
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';

// Components.
import TextMaskPhone from '../utils/TextMaskPhone';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { updateVendor } from "../../../actions/vendorActions/VendorActions";
import { RootStore } from "../../../Store";

// Translations.
import { useTranslation } from 'react-i18next';

// Global Types.
import { VendorListComplete } from '../models/ModelTypes';
import { AddressType } from '../models/ModelTypes';

// Styles.
import useStyles from './VendorEditStyles'

type SwitchCategoryType = {
  enabled: boolean,
  name: string
};

interface VendorEditProps {
  vendor: VendorListComplete;
  isExpanded: boolean;
};

const VendorEdit = ({ vendor }: VendorEditProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();

  // Redux state.
  const reduxState = useSelector((state: RootStore) => state.vendors);
  const loading = reduxState.loading;
  const reduxItemState = useSelector((state: RootStore) => state.itemCategories);
  const category = reduxItemState.categories;

  // Define local state.
  const [vendorState, setVendorState] = useState<VendorListComplete>(vendor);
  const [vendorStateAddress, setVendorStateAddress] = useState<AddressType>(vendor.address);
  const [editing, setEditing] = useState<boolean | false>(false);
  const [errorArray, setErrorArray] = useState<Array<string>>([]);
  const [itemCategoryNames, setItemCategoryNames] = useState<Array<string>>([]);
  const [switchState, setSwitchState] = useState<Array<SwitchCategoryType>>([]);
  const [switchStateTemp, setSwitchStateTemp] = useState<Array<SwitchCategoryType>>([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (category !== undefined) {
      var newCategoryArray: Array<string> = [];
      category.map((item) => (newCategoryArray.push(item.name)));
      setItemCategoryNames(newCategoryArray);

      // Set the default values.
      var defaultItemCategories: Array<SwitchCategoryType> = getDefaultItemCategoryValues();
      setSwitchState(defaultItemCategories);
      setSwitchStateTemp(defaultItemCategories);
    }
    // eslint-disable-next-line
  }, [category]);

  const getDefaultItemCategoryValues = () => {
    var defaultItemCategoriesValues: Array<SwitchCategoryType> = [];
    category.forEach((category) => {
      var enabled = false;
      vendor.vendorCategories?.forEach((item) => {
        if (item.id.category === category.name) enabled = true;
      });
      defaultItemCategoriesValues.push({
        enabled: enabled,
        name: category.name
      });
    });

    return defaultItemCategoriesValues;
  };

  const setSwitchStateChange = (event: any, index: number, name: string) => {
    var newArray: Array<SwitchCategoryType> = [...switchState];
    newArray[index] = { enabled: event.target.checked, name: name };

    // Save the changes on a temporal state.
    setSwitchStateTemp(newArray);

    // Open confirmation modal.
    handleOpenModal();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSwitchStateTemp([...switchState]);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  const processSwitchState = () => {
    setSwitchState([...switchStateTemp]);
    setOpenModal(false);
  };

  // Handles the field changes to update the state.
  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    // Use the HTML name atribute to know what property update.
    const name = event.target.name as keyof typeof vendorState;
    var inputValue = String(event.target.value);

    setVendorState({
      ...vendorState,
      [name]: inputValue,
    });

    // Validate and add errors.
    const errors = [...errorArray];
    const nameString = '' + [name]
    const isPhoneFormat = /^[0-9\s]+$/.test(inputValue);
    if (inputValue === '' || (nameString === 'phoneNumber' && !isPhoneFormat)) {
      errors.push(nameString);
      setErrorArray(errors);
    } else {
      const newErrors = errors.filter(function (value) {
        return value !== nameString;
      });
      setErrorArray(newErrors);
    }
  };

  const handleChangeAddress = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    // Use the HTML name atribute to know what property update.
    const name = event.target.name as keyof typeof vendorStateAddress;
    var inputValue = String(event.target.value);

    setVendorStateAddress({
      ...vendorStateAddress,
      [name]: inputValue,
    });

    // Validate and add errors.
    const errors = [...errorArray];
    const nameString = '' + [name]
    if (inputValue === '') {
      errors.push(nameString);
      setErrorArray(errors);
    } else {
      const newErrors = errors.filter(function (value) {
        return value !== nameString;
      });
      setErrorArray(newErrors);
    }
  };
  const handleChangeValidation = (inputName: string) => {
    var result = false;
    errorArray.forEach((key) => {
      if (key === inputName) {
        result = true;
      }
    });

    return result;
  };

  const validateFields = () => {
    var result = true;
    const errors = [...errorArray];
    if (vendorState.name === '') {
      errors.push('name');
      result = false;
    }
    const isPhoneFormat = /^[0-9\s]+$/.test(vendorState.phoneNumber);
    if (!vendorState.phoneNumber || !isPhoneFormat) {
      errors.push('phoneNumber');
      result = false;
    }
    if (vendorState.categoriesArray === []) {
      errors.push('categoriesArray');
      result = false;
    }
    if (!vendorStateAddress.canton) {
      errors.push('canton');
      result = false;
    }
    if (!vendorStateAddress.district) {
      errors.push('district');
      result = false;
    }
    if (!vendorStateAddress.fullAddress) {
      errors.push('fullAddress');
      result = false;
    }
    if (!vendorStateAddress.postalCode) {
      errors.push('postalCode');
      result = false;
    }
    if (!vendorStateAddress.province) {
      errors.push('province');
      result = false;
    }

    setErrorArray(errors);

    return result;
  };

  const editOnClick = () => {
    setEditing(true);
  }

  const cancelOnClick = () => {
    setEditing(false);
    setVendorStateAddress(vendor.address);

    // Set user state to original values.
    setVendorState(vendor);
    setErrorArray([]);

    // Set the default values.
    var defaultItemCategoriesValues: Array<SwitchCategoryType> = getDefaultItemCategoryValues();
    setSwitchState(defaultItemCategoriesValues);
    setSwitchStateTemp(defaultItemCategoriesValues);
  };

  const getCategoriesToAdd = () => {
    var categories: string = '';
    switchState.forEach((iCategory, index) => {
      if (iCategory.enabled) {
        if (categories !== '') {
          categories += ',' + iCategory.name;
        } else {
          categories += iCategory.name;
        }
      }
    });
    return categories;
  };

  const getCategoriesToDelete = () => {
    var categories: string = '';
    switchState.forEach((iCategory, index) => {
      if (!iCategory.enabled) {
        if (categories !== '') {
          categories += ',' + iCategory.name;
        } else {
          categories += iCategory.name;
        }
      }
    });
    return categories;
  };

  const saveOnClick = () => {
    // Save the bundle on the BE.
    const validation = validateFields();
    
    if (errorArray.length === 0 && validation) {
      let requestObject = {
        id: vendorState.id,
        isAdminOnly: vendorState.isAdminOnly,
        name: vendorState.name,
        phoneNumber: vendorState.phoneNumber,
        sequence: vendorState.sequence,
        vendorCategoriesToAdd: getCategoriesToAdd(),
        vendorCategoriesToDelete: getCategoriesToDelete(),
        address: {
          canton: vendorStateAddress.canton,
          district: vendorStateAddress.district,
          province: vendorStateAddress.province,
          postalCode: vendorStateAddress.postalCode,
          fullAddress: vendorStateAddress.fullAddress,
        },
      };
      dispatch(updateVendor(requestObject));
      setEditing(false);
    }
  };
  const handleChangeCheckBox = () => {
    setVendorState({
      ...vendorState,
      isAdminOnly: !vendorState.isAdminOnly
    });
  }

  const getCurrentCategoryState = (category:string) => {
    const currentState = switchState.filter((c) => c.name === category);
    return currentState.length > 0 ? currentState[0].enabled : false;
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <form className={classes.formGrid}>
            <FormControl className={`${classes.formControl} ${classes.fullWidth}`}>
              <FormControlLabel control={<Checkbox disabled={!editing} defaultChecked={vendorState.isAdminOnly} onChange={handleChangeCheckBox} name="isAdminOnly" color="primary" />} label={t('vendors.checkbox-admin-only')} />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Typography variant="caption">{t('vendors.vendor-name')}</Typography>
              <TextField
                className={classes.formField}
                id="v-name" name="name"
                value={vendorState.name}
                onChange={handleChange}
                disabled={!editing}
                autoFocus
                error={handleChangeValidation('name')}
                helperText={handleChangeValidation('name') ? t('vendors.field-required') : ''}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Typography variant="caption">{t('vendors.vendor-phone-number')}</Typography>
              <Input
                id="v-phoneNumber" name="phoneNumber"
                className={classes.formField}
                value={vendorState.phoneNumber}
                onChange={handleChange}
                disabled={!editing}
                error={handleChangeValidation('phoneNumber')}
                inputComponent={TextMaskPhone as any}
                color="primary"
                fullWidth
              />
              {handleChangeValidation('phoneNumber') &&
                <Typography className={classes.inputError}>{t('vendors.phone-error')}</Typography>
              }
            </FormControl>
            <FormControl component="fieldset">
              <Typography variant="caption">{t('vendors.items-type')}</Typography>
              <FormHelperText>{t('vendors.items-type-descrip')}</FormHelperText>
              <FormGroup>
                {itemCategoryNames.map((item, index) => (
                  <FormControlLabel key={index}
                    control={
                      <Switch
                        checked={getCurrentCategoryState(item)}
                        onChange={(e) => setSwitchStateChange(e, index, item)}
                        name={item}
                        disabled={!editing}
                      />}
                    label={item}
                  />
                ))}
              </FormGroup>
            </FormControl>
            <Typography className='addressTitle' variant="caption">{t('vendors.address-title')}</Typography>
            <div className={classes.addressWrapper}>
              <FormControl className={classes.formControl}>
                <Typography variant="caption">{t('vendors.vendor-province')}</Typography>
                <TextField
                  className={classes.formField}
                  id="v-province" name="province"
                  value={vendorStateAddress.province}
                  onChange={handleChangeAddress}
                  disabled={!editing}
                  error={handleChangeValidation('province')}
                  helperText={handleChangeValidation('province') ? t('vendors.field-required') : ''}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <Typography variant="caption">{t('vendors.vendor-canton')}</Typography>
                <TextField
                  className={classes.formField}
                  id="v-canton" name="canton"
                  value={vendorStateAddress.canton}
                  onChange={handleChangeAddress}
                  disabled={!editing}
                  error={handleChangeValidation('canton')}
                  helperText={handleChangeValidation('canton') ? t('vendors.field-required') : ''}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <Typography variant="caption">{t('vendors.vendor-district')}</Typography>
                <TextField
                  className={classes.formField}
                  id="v-district" name="district"
                  value={vendorStateAddress.district}
                  onChange={handleChangeAddress}
                  disabled={!editing}
                  error={handleChangeValidation('district')}
                  helperText={handleChangeValidation('district') ? t('vendors.field-required') : ''}
                />
              </FormControl>
              <FormControl className={classes.formControlFullAddress}>
                <Typography variant="caption">{t('vendors.vendor-full-address')}</Typography>
                {editing ?
                  <TextField
                    className={classes.formField}
                    id="v-fullAddress" name="fullAddress"
                    multiline
                    maxRows={8}
                    value={vendorStateAddress.fullAddress}
                    onChange={handleChangeAddress}
                    disabled={!editing}
                    error={handleChangeValidation('fullAddress')}
                    helperText={handleChangeValidation('fullAddress') ? t('vendors.field-required') : ''}
                  /> :
                  <Typography className={classes.showMore} color="textSecondary">
                    <ShowMoreText
                      lines={3}
                      more={t('items.see-more')}
                      less={false}
                      expanded={false}
                      truncatedEndingComponent={"... "}
                    >
                      {vendorStateAddress.fullAddress}
                    </ShowMoreText>
                  </Typography>
                }
              </FormControl>
              <FormControl className={classes.formControl}>
                <Typography variant="caption">{t('vendors.vendor-postal-code')}</Typography>
                <TextField
                  className={classes.formField}
                  id="v-postalCode" name="postalCode"
                  value={vendorStateAddress.postalCode}
                  onChange={handleChangeAddress}
                  disabled={!editing}
                  inputProps={{ maxLength: 5 }}
                  error={handleChangeValidation('postalCode')}
                  helperText={handleChangeValidation('postalCode') ? t('vendors.field-required') : ''}
                />
              </FormControl>
            </div>
          </form>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <FormControl className={classes.formControlButtons}>
            {editing && (
              <div className={classes.actionsWrapper}>
                {loading === 'USER_UPDATE' && <CircularProgress />}
                <Button variant="text" color="primary" onClick={saveOnClick} disabled={errorArray.length !== 0}>
                  {t('vendors.save')}
                </Button>
                <Button variant="contained" color="secondary" onClick={cancelOnClick}>
                  {t('vendors.cancel')}
                </Button>
                <Button variant="contained" color="primary" disabled>
                  {t('vendors.edit-vendor')}
                </Button>
              </div>
            )}
            {!editing && (
              <div className={classes.actionsWrapper}>
                <Button variant="contained" color="primary" onClick={editOnClick}>
                  {t('vendors.edit-vendor')}
                </Button>
              </div>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="Confirm Modal"
      >
        <Paper elevation={3} className={classes.modalPaper}>
          <IconButton className={classes.closeModal} aria-label="close modal" component="span" onClick={() => handleCloseModal()}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" color="primary">{t('vendors.confirmation')}</Typography>
          <Typography>{t('vendors.confirmation-text')}</Typography>
          <Button variant="contained" color="primary" onClick={() => handleCloseModal()}> {t('vendors.cancel')} </Button>
          <Button variant="contained" color="secondary" onClick={() => processSwitchState()}> {t('vendors.yes')} </Button>
        </Paper>
      </Modal>
    </Fragment>
  )
}

export default VendorEdit;
