/**
 *****************************************
 *********** Dispatch Types **************
 *****************************************
*/
export const PRIZE_LOADING                  = "PRIZE_LOADING";
export const PRIZE_FAIL                     = "PRIZE_FAIL";
export const PRIZE_EVENT_DETAIL             = "PRIZE_EVENT_DETAIL";
export const PRIZE_EVENT_PLAYER_PRIZES      = "PRIZE_EVENT_PLAYER_PRIZES";
export const ADD_PRIZE_EVENT                = "ADD_PRIZE_EVENT";
export const UPDATE_PRIZE_EVENT             = "UPDATE_PRIZE_EVENT";
export const DELETE_PRIZE_EVENT             = "DELETE_PRIZE_EVENT";
export const MANAGE_PRIZE_TO_EVENT          = "MANAGE_PRIZE_TO_EVENT";
export const PRIZE_ITEMS_FOR_PRIZE          = "PRIZE_ITEMS_FOR_PRIZE";
export const EVENT_REGISTER_PRIZE_TO_PLAYER = "EVENT_REGISTER_PRIZE_TO_PLAYER";
export const EVENT_GET_ALL_PRIZES           = "EVENT_GET_ALL_PRIZES";
export const EVENT_GET_ALL_PRIZES_CATEGORIES = "EVENT_GET_ALL_PRIZES_CATEGORIES";

/**
 *****************************************
 ************* Object Types **************
 *****************************************
*/
export type PrizeType = {
  itemList?: ItemType[],
  prizeName: string,
  realQuantity: number,
  suggestedQuantity: number,
}

export type itemPrizeType={
  id:number,
  name:string,
  modifier:number,
  itemTypeId:number,
  itemTypeName:string,
  labelValuesId:number,
  promo:boolean,
}

export type PrizeReportData={
  prizeName: string,
  realTotalQuantity: number,
  suggestedTotalQuantity: number,
  difference: number
}

export type reportData = {
  prizesReport: [PrizeReportData],
  pricingDifference:number
}

export type AwardType = {
  anonymousPlayer: boolean,
  coinsToRedeem: number,
  playerId: number,
  playerName: string,
  position: number,
  prizeList: PrizeType[],
  redeemCoins: boolean
}

export type BaseAwards = {
  customQuantity: number,
  prizeId: number,
  prizeName: string
}

export type TotalAwards = {
  name: string,
  realQuantity: number,
  suggestedQuantity: number
}

export type Awards = {
  awardsLists: AwardType[],
  baseAwardsData: BaseAwards[],
  totalAwardsData: TotalAwards[]
}

export type ItemType = {
  itemId: number,
  itemName: string,
  price: number,
  quantity?: number,
  totalQuantity?: number,
  vendorName?: string,
  prizeId?: number
}

export type PrizeCategory = {
  itemTypeName:string,
  labelId:number,
  values:PrizeCategoryValues[]
}

export type PrizeCategoryValues={
  id:number,
  value:string
}

/**
 *****************************************
 *********** Interfaces Types ************
 *****************************************
*/
export interface PrizeLoading {
  type: typeof PRIZE_LOADING
  payload: string
}

export interface PrizeFail {
  type: typeof PRIZE_FAIL,
  payload: string
}

export interface GetPrizeEventDetailData {
	type: typeof PRIZE_EVENT_DETAIL;
	payload: Awards;
}

export interface GetPrizeEventPlayerPrizesData {
	type: typeof PRIZE_EVENT_PLAYER_PRIZES;
	payload: PrizeType[];
}

export interface getAllPrizesData{
  type: typeof EVENT_GET_ALL_PRIZES;
  payload: itemPrizeType[];
}

export interface getAllPrizesCategoriesData{
  type: typeof EVENT_GET_ALL_PRIZES_CATEGORIES;
  payload: PrizeCategory[];
}

export interface PrizeAdd {
  type: typeof ADD_PRIZE_EVENT;
  payload: string
}

export interface PrizeUpdate {
  type: typeof UPDATE_PRIZE_EVENT;
  payload: string
}

export interface PrizeDelete {
  type: typeof DELETE_PRIZE_EVENT,
  payload: string
}

export interface ManagePrizeToEvent {
  type: typeof MANAGE_PRIZE_TO_EVENT,
  payload: string
}

export interface GetItemsForPrize {
  type: typeof PRIZE_ITEMS_FOR_PRIZE,
  payload: ItemType[]
}

export interface RegisterEventPrizeToPlayer {
  type: typeof EVENT_REGISTER_PRIZE_TO_PLAYER,
  payload: string
}


// Export types.
export type PrizeDispatchTypes = PrizeLoading 
  | PrizeFail 
  | GetPrizeEventDetailData 
  | GetPrizeEventPlayerPrizesData
  | PrizeAdd 
  | PrizeUpdate 
  | PrizeDelete 
  | ManagePrizeToEvent 
  | GetItemsForPrize 
  | RegisterEventPrizeToPlayer
  | getAllPrizesData
  | getAllPrizesCategoriesData;
