/**
 *****************************************
 *********** Dispatch Types **************
 *****************************************
 */
export const ITEM_TYPE_LOADING = "ITEM_TYPE_LOADING";
export const ITEM_TYPE_FAIL = "ITEM_TYPE_FAIL";
export const ITEM_TYPE_LIST = "ITEM_TYPE_LIST";
export const ITEM_TYPE_PUT = "ITEM_TYPE_PUT";
export const ITEM_TYPE_CREATE = "ITEM_TYPE_CREATE";
export const ITEM_TYPE_UPDATE_PER_LABEL_VALUE = "ITEM_TYPE_UPDATE_PER_LABEL_VALUE";
export const ITEM_TYPE_REFRESH = "ITEM_TYPE_REFRESH";
export const ITEM_TYPE_FILTERS = "ITEM_TYPE_FILTERS";

/**
 *****************************************
 ************* Object Types **************
 *****************************************
 */
export type ItemType = {
  id: number,
  taxPercentage: number,
  name: string,
  image_url: string,
  description: string,
  isDeleted: boolean,
  isPublished: boolean
};

export type ItemTypeList = {
  first: boolean
  last: boolean
  number: number
  size: number
  totalPages: number
  categories: ItemType[]
};

/**
 *****************************************
 *********** Interfaces Types ************
 *****************************************
 */
export interface ItemTypeLoading {
  type: typeof ITEM_TYPE_LOADING
  payload: string
};

export interface ItemTypeFail {
  type: typeof ITEM_TYPE_FAIL
  payload: string
};

export interface ItemTypeListC {
  type: typeof ITEM_TYPE_LIST,
  payload: ItemTypeList
};

export interface ItemTypeUpdate {
  type: typeof ITEM_TYPE_PUT,
  payload: string
};

export interface ItemTypeCreate {
  type: typeof ITEM_TYPE_CREATE,
  payload: string
};

export interface ItemTypeUpdatePerLabelValue {
  type: typeof ITEM_TYPE_UPDATE_PER_LABEL_VALUE,
  payload: string
};

export interface ItemTypeRefresh {
  type: typeof ITEM_TYPE_REFRESH,
  payload: boolean
};

export interface ItemTypeFilters {
  type: typeof ITEM_TYPE_FILTERS,
  payload: object
};

// Export types.
export type ItemTypeDispatchTypes = ItemTypeLoading | ItemTypeFail | ItemTypeListC | ItemTypeUpdate | ItemTypeCreate | ItemTypeUpdatePerLabelValue | ItemTypeRefresh | ItemTypeFilters;
