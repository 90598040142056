// react.
import React from 'react';

// Material UI.
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

// Styles.
import { useStyles } from './FooterStyles';
import logoImg from '../../../logo-completo.png';
import fbLogo from '../../../images/facebook.png';
import insLogo from '../../../images/instagram.png';
import ttLogo from '../../../images/tiktok.png';
import ytLogo from '../../../images/youtube.png';
import visaLogo from '../../../images/visa.png';
import mcLogo from '../../../images/mastercard.png';
import wpLogo from '../../../images/whatsapp.png';

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.root}>
        <Grid item xs={12} md={2}>
          <img src={ logoImg } alt="StoreFront Logo" width="150"/>
        </Grid>
        <Grid item xs={12} md={5} className="align-top">
          <p>OMEGA Card Game Store © { new Date().getFullYear() }. Reservados todos los derechos.<br/>
          Powered by StoreFront.</p>
          <p>
            Costa Rica<br/>
            Heredia: <a href="tel:2262-3673">2262-3673</a><br/>
            Alajuela: <a href="tel:2442-6101">2442-6101</a><br/>
            Cartago: <a href="tel:2245-7182">2245-7182</a><br/>
            <a href="mailto:info@omegaenlinea.com">info@omegaenlinea.com</a>
          </p>
          <p className="support-link">
            <span>Soporte en Línea: </span>
            <a href="https://api.whatsapp.com/send/?phone=50662319746&lang=es" target="_blank" rel="noreferrer" aria-label="Whatsapp Link">
            <img src={wpLogo} alt="MasterCard Logo" />
            </a>
          </p>
        </Grid>
        <Grid item xs={12} md={3} className="align-top">
          <p>
            <a href="/about-us">Acerca de Nosotros</a><br/>
            <a href="/use-terms">Términos y Condiciones</a><br/>
          </p>
          <p>Verified by</p>
          <p className="verified-by">
            <span className="logo-img"><img src={visaLogo} alt="VISA Logo" /></span>
            <span className="logo-img"><img src={mcLogo} alt="MasterCard Logo" /></span>
          </p>
        </Grid>
        <Grid item xs={12} md={3} className="align-top">
          <p className="social-links">
            <a href="https://www.facebook.com/OmegaCardGamesStore" target="_blank" rel="noreferrer" aria-label="Facebook Link">
              <img src={fbLogo} alt="Facebook Logo" />
            </a>
            <a href="https://www.instagram.com/omega_card_games_store/" target="_blank" rel="noreferrer" aria-label="Instagram Link">
              <img src={insLogo} alt="Instagram Logo" />
            </a>
            <span>
              <img src={ytLogo} alt="YouTube Logo" />
              ¡Pronto!
            </span>
            <span>
              <img src={ttLogo} alt="TikTok Logo" />
              ¡Pronto!
            </span>
          </p>
        </Grid>
      </Container>
    </div>
  )
}

export default Footer;
