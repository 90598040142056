import React, { ReactNode } from 'react';
import useStyles from './modalStyles';  

interface ModalProps {
  children: ReactNode;
  modalClass?: string;
  modalContentClass?: string;
}

const Modal: React.FC<ModalProps> = ({ children, modalClass,modalContentClass }) => {

  const classes = useStyles();
  return (
    <div className={`${classes.modal} ${modalClass}`}>
      <div className={`${classes.modalContent} ${modalContentClass}`}>
        {children}
      </div>
    </div>
  );
}

export default Modal;
