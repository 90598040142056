// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      padding: theme.spacing(3, 4, 2),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3, 2, 2),
      },
      '& form': {
        padding: '0',
        width: '100%'
      },
      '& .form-fields': {
        width: '100%',
        '& .MuiFormControl-root': {
          marginBottom: theme.spacing(2),
        }
      },
      '& .MuiTypography-h5': {
        marginBottom: theme.spacing(2),
      },
      '& a': {
        color: theme.palette.primary.main
      },
      '& .MuiButton-text': {
        marginLeft: '0 !important'
      }
    },
    formText: {
      margin: theme.spacing(4, 'auto'),
      maxWidth: '80%',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      },
    },
    formControls: {
      display: 'flex',
      justifyContent: 'center',
      '& button': {
        marginLeft: '15px',
        [theme.breakpoints.down('xs')]: {
          marginLeft: 0
        },
      }
    },
    modalPaper: {
      padding: theme.spacing(2, 4),
      position: 'absolute',
      width: '600px',
      height: '268px',
      top: 'calc(50% - 134px)',
      left: 'calc(50% - 300px)',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2, 1),
        width: '90%',
        left: '5%'
      },
    },
    modalField: {
      margin: theme.spacing(2, 0)
    },
    modalButton: {
      marginTop: theme.spacing(2),
      float: 'right'
    },
    btnNew: {
      margin:theme.spacing(5, 0),
      textAlign: 'center',
      '& .MuiButton-root': {
        margin: theme.spacing(0, 1)
      }
    },
    socialLogin: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(5),
      '& > div': {
        marginRight: theme.spacing(1),
        float: 'right'
      }
    }
  }),
);

export default useStyles;
