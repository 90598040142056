// React.
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

//  JWT decode token.
import jwtDecode from 'jwt-decode';


interface SellerRouteProps extends RouteProps{
  component:any;
  strict?: boolean;
  rest?:any
}

const SellerRoute: React.SFC<SellerRouteProps> = ({ component: Component, strict, authenticated, ...rest }:any) => (
 <Route
  {...rest}
    render={(props) => {
      const authToken = localStorage.token;
      if (authToken !== undefined && authToken !== '') {
        const decodedToken:any = jwtDecode(authToken);
        if (decodedToken.auth.includes('ROLE_SELLER') || decodedToken.auth.includes('ROLE_SUPER_SELLER') || decodedToken.auth.includes('ROLE_ADMIN')) {
          if (strict) {
            if (!decodedToken.auth.includes('ASSOCIATE') && !decodedToken.auth.includes('ROLE_ADMIN_SINGLES')) {
              return <Component {...props} />
            } else {
              return <Redirect to={{ pathname: '/error-403', state: { status: 403 } }} />;
            }
          } else {
            return <Component {...props} />
          }
        } else {
          return <Redirect to={{ pathname: '/error-403', state: { status: 403 }}}  />;
        }
      } else {
        return <Redirect to={{ pathname: '/user/login', state: { from: props.location }}}  />;
      }
    }
  }
  />
);

export default SellerRoute;