// React.
import {  useEffect, useState } from 'react';

// Material UI.
import { Checkbox, CircularProgress, Grid, Paper, Typography } from "@material-ui/core";

// Styles.
import useStyles from './AddParticipationPrizeStyle';

// Translations.
import { useTranslation } from 'react-i18next';

// Redux.
import { useSelector } from "react-redux";
import { RootStore } from "../../../Store";

//Model.
import OrderBoosterSearchPrize from './OrderBoosterSearchPrize';

const AddParticipationPrize = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');

  // Redux state.
  const reduxPrizeState = useSelector((state: RootStore) => state.prize);
  const { loading } = reduxPrizeState;
  const reduxEventState = useSelector((state: RootStore) => state.events);

  //Local State.
  const [checked, setChecked] = useState<boolean|undefined>(reduxEventState.eventDetail?.havePrizeParticipation);

  useEffect(()=>{
    setChecked(reduxEventState.eventDetail?.havePrizeParticipation)
    // eslint-disable-next-line
  },[reduxEventState.eventDetail?.havePrizeParticipation])


  const onCheckboxChange = (event:any) =>{
    setChecked(event.target.checked);
  }
  return (
    <>
      <div className={ classes.root }>
        <Paper elevation={3} square={false} variant="elevation">
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <div className={classes.title}>
              <Typography variant="h5" color="primary" className={ classes.sectionTitle }>
                { t('tournament.add-participation-items') } 
                {loading === 'PRIZE_EVENT_DETAIL' || loading === 'MANAGE_PRIZE_TO_EVENT' ? (
                  <CircularProgress />
                ):(
                  <Checkbox
               checked={checked}
               onChange={onCheckboxChange}
               color="primary"
               inputProps={{ 'aria-label': 'checkbox' }}
               style={{padding:'10px', bottom:'2px'}}
               disabled={reduxEventState.eventDetail?.havePrizeParticipation}
                />
                )}
              </Typography>
              
              </div>
              {checked?
              <OrderBoosterSearchPrize/>:''
              }
            </Grid>
          </Grid>
        </Paper>
      </div>  
    </>
  );
}
export default AddParticipationPrize;