// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formItemWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      margin: theme.spacing(4, 0)
    },
    formItem: {
      width: '50%',
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      '& .MuiTextField-root': {
        width: '100%',
        marginBottom: theme.spacing(2),
      },
      '& .ck-editor': {
        width: '100%',
        marginBottom: theme.spacing(2),
      },
      '& .ck-content': {
        height: '160px'
      },
      '& .MuiButton-root': {
        marginRight: theme.spacing(1)
      }
    },
    right: {
      float: 'right'
    }
  }),
);

export default useStyles;
