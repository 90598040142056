import {
  USER_FAIL,
  USER_LOADING,
  USER_PUT,
  UPDATE_USER_PASSWORD_TEMP,
  USER_LIST,
  USER_REFRESH,
  USER_SET_FILTER,
  ADD_USER,
  DELETE_USER,
  UPDATE_USER_POINTS,
  UserDispatchTypes,
  UserList,
} from "../actions/userActions/UserActionsTypes";

// Default state interface.
interface DefaultStateI {
  loading: string,
  refresh?: boolean,
  userFilters?: object,
  userList?: UserList,
  actionStatus?: any
}

// Default state.
const defaultState: DefaultStateI = {
  loading: '',
};

const userReducer = (state: DefaultStateI = defaultState, action: UserDispatchTypes) : DefaultStateI => {
  switch (action.type) {
    case USER_FAIL:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'error'}
      }
    case USER_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case ADD_USER:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'success'}
      }
    case DELETE_USER:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'success'}
      }
    case USER_PUT:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'success'}
      }
    case UPDATE_USER_PASSWORD_TEMP:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'success'}
      }
    case USER_LIST:
      return {
        ...state,
        loading: '',
        actionStatus: undefined,
        userList: action.payload
      }
    case USER_REFRESH:
      return {
        ...state,
        refresh: action.payload
      }
    case USER_SET_FILTER:
      return {
        ...state,
        userFilters: action.payload
      }
    case UPDATE_USER_POINTS:
      return {
        ...state,
        loading: '',
        actionStatus: { menssage: action.payload, status: 'success' }
      }
    default:
      return state
  }
};


export default userReducer;
