import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root:{},
    modal:{
        position:'fixed',
        top:'0',
        left:'0',
        width:'100%',
        height:'100%',
        zIndex:1000,
        backgroundColor:'rgba(0, 0, 0, 0.5)',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    modalContent:{
        position:'relative',
        width:'400px',
        height:'auto',
        minHeight:'200px',
        zIndex:0,
        backgroundColor:'#fff',
        padding:'20px',
        borderRadius:'5px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-around',
        paddingTop:'0px'
    }
}))

export default useStyles;