// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      top: '-38px',
      backgroundColor: 'transparent',
      maxWidth: '1550px'
    }
  }),
);

export default useStyles;
