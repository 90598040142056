/**
 *****************************************
 *********** Dispatch Types **************
 *****************************************
 */
export const ITEM_CATEGORY_LOADING = "ITEM_CATEGORY_LOADING";
export const ITEM_CATEGORY_FAIL = "ITEM_CATEGORY_FAIL";
export const ITEM_CATEGORY_LIST = "ITEM_CATEGORY_LIST";

 /**
  *****************************************
  ************* Object Types **************
  *****************************************
  */
 export type CategoryTypeList = {
   name: string
 };
 /**
  *****************************************
  *********** Interfaces Types ************
  *****************************************
  */
 export interface ItemCategoryLoading {
   type: typeof ITEM_CATEGORY_LOADING
   payload: string
 };

 export interface ItemCategoryFail {
   type: typeof ITEM_CATEGORY_FAIL
 };

 export interface ItemCategoryList {
   type: typeof ITEM_CATEGORY_LIST,
   payload: CategoryTypeList[]
 };

 // Export types.
 export type ItemCategoryDispatchTypes = ItemCategoryLoading | ItemCategoryFail | ItemCategoryList;
