// React.
import React, {useEffect, useState, Fragment} from 'react';
import { useHistory } from 'react-router-dom';

// Translations.
import { useTranslation } from 'react-i18next';

// Material UI.
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import Link from '@material-ui/core/Link';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import StorefrontIcon from '@material-ui/icons/Storefront';
import SavingsIcon from '@mui/icons-material/Savings';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';


// Components.
import Logo from '../utils/Logo';
import NavBar from './NavBar';
import ImageAvatar from '../../admin/utils/ImageAvatar';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getUserSummary } from '../../../actions/authActions/AuthActions';
import { setNavFilters } from '../../../actions/navActions/NavActions';
import { getItemSearchNames } from '../../../actions/itemActions/ItemActions';
import {RootStore} from "../../../Store";


// Styles.
import { useStyles } from './HeaderStyles';
import ImageAvatarMobile from '../../admin/utils/ImageAvatarMobile';
import { getProjectConfig } from '../../../getProjectConfig';

export type autocompleteObj = {
  value: string,
  labelValueId: number,
  isMoreLink?: boolean
};

const Header = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [t] = useTranslation('global');
  let history = useHistory();

  // Redux state.
  const reduxState = useSelector((state: RootStore) => state.auth);
  const userSummary = reduxState.userSummary;

  
  const userState = useSelector((state: RootStore) => state.user);
  const userActionStatus = userState.actionStatus;
  const cartState = useSelector((state: RootStore) => state.cart);
  const cartStatus = cartState.actionStatus
  const itemsState = useSelector((state: RootStore) => state.item);
  const { itemsSearchNames } = itemsState;

  // Get URL parameter.
  const params = new URLSearchParams(window.location.search);
  let key = params.get('key');

  // Local state.
  const [cartQuantity, setCartQuantity] = useState<string>('');
  const [openDropdown, setOpenDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<autocompleteObj[]>([]);
  const [autocompleteValue, setAutocompleteValue] = useState<autocompleteObj>(key ? { value: key, labelValueId: 0 } : { value: '', labelValueId: 0 });
  const [selectedItem, setSelectedItem] = useState<autocompleteObj | null>(key ? { value: key, labelValueId: 0 } : { value: '', labelValueId: 0 });
  const [width, setWidth] = useState(window.innerWidth);
  const wishlist = useSelector((state: RootStore) => state.wishlist);


  // 3 minutes
  const MINUTE_MS = 180000;
  useEffect(() => {
    // Check if the user is already logged in.
    const authToken = localStorage.token;
    if (authToken !== undefined && authToken !== '') {
      dispatch(getUserSummary());
      }

    // Re-call the user information every 3 minutes.
    const interval = setInterval(() => {
      if (authToken !== undefined && authToken !== '') {
        dispatch(getUserSummary());
      }
    }, MINUTE_MS);
    return () => clearInterval(interval);
    
    // eslint-disable-next-line
  }, [cartStatus, userActionStatus, wishlist]);

  useEffect(() => {
    if (userSummary !== undefined) {
      setCartQuantity(`${userSummary.totalItemsInCart}`);
    } else {
      const anonymousCart = localStorage.anonymousCart !== undefined ? JSON.parse(localStorage.anonymousCart) : [];
      const anonymousBundleCart = localStorage.anonymousBundleCart !== undefined ? JSON.parse(localStorage.anonymousBundleCart) : [];
      if (localStorage.anonymousCart !== undefined || localStorage.anonymousBundleCart !== undefined) {
        setCartQuantity(getCartItemsQuantityAnonimous(anonymousCart, anonymousBundleCart));
      }
    }

    // eslint-disable-next-line
  }, [userSummary, cartStatus]);

  // For Search Autocomplete.
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setLoading(true);

      if (autocompleteValue && autocompleteValue.value !== '') {
        let params = { name: autocompleteValue.value };
        dispatch(getItemSearchNames(params));
      } else {
        setLoading(false);
      }

    }, 600)

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line
  }, [autocompleteValue]);

  useEffect(() => {
    if (itemsSearchNames !== undefined) {
      var optionsList: Array<autocompleteObj> = [];
      for (var i = 0; i < itemsSearchNames.itemsName.length; i++) {
        let item = itemsSearchNames.itemsName[i];
        optionsList.push({
          value: `${item.name}${(item.labelValue) ? ' (' + item.labelValue + ')' : ''}`,
          labelValueId: item.labelValueId
        }); 
      }

      if (itemsSearchNames.moreItems) {
        optionsList.push({
          value: t('items.view-more'),
          labelValueId: 0,
          isMoreLink: true
        }); 
      }

      // Update the state.
      setOptions(optionsList);
    }

    setLoading(false);

    // eslint-disable-next-line
  }, [itemsSearchNames]);

  //Getting the current width of the screen

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  //SETTING THE HEADER ICON SIZES
  const iconSize=()=>{
    if(width<600){
      return 'large'
    }else{
      return 'medium'
    }
  }

  useEffect(() => {
    if (selectedItem !== null && selectedItem.value !== '') {
      goToSearch();
    }

    // eslint-disable-next-line
  }, [selectedItem]);

  const getCartItemsQuantityAnonimous = (cart:any, bundleCart:any) => {
    var total = 0;
    cart.forEach((cartItem:any) => 
      total += cartItem.quantity
    );
    bundleCart.forEach((cartItem:any) => 
      total += cartItem.quantity
    );
    return String(total);
  };

  const username = userSummary !== undefined ? userSummary.firstName : '';
  const userGreeting = t('header.greetings') + ', ' + username;

  const autocompleteChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    // Set loading status.
    setLoading(true);

    // Clear the options state.
    setOptions([]);

    // Set the new autocomplete value.
    setAutocompleteValue({
      value: String(event.target.value),
      labelValueId: 0
    });
  };

  const goToSearch = () => {
    if (selectedItem !== null && selectedItem.value !== '') {
      if (selectedItem?.isMoreLink!) {
        history.push(
          `/items?key=${encodeURIComponent(autocompleteValue.value)}`
        );
      } else {
        history.push(
          `/items?key=${encodeURIComponent(selectedItem.value)}${selectedItem.labelValueId ? '&labelValueId=' + selectedItem.labelValueId : ''}`
        );
      }
    } else {
      if (autocompleteValue) {
        history.push(
          `/items?key=${encodeURIComponent(autocompleteValue.value)}${autocompleteValue.labelValueId ? '&labelValueId=' + autocompleteValue.labelValueId : ''}`
        );
      } else {
        history.push('/items')
      }
    }

    // Clear any state filter.
    let filter = {
      onlySingle: false,
      onlyBundles: false,
      onlyDiscount: false,
      itemTypeIds: '',
      labelValuesIds: '',
    };
    dispatch(setNavFilters(filter));
    setOpenDropdown(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
      >
        <Toolbar 
        className={classes.Toolbar}
          >
           {width>=600?<div className={classes.columnLogo}>
            <Logo/> 
          </div>:<></>}
          
          <div className={classes.columnSearch}>
            <FormControl>
              <Autocomplete
                id="asynchronous-search" style={{ width: '70%' }} open={openDropdown}
                onOpen={() => { autocompleteValue.value !== '' ? setOpenDropdown(true) : setOpenDropdown(false); }}
                onClose={() => { setOpenDropdown(false); }}
                value={ selectedItem }
                onChange={(event: any, newValue: autocompleteObj | null) => {
                  (newValue?.isMoreLink) ? setSelectedItem({ value: autocompleteValue.value, labelValueId: 0 }) : setSelectedItem(newValue);
                }}
                getOptionSelected={(option, value) => option === value}
                getOptionLabel={(option) => option.value} options={options}
                noOptionsText={(loading) ? t('header.loading'): t('header.no-result')}
                clearOnBlur={false}
                filterOptions={(options, state) => { return options; }}
                renderOption={(option) => <Typography className={`${option.isMoreLink && 'view-more-link'}`}>{option.value}</Typography>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={ autocompleteValue }
                    onChange={ autocompleteChange }
                    error={ selectedItem === null}
                    onKeyDown={e => {
                      if ((e.key === 'Enter') && !loading) {
                        e.preventDefault();
                        setSelectedItem(autocompleteValue);
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <IconButton aria-label="search" color="inherit" onClick={() => setSelectedItem(autocompleteValue)} disabled={loading}>
                          <SearchIcon />
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
            </div>
               
          <div className={classes.columnInformation}>
          {userSummary !== undefined && width<600 &&
              <Link color="inherit" href="/user/details" className={classes.Avatar}>
                <ImageAvatarMobile src={userSummary !== undefined ? userSummary.profileImageUrl : '' } alt="User profile image." />
              </Link>
            }   
            {width<600?<div className={classes.columnLogo}>
            <Logo/> 
            </div>:<></>}
          {userSummary !== undefined && userSummary.points !== 0 &&
           <a className={classes.pointsHeader} href="/user/details#omegaCoinHistoryRef" >
           <Typography
             variant='h4'
              className={classes.pointsTitle}
              style={{ marginBottom: '0px' }}
             >
                {getProjectConfig().STORE_CREDITS_NAME}
              </Typography>
             <Typography
                variant='h3'
                className={classes.pointsNumber}
              >
                {userSummary.points.toLocaleString('en-US')}
              </Typography>
            </a>
}
            {userSummary !== undefined && width>=600 &&
              <Link color="inherit" href="/user/details">
                <ImageAvatar src={userSummary !== undefined ? userSummary.profileImageUrl : '' } alt="User profile image." />
              </Link>
            }
            <div className={classes.columnInformationText}>
              <Typography variant="inherit">
                {userSummary !== undefined && userGreeting }               
              </Typography>
            </div>
            { (localStorage.token === '' || localStorage.token === undefined ) &&
              <div className={classes.loginLink}>          
                  <Link color="inherit" href={'/user/login'}>
                    { t('header.log-in') }
                  </Link>
              </div>
                  }
            {userSummary !==undefined && width<960 &&
              <IconButton href="/user/details#omegaCoinHistoryRef" aria-label="show cart items" color="inherit" className={`${classes.savingsIcon} ${classes.mobileViewIcon}`}>
              {userSummary.points !== undefined ?
                <Badge badgeContent='!' color="secondary">
                  <SavingsIcon fontSize={iconSize()}/>
                </Badge>
              :
                <SavingsIcon fontSize={iconSize()}/>
              }
              
            </IconButton>
              }
              {userSummary !== undefined &&
                <IconButton href={userSummary !== undefined ? '/user/orders' : '/user/login'} aria-label="show cart items" color="inherit" className={`${classes.storeFrontIcon} ${classes.mobileViewIcon}`}>
                {cartQuantity !== '' ?
                  <Badge badgeContent={userSummary.totalOrdersActive} color="secondary">
                    <StorefrontIcon fontSize={iconSize()}/>
                  </Badge>
                :
                  <StorefrontIcon fontSize={iconSize()}/>
                }
              </IconButton>}
              {userSummary!==undefined?
              <IconButton href="/user/cart" aria-label="show cart items" color="inherit" className={`${classes.shoppingCartIcon} ${classes.mobileViewIcon}`}>
              {cartQuantity !== '' ?
                <Badge badgeContent={cartQuantity} color="secondary">
                  <ShoppingCartIcon fontSize={iconSize()}/>
                </Badge>
              :
                <ShoppingCartIcon fontSize={iconSize()}/>
              }
            </IconButton>:
            <IconButton href="/user/cart" aria-label="show cart items" color="inherit" style={{gridColumnStart:"7", justifyContent:'end'}} className={`${classes.shoppingCartIcon} ${classes.mobileViewIcon}`}>
            {cartQuantity !== '' ?
              <Badge badgeContent={cartQuantity} color="secondary">
                <ShoppingCartIcon fontSize={iconSize()}/>
              </Badge>
            :
              <ShoppingCartIcon fontSize={iconSize()}/>
            }
          </IconButton>
              }
            {userSummary !== undefined &&
                <IconButton href="/user/deliveries" aria-label="show deliveries" color="inherit" className={`${classes.localShippingIcon} ${classes.mobileViewIcon}`}>
                <LocalShippingIcon fontSize={iconSize()}/>
                </IconButton>
            }
          </div>
        </Toolbar>
        <NavBar/>
      </AppBar>
    </div>
  );
}

export default Header;
