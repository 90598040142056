import {Dispatch} from "redux";
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { GET_USER, LOGIN_USER, LOGOUT_USER, REGISTER_USER, VALIDATE_ACCOUNT, GET_USER_SUMMARY, AUTH_FAIL, AUTH_LOADING, RESEND_CODE, FORGOT_PASSWORD, IS_VALID_EMAIL, AuthActionsTypes } from "./AuthActionsTypes";

const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;

/**
 * Get the user information.
 */
 export const getUserInformation = () => async (dispatch: Dispatch<AuthActionsTypes>) => {
  const sessionToken = localStorage.token;
  
  if (apiURL !== '' && sessionToken !== '') {

    try {
      dispatch({
        type: AUTH_LOADING,
        payload: 'GET_USER'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + sessionToken
      }
    
      const response = await axios.get(`${apiURL}/user/getUserInformation`, {
        headers: headers
      });
      if (response.status === 200) { 
        dispatch({
          type: GET_USER,
          payload: response.data.data
        })
      }
       
    } catch(e) {
      dispatch({
        type: AUTH_FAIL,
        payload: {msg: 'status.get-error', action: ''}
      })
    }
  } else {
    dispatch({
      type: AUTH_FAIL,
      payload: {msg: 'status.get-error', action: ''}
    })
  }
};

/**
 * Get the user summary information.
 */
export const getUserSummary = () => async (dispatch: Dispatch<AuthActionsTypes>) => {
  const sessionToken = localStorage.token;

  if (apiURL !== '' && sessionToken !== '') {

    try {
      dispatch({
        type: AUTH_LOADING,
        payload: 'GET_USER'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + sessionToken
      }

      const response = await axios.get(`${apiURL}/user/getUserSummary`, {
        headers: headers
      });
      if (response.status === 200) {
        dispatch({
          type: GET_USER_SUMMARY,
          payload: response.data.data
        })
      }

    } catch (e) {
      dispatch({
        type: AUTH_FAIL,
        payload: { msg: 'status.get-error', action: '' }
      })
    }
  } else {
    dispatch({
      type: AUTH_FAIL,
      payload: { msg: 'status.get-error', action: '' }
    })
  }
};

/**
 * Validate user credentials.
 * @param {string} email
 * @param {strong} password
 */
 export const loginUser = (email: string, password: string) => async (dispatch: Dispatch<AuthActionsTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: AUTH_LOADING,
        payload: 'LOGIN_USER'
      })

      const bodyForm = {
        "email": email,
        "password": password,
      };

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
    
      const response = await axios.post(`${apiURL}/authentication/generateToken`, bodyForm, configPUT);
      if (response.status === 200) {
        if (response.data.data.user.isAccountValidated) {
          // Save the token on the local storage.
          if (response.data.data !== undefined) {
            localStorage.removeItem('registerEmail');
            localStorage.removeItem('registerPass');
            localStorage.setItem('token', response.data.data.jwt);
          }
          dispatch({
            type: LOGIN_USER,
            payload: response.data.data
          })
        } else {
          dispatch({
            type: AUTH_FAIL,
            payload: {msg: 'status.login-error', action: 'validate-account'}
          })
        }
      }
       
    } catch(e) {
      dispatch({
        type: AUTH_FAIL,
        payload: {msg: 'status.login-error', action: ''}
      })
    }
  } else {
    dispatch({
      type: AUTH_FAIL,
      payload: {msg: 'status.login-error', action: ''}
    })
  }
};

/**
 * Validate user credentials from google.
 * @param {string} token
 */
 export const loginUserGoogle = (token: string) => async (dispatch: Dispatch<AuthActionsTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: AUTH_LOADING,
        payload: 'LOGIN_USER_GOOGLE'
      })

      const bodyForm = {
        "access_token": token,
      };

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
    
      const response = await axios.post(`${apiURL}/authentication/generateTokenFromGoogle`, bodyForm, configPUT);
      if (response.status === 200) {
        // Save the token on the local storage.
        if (response.data.data !== undefined) {
          localStorage.setItem('token', response.data.data.jwt);
        }
        
        dispatch({
          type: LOGIN_USER,
          payload: response.data.data
        })
      }
       
    } catch(e) {
      dispatch({
        type: AUTH_FAIL,
        payload: {msg: 'status.login-error', action: ''}
      })
    }
  } else {
    dispatch({
      type: AUTH_FAIL,
      payload: {msg: 'status.login-error', action: ''}
    })
  }
};

/**
 * Validate user credentials from Facebook.
 * @param {string} token
 */
 export const loginUserFacebook = (token: string) => async (dispatch: Dispatch<AuthActionsTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: AUTH_LOADING,
        payload: 'LOGIN_USER_FB'
      })

      const bodyForm = {
        "access_token": token,
      };

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
    
      const response = await axios.post(`${apiURL}/authentication/generateTokenFromFacebook`, bodyForm, configPUT);
      if (response.status === 200) {
        // Save the token on the local storage.
        if (response.data.data !== undefined) {
          localStorage.setItem('token', response.data.data.jwt);
        }
        
        dispatch({
          type: LOGIN_USER,
          payload: response.data.data
        })
      }
       
    } catch(e) {
      dispatch({
        type: AUTH_FAIL,
        payload: {msg: 'status.login-error', action: ''}
      })
    }
  } else {
    dispatch({
      type: AUTH_FAIL,
      payload: {msg: 'status.login-error', action: ''}
    })
  }
};

/**
 * Remove the user sesstion token.
 */
 export const logoutUser = () => async (dispatch: Dispatch<AuthActionsTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: AUTH_LOADING,
        payload: 'LOGOUT_USER'
      })

      localStorage.removeItem('token');

      dispatch({
        type: LOGOUT_USER
      })

    } catch(e) {
      dispatch({
        type: AUTH_FAIL,
        payload: {msg: 'status.login-error', action: ''}
      })
    }
  } else {
    dispatch({
      type: AUTH_FAIL,
      payload: {msg: 'status.login-error', action: ''}
    })
  }
};

/**
 * Create a new user on the BE.
 * @param {any} user
 */
 export const registerUser = (user: any) => async (dispatch: Dispatch<AuthActionsTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: AUTH_LOADING,
        payload: 'REGISTER_USER'
      })

      const bodyForm = {
        "email": user.email,
        "first_name": user.name,
        "last_name": user.lastName,
        "password": user.password,
        "phone_number": user.phone
      };

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
    
      const addRes = await axios.post(`${apiURL}/authentication/registerUser`, bodyForm, configPUT);

      if (addRes.status === 200) {
        // Save user information for later login.
        let cipherPass = CryptoJS.AES.encrypt(user.password, 'sfapp293847crp').toString();
        localStorage.removeItem('registerEmail');
        localStorage.removeItem('registerPass');
        localStorage.setItem('registerEmail', user.email);
        localStorage.setItem('registerPass', cipherPass);

        dispatch({
          type: REGISTER_USER,
          payload: 'status.success-create'
        })
      }
       
    } catch(e) {
      dispatch({
        type: AUTH_FAIL,
        payload: {msg: 'status.create-error', action: ''}
      })
    }
  } else {
    dispatch({
      type: AUTH_FAIL,
      payload: {msg: 'status.create-error', action: ''}
    })
  }
};

/**
 * Validate user credentials.
 * @param {string} email
 * @param {strong} code
 */
 export const validateAccount = (email: string, code: string) => async (dispatch: Dispatch<AuthActionsTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: AUTH_LOADING,
        payload: 'VALIDATE_ACCOUNT'
      })

      const bodyForm = {
        "code": code,
        "email": email
      };

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
    
      const addRes = await axios.post(`${apiURL}/authentication/validateActivationCode`, bodyForm, configPUT);

      if (addRes.status === 200) {
        dispatch({
          type: VALIDATE_ACCOUNT,
          payload: 'status.success-validate'
        })
      }
       
    } catch(e) {
      dispatch({
        type: AUTH_FAIL,
        payload: {msg: 'status.validate-error', action: ''}
      })
    }
  } else {
    dispatch({
      type: AUTH_FAIL,
      payload: {msg: 'status.validate-error', action: ''}
    })
  }
};

/**
 * Validate user credentials.
 * @param {string} email
 */
 export const resendActivationCode = (email: string) => async (dispatch: Dispatch<AuthActionsTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: AUTH_LOADING,
        payload: 'RESEND'
      })

      const bodyForm = {
        "email": email
      };

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
    
      const addRes = await axios.post(`${apiURL}/authentication/resendActivationCode`, bodyForm, configPUT);

      if (addRes.status === 200) {
        dispatch({
          type: RESEND_CODE,
          payload: 'status.success-resend'
        })
      }
       
    } catch(e) {
      dispatch({
        type: AUTH_FAIL,
        payload: {msg: 'status.resend-error', action: ''}
      })
    }
  } else {
    dispatch({
      type: AUTH_FAIL,
      payload: {msg: 'status.resend-error', action: ''}
    })
  }
};

/**
 * Send forgot password request.
 * @param {string} email
 */
 export const forgotPassword = (email: string) => async (dispatch: Dispatch<AuthActionsTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: AUTH_LOADING,
        payload: 'FORGOT_PASSWORD'
      })

      const bodyForm = {
        "email": email
      };

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
    
      const addRes = await axios.post(`${apiURL}/authentication/forgotPassword`, bodyForm, configPUT);

      if (addRes.status === 200) {
        dispatch({
          type: FORGOT_PASSWORD,
          payload: 'status.success-reset'
        })
      }
       
    } catch(e) {
      dispatch({
        type: AUTH_FAIL,
        payload: {msg: 'status.reset-error', action: ''}
      })
    }
  } else {
    dispatch({
      type: AUTH_FAIL,
      payload: {msg: 'status.reset-error', action: ''}
    })
  }
};


/**
 * Validate user email to make sure is unique.
 * @param {string} email
 */
 export const validateUserEmail = (email:string) => async (dispatch: Dispatch<AuthActionsTypes>) => {  
  if (apiURL !== '') {
    try {
      dispatch({
        type: AUTH_LOADING,
        payload: 'VALIDATING'
      })

      const headers: object = {
        ContentType: 'application/json'
      }
    
      const response = await axios.get(`${apiURL}/authentication/isValidEmail/${email}`, {
        headers: headers
      });
      if (response.status === 200) { 
        dispatch({
          type: IS_VALID_EMAIL,
          payload: response.data.data
        })
      }
       
    } catch(e) {
      dispatch({
        type: AUTH_FAIL,
        payload: {msg: 'status.get-error', action: ''}
      })
    }
  } else {
    dispatch({
      type: AUTH_FAIL,
      payload: {msg: 'status.get-error', action: ''}
    })
  }
};