/**
 *****************************************
 *********** Dispatch Types **************
 *****************************************
 */
export const ADD_BUNDLE = "ADD_BUNDLE";
export const BUNDLE_PUT = "BUNDLE_PUT";
export const BUNDLE_LOADING = "BUNDLE_LOADING";
export const BUNDLE_FAIL = "BUNDLE_FAIL";
export const BUNDLE_LIST = "BUNDLE_LIST";
export const BUNDLE_LIST_UNIQUE = "BUNDLE_LIST_UNIQUE";
export const BUNDLE_REFRESH = "BUNDLE_REFRESH";
export const BUNDLE_SET_FILTER = "BUNDLE_SET_FILTER";
export const DELETE_BUNDLE = "DELETE_BUNDLE";
 
 /**
  *****************************************
  *********** Interfaces Types ************
  *****************************************
  */

export type AddBundleRequestObject = {
  id?: number;
  bundleCode?: string; 
  name: string;
  description: string;
  files: Array<any>;
  bundleItems?: Array<any>;
  price: number;
  allVendors?: boolean;
  isPublished?: boolean;
  vendorId?: number;
};

export type BundleImageType = {
  id: number,
  key: string,
  url: string
};

export type BundleItemType = {
  id: number,
  item: any,
  quantity: number
};

export type BundleType = {
  id: number,
  bundleCode: string,
  creationDate: string,
  currency?:	any,
  currentTax: number,
  description: string,
  images: Array<BundleImageType>,
  files?: Array<any>,
  isDeleted:	boolean
  isPublished:	boolean,
  allVendors: boolean,
  items:	Array<BundleItemType>,
  name:	string,
  price: number,
  priceWithTaxes: number,
  totalQuantity: number,
  vendor?: any
};

export type BundlePutResponse = {
  message: string
  bundle: BundleType
};

export type BundleList = {
  first: boolean
  last: boolean
  number: number
  size: number
  totalPages: number
  bundles: BundleType[]
}

export interface BundleLoading {
  type: typeof BUNDLE_LOADING
  payload: string
}
export interface BundleFail {
  type: typeof BUNDLE_FAIL,
  payload: string
}
export interface BundleAdd {
  type: typeof ADD_BUNDLE,
  payload: string,
  bundle: BundleType
}

export interface ItemUpdate {
  type: typeof BUNDLE_PUT,
  payload: BundlePutResponse
};

export interface BundleListType {
  type: typeof BUNDLE_LIST,
  payload: BundleList
}

export interface BundleListUniqueType {
  type: typeof BUNDLE_LIST_UNIQUE,
  payload: BundleList
}

export interface BundleRefresh {
  type: typeof BUNDLE_REFRESH,
  payload: boolean
}

export interface BundleSetFilter {
  type: typeof BUNDLE_SET_FILTER,
  payload: object
}

export interface BundleDelete {
  type: typeof DELETE_BUNDLE,
  payload: string
}

// Export types.
export type BundleActionsTypes = BundleLoading | BundleFail | BundleAdd 
| BundleListType | BundleRefresh | BundleSetFilter | ItemUpdate | BundleDelete | BundleListUniqueType;
