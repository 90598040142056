// React.
import React, { Fragment } from 'react';

// Components.
import ImageAvatar from '../utils/ImageAvatar';
import NumberFormat from '../utils/NumberFormat';

// Material UI
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import { itemAddedListUpdate } from "../../../actions/orderActions/OrderActions";

// Translations.
import { useTranslation } from 'react-i18next';

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';
import { ItemType } from '../models/ModelTypes';

// Styles.
import { useStyles, Accordion, AccordionSummary } from './AddOrderAccordionStyles';

const AddOrderAccordion = () => {
  const [t] = useTranslation('global');
  const classes = useStyles();
  const dispatch = useDispatch();

  // Redux state.
  const orderState = useSelector((state: RootStore) => state.order);
  const itemsAdded = orderState.addedInOrder?.itemAddedList;
  const bundlesAdded = orderState.addedInOrder?.bundleAddedList;

  const deleteAddedItem = (id: number) => {
    const updateListItems = itemsAdded!.filter((current) => current.item.id !== id)
    const updateListBundles = bundlesAdded!.filter((current) => current.bundle.id !== id)
    dispatch(itemAddedListUpdate(updateListItems, updateListBundles));
  };

  const groupList = (items:any|undefined) => {
    const groupedArray:any = [];
    if (items !== undefined) {
      for (var i = 0; i < items!.length; i++) {
        const current = items[i];
        const index = groupedArray?.findIndex((item: any) => (item.item.id === current.item.id) && (item.customReference === current.customReference));
        if (index !== -1) {
          groupedArray[index].quantity = groupedArray[index].quantity + current.quantity;
        } else {
          groupedArray.push({ item: current.item, quantity: current.quantity, customReference: current.customReference, whId: current.whId});
        }
      }
    }
    return groupedArray;
  };

  const bundleGroupList = (bundles:any|undefined) => {
    const groupedArray:any = [];
    if (bundles !== undefined) {
      for (var i = 0; i < bundles!.length; i++) {
        const current = bundles[i];
        const index = groupedArray?.findIndex((element:any) => element.bundle.id === current.bundle.id);
     
        if (index !== -1) {
          groupedArray[index].quantity = groupedArray[index].quantity + current.quantity;
        } else {
          groupedArray.push({bundle: current.bundle, quantity: current.quantity});
        }
      }
    }
    return groupedArray;
  };

  const getPriceInfo = (item:any) => {
    if (item.discounts !== null && item.discounts !== undefined) {
      const discount = item.discounts.pricePercentage;
      const savings = Number(((discount/ 100) * item.priceWithTaxes).toFixed(2));
      const newPrice = (item.priceWithTaxes - savings);
      return <div>
                <Typography color="primary">{getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={newPrice}/>}</Typography>
                <Typography color="secondary" className={ classes.textDecoration }>{getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={item.priceWithTaxes}/>}</Typography>
              </div>;
    } else {
      return <Typography color="primary">{getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={item.priceWithTaxes}/>}</Typography>;
    }
  };

  const calculateTotalPerRow = (orderItem:any) => {
    if (orderItem.item.category === 'Single') {
      var price = 0;
      const priceObj = orderItem.item.customPrices?.filter((customPrice: any) => customPrice.customReference === orderItem.customReference);
      if (priceObj?.length! > 0) price = priceObj![0]?.price;
      return price * orderItem.quantity;
    } else {
      if (orderItem.item.discounts !== null && orderItem.item.discounts !== undefined) {
        const discount = orderItem.item.discounts.pricePercentage;
        const savings = Number(((discount / 100) * orderItem.item.priceWithTaxes).toFixed(2));
        return (orderItem.item.priceWithTaxes - savings) * orderItem.quantity;
      } else {
        return orderItem.item.priceWithTaxes * orderItem.quantity;
      }
    }
  };

  const calculateTotalPerRowBundle = (orderItem:any) => {
    return orderItem.bundle.priceWithTaxes * orderItem.quantity;
  };

  const getCustomReferencePrice = (referenceName: string, item: ItemType) => {
    var price = 0;
    const priceObj = item.customPrices?.filter((customPrice) => customPrice.customReference === referenceName);
    if (priceObj?.length! > 0) price = priceObj![0]?.price;
    return <Typography color="primary">{getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={price} />}</Typography>;
  };


  return (
    <Fragment>
      {bundleGroupList(bundlesAdded).map((element:any) => 
        <Accordion key={ element.bundle.id } square expanded={false}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
            <Grid container spacing={2}>
              <Grid item xs={4} sm={2} className={ classes.userImgSmall }>
                <ImageAvatar src={ (element.bundle.images.length > 0) ? element.bundle.images[0].url : '' } alt="The main item image." />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography variant="subtitle1">{ element.bundle.name }</Typography>
                <Typography variant="caption">{ t('orders.item-id') }: { element.bundle.id }</Typography>
                <Typography variant="subtitle1"><span className={classes.type}>{ t('bundles.bundle') }</span></Typography>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Typography variant="subtitle2" color="textSecondary">{ t('orders.item-unit-price') }</Typography>
                <div>{ getPriceInfo(element.bundle) }</div>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Typography variant="subtitle2" color="textSecondary">{ t('orders.item-quantity') }</Typography>
                <Typography variant="subtitle1" color="primary">{element.quantity}</Typography>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Typography variant="subtitle2" color="textSecondary">{ t('orders.total') }</Typography>
                <Typography variant="subtitle1" color="primary">{element.bundle.currency.symbol}{ <NumberFormat number={Number(calculateTotalPerRowBundle(element))}/> }</Typography>
              </Grid>
              <Grid item xs={6} sm={1}>
                <IconButton aria-label="delete" onClick={ () => deleteAddedItem(element.bundle.id) }>
                  <DeleteIcon color="action"/>
                </IconButton>
              </Grid>
            </Grid>
          </AccordionSummary>
        </Accordion>
      )}
      {groupList(itemsAdded).map((item:any) => 
        <Accordion key={ item.item.id } square expanded={false}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
          <Grid container spacing={2}>
              <Grid item xs={4} sm={2} className={ classes.userImgSmall }>
                <ImageAvatar src={ (item.item.images.length > 0) ? item.item.images[0].url : '' } alt="The main item image." />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography variant="subtitle1">{ item.item.name }</Typography>
                <Typography variant="caption">{ t('orders.item-id') }: { item.item.id }</Typography>
                {(item.item.category === 'Single') &&
                  <Fragment>
                    <br/>
                    <Typography variant="caption">{t('orders.variation')}: {item.customReference.replace(/-/g, " | ")}</Typography>
                  </Fragment>
                }
              </Grid>
              <Grid item xs={6} sm={2}>
                <Typography variant="subtitle2" color="textSecondary">{ t('orders.item-unit-price') }</Typography>
                <div>{(item.item.category === 'Single') ? getCustomReferencePrice(item.customReference, item.item) : getPriceInfo(item.item) }</div>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Typography variant="subtitle2" color="textSecondary">{ t('orders.item-quantity') }</Typography>
                <Typography variant="subtitle1" color="primary">{item.quantity}</Typography>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Typography variant="subtitle2" color="textSecondary">{ t('orders.total') }</Typography>
                <Typography variant="subtitle1" color="primary">{item.item.currency.symbol}{<NumberFormat number={Number(calculateTotalPerRow(item))}/> }</Typography>
              </Grid>
              <Grid item xs={6} sm={1}>
                <IconButton aria-label="delete" onClick={ () => deleteAddedItem(item.item.id) }>
                  <DeleteIcon color="action"/>
                </IconButton>
              </Grid>
            </Grid>
          </AccordionSummary>
        </Accordion>
      )}
    </Fragment>
  );
}

export default AddOrderAccordion;