// React.
import React from 'react';

// Material UI
import Paper from '@material-ui/core/Paper';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import Typography from '@material-ui/core/Typography';

// Components.
import LogsAccordion from './LogsAccordion';

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './LogsListStyles';

const LogsList = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');

  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <div className={classes.boxTop}>
          <TrackChangesIcon color="primary" fontSize="large" />
          <Typography variant="h5" color="primary" className={classes.sectionTitle}>
            {t('logs.search-results')}
          </Typography>
        </div>
        <div>
          <LogsAccordion />
        </div>
      </Paper>
    </div>
  )
}

export default LogsList;
