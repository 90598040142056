// React.
import React, { Fragment, useEffect, useState } from "react"

// Material UI.
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import FormControl from "@material-ui/core/FormControl"
import Grid from "@material-ui/core/Grid"
import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import ListItemText from "@material-ui/core/ListItemText"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid"

// Components.
import NumberFormat from "../../admin/utils/NumberFormat"
import TextMaskPhone from "../../admin/utils/TextMaskPhone"
// Redux
import { useDispatch, useSelector } from "react-redux"
import { RootStore } from "../../../Store"
import { updateUserPhone } from "../../../actions/userActions/UserActions"
import {
  addUserAddress,
  updateUserAddress,
} from "../../../actions/userAddressActions/UserAddressActions"
import { getUserVendors } from "../../../actions/vendorActions/VendorActions"

// Model
import { AddressUserType, ShippingType } from "../../admin/models/ModelTypes"

// Styles.
import useStyles from "./CheckoutShippingStyles"

// Translations.
import { Checkbox, FormControlLabel } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import TextMaskIdentification from "../../admin/utils/TextMaskIdentification"

// Types interfase.
interface CheckoutShippingProps {
  isSummary: boolean
  userAddresses: Array<AddressUserType> | undefined
  userEmail: string
  setSelectedShipping: Function
  selectedShipping: ShippingType | undefined
  setSelectedAddress: Function
  selectedAddress: AddressUserType | undefined
  selectedPickUpId: string
  setSelectedPickUpId: Function
  updateDisabledState: Function
  shippings: Array<ShippingType> | undefined
  selectedVendor: string
  setSelectedVendor: Function
}

const CheckoutShipping = ({
  isSummary,
  userAddresses,
  userEmail,
  setSelectedShipping,
  selectedShipping,
  setSelectedAddress,
  selectedAddress,
  updateDisabledState,
  shippings,
  selectedVendor,
  setSelectedVendor,
  setSelectedPickUpId,
  selectedPickUpId
}: CheckoutShippingProps) => {
  const classes = useStyles()
  const [t] = useTranslation("global")
  const dispatch = useDispatch()

  // Redux state.
  const authState = useSelector((state: RootStore) => state.auth)
  const loggedUser = authState.loggedUser
  const cart = loggedUser.cart
  const bundleCart = loggedUser.bundleCart

  const addressState = useSelector((state: RootStore) => state.address)
  const userAddressCreatedId = addressState.userAddressCreatedId
  const userAddressCreatedIndex = addressState.userAddressCreatedIndex

  const reduxVendorsState = useSelector((state: RootStore) => state.vendors)
  const vendorsList = reduxVendorsState.userVendors

  const [acceptPickUp, setAcceptPickUp] = useState<boolean>(false)
  const [identificationPickUpError, setIdentificationPickUpError] =
    useState<boolean>(false)

  useEffect(() => {
    if (userAddressCreatedId !== undefined && userAddressCreatedIndex !== undefined) {
      const newAddressesState = userAddressesState.addresses
      newAddressesState[userAddressCreatedIndex].id = userAddressCreatedId

      setUserAddressesState({
        ...userAddressesState,
        addresses: newAddressesState,
      })
      setSelectAddress(newAddressesState[userAddressCreatedIndex])
    }
    // eslint-disable-next-line
  }, [userAddressCreatedId])

  useEffect(() => {
    if (loggedUser.addresses !== undefined) {
      // Add the phone number on the field when an address is addded.
      if (!userPhone || userPhone === "") {
        if (loggedUser?.phoneNumber && loggedUser?.phoneNumber !== "") {
          setUserPhone(loggedUser.phoneNumber)
        } else {
          if (loggedUser.addresses[0] !== undefined) {
            setUserPhone(loggedUser.addresses[0].phoneNumber)
          }
        }
      }
    }

    // eslint-disable-next-line
  }, [loggedUser.addresses])

  // Default stage object value.
  const defaultAddresses = [
    {
      id: 0,
      province: "",
      canton: "",
      district: "",
      fullAddress: "",
      phoneNumber: "",
      postalCode: "",
      userEmail: userEmail,
    },
  ]

  const defaultAddressesState = {
    addresses: userAddresses !== undefined ? userAddresses : defaultAddresses,
  }
  const [userAddressesState, setUserAddressesState] = useState<any>(
    defaultAddressesState
  )
  const [editing, setEditing] = useState<boolean | false>(false)
  const [addressError, setAddressError] = useState<boolean>(false)
  const [userPhone, setUserPhone] = useState<string>(loggedUser.phoneNumber)
  const [userPhoneError, setUserPhoneError] = useState<boolean>(false)

  const editOnClick = () => {
    setEditing(true)
  }

  const cancelOnClick = () => {
    setEditing(false)
    setUserAddressesState(defaultAddressesState)
  }

  const setSelectedShippingAction = (shipping: ShippingType) => {
    setEditing(false)
    setSelectedShipping(shipping)
    if (shipping.id === 1) {
      dispatch(getUserVendors({ paged: false }))
    }
  }

  const getCurrentVendorAddress = () => {
    if (vendorsList !== undefined) {
      let currentVendor = vendorsList.filter(
        (vendor) => vendor.id === Number(selectedVendor)
      )
      if (currentVendor.length > 0) {
        return `${currentVendor[0].address.province}, ${currentVendor[0].address.canton}, 
                ${currentVendor[0].address.district}, ${currentVendor[0].address.fullAddress}`
      }
    }
    return t("checkout.not-available")
  }

  const getCurrentVendorPhone = () => {
    if (vendorsList !== undefined) {
      let currentVendor = vendorsList.filter(
        (vendor) => vendor.id === Number(selectedVendor)
      )
      if (currentVendor.length > 0) {
        if (currentVendor[0].phoneNumber) return currentVendor[0].phoneNumber
        if (
          !currentVendor[0].phoneNumber &&
          currentVendor[0].address.phoneNumber
        )
          return currentVendor[0].address.phoneNumber
      }
    }
    return t("checkout.not-available")
  }

  // Handles the field changes to update the state.
  const handleChangeAddress = (
    e: React.ChangeEvent<{ value: unknown }>,
    index: number,
    objectKey: string
  ) => {
    const name = objectKey as keyof typeof userAddressesState
    const newAddressesState = userAddressesState.addresses
    newAddressesState[index][name] = e.target.value

    setUserAddressesState({
      ...userAddressesState,
      addresses: newAddressesState,
    })
  }

  // Handles the field changes to update the state.
  const handleChangeUserPhone = (e: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = String(e.target.value)
    setUserPhone(newValue)

    const isPhoneFormat = /^[0-9\s]+$/.test(newValue)
    if (newValue === "" || !isPhoneFormat) {
      setUserPhoneError(true)
    } else {
      setUserPhoneError(false)
    }
  }

  const handleChangeIdentificationPickUp = (
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    const newValue = String(e.target.value)
    setSelectedPickUpId(newValue)

    const isIdentificationFormat = /^[0-9\s]+$/.test(newValue)
    if (newValue === "" || !isIdentificationFormat) {
      setIdentificationPickUpError(true)
    } else {
      setIdentificationPickUpError(false)
    }
  }
  const addNewAddressObject = () => {
    let newAddress = {
      id: `new-${userAddressesState.addresses.length}`,
      province: "",
      canton: "",
      district: "",
      fullAddress: "",
      phoneNumber: "",
      postalCode: "",
      userEmail: userEmail,
    }
    let newArray = userAddressesState.addresses
    newArray.push(newAddress)
    setUserAddressesState({
      ...userAddressesState,
      addresses: newArray,
    })
  }

  const validateAddresses = () => {
    var result = false
    const newAddressesState = userAddressesState.addresses
    newAddressesState.forEach((address: AddressUserType) => {
      if (
        address.province === "" ||
        address.canton === "" ||
        address.district === "" ||
        address.fullAddress === "" ||
        address.phoneNumber === ""
      ) {
        result = true
        setAddressError(true)
      }
    })
    return result
  }

  const saveOnClick = () => {
    if (!validateAddresses()) {
      // Save the user on the BE.
      defaultAddressesState.addresses.forEach(
        (address: AddressUserType, index: number) => {
          if (String(address.id).includes("new")) {
            dispatch(addUserAddress(address, index))
          } else {
            dispatch(updateUserAddress(address))
          }
        }
      )

      setEditing(false)
    }
  }

  const setSelectAddress = (address: AddressUserType) => {
    let selectedAddress = {
      id: address.id,
      userEmail: userEmail,
      province: address.province,
      canton: address.canton,
      district: address.district,
      fullAddress: address.fullAddress,
      phoneNumber: address.phoneNumber,
      postalCode: address.postalCode,
    }
    setSelectedAddress(selectedAddress)
  }

  const isSelected = (id: string) => {
    var className = ""
    if (selectedAddress !== undefined) {
      className = String(selectedAddress.id) === id ? "selected" : ""
    }

    return className
  }

  const isShippingSelected = (id: number) => {
    var className = ""
    if (selectedShipping !== undefined) {
      className = selectedShipping.id === id ? "selected" : ""
    }

    return className
  }

  const getVendorItemArray = () => {
    var vendorList: any = []
    // Bundles cart.
    bundleCart.forEach((cartItem: any) => {
      const exists = vendorList.findIndex(
        (listItem: any) => listItem.vendor.id === cartItem.bundle.vendor.id
      )
      if (exists !== -1) {
        vendorList[exists].items.push(cartItem)
      } else {
        vendorList.push({ vendor: cartItem.bundle.vendor, items: [cartItem] })
      }
    })
    // Normal items cart.
    cart.forEach((cartItem: any) => {
      const exists = vendorList.findIndex(
        (listItem: any) => listItem.vendor.id === cartItem.item.vendor.id
      )
      if (exists !== -1) {
        vendorList[exists].items.push(cartItem)
      } else {
        vendorList.push({
          vendor: cartItem.item.vendor,
          items: [cartItem],
          customReference: cartItem.id.customReference,
        })
      }
    })
    return vendorList
  }

  const getItemsVendors = () => {
    const vendorListInfo = getVendorItemArray()

    return (
      <Fragment>
        <Table
          size="small"
          aria-label="a dense table"
          className={classes.vendorTable}
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  {t("checkout.pickup-label")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.noPadding}>
                <FormControl className={classes.formControlVendor}>
                  <InputLabel id="store-checkbox-label">
                    {t("orders.select-store")}
                  </InputLabel>
                  <Select
                    labelId="store-checkbox-label"
                    id="store-checkbox"
                    input={<Input />}
                    value={selectedVendor}
                    onChange={(e) => setSelectedVendor(`${e.target.value}`)}
                  >
                    {vendorsList?.map((vendor) => (
                      <MenuItem key={vendor.id} value={vendor.id}>
                        <ListItemText primary={vendor.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {vendorListInfo.map((vendorInfo: any) => {
                  if (`${vendorInfo.vendor.id}` === selectedVendor) {
                    return (
                      <div key={vendorInfo.vendor.id}>
                        <strong>{t("checkout.address")}:</strong>{" "}
                        {getCurrentVendorAddress()} <br />
                        <strong>{t("checkout.phone")}:</strong>{" "}
                        {getCurrentVendorPhone()}
                        <br />
                        <div className={classes.pickUpContainer}>
                          {/* { <FormControlLabel
                            control={
                              <Checkbox
                                name="check1"
                                color="primary"
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setAcceptPickUp(event.target.checked)
                                }}
                              />
                            }
                            label="Autorizar tercero para recoger la orden"
                            style={{marginRight: '0px'}}
                          />} */}
                          {acceptPickUp && (
                            <div className={classes.identificationPickUpContainer}>
                              <div>
                              <InputLabel htmlFor="identificationPickUp">
                                <strong style={{fontSize: '14px'}}>{t("checkout.identification")}</strong>
                              </InputLabel>
                              <Input
                                id="identificationPickUp"
                                name="identificationPickUp"
                                value={selectedPickUpId}
                                onChange={handleChangeIdentificationPickUp}
                                inputComponent={TextMaskIdentification as any}
                                className={classes.formField}
                                error={identificationPickUpError}
                                placeholder="000000000000000"
                              />
                                </div>
                              
                              {identificationPickUpError && (
                                <Typography className={classes.phoneError}>
                                  {t("vendors.identification-error")}
                                </Typography>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  }
                  return false
                })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Fragment>
    )
  }

  const getUserPhoneForm = () => {
    return (
      <Fragment>
        <Table
          size="small"
          aria-label="a dense table"
          className={classes.phoneTable}
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  {t("checkout.user-phone")}
                  <span className="required">*</span>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <PhoneAndroidIcon className={classes.userPhoneIcon} />
                <FormControl className={classes.formControlUserPhone}>
                  <InputLabel htmlFor="userPhoneNumber">
                    {t("users.phone-number")}
                  </InputLabel>
                  <Input
                    id="userPhoneNumber"
                    name="userPhoneNumber"
                    value={userPhone}
                    onChange={handleChangeUserPhone}
                    inputComponent={TextMaskPhone as any}
                    className={classes.formField}
                    error={userPhoneError}
                  />
                  {userPhoneError && (
                    <Typography className={classes.phoneError}>
                      {t("vendors.phone-error")}
                    </Typography>
                  )}
                </FormControl>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Fragment>
    )
  }

  const validatePhoneNumber = () => {
    const isPhoneFormat = /^[0-9\s]+$/.test(userPhone)
    return isPhoneFormat ? false : true
  }

  const submitNext = () => {
    // Save the user phone number if needed.
    if (userPhone !== loggedUser.phoneNumber && selectedShipping?.id === 1) {
      dispatch(updateUserPhone(userPhone))
    }
    updateDisabledState(1)
  }

  const renderSummaryContent = () => {
    return (
      <Fragment>
        <Typography variant="h6">{t("checkout.step-1")}</Typography>
        <Typography variant="caption" color="textSecondary">
          {t("checkout.selected")}
        </Typography>
        {selectedShipping !== undefined && (
          <Typography>{selectedShipping.name}</Typography>
        )}
      </Fragment>
    )
  }

  const renderFullContent = () => {
    return (
      <Fragment>
        <Typography variant="h6">{t("checkout.step-1")}</Typography>
        <br />
        <Grid container className={classes.container}>
          <Grid item xs={12} md={12}>
            <Typography color="primary">
              {t("checkout.select-shipping")}
            </Typography>
            <div className={classes.shippingOptionsWrapper}>
              {shippings !== undefined &&
                shippings.map((shipping: ShippingType, index: number) => (
                  <Card
                    key={index}
                    className={`${isShippingSelected(shipping.id)}`}
                    onClick={() => setSelectedShippingAction(shipping)}
                  >
                    <CardContent>
                      <Typography>{shipping.name}</Typography>
                      <Typography variant="caption">
                        {t("checkout.cost")}: {shipping.currency.symbol}
                        <NumberFormat number={shipping.cost} />
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            {selectedShipping && (
              <Fragment>
                <Typography className={classes.shippingDescription}>
                  {t("checkout.shipping-desciption")}:{" "}
                  {selectedShipping.description}
                </Typography>
                <br />
              </Fragment>
            )}
            {selectedShipping?.id === 2 && (
              <Fragment>
                <Typography color="primary">
                  {t("checkout.choose-address")}
                </Typography>
              </Fragment>
            )}
            <Grid container>
              {selectedShipping?.id === 2 && (
                <Fragment>
                  {userAddressesState.addresses.map(
                    (address: AddressUserType, index: number) => (
                      <Grid item xs={12} sm={6} key={index}>
                        <FormControl
                          className={`${classes.formControl} ${
                            classes.addressGroup
                          } ${isSelected(String(address.id))}`}
                          onClick={() => setSelectAddress(address)}
                        >
                          <Typography
                            variant="caption"
                            className={classes.addressLabel}
                          >
                            {t("users.address-1")} {index + 1}
                          </Typography>
                          <TextField
                            className={classes.formField}
                            id="u-province"
                            name="province"
                            label={t("users.province")}
                            value={userAddressesState.addresses[index].province}
                            onChange={(e) =>
                              handleChangeAddress(e, index, "province")
                            }
                            disabled={!editing}
                            error={
                              userAddressesState.addresses[index].province ===
                                "" && addressError
                            }
                            helperText={
                              userAddressesState.addresses[index].province ===
                                "" && addressError
                                ? t("users.field-required")
                                : ""
                            }
                          />
                          <TextField
                            className={classes.formField}
                            id="u-canton"
                            name="canton"
                            label={t("users.canton")}
                            value={userAddressesState.addresses[index].canton}
                            onChange={(e) =>
                              handleChangeAddress(e, index, "canton")
                            }
                            disabled={!editing}
                            error={
                              userAddressesState.addresses[index].canton ===
                                "" && addressError
                            }
                            helperText={
                              userAddressesState.addresses[index].canton ===
                                "" && addressError
                                ? t("users.field-required")
                                : ""
                            }
                          />
                          <TextField
                            className={classes.formField}
                            id="u-district"
                            name="district"
                            label={t("users.district")}
                            value={userAddressesState.addresses[index].district}
                            onChange={(e) =>
                              handleChangeAddress(e, index, "district")
                            }
                            disabled={!editing}
                            error={
                              userAddressesState.addresses[index].district ===
                                "" && addressError
                            }
                            helperText={
                              userAddressesState.addresses[index].district ===
                                "" && addressError
                                ? t("users.field-required")
                                : ""
                            }
                          />
                          <TextField
                            className={classes.formField}
                            id="u-direction"
                            name="fullAddress"
                            label={t("users.direction")}
                            value={
                              userAddressesState.addresses[index].fullAddress
                            }
                            onChange={(e) =>
                              handleChangeAddress(e, index, "fullAddress")
                            }
                            disabled={!editing}
                            error={
                              userAddressesState.addresses[index]
                                .fullAddress === "" && addressError
                            }
                            helperText={
                              userAddressesState.addresses[index]
                                .fullAddress === "" && addressError
                                ? t("users.field-required")
                                : ""
                            }
                          />
                          <FormControl className={classes.formControlAddress}>
                            <InputLabel htmlFor="u-phone-number">
                              {t("users.phone-number")}
                            </InputLabel>
                            <Input
                              id="u-phone-number"
                              name="phoneNumber"
                              value={
                                userAddressesState.addresses[index].phoneNumber
                              }
                              onChange={(e) =>
                                handleChangeAddress(e, index, "phoneNumber")
                              }
                              disabled={!editing}
                              inputComponent={TextMaskPhone as any}
                              className={classes.formField}
                              error={
                                userAddressesState.addresses[index]
                                  .phoneNumber === "" && addressError
                              }
                            />
                            {userAddressesState.addresses[index].phoneNumber ===
                              "" &&
                              addressError && (
                                <Typography
                                  style={{ fontSize: "0.75rem" }}
                                  color="error"
                                >
                                  {t("vendors.phone-error")}
                                </Typography>
                              )}
                          </FormControl>
                          <TextField
                            className={classes.formField}
                            id="u-postal-code"
                            name="postalCode"
                            label={t("users.postal-code")}
                            value={
                              userAddressesState.addresses[index].postalCode
                            }
                            onChange={(e) =>
                              handleChangeAddress(e, index, "postalCode")
                            }
                            disabled={!editing}
                          />
                        </FormControl>
                      </Grid>
                    )
                  )}
                </Fragment>
              )}
              {selectedShipping?.id === 1 && getUserPhoneForm()}
              {selectedShipping?.id === 1 && getItemsVendors()}
            </Grid>
            {editing && userAddressesState.addresses.length < 2 && (
              <div className={classes.addAddress}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={addNewAddressObject}
                >
                  {userAddressesState.addresses.length === 0
                    ? t("users.add-address")
                    : t("users.add-second-address")}
                </Button>
              </div>
            )}
            {editing && (
              <div className={classes.actionsWrapper}>
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={saveOnClick}
                >
                  {t("users.save")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={cancelOnClick}
                >
                  {t("users.cancel")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled
                  onClick={submitNext}
                >
                  {t("checkout.next-step")}
                </Button>
              </div>
            )}
            {!editing && (
              <div className={classes.actionsWrapper}>
                {selectedShipping?.id === 2 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={editOnClick}
                  >
                    {t("checkout.edit-addresses")}
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    (selectedAddress === undefined &&
                      selectedShipping?.id !== 1) ||
                    validatePhoneNumber() ||
                    (selectedVendor === "" && selectedShipping?.id === 1)
                  }
                  onClick={submitNext}
                >
                  {t("checkout.next-step")}
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  return <div>{isSummary ? renderSummaryContent() : renderFullContent()}</div>
}

export default CheckoutShipping
