// React.
import React, { Fragment } from "react";

// Material UI.
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

// Styles.
import useStyles from "./UseTermsStyles";

const UseTermsOmegaCoin = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Paper
          elevation={3}
          square={false}
          variant="elevation"
          className={classes.errorWrapper}
        >
          <div>
            <h1>¡Bienvenido a OMEGA Coins!</h1>
            <p>
              Regístrate y acumula OMEGA Coins para pagar tus compras en
              <a href="/"> www.omegaenlinea.com</a>. ¡Únete a la acción de los torneos y sé parte
              de la comunidad de OMEGA CARD GAME STORE!
            </p>
            <p>
              Antes de empezar a acumular tus OMEGA Coins, te invitamos a leer
              nuestras preguntas frecuentes para que puedas disfrutar de
              nuestros beneficios sin preocupaciones:
            </p>
            <h2>¿Cómo puedo obtener OMEGA Coins?</h2>
            Puedes obtener OMEGA Coins participando en eventos organizados y
            seleccionados por OMEGA CARD GAME STORE. ¡Participa en nuestros
            torneos y acumula OMEGA Coins para obtener descuentos exclusivos en
            tus compras en línea en <a href="/"> www.omegaenlinea.com</a>!
            <h2>¿Cómo puedo utilizar mis OMEGA Coins?</h2>
            <p>
              Puedes utilizar tus OMEGA Coins como pago parcial o total de tus
              compras en línea en <a href="/"> www.omegaenlinea.com</a>
            </p>
            <h2>¿De qué otra forma puedo obtener OMEGA Coins?</h2>
            <p>
              Un administrador de OMEGA CARD GAME STORE puede asignarte OMEGA
              Coins. Cada vez que esto suceda, verás información sobre esta
              transacción en tu página de perfil en <a href="/"> www.omegaenlinea.com</a>.
            </p>
            <h2>
              ¿Es necesario estar registrado en <a href="/"> www.omegaenlinea.com</a> para
              recibir OMEGA Coins?
            </h2>
            <p>
              Sí, debes estar registrado en <a href="/"> www.omegaenlinea.com</a> para recibir
              tus OMEGA Coins.
            </p>
            <h2>¿Dónde puedo ver mi saldo y movimientos de OMEGA Coins?</h2>
            <p>
              Tu saldo e información de movimientos de OMEGA Coins estarán
              siempre disponibles en tu página de perfil en
              <a href="/"> www.omegaenlinea.com</a>.
            </p>
            <h2>¿Los OMEGA Coins tienen fecha de vencimiento?</h2>
            <p>
              No, los OMEGA Coins no tienen fecha de vencimiento, por lo que
              puedes acumularlos y utilizarlos en el momento que desees.
            </p>
            <h2>
              ¡No esperes más y pregunta a los organizadores de torneos de OMEGA
              CARD GAME STORE sobre cómo obtener OMEGA Coins!
            </h2>
            <p>
              Si tienes alguna duda adicional, no dudes en escribirnos a
              <a href="mailto:info@omegaenlinea.com"> info@omegaenlinea.com</a>. ¡Te esperamos en nuestros torneos para que
              empieces a acumular tus OMEGA Coins y obtener los mejores
              descuentos en línea!
              <br></br>
              <br></br>
              <br></br>
              ¡Gracias por ser parte de OMEGA Coins!
            </p>
          </div>
        </Paper>
      </Container>
    </Fragment>
  );
};

export default UseTermsOmegaCoin;
