import { Button, Grid, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { ItemType } from "../models/ModelTypes"
import { useStyles } from "./CreateInventoryRequestStyles"

interface SummaryRequestProps {
  itemsAdded: Array<{
    item: ItemType
    quantity: number
  }>
  handleEditItem: (item: any) => void
  handleDeleteItem: (itemId: number) => void
  isAdminOrSuperSeller: boolean
}

const SummaryRequest = ({
  itemsAdded,
  handleEditItem,
  handleDeleteItem,
  isAdminOrSuperSeller,
}: SummaryRequestProps) => {
  const classes = useStyles()
  const { t } = useTranslation("global")

  const totalItemsQuantity = itemsAdded?.reduce(
    (acc, item) => acc + item.quantity,
    0
  )
  return (
    <Grid item xs={12} sm={isAdminOrSuperSeller ? 12 : 6}>
      <div className={classes.sumarryTop}>
      <Typography variant="h5" color="primary" style={{ marginBottom: "32px" }}>
        {t("inventoryRequests.summary-request")}
      </Typography>
      <Typography variant="h5" color="primary" style={{ marginBottom: "32px" }}>
        {t("inventoryRequests.total-items")} {totalItemsQuantity}
      </Typography>
      </div>
      <div
        style={{
          maxHeight: "428px",
          overflowY: "auto",
          width: "100%",
        }}
      >
        <Grid container spacing={3} style={{ margin: 0, width: "100%" }}>
          {itemsAdded?.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              className={classes.summaryCardItem}
              key={index}
            >
              <div>
                <Typography variant="h6">
                  {t("inventoryRequests.item-name")} {item.item.name}
                </Typography>
                <Typography variant="h6">
                  {t("inventoryRequests.sku")} {item.item.code}
                </Typography>
                <Typography variant="h6">
                  {t("inventoryRequests.request-amount")} {item.quantity}
                </Typography>
                <Typography variant="h6">
                  {t("inventoryRequests.supplier-store")}{" "}
                  {item?.item?.vendor?.name}
                </Typography>
                <div className={classes.buttonsCard}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleEditItem(item)}
                  >
                    {t("inventoryRequests.edit-item")}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handleDeleteItem(item.item.id)}
                  >
                    {t("inventoryRequests.delete-item")}
                  </Button>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </Grid>
  )
}

export default SummaryRequest
