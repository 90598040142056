// React.
import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';

// Material UI.
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';

// Components.
import TextMaskPhone from '../admin/utils/TextMaskPhone';


// Redux
import { useDispatch, useSelector } from "react-redux";
import { registerUser, validateUserEmail } from "../../actions/authActions/AuthActions";
import { RootStore } from "../../Store";

// Global Types.
import { UserType } from '../admin/models/ModelTypes';

// Styles.
import useStyles from './RegisterFormStyles';

// Translations.
import { useTranslation } from 'react-i18next'

const RegisterForm = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  let history = useHistory();

  // Default stage object.
  const defaulUser:UserType = { 
    id: 0, 
    name: '', 
    lastName: '', 
    email: '', 
    phone: '', 
    role: '', 
    profileImageUrl: '/imgs/default-user.svg', 
    imgFile: null,
    password: '',
    passwordConfirm: ''
  };

  // Redux state.
  const dispatch = useDispatch();
  const authReduxState = useSelector((state: RootStore) => state.auth);
  const loading = authReduxState.loading;
  const actionStatus = authReduxState.actionStatus;

  // Local State.
  const [userState, setUserState] = useState<UserType>(defaulUser);
  const [errorArray, setErrorArray] = useState<Array<string>>([]);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);

  useEffect(() => {
    // Redirect to validate account after registration.
    if (authReduxState.isValidEmail !== undefined) {
      setIsValidEmail(authReduxState.isValidEmail);
    }
    
    // eslint-disable-next-line
  }, [authReduxState.isValidEmail]);

  useEffect(() => {
    // Redirect to validate account after registration.
    if (actionStatus !== undefined) {
      if (actionStatus.menssage === 'status.success-create') {
        history.push('/user/validate-account');
      }
    }
    
    // eslint-disable-next-line
  }, [actionStatus]);

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof userState;
    var inputValue = String(event.target.value);

    if (name === 'name' || name === 'lastName') {
      inputValue = inputValue.replace(/[\d,-,!,@,#,$,%,^,&,*,(,),-,_,=,+,:,;,",',?,{,}]/g, '');
    }
    setUserState({
      ...userState,
      [name]: inputValue,
    });

    // Validate and add errors.
    const errors = [...errorArray];
    const nameString = '' + [name]
    if (event.target.value === '') {
      errors.push(nameString);
      setErrorArray(errors);
    } else {
      var newErrors = errors.filter(function(value){ 
        return value !== nameString;
      });
      if (nameString === 'email') {
        newErrors = newErrors.filter(function(value){ 
          return value !== 'taken-email';
        });
      }
      setErrorArray(newErrors);
    }
  };

  const validateEmailOnDB = () => {
    // See if the email is already used.
    if (userState.email !== '') {
      dispatch(validateUserEmail(userState.email));
    }
  };

  const handleChangeValidation = (inputName: string) => {
    var result = false;
    errorArray.forEach((key) => {
      if (key === inputName) {
        result = true;
      }
    });

    return result;
  };

  const getErrorMessageEmail = () => {
    var message = '';
    if (handleChangeValidation('email')) {
      message = t('users.field-required-email');
    }
    if ( handleChangeValidation('taken-email')) {
      message = t('users.email-taken');
    }
    return message;
  };

  const validateFields = () => {
    var result = true;
    const errors = [...errorArray];
    if (userState.name === '' || userState.name.length < 2) {
      errors.push('name');
      result = false;
    }
    if (userState.lastName === '' || userState.lastName.length < 2) {
      errors.push('lastName');
      result = false;
    }
    if (userState.password === '' || userState.password === undefined) {
      errors.push('password');
      result = false;
    }
    if ((userState.passwordConfirm === '' || userState.passwordConfirm === undefined) || (userState.passwordConfirm !== userState.password)) {
      errors.push('passwordConfirm');
      result = false;
    }
    if (userState.email === '') {
      errors.push('email');
      result = false;
    } else {
      if (userState.email !== undefined) {
        // eslint-disable-next-line
        let emailMask  = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/); 
        if (!emailMask.test(userState.email)) { 
          errors.push('email');
          result = false;  
        }
      }
      if (!isValidEmail) {
        errors.push('taken-email');
        result = false;  
      }
    }
    if (userState.phone === '') {
      errors.push('phone');
      result = false;
    } else {
      if (userState.phone !== undefined) {
        let phoneMask  = new RegExp(/\(?([0-9]{4})\)?([ ]?)([0-9]{4})/);
        if (!phoneMask.test(userState.phone)) {
          errors.push('phone');
          result = false;
        }
      }
    }
    setErrorArray(errors);

    return result;
  };

  const handleCancel = () => {
    history.push('/user/login');
  };

  const saveOnClick = () => {
    // Save the user on the BE.
    const validation = validateFields();
    if (errorArray.length === 0 && validation) {
      dispatch(registerUser(userState));
      setUserState(defaulUser);
    }
  };

  return (
    <div className={ classes.formWrapper }>
      <Typography variant="h5" color="primary">{ t('authentication.create-new-account') }</Typography>
      <form noValidate autoComplete="off">
        <CssBaseline />
        <div className="form-fields">
          <TextField 
            id="first_name" 
            name="name"
            label={ t('authentication.first-name') }
            value={ userState.name } 
            color="primary" 
            fullWidth
            autoFocus
            onChange={ handleChange } 
            error={ handleChangeValidation('name') }
            helperText={handleChangeValidation('name') ? t('users.field-required'): ''}
          />
          <TextField 
            id="last_name" 
            name="lastName"
            label={ t('authentication.last-name') }
            value={ userState.lastName } 
            color="primary" 
            fullWidth
            onChange={ handleChange }
            error={handleChangeValidation('lastName')}
            helperText={handleChangeValidation('lastName') ? t('users.field-required') : ''}
          />
          <TextField 
            id="user-email" 
            name="email"
            type="email"
            label={ t('authentication.email') }
            value={ userState.email }
            color="primary" 
            fullWidth
            onChange={ handleChange }
            onBlur={ validateEmailOnDB }
            error={ handleChangeValidation('email') || handleChangeValidation('taken-email') }
            helperText={ getErrorMessageEmail() }
          />
          <FormControl>
            <InputLabel htmlFor="formatted-phone-mask-input">{ t('authentication.phone-number') }</InputLabel>
            <Input
              name="phone"
              value={ userState.phone } 
              onChange={ handleChange }
              id="formatted-phone-mask-input"
              inputComponent={TextMaskPhone as any}
              color="primary" 
              fullWidth
              error={ handleChangeValidation('phone') }
            />
          </FormControl>
          <TextField 
            id="user-password" 
            name="password"
            label={ t('authentication.password') } 
            value={ userState.password }
            color="primary" 
            type="password"
            fullWidth 
            onChange={ handleChange }
            error={handleChangeValidation('password')}
            helperText={handleChangeValidation('password') ? t('users.field-required') : ''}
          />
          <TextField 
            id="user-password-confirm" 
            name="passwordConfirm"
            label={ t('authentication.password-confirm') } 
            value={ userState.passwordConfirm }
            color="primary" 
            type="password"
            fullWidth 
            onChange={ handleChange }
            error={handleChangeValidation('passwordConfirm')}
            helperText={handleChangeValidation('passwordConfirm') ? t('authentication.error-password-confirm') : ''}
          />
        </div>
        <div className={classes.formControls}>
          { loading === 'REGISTER_USER' && <CircularProgress /> }
          <Button variant="text" color="primary" onClick={ handleCancel }> { t('authentication.cancel') } </Button>
          <Button variant="contained" color="primary" onClick={ saveOnClick } disabled={ errorArray.length !== 0 || loading === 'LIST' }> { t('authentication.create') }</Button>
        </div>
      </form>
    </div>
  )
}

export default RegisterForm;
