// React.
import React from 'react';

// Material UI.
import Paper from '@material-ui/core/Paper';

// Components.
import AppVariablesSettings from '../settings/AppVariablesSettings';

// Styles.
import useStyles from './SettingsStyles';

const AppVariablesSettingsPage = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={3} square={false} variant="elevation">
      <AppVariablesSettings />
    </Paper>
  )
}

export default AppVariablesSettingsPage;
