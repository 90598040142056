// react.
import React, { useEffect } from 'react';
import DocumentMeta from 'react-document-meta';

// Material UI.
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

// Components.
import ItemsList from '../items/ItemsList';
import SearchFilters from '../layout/SearchFilters';
import SearchSort from '../layout/SearchSort';
import BundlesList from '../bundles/BundlesList';

// Redux.
import { RootStore } from "../../../Store";
import { useSelector, useDispatch } from "react-redux";
import { getItemTypes } from '../../../actions/itemTypeActions/ItemTypeActions';
import { getUserInformation } from '../../../actions/authActions/AuthActions';

// Styles.
import useStyles from './ItemSearchStyles';

// Config.
import { getProjectConfig } from '../../../getProjectConfig';
import scrollToTop from '../../utils/scrollToTop';

const ItemSearch = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const authToken: string | undefined = localStorage.token;
  const params = new URLSearchParams(window.location.search);
  const onlyBundles = params.get('onlyBundles');
  const navAction = params.get('navAction') ? decodeURI(`${params.get('navAction')}`) : null;

  // Redux state.
  const bundleState = useSelector((state: RootStore) => state.bundle);
  const loadingBundle = bundleState.loading;
  const itemState = useSelector((state: RootStore) => state.item);
  const loadingItem = itemState.loading;
  const itemTypeReduxState = useSelector((state: RootStore) => state.itemType);
  const itemTypeList = itemTypeReduxState.itemTypeList;
  const loadingCategories = itemTypeReduxState.loading;
  const authState = useSelector((state: RootStore) => state.auth);
  const { loggedUser } = authState;

  // Page metatags info.
  const meta = getProjectConfig().METATAGS.ITEMS_SEARCH;

  useEffect(() => {
    if (itemTypeList === undefined) dispatch(getItemTypes());

    // eslint-disable-next-line
  }, []);

  // Get user information (Neded for Cart data).
  useEffect(() => {
    if (authToken !== undefined && authToken !== '') {
      if (loggedUser === undefined) dispatch(getUserInformation());
    }

    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    if (loadingBundle === '' && loadingItem === '') {
      scrollToTop();
    }
  // eslint-disable-next-line
  }, [loadingBundle, loadingItem])

  return (
    <DocumentMeta {...meta}>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.sectionWrapper}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Paper className={classes.sectionWrapperFilters}>
              <SearchSort viewMode="desktop"/>
              {(!onlyBundles && navAction?.toLowerCase() !== 'preventa') &&
                <SearchFilters />
              }
            </Paper>
          </Grid>
          <Grid item xs={12} md={9}>
            {(loadingBundle !== '' || (loadingItem !== '' && loadingItem !== 'ITEM_NAMES') || loadingCategories !== '') && <CircularProgress />}
            {(!onlyBundles && loadingCategories === '') &&
              <ItemsList/>
            }
            {(onlyBundles && loadingCategories === '') &&
              <BundlesList/>
            }
          </Grid>
        </Grid>
      </Container>
    </DocumentMeta>
  )
}

export default ItemSearch;
