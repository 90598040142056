// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        marginRight: theme.spacing(2),
        '&.name': {
          width: '30%',
          [theme.breakpoints.down('xs')]: {
            width: '100%',
          },
        },
        '&.description': {
          width: '40%',
          [theme.breakpoints.down('xs')]: {
            width: '100%',
          },
        },
        '&.tax': {
          width: '20%',
          [theme.breakpoints.down('xs')]: {
            width: '100%',
          },
        },
      },
      '& .MuiInputBase-root': {
        width: '100%'
      }
    },
    pageTitle: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(4),
      '& .MuiSvgIcon-fontSizeLarge': {
        marginRight: theme.spacing(1),
      }
    },
    bold: {
      fontFamily: 'VisbyCF-Bold'
    },
    categoryWrapper: {
      backgroundColor: process.env.REACT_APP_THEME_COLOR_EXTRA_LIGHT_GRAY,
      borderRadius: '4px',
      padding: '10px 24px 33px',
      marginBottom: theme.spacing(2)
    },
    actionsWrapper: {
      marginTop: theme.spacing(10),
      textAlign: 'right'
    },
    autocomplete: {
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginBottom: theme.spacing(1),
      }
    },
    formControlAdjustQty: {
      flexWrap: 'wrap',
      alignItems: 'center',
      flexDirection: 'row',
      margin: theme.spacing(1, 0),
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-end',
      },
      '& .MuiTypography-caption': {
        width: '100%'
      },
      '& .MuiTextField-root': {
        width: 'calc(34% - 16px)',
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          marginRight: 0
        }
      },
      '& .MuiButton-root': {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
      },
      '& .wide': {
        width: '100%'
      }
    },
    paper: {
      padding: theme.spacing(2),
    },
    card: {
      marginRight: theme.spacing(2),
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
    largeText: {
      fontSize: '20px'
    }
  }),
);

export default useStyles;
