// React.
import React, { Fragment } from 'react';

// Material UI.
import {  Grid, Paper, Typography } from "@material-ui/core";

// Styles.
import useStyles from './PrizesSummaryStyles';

// Translations.
import { useTranslation } from 'react-i18next';

// Components.

// Redux
import { useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import { PrizeReportData } from '../../../actions/prizeActions/PrizeActionsTypes';
import { getProjectConfig } from '../../../getProjectConfig';

const PrizesSummary = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');

  // Redux state.
  const reduxEventState = useSelector((state: RootStore) => state.events);
  const { eventDetail } = reduxEventState;

  return (
    <div className={ classes.root + ` ` + classes.rootTotal }>
      <Paper className={ classes.paperAlign } elevation={3} square={false} variant="elevation">
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Typography variant="h4" color="primary" className={ classes.sectionTitle }>
            { t('prizes.title-prizes-summary') }
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className={ classes.paddingBottom }>
              <div className={classes.totalAwards}>
                <Grid container spacing={1} className={classes.totalAwardsHeader}>
                  <Grid item xs={3} sm={3}>
                  <Typography className={classes.centerText} variant="body2">{ t('prizes.title-prizes') }</Typography>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Typography className={classes.centerText} variant="body2">{ t('prizes.suggested-total') }</Typography>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Typography className={classes.centerText} variant="body2">{ t('prizes.real-top') }</Typography>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Typography className={classes.centerText} variant="body2">{ "Diferencia" }</Typography>
                  </Grid>
                </Grid>
              </div> 
              { eventDetail?.reportData.pricingDifference !== undefined && 
                eventDetail?.reportData.prizesReport.map((award: PrizeReportData, i: number) => 
                <Fragment key={i}>
                  <div className={classes.totalAwards}>
                    <Grid container spacing={1}>
                      <Grid item xs={3} sm={3} style={{textAlign:'center'}}>
                        <Typography variant="caption">{ award.prizeName }</Typography>
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <Typography className={classes.centerText} variant="caption">{ award.suggestedTotalQuantity }</Typography>
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <Typography className={classes.centerText} variant="caption">{ award.realTotalQuantity }</Typography>
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <Typography className={classes.centerText} variant="caption">{ award.difference }</Typography>
                      </Grid>
                    </Grid>
                  </div>                      
                </Fragment>)
              }
              <div style={{textAlign:'center', fontSize:'18px', paddingTop:'30px'}}>Nota de Diferencia {getProjectConfig().CURRENCY_SYMBOL}{Math.abs(eventDetail?eventDetail.reportData.pricingDifference:0)}</div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default PrizesSummary;
