// react.
import React, {Fragment, useEffect, useState} from 'react';

// Material UI.
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

// Components.
import UserEdit from '../users/UserEdit';

// Redux
import { useSelector, useDispatch } from "react-redux";
import {RootStore} from "../../../Store";
import { getUserInformation } from "../../../actions/authActions/AuthActions";

// Model
import { UserType, AddressUserType } from '../../admin/models/ModelTypes';

// Styles.
import useStyles from './UserStyles';

const User = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Redux state.
  const reduxState = useSelector((state: RootStore) => state.auth);
  const loggedUser = reduxState.loggedUser;

  // Local state.
  const [userState, setUserState] = useState<UserType|undefined>(undefined);

  useEffect(() => {
    dispatch(getUserInformation());

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (loggedUser !== undefined) {
      let user:UserType = {
        id: 0,
        email: loggedUser.email,
        name: loggedUser.firstName,
        lastName: loggedUser.lastName,
        profileImageUrl: loggedUser.profileImageUrl,
        phone: loggedUser.phoneNumber,
        address: loggedUser.addresses,
        role: loggedUser.roles,
        points: loggedUser.points
      };
      setUserState(user);
    }
  // eslint-disable-next-line
  }, [loggedUser])
  return (
    <Fragment>
      <CssBaseline />
      <Container maxWidth="md" className={classes.sectionWrapper}>
        <Paper elevation={3} square={false} variant="elevation">
          { userState !== undefined &&
            <UserEdit user={ userState } userAddresses={ userState.address as Array<AddressUserType> }/>
          }
        </Paper>
      </Container>
    </Fragment>
  )
}

export default User;
