// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inOnDrag: {
      cursor: 'grabbing !important',
      '& *': {
        cursor: 'grabbing !important'
      }
    },
    marginBottom: {
      marginBottom:  theme.spacing(2),
    },
    navItemWrapper: {
      marginBottom: theme.spacing(4)
    },
    rowWrapper: {
      '& .MuiListItemSecondaryAction-root': {
        [theme.breakpoints.down('xs')]: {
          right: '-25px'
        },
      },
    },
    itemRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: theme.palette.grey[400],
      borderRadius: '4px',
      padding: theme.spacing(0, 1),
      marginBottom: theme.spacing(1),
      fontFamily: 'VisbyCF-Bold',
      height: '48px',
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0
      },
      '&:hover': {
        opacity: .8,
        cursor: 'pointer'
      },
      '& .MuiButtonBase-root': {
        padding: '5'
      },
      '&.to-move-selected': {
        border: `2px solid ${theme.palette.grey[600]}`
      },
      '&.to-displace-selected': {
        border: `2px solid ${theme.palette.grey[800]}`
      }
    },
    childItem: {
      backgroundColor: theme.palette.grey[200],
    },
    itemSubRow: {
      backgroundColor: theme.palette.grey[300],
    },
    dragHandle: {
      cursor: 'move'
    },
    fileInput: {
      display: 'none'
    },
    level2: {
      marginLeft: theme.spacing(6),
      borderRadius: '4px',
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(3)
      }
    },
    modalPaper: {
      padding: theme.spacing(6, 4),
      position: 'absolute',
      width: '50%',
      height: '80%',
      top: 'calc((100% - 80%) / 2)',
      left: 'calc((100% - 50%) / 2)',
      overflow: 'auto',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(6, 2),
        width: '80%',
        left: 'calc((100% - 80%) / 2)',
        height: '70%',
        top: 'calc((100% - 70%) / 2)',
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(6, 2),
        width: '95%',
        left: 'calc((100% - 95%) / 2)',
        height: '90%',
        top: 'calc((100% - 90%) / 2)',
      },
      '& .MuiTypography-root': {
        marginBottom: theme.spacing(1),
      },
      '& .MuiButtonBase-root': {
        marginLeft: theme.spacing(2),
      }
    },
    closeModal: {
      position: 'absolute',
      right: '0',
      top: '0'
    },
    formControl: {
      width: '100%',
      display: 'block',
      textAlign: 'left',
      marginBottom: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: '100%'
      },
      '& .MuiFormControl-root': {
        width: '100%',
      }
    },
    listSeparator: {
      fontFamily: 'VisbyCF-Bold',
      backgroundColor: theme.palette.grey[200],
      height: '24px',
      lineHeight: '24px'
    },
    verticalAlign: {
      display: 'flex',
      alignItems: 'center'
    },
    actionsWrapper: {
      width: '100%',
      textAlign: 'right'
    },
    '& .MuiAlert-root': {
      height: '50px'
    },
    notification: {
      left: 'auto',
      right: 0,
      top: '85px',
      height: '50px',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      width: 'auto',
      transform: 'inherit',
      paddingRight: theme.spacing(2)
    },
  }),
);

export default useStyles;
