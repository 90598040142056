/**
 *****************************************
 *********** Dispatch Types **************
 *****************************************
 */
export const GET_DOLAR_PRICE = "GET_DOLAR_PRICE";
export const UTILITY_FAIL = "UTILITY_FAIL";
export const UTILITY_LOADING = "UTILITY_LOADING";

/**
 *****************************************
 *********** Interfaces Types ************
 *****************************************
 */
export interface UtilityLoading {
  type: typeof UTILITY_LOADING
  payload: string
};

export interface UtilityFail {
  type: typeof UTILITY_FAIL
};

export interface GetDolarPrice {
  type: typeof GET_DOLAR_PRICE,
  payload: string
};

// Export types.
export type UtilityDispatchTypes = UtilityLoading | UtilityFail | GetDolarPrice;
