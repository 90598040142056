// React.
import React, { Fragment, useEffect, useState } from 'react';

// Components.
import VendorEdit from './VendorEdit';
import Pagination from '../utils/ResultsPagination';
import scrollToTop from '../../utils/scrollToTop';

// Material UI
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';

// Redux
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import { getAllVendors, refreshVendorView } from "../../../actions/vendorActions/VendorActions";
import { getItemCategory } from '../../../actions/categoryActions/categoryActions';

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import { Accordion, AccordionSummary, AccordionDetails } from './VendorAccordionStyles';


const VendorAccordion = () => {

  const dispatch = useDispatch();
  const [t] = useTranslation('global');

  // Local state.
  const [currentPage, setCurrentPage] = useState<number>(1);
  const url = window.location.href;

  // Redux state.
  const vendorState = useSelector((state: RootStore) => state.vendors);
  const { refresh, vendorFilters: filters } = vendorState;


  useEffect(() => {
    dispatch(getItemCategory());

    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let page = params.get('page');
    const pNumber = Number(page);
    if (page && page !== null) {
      const qParams = {
        ...filters,
        page: pNumber - 1,
        size: getProjectConfig().PAGES_SIZE,
        paged: true
      };
      dispatch(getAllVendors(qParams));
      setCurrentPage(pNumber);
      scrollToTop();
    } else {
      const qParams = {
        ...filters,
        page: 0,
        size: getProjectConfig().PAGES_SIZE,
        paged: true
      };
      dispatch(getAllVendors(qParams));
      setCurrentPage(1);
      scrollToTop();
    }

    dispatch(refreshVendorView(false));

    // eslint-disable-next-line
  }, [url, refresh]);

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const isSingleItem = (vendor:any) => {
    const result = vendor.vendorCategories.filter((obj: any) => obj.id.category === 'Single');
    if (result.length > 0) return true;
    return false;
  };

  return (
    <Fragment>
      {
        vendorState?.vendorList !== undefined &&
        (vendorState!.vendorList!.vendors.map(vendor =>
          <Accordion key={ vendor.id } square expanded={expanded === `panel-u-${ vendor.id }`} onChange={handleChange(`panel-u-${ vendor.id }`)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1">{ vendor.name }</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant="subtitle1">{t('vendors.vendor-code')}: { vendor.vendorCode }</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant="subtitle1">{t('vendors.vendor-type')}: { vendor.type }</Typography>
              </Grid>
                {(isSingleItem(vendor)) &&
                <Grid item xs={12} sm={2}>
                  <Typography variant="subtitle1">ID: {vendor.id}</Typography>
                </Grid>
              }
            </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <VendorEdit vendor={ vendor } isExpanded={expanded === `panel-u-${ vendor.id }`}/>
            </AccordionDetails>
          </Accordion>
      ))}
      {
        vendorState?.vendorList?.totalPages !== undefined && vendorState?.vendorList?.totalPages !== 1
          ? (<Pagination current={currentPage} path="/admin/vendors" pagesNumber={vendorState?.vendorList!.totalPages} />) : ''
      }
    </Fragment>
  );
}

export default VendorAccordion;
