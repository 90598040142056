import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import DateAdapter from '@mui/lab/AdapterMoment';
import moment from "moment";
import Pagination from '../utils/ResultsPagination';
import TotalsInformation from '../../utils/TotalsInformation';
import SellersSummary from './SellersSummary';
import {
  getAllOrderStatus
} from "../../../actions/orderActions/OrderActions";
import { 
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Select,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { 
  DataGrid,
  GridSortItem,
  GridSortModel,
} from '@mui/x-data-grid';
import { downloadOrdersReport } from '../utils/downloadOrdersReport';
import { useTranslation } from 'react-i18next';
import { getAllVendors } from '../../../actions/vendorActions/VendorActions';
import { getAllPaymentMethods } from '../../../actions/userPaymentMethodsActions/UserPaymentMethodsActions';
import { mapDataGridInterface } from './utils/mapDataGridInterface';
import { textFormatter } from './utils/textFormatter';
import { dateFormatter } from './utils/dateFormatter';
import { emailFormatter } from './utils/emailFormatter';
import { currencyFormatter } from './utils/currencyFormatter';
import { useGetReports, useReports, useSetFilters } from './ReportsContext';
import useReportsStyles from '../pages/ReportsStyles';
import { getUpdatedSortModel } from './utils/getUpdatedSortModel';

const ReportsComponent = ():JSX.Element => {
  const url = window.location.href;
  const { state } = useReports();
  const getReports = useGetReports();
  const setFilters = useSetFilters();
  const [t] = useTranslation('global');
  const classes = useReportsStyles();
  const dispatch = useDispatch();
  
  const [columns,] = useState( () => [
    {
      field: 'id',
      headerName: t("orders.report-id-label"),
      type: 'number',
      width: 100,
      filterable: true,
      valueFormatter: textFormatter,
    },
    {
      field: 'orderCode',
      headerName: t("orders.order-code-label"),
      type: 'string',
      width: 200,
      filterable: true,
      valueFormatter: textFormatter,
    },
    {
      field: 'creationDate',
      headerName: t("orders.report-creationdate-label"),
      type: 'date',
      width: 250,
      filterable: false,
      valueFormatter: dateFormatter,
    },
    {
      field: 'processedDate',
      headerName: t("orders.processed-date"),
      type: 'date',
      width: 250,
      filterable: false,
      valueFormatter: dateFormatter,
    },
    {
      field: 'status',
      headerName: t("orders.report-status-label"),
      type: 'select',
      width: 150,
      filterable: false,
      valueFormatter: textFormatter,
    },
    {
      field: 'trackingNumber',
      headerName: t("orders.report-tracking-label"),
      type: 'string',
      width: 250,
      filterable: false,
      valueFormatter: textFormatter,
    },
    {
      field: 'vendorName',
      headerName: t('orders.report-store-label'),
      type: 'string',
      width: 150,
      filterable: false,
      valueFormatter: textFormatter,
    },
    {
      field: 'sellerEmail',
      headerName: t("orders.report-soldby-label"),
      type: 'string',
      width: 250,
      filterable: false,
      valueFormatter: emailFormatter,
    },
    {
      field: 'type',
      headerName: t('orders.report-type-label'),
      type: 'string',
      width: 250,
      filterable: false,
    },
    {
      field: 'paymentMethod',
      headerName: t('orders.report-paymentmethod-label'),
      type: 'string',
      width: 250,
      filterable: false,
      valueFormatter: textFormatter,
    },
    {
      field: 'totalCost',
      headerName: t('orders.report-totalcost-label'),
      type: 'string',
      width: 150,
      filterable: false,
      valueFormatter: currencyFormatter,
    },
    {
      field: 'updatedDate',
      headerName: t('orders.report-updated-label'),
      type: 'date',
      width: 250,
      filterable: false,
      valueFormatter: dateFormatter,
    },
    {
      field: 'completedDate',
      headerName: t('orders.report-completed-label'),
      type: 'date',
      width: 250,
      filterable: false,
      valueFormatter: dateFormatter,
    },
  ])

  // Redux state.
  const orderState = useSelector((state: RootStore) => state.order);
  const vendorsState = useSelector((state: RootStore) => state.vendors);
  const paymentState = useSelector((state: RootStore) => state.paymentMethods);
  const statusList = orderState.orderStatusList?.orderStatuses;
  const paymentMethodsList = paymentState.paymentMethodsList;

  // local state for sorting using datatable sort interface GridSortItem
  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    {
      field: 'creationDate',
      sort: 'desc',
    },
  ]);

 /*  initial page load */
  useEffect(() => {
    if (vendorsState?.vendorList?.vendors === undefined) {
      dispatch(getAllVendors(null));
    }
    if (statusList === undefined) {
      dispatch(getAllOrderStatus());
    }
    if (paymentMethodsList === undefined) {
      dispatch(getAllPaymentMethods());
    } 
    getReports({
      filters: state.filters,
      pagination: state.pagination,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* if url changes, update state and get new results */
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let page = params.get('page');
    const pNumber = Number(page);
    getReports({
      filters: {
        ...state.filters,
        sort: `${sortModel[0].field},${sortModel[0].sort}`,
      },
      pagination: {
        ...state.pagination,
        page: pNumber - 1,
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  /* if state sortmodel changes getReports */
  useEffect(() => {
    getReports({
      filters: {
        ...state.filters,
        sort: `${sortModel[0].field},${sortModel[0].sort}`,
      },
      pagination: state.pagination,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortModel])

  const handleSortModelChange = (newModel:GridSortModel):void => {
    const newSortModel = getUpdatedSortModel({
      newModel,
      currentModel: sortModel,
    })
    setSortModel(newSortModel);
  };

  const resetAdvancedSearchFields = () => {  
    setFilters({
      ...state.filters,
      initialProcessedDateRange: '',
      endProcessedDateRange: '',
      creationDate: null,
      completedDate: null,
      paymentMethod: '',
      vendorId: '',
      status: '',
    })
  }

  const setFiltersDate = (value:any, name:string) => {
    const newDate = moment(value).format('YYYY-MM-DD');
    // Validations for dates to be in valid ranges
    if (
      name === 'start'
    ) {
      const dayDiff = moment(value).diff(state.filters.endDateRange, 'days');

      if (dayDiff < 0) {
        setFilters({
          ...state.filters,
          initialDateRange: newDate,
        });
      } else {
        setFilters({
          ...state.filters,
          initialDateRange: newDate,
          endDateRange: moment(newDate).add(1, 'days').format('YYYY-MM-DD'),
        });
      }
    }
    if (name === 'end') {
      const dayDiff = moment(value).diff(state.filters.initialDateRange, 'days');
      if (dayDiff > 0) {
        setFilters({
          ...state.filters,
          endDateRange: newDate,
        });
      } else {
        setFilters({
          ...state.filters,
          endDateRange: newDate,
          initialDateRange: moment(newDate).add(-1, 'days').format('YYYY-MM-DD'),
        });
      }
    }
  };

  const tableContent = state.orderReportList ? mapDataGridInterface(state.orderReportList) : [];
  const totalsSummary = state.summary ? state.summary.find( summary => summary.vendorName === 'Total') : null;
  
  return (
    <div className={ classes.root }>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Paper elevation={3}>
          <Container  maxWidth={false} 
            style={{
              paddingTop: 20,
              paddingBottom: 20,
            }}
          > 
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Typography variant="h5" color="primary">
                  {t("orders.report-page-title")}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1} direction={'row'}
              style={{
                marginTop: 10,
                marginBottom: 10,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={ t('orders.search') }
                    value={state.filters.searchTerm}
                    onChange={(e) => setFilters({
                      ...state.filters,
                      searchTerm: `${e.target.value}`
                    })}
                    fullWidth
                  />  
                </Grid>
                <Grid item xs={12} sm={3}>
                  <DatePicker
                    label={t("orders.order-from-date")}
                    value={state.filters.initialDateRange}
                    onChange={(newValue:any) => {
                      setFiltersDate(newValue, 'start')
                    }}
                  renderInput={(params: any) => <TextField {...params} fullWidth/>}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <DatePicker
                    label={t("orders.order-to-date")}
                    value={state.filters.endDateRange}
                    onChange={(newValue:any) => {
                      setFiltersDate(newValue, 'end')
                    }}
                    renderInput={(params:any) => <TextField {...params} fullWidth/>}
                  />
                </Grid>
            </Grid>

            <Grid container spacing={1} direction={'row'}
              style={{
                marginTop: 10,
                marginBottom: 10,
                }
              }
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls="advanced-search-content"
                  id="advanced-search-header"
                >
                  <Typography>{t('orders.advanced-search')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} direction="row">
                    
                    <Grid item xs={12} sm={4} md={4}>
                      <InputLabel id="report-filter-status-label">Estado</InputLabel>
                      <Select
                        id="report-filter-status"
                        labelId="report-filter-status-label"
                        value={state.filters.status}
                        onChange={(e) => setFilters({
                          ...state.filters,
                          status: e.target.value as string
                        })}
                        style={{
                          display: 'flex',
                        }}
                        label="Estado"
                      >
                        <MenuItem value="">Todos</MenuItem>
                        { statusList  ? statusList.map((status) => 
                          <MenuItem
                            value={status}
                            key={status}
                          >
                            {status}
                          </MenuItem>)
                        : null }
                      </Select>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>
                      <InputLabel id="report-filter-seller-label">Tienda</InputLabel>
                      <Select
                        labelId="report-filter-seller-label"
                        style={{
                          display: 'flex',
                        }}
                        id="report-filter-seller"
                        value={state.filters.vendorId}
                        label="Tienda"
                        onChange={(e) => setFilters({
                          ...state.filters,
                          vendorId: `${e.target.value}`
                        })}
                      >
                        <MenuItem value="">Todos</MenuItem>
                        {vendorsState?.vendorList?.vendors?.map((vendor) => (
                          <MenuItem 
                            value={vendor.id}
                            key={vendor.id}
                          >
                            {vendor.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>
                      <InputLabel id="report-filter-payment-label">Metodo de Pago</InputLabel>
                      <Select
                        labelId="report-filter-payment-label"
                        style={{
                          display: 'flex',
                        }}
                        id="report-filter-payment"
                        value={state.filters.paymentMethod}
                        label="Metodo de pago"
                        onChange={(e) => setFilters({
                          ...state.filters,
                          paymentMethod: `${e.target.value}`
                        })}
                      >
                        <MenuItem value="">Todos</MenuItem>
                        { paymentMethodsList
                        ? paymentMethodsList.map((paymentMethod) => 
                          <MenuItem
                            value={paymentMethod}
                            key={paymentMethod}
                          >
                            {paymentMethod}
                          </MenuItem>)
                        : null }
                      </Select>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography>Filtros avanzados por fecha:</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <DatePicker
                        label={t("orders.completed-date-label")}
                        value={state.filters.completedDate}
                        onChange={(newDate) => setFilters({
                          ...state.filters,
                          completedDate: moment(newDate).format('YYYY-MM-DD')
                        })}
                        renderInput={(params:any) => <TextField {...params} fullWidth/>}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <DatePicker
                        
                        label={t("orders.creation-date-label")}
                        value={state.filters.creationDate}
                        onChange={(newDate) => setFilters({
                          ...state.filters,
                          creationDate: moment(newDate).format('YYYY-MM-DD')
                        })}
                        renderInput={(params:any) => <TextField {...params} fullWidth/>}
                      />
                    </Grid>

                    <Grid item xs={12} >
                      <Button variant="contained" color="primary" 
                        onClick={() => resetAdvancedSearchFields()}
                      >{t('orders.clear-advanced-search')}</Button>
                    </Grid>
                    
                  </Grid>

                </AccordionDetails>
              </Accordion>
              </Grid>

              <Grid container spacing={1} direction={'row'} justifyContent='space-between'>
                <Grid item >
                  <Button 
                    onClick={() => downloadOrdersReport(state.filters)}
                    variant="contained"
                    color="primary"
                  >
                    { t('orders.download') }
                  </Button>
                </Grid>

                <Grid item >
                  <Button 
                    onClick={() => getReports({
                      filters: state.filters,
                      pagination: {
                        ...state.pagination,
                        page: 0,
                      },
                    })}
                    variant="contained"
                    color="primary"
                  >
                    { t('orders.search') }
                  </Button>
                </Grid>
              </Grid>

            </Container>
          </Paper>
          <Paper
            elevation={3} 
            style={{
              marginTop: 30,
              marginBottom: 30
            }}
          >
            <Container maxWidth={false} 
              style={{
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              <Grid container spacing={2}>
                <SellersSummary
                  sellerSummaries={state.summary || []}
                />
              </Grid>

              <Grid container>
                  { totalsSummary ? <TotalsInformation
                    totalWeb={totalsSummary ? totalsSummary.totalWeb : 0}
                    totalPackage={totalsSummary.totalPackage ?? 0}
                    totalSeller={totalsSummary.totalSeller ?? 0}
                    finalTotal={totalsSummary.finalTotal ?? 0}
                  /> : null }
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}
                  style={{
                    height: 800,
                    width: '100%',
                    paddingTop: 20,
                }}>
                  
                  <DataGrid
                    rows={tableContent}
                    columns={columns}
                    checkboxSelection={false}
                    hideFooter={true}
                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    loading={state.requestStatus !== 'idle'}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} 
                style={{ paddingBottom: 20}}
              > { state.orderReportList ? 
                <Pagination
                  current={state.pagination.page + 1} 
                  path="/admin/reports"
                  pagesNumber={state.pagination.totalPages} 
                /> : null } 
              </Grid>
            </Container>
          </Paper>
        </LocalizationProvider>
    </div>
  )
}

export default ReportsComponent;