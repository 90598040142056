// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom:  theme.spacing(2),
      padding:  theme.spacing(2),
      backgroundColor:  process.env.REACT_APP_THEME_COLOR_EXTRA_LIGHT_GRAY,
      '& .MuiTypography-root': {
        display: 'block',
        marginBottom: theme.spacing(1)
      },
      '& .MuiList-root': {
        maxHeight: '220px'
      }
    },
    select: {
      maxWidth: '60px',
      '& .MuiList-root': {
        maxWidth: '60px',
      }
    },
    dolarPrice:{
      marginLeft: theme.spacing(1),
      color: theme.palette.grey[700],
      [theme.breakpoints.down('lg')]: {
        display: 'block',
        marginLeft: 0
      },
    },
    priceCol: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px'
      },
    },
    colImg: {
      alignSelf: 'center',
      '& .MuiAvatar-root': {
        width: '126px',
        height: 'auto',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
          width: '100%'
        },
        [theme.breakpoints.down('xs')]: {
          width: '126px',
        },
      }
    },
    colButton: {
      alignSelf: 'center',
      display: 'flex',
      justifyContent:'center'
    },
    lineThrough: {
      textDecoration: 'line-through'
    },
    priceWrapper: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    modalPaper: {
      padding: theme.spacing(6, 4),
      position: 'absolute',
      width: '400px',
      height: '210px',
      top: 'calc(50% - 105px)',
      left: 'calc(50% - 200px)',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(6, 2),
        width: '90%',
        left: '5%'
      },
      '& .MuiTypography-root': {
        marginBottom: theme.spacing(2),
      },
      '& .MuiButtonBase-root': {
        marginLeft: theme.spacing(2),
      }
    },
    closeModal: {
      position: 'absolute',
      right: '0',
      top: '0'
    },
    chip: {
      marginRight: '5px',
      marginBottom: '2px',
      fontFamily: 'VisbyCF-Bold',
      width: 'fit-content',
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
        flexWrap: 'wrap',
        background: 'none',
        display: 'flex',
        justifyContent: 'flex-start',
        height: 'auto'
      },
      '& .MuiAvatar-root': {
        width: 'auto',
        height: '16px',
        padding: '0 5px',
        fontSize: '11px',
        borderRadius: '15px',
        fontFamily: 'VisbyCF-Bold',
        color: 'black',
        [theme.breakpoints.down('sm')]: {
          fontSize: '10px',
          marginBottom: '2px',
          marginLeft: 0,
          marginRight: '2px',
        }
      },
      '& .MuiChip-label': {
        [theme.breakpoints.down('sm')]: {
          width: 'auto',
          height: '16px',
          padding: '0 5px',
          fontSize: '11px',
          borderRadius: '15px',
          fontFamily: 'VisbyCF-Bold',
          color: 'black',
          backgroundColor: theme.palette.grey[300],
          marginBottom: '2px'
        }
      },
      '&.MuiChip-outlined': {
        [theme.breakpoints.down('sm')]: {
          border: 0
        }
      }
    },
    customPadding: {
      padding: '6px 12px !important'
    },
    wishlistNameContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    wishlistName: {
      justifyContent: 'center',
    },
    wishlistNameLabel: {
      fontSize: '1.2rem !important',
      textAlign: 'center' 
    },
    wishlistCustomReference: {
      display: 'flex',
      justifyContent: 'center'
    },
    iconAction: {
      cursor: 'pointer'
    },
    fontActionIcon: {
      fontSize: '2rem !important'
    },
    disabledButton: {
      fontSize: '2rem !important', 
      opacity: '.5',
      cursor: 'not-allowed',
    },
    customTable: {
      marginTop: '20px',
      display: 'grid',
      padding : '10px',
      'grid-template-columns': 'repeat(2, 1fr)',
      'grid-template-rows': 'repeat(2, 1fr)',
      'gap': '10px',

      '& .header': {
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        padding: '2px 2px',
        backgroundColor: theme.palette.grey[600],
        color: 'white',
        borderRadius: '6px',
      },
      '& .bodyContent': {
        alignItems: 'center',
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: '6px'
      },
      '& label': {
        display: 'flex',
        justifyContent: 'center',
        marginLeft: '4px',
        marginTop: '2px',
        marginBottom: '2px',
        marginRight: '4px',
      },
    },
  }),
);

export default useStyles;
