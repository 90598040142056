// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .makeStyles-root-34': {
        width: '100%'
      },
      '& .MuiPagination-ul': {
        justifyContent: 'center'
      }
    },
    grid: {
      display: 'flex',
      marginBottom:  theme.spacing(2),
    },
    noResults: {
      width: '100%',
      marginTop:  theme.spacing(2),
      textAlign: 'center'
    },
    viewMoreBundles: {
      margin: '10px auto 30px'
    },
    selectPageSize: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%',
      marginBottom:  theme.spacing(2),
    }
  }),
);

export default useStyles;
