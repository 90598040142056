// React.
import React, {useState, useEffect} from 'react';
import { useGoogleLogin } from '@react-oauth/google';

// Material UI.
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux.
import { useDispatch, useSelector } from "react-redux";
import { loginUserGoogle } from "../../../actions/authActions/AuthActions";
import { RootStore } from "../../../Store";

// Styles.
import useStyles from './LoginSocialButtonStyles';

// Image.
import googleImg from '../../../google-logo.png';

// Translations.
import { useTranslation } from 'react-i18next';

const GoogleLoginButton = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');

  const dispatch = useDispatch();
  const authReduxState = useSelector((state: RootStore) => state.auth);
  const loginObject = authReduxState.loginObject;

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (loginObject !== undefined) {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [loginObject]);

  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      setLoading(true);
      dispatch(loginUserGoogle(tokenResponse.access_token));
    },
    onError: () => console.error('Google Login Failed')
  });
  
  return (
    <div>
      { loading ? <CircularProgress /> :
        <div className={`${classes.loginButton} ${classes.googleButton}`} onClick={() => login()}>
          <div className="google-icon-wrapper">
            <img src={googleImg} alt="Google Logo" width="40" />
          </div>
          <span>{t('authentication.google-login')}</span>
        </div>
      }
    </div>
  )
}

export default GoogleLoginButton;
