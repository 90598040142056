import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiButton-root': {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(2),
        zIndex: 1299,
        [theme.breakpoints.down('xs')]: {
          marginTop: theme.spacing(2),
          zIndex: 1,
        },
      }
    }
  })
);

export default useStyles;