import React, { createContext, useReducer, useContext, ReactNode } from 'react';
import { OrderType } from '../../../actions/orderActions/OrderActionsTypes';
import moment from 'moment';
import axios from 'axios';
import { GridSortItem } from '@mui/x-data-grid';

const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;

interface ReportsFilters {
  searchTerm: string;
  initialDateRange: string;
  endDateRange: string;
  sort?: string;
  status: string;
  vendorId: string;
  paymentMethod: string;
  initialProcessedDateRange?: string;
  endProcessedDateRange?: string;
  completedDate: string | null,
  creationDate: string | null,
}

interface ReportsPagination {
  totalPages: number,
  pageSize: number,
  page: number,
}

export interface TotalSellerSummary {
  sellerId: number;
  sellerName: string;
  total: number;
}

interface ReportSummary {
  vendorId: number,
  vendorName: string,
  finalTotal: number,
  totalSeller: number,
  totalPackage: number,
  totalWeb: number,
  totalSellerSummary: TotalSellerSummary[]
}

export enum ReportsRequestStatus {
  LOADING = 'loading',
  ERROR = 'error',
  IDLE = 'idle',
}
interface ReportsState {
  pagination: ReportsPagination
  filters: ReportsFilters
  requestStatus: ReportsRequestStatus
  orderReportList?: OrderType[]
  summary?: ReportSummary[]
  sortModel: GridSortItem[];
}

const initialReportsState: ReportsState = {
  requestStatus: ReportsRequestStatus.IDLE,
  orderReportList: undefined,
  summary: undefined,
  pagination: {
    totalPages: 0,
    pageSize: 10,
    page: 0,
  },
  filters: {
    searchTerm: '',
    status: '',
    paymentMethod: '',
    vendorId: '',
    initialDateRange: `${moment().format('YYYY-MM-DD')}`,
    endDateRange: `${moment().add(1, 'days').format('YYYY-MM-DD')}`,
    initialProcessedDateRange: '',
    endProcessedDateRange: '',
    creationDate: null,
    completedDate: '',
    sort: '' // sort string for request
  },
  sortModel: [ // sort model for datatable
    {
      field: 'creationDate',
      sort: 'desc',
    },
  ]
};

export type REPORTS_ACTIONS = SET_REPORTS | SET_PAGINATION | SET_FILTERS | SET_REQUEST_STATUS ;

type SET_REPORTS = {
  type: 'SET_REPORTS'; 
  payload: {
    orderReportList: OrderType[],
    summary: ReportSummary[],
    pagination: ReportsPagination
  }
};

type SET_PAGINATION = {
  type: 'SET_PAGINATION'; 
  payload: ReportsPagination
};

type SET_FILTERS = {
  type: 'SET_FILTERS'; 
  payload: ReportsFilters
};

type SET_REQUEST_STATUS = {
  type: 'SET_REQUEST_STATUS'; 
  payload: ReportsRequestStatus
};

const ReportsContext = createContext<{
  state: ReportsState; 
  dispatch: React.Dispatch<REPORTS_ACTIONS>
} | undefined>(undefined);

function reportsReducer(
  state: ReportsState, 
  action: REPORTS_ACTIONS
): ReportsState {
  switch (action.type) {
    case 'SET_REPORTS':
      return {
        ...state, 
        orderReportList: [
          ...action.payload.orderReportList
        ],
        summary: [
          ...action.payload.summary
        ],
        pagination: {
          ...state.pagination,
          ...action.payload.pagination
        },
      };
    case 'SET_PAGINATION':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    case 'SET_FILTERS':
      return {
        ...state, 
        filters: {
          ...state.filters,
          ...action.payload
        }
      };
    case 'SET_REQUEST_STATUS':
      return {
        ...state, 
        requestStatus: action.payload
      };
    default:
      return state;
  }
}

export const ReportsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reportsReducer, initialReportsState);
  return <ReportsContext.Provider value={{ state, dispatch }}>{children}</ReportsContext.Provider>;
};

export const useReports = () => {
  const context = useContext(ReportsContext);
  if (context === undefined) {
    throw new Error('useReports must be used within a ReportsProvider');
  }
  return context;
};

export const useGetReports = () => {
  const { dispatch } = useReports();

  return async (getReportsParams: {
    filters: ReportsFilters,
    pagination: ReportsPagination
  }) => {
    try {
      dispatch({
        type: 'SET_REQUEST_STATUS',
        payload: ReportsRequestStatus.LOADING,
      });
      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token,
      };

      const res = await axios.get(`${apiURL}/seller/getAllOrdersForReports`, {
        params: {
          ...getReportsParams.filters,
          page: getReportsParams.pagination.page,
        },
        headers: headers,
      });
      const getReportsPayload = res.data.data
      
      dispatch({
        type: 'SET_REPORTS',
        payload: {
          orderReportList: getReportsPayload.page.content,
          summary: getReportsPayload.orderSellerSummaryDataList,
          pagination: {
            totalPages: getReportsPayload.page.totalPages,
            pageSize: getReportsPayload.page.size,
            page: getReportsPayload.page.number,
          },
        },
      });

      dispatch({
        type: 'SET_REQUEST_STATUS',
        payload: ReportsRequestStatus.IDLE,
      });
    } catch (e) {
      console.error('Error getting reports data', e);
      dispatch({
        type: 'SET_REQUEST_STATUS',
        payload: ReportsRequestStatus.ERROR,
      });
    }
  };
};

export const useSetPagination = () => {
  const { dispatch } = useReports();

  return (pagination: ReportsPagination) => {
    dispatch({
      type: 'SET_PAGINATION',
      payload: pagination,
    });
  };
};

export const useSetFilters = () => {
  const { dispatch } = useReports();

  return (filters: ReportsFilters) => {
    dispatch({
      type: 'SET_FILTERS',
      payload: filters,
    });
  };
};
