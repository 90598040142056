// React.
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Material UI.
import { Backdrop, Button, CircularProgress, FormControl, Grid, IconButton, MenuItem, Modal, Paper, Select, TextField, Typography } from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@material-ui/icons/Close';
import { ProductionQuantityLimits } from '@mui/icons-material';
import { Check } from '@material-ui/icons';

// Styles.
import useStyles from './PlayerListStyles';

// Translations.
import { useTranslation } from 'react-i18next';

// Components.
import RegisterAnonymousPlayerForm from './RegisterAnonymousPlayerForm';
import ModalOrders from './OrderModal/modalOrders';

// Redux.
import { useDispatch, useSelector } from "react-redux";
import { getEventById, getEventPlayers, registerPlayerEventOrder, updateCurrentOrderPlayer, updateEventInformation, updateEventPlayer } from '../../../actions/eventActions/eventActions';
import { getEventPrizeDetails } from '../../../actions/prizeActions/PrizeActions';
import { RootStore } from '../../../Store';

// Model.
import { PlayerType } from '../models/ModelTypes';

interface PlayerListProps {
  id: string;
};
const PlayerList = ({ id } : PlayerListProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();
  const history = useHistory();

  const playerEdit:PlayerType = {
    id: 0,
    firstName: '',
    lastName: '',
    phoneNumber: '',  
    notes: '',
  }

  // Redux state.
  const reduxEventState:any = useSelector((state: RootStore) => state.events);
  const { actionStatus, loading, eventPlayers, eventDetail } = reduxEventState;
  const reduxPrizeState = useSelector((state: RootStore) => state.prize);
  const { eventPrizeDetails } = reduxPrizeState;

  // Define local state for the list of players.
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openFinishedModal, setFinishedOpenModal] = useState<boolean>(false);
  const [eventOpen, setEventOpen] = useState<boolean>(false);
  const [currentPlayer, setCurrentPlayer] = useState<PlayerType>(); 
  const [currentPlayerEdit, setCurrentPlayerEdit] = useState<PlayerType>(playerEdit); 
  const [pullState, setPullState] = useState<Array<any>>([]);
  const [orderModalOpen, setOrderModalOpen] = useState<boolean>(false);

  useEffect(() => {
    // Get the item details
    dispatch(getEventPlayers(id));
    dispatch(getEventById(id));
    // eslint-disable-next-line
  }, []);

  // Getting a list of players.
  useEffect(() => {
    // Get the item details
    if (eventDetail !== undefined && eventDetail.status !== 'Finalizado') {
      dispatch(getEventPrizeDetails(id));
      dispatch(getEventPlayers(id));
    }
    // eslint-disable-next-line
  }, [eventDetail]);

  useEffect(() => {
    if (
      (actionStatus?.message === 'status.success-update' || actionStatus?.message === 'status.success-register-order') &&
      actionStatus?.status === 'success'
    ) {
      if (eventDetail?.status !== 'Finalizado') {
        dispatch(getEventPlayers(id));
      }
      handleCloseModal();
      setCurrentPlayer({
        id: 0,
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: ''
      });
      if (actionStatus.message === 'status.success-register-order') {
        setOrderModalOpen(false);
      }
    }
    // eslint-disable-next-line
  }, [actionStatus]);

  useEffect(() => {  
    if (eventPlayers !== undefined) {
      availableOptions();
    }
    // eslint-disable-next-line
  }, [eventPlayers]);

  const awardsIsDone = () => {
    if (eventPrizeDetails?.totalAwardsData) {
      let countRealQuantity: number = 0;
      let countSuggestedQuantity: number = 0;
      eventPrizeDetails?.totalAwardsData.forEach((award: any, index: number) => {
        countRealQuantity =  countRealQuantity + award.realQuantity;
        countSuggestedQuantity = countSuggestedQuantity + award.suggestedQuantity 
      });

      return (countRealQuantity >= countSuggestedQuantity) ? true : false;
      
    } else {
      return false;
    }
  }

  //Check if any player is pending to redeem the participation
  const redeemParticipation = () => {
  try{
   for(let i=0;i<reduxEventState.eventPlayers?.length;i++){
     if(!reduxEventState.eventPlayers[i].redeemParticipation){
          return false;
      }
    }
        return true;
    }catch(err){
      console.error(err)
    } 
  }


  // Check if any player has an order pending
  const orderPending = ()=>{
    try{
      for(let i=0;i<reduxEventState.eventPlayers?.length;i++){
        if(reduxEventState.eventPlayers[i].orderOpen){
          return true
        }
        return false
      }
    }catch(err){
      console.error(err)
    }
    
  }
  const availableOptions = () => {
    if (reduxEventState.eventPlayers !== undefined) {
      const pull:any[] = [];
      let count:number = 0;
      //update pull of options available according the player's positions.
      reduxEventState.eventPlayers.forEach((player:any, index:number) => {
        pull.push({position: player.position, available: (player.position === 0 || player.position === null)? true : false });
        if (player.position === 0 || player.position === null) {
          count ++;
        }    
      });

      setPullState(pull);
      setEventOpen(false);

      if (count === 0) {
        setEventOpen(true);
      } 
    }
  }

  // Handlers functions.
  // Quantities to render.
  const renderOptions = (quantity: number) => {
    //Render the options and mark all of them if there are not available items.
    if (pullState.length) {
      const pull = pullState;
      const items = [];
      items.push(<MenuItem key={0} value={0}>{t('players.any')}</MenuItem>);
      for (let i = 1; i <= quantity; i++) {
        if(pull.find(item => item.position === i)){
          items.push(<MenuItem disabled key={i} value={i}>{i}</MenuItem>)
        } else {
          items.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
        }
      }
     
      return items;
    }

    //Render a generic list of options.
    const items = [];
    items.push(<MenuItem key={0} value={0}>{t('players.any')}</MenuItem>);
    for (let x = 1; x <= quantity; x++) {
      items.push(<MenuItem key={x} value={x}>{x}</MenuItem>);
    }
    
    return items;
  };

  const handleQuantityChange = (player: any,  position: unknown | string) => {
    if (player !== undefined && position !== undefined) {
      const requestObject = {
        id: player.id,
        position: position, 
      }
      dispatch(updateEventPlayer(requestObject, player.id));
    }
  }

  //Handling Modal Open for Orders
  const handleOrderModalOpen = (playerId:number,playerName:string) => {
    dispatch(updateCurrentOrderPlayer({id:playerId, name:playerName}))
    setOrderModalOpen(true);
  }

  //Handle Modal Close for Orders
   const handleOrderModalClose = () => {
    setOrderModalOpen(false);
  }

  const handleOpenModal = (player: any) => {
    const playerCurrent:PlayerType = {
      id: player.id, 
      firstName: player.firstName,
      lastName: player.lastName,
      email: player.email, 
      phoneNumber: player.phoneNumber  
    }
    setCurrentPlayer(playerCurrent);
    setOpenModal(true);
  }

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof currentPlayerEdit;
    var inputValue = String(event.target.value);

    setCurrentPlayerEdit({
      ...currentPlayerEdit,
      [name]: inputValue,
    });
  };

  const handleOpenModalEditNotesPlayer = (player: any) => {
    const playerCurrentEdit:PlayerType = {
      id: player.id,
      firstName: player.firstName,
      lastName: player.lastName,
      phoneNumber: player.phoneNumber,
      notes: player.notes,
    }

    setCurrentPlayerEdit(playerCurrentEdit);
    setOpenEditModal(true);
  }

  const editOnPlayerNotesClick = () => {
    dispatch(updateEventPlayer(currentPlayerEdit, currentPlayerEdit.id.toString()));
    dispatch(getEventPlayers(id));
    setOpenEditModal(false);
  }

  const handleClickCloseEvent = () => {
    setFinishedOpenModal(true);
  };

  const finishedEventOnClick = () => {
    let requestObject = {
      finished: true
    };

    dispatch(updateEventInformation(requestObject, id, true));
    handleCloseFinishedModal();
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleCloseFinishedModal = () => {
    setFinishedOpenModal(false);
  };

  const handleCompleteButton = (player:any) =>{
    let payload = {
      eventPlayerId:player.id, 
      paymentMethod:'Tarjeta'
    }
    dispatch(registerPlayerEventOrder(payload))
  }

  const awardOnClick = () => {
    history.push(`/admin/tournament/prize-pool-calculator/${id}`);
  }

  if (reduxEventState.eventPlayers === undefined || reduxEventState.eventPlayers.length === 0) {
    return (
      <></>
    );
  }

  return (
    <>
    <div className={ classes.root }>
      <Paper elevation={3} square={false} variant="elevation">
      {orderModalOpen && (
        <ModalOrders onClose={handleOrderModalClose}/>
      )}
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h4" color="primary" className={ classes.sectionTitle }>
              { t('events.list-players') } {(loading === 'EVENT_PLAYERS_LIST' || loading === 'EVENT_UPDATE_PLAYERS' || loading === 'EVENT_DETAIL') && <CircularProgress/>}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
          <Paper elevation={2} square={false} variant="elevation" className={`${classes.rootTable} variationsTable`}>
              <div className={classes.customTable} aria-label="Variations table">
                <div className="header">
                  <div className="cell d28 m20">{t('players.name')}</div>
                  <div className="cell d30 m25">{t('players.order')}</div>
                  <div className="cell d20">{t('players.participation-balance')}</div>
                  <div className="cell d20">{t('players.total')}</div>
                  <div className="cell d12">{t('players.position')}</div>
                  {(eventDetail !== undefined && eventDetail.status === 'Finalizado') && <>
                    <div className="cell d20">{t('players.redeemed-oc')}</div>
                    <div className="cell d20">{t('players.balance')}</div>
                  </>
                  }
                  <div className="cell d20">{t('players.notes')}</div>
                  {(eventDetail !== undefined && eventDetail.status !== 'Finalizado') && 
                    <div className="cell d10"></div>
                  }
                </div>
                {
                  reduxEventState.eventPlayers !== undefined &&
                  (reduxEventState.eventPlayers.sort((a:any, b:any) => (a.position != null ? a.position : Infinity) - (b.position != null ? b.position : Infinity)).map((player: any, i: number) =>
                    <Fragment key={i}>
                      <div className="body">
                        <div className="row">
                          <div className="cell d28 m20">
                            <div className={classes.playerName}>
                              {player.firstName} {player.lastName} {(player.anonymous) && 
                                <VisibilityOffIcon 
                                  color={ (eventDetail !== undefined && eventDetail.status !== 'Finalizado') ? "primary" : "info" } 
                                  fontSize="small" 
                                  onClick={ (eventDetail !== undefined && eventDetail.status !== 'Finalizado') ? (e) => handleOpenModal(player) : (e) => ({})}
                                />
                              }  
                            </div>
                          </div>
                          <div className="cell d30 m25">
                          {(eventDetail !== undefined && eventDetail.status !== 'Finalizado') ?
                          <>
                            {player.orderOpen?
                            <div className={classes.orderStatusIcon} >
                            <span onClick={()=>{handleOrderModalOpen(player.id, player.firstName)}} style={{marginTop:"2px"}}>{t('tournament.see-order')}</span>
                            <ProductionQuantityLimits/>
                            <Button 
                              variant="contained" 
                              color="primary" 
                              className={ classes.buttonsCompleteOrder} 
                              onClick={()=>{handleCompleteButton(player)}}
                              disabled={loading}
                            >{t('tournament.complete')}</Button>
                              </div>: 
                            <div className={classes.orderStatusIcon} onClick={()=>{handleOrderModalOpen(player.id, player.firstName)}}>
                              <span style={{marginTop:"2px"}}>{t('tournament.order-completed')}</span>
                              <Check/>
                              </div>
                            }
                          </>
                          : 
                            <>
                            {player.orderOpen?
                            <div>
                            <span style={{marginTop:"2px"}}>{t('tournament.see-order')}</span>
                            <Button 
                              variant="contained" 
                              color="primary" 
                              className={ classes.buttonsCompleteOrder}
                            >{t('tournament.complete')}</Button>
                              </div>: 
                            <div>
                              <span style={{marginTop:"2px"}}>{t('tournament.order-completed')}</span>
                            </div>
                            }
                            </>
                          } 
                          </div>
                          <div className="cell d20 small priceCol">{player.participationBalance}</div>
                          <div className="cell d20 small priceCol">{player.totalOrderPending}</div>
                          <div className="cell d12 m30">
                          {(eventDetail !== undefined && eventDetail.status !== 'Finalizado') ? 
                            <div className={classes.quantityWrapper}>
                              <FormControl>
                                <Select
                                  id={`${player.id}`}
                                  className={classes.inputQty}
                                  value={(player.position !== null) ? player.position : 0}
                                  onChange={(e) => handleQuantityChange(player, e.target.value)}
                                  >
                                    {renderOptions(reduxEventState.eventDetail.totalPlayers ? reduxEventState.eventDetail.totalPlayers : 1)}
                                </Select>
                              </FormControl>
                            </div> :
                            <div>
                              {(player.position !== null) ? player.position : 0}
                            </div>
                          }
                          </div>
                          {(eventDetail !== undefined && eventDetail.status === 'Finalizado') && <>
                            <div className="cell d20 small priceCol">{player.redeemPoints ? 'Sí' : 'No'}</div>
                            <div className="cell d20 small priceCol">{player.pointsRedeemed}</div>
                          </>
                          }
                          <div className="cell d20">
                            { player.notes }
                          </div>
                          {(eventDetail !== undefined && eventDetail.status !== 'Finalizado') && 
                            <div className="cell d10">
                              <EditIcon color="primary" fontSize="small" onClick={(e) => handleOpenModalEditNotesPlayer(player)}/>
                            </div>
                          }
                        </div>
                      </div>
                    </Fragment>
                  ))
                }
              </div>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            {(eventDetail !== undefined && eventDetail.status !== 'Finalizado') &&
            <div className={ classes.buttons }>
              <Button 
                variant="contained" 
                color="primary" 
                disabled={(eventDetail !== undefined && eventDetail.status === 'Finalizado')} 
                className={ classes.buttonsLast } 
                onClick={ awardOnClick }>
                <Typography variant="h5">
                  { t('events.award-btn') }
                </Typography>
              </Button>
              <Button 
                
                variant="contained" 
                color="primary" 
                disabled={(reduxEventState.eventDetail !== undefined && reduxEventState.eventDetail.status === 'Finalizado') 
                  || (reduxEventState.eventDetail !== undefined && reduxEventState.eventDetail.totalPlayers === 0)
                  || (eventPrizeDetails?.totalAwardsData === null) 
                  || !eventOpen
                  || !awardsIsDone()
                  || !redeemParticipation()
                  || orderPending()
                }
                className={ classes.buttonsLast }
                onClick={ handleClickCloseEvent }>
                <Typography variant="h5">
                { t('events.finish-event-btn') }
                </Typography>
              </Button>
            </div>
            }
          </Grid>
        </Grid>
      </Paper>
      <Modal
        open={ openModal }
        onClose={ handleCloseModal }
        aria-labelledby="Add New User Modal"
        className='modal-form'
      >
        <Paper elevation={3} className={ classes.modalPaper }>
          <IconButton className={ classes.closeModal } aria-label="close modal" component="span" onClick={ () => handleCloseModal() }>
            <CloseIcon/>
          </IconButton>
          <Typography variant="h5" color="primary">{ t('players.add-new') }</Typography>
          <RegisterAnonymousPlayerForm player={currentPlayer} />
        </Paper>
      </Modal>
      <Modal
        open={ openEditModal }
        onClose={ handleCloseEditModal }
        aria-labelledby="Edit player notes"
        className='modal-form'
      >
        <Paper elevation={3} className={ classes.modalPaper }>
          <IconButton className={ classes.closeModal } aria-label="close modal" component="span" onClick={ () => handleCloseEditModal() }>
            <CloseIcon/>
          </IconButton>
          <Typography variant="h5" color="primary">{ t('players.edit-title') }</Typography>
          <form noValidate autoComplete="off" className={`add-form edit-player`}>
            <FormControl className={classes.formControl}>
              <TextField
                className={classes.formField} 
                id="b-notes" name="notes" 
                value={ currentPlayerEdit.notes? currentPlayerEdit.notes : "" }
                onChange={ handleChange  }
              />
            </FormControl>
            <div className="form-controls ">
              { loading === 'EVENT_PLAYERS_LIST' && <CircularProgress /> }
              <Button variant="contained" color="primary" onClick={ editOnPlayerNotesClick }> { t('players.update-notes') } </Button>
            </div>
          </form>
        </Paper>
      </Modal>
      <Modal
        open={ openFinishedModal }
        onClose={ handleCloseFinishedModal }
        aria-labelledby="Confirm Modal"
        >
        <Paper elevation={3} className={ classes.modalPaper + ` ` + classes.modalPaperConfirm }>
          <IconButton className={ classes.closeModal } aria-label="close modal" component="span" onClick={ () => handleCloseFinishedModal() }>
            <CloseIcon/>
          </IconButton>
          <Typography>{ t('players.delete-confirm-text') }</Typography>
          <Button variant="contained" color="primary" onClick={ () => handleCloseFinishedModal() }> { t('players.no') } </Button>
          <Button variant="contained" color="secondary" onClick={ () => finishedEventOnClick() }> { t('players.yes') } </Button>
        </Paper>
      </Modal>
    </div>
    <Backdrop className={classes.backdrop} open={loading === 'EVENT_UPDATE_FINISHED'}>
      <Paper>
        <CircularProgress color="inherit" />
        <Typography>{t('events.processing')}</Typography>
      </Paper>
    </Backdrop>
    </>
  )
}

export default PlayerList;
