// React.
import React, { Fragment, useEffect, useState } from 'react';
import ShowMoreText from "react-show-more-text";

// Global Types.
import { EventDatailResponse } from '../models/ModelTypes';

// Material UI.
import { Button, CircularProgress, FormControl, Grid, Paper, TextField, Typography } from '@material-ui/core';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getEventById, updateEventInformation } from '../../../actions/eventActions/eventActions';
import { RootStore } from "../../../Store";

// Styles.
import useStyles from './EventEditStyles';

// Translations.
import { useTranslation } from 'react-i18next';
import { getProjectConfig } from '../../../getProjectConfig';

interface EventEditProps {
  id: string;
};

const EventEdit = ({ id } : EventEditProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation('global');
  
  useEffect(() => {
    // Get the item details
    dispatch(getEventById(id));

    // eslint-disable-next-line
  }, []);

  // Redux state.
  const reduxEventState = useSelector((state: RootStore) => state.events);
  const { eventDetail, loading, actionStatus } = reduxEventState;

  // Default event object value.
  const defaultEvent: EventDatailResponse = {
    id: 0,
    name: "",
    itemTypeName: "",
    entryFee: 0,
    participationFee:0,
    status: "",
    sellerFullName: "",
    code: "",
    totalPlayers: 0,
    totalFees: 0,
    notes: "",
    itemId: 0,
    itemName: "",
    vendorName:"",
  };
  
  let currentEvent = eventDetail !== undefined ? eventDetail : defaultEvent;

  // Define local state for the event.
  const [eventState, setEventState] = useState<EventDatailResponse>(currentEvent);
  const [editing, setEditing] = useState<boolean | false>(false);
  const [errorArray, setErrorArray] = useState<Array<string>>([]);

  useEffect(() => {
    // Get the item details
    if (eventDetail !== undefined) {
      setEventState(eventDetail);
    }
      
    if (actionStatus !== undefined) {
      if (actionStatus.message === 'status.success-update' && actionStatus.status === 'success') {
        // Get the item details
        dispatch(getEventById(id));
      }
    }
    // eslint-disable-next-line
  }, [eventDetail !== undefined, actionStatus]);

  // Handlers functions.
  // Handles the field changes to update the state.
  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    // Use the HTML name atribute to know what property update.
    const name = event.target.name as keyof typeof eventState;
    var inputValue = String(event.target.value);

    // Filter incorrect numbers
    if (name === 'entryFee') {
      const re = /^[0-9\b]+$/;
      const newValue = String(inputValue);
      if (!re.test(newValue)) {
        inputValue = '';
      }
    }

    setEventState({
      ...eventState,
      [name]: inputValue,
    });

    // Validate and add errors.
    const errors = [...errorArray];
    const nameString = '' + [name]
    if (inputValue === '') {
      errors.push(nameString);
      setErrorArray(errors);
    } else {
      const newErrors = errors.filter(function(value) { 
        return value !== nameString;
      });
      setErrorArray(newErrors);
    }
  };

  const handleChangeValidation = (inputName: string) => {
    var result = false;
    errorArray.forEach((key) => {
      if (key === inputName) {
        result = true;
      }
    });

    return result;
  };
  
  const validateFields = () => {
    var result = true;
    const errors = [...errorArray];
    if (eventState.name === '') {
      errors.push('name');
      result = false;
    }
    if (eventState.code === '') {
      errors.push('code');
      result = false;
    }
    if (eventState.notes === '') {
      errors.push('notes');
      result = false;
    }

    setErrorArray(errors);

    return result;
  };

  // Actions functions.
  // Saving the event changes.
  const saveOnClick = () => {
    // Save the bundle on the BE.
    const validation = validateFields();
    if (errorArray.length === 0 && validation) {
      let requestObject = {
        code: eventState.code,
        name: eventState.name,
        finished: false,
        notes: eventState.notes
      };

      dispatch(updateEventInformation(requestObject, id));
    }
    setEditing(false);
  };

  const editOnClick = () => {
    setEditing(true);
    if (eventDetail !== undefined) {
      setEventState(eventDetail);
    }
  };

  const cancelOnClick = () => {
    setEditing(false);

    // Set user state to original values.
    setEventState(eventState);
    setErrorArray([]);
  };

  if (eventDetail === undefined) {
    return (
      <Fragment>
        {loading === 'EVENT_DETAIL' && <CircularProgress/>}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div className={ classes.root }>
          <Paper elevation={3} square={false} variant="elevation">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
              <div className={ classes.boxTop }>
                  <Typography variant="h4" color="primary" className={ classes.sectionTitle }>
                    { eventDetail.name }
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} 
              style={{display:'flex', justifyContent:'center', alignItems:'center'}}
              >
              <div style={{textAlign:'center'}}>
              <p>{t('events.code').toUpperCase()}: {eventDetail.code}</p>
              <Typography variant="h4" color="primary" className={ classes.statusText }>
                    { eventDetail.status }
              </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                {!editing ?
                <div className={ classes.detailText }>
                  <p><span>{ t('events.category') }:</span> <span>{eventDetail.itemTypeName}</span></p>
                  <p><span>{ t('events.entryFee') }:</span> <span>{getProjectConfig().CURRENCY_SYMBOL}{ eventDetail.entryFee }</span></p>
                  <p><span>{ t('tournament.tournament-fee') }:</span> <span>{getProjectConfig().CURRENCY_SYMBOL}{ eventDetail.participationFee }</span></p>
                  <p><span>{ t('tournament.final-prize') }:</span> <span>{getProjectConfig().CURRENCY_SYMBOL}{ eventDetail.participationFee + eventDetail.entryFee }</span></p>
                  <p><span>{ t('events.total-players') }:</span> <span> { eventDetail.totalPlayers }</span></p>
                  <p><span>{ t('events.vendor') }:</span> <span> { eventDetail.sellerFullName }</span></p>
                  <p><span>{ t('events.store') }:</span> <span> { eventDetail.vendorName }</span></p>
                  </div> : 
                <form className={ classes.boxForm }>
                  <FormControl className={classes.formControl}>
                    <Typography variant="caption">{ t('events.name') }</Typography>
                    <TextField 
                      className={classes.formField} 
                      id="e-name" name="name" 
                      value={ eventState.name } 
                      onChange={ handleChange } 
                      disabled={!editing}
                      autoFocus
                      error={ handleChangeValidation('name') }
                      helperText={handleChangeValidation('name') ? t('events.field-required'): ''}
                    />
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <Typography variant="caption">{ t('events.code') }</Typography>
                    <TextField 
                      className={classes.formField} 
                      id="e-code" name="code" 
                      value={ eventState.code } 
                      onChange={ handleChange } 
                      disabled={!editing}
                      autoFocus
                      error={ handleChangeValidation('code') }
                      helperText={handleChangeValidation('code') ? t('events.field-required'): ''}
                    />
                  </FormControl>

                  <FormControl className={classes.formControl}>
                      <Typography variant="caption">{ t('events.notes') }</Typography>
                      {editing ?
                        <TextField
                          multiline
                          maxRows={8}
                          className={classes.formField} 
                          id="b-notes" name="notes" 
                          value={ eventState.notes? eventState.notes : "" } 
                          onChange={ handleChange } 
                          disabled={!editing}
                          error={ handleChangeValidation('notes') }
                          helperText={handleChangeValidation('notes') ? t('events.field-required'): ''}
                        />:
                        <Typography className={ classes.showMore } color="textSecondary">
                          <ShowMoreText
                            lines={3}
                            more={ t('items.see-more') }
                            less={false}
                            expanded={false}
                            truncatedEndingComponent={"... "}
                          >
                            { eventState.notes }
                          </ShowMoreText>
                        </Typography>
                      }
                  </FormControl>
                </form>
                }
              </Grid>
              <Grid item xs={12} sm={12} style={{borderTop:'solid', borderTopWidth:'thin'}}>
                <div>
                  {eventDetail.notes!==null?<p>{t('orders.notes')}:{eventDetail.notes}</p>:''}
                  <FormControl className={ classes.formControl }>
                      {editing && (
                        <div className={ classes.actionsWrapper }>
                          {loading === 'EVENT_UPDATE' && <CircularProgress/> }
                          <Button variant="text" color="primary" onClick={ saveOnClick } disabled={errorArray.length !== 0}>
                            { t('events.save') }
                          </Button>
                          <Button variant="contained" color="secondary" onClick={ cancelOnClick }>
                            { t('events.cancel') }
                          </Button>
                          <Button variant="contained" color="primary" disabled>
                            { t('events.edit-event') }
                          </Button>
                        </div>
                      )}
                      {!editing && (
                        <div className={ classes.actionsWrapper }>
                          <Button variant="contained" color="primary" disabled={eventDetail !== undefined && eventDetail.status === 'Finalizado'} onClick={ editOnClick }>
                            { t('events.edit-event') }
                          </Button>
                        </div>
                      )}
                    </FormControl>
                </div>
              </Grid>
            </Grid>
          </Paper>
      </div>
    </Fragment>
  )
}

export default EventEdit;