// React.
import React, { Fragment, useEffect, useState } from 'react';

// Material UI.
import { Button, CircularProgress, Grid, IconButton, Modal, Paper, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

// Styles.
import useStyles from './TempPlayerListStyles';

// Translations.
import { useTranslation } from 'react-i18next';

// Redux.
import { useDispatch, useSelector } from "react-redux";
import { registerEventPlayers, getEventById, getEventPlayers } from "../../../actions/eventActions/eventActions";
import { RootStore } from '../../../Store';

interface PlayerListProps {
  playersTemp: any;
};
const TempPlayerList = ({ playersTemp } : PlayerListProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();

  // Redux state.
  const reduxEventState:any = useSelector((state: RootStore) => state.events);
  const { loading, eventPlayers, eventDetail, actionStatus } = reduxEventState;

  // Define local state for the list of players.
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [players, setPlayers] = useState<any>(playersTemp);

  useEffect(() => {
    const tempPlayers = localStorage.getItem('temp-players');
    if (tempPlayers) {
      setPlayers(JSON.parse(tempPlayers));
    }
  }, [playersTemp]);
  
  useEffect(() => {  
    if (actionStatus !== undefined) {
      if (actionStatus.message === 'Jugadores del evento registrado' && actionStatus.status === 'success') {
        // Remove key-value, it removes data by key when the players were registered.
        localStorage.removeItem('temp-players');
        // Get the players list again.
        if (eventDetail !== undefined && eventDetail.status !== 'Finalizado') {
          dispatch(getEventPlayers(eventDetail.id));
        }
        dispatch(getEventById(eventDetail.id));
      }
    }

    // eslint-disable-next-line
  }, [actionStatus]);

  const handleDeletePlayer = (player: any) => {
    let tempPlayersData = [];
    const tempPlayers = localStorage.getItem('temp-players');
    
    if (tempPlayers) {
      tempPlayersData = JSON.parse(tempPlayers);
    }

    const newtempPlayersData = removePlayerByTempKey(tempPlayersData, player.tempKey);

    setPlayers(newtempPlayersData);
    //Saving players on localstorage.
    localStorage.setItem('temp-players', JSON.stringify(newtempPlayersData));
  }

  const removePlayerByTempKey = (tempPlayersArray: any, tempKey: string) => {
    // Making a copy with the Array from() method
    const tempPlayersArrayCopy = Array.from(tempPlayersArray);
    const objWithIdIndex = tempPlayersArrayCopy.findIndex((obj: any) => obj.tempKey === tempKey);
    tempPlayersArrayCopy.splice(objWithIdIndex, 1);

    return tempPlayersArrayCopy;
  }

  const savePlayersOnClick = () => {
    const tempPlayersList = localStorage.getItem('temp-players');
    if (tempPlayersList && eventDetail) {
      dispatch(registerEventPlayers(JSON.parse(tempPlayersList), eventDetail.id));
    }

    handleCloseModal();
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  if (eventPlayers !== undefined && eventPlayers.length > 0) {
    return (
      <></>
    );
  }

  return (
    <div className={ classes.root }>
      <Paper elevation={3} square={false} variant="elevation">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h4" color="primary" className={ classes.sectionTitle }>
              { t('events.temp-list-players') } {(loading === 'EVENT_PLAYERS_LIST' || loading === 'EVENT_DETAIL') && <CircularProgress/>}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
          <Paper elevation={2} square={false} variant="elevation" className={`${classes.rootTable} variationsTable`}>
              <div className={classes.customTable} aria-label="Variations table">
                <div className="header">
                  <div className="cell d28 m20">{t('players.name')}</div>
                  <div className="cell d28 m20">{t('players.last-name')}</div>
                  <div className="cell d30 m25">{t('players.email')}</div>
                  <div className="cell d20">{t('players.phone-number')}</div>
                  <div className="cell d10"></div>
                </div>
                <div className="body">
                  {localStorage.getItem('temp-players') !== null &&
                    (players.map((player: any, i: number) =>
                      <Fragment key={i}>
                        <div className="row">
                          <div className="cell d28 m20">
                            <div className={classes.playerName}>
                              {player.firstName}
                            </div>
                          </div>
                          <div className="cell d28 m20 ">
                            <div className={classes.playerName}>
                              {player.lastName} 
                            </div>
                          </div>
                          <div className="cell d30 m25">
                            {player.email}{player.userEmail}
                          </div>
                          <div className="cell d20">
                            {player.phoneNumber} 
                          </div>
                          <div className="cell d10">
                            <DeleteIcon color="primary" fontSize="small" onClick={(e) => handleDeletePlayer(player)}/>
                          </div>
                        </div>
                      </Fragment>
                    ))
                  }
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <div className={ classes.buttons }>
              <Button 
                variant="contained" 
                color="primary" 
                disabled={(eventDetail !== undefined && eventDetail.status === 'Finalizado') || players.length === 0} 
                className={ classes.buttonsLast } 
                onClick={ handleOpenModal }>
                <Typography variant="h5">
                  { t('events.temp-btn') }
                </Typography>
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
      
      <Modal
        open={ openModal }
        onClose={ handleCloseModal }
        aria-labelledby="Confirm Modal"
        >
        <Paper elevation={3} className={ classes.modalPaper + ` ` + classes.modalPaperConfirm }>
          <IconButton className={ classes.closeModal } aria-label="close modal" component="span" onClick={ () => handleCloseModal() }>
            <CloseIcon/>
          </IconButton>
          <Typography>{ t('players.temp-add-players-confirm-text') }</Typography>
          <Button variant="contained" color="primary" onClick={ () => handleCloseModal() }> { t('players.no') } </Button>
          <Button variant="contained" color="secondary" onClick={ () => savePlayersOnClick() }> { t('players.yes') } </Button>
        </Paper>
      </Modal>
    </div>
  )
}

export default TempPlayerList;
