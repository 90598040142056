import {Dispatch} from "redux";
import axios from 'axios';
import {
  READ_NOTIFICATION, NOTIFICATION_LOADING, NOTIFICATION_FAIL, GET_USER_NOTIFICATIONS, GET_ORDER_NOTIFICATIONS,
  NotificationDispatchTypes
} from "./NotificationActionsTypes";

const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;

/**
 * Create a order on the BE.
 * @param {number} id
 */
export const readNotification = (id: number) => async (dispatch: Dispatch<NotificationDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: NOTIFICATION_LOADING,
        payload: 'READING_NOTIFICATION'
      })

      const bodyForm = {
        id: id,
      };

      const configPOST = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
    
      const addRes = await axios.post(`${apiURL}/user/readNotification`, bodyForm, configPOST);

      if (addRes.status === 200) {
        dispatch({
          type: READ_NOTIFICATION,
          payload: 'status.success-read'
        })
      }
       
    } catch(e) {
      dispatch({
        type: NOTIFICATION_FAIL,
        payload: 'status.read-error'
      })
    }
  } else {
    dispatch({
      type: NOTIFICATION_FAIL,
      payload: 'status.read-error'
    })
  }
};

/**
 * Set all notifications as read.
 */
export const readAllNotifications = () => async (dispatch: Dispatch<NotificationDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: NOTIFICATION_LOADING,
        payload: 'READING_NOTIFICATIONS'
      })

      const configPOST = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };

      const addRes = await axios.put(`${apiURL}/user/readAllNotifications`, [], configPOST);

      if (addRes.status === 200) {
        dispatch({
          type: READ_NOTIFICATION,
          payload: 'status.success-read'
        })
      }

    } catch (e) {
      dispatch({
        type: NOTIFICATION_FAIL,
        payload: 'status.read-error'
      })
    }
  } else {
    dispatch({
      type: NOTIFICATION_FAIL,
      payload: 'status.read-error'
    })
  }
};

/**
 * Get all user notifications.
 */
export const getUserNotifications = () => async (dispatch: Dispatch<NotificationDispatchTypes>) => {
  const sessionToken = localStorage.token;

  if (apiURL !== '' && sessionToken !== '') {

    try {
      dispatch({
        type: NOTIFICATION_LOADING,
        payload: 'GET_NOTIFICATIONS'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + sessionToken
      }

      const qParams = {
        page: 0,
        size: 10,
        paged: true,
      };

      const response = await axios.get(`${apiURL}/user/getUserNotifications`, {
        headers: headers,
        params: qParams,
      });
      if (response.status === 200) {
        dispatch({
          type: GET_USER_NOTIFICATIONS,
          payload: response.data.data
        })
      }

    } catch (e) {
      dispatch({
        type: NOTIFICATION_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: NOTIFICATION_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Get all seller order notifications.
 */
export const getOrderNotifications = () => async (dispatch: Dispatch<NotificationDispatchTypes>) => {
  const sessionToken = localStorage.token;

  if (apiURL !== '' && sessionToken !== '') {

    try {
      dispatch({
        type: NOTIFICATION_LOADING,
        payload: 'GET_ORDER_NOTIFICATIONS'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + sessionToken
      }

      const qParams = {
        page: 0,
        size: 10,
        paged: true,
      };

      const response = await axios.get(`${apiURL}/seller/getSellerHeader`, {
        headers: headers,
        params: qParams,
      });
      if (response.status === 200) {
        dispatch({
          type: GET_ORDER_NOTIFICATIONS,
          payload: response.data.data
        })
      }

    } catch (e) {
      dispatch({
        type: NOTIFICATION_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: NOTIFICATION_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Get all seller order notifications for admin singles.
 */
export const getAdminSinglesOrderNotifications = () => async (dispatch: Dispatch<NotificationDispatchTypes>) => {
  const sessionToken = localStorage.token;

  if (apiURL !== '' && sessionToken !== '') {

    try {
      dispatch({
        type: NOTIFICATION_LOADING,
        payload: 'GET_ORDER_NOTIFICATIONS'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + sessionToken
      }

      const qParams = {
        page: 0,
        size: 10,
        paged: true,
      };

      const response = await axios.get(`${apiURL}/admin-singles/getSellerHeader`, {
        headers: headers,
        params: qParams,
      });
      if (response.status === 200) {
        dispatch({
          type: GET_ORDER_NOTIFICATIONS,
          payload: response.data.data
        })
      }

    } catch (e) {
      dispatch({
        type: NOTIFICATION_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: NOTIFICATION_FAIL,
      payload: 'status.get-error'
    })
  }
};
