// React.
import React, { useState, useEffect } from 'react';

// Material UI
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../Store";
import {getParticipationItems } from "../../../../actions/itemActions/ItemActions";

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './OrderBoosterSearchModalStyles';

// Model types.
import { ItemParticipationType } from '../../models/ModelTypes';
import { getVendors } from '../../../../actions/vendorActions/VendorActions';
import { registerItem } from '../../../../actions/eventActions/eventActions';

const OrderBoosterSearchModal = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();

  // Local component state.
  const [currentItem, setCurrentItem] = useState<ItemParticipationType | undefined>(undefined);
  const [typingValue, setTypingValue] = useState<string>('');
  const [itemQuantity, setItemQuantity] = useState<number | undefined>(undefined);
  const [quantityError, setQuantityError] = useState(false);
  const [searchOptions, setSearchOptions] = useState<Array<any>>([]);

  // Redux state.
  const itemState = useSelector((state: RootStore) => state.item);
  const itemParticipationList = itemState.itemParticipationList;
  const eventState = useSelector((state: RootStore) => state.events);

  useEffect(() => {
    dispatch(getVendors());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (typingValue !== '') {
      const delayDebounceFn = setTimeout(
        () => triggerSearchItemsAutocomplete(),
        500
      );
      return () => clearTimeout(delayDebounceFn)
    }
    // eslint-disable-next-line
  }, [typingValue]);

  // Update the search options array.
  useEffect(() => {
    if (itemParticipationList !== undefined) {
      var optionsList: Array<any> = [];
     
      // Add items results.
      itemParticipationList?.items.forEach((option: any) => {
        optionsList.push({ element: option, type: 'item' });
      });
      setSearchOptions(optionsList);
    }
    // eslint-disable-next-line
  }, [itemParticipationList]);
  const triggerSearchItemsAutocomplete = () => {
    const qParamsItems = { eventId: eventState.eventDetail?.id, itemName:typingValue, isPromo: false};
    // Search normal items.
    dispatch(getParticipationItems(qParamsItems, 'seller'));
  };
  const handleChangeItemName = (event:any,selectedItem: any) => {
     setCurrentItem(selectedItem.element);
     if (selectedItem.element.totalQuantity!==undefined && selectedItem.element.totalQuantity > 0) {
      setItemQuantity(1);
      setQuantityError(false);
    }

  };
  const handleChangeTyping = (event: React.ChangeEvent<{ value: string }>) => {
    setTypingValue(event.target.value);
    if (event.target.value === '') {
      setCurrentItem(undefined);
      setItemQuantity(undefined);
    }
  };
  
  const handleChangeItemQuantity = (event: React.ChangeEvent<{ value: string }>) => {
      const value = parseInt(event.target.value);
      const currentItemQuantity=currentItem?.totalQuantity;
      if(currentItemQuantity!==undefined){
        if(currentItemQuantity>0 && value<=currentItemQuantity){
          setItemQuantity(value)  
          setQuantityError(false)  
        }else{
          //Se tira el error de que se estan asignando mas que en stock
          setItemQuantity(value)    
          setQuantityError(true)
        }
      }else{
        setItemQuantity(0)
      }      

  };

  const numberFormat = (value: number) => {
    var roundedNumber = Math.round(value);
    return roundedNumber.toLocaleString('en-US')
  }
  const getVendorOptionLabel: any = (option: any): string => {
    if (option.type !== undefined) {
      if (option.type === 'item') {
        const price = option.element.price ? `${t('items.normal-price')}: ` + numberFormat(option.element.price) : '';
        let priceString = `${price}`;
        return `${option.element.itemName} | Stock: ${option.element.totalQuantity} | ${priceString}`;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
  const addItemOrder = () => {
    if (itemQuantity !== undefined) {
      // Validate if the item is already added.
      // For normal items.
      if (currentItem !== undefined) {
        let payload= {
         eventPlayerId:eventState.currentPlayerOrderId?.id,
         itemId:currentItem.itemId,
         quantity:itemQuantity,
         isParticipation: true 
        }
        dispatch(registerItem(payload))
      }
    
    }
  };
  return (
    <div className={classes.root}>
        <div className={classes.boxTop}>
          <ConfirmationNumberIcon color="primary" fontSize="medium" />
          <Typography color="primary">
          {t('events.add-participation')}
          </Typography>        
        </div>
        <div className={classes.boxBottom}>
          <form className={classes.boxForm}>
            <Grid container spacing={3}>
              <Grid item xs={10} sm={10}>
                <Autocomplete
                  freeSolo
                  disableClearable
                  id="item-name-autocomplete"
                  value={ ''}
                  onChange={handleChangeItemName}
                  options={searchOptions}
                  getOptionLabel={getVendorOptionLabel}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="search-name"
                      name="search-name"
                      label={t('orders.add-item-name')}
                      value={typingValue !== '' ? typingValue : ''}
                      onChange={handleChangeTyping}
                      className={classes.fieldClass}
                      InputProps={{ ...params.InputProps, type: 'search' }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} sm={2}>
                <TextField
                  id="item-quantity"
                  name="quantity"
                  type="number"
                  value={itemQuantity !== undefined ? itemQuantity : ''}
                  label={t('orders.add-item-quantity')}
                  className={classes.fieldClass}
                  onChange={handleChangeItemQuantity}
                  error={quantityError}
                  helperText={quantityError ? t('orders.quantity-greater-error') : ''}
                  onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                 
                />
              </Grid>
            </Grid>
          </form>
          <div className={classes.buttons}>
          <Button onClick={addItemOrder} variant="contained" color="primary" className={classes.buttonsLast} disabled={quantityError || itemQuantity === undefined}>
            {t('tournament.add')}
          </Button>
          </div>
          </div>       
        </div>
  )
}

export default OrderBoosterSearchModal;