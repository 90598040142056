// React.
import { useCallback, useEffect, useState } from 'react';

// Material UI
import Paper from '@material-ui/core/Paper';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@mui/material';

// Components.
import Pagination from '../utils/ResultsPagination'


// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './tournamentListStyles';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { updatePagination } from '../../../actions/eventActions/eventActions';
import { RootStore } from '../../../Store';
import { useHistory, useLocation } from 'react-router-dom';

//Moment
const moment = require('moment');


const TournamentList = () => {
//Local State
  const [mounted, setMounted] = useState(false);

  const location=useLocation();
//History
  const history = useHistory();
// Redux state.
  const eventState:any = useSelector((state: RootStore) => state.events);


//Handles the Pagination 

const page = useCallback(() => {
  const searchParams = new URLSearchParams(location.search);
  const result = searchParams.get("page");
  return result !== null && !isNaN(parseInt(result, 10)) ? parseInt(result, 10) : 1;
}, [location.search]);


function EventsPagination(eventListState:any) {
  const [stateChanged, setStateChanged] = useState(false);

  useEffect(() => {
    setStateChanged(true);
  }, [eventListState]);

  const totalPages = eventListState?.totalPages || 1;

  return (
    <div>
      {stateChanged && (
        <Pagination current={page()} path="/admin/tournament" pagesNumber={totalPages} />
      )}
    </div>
  );
}

  const dispatch = useDispatch();
  const classes = useStyles();
  const [t] = useTranslation('global');
  
  //Sending the pagination state to redux store
  useEffect(()=>{
    if(mounted){
      const sendFilter = {
        ...eventState.filter,
        pageSize:30,
        size:30,
        page:page()-1
      };
      dispatch(updatePagination(sendFilter))
    }else{
      setMounted(true);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dispatch,page])

    const handleRedirection=(id:number)=>{
      history.push(`/admin/tournament/${id}`);
    }

  return (
    <div className={ classes.root }>
      <Paper elevation={3}>
        <div className={ classes.boxTop }>
          <TrackChangesIcon color="primary" fontSize="large"/>
          <Typography variant="h5" color="primary" className={ classes.sectionTitle }>
            { t('orders.search-results') }
          </Typography>
        </div>
        <div style={{display:'flex', justifyContent:'end'}}>
        <div className={classes.EventContainer}>  
        {
        eventState.loading===''? 
        eventState.eventList?.content!==undefined?
        eventState.eventList?.content.map((event:any)=>{
            return(
                <div>     
                {event.status==='Abierto'?
                <Paper className={classes.EventBox} style={{border:'solid', borderColor:'green'}}>
                <h1 className={classes.eventName}>{event.name}</h1>
                <div className={classes.eventStatus} style={{color:'green'}}>{t('tournament.open')}</div>
                <div className={classes.eventDate}>{moment(event.creationDate).format('MM/DD/YYYY HH:mm')}</div>
                <Button variant="contained" color="primary" className={classes.eventButton} onClick={()=>{handleRedirection(event.id)}}>{t('tournament.view-more')}</Button >
                </Paper>
                :
                <Paper className={classes.EventBox} style={{border:'solid', borderColor:'gray'}}>
                <h1 className={classes.eventName}>{event.name}</h1>
                <div className={classes.eventStatus} style={{color:'red'}}>{t('tournament.close')}</div>
                <div className={classes.eventDate}>{moment(event.creationDate).format('MM/DD/YYYY HH:mm')}</div>
                <Button variant="contained" color="primary" className={classes.eventButton} onClick={()=>{handleRedirection(event.id)}}>{t('tournament.view-more')}</Button >
                </Paper>
                }             
                </div>           
            )           
             }):<CircularProgress/>:
              eventState.loading==='loading'? <CircularProgress/>:
              eventState.loading==='error'? <Typography variant="h6" style={{justifyContent:'space-around'}} color="primary">{t('status.get-error')}</Typography>:''
}
        </div>
        </div>
        {EventsPagination(eventState.eventList)}
      </Paper>
    </div>
  )
}

export default TournamentList;
