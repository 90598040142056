// React.
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

//  JWT decode token.
import jwtDecode from 'jwt-decode';


interface AdminRouteProps extends RouteProps{
 component:any;
 rest?:any
}

const AdminRoute: React.SFC<AdminRouteProps> = ({ component: Component, authenticated, ...rest }:any) => (
 <Route
  {...rest}
    render={(props) => {
      const authToken = localStorage.token;
      if (authToken !== undefined && authToken !== '') {
        const decodedToken:any = jwtDecode(authToken);
        if (decodedToken.auth.includes('ROLE_ADMIN')) {
          return <Component {...props} /> 
        } else {
          return <Redirect to={{ pathname: '/error-403', state: { status: 403 }}}  />;
        }
      } else {
        return <Redirect to={{ pathname: '/user/login', state: { from: props.location }}}  />;
      }
    }
  }
  />
);

export default AdminRoute;