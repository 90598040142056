// React.
import React from 'react';

// Material UI.
import Paper from '@material-ui/core/Paper';

// Components.
import SliderSettings from '../settings/SliderSettings';

// Styles.
import useStyles from './SettingsStyles';

const SliderSettingsPage = () => {
  const classes = useStyles();

  return (
    <Paper className={ classes.paper } elevation={3} square={false} variant="elevation">
      <SliderSettings/>
    </Paper>
  )
}

export default SliderSettingsPage;
