// React.
import React, { Fragment } from "react";

// Components.
import ImageAvatar from "../utils/ImageAvatar";
import NumberFormat from "../utils/NumberFormat";

// Material UI
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

// Redux
import { useDispatch } from "react-redux";
import { registerEventPrizeToPlayer } from "../../../actions/prizeActions/PrizeActions";

// Translations.
import { useTranslation } from "react-i18next";

// Global config.
import { getProjectConfig } from "../../../getProjectConfig";

// Styles.
import {
  useStyles,
  Accordion,
  AccordionSummary,
} from "./AssignPrizeAccordionStylesModal";

// Model types.
import { AwardType, ItemType } from '../../../actions/prizeActions/PrizeActionsTypes';

interface AwardPrizeItemProps {
  itemList: ItemType[];
  player: AwardType;
  prizeId: number;
}

const AssignPrizeAccordionModal = ({ itemList, player, prizeId } : AwardPrizeItemProps) => {
  const [t] = useTranslation("global");
  const classes = useStyles();
  const dispatch = useDispatch();

  const deleteAddedItem = (item: any) => {
    dispatch(registerEventPrizeToPlayer(
      player.playerId,
      prizeId,
      item.itemId,
      -Math.abs(item.quantity)
    ));
  };

  const getUnitPrice = (item: any) => {
    const unitPrice = item.price / item.quantity;
    return (
      <div>
        <Typography color="primary" className={classes.modalListStyle}>
          {getProjectConfig().CURRENCY_SYMBOL}
          {<NumberFormat number={unitPrice} />}
        </Typography>
      </div>
    );
  };

  return (
    <Fragment>
      {itemList !== undefined && itemList.map((item: any) => {
        return (
          <Accordion key={item.itemId} square expanded={false}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
            >
              <Grid container spacing={2}>
                <Grid item xs={2} sm={2} className={classes.userImgSmall}>
                  <ImageAvatar
                    src={item.images ? item.image.imageURL : ""}
                    alt="The main item image."
                  />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <Typography className={classes.modalListStyle}>
                    {item.itemName}
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <Typography
                    className={classes.modalListStyle}
                    color="textSecondary"
                  >
                    {t("orders.item-unit-price")}
                  </Typography>
                  <div>{getUnitPrice(item)}</div>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <Typography
                    className={classes.modalListStyle}
                    color="textSecondary"
                  >
                    {t("orders.item-quantity")}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    className={classes.modalListStyle}
                  >
                    {item.quantity}
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <Typography
                    className={classes.modalListStyle}
                    color="textSecondary"
                  >
                    {t("orders.total")}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    className={classes.modalListStyle}
                  >
                    {getProjectConfig().CURRENCY_SYMBOL}
                    {<NumberFormat number={item.price} />}
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={1}>
                  <IconButton
                    aria-label="delete"
                    onClick={() => deleteAddedItem(item)}
                  >
                    <DeleteIcon color="action" />
                  </IconButton>
                </Grid>
              </Grid>
            </AccordionSummary>
          </Accordion>
        );
      })}
    </Fragment>
  );
};

export default AssignPrizeAccordionModal;
