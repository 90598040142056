// React.
import React, { Fragment, useEffect, useRef, useState } from "react"
import { useReactToPrint } from "react-to-print"
import { useHistory } from "react-router-dom"

// Material UI.
import Paper from "@material-ui/core/Paper"
import CssBaseline from "@material-ui/core/CssBaseline"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"

// Moment.
import Moment from "moment"

// Component.
import OrderRow from "../orders/OrderRow"
import NumberFormat from "../../admin/utils/NumberFormat"
import Divider from "@material-ui/core/Divider"
import PointsIcon from "../../utils/PointsIcon"

//  JWT decode token.
import jwtDecode from "jwt-decode"

// Global Types.
import { OrderType } from "../../admin/models/ModelTypes"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { RootStore } from "../../../Store"
import { getUserOrders } from "../../../actions/orderActions/OrderActions"
import { getOrderConfirmations } from "../../../actions/settingsActions/SettingsActions"

// Styles.
import useStyles from "./CheckoutThanksStyles"

// Translations.
import { useTranslation } from "react-i18next"

// Global config.
import { getProjectConfig } from "../../../getProjectConfig"

const CheckoutThanks = () => {
  const classes = useStyles()
  const [t] = useTranslation("global")
  const dispatch = useDispatch()
  const componentRef = useRef(null)
  const orderListCountRef = useRef(0)
  let history = useHistory()

  const [orderListLocal, setOrderListLocal] = useState<any>({ orders: [] })

  // Redux state.
  const orderState = useSelector((state: RootStore) => state.order)
  const { orderList } = orderState
  const reduxState = useSelector((state: RootStore) => state.auth)
  const loggedUser = reduxState.loggedUser
  const settingsState = useSelector((state: RootStore) => state.settings)
  const orderConfirmations = settingsState.orderConfirmations

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const orderIds = queryParams.get("oid")
    const arrayIds = orderIds?.split(",")

    arrayIds?.forEach((oId: string) => {
      const qParams = {
        id: Number(oId),
        paged: false,
      }
      dispatch(getUserOrders(qParams))
    })

    // Get confirmation page texts.
    dispatch(getOrderConfirmations())

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    orderListCountRef.current = orderListCountRef.current + 1
    if (orderList !== undefined && orderListCountRef.current > 1) {
      if (orderList.orders.length > 0) {
        var newValue = orderListLocal.orders
        newValue.push(orderList.orders[0])
        setOrderListLocal({
          orders: newValue,
        })
      }
    }

    // eslint-disable-next-line
  }, [orderList])

  useEffect(() => {
    if (orderList !== undefined) {
      if (orderList.orders.length > 0) {
        const userEmail = orderList.orders[0].user.email
        const decodedToken: any = jwtDecode(localStorage.token)
        if (userEmail !== decodedToken.sub) {
          history.push("/error-403")
        }
      }
    }

    // eslint-disable-next-line
  }, [orderState])

  const getUserName = () => {
    if (loggedUser !== undefined) {
      return `${loggedUser.firstName} ${loggedUser.lastName}`
    }
    return ""
  }

  const getOrderNumber = () => {
    var result = ""
    orderListLocal.orders.forEach((order: OrderType) => {
      result += ` ${order.orderCode} `
    })
    return result
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${getUserName()} - [${getOrderNumber()}]`,
  })

  const totalItems = (orderItemsLength: number, orderItems: any) => {
    let total = 0
    for (let i = 0; i < orderItemsLength; i++) {
      total = total + orderItems[i].normal_quantity
    }

    return total
  }

  const renderOrderDetails = () => {
    return (
      <Fragment>
        {orderListLocal.orders.map((order: OrderType, index: number) => (
          <Fragment key={index}>
            <Grid container spacing={2} className={classes.bottomBox}>
              <Grid item xs={12} sm={8}>
                <Typography>
                  <strong>{t("orders.order")}</strong> {order.orderCode}
                </Typography>
                <Typography>
                  <strong>{t("orders.seller")}:</strong> {order.vendor.name}
                </Typography>
                <Typography>
                  <strong>{t("orders.shipping")}:</strong> {order.shipping.name}
                </Typography>
                <Typography>
                  <strong>{t("orders.date")}:</strong>{" "}
                  {Moment(order.creationDate).format("MM/DD/YYYY")}
                </Typography>
                <Typography variant="subtitle1" color="primary">
                  <strong >Pin: {order.pin}</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography>
                  <span>
                    {" "}
                    ({totalItems(
                      order.orderItems.length,
                      order.orderItems
                    )}{" "}
                    {t("orders.items")})
                  </span>
                </Typography>
                <Typography>
                  <strong>{t("orders.subtotal")}</strong>{" "}
                  {order.currency.symbol}
                  {
                    <NumberFormat
                      number={order.totalCost - order.shippingCost}
                    />
                  }
                </Typography>
                <Typography className={classes.centered}>
                  <strong>
                    {getProjectConfig().STORE_CREDITS_NAME}{" "}
                    {t("orders.used-points")}:&nbsp;
                  </strong>
                  <PointsIcon width={"15"} />
                  &nbsp;{<NumberFormat number={order.points} />}
                </Typography>
                <Typography>
                  <strong>{t("cart.shipping-cost")}:</strong>{" "}
                  {order.currency.symbol}
                  {<NumberFormat number={order.shippingCost} />}
                </Typography>
                <Typography>
                  <strong>{t("orders.total")}:</strong> {order.currency.symbol}
                  {<NumberFormat number={order.totalCost} />}
                </Typography>
              </Grid>
            </Grid>
            <OrderRow order={order} />
            <Divider className={classes.divider} />
          </Fragment>
        ))}
      </Fragment>
    )
  }

  const notPaymeMethod = () => {
    var res = false
    orderList?.orders?.forEach((order) => {
      if (order.paymentMethod !== "tarjeta") res = true
    })

    return res
  }

  return (
    <Fragment>
      <CssBaseline />
      <Container maxWidth="md" className={classes.checkoutWrapper}>
        <Paper
          elevation={3}
          square={false}
          variant="elevation"
          className={classes.paperWrapper}
        >
          <Typography gutterBottom variant="h4" component="h1" color="primary">
            {t("checkout.checkout-thanks")}
          </Typography>
          <Typography>
            {t("checkout.thanks-msg")} <br />{" "}
            {notPaymeMethod() ? t("checkout.thanks-no-card-payment") : ""}
          </Typography>
          <br />
          {notPaymeMethod() && (
            <Grid container spacing={3} className={classes.withBorders}>
              {orderConfirmations?.map((oConfirmation, index) => (
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong>{oConfirmation.title}</strong>
                  </Typography>
                  <div
                    dangerouslySetInnerHTML={{ __html: oConfirmation.value }}
                  ></div>
                </Grid>
              ))}
            </Grid>
          )}
          <div ref={componentRef} className={classes.detailsWrapper}>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              color="primary"
            >
              {t("orders.order-details")}
            </Typography>

            {orderList !== undefined && orderList!.orders.length > 0
              ? renderOrderDetails()
              : "Cargando..."}
          </div>
          <Button variant="contained" color="primary" href="/items">
            {t("checkout.continue-buying")}
          </Button>
          <Button variant="contained" color="primary" onClick={handlePrint}>
            {t("checkout.print")}
          </Button>
        </Paper>
      </Container>
    </Fragment>
  )
}

export default CheckoutThanks
