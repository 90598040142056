import React from 'react';

// Metarial UI
import Avatar from '@material-ui/core/Avatar';

// Styles.
import { useStyles } from './ImageAvatarStylesMobile';

// Component interface.
interface AvatarProps {
  src: string;
  alt: string;
};

const ImageAvatarMobile = ({src, alt} : AvatarProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Avatar variant="rounded" src={ src } alt={ alt }/>
    </div>
  );
}

export default ImageAvatarMobile;