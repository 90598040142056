// React.
import React, {Fragment, useState, useEffect, useRef} from 'react';
import { useHistory } from 'react-router-dom';

// Material UI.
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// Components.
import CheckoutShipping from './CheckoutShipping';
import CheckoutPayment from './CheckoutPayment';
import CheckoutReview from './CheckoutReview';
import NumberFormat from '../../admin/utils/NumberFormat';

// Redux
import {useDispatch, useSelector} from "react-redux";
import {checkoutCart} from "../../../actions/cartActions/CartActions";
import {getShippings} from "../../../actions/orderActions/OrderActions";
import { getDolarPrice } from "../../../actions/utilityActions/UtilityActions";
import {RootStore} from "../../../Store";

// Styles.
import useStyles from './CheckoutAccordionStyles';

// Model
import { UserType, AddressUserType, ShippingType } from '../../admin/models/ModelTypes';

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

// Translations.
import { useTranslation } from 'react-i18next';
import { paymeFlow } from '../utils/PaymeFlow';
import PointsIcon from '../../utils/PointsIcon';
import { getUserInformation } from '../../../actions/authActions/AuthActions';

const CheckoutAccordion = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();
  let history = useHistory();
  const shippingRef = useRef<HTMLDivElement>(null);
  const reviewRef = useRef<HTMLDivElement>(null);
  const paymentRef = useRef<HTMLDivElement>(null);

  // Default values.
  const defaultOpenStatus = {
    elements: [false, true, true]
  };
  const defaultDisabledStatus = {
    elements: [false, true, true]
  };

  // Redux state.
  const reduxState = useSelector((state: RootStore) => state.auth);
  const loggedUser = reduxState.loggedUser;
  const cartState = useSelector((state: RootStore) => state.cart);
  const cartStatus = cartState.actionStatus;
  const loading = cartState.loading;
  const orderState = useSelector((state: RootStore) => state.order);
  const shippings = orderState.shippings;
  const utilityState = useSelector((state: RootStore) => state.utility);

  // Local state.
  const [userState, setUserState] = useState<UserType|undefined>(undefined);
  const [openState, setOpenState] = useState<any>(defaultOpenStatus);
  const [disabledState, setDisabledState] = useState<any>(defaultDisabledStatus);
  const [paymeIsDisabled ,setPaymeIsDisabled] = useState<boolean>(false);
  const [selectedShipping, setSelectedShipping] = useState<ShippingType>();
  const [selectedCard, setSelectedCard] = useState<string>('');
  const [selectedPayment, setSelectedPayment] = useState<string>('');
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<AddressUserType>();
  const [selectedVendor, setSelectedVendor] = useState<string>('');
  const [selectedPickUpId, setSelectedPickUpId] = useState<string>('');
  const [isUsingPoints, setIsUsingPoints] = useState<boolean>(false);
  const [usingPointsDiff, setUsingPointsDiff] = useState<number>(0);
  const [usingPoints, setUsingPoints] = useState<number>(0);
  const [cart, setCart] = useState<Array<any>>([]);
  const [bundleCart, setBundleCart] = useState<Array<any>>([]);
  const [total, setTotal] = useState<number>(0);
  const [subTotal, setSubTotal] = useState<number>(0);

  useEffect(() => {
    // Get all shippings.
    dispatch(getShippings());

    if (utilityState.dolarPrice === undefined) {
      dispatch(getDolarPrice());
    }
    
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(getUserInformation());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cartState])

  useEffect(() => {
    // Redirects when order is created.
    if (cartStatus !== undefined) {
      if (cartStatus.menssage === 'status.success-checkout') {
        // Get the ids.
        var ids = '';
        cartStatus.orderIds.forEach((id:number, index:number) => {
          if (index === 0) {
            ids = `${id}`;
          } else {
            ids = ids + ',' + id;
          }
        });
        history.push(`/checkout-thanks?oid=${ ids }`);
      }
    }
  // eslint-disable-next-line
  }, [cartStatus])

  useEffect(() => {
    if (loggedUser !== undefined) {
      let user:UserType = {
        id: 0,
        email: loggedUser.email,
        name: loggedUser.firstName,
        lastName: loggedUser.lastName,
        profileImageUrl: loggedUser.profileImageUrl,
        phone: loggedUser.phoneNumber,
        address: loggedUser.addresses,
        role: loggedUser.roles
      };
      setUserState(user);
      setCart(loggedUser.cart);
      setBundleCart(loggedUser.bundleCart);
      setTotal(calculateTotal(loggedUser.cart, loggedUser.bundleCart));
      setSubTotal(calculateSubTotal(loggedUser.cart, loggedUser.bundleCart));
    }
  // eslint-disable-next-line
  }, [loggedUser])

  useEffect(() => {
    var newValues = disabledState.elements;

    // Set the new active value.
    newValues[2] = true;
    setDisabledState({
      ...disabledState,
      elements: newValues
    });
  // eslint-disable-next-line
  }, [selectedCard])

  useEffect(() => {
    if (loggedUser !== undefined) {
      setTotal(calculateTotal(loggedUser.cart, loggedUser.bundleCart));
    }
    setSelectedPayment('');

  // eslint-disable-next-line
  }, [selectedShipping])

  // Calculate difference between points balance and total.
  useEffect(() => {
    if (loggedUser?.points!) {
      let shippingCost = (selectedShipping!) ? selectedShipping.cost : 0;
      let totalWithoutShipping = (total - shippingCost);
      if (loggedUser.points < totalWithoutShipping) {
        let diff = (totalWithoutShipping - loggedUser.points) + shippingCost;
        setUsingPointsDiff(diff);
        setUsingPoints(loggedUser.points);
      } else {
        setUsingPointsDiff(shippingCost);
        setUsingPoints(totalWithoutShipping);
      }
    }

    // eslint-disable-next-line
  }, [isUsingPoints, total])

  const updateOpenState = (index:number) => {
    var newValues = openState.elements;

    // Reset other values.
    for (var i = 0; i < newValues.length; i++) {
      if (i !== index) {
        newValues[i] = true;
      }
    }
    // Set the new active value.
    newValues[index] = false;
    setOpenState({
      ...openState,
      elements: newValues
    });
  };

  const updateDisabledState = (index:number) => {
    var newValues = disabledState.elements;

    // Set the new active value.
    newValues[index] = false;
    setDisabledState({
      ...disabledState,
      elements: newValues
    });

    // Open next step.
    updateOpenState(index);
  };

  useEffect(() => {
    // Move the open step
    const screenWidth = window.innerWidth;
    const moveAdjust = screenWidth < 768 ? 240 : 130;
    if (!openState.elements[0]) {
      let offsetTop = shippingRef?.current?.offsetTop ? shippingRef?.current?.offsetTop : 0;
      window.scrollTo({ behavior: 'auto', top: (offsetTop - moveAdjust) })
    }
    if (!openState.elements[1]) {
      let offsetTop = reviewRef?.current?.offsetTop ? reviewRef?.current?.offsetTop : 0;
      window.scrollTo({ behavior: 'auto', top: (offsetTop - moveAdjust) })
    }
    if (!openState.elements[2]) {
      let offsetTop = paymentRef?.current?.offsetTop ? paymentRef?.current?.offsetTop : 0;
      window.scrollTo({ behavior: 'auto', top: (offsetTop - moveAdjust) })
    }

  // eslint-disable-next-line
  }, [openState])

  const getMaxDiscountQty = (cartItem:any) => {
    const dis = cartItem.item.warehousesQuantities.find((wh:any) => wh.warehouse.isDiscount === true);
    return dis !== undefined ? Number(dis.quantity) : 0;
  };

  const getItemDiscountQty = (cartItem:any) => {
    const maxDis = getMaxDiscountQty(cartItem);
    if (cartItem.quantity < Number(maxDis)) {
      return cartItem.quantity;
    }
    return maxDis;
  };

  const getItemNormalQty = (cartItem:any) => {
    const maxDis = getMaxDiscountQty(cartItem);
    if (cartItem.quantity > Number(maxDis)) {
      return cartItem.quantity -  Number(maxDis);
    }
    return 0;
  };

  const isItemWithDiscount = (cartItem:any) => {
    if (cartItem.item.discount !== null && cartItem.item.discount !== undefined) {
      const discountQty = getMaxDiscountQty(cartItem);
      if (discountQty > 0 && cartItem.item.discount.pricePercentage > 0) {
        return true;
      }
    }
    return false;
  };

  const calculateSubTotal = (cart:Array<any>, bundleCart:Array<any>) => {
    var total = 0;
    cart.forEach((cartItem: any) => {
      if (cartItem.item.category === 'Single') {
        total = Number((total + (getSinglePriceInfo(cartItem, cartItem.id.customReference) * cartItem.quantity)).toFixed(2));
      } else {
        if (isItemWithDiscount(cartItem)) {
          const disQty = getItemDiscountQty(cartItem);
          const normalQty = getItemNormalQty(cartItem);
          total = Number((total + (getPriceWithTaxesInfo(cartItem.item, true) * disQty)).toFixed(2))
          total = Number((total + (getPriceWithTaxesInfo(cartItem.item) * normalQty)).toFixed(2))
        } else {
          total = Number((total + (getPriceWithTaxesInfo(cartItem.item) * cartItem.quantity)).toFixed(2))
        }
      }
    });

    bundleCart.forEach((cartItem: any) => {
      total = Number((total + (getPriceWithTaxesInfo(cartItem.bundle) * cartItem.quantity)).toFixed(2))
    });
    return total;
  };

  const calculateTotal = (cart:Array<any>, bundleCart:Array<any>) => {
    var total = 0;
    cart.forEach((cartItem:any) => {
      if (cartItem.item.category === 'Single') {
        total = Number((total + (getSinglePriceInfo(cartItem, cartItem.id.customReference) * cartItem.quantity)).toFixed(2));
      } else {
        if (isItemWithDiscount(cartItem)) {
          const disQty = getItemDiscountQty(cartItem);
          const normalQty = getItemNormalQty(cartItem);
          total = Number((total + (getPriceWithTaxesInfo(cartItem.item, true) * disQty)).toFixed(2))
          total = Number((total + (getPriceWithTaxesInfo(cartItem.item) * normalQty)).toFixed(2))
        } else {
          total = Number((total + (getPriceWithTaxesInfo(cartItem.item) * cartItem.quantity)).toFixed(2))
        }
      }
    });

    bundleCart.forEach((cartItem:any) => {
      total = Number((total + (getPriceWithTaxesInfo(cartItem.bundle) * cartItem.quantity)).toFixed(2))
    });

    // Add shipping cost if applies.
    if (selectedShipping !== undefined) {
      total = total + selectedShipping.cost;
    }

    return total;
  };

  const getSinglePriceInfo = (cartItem: any, customReference: string) => {
    var price = 0;
    const priceObj = cartItem.item.customPrices?.filter((customPrice: any) => customPrice.customReference === customReference);
    if (priceObj?.length! > 0) price = priceObj![0]?.price;
    return price;
  };

  const getTotalQuantity = () => {
    var total = 0;
    cart.forEach((cartItem:any) => 
      total += cartItem.quantity
    );
    bundleCart.forEach((cartItem:any) => 
      total += cartItem.quantity
    );
    return total;
  };

  const getPriceWithTaxesInfo = (item:any, hasDiscount:boolean = false) => {
    if (hasDiscount) {
      const discount = item.discount.pricePercentage;
      const savings = Number(((discount/ 100) * item.priceWithTaxes).toFixed(2));
      return item.priceWithTaxes - savings;
    } else {
      return item.priceWithTaxes;
    }
  };
  
  const isSubmitDisabled = () => {
    let res = disabledState.elements[0] || disabledState.elements[1] || disabledState.elements[2] ? true : false;
    if (selectedShipping?.id === 2 && !selectedAddress) {
      return true;
    }
    if (paymeIsDisabled) {
       return true;
    }
    if (isUsingPoints && usingPointsDiff > 0 && selectedPayment === undefined) {
      return true;
    }
    return res;
  };

  const submitOrder = () => {
    if (selectedShipping !== undefined) {
      let onlyPoints = (applyPointsTotal(total) === 0 && isUsingPoints) ? true : false;
      if (selectedAddress !== undefined) {
        dispatch(checkoutCart(Number(selectedAddress.id), selectedShipping.id, selectedCard, selectedPayment, selectedVendor, isUsingPoints, onlyPoints, selectedPickUpId));
      } else {
        dispatch(checkoutCart(0, selectedShipping.id, selectedCard, selectedPayment, selectedVendor, isUsingPoints, onlyPoints, selectedPickUpId));
      }
    }
    setPaymeIsDisabled(true);
  };

  const onSubmitPaymeOrder = () => {
    setPaymeIsDisabled(true);
    paymeFlow(
      {
        addressId: selectedAddress?.id,
        isTransfer: false,
        ld: '',
        lk: '',
        shippingId: selectedShipping?.id,
        vendorIdShipping: selectedVendor,
        usingBalancePoints: isUsingPoints
      },
      {
        total: Math.round(applyPointsTotal(total)).toLocaleString('en-US'),
        firstName: loggedUser.firstName,
        lastName: loggedUser.lastName,
        email: loggedUser.email,
      }
    )
  }


  const applyPointsTotal = (value: number) => {
    if (!isUsingPoints) return value;
    return usingPointsDiff;
  };

  return (
    <div>
      <div className={`${classes.checkoutStep}`} ref={shippingRef}>
        <div className={`${classes.elementContent} ${ !openState.elements[0] ? 'full' : '' }`}>
        { userState !== undefined &&
          <CheckoutShipping 
            isSummary={openState.elements[0]} 
            userAddresses={userState.address as Array<AddressUserType>} 
            userEmail={userState.email}
            setSelectedShipping={setSelectedShipping}
            selectedShipping={selectedShipping}
            setSelectedAddress={ setSelectedAddress }
            selectedAddress={ selectedAddress }
            updateDisabledState={ updateDisabledState }
            shippings={shippings  as Array<ShippingType>}
            selectedVendor={selectedVendor}
            setSelectedVendor={ setSelectedVendor }
            selectedPickUpId={selectedPickUpId}
            setSelectedPickUpId={ setSelectedPickUpId }
          />
        }
        </div>
        {openState.elements[0] &&
          <div className={classes.elementCta}>
            <Button variant="outlined" size="small" onClick={ () => updateOpenState(0) }>{ t('checkout.change') }</Button>
          </div>
        }
      </div>
      <div className={`${classes.checkoutStep} ${classes.linesStep}`} ref={reviewRef}> 
        <div className={`${classes.elementContent} ${ !openState.elements[1] ? 'full' : '' }`}>
          <CheckoutReview 
            isSummary={ openState.elements[1] }
            updateDisabledState={ updateDisabledState }
          />
        </div>
        {openState.elements[1] &&
          <div className={classes.elementCta}>          
            <Button variant="outlined" size="small" disabled={disabledState.elements[1]} onClick={ () => updateOpenState(1) }>{ t('checkout.change') }</Button>       
          </div>
        }
      </div>
      <div className={`${classes.checkoutStep}`} ref={paymentRef}>
        <div className={`${classes.elementContent} ${ !openState.elements[2] ? 'full' : '' }`}>
          <CheckoutPayment
            isSummary={openState.elements[2]}
            setSelectedCard={ setSelectedCard }
            setSelectedPayment={ setSelectedPayment }
            selectedPayment={ selectedPayment }
            acceptTerms={acceptTerms}
            setAcceptTerms={setAcceptTerms}
            setIsUsingPoints={setIsUsingPoints}
            isUsingPoints={isUsingPoints}
            usingPointsDiff={usingPointsDiff}
            selectedShipping={selectedShipping}
          />
        </div>
        {openState.elements[2] &&
          <div className={classes.elementCta}>        
            <Button variant="outlined" size="small" disabled={disabledState.elements[2]} onClick={ () => updateOpenState(2) }>{ t('checkout.change') }</Button>
          </div>
        }
      </div>
      <div className={classes.bottomWrapper}>
          {
            (cart.length > 0 || bundleCart.length > 0) &&
            <Fragment>
              <Typography>({ getTotalQuantity() } { t('cart.items') })</Typography>
              <Typography>{t('cart.subtotal')}: {getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={subTotal}/>}</Typography>
              <Typography className={classes.coinsUsed}>
                      {getProjectConfig().STORE_CREDITS_NAME}{' '}
                      {t('orders.used-points')}:
                    <PointsIcon width={'15'} />
                    {<NumberFormat number={isUsingPoints? usingPoints : 0} />}
                  </Typography>
              {selectedShipping !== undefined &&
                <Typography>{ t('cart.shipping-cost') }: {getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={selectedShipping.cost}/>}</Typography>
              }
              <Typography variant="h5" component="h3">{t('cart.total')}: {getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={applyPointsTotal(total)}/> }</Typography>
              {/** Pay with transfer / SINPE */}
              {((selectedPayment === 'transferencia' || (usingPointsDiff === 0 && isUsingPoints)) && acceptTerms) && 
                <Button variant="contained" color="secondary" disabled={isSubmitDisabled()} onClick={ submitOrder }>
                  {loading === 'CHECKOUT_CART' ? t('cart.processing') : t('cart.proceed-to-checkout') }
                </Button>
              }
              {/** Pay with card / payme */}
              {isUsingPoints && usingPointsDiff > 0 && !selectedPayment? t('checkout.select-another-payment'):""}
              {selectedPayment === 'payme' && acceptTerms && <Button variant="contained" color="secondary" disabled={paymeIsDisabled} onClick={() => onSubmitPaymeOrder()}>
                  { t('cart.proceed-to-checkout') }
              </Button>}
              
            </Fragment>
          }
        </div> 
    </div>
  )
}

export default CheckoutAccordion;
