// React.
import React, {Fragment} from 'react';


// Material UI.
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

// Styles.
import useStyles from './UseTermsStyles';

const AboutUs = () => {
  const classes = useStyles();

  return (
    <Fragment>
    <CssBaseline />
    <Container maxWidth="md">
      <Paper elevation={3} square={false} variant="elevation" className={classes.errorWrapper}>
        <div>
          <h1>Sobre Nosotros.</h1>
          <p>Página en construcción.</p>
        </div>
      </Paper>
    </Container>
  </Fragment>
  )
}

export default AboutUs;
