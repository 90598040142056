// React.
import React from 'react';

// Material UI.
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// Types.
import {FeaturedBoxType} from '../../admin/models/ModelTypes';

// Styles.
import useStyles from './FeaturedCardStyles';
import defaultImage from '../../../images/default-img.jpg'; 

// Translations.
import { useTranslation } from 'react-i18next';

// Types interfase.
interface FeaturedCardProps {
  box: FeaturedBoxType;
};

const FeaturedCard = ({ box }: FeaturedCardProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');

  return (
    <Card className={classes.root}>
      <Typography gutterBottom variant="h6" component="h3">
        { box.title }
      </Typography>
      <div className={`${classes.imgWrapper} ${box.items.length === 1 ? 'imgSingleColumn' : ''}`} >
        {box?.items !== undefined && 
        (box.items.map((item:any, index:number) => 
          <a href={`/item/${item.code}`} key={index}>
            <img
              src={item.imageURL ? item.imageURL : defaultImage} 
              alt="Product thumbnail" 
              loading="lazy"/>
          </a>
        ))}
      </div>
      <div>
        {box.items[0].itemTypeId !== undefined && 
          <Button size="small" color="primary" href={`/items?itemTypeId=${box.items[0].itemTypeId}`}>
          { t('cart.buy-now') }
        </Button>
      }
      </div>
    </Card>
  )
}

export default FeaturedCard;
