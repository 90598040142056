import axios from 'axios';

const apiURL: string | undefined = process.env.REACT_APP_API_URL;

const token: string | undefined = localStorage.token;

export const downloadItemReport = async (filter: any = {}) => {
  try {
    const headers: object = {
      ContentType: 'application/octet-stream',
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      Authorization: 'Bearer ' + token
    };
    const res = await axios.get(`${apiURL}/seller/generateItemReport`, {
      params: filter,
      responseType: 'blob',
      headers: headers
    });
    var blob = new Blob([res.data]);
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `inventario.xlsx`;
    link.click();
  }
  catch (e) {
    console.error(e);
    return null
  }
}