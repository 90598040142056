// React.
import React, { Fragment, useState, useEffect } from 'react';

// Components.
import TextMaskPhone from '../utils/TextMaskPhone';

// Material UI.
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Avatar from '@material-ui/core/Avatar';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';

// Redux
import {useDispatch, useSelector} from "react-redux";
import {updateUser, deleteUser, refreshUserView} from "../../../actions/userActions/UserActions";
import {addUserAddress, updateUserAddress} from "../../../actions/userAddressActions/UserAddressActions";
import {RootStore} from "../../../Store";

//  JWT decode token.
import jwtDecode from 'jwt-decode';

// Translations.
import { useTranslation } from 'react-i18next';

// Global Types.
import { UserType, AddressUserType } from '../models/ModelTypes';

// Styles.
import useStyles from './UserEditStyles';

interface UserEditProps {
  user: UserType;
  userAddress: AddressUserType | undefined;
};

const UserEdit = ({user, userAddress} : UserEditProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();
  const authToken = localStorage.token;
  var loggedUserRole = '';
  if (authToken !== undefined && authToken !== '') {
    const decodedToken:any = jwtDecode(authToken);
    loggedUserRole = decodedToken.auth;
  }

  // Default stage object value.
  const defaultAddress = {
    id: 0,
    province: '',
    canton: '',
    district: '',
    fullAddress: '',
    phoneNumber: '',
    postalCode: '',
    userEmail: user.email
  };

  const currentAddress = userAddress !== undefined ? userAddress : defaultAddress

  // Define local state for the user.
  const [userState, setUserState] = useState<UserType>(user);
  const [userAddressState, setUserAddressState] = useState<AddressUserType>(currentAddress);
  const [editing, setEditing] = useState<boolean | false>(false);
  const [fieldError, setFieldError] = useState<boolean | false>(false);
  const [phoneError, setPhoneError] = useState<boolean | false>(false);
  const [openModal, setOpenModal] = useState(false);
  const [addressGroupEditing, setAddressGroupEditing] = useState<boolean | false>(false);


  // Redux state.
  const reduxState = useSelector((state: RootStore) => state.user);
  const loading = reduxState.loading;
  const actionStatus = reduxState.actionStatus;

  useEffect(() => {
    if (actionStatus !== undefined) {
      if (actionStatus.menssage === 'status.success-delete-user') {
        dispatch(refreshUserView(true));
      }
    }
  // eslint-disable-next-line
  }, [actionStatus])

  useEffect(() => {
    // Validations.
    if (editing) {
      if (userState.name === '' || userState.lastName === '' || userState.phone === '') {
        setFieldError(true);
      } else {
        setFieldError(false);
      }
    };
    
    if (userState.phone !== '' && userState.phone !== 'null' && userState.phone !== undefined) {
      let phoneMask  = new RegExp(/\(?([0-9]{4})\)?([ ]?)([0-9]{4})/);
      phoneMask.test(userState.phone) ? setPhoneError(false) : setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  }, [userState, userAddressState, editing]);

  // Handles the field changes to update the state.
  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    // Use the HTML name atribute to know what property update.
    const name = event.target.name as keyof typeof userState;
    var inputValue = String(event.target.value);

    if (name === 'name' || name === 'lastName') {
      inputValue = inputValue.replace(/[\d,-,!,@,#,$,%,^,&,*,(,),-,_,=,+,:,;,",',?,{,}]/g, '');
    }

    setUserState({
      ...userState,
      [name]: inputValue,
    });
  };

  const handleAddressGroupFocus = () => {
    setAddressGroupEditing(true);
  };

  const isSaveDisabled = () => {
    if (!editing) return true;
    if (fieldError || phoneError) return true;
    if (!hasUpdatedData(user, userState) && !hasUpdatedData(currentAddress, userAddressState)) return true;
    return false;
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  // Handles the field changes to update the state.
  const handleChangeAddress = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    // Use the HTML name atribute to know what property update.
    const name = event.target.name as keyof typeof userAddressState;
    setUserAddressState({
      ...userAddressState,
      [name]: event.target.value,
    });
  };

  const handleUploadClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesList = event.target.files as FileList | null;
    const tempURL = URL.createObjectURL(filesList![0]);

    setUserState({
      ...userState,
      profileImageUrl: tempURL,
      imgFile: filesList![0]
    });
  };

  const editOnClick = () => {
    setEditing(true);
  };

  const cancelOnClick = () => {
    setEditing(false);

    // Set user state to original values.
    setUserState(user);
    setAddressGroupEditing(false);
    setUserAddressState(currentAddress);
  };

  const hasUpdatedData = (currentData: any, newData: any) => {
    return JSON.stringify(currentData) !== JSON.stringify(newData);
  };

  const saveOnClick = () => {
    // Save the user on the BE.
    if (hasUpdatedData(user, userState) || hasUpdatedData(currentAddress, userAddressState)) {
      if (JSON.stringify(currentAddress) !== JSON.stringify(userAddressState)) {
        if (userAddress !== undefined) {
          dispatch(updateUserAddress(userAddressState));
        } else {
          dispatch(addUserAddress(userAddressState));
        }
      };
    
      dispatch(updateUser(userState));
    };
    setEditing(false);
  };

  const deleteUserOnClick = () => {
    if (userState.email !== '') {
      dispatch(deleteUser(userState.email));
    }

    handleCloseModal();
  };

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3} md={2}>
          <div className={ classes.leftColumnWrapper }>
            <div className={ classes.imgWrapper }>
              <Avatar variant="circular" alt="Remy Sharp" src={ userState.profileImageUrl } className={ classes.userImg }/>
              {editing && (
                <div className={ classes.editImgWrapper }>
                  <input accept="image/*" id="icon-button-file" type="file" onChange={ handleUploadClick }/>
                  <label htmlFor="icon-button-file">
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </div>
              )}
            </div>
            { editing ? <LockOpenIcon fontSize="large"/> : <LockIcon fontSize="large" color="disabled" /> }
          </div>
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          <form>
            <FormControl className={classes.formControl}>
              <Typography variant="caption">{ t('users.name') }</Typography>
              <TextField 
                className={classes.formField} 
                id="u-name" name="name" 
                value={ userState.name } 
                onChange={ handleChange } 
                disabled={!editing}
                autoFocus
                error={userState.name === ''}
                helperText={userState.name === '' ? t('users.field-required'): ''}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Typography variant="caption">{ t('users.last-name') }</Typography>
              <TextField 
                className={classes.formField} 
                id="u-last-name" name="lastName" 
                value={ userState.lastName } 
                onChange={ handleChange } 
                disabled={!editing}
                error={userState.lastName === ''}
                helperText={userState.lastName === '' ? t('users.field-required'): ''}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Typography variant="caption">{ t('users.phone') }</Typography>
              <Input
                id="u-phone" 
                name="phone"
                value={ userState.phone !== 'null' ? userState.phone : '' } 
                onChange={ handleChange }
                inputComponent={TextMaskPhone as any}
                disabled={!editing}
                error={ phoneError }
                className={classes.formField} 
              />
            </FormControl>
            <FormControl className={`${classes.formControl} ${classes.addressGroup}`}>
              <Typography variant="caption" className={classes.addressLabel}>{ t('users.address-1') }</Typography>
              <TextField
                className={classes.formField} 
                id="u-province" 
                name="province"
                label={ t('users.province') }
                value={ userAddressState.province } 
                onChange={ handleChangeAddress } 
                disabled={!editing}
                error={userAddressState.province === ''}
                helperText={userAddressState.province === '' ? t('users.field-required'): ''}
                onFocus={handleAddressGroupFocus}
              />
              <TextField 
                className={classes.formField} 
                id="u-canton" 
                name="canton"
                label={ t('users.canton') }
                value={ userAddressState.canton } 
                onChange={ handleChangeAddress } 
                disabled={!editing}
                error={userAddressState.canton === ''}
                helperText={userAddressState.canton === '' ? t('users.field-required'): ''}
                onFocus={handleAddressGroupFocus}
              />
              <TextField 
                className={classes.formField} 
                id="u-district" 
                name="district"
                label={ t('users.district') } 
                value={ userAddressState.district } 
                onChange={ handleChangeAddress } 
                disabled={!editing}
                error={userAddressState.district === ''}
                helperText={userAddressState.district === '' ? t('users.field-required'): ''}
                onFocus={handleAddressGroupFocus}
              />
              <TextField 
                className={classes.formField} 
                id="u-direction" 
                name="fullAddress" 
                label={ t('users.direction') }  
                value={ userAddressState.fullAddress } 
                onChange={ handleChangeAddress } 
                disabled={!editing}
                error={userAddressState.fullAddress === ''}
                helperText={userAddressState.fullAddress === '' ? t('users.field-required'): ''}
                onFocus={handleAddressGroupFocus}
              />
              <FormControl className={ classes.formControlAddress }>
                <InputLabel htmlFor="u-phone-number">{ t('users.phone-number') }</InputLabel>
                <Input
                  id="u-phone-number" 
                  name="phoneNumber"
                  value={ userAddressState.phoneNumber } 
                  onChange={ handleChangeAddress }
                  disabled={!editing}
                  inputComponent={TextMaskPhone as any}
                  className={classes.formField} 
                  error={userAddressState.phoneNumber === ''}
                  onFocus={handleAddressGroupFocus}
                />
              </FormControl>
              <TextField 
                className={classes.formField} 
                id="u-postal-code" 
                name="postalCode"
                label={ t('users.postal-code') }
                value={ userAddressState.postalCode } 
                onChange={ handleChangeAddress } 
                disabled={!editing}
                error={userAddressState.postalCode === ''}
                helperText={userAddressState.postalCode === '' ? t('users.field-required'): ''}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Typography variant="caption">{ t('users.role') }</Typography>
              <Select
                id="u-role"
                name="role"
                value={ userState.role }
                onChange={ handleChange }
                disabled={true}
                className={classes.formField}
                error={userState.role === ''}
              >
                <MenuItem value="ROLE_USER">{ t('users.user') }</MenuItem>
                <MenuItem value="ROLE_ADMIN">{ t('users.admin') }</MenuItem>
                <MenuItem value="ROLE_SELLER">{ t('users.seller') }</MenuItem>
                <MenuItem value="ROLE_SUPER_SELLER">{ t('users.super-seller') }</MenuItem>
                <MenuItem value="ROLE_SELLER_ASSOCIATE">{ t('users.seller-associate') }</MenuItem>
                <MenuItem value="ROLE_ADMIN_SINGLES">{t('users.admin-singles')}</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={ classes.formControl }>
              {editing && (
                  <div className={ classes.actionsWrapper }>
                    {loading === 'USER_UPDATE' && <CircularProgress/> }
                    <Button variant="text"
                            color="primary"
                            onClick={saveOnClick}
                            disabled={
                              fieldError ||
                              phoneError ||
                              isSaveDisabled() ||
                              (addressGroupEditing &&
                                (userAddressState.province === '' ||
                                  userAddressState.canton === '' ||
                                  userAddressState.district === '' ||
                                  userAddressState.fullAddress === '' ||
                                  userAddressState.postalCode === ''))
                            }
                      >
                      { t('users.save') }
                    </Button>
                    <Button variant="contained" color="secondary" onClick={ cancelOnClick }>
                      { t('users.cancel') }
                    </Button>
                    <Button variant="contained" color="primary" disabled>
                      { t('users.edit-user') }
                    </Button>
                  </div>
                  
                )}
                {!editing && (
                  <div className={ classes.actionsWrapper }>
                    {(loggedUserRole.includes('ROLE_ADMIN') || loggedUserRole.includes('ROLE_SUPER_SELLER')) &&
                      <Button variant="contained" color="secondary" startIcon={<DeleteIcon />} onClick={handleOpenModal}>
                        { t('users.delete') }
                      </Button>
                    }
                    <Button variant="contained" color="primary" onClick={ editOnClick }>
                      { t('users.edit-user') }
                    </Button>
                  </div>
                )}
            </FormControl>
          </form>
        </Grid>
      </Grid>
      <Modal
        open={ openModal }
        onClose={ handleCloseModal }
        aria-labelledby="Confirm Modal"
        >
        <Paper elevation={3} className={ classes.modalPaper }>
          <IconButton className={ classes.closeModal } aria-label="close modal" component="span" onClick={ () => handleCloseModal() }>
            <CloseIcon/>
          </IconButton>
          <Typography>{ t('users.delete-confirm-text', { 'itemName': userState.name }) }</Typography>
          <Button variant="contained" color="primary" onClick={ () => handleCloseModal() }> { t('cart.no') } </Button>
          <Button variant="contained" color="secondary" onClick={ () => deleteUserOnClick() }> { t('cart.yes') } </Button>
        </Paper>
      </Modal>
    </Fragment>
  )
}

export default UserEdit;
