// React.
import React, { Fragment } from 'react';

// Material UI.
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';


// Components.
import ActionAlert from '../admin/utils/ActionAlert';
import Logo from '../client/utils/Logo';
import RegisterForm from './RegisterForm';
import logoImg from '../../alt-logo-1.png';

// Styles.
import useStyles from './RegisterStyles';

const Register = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <ActionAlert />
        <div className={ classes.logo }>
          <Logo altLogo={logoImg}/>
        </div>
        <Paper elevation={3} square={false} variant="elevation">
          <RegisterForm />
        </Paper>
      </Container>
    </Fragment>
  )
}

export default Register;
