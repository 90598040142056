import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import AddOrderDetailsModal from './AddOrderDetailsModal';
import AddOrderPaymentModal from './AddOrderPaymentModal';
import OrderItemSearchModal from './OrderItemSearchModal';
import useStyles from './modalOrdersStyles';  
import {RootStore} from "../../../../Store"
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import OrderBoosterSearchModal from './OrderBoosterSearchModal';
import AddBoosterDetailsModal from './AddBoosterDetailsModal';


interface ModalProps {
  onClose?: () => void;
}
const ModalOrders: React.FC<ModalProps> = ({onClose}) => {
  //Redux State
  const eventState = useSelector((state: RootStore) => state.events);

  const classes = useStyles();
  return (
    <div className={classes.modal}>
      <div className={classes.modalContent}>
      <div className={classes.titleBox}>
        <Typography color='primary'>{eventState.currentPlayerOrderId?.name}</Typography>
        <div onClick={onClose} style={{cursor:'pointer'}}><CloseIcon></CloseIcon></div>
      </div>
      <div className={classes.orderList}>
      {
      eventState.eventDetail?.participationFee!==0?
      <Paper className={classes.columnOnePaper}>
      <OrderBoosterSearchModal/>
      <AddBoosterDetailsModal/>
    </Paper>:
      ''
      }
      <Paper className={classes.columnOnePaper}>
        <OrderItemSearchModal/>
        <AddOrderDetailsModal/>
        </Paper>
      </div>
        <AddOrderPaymentModal/>   
      </div>
    </div>
  );
}

export default ModalOrders;
