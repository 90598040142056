import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      marginBottom: theme.spacing(3),
      '& .MuiFormControl-root': {
        width: `calc(100% - ${theme.spacing(1)}px)`
      }
    },
    rootMobile: {
      display: 'block',
      height: '100%',
      width: '100%',
      '& .MuiFormControl-root': {
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
      },
      '& .MuiInputBase-root': {
        height: '100%',
      },
      '& .MuiSelect-root': {
        paddingLeft: theme.spacing(1)
      },
      '& .MuiInput-underline:after': {
        display: 'none'
      }
    },
  }),
);