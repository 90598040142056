// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemsWrapper: {
      '& .MuiIconButton-root': {
        paddingLeft: 0,
        paddingRight: 0
      }
    }
  }),
);

export default useStyles;
