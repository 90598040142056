// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    printContent: {
      padding: theme.spacing(2),
      '& .MuiTableRow-head': {
        background: theme.palette.grey[200],
      },
      '& .MuiTableCell-head': {
        fontFamily: 'VisbyCF-Bold'
      },
      '& .MuiTableCell-root': {
        verticalAlign: 'top'
      }
    },
    headingCell: {
      ontFamily: 'VisbyCF-Bold',
      backgroundColor: theme.palette.grey[300],
      borderRight: '1px solid black',
      borderTop: "1px solid black",
      padding: '1px',
    },
    headingCellFirst: {
      borderLeft: "1px solid black",
    },
    rowNumberCell: {
      borderLeft: '1px solid black'
    },
    modalPaper: {
      padding: theme.spacing(2, 4),
      position: 'absolute',
      width: '50%',
      height: '90%',
      top: 'calc(50% - 45%)',
      left: 'calc(50% - 25%)',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1),
        width: '80%',
        height: '90%',
        top: 'calc(50% - 45%)',
        left: 'calc(50% - 40%)',
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2, 1),
        width: '90%',
        left: '5%'
      },
      overflow: 'auto'
    },
    logo: {
      background: theme.palette.grey[500]
    },
    tableItems: {
      marginTop: theme.spacing(3)
    },
    tableWrapper: {
      borderCollapse: "collapse",
      marginTop: "15px"
    },
    tableRow: {
      // border: "1px solid black"
      borderBottom: "1px solid black",
    },
    tableCell: {
      borderRight: "1px solid black",
      padding: '1px',
    },
    tableCellOdd: {
      borderRight: "1px solid black",
      padding: '1px',
      background: theme.palette.grey[100]
    }
  }),
);

export default useStyles;
