// React.
import React, { useEffect } from "react";

// Material UI
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getAllUploadedExcels, getUploadedExcelData } from "../../../actions/settingsActions/SettingsActions";
import { RootStore } from "../../../Store";

// Styles.
import useStyles from './ListXlsxSettingsStyles';

// Translations.
import { useTranslation } from 'react-i18next';

const ListXlsxSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation('global');

  // Redux state.
  const settingsState = useSelector((state: RootStore) => state.settings);
  const { loading, xlsxFilesList } = settingsState;

  // Local state.


  useEffect(() => {
    dispatch(getAllUploadedExcels());

    // eslint-disable-next-line
  }, []);

  const downloadFile = (event: React.SyntheticEvent, name:string) => {
    event.preventDefault();
    dispatch(getUploadedExcelData(name));
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h4" color="primary" className={classes.marginBottom}>
          {t('settings.files-list')} {loading === 'EXCELS_LIST' && <CircularProgress size="28px"/>}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={10}>
        {xlsxFilesList?.map((file, index:number) =>
          <Paper key={index} variant="outlined" className={classes.fileWrapper} >
            <FileCopyIcon className={classes.filesIcon} />
            <Link title="Descargar" variant="caption" onClick={(e) => downloadFile(e, file)}>{file}</Link>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};


export default ListXlsxSettings;
