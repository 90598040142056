// React.
import React, {useState, useEffect, useRef} from 'react';

// Material UI
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import DateAdapter from '@mui/lab/AdapterMoment';

// Moment.
import Moment from 'moment';

// Translations.
import { useTranslation } from 'react-i18next';

// Redux
import {useDispatch, useSelector} from "react-redux";

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

// Styles.
import useStyles from './tournamentSearchStyles';
import { Checkbox, Grid } from '@material-ui/core';
import Modal from '../../utils/modal';
import { getEventList, registerEvent, updateFilter, updatePagination } from '../../../actions/eventActions/eventActions';
import { RootStore } from '../../../Store';
import { getAllVendors } from '../../../actions/vendorActions/VendorActions';
import { getItemTypes } from '../../../actions/itemTypeActions/ItemTypeActions';
import { useHistory } from 'react-router-dom';

const today = Moment().format('YYYY-MM-DD');
const tomorrow = Moment().add(1, 'days').format('YYYY-MM-DD');

const TournamentSearch = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [t] = useTranslation('global');

  const params = new URLSearchParams(window.location.search);
  let statusParam = params.get('status');
  let startDate = sessionStorage.getItem('startCreationDate');
  let endDate = sessionStorage.getItem('endCreationDate')

  // Local component state.
  const [mounted, setMounted] = useState(false);
  const [status, setStatus] = React.useState(() => (statusParam !== null && statusParam !== 'active' && statusParam !== 'active') ? statusParam : '');
  const [game, setGame] = React.useState(() => (statusParam !== null && statusParam !== 'active' && statusParam !== 'active') ? statusParam : '');
  const [filter, setFilter] = React.useState(() => (statusParam !== null && statusParam !== 'active' && statusParam !== 'active') ? statusParam : '');
  const [startCreationDate, setStartCreationDate] = useState(() => startDate === null ? today : startDate);
  const [endCreationDate, setEndCreationDate] = useState(() => endDate === null ? tomorrow : endDate);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isParticipationChecked, setIsParticipationChecked]= useState<boolean>(false);
  const [formData, setFormData] = useState({
    name: '',
    code: '',
    entryFee: 0,
    participationFee:0,
    notes: '',
    haveParticipation:true, 
    itemTypeId: 0,
    vendorId:0
  });


  // Redux state.
  const reduxState = useSelector((state: RootStore) => state);
  const eventState: any = useSelector((state: RootStore) => state.events);
  const eventStateFilterRef = useRef(eventState.filter);


//Getting the Vendors and the Items for the Modal Form
  useEffect(()=>{
    dispatch(getAllVendors(null));

    const pageSize = getProjectConfig().UNLIMITED_PAGES_SIZE;

    let sort =  'id,asc';

    const qParams = {
      size: pageSize,
      ...(sort !== '') && { sort: sort }
    };

    dispatch(getItemTypes(qParams));
  }, [dispatch]);


  //Handling Modal Open
  const handleModalOpen = () => {
    setModalOpen(true);
  }

  //Handle Modal Close
   const handleModalClose = () => {
    setModalOpen(false);
  }

  //Handling is participation checked

  const handleIsParticipationChecked = (event:any)=>{
    setIsParticipationChecked(event.target.checked);
  }


  //Handling the status change for the Vendor Id in the Modal Form
  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as string);
    setFormData({ ...formData, vendorId: parseInt(`${event.target.value}`)})
  };

  //Handling the Status change for the games in  the Modal Form
  const handleChangeGame = (event: React.ChangeEvent<{ value: unknown }>) => {
    setGame(event.target.value as string);
    setFormData({ ...formData, itemTypeId: parseInt(`${event.target.value}`)})
  };

  //Handliong the Filter to search for the events
  const handleChangeFilter = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilter(event.target.value as string);
  };

  //Calling the events with the date filter for the first time

  useEffect(()=>{
    if(!mounted){
      const sendFilter = {
        ...eventStateFilterRef.current,
        status: "Abierto",
        startCreationDate: startCreationDate,
        endCreationDate: endCreationDate,
        pageSize:30,
        size:30,
      };
      dispatch(updatePagination(sendFilter))
      setMounted(true)
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  //Handling the Search Functionality
  const history = useHistory();

  const handleSearch = () => {  
    const currentPath = history.location.pathname;
    history.replace(currentPath); 
    const sendFilter = {
      ...eventStateFilterRef.current,
      status: filter,
      startCreationDate: startCreationDate,
      endCreationDate: endCreationDate,
      page:0,
      size:30,
      pageSize:30
    };
    dispatch(updateFilter(sendFilter))
    dispatch(getEventList(sendFilter))   
  };
  const changeInitialDate = (startDate:any) => {
    let newDateString = Moment(startDate).format('YYYY-MM-DD');
    setStartCreationDate(newDateString)
    sessionStorage.setItem('startCreationDate', newDateString);
    if (endCreationDate === null || newDateString > endCreationDate) {
      setEndCreationDate(Moment(startDate).add(1, 'days').format('YYYY-MM-DD'));
      sessionStorage.setItem('endCreationdate', Moment(startDate).add(1, 'days').format('YYYY-MM-DD'));
    }
  };
  const changeEndDate = (endDate:any) => {
    let newDateString = Moment(endDate).format('YYYY-MM-DD');
    setEndCreationDate(newDateString)
    sessionStorage.setItem('endCreationDate', newDateString);
    if (startCreationDate === null || newDateString < startCreationDate) {
      setStartCreationDate(Moment(endDate).subtract(1, 'days').format('YYYY-MM-DD'));
      sessionStorage.setItem('startCreationDate', Moment(endDate).subtract(1, 'days').format('YYYY-MM-DD'));
    }
  };

  //Handling On Register Action
  const handleRegister=(event:any)=>{
    event.preventDefault();
    if(formData.participationFee<=0){
      formData.haveParticipation=false;
    }
    dispatch(registerEvent(formData))
  }

  return (
  <LocalizationProvider dateAdapter={DateAdapter}>
    <div className={ classes.root }>
        <Paper elevation={3}>
          <div className={ classes.boxTop }>
            <SearchIcon color="primary" fontSize="large"/>
            <Typography variant="h5" color="primary">
              { t('tournament.search-event') }
            </Typography>
          </div>
          
        <div className={classes.searchEvent}>
          <Grid container spacing={1} direction={'row'}
            style={{
              marginTop: 10,
              marginBottom: 10,
              width:'auto'
              }}
            >
              <Grid item >
                <DatePicker
                  label={t("orders.order-from-date")}
                  value={startCreationDate}
                  onChange={(newValue:any) => {
                    changeInitialDate(newValue)
                  }}
                  renderInput={(params:any) => <TextField {...params} />}
                />
              </Grid>
              <Grid item >
                <DatePicker
                  label={t("orders.order-to-date")}
                  value={endCreationDate}
                  onChange={(newValue:any) => {
                    changeEndDate(newValue)
                  }}
                  renderInput={(params:any) => <TextField {...params} />}
                />
              </Grid>
            </Grid>
            <div className={classes.eventStatus}>
            <form className={ classes.boxForm }>
              <FormControl  >
                <InputLabel id="event-status-label">{ t('tournament.event-status') }</InputLabel>
                <Select
                  labelId="event-status-label"
                  id="event-status"
                  name="event"
                  value={filter}
                  onChange={ handleChangeFilter}
                  className='titme' 
                >
                 <MenuItem className='prueba' value="Abierto">Abierto</MenuItem>
                 <MenuItem value="Finalizado">Cerrado</MenuItem>
                 <MenuItem value="">Todos</MenuItem>
                </Select>
              </FormControl>
            </form>
          </div>
          </div>        
          <div className={ classes.buttons }>
            <Button onClick={ handleSearch } variant="contained" color="primary" className={ classes.buttonsLast }>
              { t('tournament.search-event') }
            </Button>
            {modalOpen && (
              <Modal>
                  <div>
                  <form onSubmit={handleRegister} className={classes.modalForm}>
                    <div style={{ display: 'flex' }}>
                      <Checkbox onChange={handleIsParticipationChecked} />
                      <h2>Participacion</h2>
                    </div>
                    <InputLabel className={classes.formLabel}>
                      <InputLabel className={classes.formLeft}>{t('tournament.event-name')}:</InputLabel>
                      <TextField type="text" name="name" value={formData.name}
                        onChange={(event) => setFormData({ ...formData, name: event.target.value })} />
                    </InputLabel>
                    <br />
                    <label className={classes.formLabel}>
                      <InputLabel className={classes.formLeft}>{t('tournament.entry-fee')}:</InputLabel>
                      <TextField type="number" name="entryFee" value={formData.entryFee}
                        onChange={(event) => setFormData({ ...formData, entryFee: parseInt(`${event.target.value}`) })}
                      />
                    </label>
                    <br />
                    {isParticipationChecked ?
                      <label className={classes.formLabel}>
                        <InputLabel className={classes.formLeft}>{t('tournament.tournament-fee')}:</InputLabel>
                        <TextField type="number" name="participationFee" value={formData.participationFee}
                          onChange={(event) => setFormData({ ...formData, participationFee: parseInt(`${event.target.value}`) })}
                        /><br />
                      </label>
                      :
                      ""
                    }
                    <label className={classes.formLabel}>
                      <InputLabel className={classes.formLeft}>{t('tournament.event-game')}:</InputLabel>
                      <Select
                        labelId="event-status-label"
                        id="event-status"
                        name="event"
                        value={game}
                        onChange={handleChangeGame}
                      >
                        {reduxState.itemType.itemTypeList?.categories.map((type) => {
                          return (
                            <MenuItem value={type.id}>{type.name}</MenuItem>
                          )
                        })}
                      </Select>
                    </label>
                    <br />
                    <label className={classes.formLabel}>
                      <InputLabel className={classes.formLeft}>{t('tournament.place')}:</InputLabel>
                      <Select
                        labelId="event-status-label"
                        id="event-status"
                        name="event"
                        value={status}
                        onChange={handleChangeStatus}
                      >
                        {reduxState.vendors.vendorList?.vendors.map((vendor) => {
                          return (
                            <MenuItem value={vendor.id}>{vendor.name}</MenuItem>
                          )
                        })}
                      </Select>
                    </label>
                    <div className={classes.formButton}>
                    <Button variant="contained" color="secondary" onClick={handleModalClose}>{t('tournament.cancel')}</Button >
                    <Button variant="contained" color="primary" onClick={handleRegister} >{t('tournament.create-event')}</Button >
                  </div>
                  </form>
                  </div>
              </Modal>
            )}
            <Button onClick={ handleModalOpen } variant="contained" color="primary">
              {t('tournament.create-event')}
            </Button>
          </div>
        </Paper>
    </div>
  </LocalizationProvider>
  )
}

export default TournamentSearch;
