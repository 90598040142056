/**
*****************************************
*********** Dispatch Types **************
*****************************************
*/
export const PAYMENT_METHODS_LOADING = "PAYMENT_METHODS_LOADING";
export const PAYMENT_METHODS_LIST = "PAYMENT_METHODS_LIST"
export const PAYMENT_METHOD_REGISTER = "PAYMENT_METHOD_REGISTER";
export const PAYMENT_METHOD_UNREGISTER = "PAYMENT_METHOD_UNREGISTER";
export const GET_ALL_PAYMENT_METHODS = "GET_ALL_PAYMENT_METHODS"

// use when you are going to charge a card token

export type CardTokenObj = {
    token: string,
    kountSession: any,
}

export type CardDataObject = {
    cardHolder: string,
    expirationDate: {
        month: number,
        year: number,
    },
    cardNumber: string,
    cvc: string,
}

export type CardTokenEcrypted = {
    ld: string;
    lk: string | false;
}

export type CardRegistration = {
    card: {
        cardHolder: string,
        expirationDate: {
            month: number,
            year: number,
        },
        cardNumber: string,
        nickname: string,
    }
}

export type CardResponseData = {
    brand: string,
    cardHolder: string,
    expirationDate: string,
    lastDigits: string,
    message: string,
    token: string
}

export interface PaymentMethodsLoading {
    type: typeof PAYMENT_METHODS_LOADING,
    payload: string,
}
export interface PaymentListType {
    type: typeof PAYMENT_METHODS_LIST,
    payload: CardResponseData[],
}

export interface PaymentRegister {
    type: typeof PAYMENT_METHOD_REGISTER,
    payload: string,
}

export interface PaymentUnRegister {
    type: typeof PAYMENT_METHOD_UNREGISTER,
    payload: string,
}

export interface GetAllPaymentMethodsType {
    type: typeof GET_ALL_PAYMENT_METHODS,
    payload: Array<string>,
}

export type PaymentDispatchTypes = PaymentMethodsLoading | PaymentListType  | PaymentRegister | PaymentUnRegister | GetAllPaymentMethodsType;