// React.
import React, { Fragment, useEffect, useState } from 'react';

// Components.
import Pagination from '../../admin/utils/ResultsPagination';
import OrderRow from './OrderRow';
import NumberFormat from '../../admin/utils/NumberFormat';

// Material UI
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {getUserOrders, refreshOrderView} from "../../../actions/orderActions/OrderActions";

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

// Moment.
import Moment from 'moment';

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import { useStyles, Accordion, AccordionSummary, AccordionDetails } from './OrderAccordionStyles';

const UserAccordion = () => {
  const [t] = useTranslation('global');
  const classes = useStyles();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const url = window.location.href;

  // Redux state.
  const orderState = useSelector((state: RootStore) => state.order);
  const {loading, orderList, refresh, orderFilters: filters} = orderState;

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let page = params.get('page');
    const pNumber = Number(page);
    if (page && page !== null) {
      const qParams = {
        ...filters,
        page: pNumber - 1,
        size: getProjectConfig().PAGES_SIZE,
        paged: true
      };
      dispatch(getUserOrders(qParams));
      setCurrentPage(pNumber);
      scrollToTop();
    } else {
      const qParams = {
        ...filters,
        page: 0,
        size: getProjectConfig().PAGES_SIZE,
        paged: true
      };
      dispatch(getUserOrders(qParams));
      setCurrentPage(1);
      scrollToTop();
    }

    dispatch(refreshOrderView(false));
    // eslint-disable-next-line
  }, [url, refresh]);

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (loading === 'LIST') {
    return (
      <div className={ classes.circularProgress }>
        <CircularProgress/>
      </div>
    )
  }

  if (orderList?.orders.length === 0) {
    return (
      <div className={ classes.circularProgress }>
        <Typography color="textSecondary">{ t('deliveries.no-deliveries') }</Typography>
      </div>
    )
  }


  const getUserOrder = (order:any) => {
    return (order.user === null) ? 'Admin' : order.user.email;
  };



  return (
    <Fragment>
        {
          orderList?.orders !== undefined && 
          (orderList!.orders!.map(order => 
            <Accordion key={ order.id } square expanded={expanded === `panel-u-${ order.id }`} onChange={handleChange(`panel-u-${ order.id }`)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={2} className={ classes.firstWrapper }>
                    <Typography variant="subtitle1">{t('orders.order')} {order.orderCode}</Typography>
                  </Grid>
                  <Grid item xs={8} sm={3}>
                  <Typography variant="subtitle1" color="primary"><strong>Pin: {order.pin}</strong></Typography>
                  <Typography variant="caption" color="textSecondary"> { getUserOrder(order) } </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="subtitle1" color="primary">Total: { order.currency.symbol }{ <NumberFormat number={order.totalCost}/> }</Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography variant="caption">{ t('orders.status') }</Typography>
                    <Typography variant="subtitle1" color="textSecondary"> { order.status } </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2} className={ classes.lastWrapper }>
                    <Typography variant="caption" color="textSecondary">{ Moment(order.creationDate).format('MM/DD/YYYY') }</Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <OrderRow order={ order }/>
              </AccordionDetails>
            </Accordion>
          ))
        }
        {
          orderList?.totalPages !== undefined && orderList?.totalPages !== 1 
          ? (<Pagination current={currentPage} path="/user/orders" pagesNumber={ orderList!.totalPages }/>) : ''
        }
    </Fragment>
  );
}

export default UserAccordion;
