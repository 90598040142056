import { Card, Grid, Typography } from "@material-ui/core";
import useStyles from "./TotalSoldCardStyles";
import { getProjectConfig } from "../../getProjectConfig";
import { useTranslation } from 'react-i18next';
import NumberFormat from "react-number-format";
import React from "react";
// Types interfase.
interface TotalSoldCardProps {
  storeName?: string;
  webSold?: number;
  physicalSold?: number;
  totalPackage?: number;
  finalTotal?: number;
}
const TotalSoldCard = ({
  storeName,
  webSold,
  physicalSold,
  totalPackage,
  finalTotal,
}: TotalSoldCardProps) => {
  const classes = useStyles();
  const [t] = useTranslation("global");

  return (
    <Card>
      <div className={classes.wrapper} >
        <Typography
          variant="h5"
          component="h5"
          color="primary"
          align="center"
          className={classes.cardTitle}
        >
          {storeName}
        </Typography>

        <Grid
          container
          spacing={1}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <Typography className={classes.typographySubTitle}
              align="left"
              color="primary"
            >
              {t("totals.web")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.typographyValues} align="right">
            {getProjectConfig().CURRENCY_SYMBOL}
              <NumberFormat
                value={(webSold ?? 0)}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <Typography className={classes.typographySubTitle}
              align="left"
              color="primary"
            >
              {t("totals.store")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.typographyValues} align="right">
            {getProjectConfig().CURRENCY_SYMBOL}
              <NumberFormat
                value={(physicalSold ?? 0)}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <Typography className={classes.typographySubTitle}
              align="left"
              color="primary"
            >
               {t("totals.deliveries")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.typographyValues} align="right">
            {getProjectConfig().CURRENCY_SYMBOL}
              <NumberFormat
                value={(totalPackage ?? 0)}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <Typography className={classes.typographySubTitleTotal}
              align="left"
              color="primary"
            >
              {t("totals.total")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.typographyValuesBold} align="right">
              {getProjectConfig().CURRENCY_SYMBOL}
              <NumberFormat
                value={(finalTotal ?? 0)}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

export default  React.memo(TotalSoldCard);
