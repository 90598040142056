import React, { useState, useEffect, Fragment } from "react";

// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {Divider, TextField } from "@material-ui/core";
import { Delete } from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit'

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getItemLabels } from "../../../actions/itemActions/ItemActions";
import { RootStore } from "../../../Store";
import { getAllExtraVariables, updateSingleExtraVariable } from "../../../actions/settingsActions/SettingsActions";

// Model Types.
import { deleteEventPrize, deleteLabelValue, getAllEventPrizes, getAllPrizes, registerEventPrize, registerEventPrizeLabel, updateEventPrize, updateLabelValues } from "../../../actions/prizeActions/PrizeActions";
import { PrizeCategory, itemPrizeType } from "../../../actions/prizeActions/PrizeActionsTypes";
import { getItemTypes } from "../../../actions/itemTypeActions/ItemTypeActions";

// Styles.
import useStyles from './PrizeLabelsSettingsStyles';//calc(100% - 10px)

//Components
import AddLabelModal from "./AddLabelsModal";

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

// Translations.
import { useTranslation } from 'react-i18next';
import Modal from "../../utils/modal";
import AddPrizeModal from "./AddPrizesModal";
import LabelEditModal from "./LabelsEditModal";
import PrizeNameEditModal from "./PrizesNameEditModal";

const LabelsSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation('global');

  // Redux state.
  const reduxState = useSelector((state:RootStore)=>state)
  const prizesState = useSelector((state:RootStore)=> state.prize)
  const itemTypeState = useSelector((state: RootStore) => state.itemType);
  const settingsState = useSelector((state: RootStore) => state.settings);
  const loading = prizesState.loading;
  const categoryLoading = itemTypeState.loading;

  // Local state.
  const [categoryId, setCategoryId] = useState<number>(0);
  const [modalOpen, setModalOpen]= useState<boolean>(false);
  const [prizeModalOpen, setPrizeModalOpen]= useState<Boolean>(false);
  const [newLabel,setNewLabel]= useState<string>("");
  const [createPrizeLabel, setCreatePrizeLabel]= useState<any>("");
  const [isPromo, setIsPromo]= useState<any>("");
  const [modDePremio,setModDePremio]=useState<number>(0);
  const [category, setCategory]= useState<any>("");
  const [itemTypeId, setItemTypeId]= useState<number>(0);
  const [variableValue,setVariableValue] = useState<number|string>('');
  const [labelEditModalOpen, setLabelEditModalOpen]= useState<boolean>(false)
  const [newEditLabelValue,setNewEditLabelValue]= useState<any>();
  const [newEditLabelValueId,setNewEditLabelValueId]= useState<any>();
  const [prizeNameModalOpen,setPrizeNameModalOpen]=useState<boolean>(false);
  const [currentPrizeId,setCurrentPrizeId]=useState<any>();
  const [newPrizeName, setNewPrizeName]=useState<string>("");


  //Getting the Date to filter by category
  useEffect(() => {
    dispatch(getAllPrizes());
    const pageSize = getProjectConfig().UNLIMITED_PAGES_SIZE;

    let sort =  'id,asc';

    const qParams = {
      size: pageSize,
      ...(sort !== '') && { sort: sort }
    };

    dispatch(getItemTypes(qParams));
    dispatch(getAllExtraVariables());
    // eslint-disable-next-line
  }, [])

  //Getting the Variable from Comision de Omega Coins Evento
  useEffect(()=>{
    if(settingsState.variables){
      for(let i=0;i<settingsState.variables.length;i++){
        if(settingsState.variables[i].id===6){
          setVariableValue(settingsState.variables[i].value);
        }
      }
    }
  // eslint-disable-next-line
  },[settingsState])
 
  //Updating whenever there is a succesfull update on the app
  useEffect(()=>{
    if(prizesState.actionStatus?.status==="success"){
      setModalOpen(false);
      setPrizeModalOpen(false);
      setLabelEditModalOpen(false);
      setPrizeNameModalOpen(false);
    }

    switch(prizesState.actionStatus?.message){
      case "status.success-create-label":
        dispatch(getAllPrizes());
        break;
      case "status.success-create-prize":
        dispatch(getAllEventPrizes(itemTypeId));
        break;
      case "status.success-delete-label-value":
        dispatch(getAllPrizes());
        dispatch(getAllEventPrizes(itemTypeId));
        break;
      case "status.success-delete-event-prize":
        dispatch(getAllEventPrizes(itemTypeId));        
        break;
      case "status.success-update-label-values":
        dispatch(getAllPrizes())
        break;    
      default:
        return;  
    }

  // eslint-disable-next-line
  },[prizesState.actionStatus])

  //handle Comision Change
  const handleOnComisionChange = (event:any)=>{
    setVariableValue(event.target.value);
  }

  //Save Variable
  const saveVariable = (variableId:number)=>{
    dispatch(updateSingleExtraVariable(variableId, `${variableValue}`));
  }

  //Handle de modal to add label
  const addLabel = () => {
    setModalOpen(true)
  };

  // Close Modals
  const closeModal = ()=>{
      setModalOpen(false);
      setPrizeModalOpen(false);
      setLabelEditModalOpen(false);
      setPrizeNameModalOpen(false);
  }

  //Handle de addPrize Button modal
  const addPrize = () => {
    setPrizeModalOpen(true);
  };

  //Handle de Create Prize Button
  const handleCreatePrize=()=>{
    const prizeLabel = JSON.parse(createPrizeLabel);
    let isPromoBoolean = isPromo==="si"?true:false
    let payload = {
        itemTypeId:itemTypeId,
        labelValueId:prizeLabel.labelId,
        mod: modDePremio,
        promo:isPromoBoolean
    }
    dispatch(registerEventPrize(payload))
  }

  const handleNewLabel=(event:any)=>{
    setNewLabel(event.target.value)
  }

  const handleCreatePrizeLabelChange=(event: React.ChangeEvent<{ value: any }>)=>{
    setCreatePrizeLabel(event.target.value)
  }

  const handleIsPromo = (event: React.ChangeEvent<{ value: any }>)=>{
    setIsPromo(event.target.value)
  }

  const handleModDePremio = (event: React.ChangeEvent<{ value: any }>) => {
    const value = event.target.value;
    // Validate input value
    if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= 1)) {
      setModDePremio(value);
    }
  };

  //Handle Create Label Button
  const handleCreateLabel = () =>{
    let payload={
      value:newLabel,
      labelId: categoryId
    }
    dispatch(registerEventPrizeLabel(payload))
  }

  const handleSelectChange = (event: React.ChangeEvent<{ value: any }>) => {
    const selectedValue = JSON.parse(event.target.value);
    //Save Current Category on local state
    setCategory(selectedValue.name)
    //Save current categoryId on Local state
    setItemTypeId(selectedValue.itemId)
    setCategoryId(selectedValue.itemId)
    //Get the labels for the category selected
    dispatch(getItemLabels(selectedValue.itemId));
    //Get The prizes for the category selected
    dispatch(getAllEventPrizes(selectedValue.itemId))

    if (selectedValue) {
      prizesState.prizesCategories?.forEach((category:any)=>{
        if(category.itemTypeName===selectedValue.name){
          //Save Category labelId to Create, Edit or Delete labels and prizes
          setCategoryId(category.labelId) 
        }
      })
    }
  };

  //Handle the delete action for deleting prizes, (Trashcan Icon)
  const handleDeleteEvent=(eventId:string)=>{
    dispatch(deleteEventPrize(eventId));
  }

  //Handle the delete action for the labels
  const handleDeleteLabel = (labelId:string)=>{
    dispatch(deleteLabelValue(labelId));
  }

  //Handle the Edit Label button
  const handleLabelEditModalOpen = (value:any,valueId:any) => {
    setNewEditLabelValue(value);
    setNewEditLabelValueId(valueId);
    setLabelEditModalOpen(true)
  };

  const handleLabelEdit = () => {
    let payload = {
      id:newEditLabelValueId,
      value: newEditLabelValue
    }
    dispatch(updateLabelValues(payload))
  };

  // Handle Edit Label Value
  const handleEditlabelValue =(event:any)=>{
    setNewEditLabelValue(event.target.value)
  }

  //handle Prize Name Modal
  const handlePrizeNameModal = (prizeName:any)=>{
    setPrizeNameModalOpen(true);
    setNewPrizeName(prizeName);
  }

  const handlePrizeNameEdit = ()=>{
    let payload = {
      name:newPrizeName
    }
    dispatch(updateEventPrize(payload,currentPrizeId));
  }

  const handlePrizeNameChange=(event:any)=>{
    setNewPrizeName(event.target.value)
  }

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12} sm={12}>
      <Paper elevation={3} square={false} variant="elevation" style={{width:"100%", backgroundColor:'#fff', marginBottom:'30px'}}>
      <Typography variant="h4" color="primary" className={classes.marginBottom} >
          {t('prizes.prize-management')}
          {(categoryLoading !== '' || loading !== '') && <CircularProgress/> }
        </Typography>
        <Typography variant="h6" color="primary" className={classes.marginBottom}>
          {t('prizes.prize-management-subtitle')}
        </Typography>
      </Paper>      
      <Paper elevation={3} square={false} variant="elevation" style={{width:"100%", backgroundColor:'#fff',marginBottom:'30px'}}>
      <Typography variant="h4" color="primary" className={classes.marginBottom}>
         {t('prizes.manage-prize-by-category')}
         </Typography> 
        <FormControl variant="outlined">
          <InputLabel id="category-select-label">{ t('settings.select-parent-category') }</InputLabel>
          <Select
            labelId="category-select-label"
            id="category-select"
            value={category.name}
            onChange={ handleSelectChange }
          >
            {itemTypeState?.itemTypeList?.categories?.map((category:any, index:number) =>
              <MenuItem  value={
                JSON.stringify({
                name: category.name,
                itemId:category.id,
                labelId: category.labelId,
                })}
              key={index}>{category.name}</MenuItem>
            )}
          </Select> 
        </FormControl> 
        {category?
        <div style={{width:'100%'}}>
        <Typography variant="h6" color="primary">
            { t('settings.label-values') }
          </Typography>
          <div style={{display:'flex', flexWrap:'wrap', justifyContent:'flex-start',gap:'30px',marginBottom:'30px',marginTop:'30px'}}>
          {
            reduxState.prize.prizesCategories?.map((eachCategory: PrizeCategory) => {
              if (eachCategory.itemTypeName === category) {
                return eachCategory.values.map((value: any) => {
                  //Checking if the Delete Button should be disabled
                  let isDisabled:boolean;
                  eachCategory.values.length<=1?isDisabled=true:isDisabled=false
                  return (
                    <Paper elevation={2} key={value.id} style={{ width: '45%', display: 'flex', alignItems: 'center' }}>
                      <TextField 
                        name="value"
                        label={t('settings.label-value')}
                        value={value.value}
                        className={classes.labelValue}
                        disabled={true}
                      />
                      <EditIcon
                        style={{ marginRight: '10px' }}
                        onClick={() => handleLabelEditModalOpen(value.value,value.id)}
                      ></EditIcon>
                      <Delete color={isDisabled?"disabled":"inherit"} onClick={()=>isDisabled?null:handleDeleteLabel(value.id)}></Delete>
                      {labelEditModalOpen?
                      <Modal children={
                      <LabelEditModal
                        closeModal={closeModal}
                        newEditLabelValue={newEditLabelValue}
                        handleLabelEdit={handleLabelEdit}
                        handleEditlabelValue={handleEditlabelValue}
                      />}></Modal>:''
                      }
                    </Paper>
                  );
                });
              }
              return null; // Add a default return value if the condition is not met
            })
          }
          </div>
          <div style={{display:'flex', marginBottom:'30px', gap:'20px'}}>
            <Button variant="contained" color="default" component="span" onClick={ addLabel }>
              {t('prizes.create-label')}
            </Button>
            <Button variant="contained" color="default" component="span" onClick={ addPrize }>
              {t('prizes.create-prize')}     
            </Button>
          </div>
      </div>:
        null
      }
          {modalOpen &&
            <Modal children={
            <AddLabelModal 
            closeModal={closeModal} 
            handleCreateLabel={handleCreateLabel} 
            handleNewLabel={handleNewLabel} 
            newLabel={newLabel}/>
            }></Modal>
          }
          {
            prizeModalOpen &&
            <Modal children={
              <AddPrizeModal
              closeModal = {closeModal}
              createPrizeLabel={createPrizeLabel}
              handleCreatePrizeLabelChange={handleCreatePrizeLabelChange}
              category={category}
              isPromo={isPromo}
              handleIsPromo={handleIsPromo}
              modDePremio={modDePremio}
              handleModDePremio={handleModDePremio}
              handleCreatePrize={handleCreatePrize}
              />
            }></Modal>
          } 
      </Paper>
      {category && prizesState.prizes?.length!==0?
        <Paper elevation={3} square={false} variant="elevation" style={{width:"100%", backgroundColor:'#fff'}}>
        <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Typography variant="h4" color="primary" className={ classes.sectionTitle }>
                  {t('prizes.prizes-table')} {loading && <CircularProgress/>} 
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <div className={classes.customTable} aria-label="Variations table">
                  <div className="header">
                    <div className="cell d10 m10">{t('prizes.category')}</div>
                    <div className="cell d20 m20">{t('prizes.prize-name')}</div>
                    <div className="cell d12">isPromo</div>
                    <div className="cell d12">Mod de Premio (0-1)</div>
                    <div className="cell d12"></div>
                  </div>
                  <div className="body">
                    {
                      prizesState.prizes? 
                        prizesState.prizes.map((prize:itemPrizeType)=>{
                          return(  
                        <Fragment>
                        <div className="row">
                            <div className={`cell d10 m10`}>
                             {prize.itemTypeName} 
                            </div>  
                          <div className="cell d20 m20">
                            <div className={classes.playerName}>        
                              <Typography color="primary" variant='body2' style={{minWidth:'80px'}}>
                              {prize.name}  
                              </Typography> 
                              <EditIcon onClick={()=>{
                                handlePrizeNameModal(prize.name)
                                setCurrentPrizeId(prize.id)  
                                }} />
                            </div>
                          </div>
                          <div className="cell d12 small priceCol">
                            {prize.promo?"Si":"No"}
                          </div>
                          <div className="cell d12 m12">
                          {prize.modifier}
                          </div>
                          <div className="cell d12 m12">
                            <Delete onClick={()=>{handleDeleteEvent(`${prize.id}`)}}></Delete>
                          </div>
                        </div>
                        {prizeNameModalOpen && 
                        <Modal 
                        children={
                        <PrizeNameEditModal
                        closeModal={closeModal}
                        newPrizeName={newPrizeName}
                        handlePrizeNameEdit={handlePrizeNameEdit}
                        handlePrizeNameChange={handlePrizeNameChange}
                        />}></Modal>}
                        <Divider></Divider>
                      </Fragment>
                          )
                        }):
                        null
                    }       
                  </div>
                </div>
              </Grid>
            </Grid>
            <Divider/>
               {
               settingsState.variables?.map((variable)=>
               {
                if(variable.id===6){
                  return(
                    <div style={{display:'flex',marginTop:'50px'}}>
                     <Typography variant="h5" className={classes.fieldLabel}>
                      <span>{variable.name}</span>
                      </Typography>
                      <TextField value={variableValue} style={{maxWidth:'60px',textAlign:'center'}} onChange={handleOnComisionChange}></TextField>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{height:'30px'}} 
                        onClick={()=>{saveVariable(variable.id)}}
                        >
                        {t('settings.save')}
                      </Button>
                    </div>
                  )
                }
                return null
               })}
          </Paper>:
          <Paper elevation={3} square={false} variant="elevation" style={{width:"100%", backgroundColor:'#fff'}}>
            <div style={{height:'400px', display:'flex', textAlign:'center', alignItems:'center',justifyContent:'center'}}>
              <Typography variant="h4" color="primary">
                {prizesState.prizes?.length===0?
                  `${t('prizes.no-prizes')}`:
                  `${t('prizes.select-category')}`
                }
              </Typography>
            </div>
            <Divider/>
          </Paper>
          }
      </Grid>
    </Grid>
  );
};


export default LabelsSettings;