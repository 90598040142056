// Material UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formAddress: {
      border: `1px solid ${theme.palette.primary.main}`,
      padding: theme.spacing(1),
      borderRadius: '6px'
    },
    phoneField: {
      width: '100%',
      '&.error label': {
        color: theme.palette.error.main,
      }
    },
    inputError: {
      color: theme.palette.error.main,
      margin: 0,
      fontSize: '0.75rem',
      marginTop: '3px',
      textAlign: 'left',
      fontWeight: 400,
      lineHeight: '1.66'
    },
    selectType: {
      marginBottom: '20px',
    },
  }),
);

export default useStyles;
