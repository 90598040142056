import { GridSortItem, GridSortModel } from "@mui/x-data-grid";

export const getUpdatedSortModel = (model:{
  newModel:GridSortModel,
  currentModel:GridSortItem[],
}):GridSortModel => {
  const { newModel, currentModel } = model;

  if (newModel.length === 0){
    /**
     * when sorting a column a third time, 
     * the newModel returns an empty array
     * so we return a reversed sort
     */
    const sort = currentModel[0].sort === 'desc' ? 'asc' : 'desc';
    const model: GridSortItem[] = [{
      field: currentModel[0].field,
      sort
    }]
    return (model);
  }  else {
    return newModel;
  }
};