import { Button, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from "@material-ui/core";
import useStyles from "./PointOfSaleOrderStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import { AddOrderScanCode } from "./AddOrderScanCode";
import { useEffect, useState } from "react";
import { PointOfSaleOrderSummary } from "./PointOfSaleOrderSummary";
import PointOfSaleOrderItems from "./PointOfSaleOrderItems";
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { addOrder, getShippings, itemAddedListUpdate } from "../../../actions/orderActions/OrderActions";
import { ShippingType, VendorTypeObject } from "../models/ModelTypes";
import { getUserInformation } from "../../../actions/authActions/AuthActions";
import { getUserVendors } from "../../../actions/vendorActions/VendorActions";
import Modal from "../../utils/modal";
import OrderItemSearch from "./OrderItemSearch";  
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';

export const PointOfSaleOrder = () => {
  const history = useHistory();
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();
  const orderItems = useSelector((state: RootStore) => state.order.addedInOrder?.itemAddedList);
  const shippings =  useSelector((state: RootStore) => state.order.shippings);
  const reduxState = useSelector((state: RootStore) => state);
  const bundleAdded = reduxState.order.addedInOrder?.bundleAddedList;
  const reduxAuthState = useSelector((state: RootStore) => state.auth);
  const loggedUser = reduxAuthState.loggedUser;

  const [isScanning, setIsScanning] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState<"Tarjeta"|"Efectivo"|"SINPE"|null>(null);

  useEffect(() => {
    dispatch(getShippings());
    dispatch(getUserVendors());
    dispatch(getUserInformation());
    // eslint-disable-next-line
  }, []);

  const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod(event.target.value as "Tarjeta"|"Efectivo"|"SINPE");
  };

  const getFirstUserStoreId = () => {
    var vendorId = null;
    if (loggedUser.sellerProfile.vendors !== undefined) {
      console.log('vendors');
      console.log(loggedUser.sellerProfile.vendors)
      loggedUser.sellerProfile.vendors.forEach((vendor: VendorTypeObject) => {
        if (vendor.type === 'Tienda') vendorId = vendor.id;
      });
    }
    return vendorId;
  };

  const getRequestObject = () => {
    const requestObject: any = { items: [], bundles: [] };

    // For normal items.
    for (var i = 0; i < orderItems!.length; i++) {
      const current = orderItems![i];
      requestObject.items.push({
        itemId: current.item.id,
        quantity: current.quantity,
        customReference: (current.customReference) ? current.customReference : 'NA'
      });
    }

    // For bundles.
    for (var j = 0; j < bundleAdded!.length; j++) {
      const current = bundleAdded![j];
      requestObject.bundles.push({
        bundleId: current.bundle.id,
        quantity: current.quantity
      });
    }

    return requestObject;
  }

  const createOrder = () => {
    const shipping:ShippingType | undefined = shippings ? shippings[0] : undefined; // Default shipping id
    const addressId = 0; // Default address id
    const vendorId = getFirstUserStoreId(); // Default vendor id
    const userEmail = '';
    const requestObject: any = getRequestObject();

    if (!paymentMethod || !vendorId || !shipping) return;
    dispatch(addOrder(requestObject, paymentMethod, userEmail, shipping.id, addressId, vendorId ));
    setTimeout(() => {
      history.push('/admin/orders');
    }, 2000);
  };

  const clearCurrentOrder = () => {
    dispatch(itemAddedListUpdate([], []));
  }

  return (
    <div>
      <Grid container spacing={2} className={'classes.orderRow'}>
        <Grid item xs={12} id="order-items">
          <Button onClick={() => setIsScanning(!isScanning)} color="primary" variant="contained" style={{ width: '300px%' }}>{t('orders.manual-search')}</Button>
        </Grid>
        <Grid item xs={9} id="order-items" >
            <Typography variant="h3" className={classes.sectionTitle}>
              <ShoppingCartCheckoutIcon/>
              { t('orders.pos-order-title')} { isScanning ? <AddOrderScanCode /> : <Modal modalContentClass={classes.itemSelectionModalWrapper}>
              <OrderItemSearch defaultQuantityOnSelect={true} />
              <Button onClick={() => setIsScanning(!isScanning)} variant="contained" style={{ width: '100%' }}>{t('orders.resume-scan')}</Button>
            </Modal> }</Typography>
          { orderItems ? <PointOfSaleOrderItems /> : <Grid container className={classes.emptyCartWrapper}><Typography variant="h6">{t('orders.start-scan')}</Typography></Grid>}
        </Grid>

        <Grid item xs={3} id="order-summary" direction="column">
          <Typography variant="h3" className={classes.sectionTitle}>{t('orders.summary')}</Typography>
          <PointOfSaleOrderSummary />
          <Grid container className={classes.paymentMethodOptions}>
            <FormLabel id="payment-method-radios">{t('orders.payment-method')}:</FormLabel>
            <RadioGroup
              row
              aria-labelledby="payment-method-radios"
              name="payment-radio-buttons-group"
              value={paymentMethod}
              onChange={handlePaymentMethodChange}
            >
              <FormControlLabel value="Tarjeta" control={<Radio className={classes.radioButton}/>} label="Tarjeta" />
              <FormControlLabel value="Efectivo" control={<Radio className={classes.radioButton}/>} label="Efectivo" />
              <FormControlLabel value="SINPE" control={<Radio className={classes.radioButton}/>} label="SINPE" />
            </RadioGroup>
          </Grid>
          <Grid container className={classes.paymentMethodOptions}>
            <Button
              onClick={() => createOrder()}
              variant="contained"
              style={{ width: '100%', marginBottom: '20px' }}
              disabled={ !orderItems || orderItems?.length === 0 || paymentMethod === null}
            >
              {t('orders.create-order')}
            </Button>
            <Button
              onClick={() => clearCurrentOrder()}
              variant="outlined"
              style={{ width: '100%' }}
            >
              {t('orders.reset-order')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}