import { useHistory } from "react-router-dom";
import { getProjectConfig } from "../../getProjectConfig";
import { useState } from "react";
import {
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import useStyles from "./SelectPageSizeStyles"
import { useTranslation } from "react-i18next";

const SelectPageSize = () => {
  const [currentPagesize] = useState<string>(localStorage.getItem('pageSize') || '10');
  const [t] = useTranslation('global');
  const history = useHistory();
  const classes = useStyles();

  const handlePageSizeChange = (newPageSize:string) => {
    const url = new URL(window.location.href);
    localStorage.setItem('pageSize', newPageSize)
    url.searchParams.set('pageSize', newPageSize);
    window.history.replaceState(null, '', url);
    history.push({
      pathname: '/items',
      search: window.location.search,
    });
  }

  const pageSizes = getProjectConfig().PAGE_SIZES;
  return (<div className={classes.container}>
    <Typography className={classes.label} color="initial">
      {t("items.showing-per-page")}
    </Typography>
    <Select
      className={classes.inputQty}
      value={currentPagesize}
      onChange={(e) => handlePageSizeChange(`${e.target.value}`)}
    >
      {pageSizes.map(
        (pageSize) => <MenuItem
          key={pageSize}
          value={pageSize}>
          {pageSize}
        </MenuItem>
      )}
    </Select>
  </div>);
};

export default SelectPageSize;
