import React from 'react';

// Styles.
import useStyles from './PointsIconStyles';

import logoImg from '../../logo.png';

// Component interface.
interface PointsIconProps {
  width: string;
  gray?: boolean;
};

const PointsIcon = ({ width, gray }: PointsIconProps) => {
  const classes = useStyles();

  return (
    <span className={`${classes.root} ${(gray!) ? 'color-gray' : ''}`} style={{ width: `${width}px`, height: `${width}px`, }}>
      <img src={logoImg} alt="Points icon"/>
    </span>
  );
}

export default PointsIcon;