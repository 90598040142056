// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiFormControl-root.MuiTextField-root': {
        width: '100%',
        marginBottom: theme.spacing(2)
      },
      '& .MuiInputBase-root': {
        width: 'calc(100% - 20px)'
      },
      '& .MuiPaper-root': {
        backgroundColor: process.env.REACT_APP_THEME_COLOR_EXTRA_LIGHT_GRAY,
        padding: theme.spacing(0, 2),
        marginBottom: theme.spacing(1),
        width: 'calc(50% - 10px)',
        marginRight: '5px',
        display: 'inline-block',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          marginRight: 0,
        },
      },
      '& .MuiFormControl-root': {
        width: '350px',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          width: '100%'
        },
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, 2px) scale(0.75)'
      },
      '& .MuiCircularProgress-root': {
        width: '25px !important',
        height: '25px !important',
        marginLeft: theme.spacing(3)
      }
    },
    marginBottom: {
      marginBottom: theme.spacing(4)
    },
    labelsWrapper: {
      backgroundColor: process.env.REACT_APP_THEME_COLOR_EXTRA_LIGHT_GRAY,
      borderRadius: '4px',
      padding: '10px 24px 33px',
      marginBottom: theme.spacing(2),
      width: '100%'
    },
    firstWrapper : {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'start',
      },
    },
    valueButton:{
      marginTop:'20px',
      marginBottom:'20px',
      gap:'15px',
      display:'flex', 
      flexDirection:"column",
      width:'300px'
    },
    lastWrapper : {
      textAlign: 'right',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'left',
      },
    },
    labelValuesWrapper: {
      '& .MuiInputBase-root:before': {
        display: 'none'
      },
      '& .MuiInputBase-root:after': {
        display: 'none'
      },
      valuesBtn: {
        width: '100%',
      }
    },
    actionsWrapper: {
      marginTop: theme.spacing(10),
      textAlign: 'right',
      '& .loading-bottom': {
        verticalAlign: 'middle',
        marginRight: theme.spacing(3)
      }
    }
  }),
);

export default useStyles;