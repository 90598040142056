import { OrderType } from "../../models/ModelTypes"
import { DataGridInterface } from "./reportsInterfaces"

export const mapDataGridInterface = (input:OrderType[]):DataGridInterface[] => {
  const output:DataGridInterface[] = input.map(orderInfo => {
    return {
      id: orderInfo.id,
      orderCode: orderInfo.orderCode,
      creationDate: orderInfo.creationDate,
      status: orderInfo.status,
      trackingNumber: orderInfo.trackingNumber,
      paymentMethod: orderInfo.paymentMethod,
      vendorName: orderInfo.vendor.name,
      sellerEmail: orderInfo.seller.email,
      type: orderInfo.type,
      totalCost: orderInfo.totalCost,
      totalTax: orderInfo.totalTax,
      shippingCost: orderInfo.shippingCost,
      updatedDate: orderInfo.updatedDate,
      completedDate: orderInfo.completedDate,
      processedDate: orderInfo.processedDate,
    }
  })
  return output
}
