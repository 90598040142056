// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      margin: theme.spacing(2),
      textAlign: 'center'
    }
  }),
);

export default useStyles;
