// React.
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Material UI
import Typography from '@material-ui/core/Typography';

// Components.
import NumberFormat from '../utils/NumberFormat';

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "../../../Store";
import { updateQuickOrder } from "../../../actions/orderActions/OrderActions";

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './PointOfSaleOrderStyles';

// Model types.
import { OrderType, ItemType } from '../models/ModelTypes';
import { getProjectConfig } from '../../../getProjectConfig';
import { Grid } from '@material-ui/core';

export const PointOfSaleOrderSummary = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();
  let history = useHistory();

  // Redux state.
  const reduxState = useSelector((state: RootStore) => state);
  const itemsAdded = reduxState.order.addedInOrder?.itemAddedList;
  const bundleAdded = reduxState.order.addedInOrder?.bundleAddedList;
  const actionStatus = reduxState.order.actionStatus;
  const createdOrder = reduxState.order.order;

  useEffect(() => {
    if (actionStatus !== undefined) {
      if (actionStatus.menssage === 'status.success-create') {
        // On quick orders set the status as completed.
        if (true && createdOrder !== undefined) {
          createdOrder.forEach((order: OrderType) => {
            const updateOrderData = {
              orderId: order.id,
              purchaseValidation: t('orders.quick-order-label'),
              status: 'Procesando',
              trackingNumber: ''
            }
            dispatch(updateQuickOrder(updateOrderData));
          });
        } else {
          history.push('/admin/orders');
        }
      }
      // When it's a quick order.
      if (actionStatus.menssage === 'status.success-update') {
        history.push('/admin/orders');
      }
    }
    // eslint-disable-next-line
  }, [actionStatus]);

  // Get a single price based on the customReference.
  const getSinglePrice = (item: ItemType, customReference: string) => {
    var price = 0;
    const priceObj = item.customPrices?.filter((customPrice: any) => customPrice.customReference === customReference);
    if (priceObj?.length! > 0) price = priceObj![0]?.price;
    return price;
  };

  const calculateSubTotal = () => {
    var total = 0;
    if (itemsAdded !== undefined) {
      for (var i = 0; i < itemsAdded!.length; i++) {
        const current = itemsAdded![i];
        if (current.item.category === 'Single') {
          let taxPercentage = current.item.itemType.taxPercentage;
          let totalWithTaxes = Number((getSinglePrice(current.item, current.customReference) * current.quantity).toFixed(2));
          total = Number((total + ((totalWithTaxes * 100) / (100 + taxPercentage))).toFixed(2));
        } else {
          const currentItemPrice = Number(getPriceInfo(current.item));
          const currentQuantity = Number(current.quantity);
          const curretTotal = currentItemPrice * currentQuantity;
          total = total + curretTotal
        }
      }
    }

    if (bundleAdded !== undefined) {
      for (var j = 0; j < bundleAdded!.length; j++) {
        const current = bundleAdded![j];
        const currentbundlePrice = Number(getPriceInfo(current.bundle));
        const currentQuantity = Number(current.quantity);
        const curretTotal = currentbundlePrice * currentQuantity;
        total = total + curretTotal
      }
    }

    return total.toFixed(2);
  };

  const getPriceInfo = (item: any) => {
    if (item.discounts !== null && item.discounts !== undefined) {
      const discount = item.discounts.pricePercentage;
      const savings = Number(((discount / 100) * item.price).toFixed(2));
      const newPrice = (item.price - savings);
      return newPrice;
    } else {
      return item.price;
    }
  };

  const getPriceWithTaxesInfo = (item: any) => {
    if (item.discounts !== null && item.discounts !== undefined) {
      const discount = item.discounts.pricePercentage;
      const savings = Number(((discount / 100) * item.priceWithTaxes).toFixed(2));
      const newPrice = (item.priceWithTaxes - savings);
      return newPrice;
    } else {
      return item.priceWithTaxes;
    }
  };

  const calculateTax = () => {
    var total = 0;
    if (itemsAdded !== undefined) {
      for (let i = 0; i < itemsAdded!.length; i++) {
        const current = itemsAdded![i];
        if (current.item.category === 'Single') {
          let taxPercentage = current.item.itemType.taxPercentage;
          let totalWithTaxes = Number((getSinglePrice(current.item, current.customReference) * current.quantity).toFixed(2));
          let subtotal = Number((totalWithTaxes * 100) / (100 + taxPercentage).toFixed(2));
          total = Number((total + ((subtotal * taxPercentage) / 100)).toFixed(2));
        } else {
          const priceWithTaxes = Number(getPriceWithTaxesInfo(current.item));
          const basePrice = Number(getPriceInfo(current.item));
          const tax = (priceWithTaxes - basePrice) * current.quantity;
          total = total + tax
        }
      }
    }
    if (bundleAdded !== undefined) {
      for (let j = 0; j < bundleAdded!.length; j++) {
        const current = bundleAdded![j];
        const priceWithTaxes = Number(getPriceWithTaxesInfo(current.bundle));
        const basePrice = Number(getPriceInfo(current.bundle));
        const tax = (priceWithTaxes - basePrice) * current.quantity;
        total = total + tax
      }
    }
    return total.toFixed(2);
  };

  const calculateTotal = () => {
    let total = Number(calculateSubTotal()) + Number(calculateTax());
    return total.toFixed(2);
  };

  const getTaxPercentage = () => {
    return '13';
  };

  return (
    <Grid container direction='column'>
      <Typography variant="h5" color="textSecondary" className={classes.summaryItems}>
        {t('orders.subtotal')} {getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={Number(calculateSubTotal())} />}
      </Typography>
      <Typography variant="h5" color="textSecondary" className={classes.summaryItems}>
        {getTaxPercentage()}% {t('orders.tax')}: {getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={Number(calculateTax())} />}
      </Typography>
      <Typography variant="h5" color="textSecondary" className={classes.summaryItems}>
        {t('orders.total')}
      </Typography>
      <Typography variant="h5" color="textPrimary" className={classes.finalTotal}>
        {getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={Number(calculateTotal())} />}
      </Typography>
    </Grid>
  )
}
