// React.
import React, {useEffect, useState} from 'react';

// Material UI
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaymentIcon from '@material-ui/icons/Payment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// Redux.
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../Store";
import { getAllPaymentMethods } from "../../../../actions/userPaymentMethodsActions/UserPaymentMethodsActions";

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './AddOrderPaymentStylesModal';
import { getProjectConfig } from '../../../../getProjectConfig';
import NumberFormat from '../../utils/NumberFormat';
import { Button, CircularProgress } from '@material-ui/core';
import { registerPlayerEventOrder } from '../../../../actions/eventActions/eventActions';
 
const AddOrderPayment = () => {
  const [t] = useTranslation('global');
  const dispatch = useDispatch();
  const classes = useStyles();
    //Local state
    const [selectedPayment, setSelectedPayment]= useState('')

    // Redux state.
    const paymentState = useSelector((state: RootStore) => state.paymentMethods);
    const paymentMethodsList = paymentState.paymentMethodsList;
    const eventState = useSelector((state: RootStore) => state.events);
    const loading = useSelector((state:RootStore)=>state.events.loading)

  useEffect(() => {
    dispatch(getAllPaymentMethods());

    // eslint-disable-next-line
  }, []);
  
  return (
    <div className={ classes.root }>
    <Paper elevation={3} className={classes.paymentWrapper}>
      <div className={ classes.boxTop }>
        <div style={{display:'flex', alignSelf:'flex-start', marginLeft:'20px',marginTop:'15px'}}>
        <PaymentIcon color="primary" fontSize="medium" style={{marginRight:'16px'}}/>
        <Typography color="primary">
          { t('orders.select-payment') }
        </Typography>
        </div>
        <Grid container>
          <Grid item xs={12} sm={12} className={classes.paymentMethodsWrapper}>
            {paymentMethodsList !== undefined && 
            paymentMethodsList.map((paymentMethod:string, index:number) =>
            <Card key={index} className={`${selectedPayment === paymentMethod ? 'selected' : ''}`} onClick={ () => setSelectedPayment(paymentMethod)}>
              <CardContent>
                <Typography className={classes.paymentMethodsBox}>{paymentMethod}</Typography>
              </CardContent>
            </Card>
            )}
          </Grid>
        </Grid>
        <div className={classes.lastPaymentRow}>
        <div className={classes.rowTotal}>
          <Typography variant="subtitle1" color="textSecondary">{t('orders.total-price')}</Typography>
          <Typography variant="h5" color="textPrimary">{getProjectConfig().CURRENCY_SYMBOL}{
            <NumberFormat number={eventState.currentPlayerOrder?.totalPriceItems? 
            Number(eventState.currentPlayerOrder?.totalPriceItems)
            :0
            }/>}</Typography>
        </div>

        {loading!=="loading"?
        <div className={classes.rowActions}>
        {
        (selectedPayment!== "" && ((eventState.currentPlayerOrder?.itemsOrder?.length ?? 0) > 0 ))|| 
        (eventState.currentPlayerOrder?.itemsParticipationOrder?.length ?? 0) > 0 ?
        <Button
        onClick={()=>{
          dispatch(registerPlayerEventOrder({
          eventPlayerId:eventState.currentPlayerOrderId?.id,
          paymentMethod:`${selectedPayment}`     
        }))
      }}
        variant="contained"
        color="primary"     
        >
          {t('tournament.complete-order')}
      </Button>:
      <Button
      variant="contained"
      color="primary"     
      disabled
      >
       {t('tournament.complete-order')}
    </Button>
        }
      </div>:
      <div className={classes.rowActions}>
        <CircularProgress></CircularProgress>      
      </div>
      }   
        </div>
      </div>
    </Paper>
  </div>
  )
}

export default AddOrderPayment;