// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(2, 1),
        padding: theme.spacing(4, 4, 1),
        width: '100%',
        height: 'auto',
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(4, 2, 1),
        },
      },
    },
    boxTop: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      '& > *': {
        marginRight: theme.spacing(2),
      },
    },
    boxForm: {
      '& > *': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%'
      },
    },
    fieldFirst: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    buttonsLast: {
      marginRight: 0
    }
  }),
);

export default useStyles;
