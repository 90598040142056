// React.
import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

// Material UI.
import { Button, CircularProgress, FormControl, Grid, Paper, Typography } from "@material-ui/core";

// Styles.
import useStyles from './PrizesTopStyles';

// Translations.
import { useTranslation } from 'react-i18next';

// Components.

// Redux
import { useSelector } from "react-redux";
import { RootStore } from "../../../Store";

const TotalPrizes = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const history = useHistory();

  // Redux state.
  const reduxPrizeState = useSelector((state: RootStore) => state.prize);
  const reduxEventState = useSelector((state: RootStore) => state.events);
  const { eventPrizeDetails, loading } = reduxPrizeState;
  const { eventDetail } = reduxEventState;

  const goBack = () => {
    history.push(`/admin/tournament/${eventDetail?.id}`);
  };

  if (eventPrizeDetails?.totalAwardsData === null) {
    return (
      <div className={ classes.root + ` ` + classes.rootTotal }>
        <Paper className={ classes.paperAlign } elevation={3} square={false} variant="elevation">
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Typography variant="h4" color="primary" className={ classes.sectionTitle }>
                { t('prizes.title-prizes-total') } {(loading === 'PRIZE_EVENT_DETAIL') && <CircularProgress/>}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <div>
                <Typography className={ classes.centerText } color="primary">
                  { t('prizes.no-results-total') }
                </Typography>
              </div>
              <div>
                <FormControl className={ classes.formControl }>
                  <div className={ classes.actionsWrapper + ` ` + classes.actionsWrapperFull }>
                    <Button variant="contained" color="primary" onClick={ goBack }>
                      { t('prizes.btn-prizes-total-no-results') }
                    </Button>
                  </div>
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }

  return (
    <div className={ classes.root + ` ` + classes.rootTotal }>
      <Paper className={ classes.paperAlign } elevation={3} square={false} variant="elevation">
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Typography variant="h4" color="primary" className={ classes.sectionTitle }>
              { t('prizes.title-prizes-total') } {(loading === 'PRIZE_EVENT_DETAIL' || loading === 'MANAGE_PRIZE_TO_EVENT') && <CircularProgress/>}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <div className={ classes.paddingBottom }>
              <div className={classes.totalAwards}>
                <Grid container spacing={1}>
                  <Grid item xs={4} sm={4}></Grid>
                  <Grid item xs={4} sm={4}>
                    <Typography className={classes.centerText} variant="body2">{ t('prizes.suggested-total') }</Typography>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Typography className={classes.centerText} variant="body2">{ t('prizes.real-top') }</Typography>
                  </Grid>
                </Grid>
              </div> 
              { eventPrizeDetails?.totalAwardsData !== undefined && 
                eventPrizeDetails?.totalAwardsData.map((award: any, i: number) => 
                <Fragment key={i}>
                  <div className={classes.totalAwards}>
                    <Grid container spacing={1}>
                      <Grid item xs={4} sm={4}>
                        <Typography variant="caption">{ award.name }</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <Typography className={classes.centerText} variant="caption">{ award.suggestedQuantity }</Typography>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <Typography className={classes.centerText} variant="caption">{ award.realQuantity }</Typography>
                      </Grid>
                    </Grid>
                  </div>                      
                </Fragment>)
              }
            </div>
            <div>
              <FormControl className={ classes.formControl }>
                <div className={ classes.actionsWrapper + ` ` + classes.actionsWrapperFull }>
                  <Button variant="contained" color="primary" onClick={ goBack } disabled={eventPrizeDetails?.totalAwardsData === null}>
                    { t('prizes.btn-prizes-total') }
                  </Button>
                </div>
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default TotalPrizes;
