// React.
import React, {useState} from 'react';

// Material UI
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

// Translations.
import { useTranslation } from 'react-i18next';

import {useDispatch} from "react-redux";
import {refreshUserView, userSetFilters} from "../../../actions/userActions/UserActions";

// Styles.
import useStyles from './UserSearchStyles';

const UserSearch = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [t] = useTranslation('global');

  // Local state.
  const [search, setSearch] = useState<string>('');

  const handleChangeSearch = (event: React.ChangeEvent<{ value: string }>) => {
    setSearch(event.target.value);
    if (event.target.value === '') {
      handleClear();
    }
  };

  const handleClick = () => {
    const sendFilter = {
      searchTerm: search
    };

    dispatch(userSetFilters(sendFilter));
    dispatch(refreshUserView(true));
  };

  const handleClear = () => {
    const sendFilter = {};
    dispatch(userSetFilters(sendFilter));
    dispatch(refreshUserView(true));
    setSearch('');
  };


  return (
    <div className={ classes.root }>
      <Paper elevation={3}>
        <div className={ classes.boxTop }>
          <SearchIcon color="primary" fontSize="large"/>
          <Typography variant="h5" color="primary">
            { t('users.user-search') }
          </Typography>
        </div>
        <div>
          <form className={ classes.boxForm }>
            <TextField 
              id="search-keys"
              name="search-keys"  
              value={ search }
              label={ t('users.search-key') } 
              className={ classes.fieldFirst }
              onChange={ handleChangeSearch }
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleClick();
                }
              }}
            />
          </form>
        </div>
        <div className={ classes.buttons }>
          <Button onClick={ handleClear } variant="contained" color="secondary">
            { t('users.clear') }
          </Button>
          <Button onClick={ handleClick } variant="contained" color="primary" className={ classes.buttonsLast }>
            { t('users.search') }
          </Button>
        </div>
      </Paper>
  </div>
  )
}

export default UserSearch;
