// react.
import React, { useState, useLayoutEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

// Material UI.
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import FilterListIcon from '@material-ui/icons/FilterList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

// Redux.
import { useSelector } from "react-redux";
import { RootStore } from "../../../Store";

// Styles.
import { useStyles, Accordion, AccordionSummary, AccordionDetails } from './SearchFiltersStyles';

// Translations.
import { useTranslation } from 'react-i18next';

const SearchFilters = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const history = useHistory();

  const params = new URLSearchParams(window.location.search);
  let labelValueIds = params.get('labelValueId');

  // Redux state.
  const itemReduxState = useSelector((state: RootStore) => state.item);
  const filterLabels = itemReduxState.itemListSearchData?.labels;

  // Local state.
  const [openDrawer, setOpenDrawer] = useState(false);
  const [expanded, setExpanded] = useState<string | false>(false);

  // eslint-disable-next-line
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  // Track the screen size changes.
  const useWindowSize = () => {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
      const updateSize = () => {
        setSize(window.innerWidth);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  let width = useWindowSize();

  const buildQueryParam = (id: number) => {
    var valuesArray = (labelValueIds !== null) ? labelValueIds.split(',') : [];
    if (!valuesArray.includes(String(id))) {
      valuesArray?.push(String(id));
    } else {
      valuesArray = valuesArray.filter((element:string) => element !== String(id));
    }

    const url = new URL(window.location.href);
    // Restart pager.
    url.searchParams.delete('page');

    // Add label id paramenters.
    if (valuesArray.length > 0) {
      url.searchParams.set('labelValueId', valuesArray.join(','));
      window.history.replaceState(null, '', url);
    } else {
      url.searchParams.delete('labelValueId');
      window.history.replaceState(null, '', url);
    }

    history.push({
      pathname: '/items',
      search: window.location.search,
    });

    handleDrawerClose();
  };

  const removeFilters = () => {
    let url = new URL(window.location.href);
    url.searchParams.delete('labelValueId');
    window.history.replaceState(null, '', url);

    history.push({
      pathname: '/items',
      search: window.location.search,
    });

    handleDrawerClose();
  };

  const isFilterSelected = (id:number) => {
    const valuesArray = (labelValueIds !== null) ? labelValueIds.split(',') : [];
    return valuesArray.includes(String(id));
  };

  const isFilterActive = () => {
    const valuesArray = (labelValueIds !== null) ? labelValueIds.split(',') : [];
    return (valuesArray.length > 0);
  };

  function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
    return <ListItem button component="a" {...props} />;
  }

  return (
    <div className={classes.root}>
      {(width < 960) &&
        <Fragment>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open ? classes.hide : '')}
          >
            <FilterListIcon />
          </IconButton>
          <Drawer
            className={classes.drawer}
            variant="temporary"
            anchor="right"
            open={openDrawer}
            onClose={handleDrawerClose}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <Typography className='mobile-title'>{t('nav.filters')}</Typography>
              <IconButton onClick={handleDrawerClose}>
                <CloseIcon/>
              </IconButton>
            </div>
            <Divider />
            <List>
              {filterLabels?.map((label) =>
                <Accordion key={label.labelName} square expanded={expanded === `panel-u-${label.labelName}`} onChange={handleChange(`panel-u-${label.labelName}`)}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id={`panel-header-${label.labelName}`}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        {label.labelName}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails id={`panel-details-${label.labelName}`}>
                    {label.labelValues?.map((value) =>
                      <ListItemLink onClick={()=>buildQueryParam(value.labelValueID)} key={value.labelValueID} className={isFilterSelected(value.labelValueID) ? 'selected' : ''}>
                        <ListItemText primary={`${value.labelValue} (${value.totalLabelValues})`} />
                      </ListItemLink>
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
              {isFilterActive() &&
                <ListItemLink onClick={() => removeFilters()}>
                  <ListItemText primary={t('nav.see-all')} />
                </ListItemLink>
              }
              <Accordion square>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="page-size-control">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      {t("items.page-size")}
                    </Grid>
                  </Grid>
                </AccordionSummary>
              </Accordion>
            </List>
          </Drawer>
        </Fragment>
      }
      {(width >= 960) &&
        <div className={classes.filtersWrapper}>
          <Typography variant="h5" component="h5" color="primary">
            {t('nav.filters')}
          </Typography>
          <br/>
          {isFilterActive() &&
            <ListItemLink onClick={() => removeFilters()}>
              <ListItemText primary={t('nav.see-all')} />
            </ListItemLink>
          }
          {filterLabels?.map((label) =>
            <Accordion className="desktop" key={label.labelName} square expanded={expanded === `panel-u-${label.labelName}`} onChange={handleChange(`panel-u-${label.labelName}`)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id={`panel-header-${label.labelName}`}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    {label.labelName}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails id={`panel-details-${label.labelName}`}>
                {label.labelValues?.map((value) =>
                  <ListItemLink onClick={() => buildQueryParam(value.labelValueID)} key={value.labelValueID} className={isFilterSelected(value.labelValueID) ? 'selected' : ''}>
                    <ListItemText primary={`${value.labelValue} (${value.totalLabelValues})`} />
                  </ListItemLink>
                )}
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      }
    </div>
  )
}

export default SearchFilters;
