// React.
import React, { useEffect, Fragment } from 'react';
import {useParams} from "react-router-dom";
import DocumentMeta from 'react-document-meta';


// Material UI.
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

// Component.
import ItemDetail from '../items/ItemDetail';
import ItemSingleDetail from '../items/ItemSingleDetail';


// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

// Redux
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {getItemByCode} from "../../../actions/itemActions/ItemActions";
import { getUserInformation } from '../../../actions/authActions/AuthActions';

// Styles.
import useStyles from './ItemDetailPageStyles';

type ParamsType = {
  code: string;
};

const ItemDetailPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const authToken: string | undefined = localStorage.token;
  // Page metatags info.
  const meta = getProjectConfig().METATAGS.ITEM_DETAIL;
  const { code } = useParams<ParamsType>();

  useEffect(() => {
    // Get the item details
    dispatch(getItemByCode(code ?? ''));

    // eslint-disable-next-line
  }, [])

  // Redux state.
  const itemProductState = useSelector((state: RootStore) => state.item);
  const { itemDetail } = itemProductState;
  const authState = useSelector((state: RootStore) => state.auth);
  const { loggedUser } = authState;

  // Get user information (Neded for Cart data).
  useEffect(() => {
    if (authToken !== undefined && authToken !== '') {
      if (loggedUser === undefined) dispatch(getUserInformation());
    }

    // eslint-disable-next-line
  }, []);

  return (
    <DocumentMeta {...meta}>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.paperWrapper}>
        <Paper elevation={3} square={false} variant="elevation">
          {itemDetail !== undefined && 
            <Fragment>
              {itemDetail.category === 'Single' ?
                <ItemSingleDetail itemDetail={itemDetail} /> :
                <ItemDetail itemDetail={itemDetail} />
              }
            </Fragment>
          }
        </Paper>
      </Container>
    </DocumentMeta>
  )
}

export default ItemDetailPage;
