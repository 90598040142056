/**
 *****************************************
 *********** Dispatch Types **************
 *****************************************
 */
export const GET_NAV = "GET_NAV";
export const NAV_FAIL = "NAV_FAIL";
export const NAV_LOADING = "NAV_LOADING";
export const SET_NAV_FILTERS = "SET_NAV_FILTERS";
export const REGISTER_MENU = "REGISTER_MENU";
export const UPDATE_MENU = "UPDATE_MENU";
export const DELETE_MENU = "DELETE_MENU";
export const REGISTER_SUBMENU = "REGISTER_SUBMENU";
export const UPDATE_SUBMENU = "UPDATE_SUBMENU";
export const DELETE_SUBMENU = "DELETE_SUBMENU";
export const GET_ALL_ICONS_URL = "GET_ALL_ICONS_URL";
export const UPDATE_NAV_ADMIN = "UPDATE_NAV_ADMIN";

export type NavFiltersType = {
  category?: string;
  onlyBundles: boolean;
  onlyDiscount: boolean;
  itemTypeIds: string;
  labelValuesIds: string;
};

export type NavSubItemType = {
  createdDate: string;
  iconUrl: string;
  id: number;
  sequence: number;
  itemTypeIds: string;
  labelValuesIds: string;
  uiUrl: string;
  value: string;
  menuId?: number;
};

export type NavItemType = {
  createdDate: string;
  iconUrl: string;
  id: number;
  sequence: number;
  itemTypeIds: string;
  labelValuesIds: string;
  submenuTitles: NavSubItemType[]
  uiUrl: string;
  value: string;
  menuId?: number;
};

/**
 *****************************************
 *********** Interfaces Types ************
 *****************************************
 */
export interface NavLoading {
  type: typeof NAV_LOADING;
  payload: string;
};

export interface NavFail {
  type: typeof NAV_FAIL;
};

export interface GetNav {
  type: typeof GET_NAV;
  payload: NavItemType[];
};

export interface RegisterMenu {
  type: typeof REGISTER_MENU;
  payload: NavItemType;
};

export interface UpdateMenu {
  type: typeof UPDATE_MENU;
  payload: NavItemType;
};

export interface DeleteMenu {
  type: typeof DELETE_MENU;
  payload: string;
};

export interface RegisterSubMenu {
  type: typeof REGISTER_SUBMENU;
  payload: NavSubItemType;
};

export interface UpdateSubMenu {
  type: typeof UPDATE_SUBMENU;
  payload: NavSubItemType;
};

export interface DeleteSubMenu {
  type: typeof DELETE_SUBMENU;
  payload: string;
};

export interface SetNavFilters {
  type: typeof SET_NAV_FILTERS;
  payload: NavFiltersType;
};

export interface GetAllIconsUrl {
  type: typeof GET_ALL_ICONS_URL;
  payload: Array<string>;
};

export interface SetUpdateNavAdmin {
  type: typeof UPDATE_NAV_ADMIN;
  payload: boolean;
};

// Export types.
export type NavDispatchTypes = NavLoading | NavFail | GetNav | SetNavFilters | RegisterMenu 
  | UpdateMenu | DeleteMenu | RegisterSubMenu | UpdateSubMenu | DeleteSubMenu | GetAllIconsUrl | SetUpdateNavAdmin;
