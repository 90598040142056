// React.
import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';

//  JWT decode token.
import jwtDecode from 'jwt-decode';

// Crypto JS
import CryptoJS from 'crypto-js';

// Material UI.
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux
import {useDispatch, useSelector} from "react-redux";
import { validateAccount, loginUser, resendActivationCode } from "../../actions/authActions/AuthActions";
import {RootStore} from "../../Store";

// Styles.
import useStyles from './LoginFormStyles';

// Translations.
import { useTranslation } from 'react-i18next';

const ValidateAccountForm = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  let history = useHistory();

  // Redux state.
  const dispatch = useDispatch();
  const reduxState = useSelector((state: RootStore) => state.auth);
  const loading = reduxState.loading;
  const actionStatus = reduxState.actionStatus;
  const loginObject = reduxState.loginObject;

  // Look for login user information.
  const authToken = localStorage.token;
  var defaultEmail = '';
  var defaultPassword = '';
  if (authToken !== undefined && authToken !== '') {
    const decodedToken:any = jwtDecode(authToken);
    defaultEmail = decodedToken.sub;
  } else {
    defaultEmail = localStorage.registerEmail ? localStorage.registerEmail : '';
  }

  const registerPass = localStorage.registerPass;
  if (registerPass !== undefined) {
    let bytes  = CryptoJS.AES.decrypt(registerPass, 'sfapp293847crp');
    defaultPassword = bytes.toString(CryptoJS.enc.Utf8);
  }

  // Local State.
  const [email, setEmail] = useState<string>(defaultEmail);
  const [code, setCode] = useState<string>('');
  const [errorArray, setErrorArray] = useState<Array<string>>([]);

  useEffect(() => {
    if (actionStatus !== undefined) {
      if (actionStatus.menssage === 'status.success-validate') {
        // Login the user.
        if (defaultEmail !== '' && defaultPassword !== '') {
          dispatch(loginUser(defaultEmail, defaultPassword));
        }
      }
    }
    
    // eslint-disable-next-line
  }, [actionStatus]);

  // Redirect to the page after login.
  if (loginObject !== undefined) {
    history.push('/');
  }

  const emailChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setEmail(String(event.target.value));

    // Validate and add errors.
    const errors = [...errorArray];
    const name = event.target.name;
    const nameString = '' + [name]
    if (event.target.value === '') {
      errors.push(nameString);
      setErrorArray(errors);
    } else {
      var newErrors = errors.filter(function(value){ 
        return value !== nameString;
      });
      setErrorArray(newErrors);
    }
  }

  const codeChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setCode(String(event.target.value));

    // Validate and add errors.
    const errors = [...errorArray];
    const name = event.target.name;
    const nameString = '' + [name]
    if (event.target.value === '') {
      errors.push(nameString);
      setErrorArray(errors);
    } else {
      var newErrors = errors.filter(function(value){ 
        return value !== nameString;
      });
      setErrorArray(newErrors);
    }
  }

  const handleChangeValidation = (inputName: string) => {
    var result = false;
    errorArray.forEach((key) => {
      if (key === inputName) {
        result = true;
      }
    });

    return result;
  };

  const validateFields = () => {
    var result = true;
    const errors = [...errorArray];
    if (email !== undefined) {
      // eslint-disable-next-line
      let emailMask  = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/); 
      if (!emailMask.test(email)) { 
        errors.push('email');
        result = false;  
      }
    }
    if (code === '') {
      errors.push('code');
      result = false;  
    }
    setErrorArray(errors);
    return result;
  };

  const resendCodeOnClick = () => {
    // eslint-disable-next-line
    let emailMask  = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/); 
    if (emailMask.test(email)) {
      dispatch(resendActivationCode(email));
    } else {
      const errors = [...errorArray];
      errors.push('email');
      setErrorArray(errors);
    }
  };

  const validateOnClick = () => {
    if (validateFields()) {
      dispatch(validateAccount(email, code));
      setEmail('');
      setCode('');
    }
  };

  return (
    <div className={ classes.formWrapper }>
      <Typography variant="h5" color="primary">{ t('authentication.validate-title') }</Typography>
      <Typography color="textSecondary">{ t('authentication.validate-description') }</Typography>
      <br/>
      <form noValidate autoComplete="off">
        <CssBaseline />
        <div className="form-fields">
          <TextField 
            id="user-email" 
            name="email"
            type="email"
            value={ email }
            label={ t('authentication.email') }
            color="primary" 
            fullWidth
            onChange={ emailChange } 
            error={ handleChangeValidation('email') }
            helperText={handleChangeValidation('email') ? t('users.field-required'): ''}
          />
          <TextField 
            id="validation-code" 
            name="code"
            label={ t('authentication.validate-code') }
            value={ code }
            color="primary" 
            fullWidth 
            autoFocus
            onChange={ codeChange } 
            error={ handleChangeValidation('code') }
            helperText={handleChangeValidation('code') ? t('users.field-required'): ''}
          />
        </div>
        <div className={classes.formControls}>
          { loading === 'VALIDATE_ACCOUNT' && <CircularProgress /> }
          <Button style={{marginRight: 10}} variant="outlined" color="primary" onClick={ () => resendCodeOnClick() } disabled={email === ''}> { t('authentication.re-send-code') } </Button>
          <Button variant="contained" color="primary" onClick={ () => validateOnClick() }> { t('authentication.validate') } </Button>
        </div>
      </form>
    </div>
  )
}

export default ValidateAccountForm;
