import {
  CREATE_BANNER, 
  SETTINGS_LOADING, 
  SETTINGS_FAIL, 
  GET_BANNERS,
  UPDATE_BANNER,
  REGISTER_ITEMS_BY_XLSX,
  SETTINGS_FAIL_DETAIL,
  VALIDATION_ITEMS_BY_XLSX,
  GET_ALL_XLSX_FILES,
  GET_XLSX_FILE_DATA,
  CREATE_BOX,
  GET_BOXES,
  GET_VARIABLES,
  GET_HOMEPAGE,
  UPDATE_VARIABLES,
  DELETE_ORDER_CONFIRMATION,
  CREATE_ORDER_CONFIRMATION,
  UPDATE_ORDER_CONFIRMATION,
  GET_ORDER_CONFIRMATIONS,
  BannerActionsTypes,
  ItemValidatioResponseType,
  VariableType,
  OrderConfirmation,
  HomepageDataType
} from "../actions/settingsActions/SettingsActionsTypes";

// Default state interface.
interface DefaultStateI {
  loading: string,
  banners?: any
  featuredBoxes?: any
  actionStatus?: any
  errorDetail?: any
  variables?: VariableType[],
  validationResult?: ItemValidatioResponseType,
  xlsxFilesList?: string[],
  homepageData?: HomepageDataType,
  orderConfirmations?: OrderConfirmation[]
}

// Default state.
const defaultState: DefaultStateI = {
  loading: '',
};

const settingsReducer = (state: DefaultStateI = defaultState, action: BannerActionsTypes) : DefaultStateI => {
  switch (action.type) {
    case SETTINGS_FAIL:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'error'}
      }
    case SETTINGS_FAIL_DETAIL:
      return {
        ...state,
        loading: '',
        errorDetail: action.payload
      }
    case SETTINGS_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case CREATE_BANNER:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'success'}
      }
    case UPDATE_BANNER:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'success'}
      }
    case GET_BANNERS:
      return {
        ...state,
        loading: '',
        banners: action.payload
      }
    case GET_HOMEPAGE:
      return {
        ...state,
        loading: '',
        homepageData: action.payload
      }
    case GET_ORDER_CONFIRMATIONS:
      return {
        ...state,
        loading: '',
        orderConfirmations: action.payload
      }
    case CREATE_ORDER_CONFIRMATION:
      return {
        ...state,
        loading: '',
        actionStatus: { menssage: action.payload, status: 'success' }
      }
    case UPDATE_ORDER_CONFIRMATION:
      return {
        ...state,
        loading: '',
        actionStatus: { menssage: action.payload, status: 'success' }
      }
    case DELETE_ORDER_CONFIRMATION:
      return {
        ...state,
        loading: '',
        actionStatus: { menssage: action.payload, status: 'success' }
      }
    case CREATE_BOX:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'success'}
      }
    case GET_BOXES:
      return {
        ...state,
        loading: '',
        featuredBoxes: action.payload
      }
    case REGISTER_ITEMS_BY_XLSX:
      return {
        ...state,
        loading: '',
        actionStatus: { menssage: action.payload, status: 'success' }
      }
    case VALIDATION_ITEMS_BY_XLSX:
      return {
        ...state,
        loading: '',
        validationResult: action.payload
      }
    case GET_ALL_XLSX_FILES:
      return {
        ...state,
        loading: '',
        xlsxFilesList: action.payload
      }
    case GET_XLSX_FILE_DATA:
      return {
        ...state,
        loading: '',
        actionStatus: { menssage: action.payload, status: 'success' }
      }
    case GET_VARIABLES:
      return {
        ...state,
        loading: '',
        variables: action.payload
      }
    case UPDATE_VARIABLES:
      return {
        ...state,
        loading: '',
        actionStatus: { menssage: action.payload, status: 'success' }
      }
    default:
      return state
  }
};


export default settingsReducer;
