// React.
import React, { useState, useEffect } from "react";

// Others.
import { Container, Draggable } from "react-smooth-dnd";

// Material UI
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { addBanner, updateBanner, getBanners } from "../../../actions/settingsActions/SettingsActions";
import {RootStore} from "../../../Store";

// Model Types.
import { SliderSettingsType } from '../models/ModelTypes';

// Styles.
import useStyles from './SliderSettingsStyles';

// Translations.
import { useTranslation } from 'react-i18next';

const SliderSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation('global');

  // Redux state.
  const settingsState = useSelector((state: RootStore) => state.settings);
  const {loading, banners} = settingsState;

  // Default values.
  var images = [
    {id: 1, img: '', imgFile: {}, enabled: false, new: true, updated: false, gradient: true, title: '', ctaText: '', ctaLink: ''},
    {id: 2, img: '', imgFile: {}, enabled: false, new: true, updated: false, gradient: true, title: '', ctaText: '', ctaLink: ''},
    {id: 3, img: '', imgFile: {}, enabled: false, new: true, updated: false, gradient: true, title: '', ctaText: '', ctaLink: ''},
    {id: 4, img: '', imgFile: {}, enabled: false, new: true, updated: false, gradient: true, title: '', ctaText: '', ctaLink: ''},
    {id: 5, img: '', imgFile: {}, enabled: false, new: true, updated: false, gradient: true, title: '', ctaText: '', ctaLink: ''},
    {id: 6, img: '', imgFile: {}, enabled: false, new: true, updated: false, gradient: true, title: '', ctaText: '', ctaLink: ''}
  ];

  useEffect(() => {
    dispatch(getBanners(false));
    // eslint-disable-next-line
  }, [])

  // Loads the BE data on the local state.
  useEffect(() => {
    if (banners !== undefined) {
      for (var i = 0; i < banners.length; i ++){
        let current = banners[i];
        let imagesPos = getIndex(images, Number(current.position));
        images[imagesPos] = {
          id: images[imagesPos].id, 
          img: current.imageUrl, 
          imgFile: {}, 
          enabled: !current.isDisable, 
          new: false, 
          updated: false,
          gradient: current.isGradient, 
          title: current.text, 
          ctaText: current.ctaText, 
          ctaLink: current.ctaLink 
        };
        setSliderSettings({...sliderSettings, images: images});
      }
    }
    // eslint-disable-next-line
  }, [banners])

  const defaultSliderSettings = {
    images: images,
    currentUpdateIndex: -1
  };

  const [sliderSettings, setSliderSettings] = useState<SliderSettingsType>(defaultSliderSettings);
  const [isOnDrag, setIsOnDrag] = useState<Boolean>(false);
  const [bigImgError, setBigImgError] = useState<Boolean>(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => startSaveProcess(), 2000);
    return () => clearTimeout(timeoutId);

    // eslint-disable-next-line
  }, [sliderSettings]);

  const startSaveProcess = () => {
    if ((sliderSettings.currentUpdateIndex !== -1)) {
      saveSlideSettings(sliderSettings.currentUpdateIndex);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setBigImgError(false);
    }, 7000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [bigImgError])

  const onDrop = ( onDropProps:any ) => {
    setIsOnDrag(false);
    
    let previousPos = (onDropProps.removedIndex - 4);
    let newPos = (onDropProps.addedIndex - 4);

    // Make some validations.
    if ((newPos < banners.length) && sliderSettings.images[previousPos].img !== '') {
      // Cache the previous values.
      const itemMoved = sliderSettings.images[previousPos];
      const itemDisplaced = sliderSettings.images[newPos];
      const newSettings = sliderSettings.images;
      // Update positions.
      newSettings[newPos] = itemMoved;
      newSettings[previousPos] = itemDisplaced;
      setSliderSettings({
        ...sliderSettings,
        images: newSettings,
        currentUpdateIndex: (previousPos + 1)
      });
    }
  };

  const onDragStart = () => {
    setIsOnDrag(true);
  };

  const handleUploadClick = (props:any) => {
    let index = Number(props.target.getAttribute('data-index'));
    let event = props.nativeEvent;
    let filesList = event.target.files as FileList | null;
    let tempURL = URL.createObjectURL(filesList![0]);
    let imagesPos = getIndex(sliderSettings.images, index);
    const newImages = sliderSettings.images;
    let status = sliderSettings.images[imagesPos].img !== '' ? false : true;
    let isEnabled = sliderSettings.images[imagesPos].enabled;
    newImages[imagesPos] = {
      id: index, 
      img: tempURL, 
      imgFile: filesList![0], 
      enabled: isEnabled, 
      new: status, 
      updated: true,
      gradient: true, 
      title: sliderSettings.images[imagesPos].title, 
      ctaText: sliderSettings.images[imagesPos].ctaText, 
      ctaLink: sliderSettings.images[imagesPos].ctaLink 
    };
    images = newImages;
    setSliderSettings({
      ...sliderSettings,
      images: newImages,
      currentUpdateIndex: index
    });
  };

  const handleChangeCheck = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newImagesArray = sliderSettings.images;
    let pos = getIndex(newImagesArray, index);
    let isEnabled = event.target.checked;
    newImagesArray[pos] = {
      id: newImagesArray[pos].id, 
      img: newImagesArray[pos].img, 
      imgFile: newImagesArray[pos].imgFile,
      enabled: isEnabled,
      new: newImagesArray[pos].img !== '' ? false : true,
      updated: true,
      gradient: newImagesArray[pos].isGradient, 
      title: newImagesArray[pos].title, 
      ctaText: newImagesArray[pos].ctaText,
      ctaLink: newImagesArray[pos].ctaLink
    };
    setSliderSettings({
      ...sliderSettings,
      images: newImagesArray,
      currentUpdateIndex: index
    });
  };

  const handleChangeGradintCheck = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newImagesArray = sliderSettings.images;
    let pos = getIndex(newImagesArray, index);
    let isGradient = event.target.checked;
    newImagesArray[pos] = {
      id: newImagesArray[pos].id, 
      img: newImagesArray[pos].img, 
      imgFile: newImagesArray[pos].imgFile,
      enabled: newImagesArray[pos].enabled,
      new: newImagesArray[pos].img !== '' ? false : true,
      updated: true,
      gradient: isGradient, 
      title: newImagesArray[pos].title, 
      ctaText: newImagesArray[pos].ctaText,
      ctaLink: newImagesArray[pos].ctaLink
    };
    setSliderSettings({
      ...sliderSettings,
      images: newImagesArray,
      currentUpdateIndex: index
    });
  };

  const handleChangeTexts = (index: number, event: React.ChangeEvent<{name: string; value: string;}>) => {
    const newImagesArray = sliderSettings.images;
    let pos = getIndex(newImagesArray, index);
    newImagesArray[pos] = {
      ...newImagesArray[pos],
      updated: true,
      [event.target.name]: event.target.value
    };
    setSliderSettings({
      ...sliderSettings,
      images: newImagesArray,
      currentUpdateIndex: index
    });
  };

  const getIndex = (arrayList: Array<any>, id: number) => {
    return arrayList.findIndex(obj => obj.id === id);
  }

  const saveSlideSettings = (index:number) => {
    var moved:Array<string> = [];
    let filtered = sliderSettings.images.filter((slide) => slide.id === index);
    if (filtered.length > 0) {
      const current = filtered[0];
      let currentPos = String((getIndex(images, current.id)) + 1);
      let newPos = String((getIndex(sliderSettings.images, current.id)) + 1);
      const extraData = {
        gradient: (current.gradient === undefined) ? true : current.gradient,
        title: current.title,
        ctaText: current.ctaText,
        ctaLink: current.ctaLink
      }
  
      if (current.img !== '' && current.imgFile !== undefined) {
        if (current.new) {
          dispatch(addBanner(currentPos, current.imgFile, extraData ));
        } else {
          if ((currentPos !== newPos) || current.updated) {
            // Validate move.
            if (!moved.includes(currentPos)) {
              dispatch(updateBanner(currentPos, newPos, !current.enabled, current.imgFile, extraData));
              moved.push(currentPos);
              moved.push(newPos);
            }
          }
        }
      }
    }
  };

  const disabledButton = (pos:number) => {
    var result = false;
    if (pos > 0) {
      if (sliderSettings.images[pos-1]!.img === '') {
        result = true;
      }
    }
    return result;
  };

  return (
    <List className={ isOnDrag ? classes.inOnDrag : ''}>
      <Container dragHandleSelector=".drag-handle" lockAxis="y" onDragStart={ onDragStart } onDrop={ onDrop }>
        <Typography variant="h4" color="primary" className={classes.marginBottom}>
          { t('settings.manage-slider-content') } {loading !== '' && <CircularProgress/>}
        </Typography>
        <Typography color="textSecondary">{ t('settings.image-size') }</Typography>
        <Typography color="textSecondary">{ t('settings.maximum-images') }</Typography>
        <Typography color="textSecondary" className={classes.marginBottom}>{ t('settings.image-weight') }</Typography>
        {sliderSettings.images.map((item, index) => (
          <Draggable key={item.id} className={classes.rowWrapper}>
            <ListItem className={classes.imageRow}>
              <form>
                <div className="row-image">
                  <Avatar className={classes.bannerImg} variant="rounded" alt="Item image" src={item.img}/>
                  <input accept="image/*" id={`banner-img-${item.id}`} data-index={item.id} className={classes.fileInput} type="file"  onChange={ handleUploadClick }/>
                </div>
                <div className="row-actions">
                  <label htmlFor={`banner-img-${item.id}`}>
                    <Button variant="contained" color="primary" component="span" disabled={ disabledButton(index) }>
                      { t('settings.update-image') }
                    </Button>
                  </label>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ item.enabled }
                        disabled={ disabledButton(index) || (item.img === '' && !item.enabled) }
                        onChange={ (e) => handleChangeCheck(item.id, e) }
                        name="slider_enabled"
                        color="primary"
                      />
                    }
                    label={ t('settings.enable-slider') }
                  />
                </div>
                <div className="row-text-inputs">
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={ item.gradient }
                        disabled={ disabledButton(index) || (item.img === '' && !item.enabled) }
                        onChange={ (e) => handleChangeGradintCheck(item.id, e) }
                        name="slider_enabled"
                        color="primary"
                      />
                    }
                    label={ t('settings.add-gradient') }
                  />
                  <FormControl>
                    <TextField 
                      value={ item.title }
                      disabled={ disabledButton(index) || (item.img === '' && !item.enabled) }
                      onChange= { (e) => handleChangeTexts(item.id, e) }
                      id="i-title" name="title" color="primary"
                      label={ t('settings.title') }
                    />
                  </FormControl>
                  <FormControl>
                    <TextField 
                      value={ item.ctaText }
                      disabled={ disabledButton(index) || (item.img === '' && !item.enabled) }
                      onChange= { (e) => handleChangeTexts(item.id, e) }
                      id="i-ctaText" name="ctaText" color="primary"
                      label={ t('settings.cta-text') }
                    />
                    <TextField 
                      value={ item.ctaLink }
                      disabled={ disabledButton(index) || (item.img === '' && !item.enabled) }
                      onChange= { (e) => handleChangeTexts(item.id, e) }
                      type="url"
                      label={ t('settings.cta-link') }
                      id="i-ctaLink" name="ctaLink" color="primary"
                    />
                  </FormControl>
                </div>
              </form>


              <ListItemSecondaryAction>
                <ListItemIcon className="drag-handle">
                  <DragIndicatorIcon />
                </ListItemIcon>
              </ListItemSecondaryAction>
            </ListItem>
          </Draggable>
        ))}
        {bigImgError &&
          <Typography color="secondary" className={classes.marginBottom}>{ t('settings.big-img-error') }</Typography>
        }
      </Container>
    </List>
  );
};


export default SliderSettings;
