// React.
import React, { useLayoutEffect, useState, useEffect } from 'react';
import DocumentMeta from 'react-document-meta';

// Components.
import Slider from '../utils/Slider';
import FeaturedCards from '../items/FeaturedCards';

// Redux
import { useDispatch } from "react-redux";
import { getHomepageInformation } from "../../../actions/settingsActions/SettingsActions";

// Config.
import { getProjectConfig } from '../../../getProjectConfig';

// Styles.
import useStyles from './HomeStyles';

const Home = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Page metatags info.
  const meta = getProjectConfig().METATAGS.HOME;

  useEffect(() => {
    dispatch(getHomepageInformation());
    // eslint-disable-next-line
  }, [])

  // Track the screen size changes.
  const useWindowSize = () => {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
      const updateSize = () => {
        setSize(window.innerWidth);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  let width = useWindowSize();

  return (
    <DocumentMeta {...meta}>
      <div className={`${(width < 768) && classes.topMargin}`}>
        {(width >= 768) &&
          <Slider />
        }
        <FeaturedCards />
      </div>
    </DocumentMeta>
  )
}

export default Home;
