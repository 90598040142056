import React, { Fragment, useState, useEffect } from 'react';

// Material UI.
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'; 
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Carousel } from 'react-responsive-carousel';
import Paper from '@material-ui/core/Paper';

// Components.
import NumberFormat from '../../admin/utils/NumberFormat';
import LeftArrow from '../utils/LeftArrow';
import RightArrow from '../utils/RightArrow';
import AddToWishList from '../utils/AddToWishlist';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { addItemToCart, addItemToCartLocal } from "../../../actions/cartActions/CartActions";
import { getUserInformation } from '../../../actions/authActions/AuthActions';
import { RootStore } from "../../../Store";

// Model Types.
import { ItemDatailResponse, ItemDatailSearchVendorData } from '../../admin/models/ModelTypes';

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

// Styles.
import useStyles from './ItemDetailStyles';

// Translations.
import { useTranslation } from 'react-i18next';
import defaultImage from '../../../images/default-img.jpg';

// Types interfase.
interface ItemCardProps {
  itemDetail: ItemDatailResponse;
};
const ItemDetail = ({ itemDetail } : ItemCardProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();
  const authToken: string | undefined = localStorage.token;

  // Redux state.
  const userReduxState = useSelector((state: RootStore) => state.auth);

  // Redux state.
  const cartReduxState = useSelector((state: RootStore) => state.cart);
  const cartLoading = cartReduxState.loading;
  const actionStatus = cartReduxState.actionStatus;

  const [quantities, setQuantities] = useState<any>({});
  var countAvailable = -1;

  useEffect(() => {
    if (actionStatus?.menssage === 'status.add-cart') {
      if (authToken !== undefined && authToken !== '') dispatch(getUserInformation());
    }

    // eslint-disable-next-line
  }, [actionStatus]);

  const handleQuantityChange = (id:string, value: unknown) => {
    if (id !== undefined && value !== undefined) {
      const newValues = quantities;
      newValues[id] = value;
      if (Number(value) >= 0) {
        setQuantities({ ...quantities, newValues });
      }
    }
  }

    // Gets the total quantity from all vendor warehouses.
  const getAllWarehousesQuantity = (currentItemQuantityData: ItemDatailSearchVendorData) => {
    var result = currentItemQuantityData.totalQuantity;

    // Check if there is already items added of this.
    if (userReduxState.loggedUser?.cart !== undefined) {
      userReduxState.loggedUser.cart.forEach((cartItem:any) => {
        if (cartItem.item.id === currentItemQuantityData.itemId) {
          result = result - cartItem.quantity;
        }
      });
    }

    // Check items added for anonimous users.
    var cartLocal = localStorage.anonymousCart !== undefined ? JSON.parse(localStorage.anonymousCart) : [];
    if (cartLocal.length > 0) {
      const index = cartLocal.findIndex((obj: any) => obj.item.itemId === currentItemQuantityData.itemId);
      if (index !== -1) {
        let currentQty = cartLocal[index].quantity;
        result = result - currentQty;
      }
    }

    // Check if there is already items of this bundle added.
    if (userReduxState.loggedUser?.bundleCart !== undefined) {
      userReduxState.loggedUser?.bundleCart.forEach((bundleItem:any) => {
        const matchedItems = bundleItem.bundle.items.filter((cartItem: any) => cartItem.item.id === currentItemQuantityData.itemId);
        if (matchedItems.length > 0) {
          result = result - (bundleItem.quantity * matchedItems[0].quantity);
        }
      });
    }

    // Check if there is already items of this bundle added for anonimous users.
    var bundleCartLocal = localStorage.anonymousBundleCart !== undefined ? JSON.parse(localStorage.anonymousBundleCart) : [];
    if (bundleCartLocal.length > 0) {
      bundleCartLocal.forEach((bundleItem:any) => {
        const matchedItems = bundleItem.bundle.items.filter((cartItem: any) => cartItem.item.id === currentItemQuantityData.itemId);
        if (matchedItems.length > 0) {
          result = result - (bundleItem.quantity * matchedItems[0].quantity);
        }
      });
    }

    if (result !== 0) {
      countAvailable = countAvailable + result;
    }

    return result;
  };

  const addToCart = (item: any) => {
    let quantity = quantities[item.itemId] > 0 ? quantities[item.itemId] : 1;
    if (localStorage.token) {
      // If user is logged.
      dispatch(addItemToCart(item.itemId, quantity));
    } else {
      // If user is anonimous.
      var cart = localStorage.anonymousCart !== undefined ? JSON.parse(localStorage.anonymousCart) : [];
      // validate if already exists.
      const index = cart.findIndex((obj: any) => obj.item.itemId === item.itemId);
      let newItem = { ...item, images: itemDetail.images }
      if (index !== -1) {
        let currentQty = cart[index].quantity;
        cart[index] = { item: newItem, itemName: itemDetail.name, quantity: (currentQty + quantity), isFull: false };
      } else {
        cart.push({ item: newItem, itemName: itemDetail.name, quantity: quantity, isFull: false });
      }
      localStorage.removeItem('anonymousCart');
      localStorage.setItem('anonymousCart', JSON.stringify(cart));
      dispatch(addItemToCartLocal());
    }

    // Re-Set the state value.
    const newValues = quantities;
    newValues[item.itemId] = getAllWarehousesQuantity(item) > 0 ? 1 : 0;
    setQuantities({ ...quantities, newValues });
  };

  // Quantities to render.
  const renderOptions = (quantity:number) => {
    const items = [];
    for(var i = 1; i <= quantity; i++) {
      items.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
    }
    if (items.length === 0) {
      items.push(<MenuItem key={1} value={1}>{1}</MenuItem>)
    }
    return items;
  };

  const getDiscountInfo = (currentItemQuantityData: ItemDatailSearchVendorData) => {
    if (itemDetail !== undefined) {
      const disObj = getDiscountQty(currentItemQuantityData);
      const hasDiscountInfo = disObj 
        && disObj.quantity !== undefined
        && disObj.discountFinalPrice !== disObj.finalPrice;
      if (!hasDiscountInfo) {
        return null;
      }
      const savings = (disObj?.finalPrice! - disObj?.discountFinalPrice!).toFixed(2);
      return (
        <Fragment>
          <br/>
          <Typography variant="caption" color="textSecondary">{ t('items.with-discount') } </Typography>
          <br/>
          <Typography variant="caption" color="textSecondary">{ t('items.savings') } </Typography>
          <Typography variant="caption" color="secondary">{getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={Number(savings)}/>}</Typography>
        </Fragment>
      );
    }
  };

  const getDiscountQty = (currentItemQuantityData: ItemDatailSearchVendorData) => {
    const dis = currentItemQuantityData.itemQuantityData.find(
      (wh: any) => wh.typeOfWarehouse === 'Discount' && wh.quantity > 0
    );
    return dis !== undefined ? dis : null;
  };

  const getNormalQty = (currentItemQuantityData: ItemDatailSearchVendorData) => {
    const dis = currentItemQuantityData.itemQuantityData.find((wh: any) => wh.typeOfWarehouse === 'Normal');
    return dis !== undefined ? dis : null;
  };

  const getPriceInfo = (item:any) => {
    if (itemDetail !== undefined) {
      const disObj = getDiscountQty(item);
      if (
        disObj?.quantity !== undefined
        && disObj.discountFinalPrice !== disObj.finalPrice
      ) {
        return (
          <Fragment>
            <Typography color="secondary" className={classes.lineThrough}>
              {getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={disObj?.finalPrice!}/>}
            </Typography>
            <Typography color="primary">
              {getProjectConfig().CURRENCY_SYMBOL}
              {<NumberFormat number={disObj?.discountFinalPrice!}/>}
            </Typography>
          </Fragment>
        );
      }
    }

    const qtyObj = getNormalQty(item);
    
    return (
      <Typography color="primary">
        {getProjectConfig().CURRENCY_SYMBOL}
        {<NumberFormat number={qtyObj?.finalPrice!} />}
      </Typography>
    );
  };

  const renderItemMedia = () => {
    if (itemDetail?.images[0] !== undefined && itemDetail?.images[0] !== null) {
      if (itemDetail.images.length > 1) {
        return <Carousel
                showArrows={ true }
                interval={ 4500 }
                transitionTime={ 700 }
                autoPlay={ true }
                showThumbs={ false }
                showIndicators={ true }
                emulateTouch={ true }
                infiniteLoop={ true }
                showStatus={ false }
                renderArrowPrev={ (onClickHandler, hasPrev, label) => hasPrev && (<RightArrow dark={true} onClickHandler={onClickHandler}/>) }
                renderArrowNext={ (onClickHandler, hasNext, label) => hasNext && (<LeftArrow  dark={true} onClickHandler={onClickHandler}/>) }
              >
                {itemDetail.images?.map((img: any, i: number) => <div key={i}><img src={img.imageURL} alt="Product media" loading="lazy"/></div> ) }
              </Carousel>;
      } else {
        return <img src={itemDetail.images[0].imageURL} alt="Product media" loading="lazy"/>;
      }
    }
    return <img  src={defaultImage} alt="Product media" loading="lazy"/>;
  };

  return (
    <Fragment>
      {(itemDetail !== undefined) && 
        <div className={classes.root}>
          <div className={classes.cardTop}>
            <div className={classes.cardMedia}>
              <div className={classes.image}>
                {renderItemMedia()}
              </div>
              <div className={classes.wishlistWrapper}>
                <AddToWishList item={itemDetail}/>
              </div>
              
            </div>
            <div className={classes.cardDescription}>
              <div className='full-col'>
                <Typography gutterBottom variant="h5" component="h1" color="primary">
                  <strong>{itemDetail.name}</strong>
                </Typography>
              </div>
              <div className='left-col'>
                <Typography gutterBottom variant="caption" color="textSecondary">
                  <strong>{t('items.item-descrip')}</strong>
                </Typography>
                <Typography
                  dangerouslySetInnerHTML={{ __html: itemDetail.notes }}
                  variant="body2" color="textSecondary"
                  component="p" className={classes.marginBottom}>
                </Typography>
              </div>
              {(itemDetail.labels.length > 0) &&
                <div className='right-col'>
                  <Typography gutterBottom variant="caption" color="textSecondary">
                    <strong>{t('items.item-labels')}</strong>
                  </Typography>
                  <div className={classes.itemLabels}>
                    {
                      itemDetail.labels?.map((label, pos) =>
                        <Fragment key={`wrapper-label-${pos}`}>
                          {label.labelValues?.map((labelValue, index) =>
                            <Fragment key={`label-${index}`}>
                              <div className='label-row'>
                                <div className='label-key'>{label.name}</div>
                                <div className='label-value'>{labelValue.name}</div>
                              </div>
                            </Fragment>
                          )}
                        </Fragment>
                      )
                    }
                  </div>
                </div>
              }
            </div>
          </div>
          <Paper className={classes.vendorWrapper}>
            <Table size="small" aria-label="a dense table" className={classes.tableWrapper}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('items.seller')}</TableCell>
                  <TableCell>{t('items.price')}</TableCell>
                  <TableCell>{t('items.quantity')}</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  (itemDetail.itemSearchVendorData !== undefined) &&
                  (itemDetail.itemSearchVendorData.map((item: any, index: number) => {
                    const allWarehousesQuantity = getAllWarehousesQuantity(item);
                    if (allWarehousesQuantity <= 0) {
                      return null;
                    }
                    return <TableRow key={`item-${index}`}>
                      <TableCell component="th" scope="row">
                        <div>
                          {item.name}
                          {allWarehousesQuantity === 0 ? t('items.out-of-stock') : ''}
                          {getDiscountInfo(item)}
                        </div>
                      </TableCell>
                      <TableCell className={classes.priceCol}>
                        {getPriceInfo(item)}
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth>
                          <Select
                            id={`${item.id}`}
                            className={classes.inputQty}
                            value={quantities[item.itemId] !== undefined ? quantities[item.itemId] : 1}
                            label={t('items.quantity')}
                            onChange={(e) => handleQuantityChange(item.itemId, e.target.value)}
                            disabled={allWarehousesQuantity < 1}
                          >
                            {renderOptions(allWarehousesQuantity)}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton color="primary" aria-label="Add to cart" component="span"
                          onClick={() => addToCart(item)}
                          disabled={getAllWarehousesQuantity(item) === 0 || cartLoading !== ''}
                        >
                          <AddShoppingCartIcon color={allWarehousesQuantity > 0 ? 'secondary' : 'disabled'} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  }))
                }
                {(countAvailable <= 0 && itemDetail !== undefined) &&
                  <div className={classes.noStockMsg}>
                    {t('items.no-available')}
                  </div>
                }
              </TableBody>
            </Table>
          </Paper>
        </div>
      }
    </Fragment>
  );
}

export default ItemDetail;
