// React.
import React, { useState, useEffect } from "react";

// Material UI
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getAllExtraVariables, updateExtraVariable } from "../../../actions/settingsActions/SettingsActions";
import { RootStore } from "../../../Store";

// Styles.
import useStyles from './AppVariablesSettingsStyles';

// Translations.
import { useTranslation } from 'react-i18next';

export type VariableType = {
  id: number
  name: string
  value: string
}

const AppVariablesSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation('global');

  // Redux state.
  const settingsState = useSelector((state: RootStore) => state.settings);
  const { loading, variables } = settingsState;

  // Local state.
  const [currentValues, setCurrentValues] = useState<VariableType[]>([]);

  useEffect(() => {
    // Get all extra variables.
    dispatch(getAllExtraVariables());

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (variables !== undefined) {
      setCurrentValues(variables);
    }

    // eslint-disable-next-line
  }, [variables])

  const getCurrentValue = (id: number) => {
    const variable = currentValues.filter(item => item.id === id);
    if (variable.length > 0) return variable[0].value;
    return '';
  };

  const getIndex = (arrayList: Array<any>, id: number) => {
    return arrayList.findIndex(obj => obj.id === id);
  }

  const handleValueChange = (varId: number, event: React.ChangeEvent<{ name: string; value: string; }>) => {
    const copyValuesArray = [...currentValues];
    let pos = getIndex(copyValuesArray, varId);
    const newValue = event.target.value;
    copyValuesArray[pos] = {
      ...copyValuesArray[pos],
      value: (Number(newValue) >= 0) ? newValue : '0'
    };
    setCurrentValues(copyValuesArray);
  };

  const handleSwitchValueChange = (varId: number, event: React.ChangeEvent<{ checked: boolean; }>) => {
    const copyValuesArray = [...currentValues];
    let pos = getIndex(copyValuesArray, varId);
    const newValue = String(event.target.checked);
    copyValuesArray[pos] = {
      ...copyValuesArray[pos],
      value: newValue
    };
    setCurrentValues(copyValuesArray);
  }

  const hasError = (id:number) => {
    const value = getCurrentValue(id);
    if (value === '' || !value) return true;
    return false;
  };

  const isThereErrors = () => {
    const errors = currentValues.filter(obj => (obj.value === ''));
    if (errors.length > 0) return true;
    return false;
  }

  const getVariableName = (key:string) => {
    switch (key) {
      case 'Currency Id':
        return t('settings.currency-id');
      case 'Current dolar':
        return t('settings.current-dolar');
      case 'Commision':
        return t('settings.commision');
      default:
        return key
    }
  };

  const saveVariables = () => {
    currentValues.forEach((variable) => {
      if (variable.id !== 1) {
        dispatch(updateExtraVariable(variable.id, variable.value));
      }
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h4" color="primary" className={classes.marginBottom}>
          {t('settings.variables-title')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <div>
          {variables?.map((variable, index) => (
            <div key={index} className={classes.formRow}>
              <Typography className={classes.fieldLabel}>
                {getVariableName(variable.name)}
              </Typography>
              {variable.id === 5 ?
                <FormControlLabel
                  control={
                    <Switch
                      name={variable.name}
                      checked={getCurrentValue(variable.id) === 'true'}
                      onChange={(e) => handleSwitchValueChange(variable.id, e)}
                    />
                  }
                  label=''
                />
              :
                <TextField
                  name={variable.name}
                  label={t('settings.value')}
                  value={getCurrentValue(variable.id)}
                  type="number"
                  disabled={variable.id === 1}
                  onChange={(e) => handleValueChange(variable.id, e)}
                  error={hasError(variable.id)}
                  helperText={hasError(variable.id) ? t('users.field-required') : ''}
                  onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                />
            }
            </div>
          ))}
          <Button
            variant="contained"
            color="primary"
            startIcon={
              (loading !== '' && loading !== 'LIST') ? <CircularProgress color="inherit" className={classes.loadingIcon} /> : <SaveIcon />
            }
            disabled={loading !== '' || isThereErrors()}
            onClick={ saveVariables }
          >
            {t('settings.save')}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};


export default AppVariablesSettings;
