// React.
import React, { Fragment, useEffect } from 'react';

// Components.
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import ActionAlert from '../../admin/utils/ActionAlert';

// Redux
import { useSelector, useDispatch } from "react-redux";
import {RootStore} from "../../../Store";
import { addItemToCart, addBundleToCart } from "../../../actions/cartActions/CartActions";

// Material UI.
import CssBaseline from '@material-ui/core/CssBaseline';

// Styles.
import useStyles from './BasePageStyles';

// Types interfase.
interface AdminProps {
  content?: React.ReactNode;
};


const BasePage = ({ content } : AdminProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const reduxState = useSelector((state: RootStore) => state.auth);
  const loggedUser = reduxState.userSummary;

  const updateCart = () => {
    if (localStorage.anonymousCart !== undefined) {
      // Save the items on the BE.
      const localCartItems = JSON.parse(localStorage.anonymousCart);
      localCartItems.forEach((cartItem:any) => {
        dispatch(addItemToCart(cartItem.item.itemId, cartItem.quantity, cartItem.customReference));
      });
      // Clear the local storage.
      localStorage.removeItem('anonymousCart');
    }
    if (localStorage.anonymousBundleCart !== undefined) {
      // Save the items on the BE.
      const localCartBundles = JSON.parse(localStorage.anonymousBundleCart);
      localCartBundles.forEach((cartItem:any) => {
        dispatch(addBundleToCart(cartItem.bundle.id, cartItem.quantity));
      });
      // Clear the local storage.
      localStorage.removeItem('anonymousBundleCart');
    }
  }

  useEffect(() => {
    if (loggedUser !== undefined && (localStorage.anonymousCart !== undefined || localStorage.anonymousBundleCart !== undefined)) {
      // Check if there is items on local storege cart.
      updateCart();
    }
    // eslint-disable-next-line
  }, [loggedUser])

  return (
    <Fragment>
      <Header />
      <CssBaseline />
      <ActionAlert isUserPages={true} />
      <div className={classes.root}>
        { content }
      </div>
      <Footer/>
    </Fragment>
  )
}

export default BasePage;
