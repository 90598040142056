// React.
import React, {Fragment} from 'react';


// Material UI.
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './NotFoundStyles';

const AccessDenied = () => {
  const [t] = useTranslation('global');
  const classes = useStyles();

  return (
    <Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <Paper elevation={3} square={false} variant="elevation" className={classes.errorWrapper}>
          <Typography variant="h3" color="primary">{ t('errors.error-403') }</Typography>
          <Typography variant="h4" color="primary">{ t('errors.not-authorized') }</Typography>
        </Paper>
      </Container>
    </Fragment>
  )
}

export default AccessDenied;
