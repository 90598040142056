/**
 *****************************************
 *********** Dispatch Types **************
 *****************************************
 */
 export const DELIVERY_LOADING = "DELVIERY_LOADING";
 export const DELIVERY_FAIL = "DELIVERY_FAIL";
 export const DELIVERY_LIST = "DELIVERY_LIST";
 export const DELIVERY_SET_FILTER = "DELIVERY_SET_FILTER";
 export const DELIVERY_REFRESH = "DELIVERY_REFRESH";
 export const UPDATE_DELIVERY = "UPDATE_DELIVERY";

export interface DeliveryResponse {
  data: DeliveryDetails
  message: string
  status: string
  status_code: number
}

export interface DeliveryDetails {
  deliverCode: string;
  id: string
  packageCode: string
  clientUserReceiverFullName: string
  clientUserReceiverEmail: string
  clientUserSenderFullName: string
  clientUserSenderEmail: string
  status: string
  price: number
  description: string
  locationOriginName: string
  locationDeliveryName: string
  customAddress: string
  weight: number
  dimensions: string
  creationDate: string
  pickupId: string
  refundStatus: string
  refundCode: string
}

export interface DeliveryList {
    deliveries: DeliveryDetails[]
    page: number,
    size: number
    totalElements: number
    first: boolean
    numberOfElements: number
    totalPages: number
    hasContent: boolean
    last: boolean
    pageable: any
    empty: boolean
}
/**
 *****************************************
 *********** Interfaces Types ************
 *****************************************
 */

export interface DeliveryLoading {
  type: typeof DELIVERY_LOADING
  payload: string
}

export interface DeliveryFail {
  type: typeof DELIVERY_FAIL
  payload: string
}
export interface DeliveryListType {
  type: typeof DELIVERY_LIST
  payload: DeliveryList
}

export interface DeliveryUpdate {
  type: typeof UPDATE_DELIVERY
  payload: string

}

export interface DeliverySetFilter {
    type: typeof DELIVERY_SET_FILTER,
    payload: object
  };

  export interface DeliveryRefresh {
    type: typeof DELIVERY_REFRESH,
    payload: boolean
  };
// Export types.
export type DeliveryDispatchTypes = DeliveryLoading | DeliveryFail | DeliveryListType | DeliverySetFilter | DeliveryRefresh | DeliveryUpdate ;
