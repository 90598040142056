import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100px',
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      padding:  theme.spacing(3, 0),
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        padding: '10px 12px'
      },
      '& .MuiContainer-root': {
        alignItems: 'center'
      },
      '& a': {
        color: theme.palette.primary.contrastText
      },
      '& .support-link': {
        fontFamily: 'VisbyCF-Bold',
        marginBottom: '30px',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
          position: 'fixed',
          bottom: '-19px',
          right: '22px',
          '& span': {
            position: 'relative',
            padding: '5px 8px',
            borderRadius: '8px',
            backgroundColor: theme.palette.primary.main,
            boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
          },
          '& span:after': {
            content: '""',
            width: 0,
            height: 0,
            position: 'absolute',
            top: '6px',
            right: '-16px',
            border: '10px solid',
            borderTopColor: 'transparent',
            borderLeftColor: theme.palette.primary.main,
            borderRightColor: 'transparent',
            borderBottomColor: 'transparent'
          }
        },
        '& img': {
          marginLeft: '6px',
          marginTop: '6px',
          width: '35px'
        }
      },
      '& .social-links': {
        display: 'flex',
        '& a, & span': {
          width: '40px',
          marginRight: '10px',
          fontSize: '12px'
        },
        '& img': {
          width: '100%'
        }
      },
      '& .verified-by': {
        display: 'flex',
        alignItems: 'center',
        '& span': {
          width: '100%'
        },
        '& .logo-img': {
          width: '70px',
          marginRight: '10px'
        },
        '& img': {
          width: '100%'
        }
      },
      '& .align-top': {
        alignSelf: 'self-start'
      }
    },
  }),
);