import {
  Vendor,
  VendorListResponse,
  VendorDispatchTypes,
  VENDOR_FAIL,
  VENDOR_LIST,
  VENDOR_LIST_USER,
  VENDOR_PUT,
  ALL_VENDORS,
  VENDOR_LOADING,
  ADD_VENDOR,
  VENDOR_REFRESH,
  VENDOR_SET_FILTER,
  VENDORS_BY_SELLER,
  AddVendorObject,
  VendorBySellerResponse
} from "../actions/vendorActions/VendorActionsTypes";

// Default state interface.
interface DefaultStateI {
  loading: string,
  vendors: Vendor[],
  userVendors?: Vendor[],
  vendorList?: VendorListResponse,
  vendor?: AddVendorObject,
  refresh?: boolean,
  vendorFilters?: object,
  vendorsBySeller?: VendorBySellerResponse[],
  actionStatus?: any
}

const defaultState: DefaultStateI = {
  loading: '',
  vendors: [],
};

const vendorReducer = (state: DefaultStateI = defaultState, action: VendorDispatchTypes) : DefaultStateI => {
  switch (action.type) {
    case VENDOR_FAIL:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'error'}
      }
    case VENDOR_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case ADD_VENDOR:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'success'},
        vendor: action.vendor
      }
    case VENDOR_PUT:
      return {
        ...state,
        loading: '',
        vendor: action.payload.vendor,
        actionStatus: {menssage: action.payload.message, status: 'success'}
      }
    case VENDOR_LIST:
      return {
        ...state,
        loading: '',
        vendors: action.payload
      }
    case VENDORS_BY_SELLER:
      return {
        ...state,
        loading: '',
        vendorsBySeller: action.payload
      }
    case VENDOR_LIST_USER:
      return {
        ...state,
        loading: '',
        userVendors: action.payload
      }
    case ALL_VENDORS:
      return {
        ...state,
        loading: '',
        vendorList: action.payload
      }
    case VENDOR_REFRESH:
      return {
        ...state,
        refresh: action.payload
      }
    case VENDOR_SET_FILTER:
      return {
        ...state,
        vendorFilters: action.payload
      }
    default:
      return state
  }
};

export default vendorReducer;
