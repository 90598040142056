//Material UI
import {
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

//Translations
import { useTranslation } from "react-i18next";

//Redux
import {  useSelector } from "react-redux";
import { RootStore } from "../../../Store";

//Types
import { PrizeCategory } from "../../../actions/prizeActions/PrizeActionsTypes";

interface addLabelModalProps {
  closeModal: () => void;
  createPrizeLabel: any;
  handleCreatePrizeLabelChange: (event: React.ChangeEvent<{ value: any }>) => void;
  category: any;
  isPromo: any;
  handleIsPromo: (event: React.ChangeEvent<{ value: any }>) => void;
  modDePremio: number;
  handleModDePremio: (event: React.ChangeEvent<{ value: any }>) => void;
  handleCreatePrize: () => void;
}

const AddPrizeModal: React.FC<addLabelModalProps> = ({
  closeModal,
  createPrizeLabel,
  handleCreatePrizeLabelChange,
  category,
  isPromo,
  handleIsPromo,
  modDePremio,
  handleModDePremio,
  handleCreatePrize
}) => {
  const [t] = useTranslation("global");

  //Redux State
  const reduxState = useSelector((state: RootStore) => state);
  const prizesState = useSelector((state: RootStore) => state.prize);
  const loading = prizesState.loading;

  return(
    <div>
      <InputLabel style={{marginBottom:'20px'}}>
        <Typography variant="h6" color="primary">{t("prizes.create-prize")}:</Typography>
        </InputLabel>
        <Typography>{t('prizes.label-to-use')}</Typography>
        <Select
          labelId="category-select-label"
          id="category-select"
          value={createPrizeLabel}
          onChange={handleCreatePrizeLabelChange}
        >
            {
              reduxState.prize.prizesCategories?.map((eachCategory: PrizeCategory) => {
                if (eachCategory.itemTypeName === category) {
                  return eachCategory.values.map((value: any) => {
                    return (
                      <MenuItem  value={
                        JSON.stringify({
                        name: value.value,
                        labelId: value.id
                        })}
                      >{value.value}</MenuItem>
                    );
                  });
                }
                return null; // Add a default return value if the condition is not met
              })
            }
        </Select>
        <Typography style={{marginTop:"20px"}}>isPromo</Typography>
        <Select
          labelId="category-select-label"
          id="category-select"
          value={isPromo}
          onChange={handleIsPromo}
        >
          <MenuItem value="si">Si</MenuItem>
          <MenuItem value="no">No</MenuItem>   
        </Select>
        <Typography style={{marginTop:"20px"}}>Mod de Premio</Typography>
        <TextField
          value={modDePremio}
          onChange={handleModDePremio}
          type="number"
        inputProps={{
          step: 0.01,
          min: 0,
          max: 1
        }}  
        ></TextField>
      <div style={{display:'flex', gap:'10px',marginTop:'20px'}}>
      <Button variant="contained" color="default" onClick={handleCreatePrize}>
        {loading? 
        <CircularProgress/>:
          `${t('prizes.create')}`
        }
      </Button>
      <Button variant="contained" color="default" onClick={closeModal}>{t('prizes.cancel')}</Button>
      </div>
    </div>
  )
};

export default AddPrizeModal;
