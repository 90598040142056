// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& .MuiTextField-root': {
        width: '40px'
      },
      '& .MuiTableCell-head': {
        fontWeight: 'bold'
      },
      '& .MuiTableCell-root': {
        [theme.breakpoints.down('xs')]: {
          padding: '6px 0px 6px 12px'
        },
      },
      '& .carousel-slider': {
        '& svg': {
          position: 'absolute',
          zIndex: 2,
          backgroundColor: 'transparent !important',
          opacity: '1 !important',
          top: 'calc(50% - 28px) !important',
          [theme.breakpoints.down('xs')]: {
            top: 'calc(30%) !important',
          },
        },
      },
      '& .left-arrow': {
        right: '20px',
        '& :hover': {
          cursor: 'pointer'
        },
        [theme.breakpoints.down('xs')]: {
          right: '10px',
        },
      },
      '& .right-arrow': {
        left: '20px',
        '& :hover': {
          cursor: 'pointer'
        },
        [theme.breakpoints.down('xs')]: {
          left: '10px',
        },
      }
    },
    tableWrapper: {
      borderCollapse: 'separate',
      '& .MuiTableHead-root .MuiTableCell-root': {
        backgroundColor: theme.palette.grey[600],
        color: 'white',
        lineHeight: '1rem'
      },
      '& .MuiTableHead-root .MuiTableCell-root:first-child': {
        borderTopLeftRadius: '6px',
        borderBottomLeftRadius: '6px'
      },
      '& .MuiTableHead-root .MuiTableCell-root:last-child': {
        borderTopRightRadius: '6px',
        borderBottomRightRadius: '6px'
      },
      '& .MuiTableCell-root': {
        textAlign: 'center'
      },
    },
    priceCol: {
      minWidth: '105px',
      '& .MuiTypography-root': {
        display: 'inline-block',
        marginRight: theme.spacing(1)
      }
    },
    itemLabels: {
      width: '100%',
      textAlign: 'center',
      '& .label-row': {
        marginBottom: theme.spacing(1),
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: '6px'
      },
      '& .label-key': {
        padding: '0 5px',
        fontFamily: 'VisbyCF-Bold',
        backgroundColor: theme.palette.grey[600],
        color: 'white',
        borderRadius: '6px'
      },
      '& .label-value': {
        padding: '0 5px',
      }
    },
    cardTop: {
      minHeight: '100px',
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
      },
    },
    cardMedia: {
      width: '45%',
      display: 'block',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      '& img': {
        width: '100%',
        height: 'auto',
        maxHeight: '400px',
        objectFit: 'contain',
        borderRadius: '4px',
        [theme.breakpoints.down('sm')]: {
          maxHeight: '250px',
        },
        [theme.breakpoints.down('xs')]: {
          maxHeight: '290px',
        },
      }
    },
    cardDescription: {
      width: '55%',
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      '& .full-col': {
        width: '100%',
        float: 'left',
        marginBottom: theme.spacing(2),
      },
      '& .left-col': {
        width: '60%',
        paddingRight: theme.spacing(1),
        float: 'left',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
      '& .right-col': {
        width: '40%',
        float: 'left',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      }
    },
    lineThrough: {
      textDecoration: 'line-through'
    },
    marginBottom: {
      marginBottom: theme.spacing(4)
    },
    noStockMsg: {
      margin: theme.spacing(2, 0) 
    },
    vendorWrapper: {
      padding: '10px 32px !important',
      [theme.breakpoints.down('xs')]: {
        padding: '10px 5px !important',
      },
    },
    inputQty: {
      border: `1px solid ${theme.palette.grey[300]}`,
      padding: '0 3px',
      width: '58px',
      margin: '0 auto',
      [theme.breakpoints.down('xs')]: {
        width: '45px',
      },
      '& .MuiSelect-icon': {
        right: '-3px'
      },
      '& .MuiSelect-select': {
        paddingRight: '8px'
      },
      '&.MuiInput-underline:before':{
        display: 'none'
      }
    },
    image:{
      width: '100%',
    },
    wishlistWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

export default useStyles;
