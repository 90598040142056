import ReactGA from "react-ga";

/**
 * @returns void
 * 
 * Implement like other hooks:
 * const gaEventTracker = useAnalyticsEventTracker('<NAME>');
 * 
 * then use it as any other custom hook:
 * gaEventTracker('<NAME>');
 */

const useAnalyticsEventTracker = (
  category: string
) => {
  const eventTracker = (action = "test action", label = "test label") => {
    ReactGA.event({category, action, label});
  }
  return eventTracker;
}

export default useAnalyticsEventTracker;