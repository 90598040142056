// React.
import React, { useState, useEffect } from 'react';

// Components.
import TextMaskPhone from '../utils/TextMaskPhone';

// Material UI.
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { MenuItem, Select, Typography } from '@material-ui/core';

// Redux
import {useDispatch, useSelector} from "react-redux";
import {addUser, getUsers } from "../../../actions/userActions/UserActions";
import { getVendors } from '../../../actions/vendorActions/VendorActions';
import {RootStore} from "../../../Store";

// Global Types.
import { UserType } from '../models/ModelTypes';

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './AddUserFormStyles';


const AddUserForm = () => {
  const [t] = useTranslation('global');
  const classes = useStyles();

  // Default stage object.
  const defaulUser:UserType = { 
    id: 0, 
    name: '', 
    lastName: '', 
    email: '', 
    phone: '', 
    role: '',
    profileImageUrl: '/imgs/default-user.svg',
    sellerVendorId: '',
    imgFile: null 
  };

  // Redux state.
  const dispatch = useDispatch();
  const reduxState = useSelector((state: RootStore) => state.user);
  const reduxVendors = useSelector((state: RootStore) => state.vendors);
  const { vendors } = reduxVendors;
  const loading = reduxState.loading;
  const existingUser = reduxState.userList;

  useEffect(()=>{
    dispatch(getVendors());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  // Local component state.
  const [userState, setUserState] = useState<UserType>(defaulUser);
  const [errorArray, setErrorArray] = useState<Array<string>>([]);
  const [saveTriggered, setSaveTriggered] = useState<boolean>(false);

  useEffect(()=>{
    if (loading === '' && saveTriggered) {
      setSaveTriggered(false);
      saveOnClick();
    }
  // eslint-disable-next-line
  },[loading, saveTriggered])

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof userState;
    var inputValue = String(event.target.value);

    if (name === 'name' || name === 'lastName') {
      inputValue = inputValue.replace(/[\d,-,!,@,#,$,%,^,&,*,(,),-,_,=,+,:,;,",',?,{,}]/g, '');
    }
    setUserState({
      ...userState,
      [name]: inputValue,
    });

    // Validate and add errors.
    const errors = [...errorArray];
    const nameString = '' + [name]
    if (event.target.value === '') {
      errors.push(nameString);
      setErrorArray(errors);
    } else {
      var newErrors = errors.filter(function(value){ 
        return value !== nameString;
      });
      if (nameString === 'email') {
        newErrors = newErrors.filter(function(value){ 
          return value !== 'taken-email';
        });
      }
      setErrorArray(newErrors);
    }
  };

  const validateFields = () => {
    var result = true;
    const errors = [...errorArray];
    if (userState.name === '') {
      errors.push('name');
      result = false;
    }
    if (userState.lastName === '') {
      errors.push('lastName');
      result = false;
    }
    if (userState.email === '') {
      errors.push('email');
      result = false;
    } else {
      if (userState.email !== undefined) {
        // eslint-disable-next-line
        let emailMask  = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/); 
        if (!emailMask.test(userState.email)) { 
          errors.push('email');
          result = false;  
        }
      }
      if (existingUser!.users.length > 0) {
        errors.push('taken-email');
        result = false;  
      }
    }
    if (userState.phone === '') {
      errors.push('phone');
      result = false;
    } else {
      if (userState.phone !== undefined) {
        let phoneMask  = new RegExp(/\(?([0-9]{4})\)?([ ]?)([0-9]{4})/);
        if (!phoneMask.test(userState.phone)) {
          errors.push('phone');
          result = false;
        }
      }
    }
    if (userState.lastName === '') {
      errors.push('lastName');
      result = false;
    }
    if (userState.role === '') {
      errors.push('role');
      result = false;
    }
    setErrorArray(errors);

    return result;
  };

  const handleChangeValidation = (inputName: string) => {
    var result = false;
    errorArray.forEach((key) => {
      if (key === inputName) {
        result = true;
      }
    });

    return result;
  };

  const getErrorMessageEmail = () => {
    var message = '';
    if (handleChangeValidation('email')) {
      message = t('users.field-required-email');
    }
    if ( handleChangeValidation('taken-email')) {
      message = t('users.email-taken');
    }
    return message;
  };

  const validateEmailOnDB = () => {
      // See if the email is already used.
      if (userState.email !== '') {
        const qParams = {
          email: userState.email
        };
        dispatch(getUsers(qParams));
      }
  };

  const saveOnClick = () => {
    // Save the user on the BE.
    if (loading === '' ) {
      const validation = validateFields();
      if (errorArray.length === 0 && validation) {
        dispatch(addUser(userState));
      }
    } else {
      setSaveTriggered(true);
    }
  };

  // Clear fields when success.
  useEffect(() => {
    if (reduxState.actionStatus !== undefined) {
      if (reduxState.actionStatus.status === 'success') {
        setUserState(defaulUser);
      }
    }

    // eslint-disable-next-line
  }, [reduxState]);

  const handleClear = () => {
    setUserState(defaulUser);
  };

  return (
    <form noValidate autoComplete="off" className={`add-form`}>
      <CssBaseline />
      <div className="left-side">
        <TextField 
          id="user-email" 
          name="email"
          type="email"
          value={ userState.email } 
          label={ t('addUserForm.email-placeholder') }
          color="primary" 
          fullWidth
          autoFocus
          onChange={ handleChange }
          onBlur={ validateEmailOnDB }
          error={ handleChangeValidation('email') || handleChangeValidation('taken-email') }
          helperText={ getErrorMessageEmail() }
          className={ classes.fieldInput }
        />
        <TextField 
          id="user-name" 
          name="name"
          value={ userState.name } 
          label={ t('addUserForm.user-name') }  
          color="primary" 
          fullWidth 
          onChange={ handleChange } 
          error={ handleChangeValidation('name') }
          helperText={handleChangeValidation('name') ? t('users.field-required'): ''}
          className={ classes.fieldInput }
        />
        <TextField 
          id="user-lastname" 
          name="lastName"
          value={ userState.lastName } 
          label={ t('addUserForm.user-lastname') }  
          color="primary" 
          fullWidth 
          onChange={ handleChange }
          error={handleChangeValidation('lastName')}
          helperText={handleChangeValidation('lastName') ? t('users.field-required') : ''}
          className={ classes.fieldInput }
        />
        <FormControl className={ classes.formControl }>
          <InputLabel htmlFor="formatted-text-mask-input">{ t('addUserForm.user-phone') }</InputLabel>
          <Input
            name="phone"
            value={ userState.phone } 
            onChange={ handleChange }
            id="formatted-text-mask-input"
            inputComponent={TextMaskPhone as any}
            color="primary" 
            fullWidth
            error={ handleChangeValidation('phone') }
            className={ classes.fieldInput }
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <Typography variant="caption">{ t('users.role') }</Typography>
          <Select
            id="u-role"
            name="role"
            value={ userState.role }
            onChange={ handleChange }
            disabled={false}
            className={classes.fieldInput}
            error={ handleChangeValidation('role') }
          >
            <MenuItem value="ROLE_USER" >{ t('users.user') }</MenuItem>
            <MenuItem value="ROLE_ADMIN">{ t('users.admin') }</MenuItem>
            <MenuItem value="ROLE_SELLER">{ t('users.seller') }</MenuItem>
            <MenuItem value="ROLE_SUPER_SELLER">{ t('users.super-seller') }</MenuItem>
            <MenuItem value="ROLE_SELLER_ASSOCIATE">{ t('users.seller-associate') }</MenuItem>
            <MenuItem value="ROLE_ADMIN_SINGLES">{t('users.admin-singles')}</MenuItem>
          </Select>
        </FormControl>

        {(userState.role === "ROLE_SELLER" || userState.role === "ROLE_SELLER_ASSOCIATE") 
          && vendors !== undefined 
          && <FormControl className={classes.formControl}>
          <Typography variant="caption">Vendor</Typography>
          <Select
            id={`u-${userState.sellerVendorId}`}
            name="sellerVendorId"
            value={ userState.sellerVendorId }
            onChange={ handleChange }
            disabled={false}
            hidden={true}
            className={classes.fieldInput}
          >
            {vendors.map((vendor, index) =>  
              ( userState.role === 'ROLE_SELLER_ASSOCIATE' 
                ?
                (vendor.type === 'Asociado' && <MenuItem id={`u-${vendor.id}`}value={vendor.id}>{vendor.name}</MenuItem>)
                :
                <MenuItem id={`u-${vendor.id}`} value={vendor.id}>{vendor.name}</MenuItem>
              )
            )}
          </Select>
        </FormControl> }

      </div>
      <div className="right-side">
        
      </div>
      <div className="form-controls ">
        { loading === 'ADD_USER' && <CircularProgress /> }
        <Button variant="outlined" color="secondary" onClick={ handleClear }> { t('users.clear') } </Button>
        <Button variant="contained" color="primary" onClick={ saveOnClick } disabled={ errorArray.length !== 0 }> { t('users.save') } </Button>
      </div>
    </form>
  )
}

export default AddUserForm;
