import { reportData } from "../prizeActions/PrizeActionsTypes";

/**
 *****************************************
 *********** Dispatch Types **************
 *****************************************
 */
export const EVENTS_LOADING = "EVENTS_LOADING";
export const EVENTS_FAIL = "EVENTS_FAIL";
export const EVENTS_LIST = "EVENTS_LIST";
export const EVENTS_REGISTER = "EVENTS_REGISTER";
export const EVENT_DETAIL = "EVENT_DETAIL";
export const EVENT_PUT = "EVENT_PUT";
export const EVENT_PLAYERS_LIST = "EVENT_PLAYERS_LIST";
export const EVENT_REGISTER_PLAYERS = "EVENT_REGISTER_PLAYERS";
export const EVENT_UPDATE_PLAYERS = "EVENT_UPDATE_PLAYERS";
export const EVENTS_UPDATEFILTER = "EVENTS_UPDATEFILTER";
export const EVENTS_UPDATEPAGINATION = "EVENTS_UPDATEPAGINATION";
export const EVENT_REGISTER_ANONYMOUS_PLAYERS = "EVENT_REGISTER_ANONYMOUS_PLAYERS";
export const EVENT_CURRENT_ORDER_PLAYER= "EVENT_CURRENT_ORDER_PLAYER";
export const EVENT_GET_PLAYER_ORDER="EVENT_GET_PLAYER_ORDER";
export const EVENT_UPDATE_ORDER_ITEMS="EVENT_UPDATE_ORDER_ITEMS";

/**
 *****************************************
 ************* Object Types **************
 *****************************************
 */
export type EventType = {
	id: number;
	taxPercentage: number;
	name: string;
	image_url: string;
	description: string;
	isDeleted: boolean;
};

export type EventList = {
	first: boolean;
	last: boolean;
	number: number;
	size: number;
	totalElements: number;
	items: EventType[];
};

export type registerEvent = {
	entryFee: number;
	itemTypeId: number;
	name: string;
	vendorId: number;
};

export type EventRegisterOrderItem={
	eventPlayerId:number,
	itemId:number,
	quantity:number
}

export type EventPlayerOrder={
	id:number,
	name:string,
	priceWithTaxes:number,
	image:{},
	quantity:number,
	totalPrice:number,
	isParticipation:boolean
};

export type EventOrder={
	itemsOrder:[EventPlayerOrder],
	itemsParticipationOrder:[EventPlayerOrder],
	totalPriceItems:number
}

export type EventUpdateCurrentOrderPlayer={
	id:number,
	name:string
}

export type EventDatailResponse = {
	id: number;
	name: string;
	itemTypeName: string;
	entryFee: number;
	status: string;
	sellerFullName: string;
	code: string;
	totalPlayers: number;
	totalFees: number;
	notes: string | null;
	vendorId:number;
	participationFee:number,
	havePrizeParticipation:boolean,
	itemId:number,
	itemName:string,
	reportData:reportData,
	vendorName:string,
};

export type EventInformationType = {
	code: string;
	finished: boolean;
	name: string;
	notes: string | null;
	havePrizeParticipation: boolean;
	itemName:string;
};

export type EventPutResponse = {
	message: string;
	event: EventInformationType;
};

export type PlayerType = {
	id: number;
	email?: string;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	anonymous?: true;
	position?: number;
	notes?: string;
};

export type EventPlayerList = {
	players: PlayerType[];
};

export type EventUpdatePlayerResponse = {
	message: string;
	player: PlayerType;
};

export type ErrorResponse = {
	message: string;
	description: String;
};

export type EventRegisterAnonymousPlayerResponse = {
	message: string;
	data: String;
};

/**
 *****************************************
 *********** Interfaces Types ************
 *****************************************
 */

export interface EventLoading {
	type: typeof EVENTS_LOADING;
	payload: string;
}

export interface EventListType {
	type: typeof EVENTS_LIST;
	payload: EventList;
}

export interface EventFail {
	type: typeof EVENTS_FAIL;
	payload: ErrorResponse;
}

export interface EventRegisterType {
	type: typeof EVENTS_REGISTER;
	payload: registerEvent;
}
export interface GetEventDetailData {
	type: typeof EVENT_DETAIL;
	payload: EventDatailResponse;
}

export interface EventUpdate {
	type: typeof EVENT_PUT;
	payload: EventPutResponse;
}

export interface EventPlayersListType {
	type: typeof EVENT_PLAYERS_LIST;
	payload: EventPlayerList;
}
export interface EventRegisterPlayers {
	type: typeof EVENT_REGISTER_PLAYERS;
	payload: string;
}

export interface EventUpdatePlayers {
	type: typeof EVENT_UPDATE_PLAYERS;
	payload: EventUpdatePlayerResponse;
}

export interface EventUpdateFilterType {
	type: typeof EVENTS_UPDATEFILTER;
	payload: {};
}

export interface EventUpdatePaginationType {
	type: typeof EVENTS_UPDATEPAGINATION;
	payload: {};
}

export interface EventRegisterAnonymousPlayer {
	type: typeof EVENT_REGISTER_ANONYMOUS_PLAYERS;
	payload: EventRegisterAnonymousPlayerResponse;
}

export interface EventUpdateCurrentOrderPlayerType{
	type: typeof EVENT_CURRENT_ORDER_PLAYER;
	payload:EventUpdateCurrentOrderPlayer;
}

export interface EventUpdatePlayerOrder{
	type: typeof EVENT_GET_PLAYER_ORDER;
	payload:EventOrder;
}

export interface EventRegisterOrderItemType{
	type: typeof EVENT_UPDATE_ORDER_ITEMS;
	payload: EventRegisterOrderItem
}

export type EventDispatchType =
	| EventLoading
	| EventListType
	| EventFail
	| EventRegisterType
	| EventUpdateFilterType
	| EventUpdatePaginationType
	| GetEventDetailData
	| EventUpdate
	| EventPlayersListType
	| EventRegisterPlayers
	| EventUpdatePlayers
	| EventRegisterAnonymousPlayer
	| EventUpdateCurrentOrderPlayerType
	| EventUpdatePlayerOrder
	| EventRegisterOrderItemType;

