// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& .MuiTextField-root': {
        width: '40px'
      },
      '& .MuiTableCell-head': {
        fontWeight: 'bold'
      },
      '& .MuiTableCell-root': {
        [theme.breakpoints.down('xs')]: {
          padding: '6px 0px 6px 12px'
        },
      },
      '& a': {
        color: theme.palette.primary.main
      },
      '& .item-description': {
        pointerEvents: 'none',
        '& p': {
          marginTop: 0,
          marginBottom: theme.spacing(1),
        },
        '& ul, & ol': {
          marginTop: 0,
          marginBottom: theme.spacing(1),
          paddingLeft: '12px'
        },
      },
      '& .MuiTableCell-sizeSmall': {
        padding: '6px 8px 6px 6px'
      }
    },
    tableWrapper: {
      borderCollapse: 'separate',
      '& .MuiTableHead-root .MuiTableCell-root': {
        backgroundColor: theme.palette.grey[600],
        color: 'white',
        lineHeight: '1rem'
      },
      '& .MuiTableHead-root .MuiTableCell-root:first-child': {
        borderTopLeftRadius: '6px',
        borderBottomLeftRadius: '6px'
      },
      '& .MuiTableHead-root .MuiTableCell-root:last-child': {
        borderTopRightRadius: '6px',
        borderBottomRightRadius: '6px'
      },
      '& .MuiTableCell-root': {
        textAlign: 'center'
      },
    },
    priceCol: {
      minWidth: '105px',
      [theme.breakpoints.down('xs')]: {
        minWidth: '75px',
      },
      '& .MuiTypography-root': {
        display: 'inline-block',
        marginRight: theme.spacing(1)
      }
    },
    cardFeatured: {
      position: 'absolute',
      right: '-10px',
      top: '-10px'
    },
    cardTop: {
      minHeight: '200px',
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        minHeight: '140px',
      },
    },
    cardMedia: {
      width: '30%',
      display: 'block',
      alignSelf: 'center',
      '& img': {
        width: '100%',
        height: 'auto',
        maxHeight: '200px',
        objectFit: 'contain',
        borderRadius: '4px',
        [theme.breakpoints.down('xs')]: {
          maxHeight: '140px',
        },
      }
    },
    cardTitle: {
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 4,
      whiteSpace: 'pre-wrap'
    },
    cardDescription: {
      width: '70%',
      padding: theme.spacing(2, 0, 2, 2),
      [theme.breakpoints.up('md')]: {
        height: '240px'
      },
    },
    lineThrough: {
      textDecoration: 'line-through'
    },
    noStockMsg: {
      margin: theme.spacing(2, 0) 
    },
    inputQty: {
      border: `1px solid ${theme.palette.grey[300]}`,
      padding: '0 3px',
      maxWidth: '58px',
      '& .MuiSelect-icon': {
        right: '-3px'
      },
      '& .MuiSelect-select': {
        paddingRight: '8px'
      },
      '&.MuiInput-underline:before': {
        display: 'none'
      }
    },
    image: {
      width: '100%',
    },
    wishlistWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

export default useStyles;
