// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiButtonBase-root': {
        textAlign: 'center',
        minHeight: '90px'
      },
      '& .MuiPagination-ul .MuiButtonBase-root': {
        minHeight: 'auto'
      },
      '& .MuiCard-root': {
        backgroundColor: '#e43a00b3',
        color: 'white',
        '&:hover': {
          backgroundColor: '#e43a00cc',
        }
      },
      '& .MuiCardContent-root': {
        padding: theme.spacing(1)
      }
    },
    disabledCard: {
      boxShadow: 'none',
      '& .MuiCardActionArea-root.Mui-disabled': {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary.dark,
      },
      '&:hover': {
        opacity: '1 !important'
      }
    },
    currentStatus: {
      '& .MuiCardActionArea-root.Mui-disabled': {
        borderBottom: `2px solid ${theme.palette.primary.main}`
      }
    },
    orderRow: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(2, 0)
    },
    boldText: {
      fontFamily: 'VisbyCF-Bold'
    },
    ctaTitle: {
      fontSize: '18px',
      lineHeight: '20px',
      marginBottom: theme.spacing(1),
      fontFamily: 'VisbyCF-Bold',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
      }
    },
    breakWord: {
      wordBreak: 'break-word',
      lineHeight: '12px',
    },
    modalPaper: {
      padding: theme.spacing(2),
      position: 'absolute',
      width: '600px',
      height: '570px',
      top: 'calc(50% - 300px)',
      left: 'calc(50% - 300px)',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
        width: '90%',
        left: '5%',
        top: '15%',
        height: '440px',
      },
      '& .MuiTypography-root': {
        marginBottom: theme.spacing(2),
      },
      '& .MuiButtonBase-root': {
        marginLeft: theme.spacing(2),
      }
    },
    closeModal: {
      position: 'absolute',
      right: '0',
      top: '0'
    },
    hasOrdersContainer:{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    orderCountCopy:{
      color: theme.palette.text.secondary,
    },
    pickUpIdContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '6px',
    }
  }),
);

export default useStyles;
