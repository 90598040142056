// React.
import React, { useEffect } from "react";

// Others.
import { Container } from "react-smooth-dnd";

// Material UI
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { addFeaturedBox, getFeaturedBox, updateFeaturedBox } from "../../../actions/settingsActions/SettingsActions";
import { getItems } from "../../../actions/itemActions/ItemActions";
import {RootStore} from "../../../Store";

// Styles.
import useStyles from './FeaturedBoxSettingsStyles';
import defaultImage from '../../../images/default-img.jpg'; 

// Translations.
import { useTranslation } from 'react-i18next';

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

const FeaturedBoxSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation('global');

  // Redux state.
  const settingsState = useSelector((state: RootStore) => state.settings);
  const {loading, featuredBoxes, actionStatus} = settingsState;
  const itemState = useSelector((state: RootStore) => state.item);
  const itemList = itemState.itemList;

  // Default values.
  const titlesValueArray = {
    values: ['', '', '', '', '', '', '', '']
  };

  const typingArray = {
    values : [
      ['','','',''],['','','',''],['','','',''],['','','',''],['','','',''],['','','',''],['','','',''],['','','','']
    ]
  };
  const selectValueArray = {
    values: [
      [{},{},{},{}],[{},{},{},{}],[{},{},{},{}],[{},{},{},{}],[{},{},{},{}],[{},{},{},{}],[{},{},{},{}],[{},{},{},{}]
    ]
  };

  const enabledValueArray = {
    values: [
      {enabled: false, new: true},
      {enabled: false, new: true},
      {enabled: false, new: true},
      {enabled: false, new: true},
      {enabled: false, new: true},
      {enabled: false, new: true},
      {enabled: false, new: true},
      {enabled: false, new: true}
    ]
  };

  useEffect(() => {
    dispatch(getFeaturedBox());
    // eslint-disable-next-line
  }, [])

  // Local state.
  const [titleValue, setTitleValue] = React.useState<any>(titlesValueArray);
  const [typingValue, setTypingValue] = React.useState<any>(typingArray);
  const [selectValue, setSelectValue] = React.useState<any>(selectValueArray);
  const [statusValue, setStatusValue] = React.useState<any>(enabledValueArray);
  const [editRowId, setEditRowId] = React.useState<number>(0);
  const [editPosId, setEditPosId] = React.useState<number>(0);
  const [edited, setEdited] = React.useState<boolean>(false);
  const [currentError, setCurrentError] = React.useState<number|undefined>(undefined);

  useEffect(() => {
    // Fill the local state with the be data.
    if (featuredBoxes !== undefined) {
      for (var i = 0; i < featuredBoxes.length; i++) {
        // Set the box titles.
        var titleValueCopy = titleValue.values;
        titleValueCopy[i] = featuredBoxes[i].title;
        setTitleValue({...titleValue, values: titleValueCopy});


        // Set the box status.
        var enabledValueCopy = statusValue.values;
        enabledValueCopy[i].enabled = !featuredBoxes[i].isDisable;
        enabledValueCopy[i].new = false;
        setStatusValue({...statusValue, values: enabledValueCopy});

        // Set the box items.
        for (var j = 0; j < featuredBoxes[i].items.length; j++) {
          if (j < 4) {
            let item = featuredBoxes[i].items[j].items[0];
            var selectValueCopy = selectValue.values;
            if (item !== undefined) {
              let imgUrl = item.images[0] !== undefined ? item.images[0].url : defaultImage;
              selectValueCopy[i][j] = {id: item.id, code: item.code, name: item.name, img: imgUrl};
            } else {
              selectValueCopy[i][j] = {id: '', code: '', name: '', img: defaultImage};
            }
            setSelectValue({...selectValue, values:selectValueCopy});
          }
        }
      }

    }
  
    // eslint-disable-next-line
  }, [featuredBoxes])

  // Refresh data.
  useEffect(() => {
    if (actionStatus !== undefined) {
      if (actionStatus.menssage === 'status.success-create' || actionStatus.menssage === 'status.success-update') {
        dispatch(getFeaturedBox());
      }
    }
    // eslint-disable-next-line
  }, [actionStatus])

  // Get the autocomplete items.
  useEffect(() => {
    let value = typingValue.values[editRowId][editPosId];
    const qParams = { name: value, page: 0, size: getProjectConfig().PAGES_SIZE, paged: true, isUniquePerCode: true };
    const delayDebounceFn = setTimeout(
      () => dispatch(getItems(qParams, 'seller')), 
      500
    );
    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line
  }, [typingValue])

  const defaultOptions = () => {
    var result:Array<any> = [];
    if (itemList !== undefined) {
      itemList!.items.forEach(item => {
        let imgUrl = item!.images![0] !== undefined ? item!.images![0].url : defaultImage;
        result.push({id: item.id, code: item.code, name: item.name, img: imgUrl});
      });
    }
    return result;
  };

  const getItemsInfo = (index:number, type: string) => {
    var result = [];
    for (var i = 0; i < selectValue.values[index].length; i ++) {
      let current = selectValue.values[index][i];
      if (current !== null && current.code !== undefined) {
        if(type === 'create') {
          result.push({itemCode: String(current.code), position: i + 1}); 
        } else {
          result.push({itemCode: String(current.code), newPosition: i + 1}); 
        }
      } else {
        if (checkIfDelete(index, i)) {
          result.push({delete: true, deletePosition: i + 1}); 
        }
      }
    }
    return result;
  };

  const checkIfDelete = (pos1:number, pos2:number) => {
    if (featuredBoxes[pos1] !== undefined) {
      if (featuredBoxes[pos1].items[pos2] !== undefined) {
        return true;
      }
    }
    return false;
  };

  const validateRowTitle = (index:number) => {
    if (titleValue.values[index] === '') {
      setCurrentError(index);
    }
  };

  const saveBoxesSettings = () => {
    for (var i = 0; i < titleValue.values.length; i ++) {
      const items = getItemsInfo(i, 'create');
      if (titleValue.values[i] !=='' && items.length > 0) {
        const title = titleValue.values[i];
        const enabled = !statusValue.values[i].enabled;
        const itemId = featuredBoxes[i] !== undefined ? featuredBoxes[i].id : 0;
        const pos = i + 1;
        if (statusValue.values[i].new) {
          dispatch(addFeaturedBox(items, pos, title));
        } else {
          const items = getItemsInfo(i, 'update');
          dispatch(updateFeaturedBox(itemId, items, pos, title, enabled));
        }
        setEdited(false);
      }
    }
  };

  return (
    <List>
      <Container dragHandleSelector=".drag-handle">
        <Typography variant="h4" color="primary" className={classes.marginBottom}>
          { t('settings.manage-boxes-content') } {loading !== '' && <CircularProgress/>}
        </Typography>
        <Typography color="textSecondary" className={classes.marginBottom}>{ t('settings.maximum-boxes') }</Typography>
        {titlesValueArray.values.map((value:any, index:number) => (
            <ListItem className={classes.rowMain} key={index}>
              <form>
                <div className={classes.leftContent}>
                  <div className={classes.rowTitle}>
                    <TextField 
                      label={ t('settings.box-title') }
                      value={ titleValue.values[index] }
                      error={currentError === index}
                      helperText={currentError === index ? t('settings.box-title-required'): ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        var preValues = titleValue.values;
                        preValues[index] = e.target.value;
                        setTitleValue({...titleValue, values: preValues});
                        setEdited(true);
                        setCurrentError(undefined);
                      }} 
                    />
                  </div>
                  <div className={classes.rowAddItems} onClick={ () => validateRowTitle(index) }>
                    <div className="item-wrapper">
                      <Typography color="primary">1</Typography>
                      <Avatar className={classes.bannerImg} variant="rounded" alt="Item thumbnail" src={ selectValue.values[index][0] !== null ? selectValue.values[index][0].img : defaultImage }/>
                      <Autocomplete
                          value={selectValue.values[index][0]}
                          disabled={titleValue.values[index] === ''}
                          onChange={(event: any, newValue: any | null) => {
                            var preValues = selectValue.values;
                            preValues[index][0] = newValue;
                            setSelectValue({...selectValue, values: preValues});
                            setEdited(true);
                          }}
                          inputValue={typingValue.values[index][0]}
                          onInputChange={(event, newInputValue) => {
                            var preValues = typingValue.values;
                            preValues[index][0] = newInputValue;                         
                            setTypingValue({...typingValue, values: preValues});
                            setEditRowId(index);
                            setEditPosId(0);
                          }}
                          id={`${index}-1`}
                          options={ defaultOptions() }
                          getOptionLabel={ (option) => (option.name !== undefined? option.name : '') }
                          renderOption={(option) => option.name }
                          getOptionSelected={(option: any, value: any) => {
                            return true;
                          }}
                          renderInput={(params) => <TextField {...params} label={ t('settings.select-item') } />}
                      />
                    </div>
                    <div className="item-wrapper">
                      <Typography color="primary">2</Typography>
                      <Avatar className={classes.bannerImg} variant="rounded" alt="Item thumbnail" src={ selectValue.values[index][1] !== null  ? selectValue.values[index][1].img : defaultImage }/>
                      <Autocomplete
                          value={selectValue.values[index][1]}
                          disabled={titleValue.values[index] === ''}
                          onChange={(event: any, newValue: any | null) => {
                            var preValues = selectValue.values;
                            preValues[index][1] = newValue;
                            setSelectValue({...selectValue, values: preValues});
                            setEdited(true);
                          }}
                          inputValue={typingValue.values[index][1]}
                          onInputChange={(event, newInputValue) => {
                            var preValues = typingValue.values;
                            preValues[index][1] = newInputValue;                         
                            setTypingValue({...typingValue, values: preValues});
                            setEditRowId(index);
                            setEditPosId(1);
                          }}
                          id={`${index}-2`}
                          options={ defaultOptions() }
                          getOptionLabel={ (option) => (option.name !== undefined? option.name : '') }
                          renderOption={(option) => option.name }
                          getOptionSelected={(option: any, value: any) => {
                            return true;
                          }}
                          renderInput={(params) => <TextField {...params} label={ t('settings.select-item') } />}
                      />
                    </div>
                    <div className="item-wrapper">
                      <Typography color="primary">3</Typography>
                      <Avatar className={classes.bannerImg} variant="rounded" alt="Item thumbnail" src={ selectValue.values[index][2] !== null  ? selectValue.values[index][2].img : defaultImage} />
                      <Autocomplete
                          value={selectValue.values[index][2]}
                          disabled={titleValue.values[index] === ''}
                          onChange={(event: any, newValue: any | null) => {
                            var preValues = selectValue.values;
                            preValues[index][2] = newValue;
                            setSelectValue({...selectValue, values: preValues});
                            setEdited(true);
                          }}
                          inputValue={typingValue.values[index][2]}
                          onInputChange={(event, newInputValue) => {
                            var preValues = typingValue.values;
                            preValues[index][2] = newInputValue;                         
                            setTypingValue({...typingValue, values: preValues});
                            setEditRowId(index);
                            setEditPosId(2);
                          }}
                          id={`${index}-3`}
                          options={ defaultOptions() }
                          getOptionLabel={ (option) => (option.name !== undefined? option.name : '') }
                          renderOption={(option) => option.name }
                          getOptionSelected={(option: any, value: any) => {
                            return true;
                          }}
                          renderInput={(params) => <TextField {...params} label={ t('settings.select-item') } />}
                      />
                    </div>
                    <div className="item-wrapper">
                      <Typography color="primary">4</Typography>
                      <Avatar className={classes.bannerImg} variant="rounded" alt="Item thumbnail" src={ selectValue.values[index][3] !== null  ? selectValue.values[index][3].img : defaultImage }/>
                      <Autocomplete
                          value={selectValue.values[index][3]}
                          disabled={titleValue.values[index] === ''}
                          onChange={(event: any, newValue: any | null) => {
                            var preValues = selectValue.values;
                            preValues[index][3] = newValue;
                            setSelectValue({...selectValue, values: preValues});
                            setEdited(true);
                          }}
                          inputValue={typingValue.values[index][3]}
                          onInputChange={(event, newInputValue) => {
                            var preValues = typingValue.values;
                            preValues[index][3] = newInputValue;                         
                            setTypingValue({...typingValue, values: preValues});
                            setEditRowId(index);
                            setEditPosId(3);
                          }}
                          id={`${index}-4`}
                          options={ defaultOptions() }
                          getOptionLabel={ (option) => (option.name !== undefined? option.name : '') }
                          renderOption={(option) => option.name }
                          getOptionSelected={(option: any, value: any) => {
                            return true;
                          }}
                          renderInput={(params) => <TextField {...params} label={ t('settings.select-item') } />}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.rightContent}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ statusValue.values[index].enabled }
                        disabled={titleValue.values[index] === '' || statusValue.values[index].new}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          var preValues = statusValue.values;
                          preValues[index].enabled = e.target.checked;
                          setStatusValue({...statusValue, values: preValues});
                        }}
                        name="slider_enabled"
                        color="primary"
                      />
                    }
                    label={ t('settings.enable-slider') }
                  />
                </div>
                <div>
                  <Typography color="primary" variant="h5">{index + 1}</Typography>
                </div>
              </form>
            </ListItem>
        ))}
        <div className={classes.bottomSection}>
          {edited && <Typography color="secondary">{ t('settings.unsaved-changes') }</Typography>}
          <br/>
          <Button variant="contained" color="primary" disabled={loading !== ''} component="span" onClick={ saveBoxesSettings }>
            { t('settings.save') } {(loading === 'ADD_BANNER' || loading === 'UPDATE_BANNER') && <CircularProgress/>}
          </Button>
        </div>
      </Container>
    </List>
  );
};


export default FeaturedBoxSettings;
