// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionWrapper: {
      padding: theme.spacing(8, 2),
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(20),
      },
      '& .MuiPaper-root': {
        padding: theme.spacing(2),
        minHeight: '550px',
      }
    },
  }),
);

export default useStyles;
