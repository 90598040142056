import { useDispatch, useSelector } from "react-redux";
import { getItemByBarcode } from "../../../actions/itemActions/ItemActions";
import { CodeScanner } from "./CodeScanner";
import { RootStore } from "../../../Store";
import { useEffect } from "react";
import { itemAddedListUpdate } from "../../../actions/orderActions/OrderActions";


export const AddOrderScanCode = (props:{
    disabled?: boolean;
}) => {
    const dispatch = useDispatch();
    const itemState = useSelector((state: RootStore) => state.item)
    const itemsInOrder = useSelector((state: RootStore) => state.order.addedInOrder?.itemAddedList)
    const bundlesInOrder = useSelector((state: RootStore) => state.order.addedInOrder?.bundleAddedList)

    useEffect(() => {
        if(itemState.scannedItem === undefined) return;

        const {
            id,
        } = itemState.scannedItem;

        if (id) {
            const itemFound = itemsInOrder?.find((item) => item.item.id === id);
            let items;
            if (itemFound) {
                console.log('itemFound', itemFound)
                console.log('itemsInOrder', itemsInOrder)
                items = itemsInOrder ? [ ...itemsInOrder.filter(item => item.item.id !== itemFound.item.id) ] : [];
                items.unshift({
                    ...itemFound,
                    quantity: itemFound.quantity + 1,
                }) 
                dispatch(itemAddedListUpdate(items, bundlesInOrder || []));
            } else {
                dispatch(itemAddedListUpdate(
                    [
                        {
                            item: itemState.scannedItem,
                            customReference: '',
                            quantity: 1,
                            whId: 1,
                        },
                        ...itemsInOrder || [],
                    ],  
                    bundlesInOrder || []
                ));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemState.scannedItem])
    
    const handleProductScan = (barcode: string) => {
        dispatch(getItemByBarcode({code: barcode}, 'seller'));
    }

    if (props.disabled) {
        return null
    }

    return <CodeScanner
        onCodeScan={(barcode) => handleProductScan(barcode)}
    />
};
