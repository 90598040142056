// Base React.
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

// Material UI.
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { StepIconProps } from '@material-ui/core/StepIcon';
import clsx from 'clsx';
import Check from '@material-ui/icons/Check';

// Styles.
import useStyles from './StatusStepperStyles';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  active: {
    '& $line': {
      borderColor: process.env.REACT_APP_THEME_COLOR_PRIMARY_MAIN,
    },
  },
  completed: {
    '& $line': {
      borderColor: process.env.REACT_APP_THEME_COLOR_PRIMARY_MAIN,
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center'
  },
  active: {
    color: process.env.REACT_APP_THEME_COLOR_PRIMARY_MAIN,
  },
  circle: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: process.env.REACT_APP_THEME_COLOR_PRIMARY_MAIN,
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

interface StatusStepperProps {
  steps: string[];
  currentStep: number
}

const StatusStepper = ({ steps, currentStep }: StatusStepperProps) => {
  const classesStepper = useStyles();

  return (
    <Stepper alternativeLabel activeStep={currentStep} connector={<QontoConnector />} className={classesStepper.rootStepper}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>
              <strong>{label}</strong>
            </StepLabel>
          </Step>
        ))}
    </Stepper>
  )
}

export default StatusStepper;
