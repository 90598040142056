import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiSnackbar-root': {
        left: 'auto',
        right: 0,
        top: '85px',
        height: '50px',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        width: 'auto',
        transform: 'inherit',
        paddingRight: theme.spacing(2),
        '&.user-pages': {
          top: '135px',
          [theme.breakpoints.down('xs')]: {
            top: '245px',
          },
        },
      },
      '& .MuiAlert-root': {
        height: '50px'
      }
    }
  })
);