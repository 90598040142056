// React.
import React from 'react';

// Components.
import ItemSearch from '../items/ItemSearch';
import ItemList from '../items/ItemList';

const Items = () => {
  return (
    <div>
      <ItemSearch />
      <ItemList />
    </div>
  )
}

export default Items
