// React.
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';


interface PrivateRouteProps extends RouteProps{
 component:any;
 rest?:any
}

const PrivateRoute: React.SFC<PrivateRouteProps> = ({ component: Component, authenticated, ...rest }:any) => (
 <Route
  {...rest}
    render={(props) => {
      const authToken = localStorage.token;
      if (authToken !== undefined && authToken !== '') {
        return <Component {...props} /> 
      } else {
        return <Redirect to={{ pathname: '/user/login', state: { from: props.location }}}  />;
      }
    }
  }
  />
);

export default PrivateRoute;