import { withStyles, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const Accordion = withStyles({
  root: {
    margin: '0',
    boxShadow: 'none',
    '& > *': {
      flexDirection: 'row-reverse',
    },
    '&$expanded': {
      margin: '0',
    }
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    borderBottom: '2px solid rgba(0, 0, 0, .3)',
    marginBottom: 1,
    padding: '0',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      margin: '0',
      borderBottom: '0',
    },
  },
  content: {
    display: 'flex',
    margin: '12px 15px',
    '&$expanded': {
      margin: '0 15px',
    },
    '& > *': {
      alignItems: 'center',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: '2px solid rgba(0, 0, 0, .3)',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 0),
    },
  },
}))(MuiAccordionDetails);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    circularProgress: {
      textAlign: 'center',
    },
    userImgSmall : {
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
    }
  }),
);
