import jwtDecode from 'jwt-decode';

export const CheckAuthentication = () => {
  const authToken = localStorage.token;
  
  if (authToken) {
    const decodedToken:any = jwtDecode(authToken);
    // Checks if the token has expired.
    if (decodedToken.exp * 1000 < Date.now()) {
      // log out user.
      localStorage.removeItem('token');
    }
  }
};
