// React.
import React, {useState} from 'react';

// Material UI
import Paper from '@material-ui/core/Paper';
import PeopleIcon from '@material-ui/icons/People';
import TuneIcon from '@material-ui/icons/Tune';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

// Redux
import {useDispatch} from "react-redux";
import {refreshUserView, userSetFilters} from "../../../actions/userActions/UserActions";

// Components.
import UserAccordion from './UserAccordion';

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './UserListStyles';

const UserList = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();

  // Local state.
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelected = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    if (event.currentTarget.getAttribute('value') !== '') {
      const sendFilter = {
        role: event.currentTarget.getAttribute('value')
      };
      dispatch(userSetFilters(sendFilter));
      dispatch(refreshUserView(true));
    }
  };

  return (
    <div className={ classes.root }>
      <Paper elevation={3}>
        <div className={ classes.boxTop }>
          <PeopleIcon color="primary" fontSize="large"/>
          <Typography variant="h5" color="primary" className={ classes.sectionTitle }>
            { t('users.search-results') }
          </Typography>
            <Button variant="text" color="primary" size="medium" onClick={handleMenu} startIcon={<TuneIcon />}>
              { t('users.filters') }
            </Button>
            <Menu id="filter-users" anchorEl={ anchorEl }
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={ handleSelected } value="ROLE_USER">{ t('users.user') }</MenuItem>
              <MenuItem onClick={ handleSelected } value="ROLE_ADMIN">{ t('users.admin') }</MenuItem>
              <MenuItem onClick={ handleSelected } value="ROLE_SELLER">{ t('users.seller') }</MenuItem>
              <MenuItem onClick={ handleSelected } value="ROLE_SUPER_SELLER">{ t('users.super-seller') }</MenuItem>
              <MenuItem onClick={ handleSelected } value="ROLE_SELLER_ASSOCIATE">{ t('users.seller-associate') }</MenuItem>
            </Menu>
        </div>
        <div>
          <UserAccordion />
        </div>
      </Paper>
    </div>
  )
}

export default UserList
