// React.
import React, { useState, useEffect } from 'react';

// Material UI
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getItemsForPrize, registerEventPrizeToPlayer } from '../../../actions/prizeActions/PrizeActions';
import { RootStore } from "../../../Store";

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './PrizeItemSearchModalStyles';

// Model types.
import { AwardType, ItemType } from '../../../actions/prizeActions/PrizeActionsTypes';

interface AwardPrizeItemProps {
  player: AwardType;
};

const PrizeItemSearchModal = ({ player } : AwardPrizeItemProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();

  // Local component state.
  const [currentItem, setCurrentItem] = useState<ItemType | undefined>(undefined);
  const [typingValue, setTypingValue] = useState<string>('');
  const [clearSearch, setClearSearch] = useState<boolean>(false);
  const [itemQuantity, setItemQuantity] = useState<number | undefined>(undefined);
  const [quantityError, setQuantityError] = useState(false);
  const [searchOptions, setSearchOptions] = useState<Array<any>>([]);


  // Redux state.
  const eventReduxState = useSelector((state: RootStore) => state.events);
  const prizeReduxState = useSelector((state: RootStore) => state.prize);
  const { eventDetail } = eventReduxState;
  const { itemsForPrize } = prizeReduxState;

  useEffect(() => {
    if (typingValue !== '') {
      const delayDebounceFn = setTimeout(
        () => triggerSearchItemsAutocomplete(),
        500
      );
      return () => clearTimeout(delayDebounceFn)
    }
    // eslint-disable-next-line
  }, [typingValue]);

  // Update the search options array.
  useEffect(() => {
    if (itemsForPrize !== undefined) {
      var optionsList: Array<any> = [];
      // Add items results.
      itemsForPrize.forEach((option: any) => {
        optionsList.push({ element: option, type: 'item' });
      });
      setSearchOptions(optionsList);
    }
    // eslint-disable-next-line
  }, [itemsForPrize]);

  const triggerSearchItemsAutocomplete = () => {
    //Search normal items.
    dispatch(getItemsForPrize(eventDetail?.id ? eventDetail?.id : 0, typingValue));
  };
    
  const handleChangeItemName = (event: any, selectedItem: any) => {
    setCurrentItem(selectedItem.element);
  };
  
  const handleChangeTyping = (event: React.ChangeEvent<{ value: string }>) => {
    setTypingValue(event.target.value);
  };
    
  const handleChangeItemQuantity = (event: React.ChangeEvent<{ value: string }>) => {
    if (event.target.value) {
      const quantity = Number(event.target.value);
      const available = currentItem?.totalQuantity ? currentItem?.totalQuantity :  0;
      if ((quantity > available) || (quantity < 1)) {
        setQuantityError(true);
        setItemQuantity(quantity);
      } else {
        setItemQuantity(quantity);
        setQuantityError(false);
      }
    } else {
      setItemQuantity(undefined);
    }
  };
  
  const numberFormat = (value: number) => {
    var roundedNumber = Math.round(value);
    return roundedNumber.toLocaleString('en-US')
  }
  
  const getVendorOptionLabel: any = (option: any): string => {
    if (option.type !== undefined) {
      const symbol = '₡';
      if (option.type === 'item') {
        const price = option.element.price ? `${t('items.normal-price')}: ${symbol}` + numberFormat(option.element.price) : '';
        let priceString = `${price}`;
        return `${option.element.itemName} | Stock: ${option.element.totalQuantity} | ${priceString} | (${option.element.vendorName})`;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
  
  const addEventPrizeToPlayer = () => {
    if (itemQuantity !== undefined) {
      if (currentItem !== undefined && eventDetail !== undefined && player !== undefined) {
        //(eventPlayerId: number, eventPrizeId: number, itemId: number, quantity: number )
        const prizeId = currentItem?.prizeId ? currentItem?.prizeId  : 0;
        dispatch(registerEventPrizeToPlayer(
          player.playerId,
          prizeId,
          currentItem.itemId,
          itemQuantity
        ));
        
        // Clear fields
        setCurrentItem(undefined);
        setTypingValue('');
        setItemQuantity(undefined); 

        setClearSearch(true);
        setTimeout(
          () => setClearSearch(false),
          1000
        );
      }
    }
  };
  
  return (
    <div className={classes.root}>
      <div className={classes.boxBottom}>
        <form className={classes.boxForm}>
          <Grid container spacing={3}>
            <Grid item xs={10} sm={10}>
              <Autocomplete
                freeSolo
                disableClearable
                id="item-name-autocomplete"
                value={clearSearch && ''}
                onChange={handleChangeItemName}
                options={searchOptions}
                getOptionLabel={getVendorOptionLabel}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="search-name"
                    name="search-name"
                    label={t('orders.add-item-name')}
                    value={typingValue !== '' ? typingValue : ''}
                    onChange={handleChangeTyping}
                    className={classes.fieldClass}
                    InputProps={{ ...params.InputProps, type: 'search' }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2} sm={2}>
              <TextField
                id="item-quantity"
                name="quantity"
                type="number"
                value={itemQuantity !== undefined ? itemQuantity : ''}
                label={t('orders.add-item-quantity')}
                className={classes.fieldClass}
                onChange={handleChangeItemQuantity}
                error={quantityError}
                helperText={quantityError ? t('orders.quantity-greater-error') : ''}
                onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                disabled={(currentItem?.totalQuantity ? false : true)}
              />
            </Grid>
          </Grid>
        </form>
        <div className={classes.buttons}>
          <Button onClick={addEventPrizeToPlayer} variant="contained" color="primary" className={classes.buttonsLast} disabled={quantityError || itemQuantity === undefined}>
            {t('prizes.add')}
          </Button>
        </div>
      </div>       
    </div>
  )
}

export default PrizeItemSearchModal;
