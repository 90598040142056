import { 
    CardResponseData,
    PaymentDispatchTypes,
    PAYMENT_METHODS_LIST,
    PAYMENT_METHODS_LOADING,
    PAYMENT_METHOD_REGISTER,
    PAYMENT_METHOD_UNREGISTER,
    GET_ALL_PAYMENT_METHODS
} from "../actions/userPaymentMethodsActions/UserPaymentMethodsActionsTypes";

interface DefaultStateI {
    loading: string,
    register: string,
    unregister: string,
    refresh?: boolean,
    cardsResponse: CardResponseData[],
    paymentMethodsList?: Array<string>,
    actionStatus?: any
  }
  
  // Default state.
  const defaultState: DefaultStateI = {
    loading: '',
    register: 'PAYMENT_METHOD_REGISTRATION_IDLE',
    unregister: '',
    cardsResponse: [],
  };

const PaymentMethodsReducer = (state: DefaultStateI = defaultState, action: PaymentDispatchTypes) : DefaultStateI => {
    switch (action.type) {
        case PAYMENT_METHODS_LIST:
            return {
                ...state,
                loading: '',
                cardsResponse: action.payload
            }
        case PAYMENT_METHODS_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case PAYMENT_METHOD_REGISTER:
            return {
                ...state,
                register: action.payload
            }
        case PAYMENT_METHOD_UNREGISTER:
            return {
                ...state,
                register: action.payload
            }
        case GET_ALL_PAYMENT_METHODS:
            return {
                ...state,
                paymentMethodsList: action.payload
            }
        default:
            return state
    }
}

export default PaymentMethodsReducer;
