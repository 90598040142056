// React.
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Material UI
import Paper from '@material-ui/core/Paper';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

// Components.
import AddOrderAccordion from './AddOrderAccordion';
import NumberFormat from '../utils/NumberFormat';

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "../../../Store";
import { itemAddedListUpdate, addOrder, updateQuickOrder } from "../../../actions/orderActions/OrderActions";
import { addCustomAddress } from "../../../actions/userAddressActions/UserAddressActions";

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './AddOrderDetailsStyles';

// Model types.
import { ShippingType, AddressUserType, OrderType, ItemType } from '../../admin/models/ModelTypes';
import scrollToTop from '../../utils/scrollToTop';
import { getProjectConfig } from '../../../getProjectConfig';

interface AddOrderDetailsProps {
  selectedShipping: ShippingType | undefined;
  selectedPayment: string;
  selectedAddressId: number;
  customAddress: AddressUserType;
  setValidateAddress: Function;
  radioValue: string;
  quickOrder: boolean;
  selectedUserEmail: string;
  selectedVendor: string;
};

const AddOrderDetails = ({ selectedShipping, selectedPayment, selectedAddressId, customAddress, setValidateAddress, 
                            radioValue, quickOrder, selectedUserEmail, selectedVendor }: AddOrderDetailsProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();
  let history = useHistory();

  // Redux state.
  const reduxState = useSelector((state: RootStore) => state);
  const itemsAdded = reduxState.order.addedInOrder?.itemAddedList;
  const bundleAdded = reduxState.order.addedInOrder?.bundleAddedList;
  const loading = reduxState.order.loading;
  const actionStatus = reduxState.order.actionStatus;
  const createdOrder = reduxState.order.order;
  const customAddressCreatedId = reduxState.address.customAddressCreatedId;

  useEffect(() => {
    if (actionStatus !== undefined) {
      if (actionStatus.menssage === 'status.success-create') {
        // On quick orders set the status as completed.
        if (quickOrder && createdOrder !== undefined) {
          createdOrder.forEach((order: OrderType) => {
            const updateOrderData = {
              orderId: order.id,
              purchaseValidation: t('orders.quick-order-label'),
              status: 'Procesando',
              trackingNumber: ''
            }
            dispatch(updateQuickOrder(updateOrderData));
          });
        } else {
          history.push('/admin/orders');
        }
      }
      // When it's a quick order.
      if (actionStatus.menssage === 'status.success-update') {
        history.push('/admin/orders');
      }
    }
    // eslint-disable-next-line
  }, [actionStatus]);

  // Get a single price based on the customReference.
  const getSinglePrice = (item: ItemType, customReference: string) => {
    var price = 0;
    const priceObj = item.customPrices?.filter((customPrice: any) => customPrice.customReference === customReference);
    if (priceObj?.length! > 0) price = priceObj![0]?.price;
    return price;
  };

  const calculateSubTotal = () => {
    var total = 0;
    if (itemsAdded !== undefined) {
      for (var i = 0; i < itemsAdded!.length; i++) {
        const current = itemsAdded![i];
        if (current.item.category === 'Single') {
          let taxPercentage = current.item.itemType.taxPercentage;
          let totalWithTaxes = Number((getSinglePrice(current.item, current.customReference) * current.quantity).toFixed(2));
          total = Number((total + ((totalWithTaxes * 100) / (100 + taxPercentage))).toFixed(2));
        } else {
          const currentItemPrice = Number(getPriceInfo(current.item));
          const currentQuantity = Number(current.quantity);
          const curretTotal = currentItemPrice * currentQuantity;
          total = total + curretTotal
        }
      }
    }

    if (bundleAdded !== undefined) {
      for (var j = 0; j < bundleAdded!.length; j++) {
        const current = bundleAdded![j];
        const currentbundlePrice = Number(getPriceInfo(current.bundle));
        const currentQuantity = Number(current.quantity);
        const curretTotal = currentbundlePrice * currentQuantity;
        total = total + curretTotal
      }
    }

    return total.toFixed(2);
  };

  const getPriceInfo = (item: any) => {
    if (item.discounts !== null && item.discounts !== undefined) {
      const discount = item.discounts.pricePercentage;
      const savings = Number(((discount / 100) * item.price).toFixed(2));
      const newPrice = (item.price - savings);
      return newPrice;
    } else {
      return item.price;
    }
  };

  const getPriceWithTaxesInfo = (item: any) => {
    if (item.discounts !== null && item.discounts !== undefined) {
      const discount = item.discounts.pricePercentage;
      const savings = Number(((discount / 100) * item.priceWithTaxes).toFixed(2));
      const newPrice = (item.priceWithTaxes - savings);
      return newPrice;
    } else {
      return item.priceWithTaxes;
    }
  };

  const calculateTax = () => {
    var total = 0;
    if (itemsAdded !== undefined) {
      for (let i = 0; i < itemsAdded!.length; i++) {
        const current = itemsAdded![i];
        if (current.item.category === 'Single') {
          let taxPercentage = current.item.itemType.taxPercentage;
          let totalWithTaxes = Number((getSinglePrice(current.item, current.customReference) * current.quantity).toFixed(2));
          let subtotal = Number((totalWithTaxes * 100) / (100 + taxPercentage).toFixed(2));
          total = Number((total + ((subtotal * taxPercentage) / 100)).toFixed(2));
        } else {
          const priceWithTaxes = Number(getPriceWithTaxesInfo(current.item));
          const basePrice = Number(getPriceInfo(current.item));
          const tax = (priceWithTaxes - basePrice) * current.quantity;
          total = total + tax
        }
      }
    }
    if (bundleAdded !== undefined) {
      for (let j = 0; j < bundleAdded!.length; j++) {
        const current = bundleAdded![j];
        const priceWithTaxes = Number(getPriceWithTaxesInfo(current.bundle));
        const basePrice = Number(getPriceInfo(current.bundle));
        const tax = (priceWithTaxes - basePrice) * current.quantity;
        total = total + tax
      }
    }
    return total.toFixed(2);
  };

  const calculateTotal = () => {
    let total = Number(calculateSubTotal()) + Number(calculateTax());
    return total.toFixed(2);
  };

  const cancelCurrentOrder = () => {
    dispatch(itemAddedListUpdate([], []));
  };

  const validateCustomAddress = () => {
    if (customAddress.province === '' || customAddress.canton === '' || customAddress.district === ''
      || customAddress.fullAddress === '' || customAddress.phoneNumber === '') {
      return false;
    }
    return true;
  };

  const getRequestObject = () => {
    const requestObject: any = { items: [], bundles: [] };

    // For normal items.
    for (var i = 0; i < itemsAdded!.length; i++) {
      const current = itemsAdded![i];
      requestObject.items.push({
        itemId: current.item.id,
        quantity: current.quantity,
        customReference: (current.customReference) ? current.customReference : 'NA'
      });
    }

    // For bundles.
    for (var j = 0; j < bundleAdded!.length; j++) {
      const current = bundleAdded![j];
      requestObject.bundles.push({
        bundleId: current.bundle.id,
        quantity: current.quantity
      });
    }

    return requestObject;
  }

  useEffect(() => {
    if (customAddressCreatedId !== undefined) {
      if (customAddressCreatedId > 0) {
        let shippingId = selectedShipping?.id !== undefined ? selectedShipping?.id : 0;
        const requestObject = getRequestObject();
        dispatch(addOrder(requestObject, selectedPayment, selectedUserEmail, shippingId, customAddressCreatedId, selectedVendor));
      }
    }
    // eslint-disable-next-line
  }, [customAddressCreatedId]);

  const createOrder = () => {
    const requestObject: any = getRequestObject();
    if (requestObject.items.length > 0 || requestObject.bundles.length > 0) {
      let shippingId = selectedShipping?.id !== undefined ? selectedShipping?.id : 0;
      // If shipping by post
      if (shippingId === 2) {
        setValidateAddress(true);

        // If custom address.
        if (radioValue === 'custom') {
          if (validateCustomAddress()) {
            // Save the address.
            dispatch(addCustomAddress(customAddress));
          } else {
            scrollToTop();
          }
        } else {
          if (selectedAddressId !== 0) {
            dispatch(addOrder(requestObject, selectedPayment, selectedUserEmail, shippingId, selectedAddressId, selectedVendor));
          } else {
            scrollToTop();
          }
        }
      } else {
        dispatch(addOrder(requestObject, selectedPayment, selectedUserEmail, shippingId, selectedAddressId, selectedVendor));
      }
    }
  };

  const getTaxPercentage = () => {
    return '13';
  };

  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <div className={classes.boxTop}>
          <ListAltIcon color="primary" fontSize="large" />
          <Typography variant="h5" color="primary" className={classes.sectionTitle}>
            {t('orders.order-details')}
          </Typography>
        </div>
        <div>
          <AddOrderAccordion />
        </div>
        <div className={classes.rowTotal}>
          <Typography variant="caption" color="textSecondary">{t('orders.subtotal')} {getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={Number(calculateSubTotal())} />}</Typography>
          <Typography variant="caption" color="textSecondary">{getTaxPercentage()}% {t('orders.tax')}: {getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={Number(calculateTax())} />}</Typography>
          <Typography variant="subtitle1" color="textSecondary">{t('orders.total-price')}</Typography>
          <Typography variant="h5" color="textPrimary">{getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={Number(calculateTotal())} />}</Typography>
        </div>
        <div className={classes.rowActions}>
          {(loading === 'ADD_ORDER' || loading === 'UPDATE_ORDER') && <CircularProgress />}
          <Button onClick={cancelCurrentOrder} variant="text" color="secondary"> {t('orders.cancel')} </Button>
          <Button
            onClick={createOrder}
            variant="contained"
            color="primary"
            disabled={
              ((itemsAdded === undefined || itemsAdded.length === 0) && (bundleAdded === undefined || bundleAdded.length === 0))
              || selectedShipping === undefined || selectedPayment === '' || loading !== '' || (selectedShipping.id === 1 && !selectedVendor)
            }>
            {t('orders.create-new-order')}
          </Button>
        </div>
        <div className={classes.rowNotes}>
          {selectedShipping === undefined &&
            <Typography variant="caption" color="textSecondary">*{t('orders.must-select-shipping')}</Typography>
          }
          {(selectedShipping?.id === 1 && !selectedVendor) &&
            <Typography variant="caption" color="textSecondary">*{t('orders.must-select-vendor')}</Typography>
          }
          {selectedPayment === '' &&
            <Typography variant="caption" color="textSecondary">*{t('orders.must-select-payment')}</Typography>
          }
          {((itemsAdded === undefined || itemsAdded.length === 0) && (bundleAdded === undefined || bundleAdded.length === 0)) &&
            <Typography variant="caption" color="textSecondary">*{t('orders.must-add-items')}</Typography>
          }
        </div>
      </Paper>
    </div>
  )
}

export default AddOrderDetails;
