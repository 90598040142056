// React.
import React from 'react';

// Components.
import PrizeLabelsSettings from '../settings/PrizeLabelsSettings';

const PrizeLabelsSettingsPage = () => {

  return (
      <PrizeLabelsSettings/>
  )
}

export default PrizeLabelsSettingsPage;
