import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    marginRight: '10px'
  },
  inputQty: {
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: '0 3px',
    width: '50px',
    backgroundColor: 'white',
    '& .MuiSelect-icon': {
      right: '-3px',
    },
    '& .MuiSelect-select': {
      paddingRight: '8px',
      paddingLeft: '8px',
    },
    '&.MuiInput-underline:before': {
      display: 'none',
    }
  },
}),);

export default useStyles;