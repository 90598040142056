// React.
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Material UI
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Redux
import {useDispatch, useSelector} from "react-redux";
import {refreshItemView, itemSetFilters} from "../../../actions/itemActions/ItemActions";
import { getItemTypes } from "../../../actions/itemTypeActions/ItemTypeActions";
import {RootStore} from "../../../Store";

// Moment.
import Moment from 'moment';

// Translations.
import { useTranslation } from 'react-i18next';

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';
import axios from 'axios';

// Styles.
import useStyles from './ItemSearchStyles';
import { downloadItemReport } from '../utils/downloadItemReport';

const ItemSearch = () => {
  const [t] = useTranslation('global');
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const apiURL: string | undefined = process.env.REACT_APP_API_URL;
  const token: string | undefined = localStorage.token;

  // Redux state.
  const itemProductState = useSelector((state: RootStore) => state.item);
  const loading = itemProductState.loading;
  const vendorsState = useSelector((state: RootStore) => state.vendors);
  const vendorLoading = vendorsState.loading;
  const itemTypeState = useSelector((state: RootStore) => state.itemType);
  const { itemTypeList } = itemTypeState;

  // Local state.
  const [openDropdown, setOpenDropdown] = useState(false);
  const [options, setOptions] = useState<string[]>([]);
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const [selectedItem, setSelectedItem] = useState<string | null>('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [category, setCategory] = useState<number | null>();
  const [itemUpdated, setItemUpdated] = useState<string | null>();
  const [itemType1, setItemType1] = useState<boolean>(true);
  const [itemType2, setItemType2] = useState<boolean>(true);
  const [isDiscount, setIsDiscount] = useState<boolean>(false);
  const [isFeatured, setIsFeatured] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  // Get all item categories.
  useEffect(() => {
    const pageSize = getProjectConfig().UNLIMITED_PAGES_SIZE;

    let sort =  'id,asc';

    const qParams = {
      size: pageSize,
      ...(sort !== '') && { sort: sort }
    };

    dispatch(getItemTypes(qParams));
  
    // eslint-disable-next-line
  }, [])

  // For Search Autocomplete.
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      let active = true;
      const headers: object = { ContentType: 'application/json', Authorization: 'Bearer ' + token };

      setSearchLoading(true);

      if (autocompleteValue && autocompleteValue !== '') {
        (async () => {
          var optionsList: Array<string> = [];

          // Search items.
          const qParamsItems = { page: 0, name: autocompleteValue, size: getProjectConfig().PAGES_SIZE, paged: true, isUniquePerCode: true };
          const resItems = await axios.get(apiURL + `/seller/getAllItems`, { params: qParamsItems, headers: headers });
          const items = (resItems.data.data.itemPage !== undefined) ? resItems.data.data.itemPage.content : resItems.data.data.content;

          if (active) {
            // Add the results to the array.
            items.forEach((item: any) => { optionsList.push(item.name); });

            // Update the state.
            setOptions(optionsList);
            setSearchLoading(false);
          }
        })();
      }

      return () => {
        active = false;
      };
    }, 300)

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line
  }, [autocompleteValue])

  const autocompleteChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAutocompleteValue(String(event.target.value));
  };

  const getInitDate = () => {
    if (itemUpdated && itemUpdated !== 'all') {
      var date = Moment().format('YYYY-MM-DD');
      if (itemUpdated === 'before') {
        date = Moment().startOf('year').format('YYYY-MM-DD')
      }
      return date;
    }
    return '';
  };

  const getEndDate = () => {
    if (itemUpdated && itemUpdated !== 'all') {
      var date = Moment().add(1, 'days').format('YYYY-MM-DD');
      if (itemUpdated === 'before') {
        date = Moment().format('YYYY-MM-DD');
      }
      return date;
    }
    return '';
  };

  const handleClick = () => {
    // When filter changes go to the root page.
    const queryParams = new URLSearchParams(location.search)

    if (queryParams.has('page')) {
      queryParams.delete('page')
      history.push({
        search: queryParams.toString(),
      });
    }


    const sendFilter = {
      name: selectedItem ? selectedItem : autocompleteValue,
      ...(category && category !== 0) && { itemtypeIds: category },
      ...(itemType1 && !itemType2) && { category: 'Sellado' },
      ...(itemType2 && !itemType1) && { category: 'Single' },
      ...(isDiscount) && { isDiscount: isDiscount },
      ...(isFeatured) && { isFeature: isFeatured },
      ...(itemUpdated && itemUpdated !== 'all') && { initialLastUpdateDateRange: getInitDate(), endLastUpdateDateRange: getEndDate() }
    };

    dispatch(itemSetFilters(sendFilter));
    dispatch(refreshItemView(true));
  };

  const handleClear = () => {
    const sendFilter = {};
    dispatch(itemSetFilters(sendFilter));
    dispatch(refreshItemView(true));
    setAutocompleteValue('');
    setSelectedItem('');
  };

  const handleDownload = async () => {
    const sendFilter = {
      name: selectedItem ? selectedItem : autocompleteValue,
      ...(category && category !== 0) && { itemtypeIds: category },
      ...(itemType1 && !itemType2) && { category: 'Sellado' },
      ...(itemType2 && !itemType1) && { category: 'Single' },
      ...(isDiscount) && { isDiscount: isDiscount },
      ...(isFeatured) && { isFeature: isFeatured },
      ...(itemUpdated && itemUpdated !== 'all') && { initialLastUpdateDateRange: getInitDate(), endLastUpdateDateRange: getEndDate() }
    };
    setIsDownloading(true);
    await downloadItemReport(sendFilter);
    setIsDownloading(false);
  };

  // Indicate when to disabled the buttons.
  const isButtonDisabled = ()=> {
    if ((loading !== '' && loading !== 'ITEM_PRICE_UPDATE') || vendorLoading !== '') return true;
    return false;
  };

  return (
    <div className={ classes.root }>
      <Paper elevation={3}>
        <div className={ classes.boxTop }>
          <SearchIcon color="primary" fontSize="large"/>
          <Typography variant="h5" color="primary">
            { t('items.item-search') }
          </Typography>
        </div>
        <div>
          <form className={ classes.boxForm }>
            <Autocomplete
              id="asynchronous-search" style={{ width: '100%' }} open={openDropdown}
              onOpen={() => { autocompleteValue !== '' ? setOpenDropdown(true) : setOpenDropdown(false); }}
              onClose={() => { setOpenDropdown(false); }}
              value={selectedItem}
              onChange={(event: any, newValue: string | null) => {
                setSelectedItem(newValue);
              }}
              getOptionSelected={(option, value) => option === value}
              getOptionLabel={(option) => option} options={options}
              noOptionsText={searchLoading ? t('header.loading') : t('header.no-result')}
              clearOnBlur={false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="search-keys"
                  name="search-keys"  
                  value={autocompleteValue}
                  onChange={autocompleteChange}
                  error={selectedItem === null}
                  disabled={isButtonDisabled()}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleClick();
                      setSelectedItem(autocompleteValue);
                    }
                  }}
                />
              )}
            />
          </form>
        </div>
        <div className={classes.advancedFilters}>
          <Typography color="primary">{t('items.filters')}</Typography>
          <FormControl>
            <InputLabel id="category-select-label">{t('items.categories')}</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              value={category}
              onChange={
                (event: React.ChangeEvent<{ value: unknown }>) => setCategory(event.target.value as number)
              }
            >
              <MenuItem value={0}>{t('items.all')}</MenuItem>
              {itemTypeList?.categories?.map(category => 
                <MenuItem value={category.id}>{category.name}</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="updated-select-label">{t('items.last-update')}</InputLabel>
            <Select
              labelId="updated-select-label"
              id="updated-select"
              value={itemUpdated}
              onChange={
                (event: React.ChangeEvent<{ value: unknown }>) => setItemUpdated(event.target.value as string)
              }
            >
              <MenuItem value={'all'}>{t('items.all')}</MenuItem>
              <MenuItem value={'today'}>{t('items.today')}</MenuItem>
              <MenuItem value={'before'}>{t('items.yesterday-before')}</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={itemType1}
                name="check1"
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setItemType1(event.target.checked);
                }}
              />
            }
            label="Sellados"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={itemType2}
                name="check2"
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setItemType2(event.target.checked);
                }}
              />
            }
            label="Singles"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isDiscount}
                name="check3"
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIsDiscount(event.target.checked)}
              />
            }
            label={t('items.has-discount')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isFeatured}
                name="check4"
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIsFeatured(event.target.checked)}
              />
            }
            label={t('items.is-featured')}
          />
        </div>
        <div className={ classes.buttons }>
          <div style={{
            flexGrow: 1,
          }}> 
            <Button onClick={() => handleDownload()} variant="contained" color="primary" disabled={isButtonDisabled() || isDownloading }>
              {(isDownloading) ? 'Descargando...' : 'Descargar'}
            </Button>
          </div>
          <Button onClick={ handleClear } variant="contained" color="secondary">
            { t('items.clear') }
          </Button>

          <Button onClick={handleClick} variant="contained" color="primary" disabled={ isButtonDisabled() }>
            { t('items.search') }
          </Button>
        </div>
      </Paper>
  </div>
  )
}

export default ItemSearch;
