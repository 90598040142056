import MaskedInput from 'react-text-mask';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function TextMaskIdentification(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  const maxLength = 15;

  const transformValue = (rawValue: string) => {
    // Limita la longitud al máximo permitido
    const limitedValue = rawValue.slice(0, maxLength);
    return limitedValue.replace(/\D/g, ''); // Elimina caracteres no numéricos
  };

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={Array.from({ length: maxLength }, () => /\d/)}
      showMask={false}
      pipe={transformValue}
    />
  );
}

export default TextMaskIdentification;
