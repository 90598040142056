// React.
import React from 'react';

// Material UI.
import Button from '@material-ui/core/Button';

// Styles.
import useStyles from './SlideStyles';

interface SlideProps {
  item: any
};
const Slide = ({item} : SlideProps) => { 
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={item.imageUrl} alt="Banner."/>
      <div className={classes.textWrapper}>
        {(item.text !== null && item.text !== 'null' && item.text !== '') && 
          <h2>{item.text}</h2>
        }
        {(item.ctaText !== null && item.ctaText !== 'null' && item.ctaText !== '') && 
          <Button variant="contained" color="primary" size="large" href={ item.ctaLink }>
            { item.ctaText }
          </Button>
        }
      </div>
      {item.gradient &&
        <span className={classes.darkGradient}>&nbsp;</span>
      }
    </div>
  )
}

export default Slide;
