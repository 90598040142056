import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '520px',
      position: 'relative',
      boxShadow: '0px -48px 54px -34px #e3e3e3 inset',
      zIndex: 2,
      [theme.breakpoints.down('xs')]: {
        height: '430px',
      },
      '& img': {
        width: '100%',
        height: '520px',
        objectFit: 'cover',
        objectPosition: 'right top',
        [theme.breakpoints.down('xs')]: {
          height: '430px',
        },
      }
    },
    textWrapper: {
      position: 'absolute',
      left: '11%',
      zIndex: 4,
      top: '45%',
      transform: 'translateY(-50%)',
      textAlign: 'left',
      maxWidth: '510px',
      color: process.env.REACT_APP_THEME_COLOR_PRIMARY_TEXT,
      [theme.breakpoints.down('xs')]: {
        width: '72%',
        left: '15%',
        top: '56%'
      },
      '& h2': {
        fontFamily: 'VisbyCF-Bold',
        fontSize: '38px',
        lineHeight: '38px',
        [theme.breakpoints.down('xs')]: {
          fontSize: '20px',
          lineHeight: '20px',
        },
      },
      '& .MuiButton-label': {
        fontFamily: 'VisbyCF-Bold',
      }
    },
    darkGradient: {
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 3,
      width: '50%',
      height: '100%',
      background: 'linear-gradient(90deg, rgba(0,0,0,1) 14%, rgba(0,0,0,.8) 55%, rgba(0,0,0,0) 100%)',
      [theme.breakpoints.down('xs')]: {
        width: '80%',
      },
    }
  })
);

export default useStyles;