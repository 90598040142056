// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(2, 1),
        padding: theme.spacing(4, 4, 1),
        width: '100%',
        height: 'auto',
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(4, 2, 1),
        },
      },
    },
    searchEvent:{
        display:'flex',
        justifyContent:'space-around',
        width:'100%'
    },
    formLabel:{
      display:'flex',
      flexDirection:'column',
    },
    formLeft:{
      display:'flex',
      width:'50%',
      
    },
    formButton:{
      display:'flex',
      width:'100%',
      justifyContent:'space-between',
      alignSelf:'flex-end',     
    },
    modalForm:{
      display:'flex',
      flexDirection:'column',
      gap:'20px'
    },
    eventStatus:{
        width:'50%'
    },
    boxTop: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      '& > *': {
        marginRight: theme.spacing(2),
      },
    },
    boxForm: {
      display: 'flex',
      justifyContent: 'start',
      '& > *': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '70%',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap'
      },
    },
    fieldFirst: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    buttonsLast: {
      marginRight: 0
    }
  }),
);

export default useStyles;