import React, { Fragment } from 'react';

interface ArrowProps {
  onClickHandler: any;
  dark?: boolean;
};
const RightArrow = ({onClickHandler, dark} : ArrowProps) => {
  return (
    <Fragment>
      <svg className="right-arrow" xmlns="http://www.w3.org/2000/svg" onClick={onClickHandler} width="30" height="58">
        <path d="M 29 0.5 L 0.5 29 L 29 57.5" fill="transparent" stroke={dark ? 'hsl(0, 0%, 30%)' : 'hsl(0, 0%, 100%)'} strokeLinecap="round" strokeDasharray="-1"></path>
      </svg>
    </Fragment>
  )
}

export default RightArrow;
