import { Grid } from "@material-ui/core";
import { SellerSummary } from "./utils/reportsInterfaces";
import TotalSoldCard from "../../utils/TotalSoldCard";
import React from "react";
import { mergeDuplicateSellerSummaries } from "../utils/mergeDuplicateSellerSummaries";

export const SellersSummary = (props: {sellerSummaries?: SellerSummary[]}): JSX.Element => {

  if (!props.sellerSummaries) {
    return <></>;
  }
  
  const uniqueSummaries = mergeDuplicateSellerSummaries(props.sellerSummaries);

  return <>{uniqueSummaries.map((summary, index) => (
    <Grid item xs={12} sm={3} key={index}>
      <TotalSoldCard
        storeName={summary.vendorName}
        webSold={summary.totalWeb}
        totalPackage={summary.totalPackage}
        physicalSold={summary.totalSeller}
        finalTotal={summary.finalTotal}
      />
    </Grid>
  ))}</>;
}

export default SellersSummary;