import { Dispatch } from "redux";
import axios from 'axios';
import {
  NAV_LOADING, NAV_FAIL, GET_NAV, SET_NAV_FILTERS, REGISTER_MENU, UPDATE_MENU, GET_ALL_ICONS_URL, UPDATE_NAV_ADMIN,
  DELETE_MENU, REGISTER_SUBMENU, UPDATE_SUBMENU, DELETE_SUBMENU, NavFiltersType, NavItemType, NavSubItemType, NavDispatchTypes 
} from "./NavActionsTypes";

const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;

/**
 * Get all nav information.
 */
export const getNav = () => async (dispatch: Dispatch<NavDispatchTypes>) => {

  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: NAV_LOADING,
        payload: 'GET_NAV'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      let queryUrl = `${apiURL}/guest/getAllMenuTitles`;
      const res = await axios.get(queryUrl, {
        headers: headers
      });

      if (res.status === 200) {
        const queryData = res.data.data;

        dispatch({
          type: GET_NAV,
          payload: queryData
        })
      } else {
        dispatch({
          type: NAV_FAIL
        })
      }
    } catch (e) {
      dispatch({
        type: NAV_FAIL
      })
    }
  } else {
    dispatch({
      type: NAV_FAIL
    })
  }
};

/**
 * Create a menu option on the BE.
 * @param {NavItemType} menuItem
 */
export const registerMenu = (menuItem: NavItemType) => async (dispatch: Dispatch<NavDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: NAV_LOADING,
        payload: 'REGISTER_MENU'
      })

      const bodyForm = {
        iconUrl: menuItem.iconUrl,
        itemTypeIds: menuItem.itemTypeIds,
        labelValuesIds: menuItem.labelValuesIds,
        sequence: menuItem.sequence,
        uiUrl: menuItem.uiUrl,
        value: menuItem.value
      };

      const configPOST = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };

      const addRes = await axios.post(`${apiURL}/admin/registerMenu`, bodyForm, configPOST);

      if (addRes.status === 200) {
        dispatch({
          type: REGISTER_MENU,
          payload: addRes.data.data
        })
      }

    } catch (e) {
      dispatch({
        type: NAV_FAIL,
        payload: 'status.create-error'
      })
    }
  } else {
    dispatch({
      type: NAV_FAIL,
      payload: 'status.create-error'
    })
  }
};

/**
 * Update a menu option on the BE.
 * @param {NavItemType} menuItem
 */
export const updateMenu = (menuItem: NavItemType) => async (dispatch: Dispatch<NavDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: NAV_LOADING,
        payload: 'UPDATE_MENU'
      })

      const bodyForm = {
        id: menuItem.id,
        iconUrl: menuItem.iconUrl,
        itemTypeIds: menuItem.itemTypeIds,
        labelValuesIds: menuItem.labelValuesIds,
        sequence: menuItem.sequence,
        uiUrl: menuItem.uiUrl,
        value: menuItem.value
      };

      const configPOST = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };

      const addRes = await axios.put(`${apiURL}/admin/updateMenu`, bodyForm, configPOST);

      if (addRes.status === 200) {
        dispatch({
          type: UPDATE_MENU,
          payload: addRes.data.data
        })
      }

    } catch (e) {
      dispatch({
        type: NAV_FAIL,
        payload: 'status.update-error'
      })
    }
  } else {
    dispatch({
      type: NAV_FAIL,
      payload: 'status.update-error'
    })
  }
};

/**
 * Delete a menu item on the BE.
 * @param {number} menuItemId
 */
export const deleteMenu = (menuItemId: number) => async (dispatch: Dispatch<NavDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: NAV_LOADING,
        payload: 'DELETE_MENU'
      })

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };

      const deleteRes = await axios.delete(`${apiURL}/admin/deleteMenu/${menuItemId}`, configPUT);

      if (deleteRes.status === 200) {
        dispatch({
          type: DELETE_MENU,
          payload: 'status.success-delete-menu'
        })
      }

    } catch (e) {
      dispatch({
        type: NAV_FAIL,
        payload: 'status.delete-error-item'
      })
    }
  } else {
    dispatch({
      type: NAV_FAIL,
      payload: 'status.delete-error-item'
    })
  }
};

/**
 * Create a submenu option on the BE.
 * @param {NavSubItemType} subMenuItem
 */
export const registerSubMenu = (subMenuItem: NavSubItemType) => async (dispatch: Dispatch<NavDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: NAV_LOADING,
        payload: 'REGISTER_SUBMENU'
      })

      const bodyForm = {
        iconUrl: subMenuItem.iconUrl,
        itemTypeIds: subMenuItem.itemTypeIds,
        labelValuesIds: subMenuItem.labelValuesIds,
        sequence: subMenuItem.sequence,
        uiUrl: subMenuItem.uiUrl,
        value: subMenuItem.value,
        menuId: subMenuItem.menuId
      };

      const configPOST = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };

      const addRes = await axios.post(`${apiURL}/admin/registerSubmenu`, bodyForm, configPOST);

      if (addRes.status === 200) {
        dispatch({
          type: REGISTER_SUBMENU,
          payload: addRes.data.data
        })
      }

    } catch (e) {
      dispatch({
        type: NAV_FAIL,
        payload: 'status.create-error'
      })
    }
  } else {
    dispatch({
      type: NAV_FAIL,
      payload: 'status.create-error'
    })
  }
};

/**
 * Update a submenu option on the BE.
 * @param {NavSubItemType} subMenuItem
 */
export const updateSubMenu = (subMenuItem: NavSubItemType) => async (dispatch: Dispatch<NavDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: NAV_LOADING,
        payload: 'UPDATE_SUBMENU'
      })

      const bodyForm = {
        id: subMenuItem.id,
        iconUrl: subMenuItem.iconUrl,
        itemTypeIds: subMenuItem.itemTypeIds,
        labelValuesIds: subMenuItem.labelValuesIds,
        sequence: subMenuItem.sequence,
        uiUrl: subMenuItem.uiUrl,
        value: subMenuItem.value
      };

      const configPOST = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };

      const addRes = await axios.put(`${apiURL}/admin/updateSubmenu`, bodyForm, configPOST);

      if (addRes.status === 200) {
        dispatch({
          type: UPDATE_SUBMENU,
          payload: addRes.data.data
        })
      }

    } catch (e) {
      dispatch({
        type: NAV_FAIL,
        payload: 'status.update-error'
      })
    }
  } else {
    dispatch({
      type: NAV_FAIL,
      payload: 'status.update-error'
    })
  }
};

/**
 * Delete a submenu item on the BE.
 * @param {number} subMenuItemId
 */
export const deleteSubMenu = (subMenuItemId: number) => async (dispatch: Dispatch<NavDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: NAV_LOADING,
        payload: 'DELETE_SUBMENU'
      })

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };

      const deleteRes = await axios.delete(`${apiURL}/admin/deleteSubmenu/${subMenuItemId}`, configPUT);

      if (deleteRes.status === 200) {
        dispatch({
          type: DELETE_SUBMENU,
          payload: 'status.success-delete-menu'
        })
      }

    } catch (e) {
      dispatch({
        type: NAV_FAIL,
        payload: 'status.delete-error'
      })
    }
  } else {
    dispatch({
      type: NAV_FAIL,
      payload: 'status.delete-error'
    })
  }
};

/**
 * Get all icons URLs.
 */
export const getAllIconsUrl = () => async (dispatch: Dispatch<NavDispatchTypes>) => {

  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: NAV_LOADING,
        payload: 'GET_ALL_ICONS'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      let queryUrl = `${apiURL}/admin/getAllIconsUrl`;
      const res = await axios.get(queryUrl, {
        headers: headers
      });

      if (res.status === 200) {
        const queryData = res.data.data;

        dispatch({
          type: GET_ALL_ICONS_URL,
          payload: queryData
        })
      } else {
        dispatch({
          type: NAV_FAIL
        })
      }
    } catch (e) {
      dispatch({
        type: NAV_FAIL
      })
    }
  } else {
    dispatch({
      type: NAV_FAIL
    })
  }
};

/**
 * Sets the nav filters.
 * @param {NavFiltersType} filter
 */
export const setNavFilters = (filter: NavFiltersType) => async (dispatch: Dispatch<NavDispatchTypes>) => {
  try {
    dispatch({
      type: NAV_LOADING,
      payload: 'UPDATE_NAV_FILTERS'
    })

    dispatch({
      type: SET_NAV_FILTERS,
      payload: filter
    })
  } catch (e) {
    dispatch({
      type: NAV_FAIL
    })
  }
};

/**
 * Update admin nav.
 * @param {boolean} mustUpdate
 */
export const setUpdateNavAdmin = (mustUpdate: boolean) => async (dispatch: Dispatch<NavDispatchTypes>) => {
  try {
    dispatch({
      type: NAV_LOADING,
      payload: 'UPDATE_NAV_ADMIN'
    })

    dispatch({
      type: UPDATE_NAV_ADMIN,
      payload: mustUpdate
    })
  } catch (e) {
    dispatch({
      type: NAV_FAIL
    })
  }
};
