import {
  ITEM_CATEGORY_LIST,
  ITEM_CATEGORY_FAIL,
  ITEM_CATEGORY_LOADING,
  CategoryTypeList,
  ItemCategoryDispatchTypes
} from '../actions/categoryActions/categoryActionsType'

// Default state interface.
interface DefaultStateI {
  loading: string,
  categories: CategoryTypeList[]
  actionStatus?: any
}

const defaultState: DefaultStateI = {
  loading: '',
  categories: []
};

const CategoryReducer = (state: DefaultStateI = defaultState, action: ItemCategoryDispatchTypes) : DefaultStateI => {
  switch (action.type) {
    case  ITEM_CATEGORY_FAIL:
      return {
        ...state,
        loading: '',
        actionStatus: {status: 'error'}
      }
    case ITEM_CATEGORY_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case ITEM_CATEGORY_LIST:
      return {
        ...state,
        loading: '',
        categories: action.payload
      }
    default:
      return state
  }
};

export default CategoryReducer;
