// React.
import React from 'react';

// Components.
import AddUserForm from '../users/AddUserForm';

// Material UI.
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

// Styles.
import { useStyles } from './AddUserStyles';

// Translations.
import { useTranslation } from 'react-i18next';

const AddUser = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');

  return (
    <Paper className={classes.paper} elevation={3} square={false} variant="elevation">
      <Typography color="primary"  variant="h5" align="inherit">
        <AddCircleOutlineIcon/> { t('addUserForm.add-new-user') }
      </Typography>
      <AddUserForm/>
    </Paper>
  )
}

export default AddUser;
