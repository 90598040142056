// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiFormControl-root.MuiTextField-root': {
        width: '100%',
        marginBottom: theme.spacing(2)
      },
      '& .MuiInputBase-root': {
        width: 'calc(100% - 20px)',
      },
      '& .MuiPaper-root': {
        backgroundColor: process.env.REACT_APP_THEME_COLOR_EXTRA_LIGHT_GRAY,
        padding: theme.spacing(0, 2),
        marginBottom: theme.spacing(1),
        width: 'calc(50% - 10px)',
        marginRight: '5px',
        display: 'inline-block',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          marginRight: 0,
        },
      },
      '& .MuiFormControl-root': {
        width: '350px',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          width: '100%'
        },
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, 2px) scale(0.75)'
      },
      '& .MuiCircularProgress-root': {
        width: '25px !important',
        height: '25px !important',
        marginLeft: theme.spacing(3)
      }
    },
    marginBottom: {
      marginBottom: theme.spacing(3),
      marginTop:theme.spacing(3)
    },
    labelsWrapper: {
      backgroundColor: process.env.REACT_APP_THEME_COLOR_EXTRA_LIGHT_GRAY,
      borderRadius: '4px',
      padding: '10px 24px 33px',
      marginBottom: theme.spacing(2),
      width: '100%'
    },
    labelValue:{
    },
    fieldLabel: {
      marginRight: theme.spacing(4),
      minWidth: '360px',
      width:'auto',
      whiteSpace:'nowrap'
    },
    paper: {
      padding: theme.spacing(2.5),
      marginBottom: theme.spacing(2.5),
      width:"100%", backgroundColor:'#fff'
    },
    labelValuesWrapper: {
      '& .MuiInputBase-root:before': {
        display: 'none'
      },
      '& .MuiInputBase-root:after': {
        display: 'none'
      },
      valuesBtn: {
        width: '100%'
      }
    },
    sectionTitle: {
      flexGrow: 1,
      marginBottom: theme.spacing(2),
      marginTop:theme.spacing(4),
      textAlign:'center',
      textTransform: 'uppercase',
    },
    customTable: {
      '& .header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        padding: '4px 4px',
        backgroundColor: theme.palette.grey[600],
        color: 'white',
        fontFamily: 'VisbyCF-Bold',
        borderRadius: '6px',
        textAlign: 'center'
      },
      '& .row': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        padding: '4px 4px',
        textAlign: 'center'
      },
      '& .body': {
        display: 'block',
        position: 'relative',
        maxHeight: '726px',
        overflow: 'auto'
      },
      '& .body:last-of-type .row': {
        borderBottom: 'initial',
      },
      '& .cell': {
        '& > .MuiSvgIcon-colorPrimary': {
          color: theme.palette.primary.main,
          cursor: 'pointer'
        },
        '&.d30': {
          width: '30%'
        },
        '&.d28': {
          width: '28%'
        },
        '&.d20': {
          width: '20%',
          display:'flex',
          justifyContent:'center'
        },
        '&.d12': {
          width: '12%',
        },
        '&.d10': {
          width: '15%',
        },
        '&.d100': {
          width: '100%'
        },
        '&.right': {
          textAlign: 'right'
        },
        '&.priceCol': {
          '& .MuiTypography-root': {
            display: 'inline-block',
            marginRight: theme.spacing(1)
          }
        },
        [theme.breakpoints.down('sm')]: {
          '&.m20': {
            width: '20%'
          },
          '&.m25': {
            width: '25%'
          },
          '&.d10 .MuiButtonBase-root': {
            padding: '5px',
          },
          '&.small p': {
            fontSize: '14px'
          }
        }
      }
    },
    playerName: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      textAlign: 'center',
      width:'100%',
      justifyContent:'center',
      '& > .MuiSvgIcon-colorPrimary': {
        color: theme.palette.primary.main,
        cursor: 'pointer'
      },
    },
    switch: {
      '& .MuiSwitch-switchBase' : {
        color: '#515153'
      },
      '& .MuiSwitch-track' : {
        backgroundColor: '#515153'
      },
      '& .MuiSwitch-colorSecondary.Mui-checked' : {
        color: '#2F983F'
      },
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track' : {
        backgroundColor: '#2F983F'
      },
      '& .MuiSwitch-colorSecondary.Mui-disabled' : {
        color: '#bdbdbd'
      },
      '& .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track' : {
        backgroundColor: '#B0B1B3'
      }
    },
    actionsWrapper: {
      marginTop: theme.spacing(10),
      textAlign: 'right',
      '& .loading-bottom': {
        verticalAlign: 'middle',
        marginRight: theme.spacing(3)
      }
    }
  }),
);

export default useStyles;