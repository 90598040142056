// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(2),
      '& > *': {
        margin: theme.spacing(1),
        padding: theme.spacing(4, 4),
        width: '100%',
        height: 'auto',
        minHeight: '400px',
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(4, 2),
        },
      },
    },
    sectionTitle: {
      flexGrow: 1,
    },
    eventName:{
        height:'45%',
        textAlign:'center',
        maxWidth:'70%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2, // Number of lines to show
        '-webkit-box-orient': 'vertical',
    },
    eventStatus:{
        height:'25%'
    },
    eventDate:{
        height:'25%'
    },
    eventButton:{
        marginBottom:'20px'
    },
    boxTop: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginBottom: theme.spacing(9),
      '& > *': {
        marginRight: theme.spacing(2),
      },
    },
    EventBox:{
        width:' 255px',
        height:'255px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        borderRadius:'20px'
    },
    EventContainer:{
        display:'flex',
        flexWrap:'wrap',
        gap:'40px',
        width:'90%',
        justifyContent:'start'
    }
  }),
);

export default useStyles;
