// React.
import React, {useState, useEffect} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Material UI
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import DateAdapter from '@mui/lab/AdapterMoment';

// Moment.
import Moment from 'moment';

// Translations.
import { useTranslation } from 'react-i18next';

// Redux
import {RootStore} from "../../../Store";
import {useDispatch, useSelector} from "react-redux";
import {orderSetFilters, refreshOrderView, getAllOrderStatus} from "../../../actions/orderActions/OrderActions";

// Styles.
import useStyles from './OrderSearchStyles';
import { Grid } from '@material-ui/core';

const today = Moment().format('YYYY-MM-DD');
const tomorrow = Moment().add(1, 'days').format('YYYY-MM-DD');

const OrderSearch = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [t] = useTranslation('global');
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(window.location.search);
  let statusParam = params.get('status');

  // Local component state.
  const [search, setSearch] = useState<string>(() => '');
  const [status, setStatus] = React.useState(() => (statusParam !== null && statusParam !== 'active' && statusParam !== 'active') ? statusParam : '');
  const [initialDateRange, setInitialDateRange] = useState(() => statusParam === null ? today : null);
  const [endDateRange, setEndDateRange] = useState(() => statusParam === null ? tomorrow : null);

  // Redux state.
  const orderState = useSelector((state: RootStore) => state.order);
  const statusList = orderState.orderStatusList?.orderStatuses;
  const loading = orderState.loading;

  useEffect(() => {
    let activeStatusList = 'Procesando,Enviado,Listo para Recoger';
    let receivedStatusList = 'Orden Recibida';
    dispatch(getAllOrderStatus());
    dispatch(orderSetFilters({
      initialDateRange,
      endDateRange,
      ...(statusParam === 'active') && { status: activeStatusList },
      ...(statusParam === 'received') && { status: receivedStatusList },
    }));
    dispatch(refreshOrderView(true));
    // eslint-disable-next-line
  }, []);

  const handleChangeSearch = (event: React.ChangeEvent<{ value: string }>) => {
    setSearch(event.target.value);
    if (event.target.value === '') {
      handleClear();
    }
  };

  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as string);
  };

  const handleClick = () => {
    // When filter changes go to the root page.
    const queryParams = new URLSearchParams(location.search)

    if (queryParams.has('page')) {
      queryParams.delete('page')
      history.replace({
        search: queryParams.toString(),
      });
    }

    if(queryParams.has('status')){
      queryParams.delete('status')
      history.replace({
        search: queryParams.toString(),
      });
    }
    const sendFilter = {
      searchTerm: search,
      status: status,
      initialDateRange,
      endDateRange,
    };
    dispatch(orderSetFilters(sendFilter));
    dispatch(refreshOrderView(true));
  };

  const handleClear = () => {
    const sendFilter = {};
    dispatch(orderSetFilters(sendFilter));
    dispatch(refreshOrderView(true));
    setSearch('');
    setStatus('');
  };

  const changeInitialDate = (startDate:any) => {
    let newDateString = Moment(startDate).format('YYYY-MM-DD');
    setInitialDateRange(newDateString)
    if (endDateRange === null || newDateString > endDateRange) {
      setEndDateRange(Moment(startDate).add(1, 'days').format('YYYY-MM-DD'));
    }
  };

  const changeEndDate = (endDate:any) => {
    let newDateString = Moment(endDate).format('YYYY-MM-DD');
    setEndDateRange(newDateString)
    if (initialDateRange === null || newDateString < initialDateRange) {
      setInitialDateRange(Moment(endDate).subtract(1, 'days').format('YYYY-MM-DD'));
    }
  };

  return (
  <LocalizationProvider dateAdapter={DateAdapter}>
    <div className={ classes.root }>
        <Paper elevation={3}>
          <div className={ classes.boxTop }>
            <SearchIcon color="primary" fontSize="large"/>
            <Typography variant="h5" color="primary">
              { t('orders.order-search') }
            </Typography>
          </div>
          <div>
            <form className={ classes.boxForm }>
              <TextField 
                id="order-number"
                name="order_number"  
                value={ search }
                label={ t('orders.order-number') }
                className={ classes.fieldFirst }
                onChange={ handleChangeSearch }
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleClick();
                  }
                }}
              />
              <FormControl>
                <InputLabel id="order-status-label">{ t('orders.order-status') }</InputLabel>
                <Select
                  labelId="order-status-label"
                  id="order-status"
                  name="order_status"
                  value={status}
                  onChange={ handleChangeStatus }
                >
                  <MenuItem value="">
                    <em>{ loading === 'STATUS_LIST' ? t('orders.loading') : t('orders.none') }</em>
                  </MenuItem>
                  {statusList !== undefined &&
                    statusList.map((status: string) => 
                      <MenuItem key={ status } value={ status }>{ status }</MenuItem>
                    )}
                  </Select>
              </FormControl>
            </form>
          </div>

          <Grid container spacing={1} direction={'row'}
            style={{
              marginTop: 10,
              marginBottom: 10,
              }}
            >
              <Grid item >
                <DatePicker
                  label={t("orders.order-from-date")}
                  value={initialDateRange}
                  onChange={(newValue:any) => {
                    changeInitialDate(newValue)
                  }}
                  renderInput={(params:any) => <TextField {...params} />}
                />
              </Grid>
              <Grid item >
                <DatePicker
                  label={t("orders.order-to-date")}
                  value={endDateRange}
                  onChange={(newValue:any) => {
                    changeEndDate(newValue)
                  }}
                  renderInput={(params:any) => <TextField {...params} />}
                />
              </Grid>
            </Grid>

          <div className={ classes.buttons }>
            <Button onClick={ handleClear } variant="contained" color="secondary">
              { t('orders.clear') }
            </Button>
            <Button onClick={ handleClick } variant="contained" color="primary" className={ classes.buttonsLast }>
              { t('orders.search') }
            </Button>
          </div>
        </Paper>
    </div>
  </LocalizationProvider>
  )
}

export default OrderSearch;
