import React, { Fragment, useState } from 'react';

// Material UI.
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';

// Components.
import NumberFormat from '../../admin/utils/NumberFormat';
import AddToWishList from '../utils/AddToWishlist';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { addItemToCart, addItemToCartLocal } from "../../../actions/cartActions/CartActions";
import { getUserInformation } from '../../../actions/authActions/AuthActions';
import { RootStore } from "../../../Store";

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

// Styles.
import useStyles from './ItemSingleCardStyles';

// Translations.
import { useTranslation } from 'react-i18next';
import defaultImage from '../../../images/default-img.jpg';

// Types interfase.
interface ItemCardProps {
  item: any;
  vendorId?: number;
};

const ItemCard = ({ item, vendorId }: ItemCardProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();

  // Redux state.
  const userReduxState = useSelector((state: RootStore) => state.auth);
  const cartReduxState = useSelector((state: RootStore) => state.cart);
  const cartLoading = cartReduxState.loading;
  const dolarPrice = useSelector((state: RootStore) => state.item?.itemListSearchData?.dolarValue);
  
  const [quantities, setQuantities] = useState<any>({});
  const [viewAll, setViewAll] = useState<boolean>(false);
  var countAvailable = -1;

  const imgURL = (item.imageURL) ? item.imageURL : defaultImage;

  const handleQuantityChange = (id: string, value: unknown) => {
    if (id !== undefined && value !== undefined) {
      const newValues = quantities;
      newValues[id] = value;
      if (Number(value) >= 0) {
        setQuantities({ ...quantities, newValues });
      }
    }
  }

  const addToCart = (childItem: any, parentItem: any,  customReference: string) => {
    const qid = `${parentItem.itemId}-${childItem.customReference.replace(/\s+/g, '')}`;
    let quantity = quantities[qid] > 0 ? quantities[qid] : 1;
    if (localStorage.token) {
      // If user is logged.
      dispatch(addItemToCart(parentItem.itemId, quantity, customReference));
      dispatch(getUserInformation());
    } else {
      // If user is anonimous.
      var cart = localStorage.anonymousCart !== undefined ? JSON.parse(localStorage.anonymousCart) : [];
      // validate if already exists.
      let images = [{ url: imgURL }];
      let itemCopy = { ...parentItem, itemQuantityData: childItem, images: images, itemName: item.name }
      const index = cart.findIndex((obj: any) => (obj.item.itemId === parentItem.itemId && obj.customReference === customReference));
      if (index !== -1) {
        let currentQty = cart[index].quantity;
        cart[index] = { item: itemCopy, quantity: (currentQty + quantity), customReference: customReference, isFull: false };
      } else {
        cart.push({ item: itemCopy, quantity: quantity, customReference: customReference, isFull: false });
      }
      localStorage.removeItem('anonymousCart');
      localStorage.setItem('anonymousCart', JSON.stringify(cart));
      dispatch(addItemToCartLocal());
    }

    // Re-Set the state value.
    const newValues = quantities;
    newValues[qid] = getAllWarehousesQuantity(childItem, parentItem) > 0 ? 1 : 0;
    setQuantities({ ...quantities, newValues });
  };

  // Gets the total quantity from all vendor warehouses.
  const getAllWarehousesQuantity = (currentItem: any, parentItem: any) => {
    var result = Number(currentItem.quantity);
    const customReference = currentItem.customReference;

    // Check if there is already items added of this.
    if (userReduxState.loggedUser?.cart !== undefined) {
      userReduxState.loggedUser.cart.forEach((cartItem: any) => {
        if (cartItem.id.itemId === parentItem.itemId && cartItem.id.customReference === customReference) {
          result = result - cartItem.quantity;
        }
      });
    }

    // Check items added for anonimous users.
    var cartLocal = localStorage.anonymousCart !== undefined ? JSON.parse(localStorage.anonymousCart) : [];
    if (cartLocal.length > 0) {
      const index = cartLocal.findIndex((obj: any) => (obj.item.itemId === parentItem.itemId && obj.item.itemQuantityData.customReference === customReference));
      if (index !== -1) {
        let currentQty = cartLocal[index].quantity;
        result = result - currentQty;
      }
    }

    // Check if there is already items of this bundle added.
    if (userReduxState.loggedUser?.bundleCart !== undefined) {
      userReduxState.loggedUser?.bundleCart.forEach((bundleItem: any) => {
        const matchedItems = bundleItem.bundle.items.filter((cartItem: any) => cartItem.item.itemId === parentItem.itemId);
        if (matchedItems.length > 0) {
          result = result - (bundleItem.quantity * matchedItems[0].quantity);
        }
      });
    }

    // Check if there is already items of this bundle added for anonimous users.
    var bundleCartLocal = localStorage.anonymousBundleCart !== undefined ? JSON.parse(localStorage.anonymousBundleCart) : [];
    if (bundleCartLocal.length > 0) {
      bundleCartLocal.forEach((bundleItem: any) => {
        const matchedItems = bundleItem.bundle.items.filter((cartItem: any) => cartItem.item.itemId === parentItem.itemId);
        if (matchedItems.length > 0) {
          result = result - (bundleItem.quantity * matchedItems[0].quantity);
        }
      });
    }

    if (result !== 0) {
      countAvailable = countAvailable + result;
    }

    return result;
  };

  // Quantities to render.
  const renderOptions = (quantity: number) => {
    const items = [];
    for (var i = 1; i <= quantity; i++) {
      items.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
    }
    if (items.length === 0) {
      items.push(<MenuItem key={1} value={1}>{1}</MenuItem>)
    }
    return items;
  };

  const getLangCode = (langName: string) => {
    switch (langName) {
      case 'English':
        return 'EN'
      case 'Spanish':
        return 'SP';
      case 'Japanese':
        return 'JP';
      default:
        return langName;
    }
  };

  const renderCustomReferences = (item: any) => {
    var tags: string[] = [];
      var firstArray = item.customReference.split('-');
      firstArray.forEach((firstValue: any) => {
        tags.push(firstValue);
      });
      let lastPos = tags.length - 1;
      return `${tags[0]} | ${getLangCode(tags[lastPos]) } | ${tags[1]} ${(tags[2] !== tags[lastPos]) ? tags[2] : ''}`;
  };

  const getItemDolarPrice = (price: number) => {
    if (dolarPrice !== undefined) {
      return (price / Number(dolarPrice));
    }
    return 0;
  };

  const getCustomReferencePrice = (item: any) => {
    var price = item.typeOfWarehouse === 'Discount' ? item.discountFinalPrice : item.finalPrice;
    return (
      <Fragment>
        {dolarPrice !== undefined && <Typography color="textSecondary">(${<NumberFormat number={getItemDolarPrice(price)} decimal={true} />}) </Typography>}
        <Typography color="primary">{getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={price} />}</Typography>
      </Fragment>
    );
  };

  var vendorsIndex = 0;
  const updateVendorsIndex = () => {
    vendorsIndex++;
    return vendorsIndex;
  };

  return (
    <Card className={classes.root}>
      <CardActionArea href={`/item/${item.code}`} type="button">
        <div className={classes.cardTop}>
          <div className={classes.cardMedia}>
            <div className={classes.image}>
              <img src={imgURL} alt="Product media" loading="lazy" />
            </div>
            <div className={classes.wishlistWrapper}>
              <AddToWishList item={item}/>
            </div>
          </div>
          
          <div className={classes.cardDescription}>
            <Typography gutterBottom className={classes.cardTitle} color="textSecondary">
              {item.name}
            </Typography>
            {(item.setName) &&
              <Typography variant="caption" color="textSecondary">Set: {item.setName}</Typography>
            }
            <Typography variant="body2" color="textSecondary" component="div" className="item-description">
                <div dangerouslySetInnerHTML={{ __html: item.additionalInfo }}></div> 
            </Typography>
          </div>
        </div>
      </CardActionArea>
      <div className={classes.customTable} aria-label="Variations table">
        <Paper>
          <div className="header">
            <div className="cell d28 m22">{t('items.seller')}</div>
            <div className="cell d50 priceCol">{t('items.price')}</div>
            <div className="cell d12">{t('items.quantity')}</div>
            <div className="cell d10 right"></div>
          </div>
          {
            item.itemSearchVendorData !== undefined &&
            (item.itemSearchVendorData.map((vendor: any, i: number) =>
              <Fragment key={`s-${i}`}>
                  <Fragment>
                    <Fragment key={`${i}`}>
                        {vendor.itemQuantityData.map( (item: any, k: number) => (
                          <Fragment>
                            {(vendorsIndex < 4 || viewAll) &&
                              <Fragment>
                                {(getAllWarehousesQuantity(item, vendor) > 0) &&
                                  <div key={`k-${updateVendorsIndex()}`}>
                                    {(vendorsIndex < 3 || viewAll) &&
                                      <div className="body">
                                        <div className="row">
                                          <div className="cell d28 m22"><Link href={`/items/vendor/${vendor.id}/${vendor.name}`}>{vendor.name}</Link></div>
                                          <div className="cell d50 small priceCol">
                                            <div className={classes.variation}>{renderCustomReferences(item)}</div>
                                            {getCustomReferencePrice(item)}
                                          </div>
                                          <div className="cell d12">
                                            <div className={classes.quantityWrapper}>
                                              <FormControl>
                                                <Select
                                                  id={`${vendor.itemId}-${item.customReference.replace(/\s+/g, '')}`}
                                                  value={quantities[`${vendor.itemId}-${item.customReference.replace(/\s+/g, '')}`] !== undefined ? quantities[`${vendor.itemId}-${item.customReference.replace(/\s+/g, '')}`] : 1}
                                                  label={t('items.quantity')}
                                                  onChange={(e) => handleQuantityChange(`${vendor.itemId}-${item.customReference.replace(/\s+/g, '')}`, e.target.value)}
                                                  disabled={getAllWarehousesQuantity(item, vendor) < 1}
                                                  className={classes.inputQty}
                                                >
                                                  {renderOptions(getAllWarehousesQuantity(item, vendor))}
                                                </Select>
                                              </FormControl>
                                            </div>
                                          </div>
                                          <div className="cell d10 right">
                                            <IconButton color="primary" aria-label="Add to cart" component="span"
                                              onClick={() => addToCart(item, vendor, item.customReference)}
                                              disabled={getAllWarehousesQuantity(item, vendor) === 0 || cartLoading !== ''}>
                                              <AddShoppingCartIcon color={getAllWarehousesQuantity(item, vendor) > 0 ? 'secondary' : 'disabled'} />
                                            </IconButton>
                                          </div>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                }
                              </Fragment>
                            }
                          </Fragment>
                        ))}
                      </Fragment>
                  </Fragment>
              </Fragment>
            ))
          }
          {(countAvailable <= 0 && item.itemSearchVendorData !== undefined) &&
            <div className={classes.noStockMsg}>
              {t('items.no-available')}
            </div>
          }
        </Paper>
      </div>

      {(vendorsIndex > 3) &&
        <Fragment>
          {viewAll ?
            <Button variant="contained" className={classes.seeMoreBtn} color="primary" size="small" href={`/item/${item.code}`}>
              {t('items.see-details')}
            </Button>
            :
            <Button variant="contained" className={classes.seeMoreBtn} color="primary" size="small" onClick={() => setViewAll(true)}>
              {t('items.see-more')}
            </Button>
          }
        </Fragment>
      }
      {(vendorId !== undefined) &&
        <Button variant="contained" className={classes.seeMoreBtn} color="primary" size="small" href={`/item/${item.itemSearchVendorData[0].itemId}`}>
          {t('items.see-all-vendors')}
        </Button>
      }
    </Card>
  );
}

export default ItemCard;
