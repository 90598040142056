// Material UI.
import { withStyles, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const Accordion = withStyles({
  root: {
    margin: '0',
    boxShadow: 'none',
    '& > *': {
      flexDirection: 'row-reverse',
    },
    '&$expanded': {
      margin: '0',
    },
    '& .enabled': {
      color: '#009900'
    }
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    borderBottom: '2px solid rgba(0, 0, 0, .3)',
    marginBottom: 1,
    padding: '0',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      margin: '0',
      borderBottom: '0',
    },
  },
  content: {
    display: 'flex',
    margin: '12px 15px',
    '&$expanded': {
      margin: '0 15px',
    },
    '& > *': {
      alignItems: 'center',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: '2px solid rgba(0, 0, 0, .3)',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 0),
    },
  },
}))(MuiAccordionDetails);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& .MuiTypography-caption': {
        display: 'block',
        width: '34%',
        margin: '4px 0',
        '&.extra-width': {
          width: '60%',
        },
        [theme.breakpoints.down('xs')]: {
          paddingRight: '5px'
        },
      },
      '& .MuiTextField-root': {
        display: 'block',
        width: '66%'
      },
      '& .MuiInput-formControl': {
        display: 'block',
        width: '100%',
        '& .MuiSelect-selectMenu': {
          width: 'auto',
        },
      },
      '& a': {
        color: theme.palette.primary.main,
        textDecoration: 'underline'
      },
      '& .MuiDivider-root': {
        width: '100%'
      }
    },
    formControlAdjustQty: {
      flexWrap: 'wrap',
      margin: theme.spacing(1, 0),
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-end',
      },
      '& .MuiTypography-caption': {
        width: '100%'
      },
      '& .MuiTextField-root': {
        width: 'calc(34% - 16px)',
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          marginRight: 0
        }
      },
      '& .MuiButton-root': {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
      }
    },
    formControlDiscount: {
      flexWrap: 'wrap',
      backgroundColor: 'white',
      padding: '6px',
      borderRadius: '4px',
      marginBottom: theme.spacing(1),
      '& .MuiTypography-caption': {
        width: '100%'
      },
      '& .MuiTextField-root': {
        display: 'block',
        width: 'calc(49% - 4px)'
      },
      '&.closed': {
        '& .MuiTypography-caption': {
          width: '25%'
        },
      }
    },
    rightMargin: {
      marginRight: theme.spacing(1)
    },
    formControlMultiple: {
      '& .MuiInput-formControl': {
        width: '66%',
      },
      '& .labelsWrapper': {
        width: '65%'
      },
      '& .MuiChip-root': {
        marginRight: '4px',
        marginBottom: '4px',
        height: '24px',
        maxWidth: '100%'
      },
      '& .MuiChip-avatar': {
        width: 'auto',
        borderRadius: '15px',
        padding: '0 5px',
        fontSize: '11px',
        color: 'black',
        height: '16px'
      },
      '& .MuiChip-label': {
        fontSize: '11px',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
    mainImg: {
      width: '100%',
      maxHeight: '204px',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        width: '100%',
        maxHeight: '180px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxHeight: '114px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        maxHeight: '150px',
        marginBottom: theme.spacing(1)
      },
      '& .MuiAvatar-img': {
        height: 'auto',
        maxHeight: '204px',
        [theme.breakpoints.down('md')]: {
          maxHeight: '180px',
        },
        [theme.breakpoints.down('sm')]: {
          maxHeight: '150px',
        }
      }
    },
    smallImg: {
      width: '30%',
      height: '76px',
      position: 'relative',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        width: '30%',
        height: '76px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '30%',
        height: '45px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '86px',
        height: '86px',
        marginBottom: theme.spacing(1),
      },
      '& .MuiAvatar-rounded': {
        width: '100%',
        height: 'inherit',
      },
      '& .MuiAvatar-img': {
        height: 'auto',
        maxHeight: '76px',
        [theme.breakpoints.down('sm')]: {
          maxHeight: '45px',
        },
        [theme.breakpoints.down('xs')]: {
          maxHeight: '86px',
        }
      }
    },
    deleteIcon: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      zIndex: 1,
      '& :hover': {
        cursor: 'pointer'
      }
    },
    smallImgWraper: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    imgWrapper: {
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    editImgWrapper: {
      '& #icon-button-file': {
        display: 'none'
      }
    },
    leftColumnWrapper: {
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        alignItems: 'center'
      },
    },
    formField: {
      width: '260px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      '& .Mui-disabled:before': {
        borderBottom: 0
      },
      '&.Mui-disabled:before': {
        borderBottom: 0
      },
      '& svg': {
        display: 'none'
      },
      '& > div': {
        width: '260px',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
      '& input': {
        padding: '0px 0 7px'
      }
    },
    formControlPrice: {
      flexWrap: 'wrap',
      backgroundColor: 'white',
      padding: '6px',
      borderRadius: '4px',
      marginBottom: theme.spacing(1),
      '& .Mui-disabled:before': {
        borderBottom: '1px dashed'
      },
    },
    actionsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start-end',
      width: '100%',
      '& button': {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          marginLeft: theme.spacing(1),
        },
        '&:first-of-type': {
          marginLeft: theme.spacing(0),
          [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(0),
          },
        }
      }
    },
    actionsWrapperPrice: {
      '& button': {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
          marginLeft: theme.spacing(1),
        },
        '&:first-of-type': {
          marginLeft: theme.spacing(1),
          [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(1)
          },
        }
      }
    },
    loadingWh: {
      marginTop: '30px'
    },
    modalPaper: {
      padding: theme.spacing(6, 4),
      position: 'absolute',
      width: '400px',
      height: '200px',
      top: 'calc(50% - 100px)',
      left: 'calc(50% - 200px)',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(6, 2),
        width: '90%',
        left: '5%'
      },
      '& .MuiTypography-root': {
        marginBottom: theme.spacing(2),
      },
      '& .MuiButtonBase-root': {
        marginLeft: theme.spacing(2),
      }
    },
    closeModal: {
      position: 'absolute',
      right: '0',
      top: '0'
    },
    vendorName: {
      fontFamily: 'VisbyCF-Bold',
      width: '100%'
    },
    vendorsWrapper: {
      '& .MuiAccordion-root:before': {
        display: 'none'
      },
      '& .vendor-item': {
        borderRadius: '6px',
        marginBottom: theme.spacing(1)
      },
      '& .vendor-item:nth-child(5n+1)': {
        backgroundColor: '#FEF9E7',
      },
      '& .vendor-item:nth-child(5n+2)': {
        backgroundColor: '#F9EBEA',
      },
      '& .vendor-item:nth-child(5n+3)': {
        backgroundColor: '#EBF5FB',
      },
      '& .vendor-item:nth-child(5n+4)': {
        backgroundColor: '#F2F4F4',
      },
      '& .vendor-item:nth-child(5n+5)': {
        backgroundColor: '#D4EFDF',
      }
    },
    vendorLoader: {
      width: '30px !important',
      height: '30px !important',
      margin: '40px auto',
      display: 'block'
    },
    ckeditor: {
      width: '66%',
    }
  }),
);

export default useStyles;