// React.
import { Fragment } from "react"

// Material UI.
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

// Translations.
import { useTranslation } from "react-i18next"
import useStyles from "../pages/InventoryRequestsPageStyles"
import InventoryRequestsList from "./InventoryRequestsList"

const InventoryRequests = () => {
  const classes = useStyles()
  const [t] = useTranslation("global")
  return (
    <Fragment>
      <CssBaseline />
      <Container maxWidth="xl" className={classes.ordersWrapper}>
        <Paper
          elevation={3}
          square={false}
          variant="elevation"
          className={classes.paperWrapper}
        >
          <Typography gutterBottom variant="h5" component="h1" color="primary">
            {t("inventoryRequests.inventory-requests")}
          </Typography>
          <InventoryRequestsList />
        </Paper>
      </Container>
    </Fragment>
  )
}

export default InventoryRequests
