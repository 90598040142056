import {
  ADDRESS_FAIL,
  ADDRESS_LOADING,
  ADDRESS_PUT,
  ADD_ADDRESS,
  ADD_CUSTOM_ADDRESS,
  UserAddressDispatchTypes,
  AddressUserType,
} from "../actions/userAddressActions/UserAddressActionsTypes";

// Default state interface.
interface DefaultStateI {
  loading: string,
  address?: AddressUserType,
  actionStatus?: any,
  customAddressCreatedId?: number,
  userAddressCreatedId?: number,
  userAddressCreatedIndex?: number,
}

// Default state.
const defaultState: DefaultStateI = {
  loading: '',
};

const userAddressReducer = (state: DefaultStateI = defaultState, action: UserAddressDispatchTypes) : DefaultStateI => {
  switch (action.type) {
    case ADDRESS_FAIL:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'error'}
      }
    case ADDRESS_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case ADD_ADDRESS:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload.message, status: 'success'},
        userAddressCreatedId: action.payload.id,
        userAddressCreatedIndex: action.payload.index
      }
    case ADD_CUSTOM_ADDRESS:
      return {
        ...state,
        loading: '',
        customAddressCreatedId: action.payload
      }
    case ADDRESS_PUT:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'success'}
      }
    default:
      return state
  }
};


export default userAddressReducer;
