import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  FormControl,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  getCategoriesTCG,
  getSetTemplate,
  getSetsTCGbyCategory,
} from "../../../actions/templateActions/TemplateActions";
import { RootStore } from "../../../Store";
import useStyles from "./CreateSinglesTemplateStyles";
import { Autocomplete } from "@mui/material";
import { CategoryType, SetType } from "../../../actions/templateActions/TemplateActionsTypes";

const CreateSinglesTemplatePage = () => {
  const classes = useStyles(); // Importa tus estilos aquí
  const [t] = useTranslation("global");
  const dispatch = useDispatch();
  const categories = useSelector(
    (state: RootStore) => state.template.categories
  );
  const sets = useSelector((state: RootStore) => state.template.sets);
  const settingsState = useSelector((state: RootStore) => state.settings);

  const { loading } = settingsState;
  const [selectedCategory, setSelectedCategory] = useState<CategoryType | null>(null);
  const [selectedSet, setSelectedSet] = useState<SetType | null>(null)
  const [isSecondDropdownEnabled, setIsSecondDropdownEnabled] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const handleChangeCategory = (event: React.ChangeEvent<{}>, newValue: CategoryType | null) => {
    if(newValue === null) {
      setIsSecondDropdownEnabled(true);
    }
    if (newValue) {
      setIsSecondDropdownEnabled(false);
      setSelectedCategory(newValue);
      const categoryId = newValue.id.toString();
      dispatch(getSetsTCGbyCategory(categoryId))
      setIsSecondDropdownEnabled(true);
      setSelectedSet(null);
    } else {
      setSelectedCategory(null);
      setIsSecondDropdownEnabled(false);
    }
  };
  
  const handleChangeSet = (event: React.ChangeEvent<{}>, newValue: SetType | null) => {
    setSelectedSet(newValue); 
  };

  const handleDownloadTemplate = async () => {
  if(!selectedCategory || !selectedSet) return;
  
  setIsDownloading(true);
  await dispatch(getSetTemplate(selectedCategory.id, selectedSet.toString()));
  setIsDownloading(false);
};

  const isButtonDisabled = ()=> {
    if (loading !== '') return true;
    return false;
  };

  useEffect(() => {
    dispatch(getCategoriesTCG());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <div className={classes.boxTop}>
          <Typography variant="h4" color="primary">
            {t("nav.create-singles-template")}
          </Typography>
          <Typography>{t("settings.select-category-set")}</Typography>
        </div>
        <div>
          <form className={classes.boxForm}>
            <FormControl>
                {
                  <Autocomplete
                  id="category-tcg"
                  options={categories || []}
                  getOptionLabel={(option) => option.name}
                  value={selectedCategory}
                  onChange={handleChangeCategory}
                  onInputChange={() => setIsSecondDropdownEnabled(false)} 
                  
                  renderInput={(params) => <TextField {...params} label={t("tcgTemplate.category-label")} id="category-tcg-label" variant="standard"
                  />}
                  renderOption={(props, option) => <li style={{fontFamily: 'VisbyCF, sans-serif'}} {...props}>{option.name}</li>}
                  />
                }
            </FormControl>
            <FormControl className={classes.setDropdown}>
              <Autocomplete
                id="set-tcg"
                options={sets || []}
                getOptionLabel={(option) => option.toString()}
                value={selectedSet}
                onChange={handleChangeSet}
                renderInput={(params) => <TextField {...params} label={t("tcgTemplate.set-label")} id="set-tcg-label" variant="standard" />}
                renderOption={(props, option) => <li style={{ fontFamily: 'VisbyCF, sans-serif' }} {...props}>{option.toString()}</li>}
                disabled={!isSecondDropdownEnabled}
              />
            </FormControl>
          </form>
          <div className={classes.buttons}>
          <Button onClick={handleDownloadTemplate} variant="contained" color="primary" disabled={isButtonDisabled() || isDownloading }>
              {(isDownloading) ? t("items.processing") : t("tcgTemplate.generate")}
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default CreateSinglesTemplatePage;
