import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& .MuiToolbar-root':{
        [theme.breakpoints.down('xs')]: {
          flexWrap: 'wrap'
        },
      }
    },
    columnLogo: {
      width: '25%',
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        width: '15%',
      },
      [theme.breakpoints.down('sm')]:{
        width:'5%'
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        textAlign: 'center',
        gridColumnStart:'1',
        gridcolumnEnd:'2',
        gridRowStart:'1',
        marginTop:'5px',  
        alignSelf:'center',
        '& img': {  
          width: 'auto',
          height:'45px',
          maxHeight: '60px',
          }
      },
      '& img': {
          width: 'auto',
          maxHeight: '60px',
      }
    },
    columnSearch: {
      width: '50%',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        width: '60%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginTop:'2px',
        gridColumnStart:'1',
        gridColumnEnd:'9',
        gridRowStart:'2',
        alignSelf:'center'
      },
      '& .MuiFormControl-root': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
      },
      '& .MuiInputBase-root': {
        background: 'white',
        height: '40px',
        width: '403px',
        borderRadius: '5px',
        padding: '0 10px',
        position: 'relative',
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        }
      },
      '& .MuiInputBase-root:before': {
        display: 'none'
      },
      '& .MuiInputBase-root:after': {
        display: 'none'
      },
      '& .MuiIconButton-root': {
        height: '40px',
        position: 'absolute',
        right: 0,
        borderRadius: '0 5px 5px 0',
        color: theme.palette.primary.contrastText,
        background: process.env.REACT_APP_THEME_COLOR_PRIMARY_DARK,
        '&.Mui-disabled': {
          background: theme.palette.grey[600]
        }
      }
    },pointsTitle: {
      fontFamily: 'VisbyCF-Bold',
      fontSize: "16px",
      textAlign: 'center',
      textTransform:'uppercase',
      marginBottom: theme.spacing(2)
    },pointsNumber: {
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'center',
      fontSize: "25px",
      gap:'5px',
      '& div': {
        marginTop: '0px'
      }
    },
    pointsHeader:{
      display:'flex',
      flexDirection:'column',
      textDecoration:'none',
      color: 'inherit',
      marginRight:theme.spacing(2),
      minWidth:'110.47px',
      [theme.breakpoints.down('sm')]:{
        display:'none',
      },
      [theme.breakpoints.down('xs')]:{
        display:'none',
      }
    },
    columnInformation: {
      width: '25%',
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        width: '30%',
      },
      [theme.breakpoints.down('md')]: {
        width: '40%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        justifyContent: 'center',
        marginBottom: theme.spacing(1),    
        display:'grid',
        gridTemplateColumns:'repeat(6,1fr)',
        gridTemplateRows:'1fr',
        gridColumnStart:"1",
        gridColumnEnd:'7',
        gridRowStart:'1',
      },
      '& .MuiAvatar-root': {
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('xs')]:{
          marginRight:'0px'
        }
      }
    },
    shoppingCartIcon:{
      gridRowStart:'1',
      gridColumnStart:'5',
      gridColumnEnd:'5'
    },
    localShippingIcon:{
      gridRowStart:'1',
      gridColumnStart:'4',
      gridColumnEnd:'5'
    },
    storeFrontIcon:{
      gridRowStart:'1',
      gridColumnStart:'3',
      griidColumnEnd:'4'
    },
    Avatar:{
      display:'flex',
      justifyContent:'center',
      alignSelf:'center',
      gridRowStart:'1',
      gridColumnStart:'6',
    },
    savingsIcon:{
      gridColumnStart:'2',
      gridColumnEnd:'3'
    },
    mobileViewIcon:{
      [theme.breakpoints.down('xs')]:{
        marginTop:'14px'
      }
    },
    columnInformationText: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: theme.spacing(2),
      minWidth:'110.47px',
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.down(720)]: {
        display:'none',
    },
      [theme.breakpoints.down('xs')]: {
        display:"none"
      },
      '& .MuiLink-root': {
        fontWeight: '600'
      }
    },
    loginLink:{
      gridColumnStart:'3',
      gridRowStart:'1',
      minWidth:'80.16px',
      [theme.breakpoints.down(600)]:{
        marginLeft:'5rem',
        marginTop:'10px',
      },
      [theme.breakpoints.down(450)]:{
        marginTop:'10px',
        marginLeft:'3rem',
      },
      [theme.breakpoints.down(340)]:{
        marginLeft:'1rem',
        marginTop:'10px',
      }
    },
    appTitle: {
      flexGrow: 1,
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    Toolbar:{
      [theme.breakpoints.down('xs')]:{
        display:"grid",
        gridTemplateColumns:"repeat(6,1fr)",
        gridTemplateRows:"1fr 1fr",
        height:'150px'
      }
    },
    notificationPopover: {
      '& .MuiPopover-paper': {
        top: '73px !important',
        left: 'unset !important',
        right: '15px',
        '&:before': {
          content: 'p'
        }
      }
    },
    notifications: {
      '& .MuiList-root': {
        maxWidth: '450px',
      },
      '& .MuiListItem-root': {
        display: 'flex',
        flexWrap: 'wrap'
      },
      '& span': {
        backgroundColor: theme.palette.primary.main,
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        position: 'absolute',
        right: '20px',
        top: 'calc(50% - 5px)'
      }
    },
    nTitle: {
      width: '100%',
      maxWidth: '400px',
      fontFamily: 'VisbyCF-Bold',
      paddingRight: '15px'
    },
    nMessage: {
      width: '100%',
      maxWidth: '400px',
      fontSize: '12px',
      paddingRight: '15px'
    }
  }),
);