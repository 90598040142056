// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    xlsxFile: {

    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
    fileInput: {
      display: 'none'
    },
    loadingIcon: {
      width: '20px !important',
      height: '20px !important'
    },
    selectedFileWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      borderTop: `1px solid ${theme.palette.primary.main}`,
      padding: theme.spacing(2),
      margin: theme.spacing(2, 0),
      '& > span, & > svg, & > div, & > p': {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
      }
    },
    boldText: {
      fontFamily: 'VisbyCF-Bold'
    },
    backdrop: {
      zIndex: 1301,
      color: '#fff',
      '& .MuiPaper-root': {
        minWidth: '300px',
        padding: theme.spacing(2, 1),
        textAlign: 'center'
      }
    },
    modalPaper: {
      padding: theme.spacing(6, 4),
      position: 'absolute',
      width: '60%',
      height: '70%',
      top: 'calc(15%)',
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      overflowY: 'auto',
      [theme.breakpoints.down('md')]: {
        width: '80%'
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(6, 2),
        width: '90%',
        height: '80%',
      }
    },
    modalConfirmPaper: {
      width: '360px',
      height: 'auto',
      top: 'calc(40%)',
      textAlign: 'center'
    },
    closeModal: {
      position: 'absolute',
      right: '0',
      top: '0',
      [theme.breakpoints.down('xs')]: {
        top: '10px'
      },
    },
    itemsContainer: {
      display: 'block',
      maxHeight: '400px',
      overflowY: 'auto',
      margin: 0,
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        maxHeight: '250px',
      },
    },
    uploadItems: {
      '& .MuiTypography-root': {
        margin: theme.spacing(0, 1)
      }
    },
    buttonsContainer: {
      textAlign: 'center',
      marginTop: theme.spacing(4),
      '& .MuiButtonBase-root': {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1)
      }
    },
    checkbox: {
      '& span.MuiButtonBase-root': {
        marginLeft: 0
      },
      '& span.MuiTypography-root': {
        marginBottom: '2px'
      }
    },
    extraPadding: {
      padding: '13px 0 !important'
    }
  }),
);

export default useStyles;
