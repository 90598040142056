// React.
import { Fragment, useState } from "react"

// Components.
import ImageAvatar from "../../admin/utils/ImageAvatar"
import NumberFormat from "../../admin/utils/NumberFormat"
import StatusStepper from "../utils/StatusStepper"

// Material UI.
import Avatar from "@material-ui/core/Avatar"
import Chip from "@material-ui/core/Chip"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

// Moment.
import Moment from "moment"

// Translations.
import { useTranslation } from "react-i18next"

// Global Types.
import {
  OrderBundleType,
  OrderItemType,
  OrderType,
} from "../../admin/models/ModelTypes"

// Styles.
import { Button, ButtonBase, Input } from "@material-ui/core"
import { DeleteForever } from "@mui/icons-material"
import EditIcon from "@mui/icons-material/Edit"
import { useDispatch } from "react-redux"
import { updateOrderPickupId } from "../../../actions/orderActions/OrderActions"
import defaultImage from "../../../images/default-img.jpg"
import TextMaskIdentification from "../../admin/utils/TextMaskIdentification"
import useStyles from "./OrderRowStyles"

// Component interface.
interface OrderEditProps {
  order: OrderType
}

const OrderRow = ({ order }: OrderEditProps) => {
  const classes = useStyles()
  const dispatch = useDispatch();
  const [t] = useTranslation("global")

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [selectedPickUpId, setSelectedPickUpId] = useState<string>(
    order.pickupId
  )
  const [identificationPickUpError, setIdentificationPickUpError] =
    useState<boolean>(false)
  // Define local variables.
  const trackingNumber = order.trackingNumber ? order.trackingNumber : ""
  const isItemRefund = (id: number) => {
    var result = false
    // Check for existing refunds.
    const refundItemsPrevious = order.refundItems
    for (var i = 0; i < refundItemsPrevious.length; i++) {
      if (refundItemsPrevious[i].item.id === id) {
        result = true
      }
    }
    return result
  }

  const isBundleRefund = (id: number) => {
    var result = false
    // Check for existing refunds.
    const refundBundlesPrevious = order?.refundBundles!
    for (var i = 0; i < refundBundlesPrevious.length; i++) {
      if (refundBundlesPrevious[i].bundle.id === id) {
        result = true
      }
    }
    return result
  }

   const handleChangeIdentificationPickUp = (
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    const newValue = String(e.target.value)
    setSelectedPickUpId(newValue)

    const isIdentificationFormat = /^[0-9\s]+$/.test(newValue)
    if (newValue === "" || !isIdentificationFormat) {
      setIdentificationPickUpError(true)
    } else {
      setIdentificationPickUpError(false)
    }
  }

  const handleUpdatePickupId = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    if (selectedPickUpId !== "") {
        dispatch(
          updateOrderPickupId(
            order.id,
            selectedPickUpId,
          )
        )
       setIsEditing(false)
    } else {
      setIdentificationPickUpError(true)
    }
  }

  const handleDeletePickupId = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setSelectedPickUpId("")
    dispatch(
      updateOrderPickupId(
        order.id,
        "",
      )
    )
  } 

  const getRefundDifferenceQuantity = (id: number) => {
    var refundNumber = 0
    // Check for existing refunds.
    const refundItemsPrevious = order.refundItems
    for (var i = 0; i < refundItemsPrevious.length; i++) {
      if (refundItemsPrevious[i].item.id === id) {
        refundNumber = refundItemsPrevious[i].normal_quantity
      }
    }
    return refundNumber
  }

  const getRefundBundleDifferenceQuantity = (id: number) => {
    var refundNumber = 0
    // Check for existing refunds.
    const refundBundlePrevious = order?.refundBundles!
    for (var i = 0; i < refundBundlePrevious.length; i++) {
      if (refundBundlePrevious[i].bundle.id === id) {
        refundNumber = refundBundlePrevious[i].quantity
      }
    }
    return refundNumber
  }

  const getItemImage = (orderItem: any) => {
    var image = defaultImage
    if (orderItem.images[0] !== undefined) {
      image = orderItem.images[0].url
    }
    return image
  }

  const getLangCode = (langName: string) => {
    switch (langName) {
      case "English":
        return "EN"
      case "Spanish":
        return "SP"
      default:
        return langName
    }
  }

  const renderCustomReferences = (references: string) => {
    var tags: string[] = []
    const firstArray = references.split("-")
    firstArray.forEach((firstValue) => {
      tags.push(firstValue)
    })
    let lastPos = tags.length - 1
    return (
      <Fragment>
        <Chip
          label={`${tags[1]} ${tags[2] !== tags[lastPos] ? tags[2] : ""}`}
          size="small"
          avatar={<Avatar>{tags[0]}</Avatar>}
          className={classes.chip}
        />
        <Chip
          label={getLangCode(tags[lastPos])}
          variant="outlined"
          size="small"
          className={classes.chip}
        />
      </Fragment>
    )
  }

  const getNewRefundQauntity = (itemId: number, orginalQuantity: number) => {
    var refundNumber = 0
    // Check for existing refunds.
    const refundItemsPrevious = order.refundItems
    for (var i = 0; i < refundItemsPrevious.length; i++) {
      if (refundItemsPrevious[i].item.id === itemId) {
        refundNumber = orginalQuantity - refundItemsPrevious[i].normal_quantity
      }
    }
    return refundNumber
  }

  const getNewRefundBundleQauntity = (
    bundleId: number,
    orginalQuantity: number
  ) => {
    var refundNumber = 0
    // Check for existing refunds.
    const refundBundlesPrevious = order?.refundBundles!
    for (var i = 0; i < refundBundlesPrevious.length; i++) {
      if (refundBundlesPrevious[i].bundle.id === bundleId) {
        refundNumber = orginalQuantity - refundBundlesPrevious[i].quantity
      }
    }
    return refundNumber
  }

  const getBundlePrice = (orderBundle: OrderBundleType) => {
    var bundlePrice =
      orderBundle.price + (orderBundle.price * orderBundle.tax) / 100
    return bundlePrice
  }

  const getOrderShippingAddress = (order: OrderType) => {
    var address = order.address.province
    address += `, ${order.address.canton}`
    address += `, ${order.address.district}`
    address += `, ${order.address.fullAddress}`

    return address
  }

  const getSinglePrice = (orderItem: OrderItemType) => {
    let singlePrice =
      orderItem.normal_price +
      orderItem.normal_price * orderItem.administrativeCost -
      orderItem.normal_price * orderItem.conditionCost
    let roundedPrice = Math.ceil(singlePrice / 50) * 50
    return roundedPrice
  }

  const findNumberonHtml = (html: string) => {
    // Trun string to HTML object.
    var htmlObject = document.createElement("div")
    htmlObject.innerHTML = html

    // Find the element which contains the number.
    var match: string = ""
    for (var i = 0; i < htmlObject.querySelectorAll("span").length; i++) {
      const span = htmlObject.querySelectorAll("span")[i]
      if (span?.textContent?.includes("Number")) {
        match = span.innerHTML
        break
      }
    }
    return match
  }

  const getStepsList = () => {
    if (order.status) {
      var resultArr: string[] = []

      // For orders with "Recoger en Tienda - Inmediato"
      if (order.shipping.id === 1) {
        resultArr = [
          "Orden Recibida",
          "Procesando",
          "Listo para Recoger",
          "Completado",
        ]
      }

      // For orders with "Correos de Costa Rica"
      if (order.shipping.id === 2) {
        resultArr = ["Orden Recibida", "Procesando", "Enviado", "Completado"]
      }

      // For orders with "Pago Pendiente" status.
      if (order.status === "Pago Pendiente") {
        resultArr[0] = "Pago Pendiente"
      }

      // For orders with "Cancelado" status.
      if (order.status === "Cancelado") {
        resultArr = ["Cancelado"]
      }
      return resultArr
    }

    return []
  }

  const getStatusIndex = () => {
    let statusList = getStepsList()
    const index = statusList.findIndex((status) => status === order.status)
    if (index) return index
    return 0
  }

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.orderStatus}>
          <Typography variant="body1" color="primary">
            {t("orders.tracing")}
          </Typography>
          <Typography variant="caption">
            {t("orders.last-update")}:{" "}
            {Moment(order.updatedDate).format("MM/DD/YYYY")}
          </Typography>
          {order.status && (
            <StatusStepper
              steps={getStepsList()}
              currentStep={getStatusIndex()}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={8}>
          {order?.orderBundleItems?.map((orderItem, index) => (
            <Fragment key={index}>
              {orderItem.quantity > 0 && (
                <div className={classes.itemCard} key={orderItem.bundle.id}>
                  <Grid container className={classes.leftColumn}>
                    <Grid item xs={3} sm={3} md={3}>
                      <ImageAvatar
                        src={getItemImage(orderItem.bundle)}
                        alt="Bundle main image."
                      />
                    </Grid>
                    <Grid item xs={9} sm={9} md={9}>
                      <Typography variant="caption" color="textSecondary">
                        {t("orders.item-name")}{" "}
                        <span className={classes.type}>
                          {t("bundles.bundle")}
                        </span>
                      </Typography>
                      <Typography>{orderItem.bundle.name}</Typography>
                      {isBundleRefund(orderItem.bundle.id) && (
                        <Typography variant="caption" color="secondary">
                          {t("orders.refund-message", {
                            refundNumber: getRefundBundleDifferenceQuantity(
                              orderItem.bundle.id
                            ),
                          })}
                        </Typography>
                      )}
                      <div className={classes.secondaryInfo}>
                        <div className="o-info">
                          <Typography variant="caption" color="textSecondary">
                            {t("orders.add-item-quantity")}
                          </Typography>
                          <Typography>
                            {isBundleRefund(orderItem.bundle.id)
                              ? getNewRefundBundleQauntity(
                                  orderItem.bundle.id,
                                  orderItem.quantity
                                )
                              : orderItem.quantity}
                          </Typography>
                        </div>
                        <div className="o-info">
                          <Typography variant="caption" color="textSecondary">
                            {t("orders.item-price")}
                          </Typography>
                          <Typography>
                            {order.currency.symbol}
                            {
                              <NumberFormat
                                number={getBundlePrice(orderItem)}
                              />
                            }
                          </Typography>
                        </div>
                        <div className="o-info">
                          <Typography variant="caption" color="textSecondary">
                            {t("orders.seller")}
                          </Typography>
                          <Typography>{order.vendor.name}</Typography>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Fragment>
          ))}
          {order.orderItems.map((orderItem, index) => (
            <Fragment key={index}>
              {orderItem.normal_quantity > 0 && (
                <div className={classes.itemCard} key={orderItem.item.id}>
                  <Grid container className={classes.leftColumn}>
                    <Grid item xs={3} sm={3} md={3}>
                      <ImageAvatar
                        src={getItemImage(orderItem.item)}
                        alt="Item main image."
                      />
                    </Grid>
                    <Grid item xs={9} sm={9} md={9}>
                      <Typography variant="caption" color="textSecondary">
                        {t("orders.item-name")}
                      </Typography>
                      <Typography>{orderItem.item.name}</Typography>
                      {orderItem.item.category === "Single" && (
                        <div>
                          {renderCustomReferences(orderItem.id.customReference)}
                        </div>
                      )}
                      <Typography variant="caption" color="textSecondary">
                        {t("orders.item-code")}
                      </Typography>
                      <Typography>{orderItem.item.code}</Typography>
                      {isItemRefund(orderItem.item.id) && (
                        <Typography variant="caption" color="secondary">
                          {t("orders.refund-message", {
                            refundNumber: getRefundDifferenceQuantity(
                              orderItem.item.id
                            ),
                          })}
                        </Typography>
                      )}
                      {orderItem.item.category === "Single" && (
                        <div>
                          {findNumberonHtml(
                            orderItem.item?.additionalInfo!
                          ) && (
                            <Typography variant="caption" color="textSecondary">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: findNumberonHtml(
                                    orderItem.item?.additionalInfo!
                                  ),
                                }}
                              ></div>
                            </Typography>
                          )}
                          <Typography variant="caption" color="textSecondary">
                            <strong>{t("items.labels")}: </strong>
                            <br />
                          </Typography>
                          <Typography>
                            {orderItem.item?.labelValues?.map(
                              (labelValue, index) => (
                                <span key={labelValue.id}>
                                  {index !== 0 ? ", " : ""}
                                  {labelValue.value}
                                </span>
                              )
                            )}
                          </Typography>
                        </div>
                      )}
                      <div className={classes.secondaryInfo}>
                        <div className="o-info">
                          <Typography variant="caption" color="textSecondary">
                            {t("orders.add-item-quantity")}
                          </Typography>
                          <Typography>
                            {isItemRefund(orderItem.item.id)
                              ? getNewRefundQauntity(
                                  orderItem.item.id,
                                  orderItem.normal_quantity
                                )
                              : orderItem.normal_quantity}
                          </Typography>
                        </div>
                        <div className="o-info">
                          <Typography variant="caption" color="textSecondary">
                            {t("orders.item-price")}
                          </Typography>
                          <Typography>
                            {order.currency.symbol}
                            {
                              <NumberFormat
                                number={
                                  orderItem.item.category === "Single"
                                    ? getSinglePrice(orderItem)
                                    : orderItem.item.priceWithTaxes
                                }
                              />
                            }
                          </Typography>
                        </div>
                        <div className="o-info">
                          <Typography variant="caption" color="textSecondary">
                            {t("orders.seller")}
                          </Typography>
                          <Typography>{order.vendor.name}</Typography>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
              {orderItem.discount_quantity > 0 && (
                <div className={classes.itemCard} key={orderItem.item.id}>
                  <Grid container className={classes.leftColumn}>
                    <Grid item xs={3} sm={3} md={3}>
                      <ImageAvatar
                        src={getItemImage(orderItem.item)}
                        alt="Item main image."
                      />
                    </Grid>
                    <Grid item xs={9} sm={9} md={9}>
                      <Typography variant="caption" color="textSecondary">
                        {t("orders.item-name")}
                      </Typography>
                      <Typography>{orderItem.item.name}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {t("orders.item-code")}
                      </Typography>
                      <Typography>{orderItem.item.code}</Typography>
                      {isItemRefund(orderItem.item.id) && (
                        <Typography variant="caption" color="secondary">
                          {t("orders.refund-message", {
                            refundNumber: getRefundDifferenceQuantity(
                              orderItem.item.id
                            ),
                          })}
                        </Typography>
                      )}
                      <div className={classes.secondaryInfo}>
                        <div className="o-info">
                          <Typography variant="caption" color="textSecondary">
                            {t("orders.add-item-quantity")}
                          </Typography>
                          <Typography>
                            {isItemRefund(orderItem.item.id)
                              ? getNewRefundQauntity(
                                  orderItem.item.id,
                                  orderItem.discount_quantity
                                )
                              : orderItem.discount_quantity}
                          </Typography>
                        </div>
                        <div className="o-info">
                          <Typography variant="caption" color="textSecondary">
                            {t("orders.item-price")}
                          </Typography>
                          <Typography className={classes.discountLabel}>
                            {order.currency.symbol}
                            {
                              <NumberFormat
                                number={orderItem.item.priceWithTaxes}
                              />
                            }
                          </Typography>
                          <Typography>
                            {order.currency.symbol}
                            {
                              <NumberFormat
                                number={
                                  orderItem.item.discountPriceWithTaxes !==
                                  undefined
                                    ? orderItem.item.discountPriceWithTaxes
                                    : 0
                                }
                              />
                            }
                          </Typography>
                        </div>
                        <div className="o-info">
                          <Typography variant="caption" color="textSecondary">
                            {t("orders.seller")}
                          </Typography>
                          <Typography>{order.vendor.name}</Typography>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Fragment>
          ))}
        </Grid>
        <Grid item xs={12} sm={4} className={classes.rightColumn}>
          <Typography variant="body1" color="primary">
            {t("orders.tracking-details")}
          </Typography>
          <div>
            <Typography variant="caption" color="textSecondary">
              {t("orders.delivery-method")}
            </Typography>
            <Typography>
              {order.shipping.name} - {order.shipping.description}
            </Typography>
            {order.shipping.id === 1 && (
              <Fragment>
                <Typography variant="caption" color="textSecondary">
                  {t("orders.pickup-at")}
                </Typography>
                <Typography>{order.addressName}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {t("orders.store-address")}
                </Typography>
                <Typography>{getOrderShippingAddress(order)}</Typography>
              </Fragment>
            )}
            {order.shipping.id === 2 && (
              <Fragment>
                <Typography variant="caption" color="textSecondary">
                  {t("orders.send-to")}
                </Typography>
                <Typography>{order.addressName}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {t("orders.shipping-address")}
                </Typography>
                <Typography>{getOrderShippingAddress(order)}</Typography>
              </Fragment>
            )}
            <Typography variant="caption" color="textSecondary">
              {t("orders.pin-code")}
            </Typography>
            <Typography>
                {order.pin}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {t("orders.tracking-number")}
            </Typography>
            <Typography>
              {trackingNumber ? trackingNumber : t("orders.not-specified")}
            </Typography>
            { <div className={classes.pickupIdContainer}>
              <div>
                <Typography variant="caption" color="textSecondary">
                  {t("orders.pickUpId")}
                </Typography>
                {isEditing ? (
                  <div className={classes.identificationPickUpContainer}>
                    <Input
                      id="identificationPickUp"
                      name="identificationPickUp"
                      value={selectedPickUpId}
                      onChange={handleChangeIdentificationPickUp}
                      inputComponent={TextMaskIdentification as any}
                      error={identificationPickUpError}
                    />
                  </div>
                ) : (
                  <Typography>
                    {selectedPickUpId !== ""
                      ? selectedPickUpId
                      : t("orders.not-specified")}
                  </Typography>
                )}
              </div>
              <div className={classes.editableIcons}>
                {isEditing ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={e => handleUpdatePickupId(e)}
                    size="small"
                  >
                    {t("players.update")}{" "}
                  </Button>
                ) : order.status !== "Completado" ? (
                  <>
                    <ButtonBase
                      onClick={ e => {
                        e.preventDefault()
                        setIsEditing(!isEditing)
                      }}
                    >
                      <Typography color="primary">
                        <EditIcon fontSize="large"/>
                      </Typography>
                    </ButtonBase>
                    <ButtonBase onClick={e => {handleDeletePickupId(e)}}>
                      <Typography color="primary">
                        <DeleteForever fontSize="large" />
                      </Typography>
                    </ButtonBase>
                  </>
                ) : <></>}
              </div>
            </div>}
          </div>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default OrderRow
