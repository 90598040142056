import axios from "axios"
import { Dispatch } from "redux"

import { ORDER_FAIL, ORDER_LOADING, OrderDispatchTypes, UPDATE_ORDER } from "../orderActions/OrderActionsTypes"
import {
  DELIVERY_FAIL,
  DELIVERY_LIST,
  DELIVERY_LOADING,
  DELIVERY_REFRESH,
  DELIVERY_SET_FILTER,
  DeliveryDispatchTypes,
} from "./DeliveryActionsTypes"

const apiURL: string | undefined = process.env.REACT_APP_API_URL

/**
 * Get a list of user deliveries from the BE, it can be filtered with date.
 * Get a list if the user is sender o receiver.
 * @param {object|null} qParams
 */
export const getUserDeliveries =
  (qParams: object | null, flag: number) =>
  async (dispatch: Dispatch<DeliveryDispatchTypes>) => {
    const userToken: string | undefined = localStorage.token
    if (apiURL !== "" && userToken !== "") {
      try {
        dispatch({
          type: DELIVERY_LOADING,
          payload: "LIST",
        })

        const headers: object = {
          ContentType: "application/json",
          Authorization: "Bearer " + userToken,
        }

        const res = await axios.get(
          `${apiURL}/user/getPackages/${flag === 0 ? true : false}`,
          {
            params: qParams,
            headers: headers,
          }
        )

        if (res.status === 200) {
          const queryData = res.data.data.content
          const deliveries: any = []

          for (var i = 0; i < queryData.length; i++) {
            const delivery = queryData[i]

            deliveries.push({
              id: delivery.id,
              packageCode: delivery.packageCode,
              clientUserReceiverFullName: delivery.clientUserReceiverFullName,
              clientUserReceiverEmail: delivery.clientUserReceiverEmail,
              clientUserSenderFullName: delivery.clientUserSenderFullName,
              clientUserSenderEmail: delivery.clientUserSenderEmail,
              status: delivery.status,
              price: delivery.price,
              description: delivery.description,
              locationOriginName: delivery.locationOriginName,
              locationDeliveryName: delivery.locationDeliveryName,
              customAddress: delivery.customAddress,
              weight: delivery.weight,
              dimensions: delivery.dimensions,
              creationDate: delivery.creationDate,
              pickupId: delivery.pickupId,
              deliverCode: delivery.deliverCode,
              refundStatus: delivery.refundStatus,
              refundCode: delivery.refundCode,
            })
          }

          const data: any = {
            first: res.data.data.first,
            last: res.data.data.last,
            number: res.data.data.number,
            size: res.data.data.size,
            totalPages: res.data.data.totalPages,
            deliveries: deliveries,
          }

          dispatch({
            type: DELIVERY_LIST,
            payload: data,
          })
        } else {
          dispatch({
            type: DELIVERY_FAIL,
            payload: "status.get-error",
          })
        }
      } catch (e) {
        dispatch({
          type: DELIVERY_FAIL,
          payload: "status.get-error",
        })
      }
    } else {
      dispatch({
        type: DELIVERY_FAIL,
        payload: "status.get-error",
      })
    }
  }
/**
 * Update the delivery package
 * 
 */
export const updatePackage = (packageId: number, pickupId: string) => async (dispatch: Dispatch<OrderDispatchTypes>) => {
  const userToken: string | undefined = localStorage.token
  if (apiURL !== "" && userToken !== "") {
    try {
      dispatch({
        type: ORDER_LOADING,
        payload: "UPDATE_DELIVERY",
      })

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + userToken,
          'Content-Type': 'application/json',
        }
      };

      const data = {
        packageId: packageId,
        pickupId: pickupId
      }
      const res = await axios.put(`${apiURL}/user/updatePackage`,data,configPUT)
      if (res.status === 200) {
        dispatch({
          type: UPDATE_ORDER,
          payload: 'status.success-update'
        })
      }
    } catch (e) {
      dispatch({
        type: ORDER_FAIL,
        payload: "status.error-update",
      })
    }
  } else {
    dispatch({
      type: ORDER_FAIL,
      payload: "status.error-update",
    })
  }
}

export const refundPackage = (packageId: number) => async (dispatch: Dispatch<OrderDispatchTypes>) => {
  const userToken: string | undefined = localStorage.token
  if (apiURL !== "" && userToken !== "") {
    try {
      dispatch({
        type: ORDER_LOADING,
        payload: "UPDATE_DELIVERY",
      })

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + userToken,
          'Content-Type': 'application/json',
        }
      };

      const data = {
        packageId: packageId,
      }
      const res = await axios.put(`${apiURL}/user/refundPackage`,data,configPUT)
      if (res.status === 200) {
        dispatch({
          type: UPDATE_ORDER,
          payload: 'status.success-update'
        })
      }
    } catch (e) {
      dispatch({
        type: ORDER_FAIL,
        payload: "status.error-update",
      })
    }
  } else {
    dispatch({
      type: ORDER_FAIL,
      payload: "status.error-update",
    })
  }
}


/**
 * Update the delivery view filters state.
 * @param {object} filters
 */
export const deliverySetFilter =
  (filters: object) => async (dispatch: Dispatch<DeliveryDispatchTypes>) => {
    dispatch({
      type: DELIVERY_SET_FILTER,
      payload: filters,
    })
  }

/**
 * Update the refresh delivery view state.
 * @param {boolean} value
 */
export const refreshDeliveryView =
  (value: boolean) => async (dispatch: Dispatch<DeliveryDispatchTypes>) => {
    dispatch({
      type: DELIVERY_REFRESH,
      payload: value,
    })
}
