// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(2, 1),
        padding: theme.spacing(4, 4, 1),
        width: '100%',
        height: 'auto',
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(4, 2, 1),
        },
      },
    },
    boxTop: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      margin:'0',
      padding:'0',
      marginTop:'20px',
      marginLeft:'20px',
      '& > *': {
        marginRight: theme.spacing(2),
      },
    },
    selectItem:{
      width:'200px'
    },
    boxBottom:{
      margin:'0',
      padding:'0',
      display:'flex',
      justifyContent:'space-around'
    },
    boxForm: {
      width:'70%',
      '& > *': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%'
      },
    },
    fieldClass: {
      marginRight: theme.spacing(2),
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
    itemInfo:{
      margin:'0',
      padding:'0',
      display:'flex',
      flexDirection:'column',
      width:'100%',
    },
    prizeItem:{
        display:'flex',
        flexDirection:'row',
        width:'100%',
    },
    header:{
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        padding: '4px 4px',
        backgroundColor: theme.palette.grey[600],
        color: 'white',
        fontFamily: 'VisbyCF-Bold',
        borderRadius: '6px',
        textAlign: 'center'
    },
    formControl: {
      width: '100%',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems:'flex-end',
      alignSelf:'center',
      height:'60px',
      width:'20%',
      margin:'0',
      padding:'0',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    buttonsLast: {
      marginRight: 0
    }
  }),
);

export default useStyles;
