import { useTranslation } from "react-i18next";
import useStyles from "./LookerStyles";

const lookerIframeSrc = process.env.REACT_APP_LOOKER_LINK
export const Looker = () => {
  const [t] = useTranslation('global');
  const classes = useStyles();
  
  const pageHeight = window.innerHeight;
  const pageWidth = window.innerWidth - 50;

  if ( !lookerIframeSrc || lookerIframeSrc === "" ) {
    return (
      <div>
        <h1>{t('looker.page-title')}</h1>
        <p>{t('looker.no-looker-url')}</p>
      </div>
    );
  }

  return <>
    
    <div className={classes.container}>
      <h1>{t('looker.page-title')}</h1>
      <iframe
        title={t('looker.page-title')}
        width={pageWidth}
        height={pageHeight}
        src={lookerIframeSrc}
        style={{border:0}}
        allowFullScreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      ></iframe>  
    </div>
  </>;
}