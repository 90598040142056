// React.
import React from 'react';

// Components.

import TournamentSearch from '../events/tournamentSearch';
import TournamentList from '../events/tournamentList';

const Tournament = () => {
  return (
    <div>
      <TournamentSearch/>
      <TournamentList/>
    </div>
  )
}

export default Tournament;
