import React, { useEffect, useRef, useState } from 'react'

interface CodeScannerProps {
  onCodeScan: (barcode: string) => void;
  stopInterval?: boolean;
}

export const CodeScanner = (props:CodeScannerProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [barcode, setBarcode] = useState('');
  const [lastKeypressTime, setLastKeypressTime] = useState(0);

  useEffect(() => {
      inputRef.current?.focus();
  }, []);

  useEffect(() => {

    if (props.stopInterval) {
      return;
    }

    const inputElement = inputRef.current;

    // Function to refocus the input
    const ensureFocus = () => {
        if (document.activeElement !== inputElement) {
            inputElement?.focus();
        }
    };

    // Continuously ensure the input is focused
    const intervalId = setInterval(ensureFocus, 100);

    return () => {
        clearInterval(intervalId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScan = () => {
      if (barcode !== '') {
        // use onCodeScan to pass the barcode to the parent component
        props.onCodeScan(barcode);
        setBarcode(''); // Clear the barcode state
        inputRef.current!.value = ''; // Clear the input field
      }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      const currentTime = new Date().getTime();
      // Check if the keypress is likely from a scanner (e.g., less than 50ms since last keypress)
      const newBarcode = event.key;
      setBarcode(barcode + newBarcode);
      if (currentTime - lastKeypressTime < 60) {
          // Check for a terminator key (e.g., Enter key) and handle the scan
          if ( event.key.toLocaleLowerCase().includes('enter') ) {
              handleScan();
          }
      }
      setLastKeypressTime(currentTime);
  };

  return <input 
      type="text" 
      ref={inputRef}
      onKeyDown={handleKeyPress} 
      style={{ opacity: 0, position: "absolute" }}
  />
}
