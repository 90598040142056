import { 
  PRIZE_FAIL, 
  PRIZE_LOADING, 
  PRIZE_EVENT_DETAIL,
  PRIZE_EVENT_PLAYER_PRIZES,
  ADD_PRIZE_EVENT,
  UPDATE_PRIZE_EVENT,
  DELETE_PRIZE_EVENT,
  MANAGE_PRIZE_TO_EVENT,
  PRIZE_ITEMS_FOR_PRIZE,
  EVENT_REGISTER_PRIZE_TO_PLAYER, 
  ItemType,
  Awards,
  PrizeType,
  PrizeDispatchTypes,
  EVENT_GET_ALL_PRIZES,
  EVENT_GET_ALL_PRIZES_CATEGORIES,
  itemPrizeType,
  PrizeCategory,
} from '../actions/prizeActions/PrizeActionsTypes';

// Default state interface.
interface DefaultStateI {
  loading: string,
  refresh?: boolean,
  eventPrizeDetails?: Awards,
  eventPlayerPrizes?: PrizeType[],
  itemsForPrize?: ItemType[],
  actionStatus?: any,
  prizes?:itemPrizeType[],
  prizesCategories?:PrizeCategory[]
}

// Default state.
const defaultState: DefaultStateI = {
  loading: '',
};

const prizeReducer = (state: DefaultStateI = defaultState, action: PrizeDispatchTypes) : DefaultStateI => {
  switch (action.type) {
    case PRIZE_FAIL:
      return {
        ...state,
        loading: '',
        actionStatus: {message: action.payload, status: 'error'}
      }
    case PRIZE_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case EVENT_GET_ALL_PRIZES:
      return {
        ...state,
        loading:'',
        prizes:action.payload
      }  
    case PRIZE_EVENT_DETAIL:
      return {
        ...state,
        loading: '',
        eventPrizeDetails: action.payload
      }
    case PRIZE_EVENT_PLAYER_PRIZES:
      return {
        ...state,
        loading: '',
        eventPlayerPrizes: action.payload
      }
    case ADD_PRIZE_EVENT:
      return {
        ...state,
        loading: '',
        actionStatus: {message: action.payload, status: 'success'}
      }
    case UPDATE_PRIZE_EVENT:
      return {
        ...state,
        loading: '',
        actionStatus: {message: action.payload, status: 'success'}
      }        
    case DELETE_PRIZE_EVENT: 
      return {
        ...state,
        loading: '',
        actionStatus: {message: action.payload, status: 'success'}
      }
    case MANAGE_PRIZE_TO_EVENT: 
      return {
        ...state,
        loading: '',
        actionStatus: {message: action.payload, status: 'success'}
      }
    case PRIZE_ITEMS_FOR_PRIZE:
      return {
        ...state,
        loading: '',
        itemsForPrize: action.payload
      }
    case EVENT_REGISTER_PRIZE_TO_PLAYER:
      return {
        ...state,
        loading: '',
        actionStatus: {message: action.payload, status: 'success'}
      }
      case EVENT_GET_ALL_PRIZES_CATEGORIES:
        return{
          ...state,
          loading:'',
          prizesCategories:action.payload
        }       
    default:
      return state
  }
};


export default prizeReducer;