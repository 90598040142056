import {Dispatch} from "redux";
import axios from 'axios';
import {
  ITEM_TYPE_FAIL,
  ITEM_TYPE_LOADING,
  ITEM_TYPE_LIST,
  ITEM_TYPE_PUT,
  ITEM_TYPE_CREATE,
  ITEM_TYPE_UPDATE_PER_LABEL_VALUE,
  ITEM_TYPE_REFRESH,
  ItemTypeDispatchTypes} from "./ItemTypeActionsTypes";

const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;

/**
 * Gets all item categories from BE.
 *
 */
export const getItemTypes = (qParams?: object) => async (dispatch: Dispatch<ItemTypeDispatchTypes>) => {

  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: ITEM_TYPE_LOADING,
        payload: 'ITEM_TYPE_LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      const requestPath = !token ? `${apiURL}/guest/getAllItemType` : `${apiURL}/user/getAllItemType`;

      const res = await axios.get(requestPath, {
        params: qParams || { paged: false, unpaged: true },
        headers: headers
       });

       if (res.status === 200) {
          const queryData = res.data.data.content;
          const categories:any = [];
         

        for(var i = 0; i < queryData.length; i++) {
          const category = queryData[i];
        
          categories.push({
            id: category.id,
            taxPercentage: category.taxPercentage,
            name: category.name,
            image_url: category.image_url,
            description: category.description,
            isDeleted: category.isDeleted,
            isPublished: category.isPublished
          });
        }
  
        const data:any = {
          first: res.data.data.first,
          last: res.data.data.last,
          number: res.data.data.number,
          size: res.data.data.size,
          totalPages: res.data.data.totalPages,
          categories: categories
        };

        dispatch({
          type: ITEM_TYPE_LIST,
          payload: data
        })
       } else {
        dispatch({
          type: ITEM_TYPE_FAIL,
          payload: 'status.get-error'
        })
       }
    } catch(e) {
      dispatch({
        type: ITEM_TYPE_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: ITEM_TYPE_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Update a item type on BE.
 * @param {any} category
 */
 export const createItemType = (category: any) => async (dispatch: Dispatch<ItemTypeDispatchTypes>) => {
  try {
    dispatch({
      type: ITEM_TYPE_LOADING,
      payload: 'CREATE_TYPE'
    })

    const bodyForm = category;

    const configPUT = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    };

    let requestPath = `${apiURL}/admin/registerItemType`;
  
    const res = await axios.post(requestPath, bodyForm, configPUT);
    if (res.status === 200) {
      dispatch({
        type: ITEM_TYPE_CREATE,
        payload: 'status.success-saved'
      })
    } else {
      dispatch({
        type: ITEM_TYPE_FAIL,
        payload: 'status.error-create'
      })
    }

  } catch(e) {
    dispatch({
      type: ITEM_TYPE_FAIL,
      payload: 'status.error-create'
    })
  }
};

/**
 * Update a item type on BE.
 * @param {any} category
 */
 export const updateItemType = (category: any) => async (dispatch: Dispatch<ItemTypeDispatchTypes>) => {
  try {
    dispatch({
      type: ITEM_TYPE_LOADING,
      payload: 'UPDATE_TYPE'
    })

    const bodyForm = category;

    const configPUT = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    };

    let requestPath = `${apiURL}/admin/updateItemType`;
  
    const res = await axios.put(requestPath, bodyForm, configPUT);
    if (res.status === 200) {
      dispatch({
        type: ITEM_TYPE_PUT,
        payload: 'status.success-saved'
      })
    } else {
      dispatch({
        type: ITEM_TYPE_FAIL,
        payload: 'status.error-update'
      })
    }

  } catch(e) {
    dispatch({
      type: ITEM_TYPE_FAIL,
      payload: 'status.error-update'
    })
  }
};

/**
 * Update in bulk all items by label id.
 * @param { string } labelValueId
 * @param { string } process
 */

export const updateItemPerLabelValue = (labelValueId: string, process: string) => async (dispatch:Dispatch<ItemTypeDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: ITEM_TYPE_LOADING,
        payload: 'ITEM_TYPE_UPDATE_PER_LABEL_VALUE'
      });

      const bodyForm = {
        labelValueId: labelValueId,
        process: process
      }
      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };

      const addRes = await axios.put(`${apiURL}/admin/massItemProcess`, bodyForm, configPUT);
      
      if (addRes.status === 200) {
        dispatch({
          type: ITEM_TYPE_UPDATE_PER_LABEL_VALUE,
          payload: 'status.success-update'
        });
      }

    } catch(error: any) {
      if (error.response && error.response.data.status_code === 422) {
				//Showing the error message when something went wrong during the BE validation.
				dispatch({
					type: ITEM_TYPE_FAIL,
					payload: error.response.data.message,
				});
			} else {
        dispatch({
          type: ITEM_TYPE_FAIL,
          payload: 'status.error-update'
        });
      }
    }

  } else {
    dispatch({
      type: ITEM_TYPE_FAIL,
      payload: 'status.error-update'
    });
  }

}

/**
 * Update the refresh view state, when the value is true the view will refresh.
 * @param {boolean} value
 */
export const refreshItemTypeView = (value: boolean) => async (dispatch: Dispatch<ItemTypeDispatchTypes>) => {
  dispatch({
    type: ITEM_TYPE_REFRESH,
    payload: value
  })
}


