// React.
import React, { useState, Fragment} from 'react';

// Material UI.
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Tooltip from '@mui/material/Tooltip';


// Redux
import { useDispatch } from "react-redux";
import { addToWishList, addToCartFromWishList } from "../../../actions/wishlistActions/wishlistActions";

// Styles.
import useStyles from './ItemCartStyles';
import defaultImage from '../../../images/default-img.jpg'; 

// Translations.
import { useTranslation } from 'react-i18next';

interface ItemCartProps {
  wishlistItem: any;
  customReference: string;
};
const ItemSingleWishList = ({ wishlistItem, customReference } : ItemCartProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();


  // Validate if the item is still available.
  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  }
  
  const addToCart = (code: string, availableToCart: boolean) => {
    if(!availableToCart)
      return
    dispatch(addToCartFromWishList(code));
    handleCloseModal();
  }
  
  const deleteWishlistItem = (code: string, customReference: string) => {
    dispatch(addToWishList(code, customReference, true));//send flag for delelte element
    handleCloseModal();
  };

  const renderCustomReferences = (references: string) => {
    var tags: string[] = [];
    const firstArray = references.split('-');
    firstArray.forEach((firstValue) => {
      tags.push(firstValue);
    });
    let lastPos = tags.length - 1;
    return <Fragment>
      <Chip label={`${tags[1]} ${(tags[2] !== tags[lastPos]) ? tags[2] : ''}`} size="small" avatar={<Avatar>{tags[0]}</Avatar>} className={classes.chip} />
      <Chip label={getLangCode(tags[lastPos])} variant="outlined" size="small" className={classes.chip} />
    </Fragment>;
  };

  const renderDetailsSingle = (details: string) => {
      if (details === '') {
        return 
      }
      let itemsArray = details.split(',')
      if(itemsArray.length > 4){
        itemsArray = itemsArray.slice(0, 4);
      }
      const itemsObjects:any = []

      itemsArray.forEach(item => {
        let wordsItem = item.split('-');
        if(wordsItem.length > 0){
          itemsObjects.push({
              key: wordsItem[0],
              value: wordsItem[1]
          })
        }
      });
      return (
        <Fragment>
          {
            <div className={classes.customTable} aria-label="Variations table">
              {(itemsObjects?.map((item: any) =>
                <div className='bodyContent'>
                  <div className='header'>
                    <label>{item.key}</label>
                  </div>
                <label>{item.value}</label>
                </div>
              ))}
            </div>
          }
        </Fragment>
      )
  }

  const getLangCode = (langName: string) => {
    switch (langName) {
      case 'English':
        return 'EN'
      case 'Spanish':
        return 'SP';
      default:
        return langName;
    }
  };

  const getItemImage = () => {
    if (wishlistItem.imageUrl === undefined) {
      return defaultImage
    }
    return wishlistItem.imageUrl;
  };
  const getTooltipString = () => {
    return t('wishlist.no-stock-wishlist-tooltip')
  }
  return (
    <Fragment>
      {
        <Card className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={2} md={3} className={classes.colImg}>
              <Avatar variant="rounded" alt="" src={getItemImage()}/>
            </Grid>
            <Grid item xs={12} sm={5} md={5} className={classes.customPadding+" "+classes.wishlistNameContainer}>
              <Grid container spacing={3} className={classes.wishlistName}>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="caption" className={classes.wishlistNameLabel} color="textSecondary">{ wishlistItem.name}</Typography>
                  <div className={classes.wishlistCustomReference}>
                    {renderCustomReferences(customReference)}
                  </div>
                  <div>
                    {renderDetailsSingle(wishlistItem.additionalInfo)}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={2} className={classes.colButton}>
              {wishlistItem.availableToCart ? 
              <IconButton color="primary" aria-label="add item to wishlist" component="span" onClick={ () => addToCart(wishlistItem.itemCode, wishlistItem.availableToCart) }
              className={!wishlistItem.availableToCart ? classes.disabledButton : ''}
              >
              <AddShoppingCartIcon color="secondary" className={`${classes.iconAction} ${classes.fontActionIcon}`}/>
            </IconButton> :
              <Tooltip title={getTooltipString()}>
                <IconButton color="primary" aria-label="add item to wishlist" component="span" onClick={ () => addToCart(wishlistItem.itemCode, wishlistItem.availableToCart) }
                  className={`${!wishlistItem.availableToCart ? classes.disabledButton : classes.iconAction}`}
                >
                  <AddShoppingCartIcon color="secondary" className={classes.fontActionIcon}/>
                </IconButton>
              </Tooltip>
}
            </Grid>
            <Grid item xs={6} sm={2} className={classes.colButton}>
              <IconButton color="primary" aria-label="Delete item from cart" component="span" onClick={ () => handleOpenModal() }>
                <DeleteIcon color="secondary" className={`${classes.iconAction} ${classes.fontActionIcon}`}/>
              </IconButton>
            </Grid>
          </Grid>
        </Card>
      }
      <Modal
        open={ openModal }
        onClose={ handleCloseModal }
        aria-labelledby="Confirm Modal"
        >
        <Paper elevation={3} className={ classes.modalPaper }>
          <IconButton className={ classes.closeModal } aria-label="close modal" component="span" onClick={ () => handleCloseModal() }>
            <CloseIcon/>
          </IconButton>
          <Typography>
            {t('cart.delete-confirm-text', { 'itemName': wishlistItem.name}) }
          </Typography>
          <Button variant="contained" color="primary" onClick={ () => handleCloseModal() }> { t('cart.no') } </Button>
          <Button variant="contained" color="secondary" onClick={() => deleteWishlistItem(wishlistItem.itemCode, wishlistItem.customReference) }> { t('cart.yes') } </Button>
        </Paper>
      </Modal>
  </Fragment>
  )
}
export default ItemSingleWishList;
