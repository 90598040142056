// React.
import React, { useEffect, useState, useLayoutEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

// Translations.
import { useTranslation } from 'react-i18next';

// Material UI.
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

// Styles.
import { useStyles } from './SearchSortStyles';

interface SearchSortProps {
  viewMode: string;
};

const SearchSort = ({ viewMode }: SearchSortProps) => {
  const classes = useStyles();
  const history = useHistory();
  const [t] = useTranslation('global');

  // Track the screen size changes.
  const useWindowSize = () => {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
      const updateSize = () => {
        setSize(window.innerWidth);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  let width = useWindowSize();

  useEffect(() => {
    return history.listen((location) => {
      const params = new URLSearchParams(window.location.search);
      var sortParam = params.get('sort');
      if (!sortParam) {
        setSort('default');
      }
    })
  }, [history]) 

  // Local state.
  const params = new URLSearchParams(window.location.search);
  var sortParam = params.get('sort');
  const [sort, setSort] = React.useState((sortParam) ? sortParam : 'default');

  const handleSortChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSort(event.target.value as string);
    setSortOnUrl(event.target.value as string);
  };

  const setSortOnUrl = (sort: string) => {
    const url = new URL(window.location.href);
    if (sort === 'default') {
      url.searchParams.delete('sort');
      window.history.replaceState(null, '', url); 
    } else {
      url.searchParams.set('sort', sort);
      window.history.replaceState(null, '', url); 
    }

    history.push({
      pathname: '/items',
      search: window.location.search,
    });
  };

  return (
    <Fragment>
      {(width < 960 && viewMode === 'mobile') &&
        <div className={classes.rootMobile}>
          <FormControl>
            <Select
              id="sort-select"
              value={sort}
              onChange={handleSortChange}
            >
              <MenuItem value={'default'}>{t('nav.relevance')}</MenuItem>
              <MenuItem value={'name,asc'}>{t('nav.name-asc')}</MenuItem>
              <MenuItem value={'name,desc'}>{t('nav.name-desc')}</MenuItem>
              <MenuItem value={'price,desc'}>{t('nav.price-desc')}</MenuItem>
              <MenuItem value={'price,asc'}>{t('nav.price-asc')}</MenuItem>
            </Select>
          </FormControl>
        </div>
      }
      {(width >= 960 && viewMode === 'desktop') && 
        <div className={classes.root}>
          <Typography variant="h5" component="h5" color="primary">
            {t('nav.sort-by')}
          </Typography>
          <FormControl>
            <Select
              labelId="sort-select-label"
              id="sort-select"
              value={sort}
              onChange={handleSortChange}
            >
              <MenuItem value={'default'}>{t('nav.relevance')}</MenuItem>
              <MenuItem value={'name,asc'}>{t('nav.name-asc')}</MenuItem>
              <MenuItem value={'name,desc'}>{t('nav.name-desc')}</MenuItem>
              <MenuItem value={'price,desc'}>{t('nav.price-desc')}</MenuItem>
              <MenuItem value={'price,asc'}>{t('nav.price-asc')}</MenuItem>
            </Select>
          </FormControl>
        </div>
      }
    </Fragment>
  );
}

export default SearchSort;
