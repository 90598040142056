// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        marginRight: theme.spacing(2),
        '&.name': {
          width: '30%',
          [theme.breakpoints.down('xs')]: {
            width: '100%',
          },
        },
        '&.description': {
          width: '40%',
          [theme.breakpoints.down('xs')]: {
            width: '100%',
          },
        },
        '&.tax': {
          width: '20%',
          [theme.breakpoints.down('xs')]: {
            width: '100%',
          },
        },
      },
      '& .MuiInputBase-root': {
        width: '100%'
      }
    },
    marginBottom: {
      marginBottom: theme.spacing(4)
    },
    categoryWrapper: {
      backgroundColor: process.env.REACT_APP_THEME_COLOR_EXTRA_LIGHT_GRAY,
      borderRadius: '4px',
      padding: '10px 24px 33px',
      marginBottom: theme.spacing(2)
    },
    actionsWrapper: {
      marginTop: theme.spacing(10),
      textAlign: 'right'
    }
  }),
);

export default useStyles;
