// React.
import React, { useRef, useState, Fragment } from 'react';
import { useReactToPrint } from 'react-to-print';

// Material UI
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import NumberFormat from '../utils/NumberFormat';

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './OrderDetailPrintableStyles';

// Global Types.
import { OrderType, OrderItemType } from '../models/ModelTypes';

// Images.
import logoImg from '../../../logo-completo.png';

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

// Component interface.
interface OrderEditProps {
  order: OrderType;
};

const OrderDetailPrintable = ({ order }: OrderEditProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `ORDER_${order.orderCode}`,
    pageStyle: `
      @media print {
        html, body {
          -webkit-print-color-adjust: exact;
        }
      }
      @page {
        size: auto;
        margin: 10mm 5mm;
      }`, 
    onAfterPrint: () => {
      handleCloseModal();
    }
  });

  // Local state.
  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const startPrintProcess = () => {
    setOpenModal(true);

    // Wait until the popup is open.
    setTimeout(function () {
      handlePrint?.();
    }, 500);

  };

  const getPrice = (orderItem: OrderItemType, hasDiscount = false) => {
    var singlePrice = orderItem.normal_price + ((orderItem.normal_price * orderItem.itemTypeTax) / 100);
    if (hasDiscount) {
      singlePrice = orderItem.discount_price + ((orderItem.discount_price * orderItem.itemTypeTax) / 100);
    }
    return singlePrice;
  };

  const getSinglePrice = (orderItem: OrderItemType) => {
    let singlePrice = orderItem.normal_price + (orderItem.normal_price * orderItem.administrativeCost) - (orderItem.normal_price * orderItem.conditionCost)
    let roundedPrice = Math.ceil(singlePrice / 50) * 50;
    return roundedPrice;
  };

  const getUserPhoneNumber = () => {
    var result = t('orders.not-available');
    if (order.address.phoneNumber !== null && order.address.phoneNumber !== '') {
      result = order.address.phoneNumber;
    }

    if (order.user !== null) {
      if (order.user.phoneNumber !== null && order.user.phoneNumber !== '') {
        result = order.user.phoneNumber;
      }
    }
    return result;
  };

  const getUserAddress = () => {
    var result = t('orders.not-available');
    if (order.address !== null && order.address !== undefined) {
      result = `${order.address.province}, ${order.address.canton}, ${order.address.district}, ${order.address.district}`;
      result += order.address.fullAddress ? `, ${order.address.fullAddress}` : '';
      result += order.address.postalCode ? `, ${order.address.postalCode}.` : '.';
    }

    return result;
  };

  const findNumberonHtml = (html: string, item: any) => {
    var matchNumber:string = '';
    if (item.itemType.name === 'Magic: The Gathering') {
      const regexFindNumberSingleMTG = /#: <\/strong>(\d+)<\/span>/;
      const match = html.match(regexFindNumberSingleMTG);
      matchNumber = match ? match[1] : '';
    } else {
      // Trun string to HTML object.
      var htmlObject = document.createElement('div');
      htmlObject.innerHTML = html;
      // Find the element which contains the number.
      for (var i = 0; i < htmlObject.querySelectorAll('span').length; i++) {
        const span = htmlObject.querySelectorAll('span')[i];
        if (span?.textContent?.includes('Number')) {
          matchNumber = span.innerHTML.replace("<strong>Number: </strong>","");
          break;
        }
      }
    }

    return matchNumber;
  };

  return (
    <div>
      { openModal ? <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="Refund Modal"
        aria-describedby="Refund items from current order"
      >
        <Paper elevation={3} className={classes.modalPaper}>
          <Typography color="primary" align="center">{t('orders.printing')}...</Typography>
          <hr/>
          <div ref={componentRef} className={classes.printContent}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell scope="row">
                      <strong>{t('orders.code')}:</strong><br />{order.orderCode}<br/>
                      <strong>{t('orders.payment-method')}:</strong><br />{order.paymentMethod}<br />
                      <strong>{t('orders.status')}</strong><br />{order.status}
                    </TableCell>
                    <TableCell>
                      {order.user !== null && 
                        <Fragment>
                          <strong>{t('orders.client-name')}:</strong><br />{order.user.firstName} {order.user.lastName}<br />
                          <strong>{t('orders.phone')}:</strong><br />{getUserPhoneNumber()}<br />
                          <strong>Email:</strong><br />{order.user.email}<br />
                          {order.shipping.id === 1 &&
                            <Fragment><strong>{t('orders.pickup-at')}</strong><br />{ order.addressName }</Fragment>
                          }
                          {order.shipping.id === 2 && 
                            <Fragment><strong>{t('orders.client-address')}:</strong><br />{getUserAddress()}</Fragment>
                          }
                        </Fragment>
                      }
                    </TableCell>
                    <TableCell align="center">
                      <div className={classes.logo}>
                        <img src={logoImg} alt="StoreFront Logo" width="150" />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <table className={classes.tableWrapper}>
              <thead>
                <tr className={classes.tableRow}>
                  <th className={`${classes.headingCell} ${classes.headingCellFirst}`}>{t('orders.item-qty')}</th>
                  <th className={classes.headingCell}>{t('orders.product')}</th>
                  <th className={classes.headingCell}>{t('orders.number')}</th>
                  <th className={classes.headingCell}>{t('items.price')}</th>
                  <th className={classes.headingCell}>{t('orders.code')}</th>
                  <th className={classes.headingCell}>{t('items.labels')}</th>
                </tr>
              </thead>
              <tbody>
                {order.orderItems.map((orderItem, index) =>
                  {
                    const cellClassName = index % 2 ? classes.tableCell : classes.tableCellOdd;
                    const isSingle = orderItem.item.category === 'Single';
                    return <tr className={classes.tableRow} key={orderItem.id}>
                      <th className={`${cellClassName} ${classes.rowNumberCell}`}>{orderItem.normal_quantity}</th>
                      <th className={cellClassName}>{orderItem.item.name}</th>
                      <th className={cellClassName}>
                        {findNumberonHtml(orderItem.item?.additionalInfo!, orderItem.item) ?
                          <div dangerouslySetInnerHTML={{ __html: findNumberonHtml(orderItem.item?.additionalInfo!, orderItem.item) }}></div>
                        :
                          "No number"
                        }
                      </th>
                      <th className={cellClassName}>
                        {getProjectConfig().CURRENCY_SYMBOL}
                        {<NumberFormat number={isSingle ? getSinglePrice(orderItem) : getPrice(orderItem)}/>}
                      </th>
                      <th className={cellClassName}>{orderItem.item.code}</th>
                      <th className={cellClassName}>
                        {isSingle ?
                          <Fragment>
                            {orderItem.item?.labelValues?.map((labelValue, index) =>
                              <span key={labelValue.id}>
                                {index !== 0 ? ' | ' : ''}{labelValue.value}
                              </span>
                            )} | {orderItem.id.customReference}
                          </Fragment>
                        :
                          <span>N/A</span>
                      }
                      </th>
                    </tr> 
                  }
                )}
              </tbody>
            </table>
          </div>
        </Paper>
      </Modal> : null }
      <Button variant="outlined" color="primary" onClick={startPrintProcess}>
        {t('orders.print')}
      </Button>
    </div>
  )
}

export default OrderDetailPrintable;
