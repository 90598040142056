
/**
 *****************************************
 *********** Dispatch Types **************
 *****************************************
 */
export const CATEGORY_LOADING = "CATEGORY_LOADING";
export const CATEGORY_FAIL = "CATEGORY_FAIL";
export const CATEGORY_LIST = "CATEGORY_LIST";
export const SET_LOADING = "SET_LOADING";
export const SET_FAIL = "SET_FAIL";
export const SET_LIST = "SET_LIST";
/**
 *****************************************
 ************* Object Types **************
 *****************************************
 */
export type CategoryType = {
  name: string,
  id: number,
};

export type SetType = {
  name: any;
};

export type CategoryListType = {
    categories: CategoryType[],
};

export type SetListType = {
  sets: SetType[];
};

/**
 *****************************************
 *********** Interfaces Types ************
 *****************************************
 */

 export interface CategoryLoading {
    type: typeof CATEGORY_LOADING
    payload: string
  }
  
  export interface CategoryFail {
    type: typeof CATEGORY_FAIL,
    payload: string
  }
  export interface CategoryList {
    type: typeof CATEGORY_LIST;
    payload: CategoryListType;
  }
  export interface SetLoading {
    type: typeof SET_LOADING;
    payload: string;
  }
  
  export interface SetFail {
    type: typeof SET_FAIL;
    payload: string;
  }
  
  export interface SetList {
    type: typeof SET_LIST;
    payload: SetListType;
  }
  

  // Export types.
export type TemplateActionsTypes = CategoryLoading | CategoryFail | CategoryList | SetLoading | SetFail | SetList;