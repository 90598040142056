import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import NumberFormat from "react-number-format";
import { getProjectConfig } from "../../../getProjectConfig";
import { useStyles } from "./SellersStoreTotalsStyles";
import { SellerSummary } from "./utils/reportsInterfaces";
import { TotalSellerSummary } from "./ReportsContext";

export const SellersStoreTotals = (props: {sellerSummaries?: SellerSummary[]}): JSX.Element | null => {
  const classes = useStyles();
  
  if (!props.sellerSummaries) {
    return null;
  }

  const uniqueSellerSummaries = props.sellerSummaries.reduce<TotalSellerSummary[]>((acc, vendor) => {
    vendor.totalSellerSummary.forEach(seller => {
      const existingSeller = acc.find(item => item.sellerId === seller.sellerId);
      if (existingSeller) {
        existingSeller.total += seller.total;
      } else {
        acc.push({ ...seller });
      }
    });
    return acc;
  }, []);

  return <Paper elevation={3} className={classes.customPaper}>
    <Grid className={classes.customGrid}>
      {uniqueSellerSummaries.map((summary, index) => (
        <Grid item xs={12} sm={3} key={index} className={classes.customGridItem}>
          <Typography className={classes.sellerName}>
            {summary.sellerName}:
          </Typography>
          <Typography className={classes.sellerAmmount}>
            <NumberFormat
              value={(summary.total ?? 0)}
              prefix={getProjectConfig().CURRENCY_SYMBOL}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Typography>
        </Grid>
      ))}
    </Grid>
  </Paper>;
}

export default SellersStoreTotals;