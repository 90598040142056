// React.
import React from 'react';

// Components.
import ManageStorePoints from '../users/ManageStorePoints';

const StorePoints = () => {
  return (
    <div>
      <ManageStorePoints />
    </div>
  )
}

export default StorePoints;
