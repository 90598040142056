import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import {
  Theme,
  createStyles,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles"

export const Accordion = withStyles({
  root: {
    margin: "0",
    boxShadow: "none",
    "& > *": {
      flexDirection: "row-reverse",
    },
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
})(MuiAccordion)

export const AccordionSummary = withStyles({
  root: {
    borderBottom: "2px solid rgba(0, 0, 0, .3)",
    marginBottom: 1,
    padding: "0",
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
      margin: "0",
      borderBottom: "0",
    },
  },
  content: {
    display: "flex",
    margin: "12px 15px",
    "&$expanded": {
      margin: "0 15px",
    },
    "& > *": {
      alignItems: "center",
    },
  },
  expanded: {},
})(MuiAccordionSummary)

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "2px solid rgba(0, 0, 0, .3)",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2, 0),
    },
  },
}))(MuiAccordionDetails)

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    circularProgress: {
      textAlign: "center",
    },
    firstWrapper: {
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "start",
      },
    },
    lastWrapper: {
      textAlign: "right",
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
      },
    },
    summaryContainer: {
      justifyContent: "space-between",
    },
    nameContainer: {
      marginLeft: theme.spacing(6),
      [theme.breakpoints.down("xs")]: {
        marginLeft: theme.spacing(0),
      },
    },
    filters: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    disabledCard: {
      boxShadow: "none",
      "& .MuiCardActionArea-root.Mui-disabled": {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary.dark,
      },
      "&:hover": {
        opacity: "1 !important",
      },
    },
    currentStatus: {
      "& .MuiCardActionArea-root.Mui-disabled": {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
    },
    ctaTitle: {
      fontSize: "18px",
      lineHeight: "20px",
      marginBottom: theme.spacing(1),
      fontFamily: "VisbyCF-Bold",
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
      },
    },
    modalContent: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
     orderRow: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(2, 0),
      justifyContent: 'space-between',
    },
    itemContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
    itemImage: {
      width: 80,
      height: 80,
      marginRight: theme.spacing(2),
    },
    detailsModal:{
      display: 'flex',
      flexDirection: 'column',
    }
  })
)
