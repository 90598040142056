// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topMargin: {
      marginTop: theme.spacing(38)
    }
  }),
);

export default useStyles;
