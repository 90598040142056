import axios from "axios"
import { Dispatch } from "redux"

import { ORDER_FAIL, ORDER_LOADING, OrderDispatchTypes, UPDATE_ORDER } from "../orderActions/OrderActionsTypes"
import { INVENTORY_REQUESTS_FAIL, INVENTORY_REQUESTS_LIST, INVENTORY_REQUESTS_LOADING, INVENTORY_REQUESTS_REFRESH, INVENTORY_REQUESTS_SET_FILTER, InventoryRequestsDispatchTypes, InventoryRequestsResponse, UPDATE_INVENTORY_REQUESTS } from "./InventoryRequestsActionsTypes"


const apiURL: string | undefined = process.env.REACT_APP_API_URL

/**
 * Get a list of user deliveries from the BE, it can be filtered with date.
 * Get a list if the user is sender o receiver.
 * @param {object|null} qParams
 */
export const getInventoryRequests =
  (qParams: object | null) =>
  async (dispatch: Dispatch<InventoryRequestsDispatchTypes>) => {
    const userToken: string | undefined = localStorage.token;
  const apiURL: string = process.env.REACT_APP_API_URL || "";

  if (apiURL !== "" && userToken !== "") {
    try {
      dispatch({
        type: INVENTORY_REQUESTS_LOADING,
        payload: "LIST",
      });

      const headers: object = {
        ContentType: "application/json",
        Authorization: "Bearer " + userToken,
      };

      const res = await axios.get<InventoryRequestsResponse>(
        `${apiURL}/seller/getAllInternalOrders`,
        {
          params: qParams,
          headers: headers,
        }
      );

      if (res.status === 200) {
        dispatch({
          type: INVENTORY_REQUESTS_LIST,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: INVENTORY_REQUESTS_FAIL,
          payload: "status.get-error",
        });
      }
    } catch (error) {
      dispatch({
        type: INVENTORY_REQUESTS_FAIL,
        payload: "status.get-error",
      });
    }
  } else {
    dispatch({
      type: INVENTORY_REQUESTS_FAIL,
      payload: "status.get-error",
    });
  }
};

  
/**
 * Update the delivery package
 * 
 */
export const updateInventoryRequest = (orderId: number, status: string) => async (dispatch: Dispatch<InventoryRequestsDispatchTypes | OrderDispatchTypes>) => {
  const userToken: string | undefined = localStorage.token
  if (apiURL !== "" && userToken !== "") {
    try {
      dispatch({
        type: UPDATE_INVENTORY_REQUESTS,
        payload: "UPDATE_INVENTORY_REQUESTS",
      })

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + userToken,
          'Content-Type': 'application/json',
        }
      };

      const data = {
        orderId: orderId,
        status: status
      }
      const res = await axios.put(`${apiURL}/seller/updateInternalOrder`,data,configPUT)
      if (res.status === 200) {
        dispatch({
          type: UPDATE_ORDER,
          payload: 'status.success-update'
        })
      }
    } catch (e) {
      dispatch({
        type: ORDER_FAIL,
        payload: "status.error-update",
      })
    }
  } else {
    dispatch({
      type: ORDER_FAIL,
      payload: "status.error-update",
    })
  }
}



/**
 * Update the delivery view filters state.
 * @param {object} filters
 */
export const inventoryRequestSetFilter =
  (filters: object) => async (dispatch: Dispatch<InventoryRequestsDispatchTypes>) => {
    dispatch({
      type: INVENTORY_REQUESTS_SET_FILTER,
      payload: filters,
    })
  }

/**
 * Update the refresh delivery view state.
 * @param {boolean} value
 */
export const refreshDeliveryView =
  (value: boolean) => async (dispatch: Dispatch<InventoryRequestsDispatchTypes>) => {
    dispatch({
      type: INVENTORY_REQUESTS_REFRESH,
      payload: value,
    })
}
