// React.
import React, {useEffect, useLayoutEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';

// Material UI.
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import AddIcon from '@material-ui/icons/Add';
import MenuIcon from '@material-ui/icons/Menu';
import MinimizeIcon from '@material-ui/icons/Minimize';

//  JWT decode token.
import jwtDecode from 'jwt-decode';

// Components.
import SearchSort from './SearchSort';
import adminLogo from '../../../images/admin-icon.png';

// Redux.
import { useDispatch, useSelector } from "react-redux";
import { getNav, setNavFilters } from '../../../actions/navActions/NavActions';
import { RootStore } from "../../../Store";

// Translations.
import { useTranslation } from 'react-i18next';

// Models.
import { NavItemType, NavSubItemType } from '../../admin/models/ModelTypes';

// Styles.
import { useStyles, Accordion, AccordionSummary, AccordionDetails } from './NavBarStyles';

const NavBar = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const classes = useStyles();
  const [t] = useTranslation('global');
  const params = new URLSearchParams(window.location.search);
  const navAction = params.get('navAction') ? decodeURI(`${params.get('navAction')}`) : null;
  const subNavAction = params.get('subNavAction') ? decodeURI(`${params.get('subNavAction')}`) : null;
  const pCategory = params.get('category') ? decodeURI(`${params.get('category')}`) : null;
  const pItemTypeIds = params.get('itemTypeIds') ? decodeURI(`${params.get('itemTypeIds')}`) : null;
  const pLabelValuesIds = params.get('labelValuesIds') ? decodeURI(`${params.get('labelValuesIds')}`) : null;
  const pOnlyBundles = params.get('onlyBundles') ? decodeURI(`${params.get('onlyBundles')}`) : null;
  const pIsDiscount = params.get('isDiscount') ? decodeURI(`${params.get('isDiscount')}`) : null;
  const decodedToken: any = localStorage.token !== undefined && localStorage.token !== '' ? jwtDecode(localStorage.token) : false;

  // Local state.
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [expanded, setExpanded] = useState<string | false>(false);

  // Redux state.
  const navReduxState = useSelector((state: RootStore) => state.nav);
  const {navItems} = navReduxState;


  const setDefaultFilters = () => {
    const navItemsExist = (navAction || subNavAction) && navItems !== undefined
    if (navItemsExist) {
      // Update the state.
      let filter = {
        ...(pCategory) && { category: pCategory },
        onlyBundles: pOnlyBundles ? true : false,
        onlyDiscount: pIsDiscount ? true : false,
        itemTypeIds: pItemTypeIds ? pItemTypeIds : '',
        labelValuesIds: pLabelValuesIds ? pLabelValuesIds : '',
      };

      dispatch(setNavFilters(filter));
    }
    let pathname = window.location.pathname;
    const setFallbackConfig = (!navAction && !subNavAction) && navItems !== undefined && (pathname === '/items' || pathname.includes('/items/vendor/'))
    if (setFallbackConfig) {
      let filter = {
        category: 'single',
        onlyBundles: false,
        onlyDiscount: false,
        itemTypeIds: '',
        labelValuesIds: '',
      };
      dispatch(setNavFilters(filter));
    }
  };

  // Bring all nav elements.
  useEffect(() => {
    if (navItems === undefined) dispatch(getNav());
    // eslint-disable-next-line
  }, []);

  // When nav elements are loaded set the default nav element.
  useEffect(() => {

    if (navItems !== undefined) setDefaultFilters();

    // eslint-disable-next-line
  }, [navItems]);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleChange = (event: React.ChangeEvent<{}>, panel: string) => {
    event.stopPropagation();
    if (expanded === panel) {
      setExpanded(false);
    } else {
      setExpanded(panel ? panel : false);
    }
  };

  function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
    return <ListItem button component="a" {...props} />;
  }

  const isAdminUser = () => {
    if (decodedToken) {
      if (decodedToken.auth.includes('ROLE_SELLER') || decodedToken.auth.includes('ROLE_ADMIN') || decodedToken.auth.includes('ROLE_SUPER_SELLER')) {
        return true;
      }
    }
    return false;
  };

  // Track the screen size changes.
  const useWindowSize = () => {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
      const updateSize = () => {
        setSize(window.innerWidth);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  let width = useWindowSize();

  const setCurrentFilters = (navItem: NavItemType | NavSubItemType) => {
    const urlParams = new URLSearchParams(navItem.uiUrl);
    const category = urlParams.get('category')
    // Update the state.
    let filter = {
      ...(category && category !== undefined) && { category: category },
      onlyBundles: navItem.uiUrl.includes('onlyBundles=true') ? true : false,
      onlyDiscount: navItem.uiUrl.includes('isDiscount=true') ? true : false,
      itemTypeIds: navItem.itemTypeIds ? navItem.itemTypeIds : '',
      labelValuesIds: navItem.labelValuesIds ? navItem.labelValuesIds : '',
    };

    dispatch(setNavFilters(filter));
  };

  const handleLinkAction = (navItem: NavItemType | NavSubItemType | null, isAdmin = false) => {
    if (navItem && !navItem.uiUrl.includes('nolink=true')) {
      // Update the state.
      setCurrentFilters(navItem);

      // Update the URL.
      const urlObj = navItem.uiUrl.split('?');
      history.push({
        pathname: (urlObj[0] !== undefined) ? urlObj[0] : '/items',
        search: (urlObj[1] !== undefined) ? `?${urlObj[1]}` : '',
      });
    }

    if (isAdmin) {
      history.push({pathname: '/admin'});
    }

    // Close drawer.
    handleDrawerClose();
  };

  const isClickOpen = (navItem: NavItemType) => {
    if (navItem.uiUrl.includes('nolink=true')) {
      return true;
    }
    return false;
  };

  const isActiveLink = (navItem: NavItemType | NavSubItemType) => {
    if (navItem.uiUrl.includes(`${navAction}`) && !navItem.uiUrl.includes('subNavAction')) return true;
    if (navItem.uiUrl.includes(`${subNavAction}`)) return true;
    return false;
  };

  const getExpandIcon = (navItem:any, index: number) => {
    if (navItem?.submenuTitles?.length > 0) {
      return (expanded === `panel-u-${index}`) ? 
      <MinimizeIcon onClick={(e) => handleChange(e, `panel-u-${index}`)} /> : 
      <AddIcon onClick={(e) => handleChange(e, `panel-u-${index}`)} />;
    }
    return '';
  };

  return (
    <div className={classes.root}>
      {(width >= 960) &&
        <div className={classes.desktopNavWrapper} role="navigation">
          {navItems?.map((navItem, index) =>
            <div key={`d-${index}`} className={`${classes.navItemWrapper} ${isClickOpen(navItem) ? 'nolink' : ''} ${isActiveLink(navItem) ? 'is-active' : ''}`}>
              <Link onClick={() => handleLinkAction(navItem)} aria-label={navItem.value}>
                <img src={navItem.iconUrl} alt={navItem.value} />
                <span>{navItem.value}</span>
              </Link>
              {(navItem?.submenuTitles.length > 0) &&
                <div className={`subNavItemWrapper`}>
                  {navItem?.submenuTitles.map((subItem, subIndex) =>
                    <Link key={subIndex} onClick={() => handleLinkAction(subItem)} className={`${isActiveLink(subItem) ? 'is-active' : ''}`}>
                      {subItem.value}
                    </Link>
                  )}
                </div>
              }
            </div>
          )}
          {(isAdminUser() && navItems !== undefined) &&
            <div className={classes.navItemWrapper}>
              <Link href="/admin" aria-label="Admin Pages">
                <img src={adminLogo} alt="Admin logo"/>
                <span>{t('nav.admin-link')}</span>
              </Link>
            </div>
          }
        </div>
      }
      {(width < 960) &&
        <div className={classes.mobileNavWrapper} role="navigation">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            className={classes.drawer}
            variant="temporary"
            anchor="left"
            open={openDrawer}
            onClose={handleDrawerClose}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <Divider />
            <List className={classes.navList}>
              {navItems?.map((navItem, index) =>
                <Accordion key={`m-${index}`} square expanded={expanded === `panel-u-${index}`}>
                  <AccordionSummary
                    {...((navItem?.submenuTitles?.length > 0) && { onClick: (e) => handleChange(e, `panel-u-${index}`) })}
                    {...((navItem?.submenuTitles?.length === 0) && { onClick: () => handleLinkAction(navItem) })}
                    expandIcon={getExpandIcon(navItem, index)} 
                    aria-controls="panel-content" id={`panel-header-${index}`}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} className={`${isActiveLink(navItem) ? 'is-active' : ''}`}>
                        <img src={navItem.iconUrl} alt={navItem.value} />
                        <span>{navItem.value}</span>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  {(navItem?.submenuTitles?.length > 0) &&
                    <AccordionDetails id={`panel-details-${index}`} className={`${isActiveLink(navItem) ? 'is-active-ac' : ''}`}>
                      {navItem?.submenuTitles?.map((subItem) =>
                        <ListItemLink onClick={() => handleLinkAction(subItem)} key={subItem.id} className={`${isActiveLink(subItem) ? 'is-active' : ''}`}>
                          <ListItemText primary={`${subItem.value}`} />
                        </ListItemLink>
                      )}
                      <ListItemLink onClick={() => handleLinkAction(navItem)}>
                        <ListItemText primary={t('nav.see-all')} />
                      </ListItemLink>
                    </AccordionDetails>
                  }
                </Accordion>
              )}
              {isAdminUser() &&
                <Accordion square>
                  <AccordionSummary onClick={() => handleLinkAction(null, true)}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <img src={adminLogo} alt="Admin logo"/>
                        <span>{t('nav.admin-link')}</span>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                </Accordion>
              }
            </List>
          </Drawer>
          {(window.location.pathname === '/items') && 
            <div className={`${width < 1024 && classes.sortWrapper}`}><SearchSort viewMode="mobile" /></div>
          }
        </div>
      }
    </div>
  )
}

export default NavBar;
