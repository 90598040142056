import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootStepper: {
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1, 0),
        '& .MuiStepLabel-label': {
          fontSize: '0.7rem'
        }
      },
    },
  })
);

export default useStyles;