// React.
import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';

// Material UI.
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// Components.
import BundleCard from './BundleCard';
import Pagination from '../../admin/utils/ResultsPagination';

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

// Redux
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {getBundles, refreshBundleView} from "../../../actions/bundleActions/BundleActions";
import { getDolarPrice } from "../../../actions/utilityActions/UtilityActions";

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './BundlesListStyles';
import scrollToTop from '../../utils/scrollToTop';
import SelectPageSize from '../../utils/SelectPageSize';

const BundlesList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation('global');
  let history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const onlyBundles = params.get('onlyBundles');

  // Redux state.
  const bundleState = useSelector((state: RootStore) => state.bundle);
  const {bundleList, refresh, bundleFilters: filters, loading} = bundleState;
  const utilityState = useSelector((state: RootStore) => state.utility);

  // Local component state.
  const [currentPage, setCurrentPage] = useState<number>(1);
  const url = window.location.href;

  useEffect(() => {
    if (utilityState.dolarPrice === undefined) {
      dispatch(getDolarPrice());
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const pageSize = onlyBundles !== null ? getProjectConfig().PAGE_SIZES[0] : 2;
    let page = params.get('page');
    let key = params.get('key');
    let category = params.get('category');
    let labelValueId = params.get('labelValueId');
    var sort = params.get('sort');
    if (!sort) {
      sort = 'id,desc';
    }
    const pNumber = Number(page);
    if (page && page !== null) {
      const qParams = {
        ...filters,
        page: pNumber - 1,
        ...(key !== '') && { name: key },
        ...(category !== '') && { itemtypeIds: category },
        ...(labelValueId !== '') && { labelvaluesIds: labelValueId },
        sort: sort,
        size: pageSize,
        paged: true,
        isUniquePerBundleCode: true
      };
      dispatch(getBundles(qParams, 'user'));
      setCurrentPage(pNumber);
      scrollToTop();
    } else {
      const qParams = {
        ...filters,
        page: 0,
        ...(key !== '') && { name: key },
        ...(category !== '') && { itemtypeIds: category },
        ...(labelValueId !== '') && { labelvaluesIds: labelValueId },
        sort: sort,
        size: pageSize,
        paged: true,
        isUniquePerBundleCode: true
      };
      dispatch(getBundles(qParams, 'user'));
      setCurrentPage(1);
      scrollToTop();
    }

    dispatch(refreshBundleView(false));
    
    // eslint-disable-next-line
  }, [url, refresh]);

  const viewMoreBundle = () => {
    var newUrl = '/items?onlyBundles=true';
    let key = params.get('key');
    let category = params.get('category');
    let labelValueId = params.get('labelValueId');
    if (key !== null) {
      newUrl += `&key=${key}`;
    }
    if (category !== null) {
      newUrl += `&category=${category}`;
    }
    if (labelValueId !== null) {
      newUrl += `&labelValueId=${labelValueId}`;
    }
    history.push(newUrl);
  };

  return (
    <div className={classes.root}>
      {
        !loading ? (<div className={classes.selectPageSize}>
          {/* <Pagination current={currentPage} path={window.location.pathname} pagesNumber={ itemListSearchData!.totalPages }/> */}
          <SelectPageSize/>
        </div>) : null
      }
      <Grid container spacing={3}>
        {
          bundleList?.bundles !== undefined && 
          (bundleList?.bundles.map(bundle => 
            <Grid key={bundle.id} item xs={12} md={12} lg={6} className={classes.grid}><BundleCard bundle={bundle}/></Grid>
          ))
        }
        {(onlyBundles === null && bundleList?.bundles !== undefined && bundleList?.bundles.length > 0) &&
          <Grid item xs={12} md={12} lg={12} className={classes.grid}>
            <Button className={classes.viewMoreBundles} variant="contained" color="primary" onClick={ () => viewMoreBundle() }>
              { t('bundles.view-more') }
            </Button>
          </Grid>
        }
        {
          loading === '' && onlyBundles !== null && bundleList?.totalPages !== undefined && bundleList?.totalPages !== 1 && bundleList?.bundles.length !== 0
          ? (<Pagination current={currentPage} path="/items" pagesNumber={ bundleList!.totalPages }/>) : ''
        }
      </Grid>
    </div>
  )
}

export default BundlesList;
