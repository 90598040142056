// React.
import React, { useEffect, useState } from "react";

// Material UI
import {
	Backdrop,
	Button,
	CircularProgress,
	FormControl,
	FormControlLabel,
	IconButton,
	Modal,
	Radio,
	RadioGroup,
	TextField,
	Typography,
	Paper,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import PublishIcon from "@mui/icons-material/Publish";

// Translations.
import { useTranslation } from "react-i18next";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { updateItemPerLabelValue } from "../../../actions/itemTypeActions/ItemTypeActions";
import { RootStore } from "../../../Store";

// Global config.
import { getProjectConfig } from "../../../getProjectConfig";
import axios from "axios";

// Styles.
import useStyles from "./BulkItemFormStyles";
import { ItemType } from "../models/ModelTypes";

const BulkItemForm = () => {
	const classes = useStyles();
	const [t] = useTranslation("global");
	const dispatch = useDispatch();
	const apiURL: string | undefined = process.env.REACT_APP_API_URL;
	const token: string | undefined = localStorage.token;

	// Redux state.
	const itemTypeState = useSelector((state: RootStore) => state.itemType);
	const { loading } = itemTypeState;

	// Define local state.
	interface Item {
		name: string;
		id: number;
	}

	interface ItemTag {
		name: string;
		id: number;
		value: string;
	}

	const [openModal, setOpenModal] = useState<boolean>(false);
	const [editorial, setEditorial] = useState<string>("");

	const [categories, setCategories] = useState<ItemType[]>([]);
	const [parentTags, setParentTags] = useState<Item[]>([]);
	const [subTags, setSubTags] = useState<ItemTag[]>([]);

	const [selectedCategory, setSelectedCategory] = useState<ItemType | null>(null)
	const [selectedParentTag, setSelectedParentTag] = useState<Item | null>(null)
	const [selectedSubTag, setSelectedSubTag] = useState<ItemTag | null> (null)

	const [isSecondDropdownEnabled, setIsSecondDropdownEnabled] = useState<boolean>(false)
	const [isThirdDropdownEnabled, setIsThirdDropdownEnabled] = useState<boolean>(false)

	const getCategories = async () => {
		const headers: object = {
			ContentType: "application/json",
			Authorization: "Bearer " + token,
		};

		const totalItemsResponse = await axios.get(apiURL + `/user/getAllItemType`, {
			params: {
				page: 0,
				size: 1,
				paged: true,
				isUniquePerCode: true,
				sort: "name,asc"
			},
			headers: headers,
		});

		const totalItem = totalItemsResponse.data.data.totalElements;

		const qParamsItems = {
			page: 0,
			size: totalItem,
			paged: true,
			isUniquePerCode: true,
			sort: "name,asc"
		};

		const resItems = axios.get(apiURL + `/user/getAllItemType`, {
			params: qParamsItems,
			headers: headers,
		});

		resItems.then((response) =>{
			setCategories(response.data.data.content.map((item: any) => item))
		})
	}

	const getParentTags = async () => { 
		if(selectedCategory !== null){
			const headers: object = {
				ContentType: "application/json",
				Authorization: "Bearer " + token,
			};

			const qParamsItems = {
				page: 0,
				size: 1,
				paged: true,
				isUniquePerCode: true,
			};

			const totalItemsResponse = await axios.get(
				apiURL + `/user/getAllLabel/` + selectedCategory.id,
				{ params: qParamsItems, headers: headers }
			);

			const totalItems = totalItemsResponse.data.data.totalElements;

			const getAllItemsResponse = await axios.get(
				apiURL + `/user/getAllLabel/` + selectedCategory.id,
				{
					params: {
						page: 0,
						size: totalItems,
						paged: true,
					}, 
					headers: headers
				}
			);
		
			const parentTags = getAllItemsResponse.data.data.content.map((item: any) => item);
			setParentTags(parentTags);
		}
	}

	const getSubTags = async () =>{
		if(selectedParentTag !== null){
			const headers: object = {
				ContentType: "application/json",
				Authorization: "Bearer " + token,
			  };

				const totalItemsResponse = await axios.get(apiURL + `/user/getAllLabelValues/` + selectedParentTag?.id, { 
					params: {
						page: 0,
						size: 1,
						paged: true,
						isUniquePerCode: true,
					},
					headers: headers
				});

				const totalItems = totalItemsResponse.data.data.totalElements;

			  const response = await axios.get(apiURL + `/user/getAllLabelValues/` + selectedParentTag?.id,
					{
						params: {
							page: 0,
							size: totalItems,
							paged: true,
							isUniquePerCode: true,
						},
						headers: headers
					}
			  );
	
			  const data = response.data.data.content.map((item: any) => item);
			  setSubTags(data);
		}
	}

	const handleChangeCategory = (event: any, newValue: any | null) => {
		if (newValue === null || newValue === undefined) {
		  setIsSecondDropdownEnabled(false);
		  setIsThirdDropdownEnabled(false);
		  setSelectedCategory(null);
		  setSelectedParentTag(null);
		  setSelectedSubTag(null);
		} else {
		  setIsSecondDropdownEnabled(true);
		  setSelectedCategory(newValue);
		}
	}

	const handleChangeParentTag = (event: any, newValue: any | null) => {
		if(newValue === null || newValue === undefined){
			setIsThirdDropdownEnabled(false)
			setSelectedSubTag(null)
			setSelectedParentTag(null)
		}
		if(newValue){
			setIsThirdDropdownEnabled(true)
			setSelectedParentTag(newValue)
		} 
	}

	const handleChangeSubTag = (event: any, newValue: any | null) => {
		if(newValue){
			setSelectedSubTag(newValue)
		} else{
			setSelectedSubTag(null)
		}
	}

	const textConfirmModal = {
		"PUBLISH": "items.bulk-publish",
		"UNPUBLISH": "items.bulk-unpublish",
		"SINGLE_PRICE": "items.bulk-form-price",
		"SINGLE_DESCRIPTION": "items.bulk-form-description",
	}
	const disableEnableClass = !(
		selectedCategory && selectedCategory !== null
	)
		? classes.sectionTitleDisable
		: classes.sectionTitleEnable;

	const disableEnableClassTag = !(
		selectedCategory &&
		selectedCategory !== null &&
		selectedParentTag &&
		selectedParentTag !== null
	)
		? classes.sectionTitleDisable
		: classes.sectionTitleEnable;

	const confirmModalText = (
		t(textConfirmModal[editorial as keyof typeof textConfirmModal])
	).toLowerCase();

	useEffect(() => { 
		getCategories();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])


	useEffect(() => {
		if (selectedCategory !== null) {
		  getParentTags();
		}
	  // eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [selectedCategory]);

	  useEffect(() => { 
		if(selectedParentTag !== null){
			getSubTags();
		}
	  // eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [selectedParentTag]);

	const onChangeEditorial = (event: React.ChangeEvent<{ value: unknown }>) => {
		setEditorial(String(event.target.value));
	};

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const processOnClick = () => {
		if (selectedSubTag && editorial) {
			dispatch(
				updateItemPerLabelValue(
					String(selectedSubTag.id),
					editorial
				)
			);
			handleCloseModal();
			setSelectedCategory(null);
			setSelectedParentTag(null);
			setSelectedSubTag(null);
			setIsSecondDropdownEnabled(false);
		}
	};

	return (
		<>
			<div className={classes.root}>
				<Paper elevation={3}>
					<div className={classes.boxForm}>
						<form>
							<FormControl className={classes.formControl}>
							<Typography
									variant="h6"
									color="primary"
									className={classes.sectionTitle}
								>
									{t("items.bulk-categories")}
								</Typography>
								{
									<Autocomplete 
										id="category-tcg"
										options={categories || []}
										value={selectedCategory}
										onChange={handleChangeCategory}
										getOptionLabel={(option: any) => option.name}
										renderInput={(params) => <TextField {...params} />}
										noOptionsText={t("header.no-result")}
									/>
								}
							</FormControl>

							<FormControl className={classes.formControl}>
								<Typography
									variant="h6"
									color="primary"
									className={classes.sectionTitle + ` ` + disableEnableClass}
								>
									{t("items.bulk-tag-parent")}
								</Typography>
								<Autocomplete 
										id="category-tcg"
										options={parentTags}
										value={selectedParentTag}
										onChange={handleChangeParentTag}
										getOptionLabel={(option: any) => option.name}
										renderInput={(params) => <TextField {...params} />}
										onInputChange={() => setIsThirdDropdownEnabled(false)}
										disabled={!isSecondDropdownEnabled}
										noOptionsText={t("header.no-result")}
									/>
							</FormControl>

							<FormControl className={classes.formControl}>
								<Typography
									variant="h6"
									color="primary"
									className={classes.sectionTitle + ` ` + disableEnableClassTag}
								>
									{t("items.bulk-subtag")}
								</Typography>
								<Autocomplete 
										id="category-tcg"
										options={subTags}
										value={selectedSubTag}
										onChange={handleChangeSubTag}
										getOptionLabel={(option: any) => option.value}
										renderInput={(params) => <TextField {...params} />}
										disabled={!isThirdDropdownEnabled}
										noOptionsText={t("header.no-result")}
									/>
							</FormControl>
							<FormControl className={classes.formControl}>
								<RadioGroup
									row
									aria-labelledby="row-radio-buttons-group-label"
									name="row-radio-buttons-group"
									defaultValue=""
									onChange={onChangeEditorial}
									value={editorial}
								>
									<FormControlLabel
										value="PUBLISH"
										control={<Radio />}
										label={t("items.bulk-publish")}
										disabled={
											!(
												selectedCategory &&
												selectedCategory!== null &&
												selectedParentTag &&
												selectedParentTag !== null &&
												selectedSubTag &&
												selectedSubTag !== null
											)
										}
									/>
									<FormControlLabel
										value="UNPUBLISH"
										control={<Radio />}
										label={t("items.bulk-unpublish")}
										disabled={
											!(
												selectedCategory &&
												selectedCategory!== null &&
												selectedParentTag &&
												selectedParentTag !== null &&
												selectedSubTag &&
												selectedSubTag !== null
											)
										}
									/>
									<FormControlLabel
										value="SINGLE_PRICE"
										control={<Radio />}
										label={t("items.bulk-form-price")}
										disabled={
											!(
												selectedCategory &&
												selectedCategory!== null &&
												selectedParentTag &&
												selectedParentTag !== null &&
												selectedSubTag &&
												selectedSubTag !== null
											)
										}
									/>
									<FormControlLabel
										value="SINGLE_DESCRIPTION"
										control={<Radio />}
										label={t("items.bulk-form-description")}
										disabled={
											!(
												selectedCategory &&
												selectedCategory!== null &&
												selectedParentTag &&
												selectedParentTag !== null &&
												selectedSubTag &&
												selectedSubTag !== null
											)
										}
									/>
								</RadioGroup>
							</FormControl>

							<FormControl className={classes.formControl}>
								<div className={classes.actionsWrapper}>
									<Button
										variant="contained"
										color="primary"
										onClick={handleOpenModal}
										disabled={
											!(
												selectedCategory &&
												selectedCategory!== null &&
												selectedParentTag &&
												selectedParentTag !== null &&
												selectedSubTag &&
												selectedSubTag !== null &&
												editorial &&
												editorial !== ""
											)
										}
									>
										{t("items.bulk-btn-execute")}
									</Button>
								</div>
							</FormControl>
						</form>
					</div>
				</Paper>
				<Modal
					open={openModal}
					onClose={handleCloseModal}
					aria-labelledby="Confirm Modal"
				>
					<Paper
						elevation={3}
						className={classes.modalPaper + ` ` + classes.modalPaperConfirm}
					>
						<IconButton
							className={classes.closeModal}
							aria-label="close modal"
							component="span"
							onClick={() => handleCloseModal()}
						>
							<CloseIcon />
						</IconButton>
						<PublishIcon color="primary" fontSize="large" />
						<Typography>
							{editorial === "PUBLISH" || editorial === "UNPUBLISH" ? (t("items.bulk-confirm-modal-title-1")) : (t("items.bulk-singles-confirm-modal-title-1"))}
							<strong>{confirmModalText} </strong>
							{editorial === "PUBLISH" || editorial === "UNPUBLISH" ? t("items.bulk-confirm-modal-title-2") : " de " + t("items.bulk-confirm-modal-title-2")}
							<strong>
								{selectedParentTag?.name + ` / ` + selectedSubTag?.value} 
							</strong>
							{t("items.bulk-confirm-modal-title-3")}
						</Typography>
						<Button
							variant="outlined"
							color="secondary"
							onClick={() => processOnClick()}
						>
							{" "}
							{t("items.bulk-confirm-modal-yes")}{" "}
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={() => handleCloseModal()}
						>
							{" "}
							{t("items.bulk-confirm-modal-no")}{" "}
						</Button>
					</Paper>
				</Modal>
			</div>
			<Backdrop
				className={classes.backdrop}
				open={loading === "ITEM_TYPE_PUBLISH_PER_LABEL_VALUE"}
			>
				<Paper>
					<CircularProgress color="inherit" />
					<Typography>{t("items.processing")}</Typography>
				</Paper>
			</Backdrop>
		</>
	);
};

export default BulkItemForm;
