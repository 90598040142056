/**
 *****************************************
 *********** Dispatch Types **************
 *****************************************
 */
export const SETTINGS_FAIL = "SETTINGS_FAIL";
export const SETTINGS_FAIL_DETAIL = "SETTINGS_FAIL_DETAIL";
export const SETTINGS_LOADING = "SETTINGS_LOADING";
export const CREATE_BANNER = "CREATE_BANNER";
export const UPDATE_BANNER = "UPDATE_BANNER";
export const DELETE_BANNER = "DELETE_BANNER";
export const GET_BANNERS = "GET_BANNERS";
export const CREATE_BOX = "CREATE_BOX";
export const GET_BOXES = "GET_BOXES";
export const GET_HOMEPAGE = "GET_HOMEPAGE";
export const REGISTER_ITEMS_BY_XLSX = "REGISTER_ITEMS_BY_XLSX";
export const VALIDATION_ITEMS_BY_XLSX = "VALIDATION_ITEMS_BY_XLSX";
export const GET_ALL_XLSX_FILES = "GET_ALL_XLSX_FILES";
export const GET_XLSX_FILE_DATA = "GET_XLSX_FILE_DATA";
export const GET_VARIABLES = "GET_VARIABLES";
export const UPDATE_VARIABLES = "UPDATE_VARIABLES";
export const DELETE_ORDER_CONFIRMATION = "DELETE_ORDER_CONFIRMATION";
export const CREATE_ORDER_CONFIRMATION = "CREATE_ORDER_CONFIRMATION";
export const UPDATE_ORDER_CONFIRMATION = "UPDATE_ORDER_CONFIRMATION";
export const GET_ORDER_CONFIRMATIONS = "GET_ORDER_CONFIRMATIONS";

/**
 *****************************************
 *********** Model Types ************
 *****************************************
 */

export type ItemValidationType = {
  customReference: string
  priceFoil: number
  priceNormal: number
  productId: string
  productName: string
  quantity: number
  setName: string
}

export type ItemValidatioResponseType = {
  itemList: ItemValidationType[]
  vendorId: number
  vendorName: string
}

export type OrderConfirmation = {
  id?: number,
  sequence: number,
  title: string,
  value: string
};

export type VariableType = {
  id: number
  name: string
  value: string
}

export type HomepageBannerType = {
  ctaLink: string,
  ctaText: string,
  disable: boolean,
  gradient: boolean,
  id: number,
  imageKey: string,
  imageUrl: string,
  position: number,
  text: string,
  type: string
};

export type HomepageFeatureBoxesItemType = {
  code: string,
  imageURL: string,
  position: number,
  itemTypeId: number
};

export type HomepageFeatureBoxesType = {
  disable: boolean,
  id: number,
  items: HomepageFeatureBoxesItemType[],
  position: number,
  title: string
};

export type HomepageDataType = {
  banners: HomepageBannerType[],
  featureBoxes: HomepageFeatureBoxesType[]
};
 
/**
  *****************************************
  *********** Interfaces Types ************
  *****************************************
  */
export interface GetBanners {
  type: typeof GET_BANNERS,
  payload: any
}
export interface BannerLoading {
  type: typeof SETTINGS_LOADING
  payload: string
}
export interface BannerFail {
  type: typeof SETTINGS_FAIL,
  payload: string
}
export interface SettingsFailDetail {
  type: typeof SETTINGS_FAIL_DETAIL,
  payload: any
}
export interface CreateBanner {
  type: typeof CREATE_BANNER,
  payload: string
}
export interface CreateFeaturedBox {
  type: typeof CREATE_BOX,
  payload: string
}
export interface GetBoxes {
  type: typeof GET_BOXES,
  payload: any
}
export interface GetHomepageData {
  type: typeof GET_HOMEPAGE,
  payload: HomepageDataType
}
export interface UpdateBanner {
  type: typeof UPDATE_BANNER,
  payload: string
}
export interface DeleteBanner {
  type: typeof DELETE_BANNER,
  payload: any
}

export interface RegisterItemsByXlsx {
  type: typeof REGISTER_ITEMS_BY_XLSX,
  payload: any
}

export interface ValidationItemsByXlsx {
  type: typeof VALIDATION_ITEMS_BY_XLSX,
  payload: ItemValidatioResponseType
}

export interface GetAllUploadedXlsx {
  type: typeof GET_ALL_XLSX_FILES,
  payload: string[]
}

export interface GetUploadedXlsxData {
  type: typeof GET_XLSX_FILE_DATA,
  payload: string
}

export interface GetVariables {
  type: typeof GET_VARIABLES,
  payload: VariableType[]
}

export interface UpdateVariables {
  type: typeof UPDATE_VARIABLES,
  payload: string
}

export interface DeleteOrderConfirmation {
  type: typeof DELETE_ORDER_CONFIRMATION,
  payload: string
}

export interface CreateOrderConfirmation {
  type: typeof CREATE_ORDER_CONFIRMATION,
  payload: string
}

export interface UpdateOrderConfirmation {
  type: typeof UPDATE_ORDER_CONFIRMATION,
  payload: string
}

export interface GetOrderConfirmations {
  type: typeof GET_ORDER_CONFIRMATIONS,
  payload: OrderConfirmation[]
}
 
// Export types.
export type BannerActionsTypes = GetBanners | BannerLoading | BannerFail | DeleteOrderConfirmation | CreateOrderConfirmation | UpdateOrderConfirmation |
  CreateBanner | UpdateBanner | DeleteBanner | CreateFeaturedBox | GetBoxes | GetHomepageData | GetOrderConfirmations |
  RegisterItemsByXlsx | ValidationItemsByXlsx | SettingsFailDetail | GetVariables | UpdateVariables | GetAllUploadedXlsx | GetUploadedXlsxData;
