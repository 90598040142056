// React.
import React, { useState, useEffect, Fragment } from "react";

// Material UI
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BackupIcon from '@material-ui/icons/Backup';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import DescriptionIcon from '@material-ui/icons/Description';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import Backdrop from '@material-ui/core/Backdrop';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { uploadExcelFile } from "../../../actions/settingsActions/SettingsActions";
import { RootStore } from "../../../Store";

// Styles.
import useStyles from './ItemXlsxSettingsStyles';

// Translations.
import { useTranslation } from 'react-i18next';

const ItemXlsxSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation('global');

  // Redux state.
  const settingsState = useSelector((state: RootStore) => state.settings);
  const { loading, actionStatus, errorDetail, validationResult } = settingsState;

  // Local state.
  const [currentFileName, setCurrentFileName] = useState<string>('');
  const [currentFileSize, setCurrentFileSize] = useState<string>('');
  const [errorText, setErrorText] = useState<string[]>([]);
  const [currentFile, setCurrentFile] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [checkboxState, setCheckboxState] = useState(false);
  const [checkboxRowsState, setCheckboxRowsState] = useState(false);
  const [checkboxCardsState, setCheckboxCardsState] = useState(false);
  const [checkboxFileState, setCheckboxFileState] = useState(false);

  useEffect(() => {
    if (actionStatus !== undefined) {
      if (actionStatus.status === 'success') {
        setCurrentFileName('');
        setCurrentFileSize('');
        setCurrentFile(null);
        handleCloseModal();
      } else {
        if (errorDetail !== undefined) {
          if (errorDetail?.data?.message) {
            // eslint-disable-next-line
            let errorArray = errorDetail?.data?.message.replace(/\[|\]/g, '').replace(/\{|\}/g, '').replace(/\,/g, '').split('Xlsx Error');
            setErrorText(errorArray);
          } else {
            if (errorDetail === 'Network Error') {
              setErrorText([t('settings.timeout-error')]);
            } else {
              setErrorText([t('settings.unkown-error')]);
            }
          }
        }
      }

      if (actionStatus.menssage === 'status.success-upload-file') {
        handleCloseModal();
        handleOpenConfirmationModal();
      }
    }

    // eslint-disable-next-line
  }, [actionStatus]);

  useEffect(() => {
    if (validationResult !== undefined) {
      handleOpenModal();
    }

    // eslint-disable-next-line
  }, [validationResult]);


  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
    window.location.reload();
  };

  const handleOpenConfirmationModal = () => {
    setOpenConfirmationModal(true);
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxState(event.target.checked);
  };

  const handleCheckboxRowsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxRowsState(event.target.checked);
  };

  const handleCheckboxCardsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxCardsState(event.target.checked);
  };

  const handleCheckboxFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxFileState(event.target.checked);
  };

  const bytesToSize = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return 'n/a'
    const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))), 10)
    if (i === 0) return `${bytes} ${sizes[i]})`
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
  }

  const handleUploadClick = (props: any) => {
    let event = props.nativeEvent;
    let filesList = event.target.files as FileList | null;
    if (filesList && filesList !== undefined) {
      let currentFile = filesList[0];
      setCurrentFileName(currentFile.name);
      setCurrentFileSize(bytesToSize(currentFile.size));
      setCurrentFile(currentFile);

      // Clear other state.
      setCheckboxState(false);
      setCheckboxRowsState(false);
      setCheckboxCardsState(false);
      setCheckboxFileState(false);
    }
  };
  
  const submitFile = () => {
    if (currentFile) {
      dispatch(uploadExcelFile(currentFile, true));
    }
  };

  const saveFile = () => {
    if (currentFile) {
      dispatch(uploadExcelFile(currentFile, false));
    }
  };

  const cancelFile = () => {
    handleCloseModal();
  };

  const getLoadingMsg = () => {
    switch (loading) {
      case 'UPLOADING':
        return t('settings.uploading');
      case 'PROCESSING':
        return t('settings.processing');
      default:
        return t('settings.loading');
    }
  };

  const getCardsTotal = () => {
    if (validationResult?.itemList !== undefined) {
      var total = 0;
      validationResult?.itemList.forEach((item) => {
        total += item?.quantity !== undefined ? item?.quantity : 0;
      });
      return total;
    }
    return 0;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h4" color="primary" className={classes.marginBottom}>
          {t('settings.load-excel-title')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <div className={classes.xlsxFile }>
          <Typography>
            {t('settings.load-excel-help-text')}
          </Typography>
          <br/>
          <input
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            id="xlsx-file"
            type="file"
            onChange={handleUploadClick}
            className={classes.fileInput}
          />
          <label htmlFor="xlsx-file">
            <Button variant="outlined" component="span" startIcon={<FindInPageIcon />}>
              {t('settings.select-file')}
            </Button>
          </label>
        </div>
        {currentFileName &&
          <Fragment>
            <div className={classes.selectedFileWrapper}>
              <DescriptionIcon fontSize="large" />
              <Typography>
                {t('settings.file')}: {currentFileName}<br />
                {t('settings.size')}: {currentFileSize}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={
                  <BackupIcon />
                }
                onClick={submitFile}
                disabled={loading !== ''}
              >
                {t('settings.upload')}
              </Button>
            </div>
            {errorText.length > 0 &&
              <Alert severity="error" color="error">{t('settings.errors')}: 
                {errorText.map((error:string, index:number) =>
                  <Fragment>
                    {error !== '\n' &&
                      <p>({index}) - {error}</p>
                    }
                  </Fragment>
                )}
              </Alert>
            }
          </Fragment>
        }

        <Modal
          open={openModal && loading === ''}
          onClose={handleCloseModal}
          aria-labelledby="Confirm Modal"
        >
          <Paper elevation={3} className={classes.modalPaper}>
            <IconButton className={classes.closeModal} aria-label="close modal" component="span" onClick={() => handleCloseModal()}>
              <CloseIcon />
            </IconButton>
            <div>
              <Alert severity="success">{t('settings.file-submited')}</Alert><br/>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3} className={classes.extraPadding}>
                  <Typography className={classes.boldText}>{t('settings.seller')}: {validationResult?.vendorName}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={<Checkbox name="confirm" checked={checkboxState} onChange={handleCheckboxChange} />}
                    label={t('settings.confirm-vendor')}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3} className={classes.extraPadding}>
                  <Typography variant="caption">
                    {t('settings.rows-total')}: {validationResult?.itemList?.length ? validationResult?.itemList.length : 0}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={<Checkbox name="confirm" checked={checkboxRowsState} onChange={handleCheckboxRowsChange} />}
                    label={t('settings.confirm-rows')}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3} className={classes.extraPadding}>
                  <Typography variant="caption">{t('settings.cards-total')}: {getCardsTotal()}</Typography><br />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={<Checkbox name="confirm" checked={checkboxCardsState} onChange={handleCheckboxCardsChange} />}
                    label={t('settings.confirm-cards')}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3} className={classes.extraPadding}>
                  <Typography variant="caption">{t('settings.file')}: <br/> {currentFileName}</Typography><br />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={<Checkbox name="confirm" checked={checkboxFileState} onChange={handleCheckboxFileChange} />}
                    label={t('settings.confirm-file')}
                  />
                </Grid>
              </Grid>

              <br />
              <div className={classes.buttonsContainer}>
                <Button variant="contained" color="secondary" onClick={cancelFile}>
                  {t('settings.cancel')}
                </Button>
                <Button variant="contained" color="primary" onClick={saveFile} 
                  disabled={(loading !== '') || !checkboxState || !checkboxRowsState || !checkboxCardsState || !checkboxFileState}>
                  {t('settings.confirm-load')}
                </Button>
              </div>
            </div>
          </Paper>
        </Modal>
        <Modal
          open={openConfirmationModal && loading === ''}
          onClose={handleCloseConfirmationModal}
          aria-labelledby="Confirm Modal"
        >
          <Paper elevation={3} className={`${classes.modalPaper} ${classes.modalConfirmPaper}`}>
            <CheckCircleIcon fontSize="large" color="primary"/>
            <Typography>{t('settings.confirmation-text')}</Typography>
            <br/>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseConfirmationModal}
              disabled={loading !== ''}
            >
              {t('settings.btn-confirmation')}
            </Button>
          </Paper>
        </Modal>
      </Grid>
      <Backdrop className={classes.backdrop} open={loading === 'UPLOADING' || loading === 'PROCESSING'}>
        <Paper>
          <CircularProgress color="inherit" />
          <Typography>{getLoadingMsg()}</Typography>
        </Paper>
      </Backdrop>
    </Grid>
  );
};


export default ItemXlsxSettings;
