// React.
import React, {useState} from 'react';

// Material UI.
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import TuneIcon from '@material-ui/icons/Tune';

// Components.
import OrderAccordion from './OrderAccordion';

// Translations.
import { useTranslation } from 'react-i18next';

// Redux
import {useDispatch} from "react-redux";
import {refreshOrderView, orderSetFilters} from "../../../actions/orderActions/OrderActions";

// Styles.
import useStyles from './OrderListStyles';

const OrderList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation('global');

  // Local state.
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const newDate = new Date()
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelected = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    const filterType = event.currentTarget.getAttribute('data-type');
    if (event.currentTarget.getAttribute('value') !== '') {
      var sendFilter = {};
      if (filterType === 'past-3-m') {
        sendFilter = {
          isPastThreeMonths: true
        };
      }
      if (filterType === 'this-year' || filterType === 'last-year') {
        sendFilter = {
          year: Number(event.currentTarget.getAttribute('value'))
        };
      }

      dispatch(orderSetFilters(sendFilter));
      dispatch(refreshOrderView(true));
    }
  };

  return (
    <div className={ classes.root }>
      <div className={ classes.filters }>
        <Button variant="text" color="primary" size="medium" onClick={handleMenu} startIcon={<TuneIcon />}>
          { t('users.filters') }
        </Button>
        <Menu id="filter-users" anchorEl={ anchorEl }
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={ handleSelected } value={month} data-type="past-3-m">{ t('orders.past-3-months') }</MenuItem>
          <MenuItem onClick={ handleSelected } value={year} data-type="this-year">{ year }</MenuItem>
          <MenuItem onClick={ handleSelected } value={ year - 1 } data-type="last-year">{ year - 1 }</MenuItem>
        </Menu>
      </div>
      <div>
        <OrderAccordion />
      </div>
    </div>
  )
}

export default OrderList;
