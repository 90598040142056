import React, {useState } from 'react';
import { useHistory } from 'react-router-dom';

// Material UI.
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Tooltip from '@mui/material/Tooltip';


// Redux
import { useDispatch } from "react-redux";
import { addToWishList } from "../../../actions/wishlistActions/wishlistActions";
import { getUserInformation } from '../../../actions/authActions/AuthActions';

// Styles.
import useStyles from './AddToWishlistStyles';

// Translations.
import { useTranslation } from 'react-i18next';

// Types interfase.
interface AddToWishlistProps {
  item: any;
};
const AddToWishlist = ({ item }: AddToWishlistProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const [t] = useTranslation('global');

  const [isFavorite] = useState<boolean>(false);

  const addToWishlist = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, item: any) => {
    // Prevent parent link to redirect.
    e.stopPropagation();
    e.preventDefault();
    if(localStorage.token){
      let customReference:any;
      item.category === "Single"? customReference = getCustomReference(item): customReference = null
      if (!isFavorite){
        if (localStorage.token) {
            dispatch(addToWishList(item.code, customReference));
            dispatch(getUserInformation());
        } 
      } else{
          dispatch(addToWishList(item.code, customReference, true));
          dispatch(getUserInformation());
      }
    } else {
        history.push('/user/login');
    }
  }

  const getCustomReference = (item: any) => {
      let customReference = 'noreference';
      item.itemSearchVendorData.forEach((element:any) => {
          element.itemQuantityData.forEach((item:any) => {
            if(item.customReference){
              customReference = item.customReference
              return customReference
            }
        });
      });
      return customReference
  }

  const getTooltipString = () => {
    return t('wishlist.add-to-wishlist-tooltip')
  }

  return (
    
    <Tooltip
      title={getTooltipString()}
      placement="right"
      onClick={(e) => addToWishlist(e, item)}
    >
      <FavoriteBorderIcon
        className={`${classes.wishlistIcon} ${classes.clicked}`}
      />
    </Tooltip>
  );
}

export default AddToWishlist;
