import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customPaper: {
      padding: "16px",
      marginBottom: "16px"
    },
    customGrid: {
      display: "flex",
      [theme.breakpoints.down('xs')]: {
        flexDirection: "column",
      },
      flexDirection: "row",
    },
    customGridItem: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    sellerName: {
      fontFamily: "VisbyCF-Bold",
      textAlign: "center",
    },
    sellerAmmount: {
      textAlign: "center",
    },
  })
);