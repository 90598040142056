// Material UI.
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftColumn: {
      alignItems: 'center',
    },
    itemCard: {
      background: grey[100],
      borderRadius: '6px',
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
      marginRight: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        marginRight: 0,
      },
      '& .MuiAvatar-root': {
        width: '80px',
        height: 'auto',
        margin: '0 auto',
        [theme.breakpoints.down('xs')]: {
          width: '50px',
        height: '50px'
        },
      }
    },
    rightColumn: {
      height: 'fit-content',
      marginTop: theme.spacing(1),
      '& .MuiTextField-root': {
        width: '100%'
      },
      '& .MuiFormControl-root': {
        width: '100%'
      }
    },
    secondaryInfo: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap'
      },
      '& > div': {
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
          minWidth: '45%'
        }
      }
    },
    refundWrapper: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'right',
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2)
      }
    },
    formControl: {
      width: '100%',
      padding: theme.spacing(2, 0)
    },
    actionsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      '& button': {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          marginLeft: theme.spacing(1),
        },
      }
    },
    modalPaper: {
      padding: theme.spacing(2, 4),
      position: 'absolute',
      width: '600px',
      height: '268px',
      top: 'calc(50% - 134px)',
      left: 'calc(50% - 300px)',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2, 1),
        width: '90%',
        left: '5%'
      },
    },
    modalHeading: {
      display: 'flex',
      marginBottom: theme.spacing(4)
    },
    modalFields: {
      display: 'flex',
      margin: theme.spacing(0, 1, 5)
    },
    modalField: {
      width: '120px',
      marginRight: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        '& .MuiInputBase-root': {
          width: '100%'
        },
      },
    },
    modalActions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      '& button': {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          marginLeft: theme.spacing(1),
        },
      }
    },
    discountLabel: {
      textDecoration: 'line-through'
    },
    type: {
      padding: '0 5px 2px',
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '4px',
      marginLeft: '10px'
    },
    typeSingle: {
      padding: '0 5px 2px',
      color: 'white',
      backgroundColor: theme.palette.grey[600],
      borderRadius: '4px',
      marginLeft: '10px'
    },
    variationSingle: {
      float: 'right',
      [theme.breakpoints.down('md')]: {
        float: 'unset',
        display: 'block'
      },
    },
    textSuccess:{
      textAlign: 'left',
      display: 'block',
      padding: '5px 0',
      '& span': {
        padding: '4px 10px',
        backgroundColor: 'green',
        borderRadius: '5px',
        color: 'white',
        fontFamily: 'VisbyCF-Bold'
      }
    },
    textDanger:{
      textAlign: 'left',
      display: 'block',
      padding: '5px 0',
      '& span': {
        padding: '4px 10px',
        backgroundColor: 'red',
        borderRadius: '5px',
        color: 'white',
        fontFamily: 'VisbyCF-Bold'
      }
    },
    textBold: {
      fontFamily: 'VisbyCF-Bold'
    },
    clientSection: {
      padding: theme.spacing(1),
      border: '2px solid',
      borderRadius: '6px',
      borderColor: theme.palette.grey[500],
      marginBottom: theme.spacing(1),
    },
    trackingSection: {
      padding: theme.spacing(1),
      border: '2px solid',
      borderRadius: '6px',
      borderColor: theme.palette.grey[500],
      marginBottom: theme.spacing(1),
    },
    paymentSection: {
      padding: theme.spacing(1),
      border: '2px solid',
      borderRadius: '6px',
      borderColor: theme.palette.grey[500],
    },
    vendorBox: {
      padding: theme.spacing(0, 1),
      borderRadius: '6px',
      fontFamily: 'VisbyCF-Bold',
      width: 'max-content'
    },
    bgYellow: {
      backgroundColor: '#fcf1c9'
    },
    bgOrange: {
      backgroundColor: '#F8A036'
    },
    bgRed: {
      backgroundColor: '#eec6c3'
    },
    bgBlue: {
      backgroundColor: '#c1e0f2'
    },
    bgGray: {
      backgroundColor: '#d7dddd'
    },
    bgGreen: {
      backgroundColor: '#b2e2c6'
    },
    itemLabels: {
      width: '100%',
      textAlign: 'center',
      '& .label-row': {
        marginBottom: theme.spacing(1),
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: '6px'
      },
      '& .label-key': {
        padding: '0 5px',
        fontFamily: 'VisbyCF-Bold',
        backgroundColor: theme.palette.grey[600],
        color: 'white',
        borderRadius: '6px'
      },
      '& .label-value': {
        padding: '0 5px',
      }
    },
    inputPickUpId:{
      width: '100%'
    }
  }),
);

export default useStyles;
