import React, { Fragment } from 'react';


interface ArrowProps {
  onClickHandler: any;
  dark?: boolean;
};
const LeftArrow = ({onClickHandler, dark} : ArrowProps) => {
  return (
    <Fragment>
      <svg className="left-arrow" xmlns="http://www.w3.org/2000/svg" onClick={onClickHandler} width="28" height="58">
        <path d="M 1 0.5 L 27.5 29 L 1 57.5" fill="transparent" stroke={dark ? 'hsl(0, 0%, 30%)' : 'hsl(0, 0%, 100%)'}></path>
      </svg>
    </Fragment>
  )
}

export default LeftArrow;
