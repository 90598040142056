// React.
import React from 'react';

// Material UI
import Paper from '@material-ui/core/Paper';
import Store from '@material-ui/icons/Store';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// Components.

// Redux.
import {RootStore} from "../../../Store";
import {useSelector} from "react-redux";

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './VendorListStyles';
import VendorAccordion from './VendorAccordion';

const VendorList = () => {

  const classes = useStyles();
  const [t] = useTranslation('global');

  const vendorState = useSelector((state: RootStore) => state.vendors);
  const { loading } = vendorState;

  return (
    <div className={ classes.root }>
      <Paper elevation={3}  className={classes.paper}>
        <div className={ classes.boxTop }>
          <Store color='primary' fontSize="large"/>
          <Typography variant="h4" color="primary"className={ classes.sectionTitle }>
            { t('vendors.vendor-list') } {loading === 'LIST' && <CircularProgress/>}
          </Typography>
        </div>
        <div>
          <VendorAccordion />
        </div>
      </Paper>
    </div>
  )
}

export default VendorList;
