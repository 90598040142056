import {
  DELETE_FROM_WISHLIST, 
  ADD_TO_WISHLIST, 
  ADD_FROM_WISHLIST,
  ADD_FROM_WISHLIST_FAIL, 
  ADD_FROM_WISHLIST_LOADING,
  ADD_TO_WISHLIST_EXISTS,
  GET_WISHLIST, 
  WishlistActionsTypes
} from "../actions/wishlistActions/wishlistActionsTypes";

// Default state interface.
interface DefaultStateI {
  loading: string,
  actionStatus?: any,
  data?: any
}

// Default state.
const defaultState: DefaultStateI = {
  loading: '',
};

const WishlistReducer = (state: DefaultStateI = defaultState, action: WishlistActionsTypes) : DefaultStateI => {
  switch (action.type) {
    case ADD_FROM_WISHLIST_FAIL:
      return {
        ...state,
        loading: '',
        actionStatus: {message: action.payload, status: 'error'}
      }
    case ADD_FROM_WISHLIST_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case ADD_TO_WISHLIST:
      return {
        ...state,
        loading: '',
        actionStatus: {message: action.payload, status: 'success'}
      }
    case ADD_FROM_WISHLIST:
      return {
        ...state,
        loading: '',
        actionStatus: {message: action.payload, status: 'success'}
      }
    case GET_WISHLIST:
      return {
        ...state,
        loading: '',
        data: action.payload,
      }
    case DELETE_FROM_WISHLIST:
      return {
        ...state,
        loading: '',
        actionStatus: {message: action.payload, status: 'success'}
      }
    case ADD_TO_WISHLIST_EXISTS:
      return {
        ...state,
        loading: '',
        actionStatus: {message: action.payload, status: 'error'}
      }
    default:
      return state
  }
};


export default WishlistReducer;
