// React.
import React from "react";

// Material UI
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

// Translations.
import { useTranslation } from "react-i18next";

// Styles.
import useStyles from "./BulkItemTitleStyles";

const BulkItemTitle = () => {
	const classes = useStyles();
	const [t] = useTranslation("global");

	return (
		<div className={classes.root}>
			<Paper elevation={3}>
				<div className={classes.boxTop}>
					<Typography variant="h5" color="primary">
						{t("items.bulk-title")}
					</Typography>
				</div>
				<div className={classes.boxForm}>
					<Typography variant="body1" color="textPrimary">
						{t("items.bulk-description")}
					</Typography>
				</div>
			</Paper>
		</div>
	);
};

export default BulkItemTitle;
