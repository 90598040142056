// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldInput: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    formControl: {
      width: '100%'
    }
  }),
);

export default useStyles;
