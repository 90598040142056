// React.
import React from 'react';

// Material UI.
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

// Components.
import ItemXlsxSettings from '../settings/ItemXlsxSettings';
import ListXlsxSettings from '../settings/ListXlsxSettings';

// Styles.
import useStyles from './SettingsStyles';

const ItemXlsxSettingsPage = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={3} square={false} variant="elevation">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <ItemXlsxSettings />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ListXlsxSettings />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ItemXlsxSettingsPage;
