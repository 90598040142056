// React.
import React, { Fragment } from "react";

// Material UI.
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

// Styles.
import useStyles from "./UseTermsStyles";

const UseTerms = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Paper
          elevation={3}
          square={false}
          variant="elevation"
          className={classes.errorWrapper}
        >
          <div>
            <h2 style={{
              textAlign: 'center'
            }}>Por favor lea cuidadosamente estos Términos y Condiciones de Uso (Términos y Condiciones), antes de ingresar a cualquiera de nuestros servicios.</h2>
            <h1>Términos y Condiciones</h1>
            <ul>
              <li>
                <p>El acceso al Sitio Web de propiedad de <strong style={{fontFamily: 'system-ui'}}>OMEGA Card Game Store</strong> implica la  aceptación  del  usuario  de  las  normas  de  uso  que  se  establecen  para  el mismo, así como la aceptación del contenido de la cláusula de Exención de Responsabilidad,  de  los  Derechos  de  la  Propiedad  Intelectual  aplicables (diseños  y  arte  son  propiedad  de  OMEGA  Card  Game  Store)  y  de  nuestra Política   de   Privacidad   y   Seguridad.   El   Usuario   deberá   consultar   las condiciones  específicas  que  se  establecen  para  el  acceso  y  uso  de determinados servicios dentro del Sitio Web de <strong style={{fontFamily: 'system-ui'}}>OMEGA Card Game Store</strong>. El  acceso  a  dichos  servicios  implica  igualmente  la  aceptación  de  dichas cláusulas por parte del Usuario.</p>
              </li>
              <li>
                <p>El acceso al Sitio es gratuito y no genera relación comercial, contractual o profesional alguna entre sus usuarios y <strong style={{fontFamily: 'system-ui'}}>OMEGA Card Game Store</strong> , hasta el momento de realizar una compra, al generar la compra o la pre compra se crea una relación comercial entre el usuario y el sitio web.</p>
              </li>
              <li>
                <p>El Sitio puede contener accesos a foros con moderador, que pueden presentar opiniones personales y otras expresiones de quienes participan de ellos, en una amplia variedad de temas. El contenido de esos foros y ligas que se incluyan desde ellos a otros Sitios externos, son revisados, monitoreados o aprobados por <strong style={{fontFamily: 'system-ui'}}>OMEGA Card Game Store</strong>. <strong style={{fontFamily: 'system-ui'}}>OMEGA Card Game Store</strong> es el editor de los contenidos de estos foros, o de los Sitios a los que se pueda acceder a través de ellos.</p>
              </li>
              <li>
                <p>El texto, y cualquier otro material publicado en estos foros, muestran la opinión de un autor específico y no corresponden a la opinión de <strong style={{fontFamily: 'system-ui'}}>OMEGA Card Game Store</strong> y su representante legal.  </p>
              </li>
              <li>
                <p>El Usuario está de acuerdo en no ingresar, o intentar ingresar a la cuenta de otro usuario, o falsificar o intentar falsificar su identidad mientras usa el Sitio o alguno de los sistemas para los que tenga permiso de acceso.</p>
              </li>
            </ul>

            <p><strong style={{fontFamily: 'system-ui'}}>El usuario está de acuerdo con las normas de uso del Sitio.</strong></p>

            <p>Tampoco podrá enviar por correo electrónico, o publicar ninguno de los siguientes contenidos en el Sitio:</p>

            <p>Contenido que <strong style={{fontFamily: 'system-ui'}}>difame o amenace</strong> a otras personas.</p>

            <p>Declaraciones que se puedan considerar <strong style={{fontFamily: 'system-ui'}}>fanáticas, de odio o racialmente ofensivas.</strong></p>

            <p>Contenido que discuta actividades <strong style={{fontFamily: 'system-ui'}}>ilegales</strong> con la intención de cometerlas.</p>

            <p><strong style={{fontFamily: 'system-ui'}}>Contenido que infrinja la propiedad intelectual de otros</strong>, incluyendo, aunque no limitado a, copyrights, marcas registradas o negociar secretos.</p>

            <p>Material que contenga lenguaje o imágenes que puedan ser consideradas <strong style={{fontFamily: 'system-ui'}}>vulgares u obscenas</strong>.</p>

            <p>Publicidad o cualquier otra forma de solicitud comercial.</p>

            <p><strong style={{fontFamily: 'system-ui'}}>El material protegido por Copyright, o por cualquier otro derecho legal, por ejemplo: software, gráficos, texto, fotografías y sonidos</strong>.</p>
          </div>
        </Paper>
      </Container>
      <Container maxWidth="md">
        <Paper
          elevation={3}
          square={false}
          variant="elevation"
          className={classes.errorWrapper}
        >
          <div>
            <h1>Políticas de privacidad</h1>

            <p>La recolección y uso de la información personal y de usuario como de compras, se respetará su derecho a la privacidad, y no se proveerá a terceras personas la información personal de sus usuarios, sin su consentimiento, de acuerdo a la LEY 8968 DE PROTECCIÓN DE LA PERSONA FRENTE AL TRATAMIENTO DE SUS DATOS PERSONALES.</p>
            <p>Los derechos de uso de datos personales se limitan únicamente a base de datos para envíos o publicidad, con la aceptación de correos promocionales, <strong style={{fontFamily: 'system-ui'}}>OMEGA Card Game Store</strong> se compromete a no compartir información privada de sus clientes y usuarios a terceros.</p>
            <p><strong style={{fontFamily: 'system-ui'}}>OMEGA Card Game Store</strong> se compromete a no recolectar, almacenar ni transmitir datos sensibles de los usuarios (estipulados por la el articulo 7 de la ley 8968) sin el consentimiento de su titular.</p>
            <p>Se garantiza el derecho de toda persona al acceso de sus datos personales, rectificación o supresión de estos y a consentir la cesión de sus datos. Si desea eliminar sus datos, por favor enviar la solicitud por escrito a <a href="mailto:info@omegaenlinea.com"><strong style={{fontFamily: 'system-ui'}}>info@omegaenlinea.com </strong></a></p>
          </div>
        </Paper>
      </Container>
      <Container maxWidth="md">
        <Paper
          elevation={3}
          square={false}
          variant="elevation"
          className={classes.errorWrapper}
        >
          <div>
            <h1>Políticas de compra y garantía</h1>

                <p><strong style={{fontFamily: 'system-ui'}}>Términos para ventas y compras en el sitio web</strong>. Será necesaria la creación de una cuenta de usuario (nombre completo, correo electrónico, dirección)</p>

                <p><strong style={{fontFamily: 'system-ui'}}>Términos de compra</strong>. El usuario al momento de realizar la compra (pago) inmediatamente está aceptando que se convierte en cliente y usuario de la plataforma,</p>

                <p><strong style={{fontFamily: 'system-ui'}}>Políticas de anulación de pedido (compra).</strong></p>

                <p>Desde el momento del pago cuenta con un periodo de una hora hasta un máximo de seis horas, para realizar la anulación del pedido  y esta solicitud debe ser de manera escrita y además adjuntarse comprobante de pago, por el medio oficial de contacto correo: <strong style={{fontFamily: 'system-ui'}}>info@omegaenlinea.com</strong> y adicionalmente como medio de notificación el <a href="tel:+50660475959"><strong style={{fontFamily: 'system-ui'}}>teléfono 6047-5959</strong></a> para tramitar la devolución,  en caso de calificar a la devolución esta se realiza  a la tarjeta o cuenta desde donde se realizó el pago.</p>

                <p><strong style={{fontFamily: 'system-ui'}}>Términos y condiciones sobre  garantía</strong>: La garantía es de 30 días a partir de la entrega del producto, para su garantía únicamente defectos de fábrica, y con su respectivo comprobante de compra. El medio oficial de contacto correo: <a href="mailto:info@omegaenlinea.com"><strong style={{fontFamily: 'system-ui'}}>info@omegaenlinea.com </strong></a> y adicionalmente como medio de notificación el <a href="tel:+50660475959"><strong style={{fontFamily: 'system-ui'}}>teléfono 6047-5959</strong></a> para solicitar la garantía en los casos que califique. </p>

                <p><strong style={{fontFamily: 'system-ui'}}>Métodos de pagos aceptados por el sitio web</strong>.  Son válidos pagos realizados en moneda Nacional (<strong style={{fontFamily: 'system-ui'}}>Colones</strong>) y adicionalmente <strong style={{fontFamily: 'system-ui'}}>dólares</strong> estadounidenses y adicionalmente pagos digitales son aceptados mediante transferencias bancarias, y pagos con tarjetas de debido y crédito respaldadas bajo el sistema de transacciones   Master Card y Visa.</p>

                <p><strong style={{fontFamily: 'system-ui'}}>Políticas para Pre Compras</strong> (Pre Orden) Mediante la orden de Pre Compra se crea una relación comercial entre <strong style={{fontFamily: 'system-ui'}}>OMEGA Card Game Store</strong> y el usuario.</p>

                <p><strong style={{fontFamily: 'system-ui'}}>Política de desistimiento de Pre Compra</strong>. A partir del momento de la relación comercial (pago) el usuario cuenta con un periodo de veinticuatro horas   desde el pago, para solicitar de manera escrita el retiro de la oferta de Pre Compra por el medio oficial de contacto correo: <strong style={{fontFamily: 'system-ui'}}>info@omegaenlinea.com</strong> para tramitar la gestión, en caso de calificar para el desistimiento las devoluciones se realizan a la misma tarjeta o cuenta con la cual se ejecutó el pago</p>

                <p>Finalizado el plazo para el desistimiento de Pre compra <strong style={{fontFamily: 'system-ui'}}>OMEGA Card Game Store</strong> legalmente no se hacen responsable y no hay devolución.</p>

                <p><strong style={{fontFamily: 'system-ui'}}>Política de cargos adicionales y de envíos.</strong>  La tarifa por los servicios y cualquier otro cargo que pueda incurrir en relación con el uso del servicio, como los impuestos y las posibles tarifas de transacción, se cobrarán a cuenta del comprador (I.V.A, Comisiones adicionales).</p>

                <p>Los envíos se realizan por medio de correos de Costa Rica, el cargo de envió es un costo adicional el cual el cliente debe asumir que va desde los tres mil colones como mínimo, y hasta un máximo según peso del envío. Con un periodo de entrega de 5 días hábiles siempre y cuando sea dentro del Gran Área Metropolitana, fuera de este territorio, podría variar hasta un máximo de 30 días hábiles a partir del momento de la compra (tiempo aproximado)  </p>
          </div>
        </Paper>
      </Container>
    </Fragment>
  );
};

export default UseTerms;
