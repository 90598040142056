// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
    fileInput: {
      display: 'none'
    },
    loadingIcon: {
      width: '20px !important',
      height: '20px !important'
    },
    formRow: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(2),
    },
    fieldLabel: {
      marginRight: theme.spacing(4),
      minWidth: '260px'
    }
  }),
);

export default useStyles;
