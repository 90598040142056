import { Dispatch } from "redux";
import axios from 'axios';
import { UTILITY_FAIL, UTILITY_LOADING, GET_DOLAR_PRICE, UtilityDispatchTypes } from "./UtilityActionsTypes";

const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;

/**
 * Get the dolar price.
 */
export const getDolarPrice = () => async (dispatch: Dispatch<UtilityDispatchTypes>) => {

  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: UTILITY_LOADING,
        payload: 'GET_DOLAR'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      let queryUrl = `${apiURL}/guest/getDolarPrice`;
      const res = await axios.get(queryUrl, {
        headers: headers
      });

      if (res.status === 200) {
        const queryData = res.data.data;

        dispatch({
          type: GET_DOLAR_PRICE,
          payload: queryData
        })
      } else {
        dispatch({
          type: UTILITY_FAIL
        })
      }
    } catch (e) {
      dispatch({
        type: UTILITY_FAIL
      })
    }
  } else {
    dispatch({
      type: UTILITY_FAIL
    })
  }
};
