// React.
import React, { Fragment, useEffect, useState } from 'react';

// Material UI.
import { Button, CircularProgress, FormControl, Grid, MenuItem, Paper, Select, Typography } from "@material-ui/core";

// Styles.
import useStyles from './PrizesTopStyles';

// Translations.
import { useTranslation } from 'react-i18next';

// Components.

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getEventPrizeDetails, managePrizeToEvent } from '../../../actions/prizeActions/PrizeActions';
import { getEventById } from '../../../actions/eventActions/eventActions';
import { RootStore } from "../../../Store";

interface EventEditProps {
  eventId: string;
};

const PrizesTop = ({ eventId }: EventEditProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();

  useEffect(() => {
    // Get the item details
    dispatch(getEventPrizeDetails(eventId));
    dispatch(getEventById(eventId));

    // eslint-disable-next-line
  }, []);

  // Redux state.
  const reduxEventState = useSelector((state: RootStore) => state.events);
  const reduxPrizeState = useSelector((state: RootStore) => state.prize);
  const { eventDetail } = reduxEventState;
  const { eventPrizeDetails, loading, actionStatus } = reduxPrizeState;

  // Define local state for the event.
  const [prizeList, setPrizeList] = useState<Array<any>>([]);

  useEffect(() => {
    if (actionStatus !== undefined) {
      if (actionStatus.message === 'status.success-update' && actionStatus.status === 'success') {
        // Get the item details
        dispatch(getEventPrizeDetails(eventId));
      }
    }

    // eslint-disable-next-line
  }, [actionStatus]);

  useEffect(() => {
    if (eventPrizeDetails?.baseAwardsData !== undefined) {
      // Build a prize list state.
      const prizeArray:any[] = [];
      eventPrizeDetails?.baseAwardsData.forEach((award: any, i: number) => { 
        prizeArray.push( {
          eventPrizeId: award.prizeId,
          quantity: award.customQuantity
        })
      });

      setPrizeList(prizeArray);
    }
    // eslint-disable-next-line
  }, [eventPrizeDetails]);

  const awardOnClick = () => {
    if (prizeList !== undefined) {
      dispatch(managePrizeToEvent(prizeList, eventId));   
    }
  };

  // Quantities to render.
  const renderOptions = (quantity: number) => {
    const items = [];
    items.push(<MenuItem key={0} value={0}>{0}</MenuItem>);
    for (var i = 1; i <= quantity; i++) {
      items.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
    }
    
    return items;
  };

  const handleQuantityChange = (award: any,  value: unknown | string) => {
    if (award !== undefined && value !== undefined) {

      const newPrizeList = prizeList.map((prizes) => {
        if (prizes.eventPrizeId === award.prizeId) {
          return {...prizes, quantity: value};
        }
        return prizes;
      });
    
      setPrizeList(newPrizeList);
    }
  }

  if (eventPrizeDetails?.baseAwardsData === null || eventPrizeDetails?.baseAwardsData.length === 0) {
    return (
      <div className={ classes.root + ` ` + classes.rootTotal }>
        <Paper className={ classes.paperAlign } elevation={3} square={false} variant="elevation">
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Typography variant="h4" color="primary" className={ classes.sectionTitle }>
                { t('prizes.title-prizes-top') } {(loading === 'PRIZE_EVENT_DETAIL') && <CircularProgress/>}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <div>
                <Typography className={ classes.centerText } color="primary">
                  { t('prizes.no-results-top') }
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }

  return (
    <div className={ classes.root }>
      <Paper className={ classes.paperAlign } elevation={3} square={false} variant="elevation">
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Typography variant="h4" color="primary" className={ classes.sectionTitle }>
              { t('prizes.title-prizes-top') } {(loading === 'PRIZE_EVENT_DETAIL' || loading === 'MANAGE_PRIZE_TO_EVENT') && <CircularProgress/>}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <div className={ classes.paddingBottom }>
              { (eventPrizeDetails?.baseAwardsData !== undefined && prizeList.length !== 0) && 
                eventPrizeDetails?.baseAwardsData.map((award: any, i: number) => 
                <Fragment key={i}>
                  <div className={classes.quantityWrapper}>
                    <FormControl>
                      <Grid container spacing={1}>
                        <Grid item xs={2} sm={6}>
                          <Typography variant="caption">{ award.prizeName }</Typography>
                        </Grid>
                        <Grid className={classes.centerText} item xs={6} sm={6}>
                          <Select
                            id={`${award.prizeId}`}
                            className={classes.inputQty}
                            value={(prizeList[i]?.quantity) ? prizeList[i]?.quantity : 0}
                            onChange={(e) => handleQuantityChange(award, e.target.value)}
                          >
                            {renderOptions(eventDetail?.totalPlayers? eventDetail.totalPlayers: 8)}
                          </Select>
                        </Grid>
                      </Grid>
                    </FormControl>
                  </div>                      
                </Fragment>)
              }
            </div>
            <div>
              <Typography className={ classes.centerText } variant='body2'>
                { t('prizes.note-prizes-top') }
              </Typography>
            </div>
            <div>
              <FormControl className={ classes.formControl }>
                <div className={ classes.actionsWrapper + ` ` + classes.actionsWrapperFull }>
                  <Button variant="contained" color="primary" onClick={ awardOnClick } disabled={eventDetail?.totalPlayers? false : true}>
                    { t('prizes.btn-prizes-top') }
                  </Button>
                </div>
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default PrizesTop;