// Material UI
import grey from '@material-ui/core/colors/grey';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        '& .MuiTableCell-sizeSmall': {
          paddingRight: 0,
          paddingLeft: 0,
          '& .MuiIconButton-root': {
            padding: 0
          }
        }
      },
    },
    shippingOptionsWrapper: {
      padding: theme.spacing(3, 0),
      display: 'flex',
      flexWrap: 'wrap',
      '& .MuiCard-root': {
        backgroundColor: '#f1f1f1',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          marginBottom: theme.spacing(1)
        },
      },
      '& .selected': {
        border: '2px solid',
        borderColor: theme.palette.primary.main
      },
      '& .MuiCardContent-root': {
        padding: theme.spacing(3, 5)
      }
    },
    vendorTable: {
      margin: theme.spacing(2, 0),
    },
    itemRow: {
      padding: theme.spacing(0, 1)
    },
    itemLabel: {
      marginBottom: theme.spacing(1)
    },
    phoneTable: {
      '& tbody .MuiTableCell-root': {
        borderBottom: 0
      },
      '& thead .required': {
        color: theme.palette.error.main,
        verticalAlign: 'middle',
        fontSize: '16px'
      }
    },
    userPhoneIcon: {
      width: '30px',
      height: '30px',
      marginTop: '12px',
      marginRight: '20px',
      color: theme.palette.primary.main
    },
    phoneError: {
      color: theme.palette.error.main,
      margin: 0,
      fontSize: '0.75rem',
      marginTop: '3px',
      textAlign: 'left',
      fontWeight: 400,
      lineHeight: '1.66'
    },
    formField: {
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      '& .Mui-disabled:before': {
        borderBottom: 0
      },
      '&.Mui-disabled:before': {
        borderBottom: 0
      },
      '& svg': {
        display: 'none'
      },
      '& > div': {
        width: '100%',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
    },
    addressGroup: {
      borderRadius: '4px',
      border: '1px solid',
      borderColor: grey[400],
      padding: theme.spacing(2, 1),
      cursor: 'pointer',
      '& .Mui-disabled': {
        cursor: 'pointer',
      },
      '&.selected': {
        borderColor: theme.palette.primary.main,
        border: '2px solid',
      },
    },
    formControl: {
      margin: theme.spacing(1),
      display: 'block',
      '& > *': {
        display: 'block',
      }
    },
    noPadding: {
      paddingLeft: '',
      paddingRight: '0 !important',
    },
    formControlVendor: {
      width: '304px',
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        width: '234px'
      },
      '& .MuiInputBase-root': {
        maxHeight: '32px'
      }
    },
    addressLabel: {
      position: 'absolute',
      top: '-9px',
      background: 'white',
      padding: theme.spacing(0, 1)
    },
    formControlAddress: {
      margin: theme.spacing(0),
      '& .MuiFormLabel-root': {
        color: grey[500]
      }
    },
    formControlUserPhone: {
      margin: theme.spacing(0),
      width: '254px',
      [theme.breakpoints.down('xs')]: {
        width: '182px',
      },
      '& .MuiFormLabel-root': {
        color: grey[500]
      }
    },
    actionsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      '& button': {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          marginLeft: theme.spacing(1),
        },
      }
    },
    addAddress: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      margin: theme.spacing(3, 1)
    },
    shippingDescription: {
      fontStyle: 'italic',
      fontSize: '0.9rem'
    },
    pickUpContainer:{
      display: 'flex',
      flexDirection: 'column',
      padding: '8px',
      '& .MuiFormControlLabel-root':{
        display: 'flex',
        gap: '4px'
      }
    },
    identificationPickUpContainer:{
      display: 'flex',
      flexDirection: 'column',
      fontSize: '0.9rem',
      marginTop: '-12px',
      marginLeft: '40px',
      '& div':{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: '-4px', 
        marginLeft: '17px',
      },
      '& .MuiInputBase-root':{
        width: '200px',
        fontSize: '14px',
        [theme.breakpoints.down('xs')]: {
          width: '120px',
          fontSize: '12px'
        },
      },
      '& .MuiTypography-root':{
        fontSize: '12px'
      }
    }
  }),
);

export default useStyles;
