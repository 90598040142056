// React.
import React, { useEffect } from 'react';

// Material UI.
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';

// Redux.
import { getAllIconsUrl } from "../../../actions/navActions/NavActions";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../Store";

// Styles
import useStyles from './NavigationStyles';

const NavigationIcons = () => {
  const classes = useStyles();
  const dispatch = useDispatch();


  useEffect(() => {
    if (!navLoading) {
      dispatch(getAllIconsUrl());
    }

    // eslint-disable-next-line
  }, []);

  // Redux state.
  const navState = useSelector((state: RootStore) => state.nav);
  const { navLoading, allIconsUrl } = navState;

  return (
    <div className={classes.root}>
      {allIconsUrl?.map(imgUrl =>
        <Card className={classes.listCard}>
          <Grid container>
            <Grid item md={3}>
              <img className={classes.listImg} src={imgUrl} alt="" />
            </Grid>
            <Grid item md={9} className={classes.listUrl}>
              <p>URL: {imgUrl}</p>
            </Grid>
          </Grid>
        </Card>
      )}
    </div>
  )
}

export default NavigationIcons;
