/**
 *****************************************
 *********** Dispatch Types **************
 *****************************************
 */
 export const ADD_FROM_WISHLIST_FAIL = "ADD_FROM_WISHLIST_FAIL";
 export const ADD_FROM_WISHLIST_LOADING = "ADD_FROM_WISHLIST_LOADING";
 export const DELETE_FROM_WISHLIST = "DELETE_FROM_WISHLIST";
 export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
 export const ADD_TO_WISHLIST_EXISTS = "ADD_TO_WISHLIST_EXISTS";
 export const ADD_FROM_WISHLIST = "ADD_TO_WISHLIST";
 export const GET_WISHLIST = "GET_WISHLIST";
 
 /**
  *****************************************
  *********** Interfaces Types ************
  *****************************************
  */

 export interface WishlistLoading {
   type: typeof ADD_FROM_WISHLIST_LOADING
   payload: string
 }
 export interface WishlistFail {
   type: typeof ADD_FROM_WISHLIST_FAIL,
   payload: string
 }
 export interface WishlistDelete {
   type: typeof DELETE_FROM_WISHLIST,
   payload: string
 }
 export interface WishlistAdd {
   type: typeof ADD_TO_WISHLIST,
   payload: string
 }
 export interface WishlistAddExists {
  type: typeof ADD_TO_WISHLIST_EXISTS,
  payload: string
}
 export interface WishlistAddFrom {
  type: typeof ADD_FROM_WISHLIST,
  payload: string
}
 export interface WishlistGet {
  type: typeof GET_WISHLIST,
  payload: string
}

 
 // Export types.
 export type WishlistActionsTypes = WishlistLoading | WishlistFail | WishlistDelete | WishlistAdd | WishlistGet | WishlistAddFrom | WishlistAddExists;
 