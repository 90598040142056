// React.
import React, { useEffect } from "react"

// Components.

// Translations.
import { useTranslation } from "react-i18next"

// Redux
import { useDispatch } from "react-redux"

// Moment.

// Styles.
import { Box, Tab, Tabs } from "@material-ui/core"
import DateAdapter from "@mui/lab/AdapterMoment"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import jwtDecode from "jwt-decode"
import { useHistory, useLocation } from "react-router-dom"
import { refreshDeliveryView } from "../../../actions/deliveryActions/DeliveryActions"
import useStyles from "../../client/deliveries/DeliveryListStyles"
import InventoryRequestAccordion from "./InventoryRequestsAccordion"

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}
const InventoryRequestsList = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [t] = useTranslation("global")
  const history = useHistory()
  const location = useLocation()
  const decodedToken: any =
    localStorage.token !== undefined && localStorage.token !== ""
      ? jwtDecode(localStorage.token)
      : false
  // Local state.
  const [filterTab, setFilterTab] = React.useState<number>(0)
  const [pageByTab, setPageByTab] = React.useState<{ [key: number]: number }>(
    {}
  )

  const isAdmin = decodedToken
    ? decodedToken.auth.includes("ROLE_ADMIN")
    : false

  const handleChangeFilterTab = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setFilterTab(newValue)
    handleSelected(newValue)
  }

  const handleSelected = (selectedTab: number) => {
    // When filter changes go to the root page.
    const queryParams = new URLSearchParams(location.search)

    if (queryParams.has("page")) {
      queryParams.delete("page")
    }

    const currentPage = pageByTab[selectedTab] || 1
    queryParams.set("page", currentPage.toString())

    history.replace({
      search: queryParams.toString(),
    })

    dispatch(refreshDeliveryView(true))
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const currentPage = parseInt(params.get("page") || "1", 10)
    setPageByTab((prev) => ({ ...prev, [filterTab]: currentPage }))
  }, [filterTab])

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <div className={classes.root}>
        {!isAdmin && (
          <div className={classes.filters}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              style={{ width: "100%" }}
            >
              <Tabs
                value={filterTab}
                onChange={handleChangeFilterTab}
                aria-label="basic tabs example"
                indicatorColor="primary"
              >
                <Tab label={t("deliveries.sendeds")} {...a11yProps(0)} />
                <Tab label={t("deliveries.receiveds")} {...a11yProps(1)} />
              </Tabs>
            </Box>
          </div>
        )}

        <div>
          <InventoryRequestAccordion
            filterTab={filterTab}
            pageByTab={pageByTab}
          />
        </div>
      </div>
    </LocalizationProvider>
  )
}

export default InventoryRequestsList
