import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root:{},
    modal:{
        position:'fixed',
        top:'0',
        left:'0',
        width:'100%',
        height:'100%',
        zIndex:1300,
        backgroundColor:'rgba(0, 0, 0, 0.5)',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    modalContent:{
        position:'absolute',
        width:'auto',
        height:'670px',
        maxWidth:'1170px',
        backgroundColor:'#fff',
        padding:'20px',
        borderRadius:'5px',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
    },
    orderList:{
        display:'flex',
        justifyContent:'center',
        gap:'20px'
    },
    titleBox:{
        display:'flex', 
        justifyContent:'space-between',
        width:'100%',
        marginBottom:'15px'
    },
    columnOnePaper:{
        maxWidth:'546px',
        width:'80%',
        display:'inline-block',
        flexDirection:'column',
        justifyContent:'center',
        marginBottom:'20px'
    }
}))

export default useStyles;