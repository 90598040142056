// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(2, 4, 4, 4),
        padding: theme.spacing(4, 4, 4),
        width: '100%',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(4, 2, 2),
          margin: theme.spacing(2, 1, 4, 1),
        },
      },
      '& > .MuiSvgIcon-colorPrimary': {
        color: theme.palette.primary.main,
        cursor: 'pointer'
      },
    },
    rootTable: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0),
        padding: theme.spacing(1, 1, 1),
        width: '100%',
        height: 'auto',
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(2, 2, 2),
        },
      },
    },
    buttons: {
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
      '& > *': {
        margin: theme.spacing(1),
      },
      '& > button': {
        padding: theme.spacing(1, 10)
      },
    },
    buttonsLast: {
      marginRight: 0
    },
    sectionTitle: {
      marginBottom: theme.spacing(2),
      textTransform: 'uppercase',
    },
    customTable: {
      '& .header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        padding: '4px 4px',
        backgroundColor: theme.palette.grey[600],
        color: 'white',
        fontFamily: 'VisbyCF-Bold',
        borderRadius: '6px',
        textAlign: 'center'
      },
      '& .row': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        padding: '4px 4px',
        textAlign: 'center'
      },
      '& .body': {
        display: 'block',
        position: 'relative',
        maxHeight: '726px',
        overflow: 'auto'
      },
      '& .body:last-of-type .row': {
        borderBottom: 'initial',
      },
      '& .cell': {
        '& > .MuiSvgIcon-colorPrimary': {
          color: theme.palette.primary.main,
          cursor: 'pointer'
        },
        '&.d30': {
          width: '30%'
        },
        '&.d28': {
          width: '28%'
        },
        '&.d20': {
          width: '20%'
        },
        '&.d12': {
          width: '12%',
        },
        '&.d10': {
          width: '10%',
        },
        '&.d100': {
          width: '100%'
        },
        '&.right': {
          textAlign: 'right'
        },
        '&.priceCol': {
          '& .MuiTypography-root': {
            display: 'inline-block',
            marginRight: theme.spacing(1)
          }
        },
        [theme.breakpoints.down('sm')]: {
          '&.m20': {
            width: '20%'
          },
          '&.m25': {
            width: '25%'
          },
          '&.d10 .MuiButtonBase-root': {
            padding: '5px',
          },
          '&.small p': {
            fontSize: '14px'
          }
        }
      }
    },
    quantityWrapper: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiFormControl-root': {
        width: '100%',
        '& .MuiInputBase-root:before': {
          display: 'none'
        }
      },
      '& .totalQty': {
        backgroundColor: theme.palette.grey[100],
        padding: '6px 9px',
        width: '50%',
      }
    },
    inputQty: {
      border: `1px solid ${theme.palette.grey[300]}`,
      padding: '0 3px',
      width: '58px',
      margin: '0 auto',
      [theme.breakpoints.down('xs')]: {
        width: '38px',
      },
      '& .MuiSelect-icon': {
        right: '-3px'
      },
      '& .MuiSelect-select': {
        paddingRight: '8px'
      }
    },
    formControl: {
      // margin: theme.spacing(1),
      display: 'block',
      '& > *': {
        display: 'block',
      }
    },
    formControlAddress: {
      margin: theme.spacing(0),
      '& .MuiFormLabel-root': {
        color: grey[500]
      }
    },
    formField: {
      width: '100%',
      '& .Mui-disabled:before': {
        borderBottom: 0
      },
      '&.Mui-disabled:before': {
        borderBottom: 0
      },
      '& svg': {
        display: 'none'
      },
      '& > div': {
        width: '100%',
      },
    },
    noAddress: {
      margin: theme.spacing(2, 1)
    },
    playerName: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      textAlign: 'initial',
      '& > .MuiSvgIcon-colorPrimary': {
        color: theme.palette.primary.main,
        cursor: 'pointer'
      },
    },
    title:{
      display:'flex',
      flexDirection:'row',
      justifyContent:'start',
      alignItems:'flex-start'
    },
    circularProgress: {
      textAlign: 'center',
    },
    itemClickable: {
      cursor: 'pointer'
    },
    switch: {
      '& .MuiSwitch-switchBase' : {
        color: '#515153'
      },
      '& .MuiSwitch-track' : {
        backgroundColor: '#515153'
      },
      '& .MuiSwitch-colorSecondary.Mui-checked' : {
        color: '#2F983F'
      },
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track' : {
        backgroundColor: '#2F983F'
      },
      '& .MuiSwitch-colorSecondary.Mui-disabled' : {
        color: '#bdbdbd'
      },
      '& .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track' : {
        backgroundColor: '#B0B1B3'
      }
    }
  }),
);

export default useStyles;
