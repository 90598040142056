import {Dispatch} from "redux";
import axios from 'axios';
import aesjs from 'aes-js';
import JSEncrypt from "jsencrypt";
import { DELETE_CART, ADD_CART, CART_FAIL, CART_LOADING, CHECKOUT_CART, CartActionsTypes } from "./CartActionsTypes";

const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;
let encrypt = new JSEncrypt();

/**
 * Add an item to the cart.
 */
export const addItemToCartLocal = () => async (dispatch: Dispatch<CartActionsTypes>) => {
  try {
    dispatch({
      type: ADD_CART,
      payload: 'status.add-cart'
    })
    
  } catch(e) {
    dispatch({
      type: CART_FAIL,
      payload: 'status.cart-error'
    })
  }
}

/**
 * Delete an item to the cart.
 */
 export const deleteItemToCartLocal = () => async (dispatch: Dispatch<CartActionsTypes>) => {
  try {
    dispatch({
      type: DELETE_CART,
      payload: 'status.delete-cart'
    })
    
  } catch(e) {
    dispatch({
      type: CART_FAIL,
      payload: 'status.cart-error'
    })
  }
}

/**
 * Add an item to the cart.
 * @param {string} itemId
 * @param {string} quantity
 * @param {string} customReference
 */
export const addItemToCart = (itemId: string, quantity: string, customReference:string = '') => async (dispatch: Dispatch<CartActionsTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: CART_LOADING,
        payload: 'ADD_CART'
      })

      const bodyForm = {
        "id": itemId,
        "quantity": quantity,
        ...(customReference) && { "customReference": customReference }
      };

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
    
      const response = await axios.post(`${apiURL}/user/registerItemToCart`, bodyForm, configPUT);
      if (response.status === 200) {
        dispatch({
          type: ADD_CART,
          payload: 'status.add-cart'
        })
      }
       
    } catch(e) {
      dispatch({
        type: CART_FAIL,
        payload: 'status.cart-error'
      })
    }
  } else {
    dispatch({
      type: CART_FAIL,
      payload: 'status.cart-error'
    })
  }
};

/**
 * Add an bundle to the cart.
 * @param {string} bundleId
 * @param {string} quantity
 */
 export const addBundleToCart = (bundleId: string, quantity: string) => async (dispatch: Dispatch<CartActionsTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: CART_LOADING,
        payload: 'ADD_CART'
      })

      const bodyForm = {
        "id": bundleId,
        "quantity": quantity,
      };

      const configPOST = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
    
      const response = await axios.post(`${apiURL}/user/registerBundleToCart`, bodyForm, configPOST);
      if (response.status === 200) {
        dispatch({
          type: ADD_CART,
          payload: 'status.add-cart'
        })
      }
       
    } catch(e) {
      dispatch({
        type: CART_FAIL,
        payload: 'status.cart-error'
      })
    }
  } else {
    dispatch({
      type: CART_FAIL,
      payload: 'status.cart-error'
    })
  }
};

/**
 * Delete an item from the cart.
 * @param {string} itemId
 * @param {string} quantity
 * @param {string} customReference
 */
export const deleteItemFromCart = (itemId: string, quantity: string, customReference: string = '') => async (dispatch: Dispatch<CartActionsTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: CART_LOADING,
        payload: 'DELETE_CART'
      })

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
      let path = (customReference) ? `user/deleteItemInCart/${itemId}/${quantity}/${customReference}` : `user/deleteItemInCart/${itemId}/${quantity}/NA`;
      const response = await axios.delete(`${apiURL}/${path}`, configPUT);
      if (response.status === 200) {
        dispatch({
          type: DELETE_CART,
          payload: 'status.add-cart'
        })
      }
       
    } catch(e) {
      dispatch({
        type: CART_FAIL,
        payload: 'status.cart-error'
      })
    }
  } else {
    dispatch({
      type: CART_FAIL,
      payload: 'status.cart-error'
    })
  }
};

/**
 * Delete an bundle from the cart.
 * @param {string} bundleId
 * @param {string} quantity
 */
 export const deleteBundleFromCart = (bundleId: string, quantity: string) => async (dispatch: Dispatch<CartActionsTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: CART_LOADING,
        payload: 'DELETE_CART'
      })

      const configDELETE = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
    
      const response = await axios.delete(`${apiURL}/user/deleteBundleInCart/${bundleId}/${quantity}`, configDELETE);
      if (response.status === 200) {
        dispatch({
          type: DELETE_CART,
          payload: 'status.delete-cart'
        })
      }
       
    } catch(e) {
      dispatch({
        type: CART_FAIL,
        payload: 'status.cart-error'
      })
    }
  } else {
    dispatch({
      type: CART_FAIL,
      payload: 'status.cart-error'
    })
  }
};

/**
 * Checkout Cart.
 * @param {number} addressId
 * @param {number} shippingId
 * @param {string} cardToken
 * @param {string} paymentMethod
 * @param {string} selectedVendor
 * @param {boolean} usingBalancePoints
 * @param {boolean} onlyPoints
 */
export const checkoutCart = (addressId: number, shippingId: number, cardToken: string, paymentMethod: string, selectedVendor: string, usingBalancePoints: boolean, onlyPoints: boolean, pickupId: string) => async (dispatch: Dispatch<CartActionsTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: CART_LOADING,
        payload: 'CHECKOUT_CART'
      })
      
      const isTransfer = (paymentMethod === 'transferencia' || onlyPoints) ? true : false;

      const cardData = {
        "token": cardToken, 
        "kountSession": `${process.env.MercSessId}`
      }

      const encryptedCardData = pack(cardData, undefined);

      const bodyForm = addressId !== 0 ? 
        { isTransfer: isTransfer, addressId: addressId, shippingId: shippingId, ...encryptedCardData, vendorIdShipping: selectedVendor, usingBalancePoints: usingBalancePoints, pickupId: pickupId } : 
        { isTransfer: isTransfer, shippingId: shippingId, ...encryptedCardData, vendorIdShipping: selectedVendor, usingBalancePoints: usingBalancePoints, pickupId: pickupId }

      const configPOST = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
    
      const addRes = await axios.post(`${apiURL}/user/checkoutCart`, bodyForm, configPOST);
      if (addRes.status === 200) {
        // Get order ids.
        var ids:any = [];
        addRes.data.data.forEach((order:any, index:number) => {
          ids.push(order.id);
        });
        dispatch({
          type: CHECKOUT_CART,
          payload: {message: 'status.success-checkout', orderIds: ids}
        })
      }
    } catch(e) {
      dispatch({
        type: CART_FAIL,
        payload: 'status.order-error'
      })
    }
  } else {
    dispatch({
      type: CART_FAIL,
      payload: 'status.order-error'
    })
  }
};

function pack(obj:any, pair_:any) {

  const generateAESPairs = () => {
      let key = []
      let iv = 0;
      for (let k = 0; k < 16; k++) {
          key.push(Math.floor(Math.random() * 255))
      }
      for (let k = 0; k < 16; k++) {
          iv = Math.floor(Math.random() * 255)
      }
      return {
          k: key,
          s: iv
      }
  }

  var pair = (pair_ !== undefined) ? pair_ : generateAESPairs();
  var textBytes = aesjs.utils.utf8.toBytes(JSON.stringify(obj));
  var aesCtr = new aesjs.ModeOfOperation.ctr(pair.k, new aesjs.Counter(pair.s));
  var encryptedBytes = aesCtr.encrypt(textBytes);
  var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
  var returnObj = {
      ld:encryptedHex,
      lk:encrypt.encrypt(JSON.stringify(pair))
  };
  return returnObj;
}