// React
import React, {useState, useEffect} from 'react'

// Material UI
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

// Styles.
import { useStyles } from './ActionAlertStyles';

// Redux
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";

// Translations.
import { useTranslation } from 'react-i18next';

// Component interface.
interface AlertCustomProps {
  isUserPages?: boolean;
};

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ActionAlert = ({ isUserPages } : AlertCustomProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');

  // Redux state.
  const userState = useSelector((state: RootStore) => state.user);
  const userActionStatus = userState.actionStatus;
  const itemState = useSelector((state: RootStore) => state.item);
  const itemActionStatus = itemState.actionStatus;
  const orderState = useSelector((state: RootStore) => state.order);
  const orderActionStatus = orderState.actionStatus;
  const authState = useSelector((state: RootStore) => state.auth);
  const authActionStatus = authState.actionStatus;
  const settingsState = useSelector((state: RootStore) => state.settings);
  const settingsActionStatus = settingsState.actionStatus;
  const cartState = useSelector((state: RootStore) => state.cart);
  const cartActionStatus = cartState.actionStatus;
  const addressState = useSelector((state: RootStore) => state.address);
  const addressActionStatus = addressState.actionStatus;
  const itemCategoryState = useSelector((state: RootStore) => state.itemType);
  const itemCategoryActionStatus = itemCategoryState.actionStatus;
  const bundleState = useSelector((state: RootStore) => state.bundle);
  const bundleStateActionStatus = bundleState.actionStatus;
  const vendorState = useSelector((state: RootStore) => state.vendors);
  const vendorStateActionStatus = vendorState.actionStatus;
  const navState = useSelector((state: RootStore) => state.nav);
  const navStateActionStatus = navState.actionStatus;
  const eventState = useSelector((state: RootStore) => state.events);
  const eventStateActionStatus = eventState.actionStatus;
  const prizeState = useSelector((state: RootStore) => state.prize);
  const prizeStateActionStatus = prizeState.actionStatus;
  const wishlistState = useSelector((state: RootStore) => state.wishlist);
  const wishlistStateActionStatus = wishlistState.actionStatus;

  // Local component state.
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string>('');
  const [severity, setSeverity] = useState<string>('');

  useEffect(() => {
    // User alerts
    if (userActionStatus !== undefined) {
      setMessage(t(userActionStatus!.menssage));
      setSeverity(userActionStatus!.status);
      setOpen(true);
    }
    // Item alerts
    if (itemActionStatus !== undefined) {
      setMessage(t(itemActionStatus!.menssage));
      setSeverity(itemActionStatus!.status);
      setOpen(true);
    }
    // Order alerts
    if (orderActionStatus !== undefined) {
      setMessage(t(orderActionStatus!.menssage));
      setSeverity(orderActionStatus!.status);
      setOpen(true);
    }
    // Auth alerts
    if (authActionStatus !== undefined) {
      setMessage(t(authActionStatus!.menssage));
      setSeverity(authActionStatus!.status);
      setOpen(true);
    }
    // Settings alerts
    if (settingsActionStatus !== undefined) {
      if (settingsActionStatus!.menssage !== 'status.success-upload-file') {
        setMessage(t(settingsActionStatus!.menssage));
        setSeverity(settingsActionStatus!.status);
        setOpen(true);
      }
    }
    // Cart alerts
    if (cartActionStatus !== undefined) {
      setMessage(t(cartActionStatus!.menssage));
      setSeverity(cartActionStatus!.status);
      setOpen(true);
    }
    // Address alerts
    if (addressActionStatus !== undefined) {
      setMessage(t(addressActionStatus!.menssage));
      setSeverity(addressActionStatus!.status);
      setOpen(true);
    }
    // Item type alerts
    if (itemCategoryActionStatus !== undefined) {
      setMessage(t(itemCategoryActionStatus!.menssage));
      setSeverity(itemCategoryActionStatus!.status);
      setOpen(true);
    }
    // Bundle type alerts
    if (bundleStateActionStatus !== undefined) {
      setMessage(t(bundleStateActionStatus!.menssage));
      setSeverity(bundleStateActionStatus!.status);
      setOpen(true);
    }
    // Vendor type alerts
    if (vendorStateActionStatus !== undefined) {
      setMessage(t(vendorStateActionStatus!.menssage));
      setSeverity(vendorStateActionStatus!.status);
      setOpen(true);
    }
    // Nav type alerts
    if (navStateActionStatus !== undefined) {
      setMessage(t(navStateActionStatus!.menssage));
      setSeverity(navStateActionStatus!.status);
      setOpen(true);
    }
    // Event type alerts
    if (eventStateActionStatus !== undefined) {
      setMessage(t(eventStateActionStatus!.message)); //message
      setSeverity(eventStateActionStatus!.status);  
      setOpen(true);
    }
    // Prize type alerts
    if (prizeStateActionStatus !== undefined) {
      setMessage(t(prizeStateActionStatus!.message)); //message
      setSeverity(prizeStateActionStatus!.status);  
      setOpen(true);
    }
    // Wishlist type alerts
    if (wishlistStateActionStatus !== undefined) {
      setMessage(t(wishlistStateActionStatus!.message));
      setSeverity(wishlistStateActionStatus!.status);
      setOpen(true);
    }

    // eslint-disable-next-line
  }, [userActionStatus, itemActionStatus, orderActionStatus, authActionStatus, 
    settingsActionStatus, cartActionStatus, addressActionStatus, itemCategoryActionStatus, 
    bundleStateActionStatus, vendorStateActionStatus, navStateActionStatus,
    eventStateActionStatus, prizeStateActionStatus, wishlistStateActionStatus])

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const extraClass = isUserPages !== undefined ? 'user-pages' : '';

  return (
    <div className={ classes.root }>
      {(message !== '' && severity !== '') &&
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} className={ extraClass }>
          <Alert onClose={handleClose} severity={severity === 'success' ? 'success' : 'error'}>
            { message }
          </Alert>
        </Snackbar>
      }
    </div>
  )
}

export default ActionAlert;
