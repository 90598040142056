/**
 *****************************************
 *********** Dispatch Types **************
 *****************************************
 */
export const ITEM_LOADING = "ITEM_LOADING";
export const ITEM_FAIL = "ITEM_FAIL";
export const ITEM_LIST = "ITEM_LIST";
export const ITEM_LIST_SEARCH_DATA = "ITEM_LIST_SEARCH_DATA";
export const ITEM_LABEL_LIST = "ITEM_LABEL_LIST";
export const ITEM_SUGGESTED_LABEL_LIST = "ITEM_SUGGESTED_LABEL_LIST";
export const ITEM_LABEL_BY_TYPE_LIST = "ITEM_LABEL_BY_TYPE_LIST";
export const ITEM_PUT = "ITEM_PUT";
export const ITEM_SINGLE_DESC_PUT = "ITEM_SINGLE_DESC_PUT";
export const ITEM_REFRESH = "ITEM_REFRESH";
export const ITEM_SET_FILTER = "ITEM_SET_FILTER";
export const ADD_ITEM = "ADD_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const ADD_ITEM_DISCOUNT = "ADD_ITEM_DISCOUNT";
export const BARCODE_LOADING = "BARCODE_LOADING";
export const BARCODE_SUCCESS = "BARCODE_SUCCESS";
export const BARCODE_NOT_FOUND = "BARCODE_NOT_FOUND";
export const UPDATE_ITEM_DISCOUNT = "UPDATE_ITEM_DISCOUNT";
export const ADD_ITEM_LABEL = "ADD_ITEM_LABEL";
export const UPDATE_ITEM_LABEL = "UPDATE_ITEM_LABEL";
export const ADD_ITEM_LABEL_VALUE = "ADD_ITEM_LABEL_VALUE";
export const UPDATE_ITEM_LABEL_VALUE = "UPDATE_ITEM_LABEL_VALUE";
export const ITEM_LABEL_SAVED = "ITEM_LABEL_SAVED";
export const ITEM_PRICE_QUANTITY_UPDATE_STATUS = "ITEM_PRICE_QUANTITY_UPDATE_STATUS";
export const ITEM_AUTOMATIC_PRICE_PUT = "ITEM_AUTOMATIC_PRICE_PUT";
export const ITEM_SINGLE_PRICE_PUT = "ITEM_SINGLE_PRICE_PUT";
export const ITEM_SEARCH_NAMES = "ITEM_SEARCH_NAMES";
export const ITEM_INDIVIDUAL_PUT = "ITEM_INDIVIDUAL_PUT";
export const ITEM_DETAIL = "ITEM_DETAIL";
export const ITEM_PARTICIPATION_LIST="ITEM_PARTICIPATION_LIST";

/**
 *****************************************
 ************* Object Types **************
 *****************************************
 */
export type ItemCategoryType = {
  id: number,
  taxPercentage: number,
  name: string,
  image_url: string,
  description: string,
  isDeleted: boolean
};

export type ItemCurrencyType = {
  id: number,
  name: string,
  symbol: string,
  code: string,
  isDeleted: boolean
};

export type IndividualItemObj = {
  additionalPrice?: number;
  customReference?: string;
  id: number;
  price?: number;
  quantity?:number;
};

export type ItemImageType = {
  id: number,
  url: string,
  key: string
};

export type ItemWarehouseRelationType = {
  warehouseId: number,
  itemId: number,
  customReference?: string
};

export type ItemWarehouseEntityType = {
  name: string,
  description: string,
  isDiscount: boolean
};

export type ItemWarehouseType = {
  id: ItemWarehouseRelationType,
  quantity: number,
  warehouse: ItemWarehouseEntityType
};

export type ItemLabelValuesType = {
  id: number,
  value: string
};

export type ItemLabelsType = {
  id: number,
  value: string,
  labelValues: ItemLabelValuesType[],
  totalPages:number
};

export type ItemLabelsByCategoryType = {
  typeId: number,
  labels: ItemLabelsType[],
};

export type ItemVendorType = {
  id: number,
  name: string,
};

export type CustomPrice = {
  price: number,
  customReference: string
}

export type ItemType = {
  id: number,
  name: string,
  brand?: string,
  price: number,
  commission: number,
  state: string,
  code?: string,
  weightPerUnit: number,
  minimumQuantity: number,
  costPrice: number,
  customPrices?: Array<CustomPrice>,
  additionalPrice?: number,
  notes: string,
  additionalInfo: string;
  isDeleted: boolean,
  isFeature?: boolean,
  itemType?: ItemCategoryType,
  currency: ItemCurrencyType,
  vendor?: ItemVendorType,
  warehousesQuantities: Array<ItemWarehouseType>,
  warehousesCustomQuantities?: Array<ItemWarehouseType>,
  totalQuantity: number,
  labelValues?: Array<ItemLabelsType>,
  images?: Array<ItemImageType>,
  discounts?: any,
  priceWithTaxes: number,
  creationDate: string,
  lastUpdate?: string,
  files?: Array<File> | null,
  isPublished: boolean,
  category?: string,
  automaticPrice?: boolean,
  isForSale:boolean
};

export type ItemTypeSearchData = {
  additionalInfo: string;
  category?: string,
  code?: string,
  isFeature?: boolean,
  imageURL?: string,
  name: string,
  notes: string,
  itemSearchVendorData?: Array<ItemSearchVendorData>,
  setName: string | null
};

export type ItemSearchVendorData = {
  id: number,
  itemId: number,
  name: string,
  totalQuantity: number,
  itemQuantityData?: Array<ItemQuantityData>,
}

export type ItemQuantityData = {
  customReference?: string,
  discountFinalPrice?: number,
  quantity?: number, 
  finalPrice?: number,
  typeOfWarehouse?: string
}

export type LabelValuesType = {
  labelValue: string,
  labelValueID: number,
  totalLabelValues: number
};

export type LabelListType = {
  labelID: number,
  labelName: string,
  labelValues: LabelValuesType[]
};

export type ItemList = {
  first: boolean,
  last: boolean,
  number: number,
  size: number,
  totalPages: number,
  items: ItemType[],
  labels?: LabelListType[]
};

export type ItemParticipationList = {
  items:any
};

export type ItemListSearchData = {
  first: boolean,
  last: boolean,
  number: number,
  size: number,
  totalPages: number,
  items: ItemTypeSearchData[],
  labels?: LabelListType[],
  dolarValue: number
}

export type ItemPutResponse = {
  message: string
  item: ItemType
};

export type LabelResponse = {
  description: string,
  id: number,
  isDeleted: boolean,
  name: string
};

export type LabelValueResponse = {
  id: number,
  isDeleted: boolean,
  value: string
};

export type ItemLabelResponse = {
  label: LabelValueResponse,
  message: string
  index: number
};

export type ItemLabelValueResponse = {
  labelValue: LabelValueResponse,
  message: string
};

export type ItemSearchName = {
  labelValue: string,
  labelValueId: number,
  name: string
};

export type ItemSearchNamesResponse = {
  itemsName: ItemSearchName[],
  maxResults: number,
  moreItems: boolean
};

export type ItemDatailImages = {
  imageId: number,
  imageURL: string
};

export type ItemDatailQuantityData = {
  customReference: string,
  discountFinalPrice: number,
  finalPrice: number,
  quantity: number,
  typeOfWarehouse: string
};

export type ItemDatailSearchVendorData = {
  id: number,
  itemId: number,
  itemQuantityData: ItemDatailQuantityData[],
  name: string,
  totalQuantity: number
};

export type ItemDatailLabelValues = {
  name: string
};

export type ItemDatailLavels = {
  labelValues: ItemDatailLabelValues[]
  name: string
};

export type ItemDatailResponse = {
  additionalInfo: string,
  category: string,
  code: string,
  dolarValue: string,
  images: ItemDatailImages[],
  itemSearchVendorData: ItemDatailSearchVendorData[],
  itemTypeId: number,
  labels: ItemDatailLavels[],
  name: string,
  notes: string,
  setName: string | null
};


/**
 *****************************************
 *********** Interfaces Types ************
 *****************************************
 */
export interface ItemLoading {
  type: typeof ITEM_LOADING
  payload: string
};

export interface ItemFail {
  type: typeof ITEM_FAIL,
  payload: string
};

export interface ItemUpdate {
  type: typeof ITEM_PUT,
  payload: ItemPutResponse
};

export interface ItemAdd {
  type: typeof ADD_ITEM,
  payload: string,
  item: ItemType
};

export interface BarcodeLoading {
  type: typeof BARCODE_LOADING,
  payload: string
};

export interface BarcodeError {
  type: typeof BARCODE_NOT_FOUND,
  payload: string
};

export interface BarcodeSuccess {
  type: typeof BARCODE_SUCCESS,
  payload: ItemType
};

export interface ItemDelete {
  type: typeof DELETE_ITEM,
  payload: string
}

export interface ItemListType {
  type: typeof ITEM_LIST,
  payload: ItemList
};

export interface ItemParticipationListType {
  type: typeof ITEM_PARTICIPATION_LIST,
  payload: ItemParticipationList
};

export interface GetItemDetailData {
  type: typeof ITEM_DETAIL,
  payload: ItemDatailResponse
};

export interface ItemListSearchDataType {
  type: typeof ITEM_LIST_SEARCH_DATA,
  payload: ItemListSearchData
}

export interface ItemLabelListType {
  type: typeof ITEM_LABEL_LIST,
  payload: ItemLabelsType[]
};

export interface ItemLabelByTypeListType {
  type: typeof ITEM_LABEL_BY_TYPE_LIST,
  payload: ItemLabelsByCategoryType[]
};

export interface ItemRefresh {
  type: typeof ITEM_REFRESH,
  payload: boolean
};

export interface ItemSetFilter {
  type: typeof ITEM_SET_FILTER,
  payload: object
};

export interface ItemAddDiscount {
  type: typeof ADD_ITEM_DISCOUNT,
  payload: string
};

export interface ItemUpdateDiscount {
  type: typeof UPDATE_ITEM_DISCOUNT,
  payload: string
};

export interface ItemLabelAdd {
  type: typeof ADD_ITEM_LABEL,
  payload: ItemLabelResponse
};

export interface ItemLabelUpdate {
  type: typeof UPDATE_ITEM_LABEL,
  payload: ItemLabelResponse
};

export interface ItemLabelValueAdd {
  type: typeof ADD_ITEM_LABEL_VALUE,
  payload: ItemLabelValueResponse
};

export interface ItemLabelValueUpdate {
  type: typeof UPDATE_ITEM_LABEL_VALUE,
  payload: ItemLabelValueResponse
};

export interface ItemSaveLabelsDone {
  type: typeof ITEM_LABEL_SAVED,
  payload: string
};

export interface ItemUpdatePriceQuantityStatus {
  type: typeof ITEM_PRICE_QUANTITY_UPDATE_STATUS,
  payload: string
};

export interface ItemAutomaticPriceUpdate {
  type: typeof ITEM_AUTOMATIC_PRICE_PUT,
  payload: string
};

export interface ItemSinglePriceUpdate {
  type: typeof ITEM_SINGLE_PRICE_PUT,
  payload: string
};

export interface IndividualItemUpdate {
  type: typeof ITEM_INDIVIDUAL_PUT,
  payload: string
};

export interface ItemTriggerUpdateDescriptionSingle {
  type: typeof ITEM_SINGLE_DESC_PUT,
  payload: string
};

export interface GetItemSearchNames {
  type: typeof ITEM_SEARCH_NAMES,
  payload: ItemSearchNamesResponse
};

export interface GetSuggestedLabelItem {
  type: typeof ITEM_SUGGESTED_LABEL_LIST,
  payload: ItemList
}

// Export types.
export type ItemDispatchTypes = ItemLoading | ItemFail | ItemUpdate | ItemListType | ItemListSearchDataType | ItemLabelListType |
  ItemRefresh | ItemSetFilter | ItemAdd | ItemAddDiscount | ItemUpdateDiscount | ItemDelete | ItemLabelAdd | GetItemSearchNames |
  ItemLabelUpdate | GetSuggestedLabelItem | ItemLabelValueAdd | ItemLabelValueUpdate | ItemSaveLabelsDone | ItemUpdatePriceQuantityStatus | GetItemDetailData |
  ItemSinglePriceUpdate | ItemAutomaticPriceUpdate | ItemTriggerUpdateDescriptionSingle | ItemLabelByTypeListType | IndividualItemUpdate |
  ItemParticipationListType | BarcodeLoading | BarcodeError | BarcodeSuccess;
