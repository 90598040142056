import { QRCode } from "react-qrcode-logo";
import Barcode from 'react-barcode';

export const codeType = process.env.REACT_APP_CODE_TYPE || 'BARCODE'; // BARCODE | QRCODE

export const DeliveryCode = (props: {
    codeValue: string;
}) => {
    if (codeType === 'QRCODE') {
        return (<QRCode value={props.codeValue} size={100} quietZone={5} />);
    }
    // codeType === 'QRCODE' or default to barcode
    return (
        (<Barcode value={props.codeValue} displayValue={false} margin={0} width={1.5}/>)
    );
};
export default DeliveryCode;