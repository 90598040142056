// Material UI.
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formGrid: {
      gridRow: '1 / span 1',
      '& .addressTitle': {
        display: 'block',
        marginLeft: theme.spacing(2),
        borderBottom: `1px solid #f1f1f1`,
        [theme.breakpoints.down('xs')]: {
          marginLeft: theme.spacing(2),
        }
      },
    },
    formControlFullAddress: {
      margin: theme.spacing(1, 0),
      width: '89%',
      textAlign: 'justify'
    },
    formControl: {
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        width: '89%',
        marginRight: theme.spacing(1),
      }
    },
    formControlButtons: {
      margin: theme.spacing(1),
      display: 'block',
      '& > *': {
        display: 'block',
      }
    },
    formControlAddress: {
      margin: theme.spacing(0),
      '& .MuiFormLabel-root': {
        color: grey[500]
      }
    },
    leftColumnWrapper: {
      textAlign: 'center',
      paddingTop: '50px',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 0,
      },
    },
    formField: {
      '& .Mui-disabled:before': {
        borderBottom: 0
      },
      '&.Mui-disabled:before': {
        borderBottom: 0
      },
      '& svg': {
        display: 'none'
      },
      '& > div': {
        width: '100%',
      },
    },
    noAddress: {
      margin: theme.spacing(2, 1)
    },
    actionsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(4),
      '& button': {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          marginLeft: theme.spacing(1),
        },
      }
    },
    vendorName: {
      fontFamily: 'VisbyCF-Bold'
    },
    marginB: {
      marginBottom: theme.spacing(1),
      display: ' block'
    },
    showMore: {
      '& a': {
        color: theme.palette.primary.main
      }
    },
    addressWrapper: {
      border: `1px solid ${theme.palette.primary.main}`,
      padding: theme.spacing(1),
      borderRadius: '6px'
    },
    inputError: {
      color: theme.palette.error.main,
      margin: 0,
      fontSize: '0.75rem',
      marginTop: '3px',
      textAlign: 'left',
      fontWeight: 400,
      lineHeight: '1.66'
    },
    selectType: {
      marginBottom: '20px',
    },
    fullWidth: {
      width: '100%'
    },
    modalPaper: {
      padding: theme.spacing(6, 4),
      position: 'absolute',
      width: '420px',
      height: 'auto',
      top: 'calc(50% - 100px)',
      left: 'calc(50% - 200px)',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(6, 2),
        width: '90%',
        left: '5%'
      },
      '& .MuiTypography-root': {
        marginBottom: theme.spacing(2),
      },
      '& .MuiButtonBase-root': {
        marginLeft: theme.spacing(2),
      }
    },
    closeModal: {
      position: 'absolute',
      right: '0',
      top: '0'
    },
  }),
);

export default useStyles;