// React.
import React from 'react';

// Material UI
import Paper from '@material-ui/core/Paper';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// Components.
import OrdersToBeDeliveredRow from './OrdersToBeDeliveredRow';
import OrdersToBeDeliveredFilters from './OrdersToBeDeliveredFilters';

// Redux
import { RootStore } from "../../../Store";
import { useSelector } from "react-redux";

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './OrderListStyles';

const OrdersToBeDeliveredList = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');

  // Redux state
  const ordersState = useSelector((state: RootStore) => state.order);
  const { loading, ordersToDeliver } = ordersState;

  return (
    <div className={classes.root}>
      <OrdersToBeDeliveredFilters />
      <Paper elevation={3}>
        <div className={classes.boxTop}>
          <TrackChangesIcon color="primary" fontSize="large" />
          <Typography variant="h5" color="primary" className={classes.sectionTitle}>
            {t('orders.orders-to-be-delivered')} ({ordersToDeliver?.totalElements} {t('orders.results')})
          </Typography>
          {loading &&
            <CircularProgress size={26} color="primary" />
          }
        </div>
        
        <div>
          <OrdersToBeDeliveredRow />
        </div>
      </Paper>
    </div>
  )
}

export default OrdersToBeDeliveredList;
