// React.
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components.
import Pagination from '../utils/ResultsPagination';

// Material UI
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import { getLogs, refreshLogsView } from "../../../actions/logActions/LogActions";

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';
// Moment.
import Moment from 'moment';

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import { useStyles, Accordion, AccordionSummary, AccordionDetails } from './LogsAccordionStyles';
import scrollToTop from '../../utils/scrollToTop';

const LogsAccordion = () => {
  const [t] = useTranslation('global');
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const startDate = Moment().format('YYYY-MM-DD');
  const endDate = Moment().add(1, 'days').format('YYYY-MM-DD');

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const url = window.location.href;

  // Redux state.
  const logState = useSelector((state: RootStore) => state.log);
  const { loading, logsList, refresh, logsFilters: filters } = logState;

  useEffect(() => {
    setFirstLoad(true);
    // eslint-disable-next-line
  }, [])

  // Scroll top when pager update url.
  useEffect(() => {
    return history.listen((location) => {
      scrollToTop();
    })
  }, [history])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let page = params.get('page');
    const pNumber = Number(page);
    if (page && page !== null) {
      const qParams = {
        page: pNumber - 1,
        size: getProjectConfig().PAGES_SIZE,
        paged: true,
        initialDateRange: startDate,
        endDateRange: endDate,
        ...filters,
      };
      dispatch(getLogs(qParams));
      setCurrentPage(pNumber);
    } else {
      const qParams = {
        page: 0,
        size: getProjectConfig().PAGES_SIZE,
        paged: true,
        initialDateRange: startDate,
        endDateRange: endDate,
        ...filters,
      };
      dispatch(getLogs(qParams));
      setCurrentPage(1);
    }

    dispatch(refreshLogsView(false));

    // eslint-disable-next-line
  }, [url, refresh]);

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (loading === 'LIST' && firstLoad) {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    )
  }

  if (logsList?.logs?.length === 0) {
    return (
      <div className={classes.circularProgress}>
        <Typography color="textSecondary">{t('orders.no-orders')}</Typography>
      </div>
    )
  }


  return (
    <Fragment>
      {
        logsList?.logs !== undefined &&
        (logsList!.logs!.map(log =>
          <Accordion key={log.id} square expanded={expanded === `panel-u-${log.id}`} onChange={handleChange(`panel-u-${log.id}`)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
              <Grid container spacing={2}>
                <Grid item xs={2} sm={1} className={classes.firstWrapper}>
                  <Typography variant="subtitle1"><strong>Id:</strong> {log.id}</Typography>
                </Grid>
                <Grid item xs={10} sm={3}>
                  <Typography>{log.objectAttributeName}</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography>{Moment(log.creationDate).format('MM/DD/YYYY HH:mm')}</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography><strong>{t('logs.object-type')}:</strong> {log.objectType}</Typography>
                  <Typography><strong>{t('logs.object-id')}:</strong> {log.objectId}</Typography>
                </Grid>
                <Grid item xs={12} sm={3} className={classes.lastWrapper}>
                  <Typography><strong>{t('logs.user')}:</strong> {log.user}</Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Typography><strong>{t('logs.new-value')}:</strong> {log.newValue}</Typography>
              <Typography><strong>{t('logs.old-value')}:</strong> {log.oldValue}</Typography>
              <Typography><strong>{t('logs.additional-info')}:</strong> <span dangerouslySetInnerHTML={{ __html: log.additionalInfo }}></span></Typography>
              <Typography><strong>{t('logs.message')}:</strong> {log.customMessage}</Typography>
            </AccordionDetails>
          </Accordion>
        ))
      }
      {
        logsList?.totalPages !== undefined && logsList?.totalPages !== 1
          ? (<Pagination current={currentPage} path="/admin/logs" pagesNumber={logsList!.totalPages} />) : ''
      }
    </Fragment>
  );
}

export default LogsAccordion;
