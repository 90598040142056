import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  Checkbox,
  Typography,
} from "@material-ui/core";
import useStyles from "./CheckIsStockPositiveStyles"
import { useTranslation } from "react-i18next";

const CheckIsStockPositive = () => {
  const [
    currentIsStockPositive,
    setCurrentIsStockPositive] = useState<boolean>(localStorage.getItem('isStockPositive') === 'true');
  const history = useHistory();
  const classes = useStyles();
  const [t] = useTranslation('global');

  const handleChangeIsStockPositive = () => {
    const url = new URL(window.location.href);
    const newIsStockPositive = !currentIsStockPositive;

    localStorage.setItem('isStockPositive', `${newIsStockPositive}`);
    url.searchParams.set('isStockPositive', `${newIsStockPositive}`);
    setCurrentIsStockPositive(newIsStockPositive);

    window.history.replaceState(null, '', url);
    history.push({
      pathname: '/items',
      search: window.location.search,
    });
  };

  useEffect(() => {
    setCurrentIsStockPositive(localStorage.getItem('isStockPositive') === 'true');
  }, []);

  return <div className={classes.container}>
    <Checkbox
      onChange={handleChangeIsStockPositive}
      checked={currentIsStockPositive}
      color="default"
      />
    <Typography className={classes.textWrapper} color="initial">
    {t("items.is-in-stock")}
    </Typography>
  </div>;
};

export default CheckIsStockPositive;
