// React.
import React from 'react';

// Components.
import PendingOrderList from '../orders/PendingOrderList';

// Styles
import useStyles from './OrdersStyles';

const Orders = () => {
  const classes = useStyles();

  return (
    <div className={ classes.root }>
      <PendingOrderList />
    </div>
  )
}

export default Orders;
