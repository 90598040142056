// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    productRow: {
      borderBottom: '1px solid #000',
      padding: '10px',
    },
    sectionTitle: {
      fontSize: '2.5rem',
      paddingBottom: '20px',
    },
    sectionText: {
      fontSize: '1.5rem',
    },
    summaryItems: {
      fontSize: '2rem',
      borderBottom: '1px solid',
    },
    sectionTotal: {
      fontSize: '2.5rem',
      color: theme.palette.primary.main,
    },
    emptyCartWrapper: {
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    itemSelectionModalWrapper: {
      width: '80%',
      maxWidth: '800px',
      height: '80%',
      maxHeight: '800px',
    },
    paymentMethodOptions: {
      marginTop: '20px',
      marginBottom: '20px',
    },
    finalTotal: {
      fontSize: '3rem',
      color: theme.palette.primary.main,
    },
    radioButton: {
      color: theme.palette.primary.main,
    },
  }),
);

export default useStyles;
