import {Dispatch} from "redux";
import axios from 'axios';
import {WH_FAIL, WH_LOADING, WH_LIST, WH_ADD_QUANTITY, WarehouseDispatchTypes} from "./WarehouseActionsTypes";

const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;

/**
 * Get a list of warehouses by  vendor id.
 * @param {number} vendorId
 */
export const getWarehouses = (vendorId: number = 0) => async (dispatch: Dispatch<WarehouseDispatchTypes>) => {

  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: WH_LOADING,
        payload: 'WH_LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      let queryUrl = (vendorId !== 0) ? `${apiURL}/admin/getAllWarehouses?vendorId=${vendorId}` : `${apiURL}/admin/getAllWarehouses`;
      const res = await axios.get(queryUrl, {
        headers: headers
       });

       if (res.status === 200) {
          const queryData = res.data.data.content;
          const whs:any = [];
         
        for(var i = 0; i < queryData.length; i++) {
          const wh = queryData[i];
        
          whs.push({
            id: wh.id,
            name: wh.name,
            description: wh.description,
            isDiscount: wh.isDiscount,
            isDeleted: wh.isDeleted
          });
        }

        const data:any = {
          first: res.data.data.first,
          last: res.data.data.last,
          number: res.data.data.number,
          size: res.data.data.size,
          totalPages: res.data.data.totalPages,
          warehoueses: whs
        };

        dispatch({
          type: WH_LIST,
          payload: data
        })
       } else {
        dispatch({
          type: WH_FAIL
        })
       }
    } catch(e) {
      dispatch({
        type: WH_FAIL
      })
    }
  } else {
    dispatch({
      type: WH_FAIL
    })
  }
};

/**
 * Register the item quantity on a given warehouse.
 * @param {number} itemId
 * @param {number} quantity
 * @param {number} whIid
 */
export const addItemToWarehouse = (itemId: number, quantity: number, whIid: number ) => async (dispatch: Dispatch<WarehouseDispatchTypes>) => {

  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: WH_LOADING,
        payload: 'ADD_WH_QUANTITY'
      })

      const bodyForm = {
        "itemId": itemId,
        "quantity": quantity.toString() !== '' ? quantity : 0,
        "warehouseId": whIid
      };
      const configPOST = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
    
      const addRes = await axios.post(`${apiURL}/vendor/addItemToWarehouse`, bodyForm, configPOST);

      if (addRes.status === 200) {
        dispatch({
          type: WH_ADD_QUANTITY
        })
      }
       
    } catch(e) {
      dispatch({
        type: WH_FAIL
      })
    }
  } else {
    dispatch({
      type: WH_FAIL
    })
  }
};
