import React, {Fragment, useState, useEffect} from 'react';
import ShowMoreText from "react-show-more-text";

// Material UI.
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'; 
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Components.
import NumberFormat from '../../admin/utils/NumberFormat';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { addBundleToCart, addItemToCartLocal } from "../../../actions/cartActions/CartActions";
import { getUserInformation } from '../../../actions/authActions/AuthActions';
import {RootStore} from "../../../Store";

// Model Types.
import { ItemType, BundleType, BundleItemType } from '../../admin/models/ModelTypes';

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

// Styles.
import useStyles from './BundleDetailStyles';

// Translations.
import { useTranslation } from 'react-i18next';
import defaultImage from '../../../images/default-img.jpg';
import axios from 'axios'; 

// Types interfase.
interface BundleCardProps {
  bundle: BundleType;
};

const BundleDetail = ({bundle} : BundleCardProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();
  const authToken: string | undefined = localStorage.token;

  // Redux state.
  const userReduxState = useSelector((state: RootStore) => state.auth);
  const cartReduxState = useSelector((state: RootStore) => state.cart);
  const cartLoading = cartReduxState.loading;
  const actionStatus = cartReduxState.actionStatus;

  const [quantities, setQuantities] = useState<any>({});
  const [uniqueBundles, setUniqueBundles] = useState<BundleType[]>();
  var countAvailable = -1;

  const imgURL = bundle.images[0] !== undefined ? bundle.images[0].url : defaultImage;

  useEffect(() => {
    if (bundle.bundleCode !== undefined) {
      getBundlesByCode(bundle.bundleCode);
    }

  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (actionStatus?.menssage === 'status.add-cart') {
      if (authToken !== undefined && authToken !== '') dispatch(getUserInformation());
    }

    // eslint-disable-next-line
  }, [actionStatus]);

  const getBundlesByCode = async (code:string) => {
    const apiURL: string | undefined = process.env.REACT_APP_API_URL;
    const token: string | undefined = localStorage.token;

    const headers: object = { ContentType: 'application/json', Authorization: 'Bearer ' + token }
    const requestPath = !token ? `${apiURL}/guest/getAllBundles` : `${apiURL}/user/getAllBundles`;
    const res = await axios.get(requestPath, { params: { bundleCode: code, sort: 'price,asc' }, headers: headers});

    if (res.status === 200) {
      const queryData = res.data.data.content;
      const bundles:BundleType[] = [];
      for(var i = 0; i < queryData.length; i++) {
        const bundle = queryData[i];
        bundles.push({
          id:  bundle.id,
          bundleCode: bundle.bundleCode,
          creationDate: bundle.creationDate,
          currentTax: bundle.currentTax,
          description: bundle.description,
          images: bundle.images,
          isDeleted:	bundle.isDeleted,
          isPublished:	bundle.isPublished,
          items:	bundle.items,
          name:	bundle.name,
          price: bundle.price,
          priceWithTaxes: bundle.priceWithTaxes,
          totalQuantity: bundle.totalQuantity,
          vendor: bundle.vendor
        });
      }

      setUniqueBundles(bundles);
   }
  }

  const handleQuantityChange = (id:string, value: unknown) => {
    if (id !== undefined && value !== undefined) {
      const newValues = quantities;
      newValues[id] = value;
      if (Number(value) >= 0) {
        setQuantities({ ...quantities, newValues });
      }
    }
  }

  const addToCart = (bundle: any) => {
    let quantity = quantities[bundle.id] > 0 ? quantities[bundle.id] : 1;
    if (localStorage.token) {
      // If user is logged.
      dispatch(addBundleToCart(bundle.id, quantity));
    } else {
      // If user is anonimous.
      var cart = localStorage.anonymousBundleCart !== undefined ? JSON.parse(localStorage.anonymousBundleCart) : [];
      // validate if already exists.
      const index = cart.findIndex((obj:any) => (obj.bundle.id === bundle.id));
      if (index !== -1) {
        let currentQty = cart[index].quantity;
        cart[index] = { bundle: bundle, quantity: (currentQty + quantity) };
      } else {
        cart.push({ bundle: bundle, quantity: quantity });
      }
      localStorage.removeItem('anonymousBundleCart');
      localStorage.setItem('anonymousBundleCart', JSON.stringify(cart));
      dispatch(addItemToCartLocal());
    }

    // Re-Set the state value.
    const newValues = quantities;
    newValues[bundle.id] = getAvailableQuantity(bundle) > 0 ? 1 : 0;
    setQuantities({ ...quantities, newValues });
  };

  // Gets the total quantity from all vendor warehouses.
  const getAvailableQuantity = (currentItem:BundleType) => {
    var result = currentItem.totalQuantity;


    // Check if there is already bundles added of this.
    if (userReduxState.loggedUser?.bundleCart !== undefined) {
      userReduxState.loggedUser.bundleCart.forEach((cartItem:any) => {
        if (cartItem.bundle.id === currentItem.id) {
          result = result - cartItem.quantity;
        }
      });
    }

    // Check if there is already items of this bundle added.
    if (userReduxState.loggedUser?.cart !== undefined) {
      currentItem.items.forEach((bundleItem:any) => {
        const matchedItems = userReduxState.loggedUser?.cart.filter((cartItem:any) => cartItem.item.id === bundleItem.item.id);
        if (matchedItems.length > 0) {
          result = result - matchedItems[0].quantity;
        }
      });
    }

    // Check bunldes added for anonimous users.
    var cartLocal = localStorage.anonymousBundleCart !== undefined ? JSON.parse(localStorage.anonymousBundleCart) : [];
    if (cartLocal.length > 0) {
      const index = cartLocal.findIndex((obj:any) => obj.bundle.id === currentItem.id);
      if (index !== -1) {
        let currentQty = cartLocal[index].quantity;
        result = result - currentQty;
      }
    }

    // Check if there is already items of this bundle added for anonimous users.
    if (cartLocal.length > 0) {
      currentItem.items.forEach((bundleItem:any) => {
        // Local bundle cart items.
        cartLocal.forEach((bundleCartItem:any) => {
          const matchedItems = bundleCartItem.bundle.items.filter((item:any) => item.id === bundleItem.item.id);
          if (matchedItems.length > 0) {
            result = result - matchedItems[0].quantity;
          }
        });
      });
    }
    
    if (result !== 0) {
      countAvailable = countAvailable + result;
    }
    return result;
  };

  // Quantities to render.
  const renderOptions = (quantity:number) => {
    const items = [];
    for(var i = 1; i <= quantity; i++) {
      items.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
    }
    if (items.length === 0) {
      items.push(<MenuItem key={1} value={1}>{1}</MenuItem>)
    }
    return items;
  };

  const getDiscountQty = (item:ItemType) => {
    const dis = item.warehousesQuantities.find((wh:any) => wh.warehouse.isDiscount === true);
    return dis !== undefined ? Number(dis.quantity) : 0;
  };

  const getPriceInfo = (bundle:any) => {
    if (bundle.discounts !== null && bundle.discounts !== undefined) {
      const disQty = getDiscountQty(bundle);
      if (bundle.discounts.pricePercentage > 0 && disQty > 0) {
        const discount = bundle.discounts.pricePercentage;
        const savings = Number(((discount/ 100) * bundle.priceWithTaxes).toFixed(2));
        const newPrice = (bundle.priceWithTaxes - savings);
        return (
          <Fragment>
            <Typography color="secondary" className={classes.lineThrough}>{getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={bundle.priceWithTaxes}/>}</Typography>
            <Typography color="primary">{getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={newPrice}/>}</Typography>
          </Fragment>
        );
      }
    }
    
    return (
      <Typography color="primary">{getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={bundle.priceWithTaxes} />}</Typography>
    );
  };

  return (
    <Fragment>
      <Typography gutterBottom variant="h5" component="h1" color="primary">
        <strong>{bundle.name}</strong> <span className={classes.type}>{ t('bundles.bundle') }</span>
      </Typography>
      <Card className={classes.root}>
        <div className={classes.cardTop}>
          <div className={classes.cardMedia}>
            <img src={imgURL} alt="Product media" loading="lazy"/>
          </div>
          <div className={classes.cardDescription}>
            <Typography gutterBottom variant="caption" color="textSecondary">
              <strong>{ t('bundles.bundle-description') }</strong>
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" className="item-description">
              <ShowMoreText
                lines={3}
                more={t('items.view-more') }
                less={t('items.view-less')}
                expanded={false}
                truncatedEndingComponent={"... "}
              >
                { bundle.description }
              </ShowMoreText>
            </Typography>
            <br/>
            <Typography gutterBottom variant="caption" color="textSecondary">
              { t('bundles.items') }:
            </Typography>
            <Table size="small" aria-label="The items table" className={classes.itemsTable}>
              <TableBody>
                {
                  bundle.items?.map((bundleItem:BundleItemType) => 
                  <TableRow key={bundleItem.id}>
                    <TableCell component="th" scope="row">
                      <p>{ bundleItem.item.name }</p>
                    </TableCell>
                    <TableCell> { t('bundles.quantity') }: { bundleItem.quantity } </TableCell>
                  </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </div>
        </div>
        <Table size="small" aria-label="a dense table" className={classes.tableWrapper}>
          <TableHead>
            <TableRow>
              <TableCell>{ t('items.seller') }</TableCell>
              <TableCell>{ t('items.price') }</TableCell>
              <TableCell>{ t('items.quantity') }</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { (uniqueBundles?.map((currentBundle:BundleType) => 
              getAvailableQuantity(currentBundle) > 0 &&
              <TableRow key={currentBundle.id}>
                <TableCell component="th" scope="row">
                  { currentBundle?.vendor?.name }
                </TableCell>
                  <TableCell className={classes.priceCol}>{ getPriceInfo(currentBundle) }</TableCell>
                <TableCell>
                  <FormControl fullWidth>
                    <Select
                      id={`${currentBundle.id}`}
                        className={classes.inputQty}
                      value={ quantities[currentBundle.id] !== undefined ? quantities[currentBundle.id] : 1 }
                      label={ t('items.quantity') }
                      onChange={ (e) => handleQuantityChange(String(currentBundle.id), e.target.value) }
                      disabled={getAvailableQuantity(currentBundle) < 1}
                    >
                      { renderOptions(getAvailableQuantity(currentBundle)) }
                  </Select>
                  </FormControl>
                </TableCell>
                <TableCell align="right">
                  <IconButton color="primary" aria-label="Add to cart" component="span" onClick={ () => addToCart(currentBundle) } disabled={getAvailableQuantity(currentBundle) === 0 || cartLoading !== ''}>
                    <AddShoppingCartIcon color={ getAvailableQuantity(currentBundle) > 0 ? 'secondary' : 'disabled' }/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          }
          {(countAvailable <= 0) &&
            <div className={ classes.noStockMsg}>
              { t('items.no-available') }
            </div>
          }
          </TableBody>
        </Table>
      </Card>
    </Fragment>
  );
}

export default BundleDetail;
