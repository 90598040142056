import {
  DELETE_CART, 
  ADD_CART, 
  CART_FAIL, 
  CART_LOADING, 
  CHECKOUT_CART,
  CartActionsTypes
} from "../actions/cartActions/CartActionsTypes";

// Default state interface.
interface DefaultStateI {
  loading: string,
  actionStatus?: any
}

// Default state.
const defaultState: DefaultStateI = {
  loading: '',
};

const CartReducer = (state: DefaultStateI = defaultState, action: CartActionsTypes) : DefaultStateI => {
  switch (action.type) {
    case CART_FAIL:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'error'}
      }
    case CART_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case ADD_CART:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'success'}
      }
    case CHECKOUT_CART:
      return {
        ...state,
        loading: '',
        actionStatus: {orderIds: action.payload.orderIds, menssage: action.payload.message, status: 'success'}
      }
    case DELETE_CART:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'success'}
      }
    default:
      return state
  }
};


export default CartReducer;
