// React.
import React, {useState, useEffect, Fragment} from 'react';

// Material UI
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';

//  JWT decode token.
import jwtDecode from 'jwt-decode';

// Components.
import TextMaskPhone from '../../admin/utils/TextMaskPhone';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import { getShippings } from "../../../actions/orderActions/OrderActions";
import { getUserVendors } from '../../../actions/vendorActions/VendorActions';

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './AddOrderShippingStyles';

// Model types.
import { ShippingType, AddressUserType, VendorTypeObject } from '../../admin/models/ModelTypes';
import { getUserInformation } from '../../../actions/authActions/AuthActions';

interface AutoCompleteType {
  name: string;
  email: string;
  addresses: Array<any>
}

interface AddOrderShippingProps {
  selectedShipping: ShippingType | undefined;
  setSelectedShipping: Function;
  selectedAddressId: number;
  setSelectedAddressId: Function;
  setCustomAddress: Function;
  customAddress: AddressUserType;
  setValidateAddress: Function;
  validateAddress: boolean;
  setRadioValue: Function;
  radioValue: string;
  quickOrder: boolean;
  setSelectedUserEmail: Function;
  selectedVendor: string;
  setSelectedVendor: Function;
};

const AddOrderShipping = ({selectedShipping, setSelectedShipping, selectedAddressId, setSelectedAddressId, 
  setCustomAddress, customAddress, setValidateAddress, validateAddress, setRadioValue, radioValue, quickOrder, setSelectedUserEmail, selectedVendor, setSelectedVendor }: AddOrderShippingProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();

  // Redux state.
  const orderState = useSelector((state: RootStore) => state.order);
  const shippings = orderState.shippings;
  const reduxVendorsState = useSelector((state: RootStore) => state.vendors);
  const vendorsList = reduxVendorsState.userVendors;
  const reduxAuthState = useSelector((state: RootStore) => state.auth);
  const loggedUser = reduxAuthState.loggedUser;

  useEffect(() => {
    dispatch(getShippings());
    dispatch(getUserVendors());
    dispatch(getUserInformation());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Set default store for sellers.
    if (loggedUser !== undefined && quickOrder) {
      let vendorId = getFirstUserStoreId();
      if (vendorId) {
        setSelectedVendor(vendorId);
      }
    }
    
    // eslint-disable-next-line
  }, [quickOrder]);
  

  useEffect(() => {
    if (shippings !== undefined && quickOrder) {
      setSelectedShipping(shippings[0]);
    }

    // eslint-disable-next-line
  }, [shippings, quickOrder]);

  // Local state.
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<AutoCompleteType[]>([]);
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const [selectedUser, setSelectedUser] = useState<AutoCompleteType|null>(null);

  const getFirstUserStoreId = () => {
    var vendorId = null;
    if (loggedUser.sellerProfile.vendors !== undefined) {
      loggedUser.sellerProfile.vendors.forEach((vendor: VendorTypeObject) => {
        if (vendor.type === 'Tienda') vendorId = vendor.id;
      });
    }
    return vendorId;
  };

  const autocompleteChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAutocompleteValue(String(event.target.value));
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
    setValidateAddress(false);
  };

  const handleChangeAddress = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    // Use the HTML name atribute to know what property update.
    const name = event.target.name as keyof typeof customAddress;
    setCustomAddress({
      ...customAddress,
      [name]: event.target.value,
    });
  };

  const isShippingSelected = (id:number) => {
    var className =  '';
    if (selectedShipping !== undefined) {
      className =  selectedShipping.id === id ? 'selected' : '';
    }

    return className;
  };

  // Renders the custom address form.
  const renderCustomAddressForm = () => {
    return (
      <Fragment>
        <FormControl className={classes.formControl}>
          <TextField
            className={classes.formField} 
            id="u-province" 
            name="province"
            label={ t('users.province') }
            onChange={ handleChangeAddress }
            error={ customAddress.province === '' && validateAddress }
          />
          <TextField 
            className={classes.formField} 
            id="u-canton" 
            name="canton"
            label={ t('users.canton') }
            onChange={ handleChangeAddress }
            error={ customAddress.canton === '' && validateAddress }
          />
          <TextField 
            className={classes.formField} 
            id="u-district" 
            name="district"
            label={ t('users.district') } 
            onChange={ handleChangeAddress }
            error={ customAddress.district === '' && validateAddress }
          />
          <TextField 
            className={classes.formField} 
            id="u-direction" 
            name="fullAddress" 
            label={ t('users.direction') }
            onChange={ handleChangeAddress }
            error={ customAddress.fullAddress === '' && validateAddress }
          />
          <FormControl >
            <InputLabel htmlFor="u-phone-number">{ t('users.phone-number') }</InputLabel>
            <Input
              id="u-phone-number" 
              name="phoneNumber"
              inputComponent={TextMaskPhone as any}
              className={ classes.formField } 
              onChange={ handleChangeAddress }
              error={ customAddress.phoneNumber === '' && validateAddress }
            />
          </FormControl>
          <TextField 
            className={classes.formField} 
            id="u-postal-code" 
            name="postalCode"
            label={ t('users.postal-code') }
            onChange={ handleChangeAddress }
          />
        </FormControl><br/>
      </Fragment>
    );
  };

  useEffect(() => {
    let active = true;
    const apiURL: string | undefined = process.env.REACT_APP_API_URL;
    const token: string | undefined = localStorage.token;
    const headers: object = {
      ContentType: 'application/json',
      Authorization: 'Bearer ' + token
    };
    
    (async () => {
      const token: string | undefined = localStorage.token;
      const decodedToken:any = jwtDecode(String(token));
      const rolePath = decodedToken.auth.includes('ROLE_ADMIN') ? 'admin' : 'seller';
      const res = await axios.get(apiURL + `/${rolePath}/getAllUsers`, {
        params: {searchTerm: autocompleteValue, paged: true, size: 15}, 
        headers: headers
      });
      const users = res.data.data.content;
      if (active) {
        var usersList:Array<AutoCompleteType> = [];
        users.forEach((user:any) => {
          usersList.push({ name: `${user.firstName} ${user.lastName}`, email: user.email, addresses: user.addresses });
        });
        setOptions(usersList);
      }
    })();

    return () => {
      active = false;
    };
  }, [autocompleteValue]);

  const renderUserAddressForm = () => {
    return (
      <Fragment>
        <Autocomplete
          id="asynchronous-users" style={{ width: '70%' }} open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          value={ selectedUser }
          onChange={(event: any, newValue: AutoCompleteType | null) => {
            setSelectedUser(newValue);
            setSelectedUserEmail(`${newValue?.email}`);
            setSelectedAddressId(0);
          }}
          getOptionSelected={(option, value) => option.email === value.email}
          getOptionLabel={(option) => option.name} options={options}
          renderInput={(params) => (
            <TextField
              {...params}
              label={ t('orders.select-user') }
              value={ autocompleteValue }
              onChange={ autocompleteChange }
              error={ selectedUser === null && validateAddress }
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        {selectedUser !== null && 
          <Fragment>
            {selectedUser.addresses.map((address:any, index:number) =>
              <Card key={index} className={`address-box ${selectedAddressId === address.id ? 'selected' : ''}`} onClick={ () => setSelectedAddressId(address.id) } >
                <CardContent>
                  <Typography>{index + 1}: {address.province}, {address.canton}, {address.district}, {address.fullAddress}</Typography>
                </CardContent>
              </Card>
            )}
            {selectedUser!.addresses.length === 0 && 
              <Typography>{ t('orders.no-user-address') }</Typography>
            }
          </Fragment>
        }
        {selectedAddressId === 0 && validateAddress &&
          <Typography color="secondary">{ t('orders.no-address-error') }</Typography>
        }
      </Fragment>
    );
  };
  
  return (
    <div className={ classes.root }>
      <Paper elevation={3}>
        <div className={ classes.boxTop }>
          <LocalShippingIcon color="primary" fontSize="large"/>
          <Typography variant="h5" color="primary">
            { t('orders.select-shipping') }
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={7}  className={classes.shippingOptionsWrapper}>
              {shippings !== undefined &&
              shippings.map((shipping:ShippingType, index:number) =>
                <Card key={index} className={ `${ isShippingSelected(shipping.id) } ${(quickOrder && shipping.id === 2 ) ? 'disabled' : '' }` } onClick={ () => setSelectedShipping(shipping) }>
                  <CardContent>
                    <Typography>{shipping.name}</Typography>
                    <Typography variant="caption">{ t('checkout.cost') }: {shipping.currency.symbol}{shipping.cost}</Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
            <Grid item xs={12} sm={5}>
              {selectedShipping?.id === 2 && 
                <Fragment>
                  <Typography color="primary">{ t('orders.shipping-address') }</Typography><br/>
                  <div className={classes.addressRadioWrapper}>
                    <RadioGroup aria-label="quiz" name="quiz" value={radioValue} onChange={handleRadioChange}>
                      <FormControlLabel value="custom" control={<Radio color="primary"/>} label={ t('orders.custom-address') } />
                      <FormControlLabel value="user" control={<Radio color="primary" />} label={ t('orders.user-address') } />
                    </RadioGroup>
                  </div>
                  { radioValue === 'custom' && 
                    renderCustomAddressForm()
                  }
                  { radioValue === 'user' && 
                    renderUserAddressForm()
                  }
                </Fragment>
              }
              {selectedShipping?.id === 1 &&
                <Fragment>
                  <Typography color="primary">{t('checkout.store-pickup')}</Typography><br />
                  <FormControl className={classes.formControlVendor}>
                    <InputLabel id="store-checkbox-label">{t('orders.select-store')}</InputLabel>
                    <Select
                      labelId="store-checkbox-label"
                      id="store-checkbox"
                      input={<Input />}
                      value={selectedVendor}
                      onChange={(e) => setSelectedVendor(`${e.target.value}`)}
                    >
                      {vendorsList?.map((vendor) =>
                        <MenuItem key={vendor.id} value={vendor.id}>
                          <ListItemText primary={vendor.name} />
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Fragment>
              }
            </Grid>
          </Grid>
        </div>
      </Paper>
    </div>
  )
}

export default AddOrderShipping;