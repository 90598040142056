/**
 *****************************************
 *********** Dispatch Types **************
 *****************************************
 */
export const GET_USER = "GET_USER";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOADING = "AUTH_LOADING";
export const REGISTER_USER = "REGISTER_USER";
export const LOGIN_USER = "LOGIN_USER";
export const VALIDATE_ACCOUNT = "VALIDATE_ACCOUNT";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const IS_VALID_EMAIL = "IS_VALID_EMAIL";
export const RESEND_CODE = "RESEND_CODE";
export const GET_USER_SUMMARY = "GET_USER_SUMMARY";

export type UserSummaryType = {
  accountValidated: boolean,
  firstName: string,
  profileImageUrl: string,
  roles: string,
  totalItemsInCart: number,
  totalUnreadNotifications: number,
  totalOrdersActive: number;
  points:number
};
 
/**
*****************************************
*********** Interfaces Types ************
*****************************************
*/
export interface GetUser {
  type: typeof GET_USER,
  payload: any
}
export interface GetUserSummary {
  type: typeof GET_USER_SUMMARY,
  payload: UserSummaryType
}
export interface AuthLoading {
  type: typeof AUTH_LOADING
  payload: string
}
export interface AuthFail {
  type: typeof AUTH_FAIL,
  payload: any
}
export interface UserRegister {
  type: typeof REGISTER_USER,
  payload: string
}
export interface ValidateAccount {
  type: typeof VALIDATE_ACCOUNT,
  payload: string
}
export interface ResendCode {
  type: typeof RESEND_CODE,
  payload: string
}
export interface UserLogin {
  type: typeof LOGIN_USER,
  payload: any
}
export interface UserLogout {
  type: typeof LOGOUT_USER,
}
export interface ForgotPassword {
  type: typeof FORGOT_PASSWORD,
  payload: string
}

export interface EmailTaken {
type: typeof IS_VALID_EMAIL,
payload: boolean
}
 
// Export types.
export type AuthActionsTypes = GetUser | UserRegister | UserLogin | UserLogout | GetUserSummary 
                              | AuthLoading | AuthFail | ValidateAccount | ForgotPassword | EmailTaken | ResendCode;
 