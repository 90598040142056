// React.
import React, { useEffect, useState } from 'react';

// Material UI
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Redux
import { RootStore } from "../../../Store";
import { useDispatch, useSelector } from "react-redux";
import { getAllVendorBySeller } from "../../../actions/vendorActions/VendorActions";
import { orderSetFilters } from "../../../actions/orderActions/OrderActions";

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './OrdersToBeDeliveredFiltersStyles';

const OrdersToBeDeliveredFilters = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();

  // Redux state.
  const vendorsState = useSelector((state: RootStore) => state.vendors);
  const { loading, vendorsBySeller} = vendorsState;
  const orderState = useSelector((state: RootStore) => state.order);
  const { orderFilters: filters } = orderState;

  // Local component state.
  const [vendorAddressId, setVendorAddressId] = useState<string>('');
  const [status, setStatus] = useState<string>('all');


  useEffect(() => {
    if (vendorsBySeller === undefined) {
      dispatch(getAllVendorBySeller());
    }

    // eslint-disable-next-line
  }, []);

  const handleChangeVendor = (event: React.ChangeEvent<{ value: unknown }>) => {
    let newValue = event.target.value as string;
    setVendorAddressId(newValue);
    
    let sendValue = (newValue) ? newValue : null;
    dispatch(orderSetFilters({ ...filters, addressId: sendValue }));
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = (event.target as HTMLInputElement).value;
    setStatus(newValue);

    let sendValue = (newValue !== 'all') ? newValue : '';
    dispatch(orderSetFilters({ ...filters, status: sendValue }));
  };

  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <div className={classes.boxTop}>
          <SearchIcon color="primary" fontSize="large" />
          <Typography variant="h5" color="primary">
            {t('orders.order-filter')}
          </Typography>
        </div>
        <div>
          <form className={classes.boxForm}>
            <FormControl>
              <InputLabel id="order-vendor-label">{t('orders.vendor')}</InputLabel>
              <Select
                labelId="order-vendor-label"
                id="order-vendor"
                name="order_vendor"
                value={vendorAddressId}
                onChange={handleChangeVendor}
              >
                <MenuItem value="">
                  <em>{loading === 'STATUS_LIST' ? t('orders.loading') : t('orders.none')}</em>
                </MenuItem>
                {vendorsBySeller !== undefined &&
                  vendorsBySeller.map((vendor) =>
                    <MenuItem key={vendor.vendorId} value={vendor.addressId}>{vendor.name}</MenuItem>
                  )
                }
              </Select>
            </FormControl>
            <FormControl component="fieldset" className={classes.radioButtons}>
              <RadioGroup aria-label="quiz" name="quiz" value={status} onChange={handleRadioChange}>
                <FormControlLabel value="all" control={<Radio />} label="Ver todas" />
                <FormControlLabel value="Completado" control={<Radio />} label="Solo Completadas" />
              </RadioGroup>
            </FormControl>
          </form>
        </div>
      </Paper>
    </div>
  )
}

export default OrdersToBeDeliveredFilters;
