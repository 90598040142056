/**
 *****************************************
 *********** Dispatch Types **************
 *****************************************
 */
export const USER_LOADING = "USER_LOADING";
export const USER_FAIL = "USER_FAIL";
export const USER_LIST = "USER_LIST";
export const USER_PUT = "USER_PUT";
export const USER_REFRESH = "USER_REFRESH";
export const USER_SET_FILTER = "USER_SET_FILTER";
export const ADD_USER = "ADD_USER";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_USER_PASSWORD_TEMP = "UPDATE_USER_PASSWORD_TEMP";
export const UPDATE_USER_POINTS = "UPDATE_USER_POINTS";

/**
 *****************************************
 ************* Object Types **************
 *****************************************
 */
export type UserType = {
  id: number,
  email: string,
  name: string,
  lastName: string,
  password?: string,
  image_key?: string,
  profileImageUrl?: string,
  phone?: string,
  address?: Object,
  role: string,
  points: number
}

export type UserList = {
  first: boolean
  last: boolean
  number: number
  size: number
  totalPages: number
  users: UserType[]
}

/**
 *****************************************
 *********** Interfaces Types ************
 *****************************************
 */
export interface UserLoading {
  type: typeof USER_LOADING
  payload: string
}

export interface UserFail {
  type: typeof USER_FAIL,
  payload: string
}

export interface UserUpdate {
  type: typeof USER_PUT,
  payload: string
}

export interface UserPasswordTempUpdate {
  type: typeof UPDATE_USER_PASSWORD_TEMP,
  payload: string
}

export interface UserAdd {
  type: typeof ADD_USER,
  payload: string
}

export interface UserDelete {
  type: typeof DELETE_USER,
  payload: string
}

export interface UserListType {
  type: typeof USER_LIST,
  payload: UserList
}

export interface UserRefresh {
  type: typeof USER_REFRESH,
  payload: boolean
}

export interface UserSetFilter {
  type: typeof USER_SET_FILTER,
  payload: object
}

export interface UpdateUserPoints {
  type: typeof UPDATE_USER_POINTS,
  payload: string
}

// Export types.
export type UserDispatchTypes = UserLoading | UserFail | UserUpdate | UserPasswordTempUpdate | UserListType 
  | UserRefresh | UserSetFilter | UserAdd | UserDelete | UpdateUserPoints;