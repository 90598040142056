import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '150px',
      paddingBottom: '80px',
      backgroundColor:  process.env.REACT_APP_THEME_COLOR_EXTRA_LIGHT_GRAY,
      [theme.breakpoints.down('sm')]: {
        marginTop: '110px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0),
      },
    },
  })
);

export default useStyles;