import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(12),
      width: '100%',
      maxWidth: 'unset!important',
    },
  })
);

export default useStyles;