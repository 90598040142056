// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorWrapper: {
      marginTop: theme.spacing(6),
      padding: theme.spacing(2),
      '& h1': {
        textAlign: 'center'
      }
    },
  }),
);

export default useStyles;
