// React.
import React from 'react';

// Material UI.
import Paper from '@material-ui/core/Paper';

// Components.
import TypeSettings from '../settings/TypeSettings';

// Styles.
import useStyles from './SettingsStyles';

const TypeSettingsPage = () => {
  const classes = useStyles();

  return (
    <Paper className={ classes.paper } elevation={3} square={false} variant="elevation">
      <TypeSettings/>
    </Paper>
  )
}

export default TypeSettingsPage;
