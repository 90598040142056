// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ordersWrapper: {
      paddingTop: theme.spacing(8),
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(20),
      },
    },
    paperWrapper: {
      padding: theme.spacing(2),
      minHeight: '600px',
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(2),
      },
    },
  }),
);

export default useStyles;
