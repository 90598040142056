import {
  GET_DOLAR_PRICE,
  UTILITY_FAIL,
  UTILITY_LOADING,
  UtilityDispatchTypes,
} from "../actions/utilityActions/UtilityActionsTypes";

// Default state interface.
interface DefaultStateI {
  loading: string,
  dolarPrice?: string
}

// Default state.
const defaultState: DefaultStateI = {
  loading: '',
};

const utilityReducer = (state: DefaultStateI = defaultState, action: UtilityDispatchTypes) : DefaultStateI => {
  switch (action.type) {
    case UTILITY_FAIL:
      return {
        ...state,
        loading: '',
      }
    case UTILITY_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case GET_DOLAR_PRICE:
      return {
        ...state,
        loading: '',
        dolarPrice: action.payload
      }
    default:
      return state
  }
};


export default utilityReducer;
