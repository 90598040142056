import {
  LogsList,
  LogDispatchTypes,
  LOG_REFRESH,
  LOG_SET_FILTER,
  LOG_FAIL,
  LOG_LIST,
  LOG_LOADING,
  GET_CHANGE_LOGS_TYPES
} from "../actions/logActions/LogActionsTypes";

// Default state interface.
interface DefaultStateI {
  loading: string,
  logsList?: LogsList,
  actionStatus?: any,
  refresh?: boolean,
  logsFilters?: object,
  changeLogTypes?: string[]
}

const defaultState: DefaultStateI = {
  loading: ''
};

const logReducer = (state: DefaultStateI = defaultState, action: LogDispatchTypes) : DefaultStateI => {
  switch (action.type) {
    case LOG_FAIL:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'error'}
      }
    case LOG_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case LOG_LIST:
      return {
        ...state,
        loading: '',
        logsList: action.payload
      }
    case LOG_REFRESH:
      return {
        ...state,
        refresh: action.payload
      }
    case LOG_SET_FILTER:
      return {
        ...state,
        logsFilters: action.payload
      }
    case GET_CHANGE_LOGS_TYPES:
      return {
        ...state,
        changeLogTypes: action.payload
      }
    default:
      return state
  }
};

export default logReducer;
