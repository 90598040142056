// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: '100%',
      height: 'auto',
      maxWidth: '100%',
    },
    editImgWrapper: {
      '& #icon-button-file': {
        display: 'none'
      }
    },
    addedItemsContainer: {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '6px',
      padding: theme.spacing(1),
      '& .MuiTypography-root': {
        marginBottom: theme.spacing(2)
      },
      '& .MuiPaper-root': {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        display: 'flex',
        alignItems: 'center'
      },
      '& .MuiAvatar-root': {
        marginRight: theme.spacing(2)
      }
    },
    addItemWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginTop: theme.spacing(4)
    },
    vendorSelect: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    autocomplete: {
      width: '60%',
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginRight: 0,
      }
    },
    currentQty: {
      width: '10%',
      minWidth: '80px',
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginRight: 0,
      }
    },
    currentBtn: {
      width: 'auto',
      height: '36px'
    },
    mainImg: {
      width: '100%',
      maxHeight: '300px',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        width: '100%',
        maxHeight: '220px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxHeight: '114px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: 'auto',
        marginBottom: theme.spacing(1)
      },
      '& .MuiAvatar-img' : {
        maxHeight: 'inherit'
      }
    },
    smallImg: {
      width: '30%',
      maxHeight: '76px',
      position: 'relative',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        width: '30%',
        maxHeight: '76px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '30%',
        maxHeight: '45px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '86px',
        maxHeight: '86px',
        marginBottom: theme.spacing(1),
      },
      '& .MuiAvatar-rounded': {
        width: '100%',
        height: 'inherit',
      },
      '& .MuiAvatar-img' : {
        maxHeight: 'inherit'
      }
    },
    deleteIcon: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      zIndex: 1,
      '& :hover': {
        cursor: 'pointer'
      }
    },
    smallImgWraper: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    imgWrapper: {
      position: 'relative',
      marginBottom: '100px',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '40px',
      }
    },
    formControl: {
      marginBottom: theme.spacing(1),
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(3),
      }
    },
    formCheckbox:{
      padding: '0'
    },
    notAllVendors: {
      width: '100%',
      color: 'red'
    }
  }),
);

export default useStyles;
