import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.dark,
      minHeight: '66px',
      [theme.breakpoints.down('sm')]: {
        minHeight: '48px'
      },
    },
    desktopNavWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    navItemWrapper: {
      padding: '8px 14px',
      position: 'relative',
      '& a': {
        textAlign: 'center',
        color: 'white',
        textDecoration: 'none',
        textTransform: 'uppercase',
        fontFamily: 'VisbyCF-Bold',
        fontSize: '13px',
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'none',
          cursor: 'pointer',
        }
      },
      '& img': {
        maxHeight: '49px',
        display: 'block',
        margin: '0 auto'
      },
      '& .subNavItemWrapper': {
          opacity: 0,
          overflow: 'hidden',
          transition: 'opacity 0.3s linear, top 0.3s',
          position: 'absolute',
          width: '250px',
          top: 'calc(100% + 30px)',
          left: 0,
          pointerEvents: 'none',
          '&.active': {
            display: 'block',
            height: 'auto',
            opacity: 1
          },
          '& a': {
            display: 'block',
            opacity: 1,
            textAlign: 'left',
            padding: theme.spacing(1, 2),
            backgroundColor: theme.palette.primary.dark,
            borderBottom: `1px solid ${theme.palette.grey[600]}`,
            '&:hover': {
              textDecoration: 'none',
              cursor: 'pointer',
              backgroundColor: theme.palette.grey[800],
            }
          },
          '& a:last-child': {
            borderBottom: 0
          }

      },
      '&:hover': {
        backgroundColor: theme.palette.grey[800],
      },
      '&:not(.open-onclick):hover .subNavItemWrapper': {
        display: 'block',
        height: 'auto',
        top: 'calc(100% - 14px)',
        opacity: 1,
        pointerEvents: 'auto',
        paddingTop: '20px'
      },
      '&.nolink': {
        cursor: 'default',
        '& a:hover': {
          cursor: 'default',
        }
      },
      '&.is-active': {
        backgroundColor: theme.palette.grey[800],
        '& .subNavItemWrapper': {
          '& a.is-active': {
            backgroundColor: theme.palette.grey[800],
          },
          '& a:hover': {
            backgroundColor: theme.palette.grey[800],
          }
        }
      }
    },
    mobileNavWrapper: {
      display: 'flex'
    },
    sortWrapper: {
      width: '70%',
      [theme.breakpoints.up('sm')]: {
        width: '85%'
      },
    },
    menuButton: {
      marginLeft: theme.spacing(1),
    },
    navList: {
      paddingTop: 0
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.palette.primary.dark
    },
    drawerHeader: {
      backgroundColor: theme.palette.primary.dark,
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
      '& .MuiIconButton-label': {
        color: 'white'
      }
    },
  }),
);

export const Accordion = withStyles((theme) => ({
  root: {
    margin: '0',
    boxShadow: 'none',
    position: 'initial',
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
    '&$expanded': {
      margin: '0',
    },
    '& .enabled': {
      color: '#009900'
    },
    '& .selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  },
  expanded: {},
}))(MuiAccordion);

export const AccordionSummary = withStyles((theme) => ({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .3)',
    marginBottom: 1,
    padding: '0',
    '&$expanded': {
      minHeight: 56,
      margin: '0',
      borderBottom: '0',
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0
    },
    '& .MuiButtonBase-root': {
      backgroundColor: theme.palette.primary.dark,
      color: 'white',
      borderRadius: 0
    },
    '& img': {
      maxHeight: '30px',
      marginRight: '8px'
    },
    '& .MuiGrid-item': {
      display: 'flex',
      alignItems: 'center'
    },
    '& .is-active': {
      backgroundColor: 'rgba(0, 0, 0, .2)',
    }
  },
  content: {
    display: 'flex',
    margin: '12px 15px',
    fontFamily: 'VisbyCF-Bold',
    '&$expanded': {
      margin: '0 15px',
    },
    '& > *': {
      alignItems: 'center',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: '1px solid rgba(0, 0, 0, .3)',
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 0),
    },
    '&.is-active-ac': {
      '& .is-active': {
        backgroundColor: 'rgba(0, 0, 0, .2)'
      }
    }
  },
}))(MuiAccordionDetails);