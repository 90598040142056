// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent:'center',
      width:'80%',
      maxWidth:'546px',
      height:'350px',
      '& > *': {
        marginBottom:'0',
        width: '100%',
        height: '250px',
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(4, 2, 1),
        },
        '@media screen and (max-height: 790px)': {
          height:'200px',
        }  

      },
      '& .address-box': {
        margin: theme.spacing(2, 0),
        backgroundColor: '#f1f1f1',
        cursor: 'pointer',
        height: 'fit-content',
        '&.selected': {
          border: '2px solid',
          borderColor: theme.palette.primary.main
        },  
        '& .MuiCardContent-root': {
          paddingBottom: '14px'
        },  
      }
    },
    boxTop: {
      display: 'flex',
      flexDirection:'column',
      overflowX:'hidden',
      width:'437px',
      '& > *': {
        marginRight: theme.spacing(2),
      },
      '&::-webkit-scrollbar': {
        width: '0.4em', /* set the width of the scrollbar */
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,0.2)', /* set the color of the scrollbar thumb */
      },
      '@media screen and (max-height:790px)':{
        height:'200px',
        overflowY:'scroll',
      }
    },
    paymentWrapper:{
      padding:'0',
      width:'437px',
      height:'200px'
    },
    lastPaymentRow:{
      display:'flex',
      flexDirection:'row-reverse',
      justifyContent:'space-between',
      marginLeft:'10px',
    },
    paymentMethodsWrapper: {
      width: '100%',
      padding: 0,
      paddingTop:'20px',
      display: 'flex',
      justifyContent:'center',
      '& .MuiCard-root': {
        backgroundColor: '#f1f1f1',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1 ),
        cursor: 'pointer',
        height: 'fit-content',
        [theme.breakpoints.down('xs')]: {
          width: '90%',
        }
      },
      '& .selected': {
        border: '2px solid',
        borderColor: theme.palette.primary.main
      },
      '& .MuiCardContent-root': {
          height:'50px',
          textAlign:'center',
          width:'auto',
          minWidth:'84px',
          '@media screen and (max-width:760px)':{
            width:'90px'
          }
      }
    },    
    paymentMethodsBox:{
      fontSize:'10px'
    },
    rowTotal: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(2),
      gap:'10px',
      '& > *': {
        marginBottom: theme.spacing(1),
      },
    },
    rowActions: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      '& > *': {
        marginRight: theme.spacing(2),
      },
    },
  }),
);

export default useStyles;
