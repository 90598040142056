// Material UI.
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftColumn: {
      alignItems: 'center',
    },
    itemCard: {
      background: grey[100],
      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
      borderRadius: '6px',
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
      marginRight: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        marginRight: 0,
      },
      '& .MuiAvatar-root': {
        width: '80px',
        height: 'auto',
        margin: '0 auto',
        [theme.breakpoints.down('xs')]: {
          width: '50px',
        height: '50px'
        },
      }
    },
    rightColumn: {
      height: 'fit-content',
      padding: theme.spacing(1),
      border: '1px solid',
      borderRadius: '6px',
      borderColor: theme.palette.primary.main,
      marginTop: theme.spacing(1),
      '& .MuiTextField-root': {
        width: '100%'
      },
      '& .MuiFormControl-root': {
        width: '100%'
      }
    },
    secondaryInfo: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap'
      },
      '& > div': {
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
          minWidth: '45%'
        }
      }
    },
    refundWrapper: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'right',
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2)
      }
    },
    formControl: {
      width: '100%',
      padding: theme.spacing(2, 0)
    },
    actionsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      '& button': {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          marginLeft: theme.spacing(1),
        },
      }
    },
    modalPaper: {
      padding: theme.spacing(2, 4),
      position: 'absolute',
      width: '600px',
      height: '268px',
      top: 'calc(50% - 134px)',
      left: 'calc(50% - 300px)',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2, 1),
        width: '90%',
        left: '5%'
      },
    },
    modalHeading: {
      display: 'flex',
      marginBottom: theme.spacing(4)
    },
    modalFields: {
      display: 'flex',
      margin: theme.spacing(0, 1, 5)
    },
    modalField: {
      width: '120px',
      marginRight: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        '& .MuiInputBase-root': {
          width: '100%'
        },
      },
    },
    modalActions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      '& button': {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          marginLeft: theme.spacing(1),
        },
      }
    },
    discountLabel: {
      textDecoration: 'line-through'
    },
    type: {
      padding: '0 5px 2px',
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '4px',
      marginLeft: '10px'
    },
    variationSingle: {
      float: 'right',
      [theme.breakpoints.down('md')]: {
        float: 'unset',
        display: 'block'
      },
    },
    chip: {
      marginRight: '5px',
      marginBottom: '2px',
      fontFamily: 'VisbyCF-Bold',
      width: 'fit-content',
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
        flexWrap: 'wrap',
        background: 'none',
        display: 'flex',
        justifyContent: 'flex-start',
        height: 'auto'
      },
      '& .MuiAvatar-root': {
        width: 'auto',
        height: '16px',
        padding: '0 5px',
        fontSize: '11px',
        borderRadius: '15px',
        fontFamily: 'VisbyCF-Bold',
        color: 'black',
        marginLeft: '5px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '10px',
          marginBottom: '2px',
          marginLeft: 0,
          marginRight: '2px',
        }
      },
      '& .MuiChip-label': {
        [theme.breakpoints.down('sm')]: {
          width: 'auto',
          height: '16px',
          padding: '0 5px',
          fontSize: '11px',
          borderRadius: '15px',
          fontFamily: 'VisbyCF-Bold',
          color: 'black',
          backgroundColor: theme.palette.grey[300],
          marginBottom: '2px'
        }
      },
      '&.MuiChip-outlined': {
        [theme.breakpoints.down('sm')]: {
          border: 0
        }
      }
    },
    orderStatus: {
      textAlign: 'center'
    },
    pickupIdContainer:{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    editableIcons:{
      display: 'flex',
      gap: '8px',
      '& .MuiButtonBase-root':{
        margin: 0
      }
    },
    identificationPickUpContainer:{
      display: 'flex',
      alignItems: 'center',
      width: '140px',
    }
  }),
);

export default useStyles;