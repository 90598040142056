// React.
import React from 'react';

// Components.
import UserSearch from '../users/UserSearch';
import UserList from '../users/UserList';

const Users = () => {
  return (
    <div>
      <UserSearch />
      <UserList />
    </div>
  )
}

export default Users;
