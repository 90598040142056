// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: '100%',
      height: 'auto',
      maxWidth: '100%',
    },
    editImgWrapper: {
      '& #icon-button-file': {
        display: 'none'
      }
    },
    mainImg: {
      width: '100%',
      maxHeight: '300px',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        width: '100%',
        maxHeight: '220px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxHeight: '114px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: 'auto',
        marginBottom: theme.spacing(1)
      },
      '& .MuiAvatar-img' : {
        maxHeight: 'inherit'
      }
    },
    smallImg: {
      width: '30%',
      maxHeight: '76px',
      position: 'relative',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        width: '30%',
        maxHeight: '76px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '30%',
        maxHeight: '45px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '86px',
        maxHeight: '86px',
        marginBottom: theme.spacing(1),
      },
      '& .MuiAvatar-rounded': {
        width: '100%',
        height: 'inherit',
      },
      '& .MuiAvatar-img' : {
        maxHeight: 'inherit'
      }
      
    },
    deleteIcon: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      zIndex: 1,
      '& :hover': {
        cursor: 'pointer'
      }
    },
    smallImgWraper: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    imgWrapper: {
      position: 'relative',
    },
    formControl: {
      marginBottom: theme.spacing(1),
      width: '100%'
    },
    descripLabel: {
      marginBottom: '5px'
    }
  }),
);

export default useStyles;
