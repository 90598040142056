// React.
import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";


// Components.
import TextMaskPhone from '../../admin/utils/TextMaskPhone';
import PointsIcon from '../../utils/PointsIcon';

// Material UI.
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Avatar from '@material-ui/core/Avatar';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'; 
import FavoriteIcon from '@mui/icons-material/Favorite';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { updateNormalUser } from '../../../actions/userActions/UserActions';
import {
  addUserAddress,
  updateUserAddress,
} from '../../../actions/userAddressActions/UserAddressActions';
import { logoutUser } from '../../../actions/authActions/AuthActions';
import { RootStore } from '../../../Store';

// Translations.
import { useTranslation } from 'react-i18next';

// Global Types.
import { UserType, AddressUserType } from '../../admin/models/ModelTypes';

// Styles.
import useStyles from './UserEditStyles';

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';
import { Table } from '@material-ui/core';
import axios from 'axios';
import Pagination from '../../admin/utils/ResultsPagination'
import UseTermsOmegaCoin from '../pages/UseTermsOmegaCoins';

interface UserEditProps {
  user: UserType;
  userAddresses: Array<AddressUserType> | undefined;
}

const UserEdit = ({ user, userAddresses }: UserEditProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();
  let history = useHistory();
  const location=useLocation();
  const page = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const result = searchParams.get("page");
    return result !== null && !isNaN(parseInt(result, 10)) ? parseInt(result, 10) : 1;
  }, [location.search]);
  const omegaCoinHistoryRef=useRef<any>(null);
  



  // Default stage object value.
  const defaultAddresses = [
    {
      id: 0,
      province: '',
      canton: '',
      district: '',
      fullAddress: '',
      phoneNumber: '',
      postalCode: '',
      userEmail: user.email,
    },
  ];



  const defaultAddressesState = {
    addresses: userAddresses !== undefined ? userAddresses : defaultAddresses,
  };

  // Define local state for the user.
  const [userState, setUserState] = useState<UserType>(user);
  const [userAddressesState, setUserAddressesState] = useState<any>(
    defaultAddressesState
  );
  const [omegaCoinsHistoryState,setOmegaCoinHistory]=useState<any>();
  const [editing, setEditing] = useState<boolean | false>(false);
  const [fieldError, setFieldError] = useState<boolean | false>(false);
  const [phoneError, setPhoneError] = useState<boolean | false>(false);
  const [openModal, setOpenModal] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [addressError, setAddressError] = useState<boolean>(false);
  const [isSocialLogin, setIsSocialLogin] = useState<boolean>(false);
  const [width, setWidth] = useState(window.innerWidth);


  // Redux state.
  const reduxState = useSelector((state: RootStore) => state.user);
  const loading = reduxState.loading;
  const authReduxState = useSelector((state: RootStore) => state.auth);
  const loggedUser = authReduxState.loggedUser;


  //apiUrl
  const apiURL: string | undefined = process.env.REACT_APP_API_URL;

  // Redirects after log out.
  useEffect(() => {
    if (!loggedUser || loggedUser === undefined) {
      history.push('/user/login');
    } else {
      setIsSocialLogin(loggedUser.isSocialLogin);
    }
    // eslint-disable-next-line
  }, [loggedUser]);

  useEffect(() => {
    // Validations.
    if (
      userState.email === '' ||
      userState.name === '' ||
      userState.lastName === '' ||
      userState.role === ''
    ) {
      setFieldError(true);
    } else {
      setFieldError(false);
    }
    if (
      userState.phone !== '' &&
      userState.phone !== 'null' &&
      userState.phone !== undefined
    ) {
      let phoneMask = new RegExp(/\(?([0-9]{4})\)?([ ]?)([0-9]{4})/);
      phoneMask.test(userState.phone)
        ? setPhoneError(false)
        : setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  }, [userState, userAddressesState]);


  //Handles de scroll into view when coming outside of the page
  useEffect(()=>{
    const { hash } = window.location;
    if (hash !== '') {
      const target = document.querySelector(hash);
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
      }
    }
  },[])
  //Make the call for the OMEGA Coins history

  useEffect(()=>{
      const headers: object = {
      ContentType: 'application/json',
      Authorization: 'Bearer ' + localStorage.token
    }
    const params: object ={
      pageSize:10,
      page:page()-1,
      size:30
    }
    axios.get(`${apiURL}/user/getAllUserPointsTransactions`, {
      headers: headers,
      params
    }).then((res)=>{
      setOmegaCoinHistory(res.data.data);
    });
  },[location,apiURL,page])


  // Handles the field changes to update the state.
  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    // Use the HTML name atribute to know what property update.
    const name = event.target.name as keyof typeof userState;
    var inputValue = String(event.target.value);

    if (name === 'name' || name === 'lastName') {
      inputValue = inputValue.replace(
        /[\d,-,!,@,#,$,%,^,&,*,(,),-,_,=,+,:,;,',',?,{,}]/g,
        ''
      );
    }

    setUserState({
      ...userState,
      [name]: inputValue,
    });

    setPasswordError(false);
  };


  //Handles the Pagination of the OmegaCoinsHistory
  const omegaCoinsHistoryPagination = (omegaCoinsHistoryState:any) =>{
    const totalPages = omegaCoinsHistoryState?.totalPages||1;
    return (
      <div>
        {totalPages > 1 && <Pagination current={page()} path="/user/details" pagesNumber={totalPages} />}
      </div>
    );
  }

  // Handles the field changes to update the state.
  const handleChangeAddress = (
    e: React.ChangeEvent<{ value: unknown }>,
    index: number,
    objectKey: string
  ) => {
    const name = objectKey as keyof typeof userAddressesState;
    const newAddressesState = userAddressesState.addresses;
    newAddressesState[index][name] = e.target.value;

    setUserAddressesState({
      ...userAddressesState,
      addresses: newAddressesState,
    });
  };

  const handleUploadClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesList = event.target.files as FileList | null;
    const tempURL = URL.createObjectURL(filesList![0]);

    setUserState({
      ...userState,
      profileImageUrl: tempURL,
      imgFile: filesList![0],
    });
  };

  const editOnClick = () => {
    setEditing(true);
  };

  const cancelOnClick = () => {
    setEditing(false);
    // Set user state to original values.
    setUserState(user);
    setUserAddressesState(defaultAddressesState);
  };

  const addNewAddressObject = () => {
    let newAddress = {
      id: 0,
      province: '',
      canton: '',
      district: '',
      fullAddress: '',
      phoneNumber: '',
      postalCode: '',
      userEmail: user.email,
    };
    let newArray = userAddressesState.addresses;
    newArray.push(newAddress);
    setUserAddressesState({
      ...userAddressesState,
      addresses: newArray,
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const validatePasswordField = () => {
    var result = true;
    if (userState.oldPassword === '' || userState.oldPassword === undefined) {
      result = false;
    }
    if (userState.password === '' || userState.password === undefined) {
      result = false;
    }
    if (
      userState.passwordConfirm === '' ||
      userState.passwordConfirm === undefined
    ) {
      result = false;
    }
    if (userState.password !== userState.passwordConfirm) {
      result = false;
    }
    return result;
  };

  const updatePasswordHandler = () => {
    if (validatePasswordField()) {
      dispatch(updateNormalUser(userState));
      handleCloseModal();
    } else {
      setPasswordError(true);
    }
  };

  const validateAddresses = () => {
    var result = false;
    const newAddressesState = userAddressesState.addresses;
    newAddressesState.forEach((address: AddressUserType) => {
      if (
        address.province === '' ||
        address.canton === '' ||
        address.district === '' ||
        address.fullAddress === '' ||
        address.phoneNumber === ''
      ) {
        result = true;
        setAddressError(true);
      }
    });
    return result;
  };

  const logoutOnClick = () => {
    dispatch(logoutUser());
  };

  const saveOnClick = () => {
    // Save the user on the BE.
    if (!validateAddresses()) {
      defaultAddressesState.addresses.forEach((address: AddressUserType) => {
        if (address.id === 0) {
          dispatch(addUserAddress(address));
        } else {
          dispatch(updateUserAddress(address));
        }
      });
      dispatch(updateNormalUser(userState));
      setEditing(false);
    }
  };
  const goToWishlist = () => {
    history.push('/user/wishlists');
  };

  //Getting the current width of the screen

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const getButtonWishlistString = () => {
    return t('wishlist.button-see-my-wishlist')
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Typography variant='h4' component='h1' color='primary'>
            {t('users.my-info')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <div className={classes.leftColumnWrapper}>
            <div className={classes.imgWrapper}>
              <Avatar
                variant='circular'
                alt='Remy Sharp'
                src={userState.profileImageUrl}
                className={classes.userImg}
              />
              {editing && (
                <div className={classes.editImgWrapper}>
                  <input
                    accept='image/*'
                    id='icon-button-file'
                    type='file'
                    onChange={handleUploadClick}
                  />
                  <label htmlFor='icon-button-file'>
                    <IconButton
                      color='primary'
                      aria-label='upload picture'
                      component='span'
                    >
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </div>
              )}
            </div>
            {editing ? (
              <LockOpenIcon fontSize='large' />
            ) : (
              <LockIcon fontSize='large' color='disabled' />
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <form>
            <div className={classes.upperInfo}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControl className={classes.formControl}>
                  <Typography variant='caption'>{t('users.name')}</Typography>
                  <TextField
                    className={classes.formField}
                    id='u-name'
                    name='name'
                    autoFocus
                    value={userState.name}
                    onChange={handleChange}
                    disabled={!editing}
                    error={userState.name === ''}
                    helperText={
                      userState.name === '' ? t('users.field-required') : ''
                    }
                  />
                </FormControl>

                <FormControl className={classes.formControl}>
                  <Typography variant='caption'>
                    {t('users.last-name')}
                  </Typography>
                  <TextField
                    className={classes.formField}
                    id='u-last-name'
                    name='lastName'
                    value={userState.lastName}
                    onChange={handleChange}
                    disabled={!editing}
                    error={userState.lastName === ''}
                    helperText={
                      userState.lastName === '' ? t('users.field-required') : ''
                    }
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Typography variant='caption'>{t('users.phone')}</Typography>
                  <Input
                    id='u-phone'
                    name='phone'
                    placeholder='8888 8888'
                    value={userState.phone !== 'null' ? userState.phone : ''}
                    onChange={handleChange}
                    inputComponent={TextMaskPhone as any}
                    disabled={!editing}
                    error={phoneError}
                    className={classes.formField}
                  />
                </FormControl>
              </div>
              
              <div style={{ display: 'flex', alignSelf: 'center' }} onClick={goToWishlist}>
                <div className={`${classes.points} ${classes.upperPoints}`}>
                  <div color='primary' className={classes.omegaCoinsButton}  > 
                  <Typography
                    variant='h4'
                    className={classes.pointsTitle}
                    style={{ marginBottom: '0px' }}
                  >
                    {getProjectConfig().STORE_CREDITS_NAME}
                  </Typography>
                  <Typography
                    variant='h3'
                    className={classes.pointsNumberButton}
                  >
                    <PointsIcon width={'30'} />
                    {userState.points?.toLocaleString('en-US')}
                  </Typography>
                  <a className={classes.historialButton} href="/user/details#omegaCoinHistoryRef">{t('users.see-history')}</a>
                  </div>
                  
                  <div color='primary' className={classes.wishlistButton}  > 
                    <Typography
                      variant='h4'
                      className={classes.pointsTitle}
                      style={{ marginBottom: '0px' }}
                    >           
                      {getButtonWishlistString()}
                    </Typography>
                    <Typography
                      variant='h3'
                      className={classes.pointsNumberButton}
                    >
                    <FavoriteIcon className={classes.heart}/>
                    </Typography>
                  </div>
                </div>
              </div>
              
            </div>
            <div className={classes.addressWrapper}>
              {userAddressesState.addresses.map(
                (address: AddressUserType, index: number) => (
                  <FormControl
                    key={index}
                    className={`${classes.formControl} ${classes.addressGroup}`}
                  >
                    <Typography
                      variant='caption'
                      className={classes.addressLabel}
                    >
                      {t('users.address-1')} {index + 1}
                    </Typography>
                    <TextField
                      className={classes.formField}
                      id='u-province'
                      name='province'
                      label={t('users.province')}
                      value={userAddressesState.addresses[index].province}
                      onChange={(e) =>
                        handleChangeAddress(e, index, 'province')
                      }
                      disabled={!editing}
                      error={
                        userAddressesState.addresses[index].province === '' &&
                        addressError
                      }
                      helperText={
                        userAddressesState.addresses[index].province === '' &&
                        addressError
                          ? t('users.field-required')
                          : ''
                      }
                    />
                    <TextField
                      className={classes.formField}
                      id='u-canton'
                      name='canton'
                      label={t('users.canton')}
                      value={userAddressesState.addresses[index].canton}
                      onChange={(e) => handleChangeAddress(e, index, 'canton')}
                      disabled={!editing}
                      error={
                        userAddressesState.addresses[index].canton === '' &&
                        addressError
                      }
                      helperText={
                        userAddressesState.addresses[index].canton === '' &&
                        addressError
                          ? t('users.field-required')
                          : ''
                      }
                    />
                    <TextField
                      className={classes.formField}
                      id='u-district'
                      name='district'
                      label={t('users.district')}
                      value={userAddressesState.addresses[index].district}
                      onChange={(e) =>
                        handleChangeAddress(e, index, 'district')
                      }
                      disabled={!editing}
                      error={
                        userAddressesState.addresses[index].district === '' &&
                        addressError
                      }
                      helperText={
                        userAddressesState.addresses[index].district === '' &&
                        addressError
                          ? t('users.field-required')
                          : ''
                      }
                    />
                    <TextField
                      className={classes.formField}
                      id='u-direction'
                      name='fullAddress'
                      label={t('users.direction')}
                      value={userAddressesState.addresses[index].fullAddress}
                      onChange={(e) =>
                        handleChangeAddress(e, index, 'fullAddress')
                      }
                      disabled={!editing}
                      error={
                        userAddressesState.addresses[index].fullAddress ===
                          '' && addressError
                      }
                      helperText={
                        userAddressesState.addresses[index].fullAddress ===
                          '' && addressError
                          ? t('users.field-required')
                          : ''
                      }
                    />
                    <FormControl className={classes.formControlAddress} id='omegaCoinHistoryRef' ref={omegaCoinHistoryRef}>
                      <InputLabel htmlFor='u-phone-number'>
                        {t('users.phone-number')}
                      </InputLabel>
                      <Input
                        id='u-phone-number'
                        name='phoneNumber'
                        value={userAddressesState.addresses[index].phoneNumber}
                        onChange={(e) =>
                          handleChangeAddress(e, index, 'phoneNumber')
                        }
                        disabled={!editing}
                        inputComponent={TextMaskPhone as any}
                        className={classes.formField}
                        error={
                          userAddressesState.addresses[index].phoneNumber ===
                            '' && addressError
                        }
                      />
                    </FormControl>

                    <TextField 
                      className={classes.formField}
                      id='u-postal-code'
                      name='postalCode'
                      label={t('users.postal-code')}
                      value={userAddressesState.addresses[index].postalCode}
                      onChange={(e) =>
                        handleChangeAddress(e, index, 'postalCode')
                      }
                      disabled={!editing}
                    />
                  </FormControl>
                )
              )}
            </div>
            {userAddressesState.addresses.length === 0 && (
              <Typography className={classes.noAddress} color='secondary'>
                {t('users.no-address')}
              </Typography>
            )}
            {editing && userAddressesState.addresses.length < 2 && (
              <div className={classes.addAddress}>
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  onClick={addNewAddressObject}
                >
                  {userAddressesState.addresses.length > 0
                    ? t('users.add-second-address')
                    : t('users.add-address')}
                </Button>
              </div>
            )}
            <FormControl className={classes.formControl} 
>             {editing && (
                <div className={classes.actionsWrapper}>
                  {loading === 'USER_UPDATE' && <CircularProgress />}
                  <Button
                    variant='text'
                    color='primary'
                    onClick={saveOnClick}
                    disabled={fieldError || phoneError}
                  >
                    {t('users.save')}
                  </Button>
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={cancelOnClick}
                  >
                    {t('users.cancel')}
                  </Button>
                  <Button variant='contained' color='primary' disabled >
                    {t('users.edit-user')}
                  </Button>
                </div>
              )}
              {!editing && (
                <div className={classes.actionsWrapper}>
                  <Button color='primary' onClick={logoutOnClick}>
                    {t('authentication.log-out')}
                  </Button>
                  {!isSocialLogin && (
                    <Button color='primary' onClick={handleOpenModal}>
                      {t('users.reset-password')}
                    </Button>
                  )}
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={editOnClick}
                  >
                    {t('users.edit-user')}
                  </Button>
                </div>
              )}
            </FormControl>
          </form>
        </Grid>
        
        {userState?.points! > 0 && (
          <>
            <Divider className={classes.wide} />
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.points} >
                <Typography variant='h4' className={classes.pointsTitle}>
                  {getProjectConfig().STORE_CREDITS_NAME}
                </Typography>
                <Typography>
                  {t('users.user-points-text', {
                    name: getProjectConfig().STORE_CREDITS_NAME,
                  })}
                </Typography>
                <Typography
                  variant='h3'
                  color='primary'
                  className={classes.pointsNumber}
                >
                  <PointsIcon width={'30'} />
                  {userState.points?.toLocaleString('en-US')}
                </Typography>
              </div>
              <Table size="medium" aria-label="a dense table" className={classes.tableWrapper}>
          <TableHead>
            <TableRow >
            <TableCell >{t('logs.created-by')}</TableCell>
              <TableCell >{ t('logs.date') }</TableCell>
                  {width>600?<TableCell >{ t('logs.reason') }:</TableCell>:<></>}
              <TableCell >{t('logs.total-used')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              {omegaCoinsHistoryState!==undefined? omegaCoinsHistoryState.content.map((transaction:any)=>{
                    return (
                      <TableRow  >            
                      <TableCell component="th" scope="row" >
                      {transaction.createdBy.firstName}
                      </TableCell>
                        <TableCell > 
                          {transaction.creationDate.substring(0, 10)}
                        </TableCell>
                        
                      {width>600?<TableCell   >
                        <div className={classes.Reason}>{transaction.reason}</div>
                      </TableCell>:<></>}
                      <TableCell  >
                        {
                        transaction.newValue<transaction.oldValue? 
                        transaction.oldValue-transaction.newValue :
                        transaction.newValue-transaction.oldValue
                        }
                      </TableCell>
                      </TableRow>
                    )
                  }):<TableRow>Loading...</TableRow>}
          </TableBody>
        </Table>
            {omegaCoinsHistoryPagination(omegaCoinsHistoryState)}
            </Grid>
          </>
        )}
          <Divider className={classes.wide} />
          <div style={{
            height: 430,
            paddingBottom: 25,
            overflow: 'auto',
          }} 
            
          >
            <UseTermsOmegaCoin />
          </div>    

      </Grid>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby='Forgot Password'
      >
        <Paper elevation={3} className={classes.modalPaper}>
          <Typography variant='h5' color='primary'>
            {t('users.change-password')}
          </Typography>
          <div className={classes.modalField}>
            <TextField
              id='user-current-password'
              name='oldPassword'
              type='password'
              label={t('authentication.current-password')}
              onChange={handleChange}
              color='primary'
              fullWidth
            />
            <TextField
              id='user-password'
              name='password'
              type='password'
              label={t('authentication.new-password')}
              onChange={handleChange}
              color='primary'
              fullWidth
            />
            <TextField
              id='user-password-confirm'
              name='passwordConfirm'
              type='password'
              onChange={handleChange}
              label={t('authentication.password-confirm')}
              color='primary'
              fullWidth
            />
            {passwordError && (
              <Typography color='secondary'>
                {t('users.change-password-error')}
              </Typography>
            )}
          </div>
          <Button
            variant='contained'
            color='primary'
            className={classes.modalButton}
            onClick={updatePasswordHandler}
          >
            {' '}
            {t('users.save')}{' '}
          </Button>
        </Paper>
      </Modal>
    </Fragment>
  );
};

export default UserEdit;
