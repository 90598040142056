// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height:'auto', //225px
      flexDirection:'column',
      '& > *': {
        margin: theme.spacing(1),
        padding: theme.spacing(4, 4),
        width: '80%',
        height: 'auto',
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(4, 2),
        },
      },
      '& > .MuiPaper-root': {
        minHeight: '58vh',
        [theme.breakpoints.down('xs')]: {
          height: 'auto',
          minHeight: 'auto'
        },
      },
      '&  > .MuiTypography-root': {
        marginBottom: '0',
      }
    },
    sectionTitle: {
      flexGrow: 1,
    },
    wrapperDetails: {
      maxHeight: '460px',
      display: 'block',
      position: 'relative',
      overflowY: 'auto',
    },
    boxTop: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      margin:'0',
      padding:'0',
      height:'50px',
      marginLeft:'20px',
      marginBottom: theme.spacing(2),
      '& > *': {
        marginRight: theme.spacing(2),
      },
      '&  > .MuiTypography-root': {
        marginBottom: '0',
      }
    },
    boxBottom:{
      margin: '0',
      padding: '0',
      width: '100%',
      maxHeight: '400px',
      overflow: 'scroll',
      /* hide scrollbar */
      '-ms-overflow-style': 'none',  /* IE and Edge */
      'scrollbar-width': 'none',     /* Firefox */
      '&::-webkit-scrollbar': {
        width: '0px',
        background: 'transparent'
      }
    },
    rowTotal: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      marginTop: theme.spacing(2),
      '& > *': {
        //marginBottom: theme.spacing(1),
      },
    },
    rowActions: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      //marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      '& > *': {
        //marginRight: theme.spacing(2),
      },
    },
    rowNotes: {
      display: 'flex',
      textAlign: 'right',
      flexDirection: 'column',
      '& > *': {
        width: '100%',
        marginRight: theme.spacing(2),
      },
    }
  }),
);

export default useStyles;
