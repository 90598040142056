import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      height: '520px',
      [theme.breakpoints.down('xs')]: {
        height: '430px',
      },
      '& svg': {
        position: 'absolute',
        zIndex: 2,
        backgroundColor: 'transparent !important',
        opacity: '1 !important',
        top: 'calc(50% - 46px) !important',
        [theme.breakpoints.down('xs')]: {
          top: 'calc(50%) !important',
        },
      },
      '& .left-arrow': {
        right: '40px',
        '& :hover': {
          cursor: 'pointer'
        },
        [theme.breakpoints.down('xs')]: {
          right: '10px',
        },
      },
      '& .right-arrow': {
        left: '40px',
        '& :hover': {
          cursor: 'pointer'
        },
        [theme.breakpoints.down('xs')]: {
          left: '10px',
        },
      },
      '& .control-dots': {
        bottom: '44px',
        '& .dot': {
          margin: '0 5px',
          width: '12px',
          height: '12px',
          background: 'transparent',
          border: '2px solid white'
        },
        '& .dot.selected': {
          background: 'white'
        }
      }
    },
    gradient: {
      height: '45px',
      width: '100%',
      position: 'absolute',
      bottom: 0,
      background: 'linear-gradient(180deg, rgba(34, 102, 255, 0) 0%, hsl(0, 0%, 89%) 100%);'
    }
  })
);

export default useStyles;