import {
  READ_NOTIFICATION,
  NOTIFICATION_LOADING,
  NOTIFICATION_FAIL,
  GET_USER_NOTIFICATIONS,
  GET_ORDER_NOTIFICATIONS,
  NotificationDispatchTypes,
  UserNotificationDataType,
  OrderNotificationType
} from "../actions/notificationActions/NotificationActionsTypes";

// Default state interface.
interface DefaultStateI {
  loading: string,
  actionStatus?: any,
  userNotifications?: UserNotificationDataType,
  orderNotifications?: OrderNotificationType,
  notificationsRead: boolean
}

// Default state.
const defaultState: DefaultStateI = {
  loading: '',
  notificationsRead: false
};

const OrderReducer = (state: DefaultStateI = defaultState, action: NotificationDispatchTypes) : DefaultStateI => {
  switch (action.type) {
    case READ_NOTIFICATION:
      return {
        ...state,
        loading: '',
        notificationsRead: true
      }
    case NOTIFICATION_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case NOTIFICATION_FAIL:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'error'}
      }
    case GET_USER_NOTIFICATIONS:
      return {
        ...state,
        loading: '',
        notificationsRead: false,
        userNotifications: action.payload
      }
    case GET_ORDER_NOTIFICATIONS:
      return {
        ...state,
        loading: '',
        orderNotifications: action.payload
      }
    default:
      return state
  }
};


export default OrderReducer;
