// React.
import React from 'react';

// Components.
import BundleSearch from '../bundles/BundleSearch';
import BundleList from '../bundles/BundleList';

const Bundles = () => {
  return (
    <div>
      <BundleSearch />
      <BundleList />
    </div>
  )
}

export default Bundles;
