// Material UI
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Typography:{
        fontFamily: 'VisbyCF-Bold',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
        },
        flexDirection: "row",
    },
    paper: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      color: theme.palette.text.secondary,
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
      flexDirection: "row",
    },
    totalLabel: {
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginRight: theme.spacing(1),
      [theme.breakpoints.down("md")]: {
        marginRight: 0,
      },
    },
  })
);

export default useStyles;
