import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    menuButton: {
      color: 'white',
      position: 'fixed',
      zIndex: 1100,
      top: '65px',
      right: '5px',
      [theme.breakpoints.down('xs')]: {
        top: '150px',
      },
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
      '& .MuiIconButton-label': {
        color: 'white'
      },
      '& .mobile-title': {
        width: '100%',
        fontFamily: 'VisbyCF-Bold',
        color: 'white',
        marginLeft: theme.spacing(1)
      }
    },
    filtersWrapper: {
      paddingRight: theme.spacing(1),
      '& .selected': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }
    },
    filterCategory: {
      fontFamily: 'VisbyCF-Bold',
    }
  }),
);

export const Accordion = withStyles({
  root: {
    margin: '0',
    boxShadow: 'none',
    position: 'initial',
    '&$expanded': {
      margin: '0',
    },
    '& .enabled': {
      color: '#009900'
    },
    '& .selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },
    '&.desktop': {
      backgroundColor: 'transparent',
      padding: '0 !important'
    }
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .3)',
    marginBottom: 1,
    padding: '0',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      margin: '0',
      borderBottom: '0',
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0
    }
  },
  content: {
    display: 'flex',
    margin: '12px 15px',
    fontFamily: 'VisbyCF-Bold',
    '&$expanded': {
      margin: '0 15px',
    },
    '& > *': {
      alignItems: 'center',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: '1px solid rgba(0, 0, 0, .3)',
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 0),
    },
  },
}))(MuiAccordionDetails);
