// React.
import React, { Fragment, useEffect, useState, useRef } from 'react';

// Components.
import Pagination from '../utils/ResultsPagination';
import ImageAvatar from '../utils/ImageAvatar';
import ItemEdit from './ItemEdit';
import ItemSingleEdit from './ItemSingleEdit';

// Material UI
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

// Redux
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {getItems, getItemLabels, refreshItemView} from "../../../actions/itemActions/ItemActions";
import { getVendors } from '../../../actions/vendorActions/VendorActions';

// Moment.
import Moment from 'moment';

// Global config.


// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import { useStyles, Accordion, AccordionSummary, AccordionDetails } from './ItemAccordionStyles';
import defaultImage from '../../../images/default-img.jpg'; 
import scrollToTop from '../../utils/scrollToTop';
import { getProjectConfig } from '../../../getProjectConfig';

const ItemAccordion = () => {
  const [t] = useTranslation('global');
  const classes = useStyles();
  const dispatch = useDispatch();

  // Redux state.
  const itemProductState = useSelector((state: RootStore) => state.item);
  const updatedItem = itemProductState.item;
  const itemsLoading = itemProductState.loading;
  const {itemList, refresh, itemFilters: filters} = itemProductState;
  const authState = useSelector((state: RootStore) => state.auth);
  const loggedUser = authState.loggedUser;

  // Local component state.
  const [currentPage, setCurrentPage] = useState<number>(1);
  const url = window.location.href;

  const firstRenderHook1 = useRef(true);
  const firstRenderHook2 = useRef(true);
  useEffect(() => { 
    // Don't do anything the first load.
    if (firstRenderHook1.current) {
      firstRenderHook1.current = false;
      return;
    }

    getItemsData(true);

    // eslint-disable-next-line
  }, [updatedItem]);

  useEffect(() => {
     // Don't do anything the first load.
    if (firstRenderHook2.current) {
      firstRenderHook2.current = false;
      return;
    }

    if (!itemsLoading) {
      getItemsData();
    }
    
    // eslint-disable-next-line
  }, [url, refresh]);

  const getItemsData = (isItemUpdate = false) => {
    const params = new URLSearchParams(window.location.search);
    let page = params.get('page');
    const pNumber = Number(page);
    if (page && page !== null) {
      const qParams = {
        sort: 'id,desc',
        ...filters,
        page: pNumber - 1,
        size: getProjectConfig().PAGES_SIZE,
        paged: true,
        isUniquePerCode: true
      };
      dispatch(getItems(qParams, 'seller'));
      dispatch(getVendors());
      setCurrentPage(pNumber);
      if (!isItemUpdate) {
        scrollToTop();
      }
    } else {
      const qParams = {
        sort: 'id,desc',
        ...filters,
        page: 0,
        size: getProjectConfig().PAGES_SIZE,
        paged: true,
        isUniquePerCode: true
      };
      dispatch(getItems(qParams, 'seller'));
      dispatch(getVendors());
      setCurrentPage(1);
      if (!isItemUpdate) {
        scrollToTop();
      }
    }

    dispatch(refreshItemView(false));
  };

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string, itemCategoryId: number, vendorId: number) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
    // Get labels.
    dispatch(getItemLabels(itemCategoryId));
  };

  if (itemList?.items.length === 0) {
    return (
      <div className={ classes.circularProgress }>
        <Typography color="textSecondary">{ t('items.no-items') }</Typography>
      </div>
    )
  }
  
  return (
    <Fragment>
        {
          itemList?.items !== [] &&
          (itemList?.items.map(item => 
            <Fragment key={item.id}>
              {((loggedUser?.roles?.includes('ROLE_SELLER_ASSOCIATE') && item?.totalQuantity > 0 && item?.category! === 'Single') || !loggedUser?.roles?.includes('ROLE_SELLER_ASSOCIATE')) &&
                <Accordion key={item.id} square expanded={expanded === `panel-u-${item.id}`} onChange={handleChange(`panel-u-${item.id}`, item.itemType?.id as number, item.vendor?.id as number)}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id={`panel-header-${item.id}`}>
                    <Grid container spacing={2}>
                      <Grid item xs={4} sm={2} className={classes.firstWrapper}>
                        <ImageAvatar src={item.images![0] ? item.images![0].url : defaultImage} alt="Item main image." />
                      </Grid>
                      <Grid item xs={8} sm={3}>
                        <Typography variant="subtitle1">{t('items.name')} {item.name}</Typography>
                        <Typography variant="caption" color="textSecondary">{t('items.sku')} {item.code}</Typography>
                      </Grid>
                      <Grid item xs={8} sm={4} className={classes.lastWrapper}>
                        <Typography variant="subtitle1">{t('items.category')} {item.itemType?.name}</Typography>
                        <Typography variant="subtitle1">{t('items.types')} {item.category !== undefined ? item.category : '---'}</Typography>
                        <Typography variant="caption" color="textSecondary">{t('items.created')}: {Moment(item.creationDate).format('MM/DD/YYYY')}</Typography>
                      </Grid>
                      <Grid item xs={4} sm={3} className={classes.lastWrapper}>
                        <FiberManualRecordIcon className={item.isPublished ? 'enabled' : 'disabled'} color="disabled" />
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails id={`panel-details-${item.id}`}>
                    {(item?.category! === 'Single') ?
                      <ItemSingleEdit
                        item={item}
                        isExpanded={expanded === `panel-u-${item.id}`}
                      /> :
                      <ItemEdit
                        item={item}
                        isExpanded={expanded === `panel-u-${item.id}`}
                      />
                    }
                  </AccordionDetails>
                </Accordion>
              }
            </Fragment>
          ))
        }
        {
          itemList?.totalPages !== undefined && itemList?.totalPages !== 1 
          ? (<Pagination current={currentPage} path="/admin/items" pagesNumber={ itemList!.totalPages }/>) : ''
        }
    </Fragment>
  );
}

export default ItemAccordion;
