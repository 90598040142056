/**
 *****************************************
 *********** Dispatch Types **************
 *****************************************
 */
export const INVENTORY_REQUESTS_LOADING = "INVENTORY_REQUESTS_LOADING"
export const INVENTORY_REQUESTS_FAIL = "INVENTORY_REQUESTS_FAIL"
export const INVENTORY_REQUESTS_LIST = "INVENTORY_REQUESTS_LIST"
export const INVENTORY_REQUESTS_SET_FILTER = "INVENTORY_REQUESTS_SET_FILTER"
export const INVENTORY_REQUESTS_REFRESH = "INVENTORY_REQUESTS_REFRESH"
export const UPDATE_INVENTORY_REQUESTS = "UPDATE_INVENTORY_REQUESTS"

export interface Address {
  canton: string
  district: string
  fullAddress: string
  id: number
  phoneNumber: string
  postalCode: string
  province: string
}

export interface VendorCategory {
  id: {
    category: string
    vendorId: number
  }
}

export interface WarehouseItem {
  id: {
    itemId: number
    warehouseId: number
  }
  item: Item
  itemCode: string
  quantity: number
}

export interface Warehouse {
  description: string
  id: number
  isDeleted: boolean
  isDiscount: boolean
  is_discount: boolean
  name: string
  warehousesitems: WarehouseItem[]
}

export interface Vendor {
  address: Address
  coordinates: string
  id: number
  isAdminOnly: boolean
  isDeleted: boolean
  name: string
  phoneNumber: string
  sequence: number
  type: string
  vendorCategories: VendorCategory[]
  vendorCode: string
  warehouses: Warehouse[]
}

export interface Currency {
  code: string
  id: number
  isDeleted: boolean
  name: string
  symbol: string
}

export interface CustomPrice {
  customReference: string
  price: number
}

export interface Discount {
  id: number
  pricePercentage: number
}

export interface Image {
  id: number
  itemCode: string
  key: string
  url: string
}

export interface ItemType {
  currency: Currency
  description: string
  id: number
  imageUrl: string
  isDeleted: boolean
  isPublished: boolean
  name: string
  taxPercentage: number
}

export interface Label {
  description: string
  id: number
  isDeleted: boolean
  isSystem: boolean
  name: string
}

export interface LabelValue {
  id: number
  isDeleted: boolean
  label: Label
  value: string
}

export interface WarehouseCustomQuantity {
  id: {
    customReference: string
    itemId: number
    warehouseId: number
  }
  itemCode: string
  quantity: number
}

export interface Item {
  additionalInfo: string
  additionalPrice: number
  automaticPrice: boolean
  category: string
  code: string
  costPrice: number
  creationDate: string
  currency: Currency
  customPrices: CustomPrice[]
  discount: Discount
  discountPriceWithTaxes: number
  id: number
  images: Image[]
  isCustomPrice: boolean
  isDeleted: boolean
  isFeature: boolean
  isForSale: boolean
  isPublished: boolean
  itemType: ItemType
  labelValues: LabelValue[]
  lastUpdate: string
  minimumQuantity: number
  name: string
  notes: string
  price: number
  priceWithTaxes: number
  state: string
  totalQuantity: number
  warehousesCustomQuantities: WarehouseCustomQuantity[]
  warehousesQuantities: any[]
  weightPerUnit: number
}

export interface InternalOrderHasItem {
  discountQuantity: number
  id: {
    itemId: number
    orderId: number
  }
  item: Item
  normalQuantity: number
}

export interface User {
  addresses: Address[]
  bundleCart: any[]
  cart: any[]
  creationDate: string
  email: string
  firstName: string
  isAccountValidated: boolean
  isActive: boolean
  isDeleted: boolean
  isResetPassword: boolean
  isSocialLogin: boolean
  lastName: string
  notifications: any[]
  phoneNumber: string
  points: number
  profileImageUrl: string
  roles: string
  sellerProfile: any
  wishlist: any[]
}

export interface InventoryRequestContent {
  creationDate: string
  destinationVendor: Vendor
  id: number
  internalOrderCode: string
  internalOrderHasItems: InternalOrderHasItem[]
  originVendor: Vendor
  receivedDate: string
  status: string
  transitDate: string
  user: User
}

export interface InventoryRequestsData {
  content: InventoryRequestContent[]
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  pageable: any
  size: number
  sort: any
  totalElements: number
  totalPages: number
}

export interface InventoryRequestsResponse {
  data: InventoryRequestsData
  message: string
  status: string
  status_code: number
}
/**
 *****************************************
 *********** Interfaces Types ************
 *****************************************
 */

export interface InventoryRequestLoading {
  type: typeof INVENTORY_REQUESTS_LOADING
  payload: string
}

export interface InventoryRequestFail {
  type: typeof INVENTORY_REQUESTS_FAIL
  payload: string
}
export interface InventoryRequestListType {
  type: typeof INVENTORY_REQUESTS_LIST
  payload: InventoryRequestsData
}

export interface InventoryRequestUpdate {
  type: typeof UPDATE_INVENTORY_REQUESTS
  payload: string
}

export interface InventoryRequestSetFilter {
  type: typeof INVENTORY_REQUESTS_SET_FILTER
  payload: object
}

export interface InventoryRequestRefresh {
  type: typeof INVENTORY_REQUESTS_REFRESH
  payload: boolean
}
// Export types.
export type InventoryRequestsDispatchTypes =
  | InventoryRequestLoading
  | InventoryRequestFail
  | InventoryRequestListType
  | InventoryRequestUpdate
  | InventoryRequestRefresh
  | InventoryRequestSetFilter
