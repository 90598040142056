// React.
import React, { useState, useEffect } from 'react';

// Material UI.
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Components.
import ItemCard from './ItemCard';
import ItemSingleCard from './ItemSingleCard';
import Pagination from '../../admin/utils/ResultsPagination';

// Redux
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import { getItemSearchData, refreshItemView } from "../../../actions/itemActions/ItemActions";

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './ItemsListStyles';
import scrollToTop from '../../utils/scrollToTop';
import SelectPageSize from '../../utils/SelectPageSize';
import { getProjectConfig } from '../../../getProjectConfig';
import CheckIsStockPositive from '../../utils/CheckIsStockPositive';

// Types interfase.
interface ItemsListProps {
  vendorId?: number;
};

const ItemsList = ({ vendorId }: ItemsListProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation('global');
  const params = new URLSearchParams(window.location.search);

  // Redux state.
  const itemProductState = useSelector((state: RootStore) => state.item);
  const { itemListSearchData, refresh, itemFilters: filters, loading } = itemProductState;
  const navState = useSelector((state: RootStore) => state.nav);
  const { navFilters, navLoading } = navState;

  // Local component state.
  const [currentPage, setCurrentPage] = useState<number>(1);
  const url = window.location.href;

  // Removes text within parentheses.
  const removeTextWithinParentheses = (text: string) => {
    let result = text.replace(/ *\([^)]*\) */g, "");
    return result.trim();
  };

  useEffect(() => {
    if (!loading && !navLoading && navFilters !== undefined) {
      var sort = params.get('sort');
      if (!sort) {
        sort = 'id,desc';
      }
      let page = params.get('page');
      let isStockPositive = localStorage.getItem('isStockPositive');
      let key = params.get('key');
      let itemTypeIdFromUrl = params.get('itemTypeId');
      var labelValueIds = params.get('labelValueId');

      // Add the extra nav label filters.
      if (labelValueIds) {
        if (navFilters.labelValuesIds) {
          labelValueIds += ',' + navFilters.labelValuesIds
        }
      } else {
        labelValueIds = navFilters.labelValuesIds
      }

      const pNumber = Number(page);

      const qParams = {
        ...filters,
        page: (page && page !== null) ? pNumber - 1 : 0,
        size: localStorage.getItem('pageSize') || getProjectConfig().PAGE_SIZES[0],
        paged: true,
        isUniquePerCode: true,
        isStockPositive,
        ...(sort !== '') && { sort: sort },
        ...(key) && { name: removeTextWithinParentheses(key) },
        ...(itemTypeIdFromUrl) && { itemtypeIds: itemTypeIdFromUrl }, // Reads the featured cards filter.
        ...(navFilters.itemTypeIds) && { itemtypeIds: navFilters.itemTypeIds }, // Reads the main nav filters.
        ...(labelValueIds !== '') && { labelvaluesIds: labelValueIds },
        ...(navFilters.onlyDiscount) && { isDiscount: true },
        ...((navFilters.category !== undefined) && !key && !itemTypeIdFromUrl) && { category: navFilters.category },
        ...(vendorId !== undefined) && { vendorId: vendorId },
      };

      dispatch(getItemSearchData(qParams));

      setCurrentPage((page && page !== null) ? pNumber : 1);
      scrollToTop();

      dispatch(refreshItemView(false));
    }

    // eslint-disable-next-line
  }, [url, refresh, navFilters]);

  return (
    <div className={classes.root}>
      {
        !loading ? (<div className={classes.stockAndSizeFilters}>
          <CheckIsStockPositive/>
          <SelectPageSize/>
        </div>) : null
      }
      <Grid container spacing={3}>
        {
          (itemListSearchData?.items !== undefined && (!loading || loading === 'ITEM_NAMES')) && 
          (itemListSearchData?.items.map((item, index) => 
            <Grid key={index} item xs={12} md={12} lg={6} className={classes.grid}>
              {(item.category === 'Single') ?
                <ItemSingleCard item={item} vendorId={vendorId}/> :
                <ItemCard item={item} />
              }
            </Grid>
          ))
        }
        { itemListSearchData?.items.length === 0
          ? <Typography className={ classes.noResults }>{ t('items.no-items') }</Typography>
          : null
        }
        {
          itemListSearchData?.totalPages !== undefined
          && itemListSearchData?.totalPages !== 1
          && itemListSearchData?.items.length !== 0
          && !loading
            ? (<Pagination current={currentPage} path={window.location.pathname} pagesNumber={ itemListSearchData!.totalPages }/>)
            : null
        }
      </Grid>
    </div>
  )
}

export default ItemsList;
