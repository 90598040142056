import {Dispatch} from "redux";
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { BUNDLE_LOADING, BUNDLE_FAIL, ADD_BUNDLE, BUNDLE_LIST, BUNDLE_REFRESH, BUNDLE_LIST_UNIQUE, 
         BUNDLE_SET_FILTER, BUNDLE_PUT, DELETE_BUNDLE, AddBundleRequestObject, BundleActionsTypes } from "./BundleActionsTypes";

const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;

/**
 * Update the refresh view state.
 * @param {boolean} value
 */
 export const refreshBundleView = (value: boolean) => async (dispatch: Dispatch<BundleActionsTypes>) => {
  dispatch({
    type: BUNDLE_REFRESH,
    payload: value
  })
};

/**
 * Update the filters view state.
 * @param {object} filters
 */
export const bundleSetFilters = (filters: object) => async (dispatch: Dispatch<BundleActionsTypes>) => {
  dispatch({
    type: BUNDLE_SET_FILTER,
    payload: filters
  })
};

/**
 * Add  new bundle to the DB.
 * @param {AddBundleRequestObject} bundle
 */
 export const addBundle = (bundle: AddBundleRequestObject) => async (dispatch: Dispatch<BundleActionsTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: BUNDLE_LOADING,
        payload: 'ADD_ITEM'
      })

      var bodyFormData = new FormData();
      bundle.name && bodyFormData.append('name', bundle.name);
      bundle.description && bodyFormData.append('description', bundle.description);
      bundle.price && bodyFormData.append('price', String(bundle.price));
      bodyFormData.append('vendorId', String(bundle.vendorId));

      // Add the image files.
      if (bundle.files) {
        for (var i = 0; i < bundle.files.length; i++) {
          bodyFormData.append('files', bundle.files[i].file, String(i));
        }
      }

      // Add the items.
      if (bundle.bundleItems) {
        for (var j = 0; j < bundle.bundleItems.length; j++) {
          bodyFormData.append(`bundleItems[${j}].itemId`, bundle.bundleItems[j].itemId);
          bodyFormData.append(`bundleItems[${j}].quantity`, bundle.bundleItems[j].quantity);
        } 
      }

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
        }
      };
    
      const addRes = await axios.post(`${getBasePath(['admin'])}/registerBundle`, bodyFormData, configPUT);

      if (addRes.status === 200) {
        dispatch({
          type: ADD_BUNDLE,
          payload: 'status.success-create',
          bundle: addRes.data.data
        })
      }
       
    } catch(e) {
      console.log(e);
      dispatch({
        type: BUNDLE_FAIL,
        payload: 'status.create-error'
      })
    }
  } else {
    dispatch({
      type: BUNDLE_FAIL,
      payload: 'status.create-error'
    })
  }
};

/**
 * Update a bundle on the BE.
 * @param {AddBundleRequestObject} bundle
 * @param {string} imagesIdToRemove
 */
 export const updateBundle = (bundle: AddBundleRequestObject, imagesIdToRemove:string) => async (dispatch: Dispatch<BundleActionsTypes>) => {
  try {
    dispatch({
      type: BUNDLE_LOADING,
      payload: 'BUNDLE_UPDATE'
    })

    var bodyFormData = new FormData();
    bundle.name && bodyFormData.append('bundleCode', `${bundle.bundleCode}`);
    bundle.name && bodyFormData.append('name', bundle.name);
    bundle.description && bodyFormData.append('description', bundle.description);
    bundle.price && bodyFormData.append('price', String(bundle.price));
    bodyFormData.append('imagesIdToRemove', imagesIdToRemove);
    bodyFormData.append('isPublished', String(bundle.isPublished));

    // Add the image files.
    if (bundle.files) {
      for (var i = 0; i < bundle.files.length; i++) {
        bodyFormData.append('files', bundle.files[i].file, String(i));
      }
    }
  
    const configPUT = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
      }
    };
  
    const res = await axios.put(`${ getBasePath(['admin']) }/updateBundle`, bodyFormData, configPUT);
    if (res.status === 200) {
      dispatch({
        type: BUNDLE_PUT,
        payload: {message: 'status.success-update', bundle: res.data.data[0]}
      })
    } else {
      dispatch({
        type: BUNDLE_FAIL,
        payload: 'status.error-update'
      })
    }

  } catch(e) {
    dispatch({
      type: BUNDLE_FAIL,
      payload: 'status.error-update'
    })
  }
};

/**
 * Update a bundle status on the BE.
 * @param {string} published
 * @param {string} bundleCode 
 */
 export const updateBundlePublished = (published: string, bundleCode: string) => async (dispatch: Dispatch<BundleActionsTypes>) => {
  try {
    dispatch({
      type: BUNDLE_LOADING,
      payload: 'BUNDLE_UPDATE'
    })

    var bodyFormData = new FormData();
    bodyFormData.append('bundleCode', bundleCode);
    bodyFormData.append('isPublished', published);
  
    const configPUT = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
      }
    };
  
    const res = await axios.put(`${ getBasePath(['admin']) }/updateBundle`, bodyFormData, configPUT);
    if (res.status === 200) {
      dispatch({
        type: BUNDLE_PUT,
        payload: {message: 'status.success-update', bundle: res.data.data[0]}
      })
    } else {
      dispatch({
        type: BUNDLE_FAIL,
        payload: 'status.error-update'
      })
    }

  } catch(e) {
    dispatch({
      type: BUNDLE_FAIL,
      payload: 'status.error-update'
    })
  }
};


/**
 * Get a list of bundles from the BE, it can be filtered.
 * @param {object|null} qParams
 * @param {string} role
 * @param {boolean} isUnique
 */
 export const getBundles = (qParams: object|null, role: string = 'seller', isUnique:boolean = false) => async (dispatch: Dispatch<BundleActionsTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: BUNDLE_LOADING,
        payload: 'LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      const res = await axios.get(`${getBasePath([role])}/getAllBundles`, {
        params: qParams,
        headers: headers
       });

       if (res.status === 200) {
          const queryData = res.data.data.content;
          const bundles:any = [];

        for(var i = 0; i < queryData.length; i++) {
          const bundle = queryData[i];
        
          if (!bundle.isDeleted) {
            bundles.push({
              id: bundle.id,
              bundleCode: bundle.bundleCode,
              creationDate: bundle.creationDate,
              currency: bundle.currency,
              currentTax: bundle.currentTax,
              description: bundle.description,
              images: bundle.images,
              isDeleted: bundle.isDeleted,
              isPublished: bundle.isPublished,
              items: bundle.items,
              name: bundle.name,
              price: bundle.price,
              priceWithTaxes: bundle.priceWithTaxes,
              totalQuantity: bundle.totalQuantity,
              vendor: bundle.vendor,
            });
          }
        }

        const data:any = {
          first: res.data.data.first,
          last: res.data.data.last,
          number: res.data.data.number,
          size: res.data.data.size,
          totalPages: res.data.data.totalPages,
          bundles: bundles
        };

        if (isUnique) {
          dispatch({
            type: BUNDLE_LIST_UNIQUE,
            payload: data
          })
        } else {
          dispatch({
            type: BUNDLE_LIST,
            payload: data
          })
        }
       } else {
        dispatch({
          type: BUNDLE_FAIL,
          payload: 'status.get-error'
        })
       }
    } catch(e) {
      dispatch({
        type: BUNDLE_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: BUNDLE_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Soft delete a given bundle.
 * @param {string} bundleCode
 */
 export const deleteBundle = (bundleCode: string) => async (dispatch: Dispatch<BundleActionsTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: BUNDLE_LOADING,
        payload: 'DELETE_BUNDLE'
      })

      const configDELETE = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
    
      const deleteRes = await axios.delete(`${ getBasePath(['admin']) }/deleteBundle/${bundleCode}`, configDELETE);

      if (deleteRes.status === 200) {
        dispatch({
          type: DELETE_BUNDLE,
          payload: 'status.success-delete-bundle'
        })
      }
       
    } catch(e) {
      dispatch({
        type: BUNDLE_FAIL,
        payload: 'status.delete-error-bundle'
      })
    }
  } else {
    dispatch({
      type: BUNDLE_FAIL,
      payload: 'status.delete-error-bundle'
    })
  }
};


/**
 * Returns the base path based on the role.
 */
const getBasePath = (roles:Array<string>) => {
  if (!token) {
    return `${apiURL}/guest`;
  } else {
    const decodedToken:any = jwtDecode(String(token));
    if (decodedToken.auth.includes('ROLE_USER') && roles.includes('user')) {
      return `${apiURL}/user`;
    }
    if (decodedToken.auth.includes('ROLE_SELLER') && roles.includes('seller')) {
      return `${apiURL}/seller`;
    }
    if ((decodedToken.auth.includes('ROLE_ADMIN') || decodedToken.auth.includes('ROLE_SUPER_SELLER')) && !roles.includes('admin') && roles.includes('seller')) {
      return `${apiURL}/seller`;
    }
    if ((decodedToken.auth.includes('ROLE_ADMIN') || decodedToken.auth.includes('ROLE_SUPER_SELLER')) && roles.includes('admin')) {
      return `${apiURL}/admin`;
    }
    return `${apiURL}/user`;
  }
};
