// React.
import { Fragment, useState } from "react"

// Components.
import StatusStepper from "../utils/StatusStepper"

// Material UI.
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { DeleteForever } from "@mui/icons-material"
import EditIcon from "@mui/icons-material/Edit"
// Moment.

// Translations.
import { useTranslation } from "react-i18next"

// Global Types.

// Styles.
import {
  Button,
  ButtonBase,
  IconButton,
  Input,
  Modal,
  Paper,
} from "@material-ui/core"
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn"
import { GridCloseIcon } from "@mui/x-data-grid"
import { useDispatch } from "react-redux"
import {
  refreshDeliveryView,
  refundPackage,
  updatePackage,
} from "../../../actions/deliveryActions/DeliveryActions"
import { DeliveryDetails } from "../../../actions/deliveryActions/DeliveryActionsTypes"
import NumberFormat from "../../admin/utils/NumberFormat"
import TextMaskIdentification from "../../admin/utils/TextMaskIdentification"
import DeliveryCode from "./DeliveryCode"
import useStyles from "./DeliveryRowStyles"

// Component interface.
interface DeliveryEditProps {
  delivery: DeliveryDetails
  isSender: number
}

const DeliveryRow = ({ delivery, isSender }: DeliveryEditProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [t] = useTranslation("global")

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [pickupId, setPickupId] = useState<string>(
    delivery.pickupId && delivery.pickupId !== "null" ? delivery.pickupId : ""
  )
  const [identificationPickUpError, setIdentificationPickUpError] =
    useState<boolean>(false)

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleUpdatePackage = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    if (pickupId !== "" && pickupId !== "null") {
      dispatch(updatePackage(parseInt(delivery.id), pickupId))
      setIsEditing(false)
    } else {
      setIdentificationPickUpError(true)
    }
  }

  const handleRefundPackage = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    dispatch(refundPackage(parseInt(delivery.id)))
    dispatch(refreshDeliveryView(true))
  }

  const handleDeletePickupId = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setPickupId("")
    dispatch(updatePackage(parseInt(delivery.id), ""))
  }

  const handleChangeIdentificationPickUp = (
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    const newValue = String(e.target.value)
    setPickupId(newValue)

    const isIdentificationFormat = /^[0-9\s]+$/.test(newValue)
    if (newValue === "" || !isIdentificationFormat) {
      setIdentificationPickUpError(true)
    } else {
      setIdentificationPickUpError(false)
    }
  }

  const getStepsList = () => {
    if (!delivery.status) return []

    if (delivery.refundStatus === "NA" || !delivery.refundCode) {
      return delivery.status === "Cancelado"
        ? ["Cancelado"]
        : ["Procesando", "En tránsito", "Listo para recoger", "Completado"]
    }

    return ["Retiro pendiente", "Retiro completado"]
  }

  const getStatusIndex = () => {
    const statusList = getStepsList()
    const currentStatus =
      delivery.refundStatus === "NA" ? delivery.status : delivery.refundStatus
    const index = statusList.findIndex((status) => status === currentStatus)

    return index !== -1 ? index : 0
  }

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.orderStatus}>
          <Typography variant="body1" color="primary">
            {t("orders.tracing")}
          </Typography>
          {delivery.status && (
            <StatusStepper
              steps={getStepsList()}
              currentStep={getStatusIndex()}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.moreInfoContainer}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="subtitle1" color="primary">
                {t("deliveries.more-info")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={5} className={classes.detailsContainer}>
              <div>
                <Typography variant="caption" color="textSecondary">
                  {t("deliveries.location-delivery")}:
                </Typography>
                {delivery.locationDeliveryName === null ||
                delivery.locationDeliveryName === "" ? (
                  <Typography>
                    {t("deliveries.no-location-delivery")}
                  </Typography>
                ) : (
                  <Typography>{delivery.locationDeliveryName}</Typography>
                )}
              </div>
              <div>
                <Typography variant="caption" color="textSecondary">
                  {t("deliveries.description")}
                </Typography>
                {delivery.description === null ||
                delivery.description === "" ? (
                  <Typography>{t("deliveries.no-description")}</Typography>
                ) : (
                  <Typography>{delivery.description}</Typography>
                )}
              </div>
              <div>
                <Typography variant="caption" color="textSecondary">
                  {t("deliveries.dimensions")}
                </Typography>
                {delivery.dimensions === null || delivery.dimensions === "" ? (
                  <Typography>{t("deliveries.no-dimensions")}</Typography>
                ) : (
                  <Typography>{delivery.dimensions}</Typography>
                )}
              </div>
              <div>
                <Typography variant="caption" color="textSecondary">
                  {t("deliveries.weight")}
                </Typography>
                {delivery.weight === null ? (
                  <Typography>{t("deliveries.no-weight")}</Typography>
                ) : (
                  <Typography>{delivery.weight}</Typography>
                )}
              </div>
              <div>
                <Typography variant="caption" color="textSecondary">
                  {t("deliveries.created-in")}
                </Typography>
                <Typography>{delivery.locationOriginName}</Typography>
              </div>
              <div>
                <Typography variant="caption" color="textSecondary">
                  {t("deliveries.price")}
                </Typography>
                <Typography>
                  {<NumberFormat number={delivery.price} />}
                </Typography>
              </div>
              {isSender !== 0 && (
                <div>
                  <Typography variant="caption" color="textSecondary">
                    {t("deliveries.pickupId")}
                  </Typography>
                  {isEditing ? (
                    <div className={classes.identificationPickUpContainer}>
                      <Input
                        id="identificationPickUp"
                        name="identificationPickUp"
                        value={pickupId}
                        onChange={handleChangeIdentificationPickUp}
                        inputComponent={TextMaskIdentification as any}
                        error={identificationPickUpError}
                      />
                    </div>
                  ) : (
                    <Typography>
                      {pickupId !== "" ? pickupId : t("deliveries.no-pickupId")}
                    </Typography>
                  )}
                  <div className={classes.editableIcons}>
                    {isEditing ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => handleUpdatePackage(e)}
                        size="small"
                      >
                        {t("players.update")}{" "}
                      </Button>
                    ) : delivery.status !== "Completado" ? (
                      <>
                        <ButtonBase
                          onClick={(e) => {
                            e.preventDefault()
                            setIsEditing(!isEditing)
                          }}
                        >
                          <Typography color="primary">
                            <EditIcon fontSize="large" />
                          </Typography>
                        </ButtonBase>
                        <ButtonBase
                          onClick={(e) => {
                            handleDeletePickupId(e)
                          }}
                        >
                          <Typography color="primary">
                            <DeleteForever fontSize="large" />
                          </Typography>
                        </ButtonBase>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )}
            </Grid>
            {
              // If the user is the sender and the delivery isnt completed, show the return package button.
              isSender === 0 && delivery.status !== "Completado" && delivery.refundStatus === "NA" ? (
                <Grid
                  container
                  item
                  xs={12}
                  md={4}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ marginBottom: "36px" }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    endIcon={<KeyboardReturnIcon fontSize="large" />}
                    onClick={() => handleOpenModal()}
                  >
                    <Typography>{t("deliveries.refund-package")}</Typography>
                  </Button>
                </Grid>
              ) : (
                <></>
              )
            }
            <Grid
              container
              item
              xs={12}
              md={3}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ marginBottom: "36px" }}
            >
              <DeliveryCode codeValue={delivery.id.toString()} />
            </Grid>
          </Grid>
        </Grid>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="Confirm Modal"
        >
          <Paper elevation={3} className={classes.modalPaper}>
            <IconButton
              aria-label="close modal"
              component="span"
              className={classes.closeModal}
              onClick={() => handleCloseModal()}
            >
              <GridCloseIcon />
            </IconButton>
            <Typography>{t("deliveries.refund-confirmation")}</Typography>
            <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleCloseModal()}
            >
              {" "}
              {t("deliveries.cancel")}{" "}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => handleRefundPackage(e)}
            >
              {" "}
              {t("deliveries.confirm")}{" "}
            </Button>
            </div>
          </Paper>
        </Modal>
      </Grid>
    </Fragment>
  )
}

export default DeliveryRow
