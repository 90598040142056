export const getProjectConfig = () => ({
  "LAN": "es",
  "PAGES_SIZE": "30",
  "UNLIMITED_PAGES_SIZE": "1000",
  "PAGE_SIZES": ["10", "30", "50"],
  "MAX_QUANTITY": "30",
  "CURRENCY_SYMBOL": `${process.env.REACT_APP_CURRENCY}`,
  "VENDOR_ID": 1,
  "STORE_CREDITS_NAME": "OMEGA Coins",
  "SD_URL": process.env.REACT_APP_SD_URL ? `${process.env.REACT_APP_SD_URL}` : "/",
  "METATAGS": {
    "HOME": {
      "title": `Tienda en linea | ${process.env.REACT_APP_BRAND}`,
      "description": "Tienda en linea, realice compras de forma segura y rápida.",
      "meta": {
        "charset": "utf-8",
        "name": {
          "keywords": "tienda,enlinea,ventas"
        }
      }
    },
    "ITEMS_SEARCH": {
      "title": `Lista de artículos | ${process.env.REACT_APP_BRAND}`,
      "description": `Lista y busqueda de artículos ${process.env.REACT_APP_BRAND}.`,
      "meta": {
        "charset": "utf-8",
        "name": {
          "keywords": "tienda,enlinea,artículos"
        }
      }
    },    
    "ITEM_DETAIL": {
      "title": `Detalle del artículo | ${process.env.REACT_APP_BRAND}`,
      "description": `Detalle del articulo ${process.env.REACT_APP_BRAND}`,
      "meta": {
        "charset": "utf-8",
        "name": {
          "keywords": "tienda,enlinea,artículo"
        }
      }
    },
    "CART": {
      "title": `Carrito de compras | ${process.env.REACT_APP_BRAND}`,
      "description": "Detalle de los artículos agregados al carrito de compras.",
      "meta": {
        "charset": "utf-8",
        "name": {
          "keywords": "tienda,enlinea,carrito"
        }
      }
    },
    "WISHLIST": {
      "title": `Lista de deseos | ${process.env.REACT_APP_BRAND}`,
    }
  }
});