import { Dispatch } from "redux";
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import {
  Vendor,
  VendorListComplete,
  VendorDispatchTypes,
  VENDOR_FAIL,
  VENDOR_LIST,
  VENDOR_LIST_USER,
  VENDOR_PUT,
  ALL_VENDORS,
  VENDOR_REFRESH,
  VENDOR_SET_FILTER,
  VENDOR_LOADING,
  VENDORS_BY_SELLER,
  AddVendorObject,
  ADD_VENDOR
} from "./VendorActionsTypes";

const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;

/**
 * Update the refresh view state.
 * @param {boolean} value
 */
export const refreshVendorView = (value: boolean) => async (dispatch: Dispatch<VendorDispatchTypes>) => {
  dispatch({
    type: VENDOR_REFRESH,
    payload: value
  })
};

/**
 * Update the filters view state.
 * @param {object} filters
 */
export const vendorSetFilters = (filters: object) => async (dispatch: Dispatch<VendorDispatchTypes>) => {
  dispatch({
    type: VENDOR_SET_FILTER,
    payload: filters
  })
};

/**
 * Get a list of vendors from the BE, it can be filtered.
 */
export const getVendors = (qParams: object | null = null) => async (dispatch: Dispatch<VendorDispatchTypes>) => {
    if (apiURL !== '' && token !== '') {
      try {
        dispatch({
          type: VENDOR_LOADING,
          payload: 'LIST'
        })
  
        const headers: object = {
          ContentType: 'application/json',
          Authorization: 'Bearer ' + token
        }
        const basePath = getBasePath(['admin','user', 'admin-singles', 'seller']); 
        const res = await axios.get(`${basePath}/getAllVendors`, {
          ...(qParams) && { params: qParams },
          headers: headers
         });
  
         if (res.status === 200) {
            const queryData = res.data.data.content;
            const vendors:Vendor[] = [];
  
          for(var i = 0; i < queryData.length; i++) {
            const vendor = queryData[i];

            // Get vendor categories.
            var categories:string[] = [];
            for (var j = 0; j < vendor.vendorCategories.length; j++) {
              categories.push(vendor.vendorCategories[j].id.category);
            }
          
            // Build response object.
            vendors.push({
              id: vendor.id,
              name: vendor.name,
              coordinates: vendor.coordinates,
              phoneNumber: vendor.phoneNumber,
              address: vendor.address,
              warehouses: vendor.warehouses,
              vendorCategories: categories,
              type: vendor.type
            });
          }
  
          const data:any = [
            ...vendors
          ];
  
          dispatch({
            type: VENDOR_LIST,
            payload: data
          })
         } else {
          dispatch({
            type: VENDOR_FAIL,
            payload: 'status.get-error'
          })
         }
      } catch(e) {
        dispatch({
          type: VENDOR_FAIL,
          payload: 'status.get-error'
        })
      }
    } else {
      dispatch({
        type: VENDOR_FAIL,
        payload: 'status.get-error'
      })
    }
  };

/**
 * Get a list of vendors from the BE, it can be filtered.
 */
export const getUserVendors = (qParams: object | null = null) => async (dispatch: Dispatch<VendorDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: VENDOR_LOADING,
        payload: 'LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }
      const basePath = getBasePath(['user']);
      const res = await axios.get(`${basePath}/getAllVendors`, {
        ...(qParams) && { params: qParams },
        headers: headers
      });

      if (res.status === 200) {
        const queryData = res.data.data.content;
        const vendors: Vendor[] = [];

        for (var i = 0; i < queryData.length; i++) {
          const vendor = queryData[i];

          vendors.push({
            id: vendor.id,
            name: vendor.name,
            coordinates: vendor.coordinates,
            phoneNumber: vendor.phoneNumber,
            address: vendor.address,
            warehouses: vendor.warehouses
          });
        }

        const data: any = [
          ...vendors
        ];

        dispatch({
          type: VENDOR_LIST_USER,
          payload: data
        })
      } else {
        dispatch({
          type: VENDOR_FAIL,
          payload: 'status.get-error'
        })
      }
    } catch (e) {
      dispatch({
        type: VENDOR_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: VENDOR_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 *
 * Get all vendors for accordion
 * @param {object|null} qParams
 *
 */
export const getAllVendors = (qParams: object | null) => async (dispatch: Dispatch<VendorDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: VENDOR_LOADING,
        payload: 'LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }
      const decodedToken:any = jwtDecode(String(token));
      const pathRole = decodedToken.auth.includes('ROLE_ADMIN') ? 'admin' : 'seller'; 
      const res = await axios.get(`${apiURL}/${pathRole}/getAllVendors`, {
        params: qParams,
        headers: headers
       });

       if (res.status === 200) {
          const queryData = res.data.data.content;
          const vendors:VendorListComplete[] = [];
        for(var i = 0; i < queryData.length; i++) {
          const vendor = queryData[i];
          vendors.push({
            id: vendor.id,
            name: vendor.name,
            isAdminOnly: vendor.isAdminOnly,
            vendorCode: vendor.vendorCode,
            phoneNumber: vendor.phoneNumber,
            sequence: vendor.sequence,
            vendorCategories: vendor.vendorCategories,
            address: {
              canton: vendor.address.canton,
              district: vendor.address.district,
              province: vendor.address.province,
              fullAddress: vendor.address.fullAddress,
              phoneNumber: vendor.address.phoneNumber,
              postalCode: vendor.address.postalCode
            },
            type: vendor.type
          });
        }

        const data:any = {
          vendors: vendors,
          first: res.data.data.first,
          last: res.data.data.last,
          number: res.data.data.number,
          size: res.data.data.size,
          totalPages: res.data.data.totalPages
       };

        dispatch({
          type: ALL_VENDORS,
          payload: data
        })
       } else {
        dispatch({
          type: VENDOR_FAIL,
          payload: 'status.get-error'
        })
       }
    } catch(e) {
      dispatch({
        type: VENDOR_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: VENDOR_FAIL,
      payload: 'status.get-error'
    })
  }
};
/**
 * add new vendor to the DB.
 * @param {AddVendorObject} vendor
 */
export const addVendor = (vendor: AddVendorObject) => async (dispatch: Dispatch<VendorDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: VENDOR_LOADING,
        payload: 'ADD_ITEM'
      })
      
      const bodyForm = {
        'adminOnly': vendor.adminOnly,
        'name': vendor.name,
        'phoneNumber': vendor.phoneNumber,
        'vendorCode': vendor.vendorCode,
        'address': vendor.address,
        'type': vendor.type,
        'vendorCategories': vendor.vendorCategories
      }
      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };

      const addRes = await axios.post(`${getBasePath(['admin'])}/registerVendor`, bodyForm, configPUT);

      if (addRes.status === 200) {
        dispatch({
          type: ADD_VENDOR,
          payload: 'status.success-create',
          vendor: addRes.data.data
        })
      }

    } catch(e) {
      console.error(e);
      dispatch({
        type: VENDOR_FAIL,
        payload: 'status.create-error'
      })
    }
  } else {
    dispatch({
      type: VENDOR_FAIL,
      payload: 'status.create-error'
    })
  }
};
/**
 *Update vendor
 */
 export const updateVendor = (vendor: VendorListComplete ) => async (dispatch: Dispatch<VendorDispatchTypes>) => {
  try {
    dispatch({
      type: VENDOR_LOADING,
      payload: 'VENDOR_UPDATE'
    })

    const configPUT = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
      }
    };
    const vForm = `?id=${vendor.id}&name=${vendor.name}&isAdminOnly=${vendor.isAdminOnly}&phoneNumber=${vendor.phoneNumber}&canton=${vendor.address.canton}&district=${vendor.address.district}&province=${vendor.address.province}&fullAddress=${vendor.address.fullAddress}&postalCode=${vendor.address.postalCode}&sequenceVendor=${vendor.sequence}&vendorCategoriesToAdd=${vendor.vendorCategoriesToAdd}&vendorCategoriesToDelete=${vendor.vendorCategoriesToDelete}`;
    const res = await axios.put(`${ getBasePath(['admin']) }/updateVendor${vForm}`, { }, configPUT);
    if (res.status === 200) {
      dispatch({
        type: VENDOR_PUT,
        payload: {message: 'status.success-update', vendor: res.data.data[0]}
      })
    } else {
      dispatch({
        type: VENDOR_FAIL,
        payload: 'status.error-update'
      })
    }

  } catch(e) {
    dispatch({
      type: VENDOR_FAIL,
      payload: 'status.error-update'
    })
  }
};

/**
 *
 * Get all vendors bby seller
 *
 */
export const getAllVendorBySeller = () => async (dispatch: Dispatch<VendorDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: VENDOR_LOADING,
        payload: 'VENDOR_BY_SELLER'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      const decodedToken: any = jwtDecode(String(token));
      const pathRole = decodedToken.auth.includes('ROLE_ADMIN_SINGLES') ? 'admin-singles' : 'seller';
      const res = await axios.get(`${apiURL}/${pathRole}/getAllVendorBySeller`, {
        headers: headers
      });

      if (res.status === 200) {
        const queryData = res.data.data;

        dispatch({
          type: VENDORS_BY_SELLER,
          payload: queryData
        })
      } else {
        dispatch({
          type: VENDOR_FAIL,
          payload: 'status.get-error'
        })
      }
    } catch (e) {
      dispatch({
        type: VENDOR_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: VENDOR_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Returns the base path based on the role.
 */
const getBasePath = (roles:Array<string>) => {
  if (!token) {
    return `${apiURL}/guest`;
  } else {
    const decodedToken:any = jwtDecode(String(token));
    if (decodedToken.auth.includes('ROLE_USER') && roles.includes('user')) {
      return `${apiURL}/user`;
    }
    if (decodedToken.auth.includes('ROLE_ADMIN_SINGLES') && roles.includes('admin-singles')) {
      return `${apiURL}/admin-singles`;
    }
    if (decodedToken.auth.includes('ROLE_SELLER') && roles.includes('seller')) {
      return `${apiURL}/seller`;
    }
    if ((decodedToken.auth.includes('ROLE_ADMIN') || decodedToken.auth.includes('ROLE_SUPER_SELLER')) && !roles.includes('admin') && roles.includes('seller')) {
      return `${apiURL}/seller`;
    }
    if ((decodedToken.auth.includes('ROLE_ADMIN') || decodedToken.auth.includes('ROLE_SUPER_SELLER')) && roles.includes('admin')) {
      return `${apiURL}/admin`;
    }
    return `${apiURL}/user`;
  }
};