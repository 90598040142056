// React.
import React, {useEffect, useState, Fragment} from 'react';

// Material UI.
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

// Components.
import NumberFormat from '../../admin/utils/NumberFormat';

// Redux
import { useDispatch } from "react-redux";
import { addItemToCart, deleteItemFromCart, deleteItemToCartLocal, addItemToCartLocal } from "../../../actions/cartActions/CartActions";

// Styles.
import useStyles from './ItemCartStyles';
import defaultImage from '../../../images/default-img.jpg'; 

// Translations.
import { useTranslation } from 'react-i18next';

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

interface ItemCartProps {
  cartItem: any;
  customReference: string;
};
const ItemCart = ({ cartItem, customReference } : ItemCartProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();

  // Validate if the item is still available.
  useEffect(() => {
    if (getAllWarehousesQuantity() < 1) {
      deleteCartItem(cartItem.item.id, '0', customReference);
    }

  // eslint-disable-next-line
  }, [])

  const getMaxDiscountQty = () => {
    if (localStorage.token || cartItem.isFull) {
      const dis = cartItem.item.warehousesQuantities.find((wh: any) => wh.warehouse.isDiscount === true);
      return dis !== undefined ? Number(dis.quantity) : 0;
    } else {
      const dis = cartItem.item.itemQuantityData.find((wh: any) => wh.typeOfWarehouse === 'Discount');
      return dis !== undefined ? Number(dis.quantity) : 0;
    }
  };

  const isItemWithDiscount = () => {
    if (localStorage.token || cartItem.isFull) {
      const discount = cartItem.item.discount !== undefined ? cartItem.item.discount : cartItem.item.discounts;
      if (discount !== null && discount !== undefined) {
        const discountQty = getMaxDiscountQty();
        if (discountQty > 0 && discount.pricePercentage > 0) {
          return true;
        }
      }
    } else {
      let itemQuantityData = cartItem.item.itemQuantityData;
      let hasDiscount = itemQuantityData.filter((vendorRow: any) => vendorRow.typeOfWarehouse === 'Discount');
      if (hasDiscount.length > 0) return true;
    }
    return false;
  };

  const getQuantityWithDiscount = () => {
    const maxQty = getMaxDiscountQty();
    if (cartItem.quantity > maxQty) return maxQty;
    return cartItem.quantity;
  };

  const getNormalQuantity = () => {
    if (isItemWithDiscount()) {
      const maxQty = getMaxDiscountQty();
      if (cartItem.quantity > maxQty) {
        return cartItem.quantity - maxQty;
      } else {
        return 0;
      }
    }
    return cartItem.quantity;
  };

  const [quantity, setQuantity] = useState(getNormalQuantity());
  const [discountQuantity, setDiscountQuantity] = useState(getQuantityWithDiscount());
  const [deleteQuantity, setDeleteQuantity] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setDiscountQuantity(getQuantityWithDiscount());
    setQuantity(getNormalQuantity());

  // eslint-disable-next-line
  }, [cartItem])

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = (deleteQty:number) => {
    setOpenModal(true);
    setDeleteQuantity(deleteQty);
  }
  
  const handleChangeSelect = (event: React.ChangeEvent<{ value: unknown }>, item: any) => {
    let value = event.target.value as string;
    setQuantity(value);

    // Update cart on BE.
    let totalQty = Number(value) + discountQuantity;
    updateCartItem(item, totalQty);
  };

  const handleChangeSelectDiscount = (event: React.ChangeEvent<{ value: unknown }>, item: any) => {
    let value = event.target.value as string;
    setDiscountQuantity(value);

    // Update cart on BE.
    let totalQty = Number(value) + quantity;
    updateCartItem(item, totalQty);
  };

  const updateCartItem = (item: any, qty: number) => {
    let newQty = cartItem.quantity - qty;
    if (localStorage.token) {
      // If user is logged.
      if(newQty < 0) {
        newQty = qty - cartItem.quantity;
        dispatch(addItemToCart(item.id, String(newQty)));
      } else {
        dispatch(deleteItemFromCart(item.id, String(newQty), customReference));
      }
    } else {
      // If user is anonimous.
      var cart = localStorage.anonymousCart !== undefined ? JSON.parse(localStorage.anonymousCart) : [];
      // validate if already exists.
      const index = cart.findIndex((obj: any) => obj.item.itemId === item.itemId);
      if (index !== -1) {
        cart[index].quantity = qty;
      } else {
        cart.push({ item: item, quantity: qty, isFull: cartItem.isFull });
      }
      localStorage.removeItem('anonymousCart');
      localStorage.setItem('anonymousCart', JSON.stringify(cart));
      dispatch(addItemToCartLocal());
    }
  };

  const deleteCartItem = (itemId: string, quantity: string, customReference: string) => {
    if (localStorage.token) {
      // If user is logged.
      dispatch(deleteItemFromCart(itemId, quantity, customReference));
    } else {
      // If user is anonimous.
      var cart = localStorage.anonymousCart !== undefined ? JSON.parse(localStorage.anonymousCart) : [];
      const index = cart.findIndex((obj: any) => obj.item.itemId === itemId);
      const currentQty = cart[index].quantity;
      const diff = currentQty - Number(quantity);
      if (diff < 1) {
        cart = cart.filter((item: any) => item.item.itemId !== itemId);
      } else {
        cart[index].quantity = diff;
      }
      localStorage.removeItem('anonymousCart');
      localStorage.setItem('anonymousCart', JSON.stringify(cart));
      dispatch(deleteItemToCartLocal());
    }
    handleCloseModal();
  };

  // Gets the total quantity from all vendor warehouses.
  const getAllWarehousesQuantity = () => {
    if (cartItem.isFull) {
      const warehousesQuantities = cartItem.item.warehousesQuantities
      var result = 0;
      warehousesQuantities.forEach((whQuantity: any) => {
        result = result + Number(whQuantity.quantity);
      });
      return result;
    }
    return cartItem.item.totalQuantity;
  };

  // Quantities to render.
  const items:any = [];
  const itemsWithDiscount:any = [];
  let totalDiscount = getMaxDiscountQty();
  let totalQty = (getAllWarehousesQuantity() - totalDiscount);
  for(var i = 1; i <= totalQty; i++) {
    items.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
  }
  for(var j = 1; j <= totalDiscount; j++) {
    itemsWithDiscount.push(<MenuItem key={j} value={j}>{j}</MenuItem>)
  }

  const getPriceInfoAnonimous = (item: any, hasDiscount:boolean = false) => {
    if (cartItem.isFull) return getPriceInfo(item);
    let itemQuantityData = cartItem.item.itemQuantityData;
    if (hasDiscount) {
      let discountRow = itemQuantityData.filter((vendorRow: any) => vendorRow.typeOfWarehouse === 'Discount');
      return (
        <>
          <Typography color="secondary" className={classes.lineThrough}>{getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={discountRow[0].finalPrice} />}</Typography>
          <Typography color="primary">{getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={discountRow[0].discountFinalPrice} />}</Typography>
        </>
      );
    }
    return (
      <Typography color="primary">{getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={itemQuantityData[0].finalPrice} />}</Typography>
    );
  };

  const getPriceInfo = (item: any) => {
    const discontObj = item.discount !== undefined ? item.discount : item.discounts;
    if (discontObj !== null && discontObj !== undefined) {
      const discount = discontObj.pricePercentage;
      const savings = Number(((discount / 100) * item.priceWithTaxes).toFixed(2));
      const newPrice = (item.priceWithTaxes - savings);
      return <div className={classes.priceWrapper}>
        <Typography color="primary">{getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={newPrice} />}&nbsp;</Typography>
        <Typography color="secondary" className={classes.lineThrough}>{getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={item.priceWithTaxes} />}</Typography>
      </div>;
    } else {
      return <Typography color="primary">{getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={item.priceWithTaxes} />}</Typography>;
    }
  };

  const getTotalPerRowAnonimous = (quantity:number, hasDiscount:boolean = false) => {
    if (cartItem.isFull) return getTotalPerRow(quantity, hasDiscount);
    let itemQuantityData = cartItem.item.itemQuantityData;
    if (hasDiscount) {
      let discountRow = itemQuantityData.filter((vendorRow: any) => vendorRow.typeOfWarehouse === 'Discount');
      if (discountRow.length > 0) return discountRow[0].discountFinalPrice * quantity;
      return 0;
    } else {
      return itemQuantityData[0].finalPrice * quantity;
    }
  };

  const getTotalPerRow = (quantity: number, hasDiscount: boolean = false) => {
    if (hasDiscount) {
      const discountObj = cartItem.item.discount !== undefined ? cartItem.item.discount : cartItem.item.discounts;
      const discount = discountObj.pricePercentage;
      const savings = Number(((discount / 100) * cartItem.item.priceWithTaxes).toFixed(2));
      return (cartItem.item.priceWithTaxes - savings) * quantity;
    } else {
      return cartItem.item.priceWithTaxes * quantity;
    }
  };

  const getItemImage = () => {
    if (cartItem.item.images === undefined || cartItem.item.images[0] === undefined) {
      return defaultImage
    }
    return cartItem.item.images![0].url;
  };

  return (
    <Fragment>
      {isItemWithDiscount() && 
        <Card className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={2} md={2} className={classes.colImg}>
              <Avatar variant="rounded" alt="" src={cartItem.item.images[0] !== undefined ? cartItem.item.images[0].url : defaultImage}/>
            </Grid>
            <Grid item xs={10} sm={8} md={8} className={classes.customPadding}>
              <Typography variant="caption" color="textSecondary">{(localStorage.token || cartItem.isFull) ? cartItem.item.name : cartItem.itemName }</Typography>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={3}>
                  <FormControl fullWidth>
                    <InputLabel id="select-label">{ t('items.quantity') }</InputLabel>
                    <Select
                      labelId="select-label"
                      id="quantity-select"
                      value={ discountQuantity }
                      label={ t('items.quantity') }
                      onChange={ (e) => handleChangeSelectDiscount(e, cartItem.item) }
                      className={ classes.select }
                    >
                      { itemsWithDiscount }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Typography variant="caption" color="textSecondary">{ t('items.seller') }</Typography>
                  <div>{ cartItem.item.name }</div>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography variant="caption" color="textSecondary">{ t('cart.init-price') }</Typography>
                  <div>{(localStorage.token) ? getPriceInfo(cartItem.item) : getPriceInfoAnonimous(cartItem.item, true)}</div>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Typography variant="caption" color="textSecondary">Total</Typography>
                  <Typography color="primary">
                    {getProjectConfig().CURRENCY_SYMBOL}
                    <NumberFormat number={ 
                      (localStorage.token) ? getTotalPerRow(discountQuantity, true) : getTotalPerRowAnonimous(discountQuantity, true)
                    }/>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={2} className={classes.colButton}>
              <IconButton color="primary" aria-label="Delete item from cart" component="span" onClick={ () => handleOpenModal(discountQuantity) }>
                <DeleteIcon color="secondary"/>
              </IconButton>
            </Grid>
          </Grid>
        </Card>
      }
      {quantity > 0 &&
        <Card className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={2} md={2} className={classes.colImg}>
              <Avatar variant="rounded" alt="" src={getItemImage()}/>
            </Grid>
            <Grid item xs={10} sm={8} md={8} className={classes.customPadding}>
              <Typography variant="caption" color="textSecondary">{(localStorage.token || cartItem.isFull) ? cartItem.item.name : cartItem.itemName}</Typography>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={3}>
                  <FormControl fullWidth>
                    <InputLabel id="select-label">{ t('items.quantity') }</InputLabel>
                    <Select
                      labelId="select-label"
                      id="quantity-select"
                      value={ quantity }
                      label={ t('items.quantity') }
                      onChange={ (e) => handleChangeSelect(e, cartItem.item) }
                      className={ classes.select }
                    >
                      { items }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} md={4}>
                  <Typography variant="caption" color="textSecondary">{ t('items.seller') }</Typography>
                  <div>{(localStorage.token) ? cartItem.item.vendor.name : cartItem.item.name}</div>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <Typography variant="caption" color="textSecondary">{ t('cart.init-price') }</Typography>
                  <div>
                    <Typography color="primary" className={classes.priceCol}>
                      <div>{(localStorage.token) ? getPriceInfo(cartItem.item) : getPriceInfoAnonimous(cartItem.item)}</div>
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Typography variant="caption" color="textSecondary">Total</Typography>
                  <Typography color="primary" className={classes.priceCol}>
                    {getProjectConfig().CURRENCY_SYMBOL}
                    <NumberFormat number={
                        (localStorage.token) ? getTotalPerRow(quantity, false) : getTotalPerRowAnonimous(quantity, false)
                      } />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={2} className={classes.colButton}>
              <IconButton color="primary" aria-label="Delete item from cart" component="span" onClick={ () => handleOpenModal(quantity) }>
                <DeleteIcon color="secondary"/>
              </IconButton>
            </Grid>
          </Grid>
        </Card>
      }
      <Modal
        open={ openModal }
        onClose={ handleCloseModal }
        aria-labelledby="Confirm Modal"
        >
        <Paper elevation={3} className={ classes.modalPaper }>
          <IconButton className={ classes.closeModal } aria-label="close modal" component="span" onClick={ () => handleCloseModal() }>
            <CloseIcon/>
          </IconButton>
          <Typography>
            {t('cart.delete-confirm-text', { 'itemName': (localStorage.token) ? cartItem.item.name : cartItem.itemName }) }
          </Typography>
          <Button variant="contained" color="primary" onClick={ () => handleCloseModal() }> { t('cart.no') } </Button>
          <Button variant="contained" color="secondary" 
            onClick={() => deleteCartItem(((localStorage.token) ? cartItem.item.id : cartItem.item.itemId), String(deleteQuantity), customReference) }
            > 
            { t('cart.yes') } 
          </Button>
        </Paper>
      </Modal>
  </Fragment>
  )
}

export default ItemCart;
