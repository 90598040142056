// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(2),
      '& > *': {
        margin: theme.spacing(1),
        padding: theme.spacing(4, 4),
        width: '100%',
        height: 'auto',
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(4, 2),
        },
      },
    },
    sectionTitle: {
      flexGrow: 1,
    },
    boxTop: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      '& > *': {
        marginRight: theme.spacing(2),
      },
    },
    loading: {
      textAlign: 'center',
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      '& .MuiCircularProgress-root': {
        width: '30px !important',
        height: '30px !important',
        verticalAlign: 'bottom'
      }
    }
  }),
);

export default useStyles;
