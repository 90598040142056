// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileWrapper: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      '& a': {
        cursor: 'pointer'
      },
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1)
      }
    },
    marginBottom: {
      marginBottom: theme.spacing(2)
    },
    filesIcon: {
      fontSize: '22px'
    }
  }),
);

export default useStyles;
