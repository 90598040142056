// Material UI
import { withStyles, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& .MuiTextField-root': {
        width: '40px'
      },
      '& .MuiTableCell-head': {
        fontWeight: 'bold'
      },
      '& .MuiTableCell-root': {
        [theme.breakpoints.down('xs')]: {
          padding: '4px 0px 4px 8px'
        },
      },
      '& .carousel-slider': {
        '& svg': {
          position: 'absolute',
          zIndex: 2,
          backgroundColor: 'transparent !important',
          opacity: '1 !important',
          top: 'calc(50% - 28px) !important',
          [theme.breakpoints.down('xs')]: {
            top: 'calc(30%) !important',
          },
        },
      },
      '& .left-arrow': {
        right: '20px',
        '& :hover': {
          cursor: 'pointer'
        },
        [theme.breakpoints.down('xs')]: {
          right: '10px',
        },
      },
      '& .right-arrow': {
        left: '20px',
        '& :hover': {
          cursor: 'pointer'
        },
        [theme.breakpoints.down('xs')]: {
          left: '10px',
        },
      },
    },
    mainTitle: {
      fontSize: '1.5rem'
    },
    labelTable: {
      '& .MuiTableRow-root': {
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)'
      },
      '& .MuiTableCell-root': {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
      }
    },
    cardTop: {
      minHeight: '100px',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
    },
    cardLeft: {
      width: '45%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        padding: theme.spacing(1, 0),
      },
      '& img': {
        width: '100%',
        height: 'auto',
        maxHeight: '400px',
        objectFit: 'contain',
        borderRadius: '4px',
        paddingTop: '6px',
        [theme.breakpoints.down('sm')]: {
          maxHeight: '250px',
        },
        [theme.breakpoints.down('xs')]: {
          maxHeight: '290px',
        },
      }
    },
    cardRight: {
      width: '55%',
      padding: theme.spacing(0, 2, 0, 2),
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        padding: theme.spacing(1, 0),
      }
    },
    cardBottom: {
      width: '100%',
      marginTop: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(0),
      },
      '& .variationsTable': {
        padding: '12px 32px !important',
        [theme.breakpoints.down('xs')]: {
          padding: '12px 5px !important',
        }
      }
    },
    cardImages: {
      width: '100%',
    },
    cardInfo: {
      width: '100%'
    },
    lineThrough: {
      textDecoration: 'line-through'
    },
    marginBottom: {
      marginBottom: theme.spacing(4)
    },
    vendorInfo: {
      padding: theme.spacing(1, 2),
      backgroundColor: theme.palette.grey[100],
      marginTop: theme.spacing(1),
      fontFamily: 'VisbyCF-Bold'
    },
    noStockMsg: {
      margin: theme.spacing(2, 0) 
    },
    filters: {
      borderRadius: '6px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      padding: theme.spacing(1),
      width: '70%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      '& .MuiFormControl-root': {
        minWidth: '112px',
        width: '30%',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        }
      },
      '& .MuiSelect-root': {
        height: '32px'
      },
      '& .MuiFormLabel-root': {
        marginTop: '5px'
      }
    },
    quantityWrapper: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiFormControl-root': {
        width: '100%',
        '& .MuiInputBase-root:before': {
          display: 'none'
        }
      },
      '& .totalQty': {
        backgroundColor: theme.palette.grey[100],
        padding: '6px 9px',
        width: '50%',
      }
    },
    chip: {
      marginRight: '5px',
      marginBottom: '2px',
      fontFamily: 'VisbyCF-Bold',
      width: 'fit-content',
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
        flexWrap: 'wrap',
        background: 'none',
        display: 'flex',
        justifyContent: 'flex-start',
        height: 'auto'
      },
      '& .MuiAvatar-root': {
        width: 'auto',
        height: '16px',
        padding: '0 5px',
        fontSize: '11px',
        borderRadius: '15px',
        fontFamily: 'VisbyCF-Bold',
        color: 'black',
        [theme.breakpoints.down('sm')]: {
          fontSize: '10px',
          marginBottom: '2px',
          marginLeft: 0,
          marginRight: '2px',
        }
      },
      '& .MuiChip-label': {
        [theme.breakpoints.down('sm')]: {
          width: 'auto',
          height: '16px',
          padding: '0 5px',
          fontSize: '11px',
          borderRadius: '15px',
          fontFamily: 'VisbyCF-Bold',
          color: 'black',
          backgroundColor: theme.palette.grey[300],
          marginBottom: '2px'
        }
      },
      '&.MuiChip-outlined': {
        [theme.breakpoints.down('sm')]: {
          border: 0
        }
      }
    },
    customTable: {
      '& .header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        padding: '4px 4px',
        backgroundColor: theme.palette.grey[600],
        color: 'white',
        fontFamily: 'VisbyCF-Bold',
        borderRadius: '6px',
        textAlign: 'center'
      },
      '& .row': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        padding: '4px 4px',
        textAlign: 'center'
      },
      '& .cell': {
        '&.d30': {
          width: '30%'
        },
        '&.d28': {
          width: '28%'
        },
        '&.d20': {
          width: '20%'
        },
        '&.d12': {
          width: '12%',
        },
        '&.d10': {
          width: '10%',
        },
        '&.d100': {
          width: '100%'
        },
        '&.right': {
          textAlign: 'right'
        },
        '&.priceCol': {
          '& .MuiTypography-root': {
            display: 'inline-block',
            marginRight: theme.spacing(1)
          }
        },
        [theme.breakpoints.down('sm')]: {
          '&.m20': {
            width: '20%'
          },
          '&.m25': {
            width: '25%'
          },
          '&.d10 .MuiButtonBase-root': {
            padding: '5px',
          },
          '&.small p': {
            fontSize: '14px'
          }
        }
      }
    },
    menuPaper: {
      height: '232px'
    },
    cardTitle: {
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    filterRoot: {
      padding: '8px 32px !important',
      marginBottom: '0 !important',
      '& .MuiPaper-root': {
        [theme.breakpoints.down('xs')]: {
          marginBottom: '0 !important'
        }
      },
      '& .MuiIconButton-edgeEnd': {
        marginRight: 0,
        marginLeft: '-16px'
      },
      '& .MuiAccordionSummary-content': {
        margin: 0
      },
      '& h3': {
        marginBottom: '3px'
      }
    },
    labelsWrapper: {
      maxWidth: '300px',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      }
    },
    itemLabels: {
      width: '100%',
      textAlign: 'center',
      '& .label-row': {
        marginBottom: theme.spacing(1),
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: '6px'
      },
      '& .label-key': {
        padding: '0 5px',
        fontFamily: 'VisbyCF-Bold',
        backgroundColor: theme.palette.grey[600],
        color: 'white',
        borderRadius: '6px'
      },
      '& .label-value': {
        padding: '0 5px',
      }
    },
    inputQty: {
      border: `1px solid ${theme.palette.grey[300]}`,
      padding: '0 3px',
      width: '58px',
      margin: '0 auto',
      [theme.breakpoints.down('xs')]: {
        width: '38px',
      },
      '& .MuiSelect-icon': {
        right: '-3px'
      },
      '& .MuiSelect-select': {
        paddingRight: '8px'
      }
    },
    wishlistWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

export const Accordion = withStyles({
  root: {
    padding: '0 !important',
    margin: '0',
    boxShadow: 'none',
    '& > *': {
      flexDirection: 'row-reverse',
    }
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    padding: '0'
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
  },
}))(MuiAccordionDetails);
