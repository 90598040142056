import { Dispatch } from "redux";
import axios from "axios";

import {
  CATEGORY_LIST,
  CATEGORY_LOADING,
  CATEGORY_FAIL,
  SET_LOADING,
  SET_LIST,
  SET_FAIL,
} from "./TemplateActionsTypes";
import {
  BannerActionsTypes,
  GET_XLSX_FILE_DATA,
  SETTINGS_FAIL,
  SETTINGS_LOADING,
} from "../settingsActions/SettingsActionsTypes";

const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;

export const getCategoriesTCG = () => async (dispatch: Dispatch) => {
  if (apiURL !== "" && token !== "") {
    try {
      dispatch({
        type: CATEGORY_LOADING,
      });

      const { data } = await axios.get(`${apiURL}/admin/getAllTCGItemTypes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: CATEGORY_LIST,
        payload: {
          categories: data.data,
        },
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: CATEGORY_FAIL,
        payload: "Error occurred while fetching categories.",
      });
    }
  } else {
    dispatch({
      type: CATEGORY_FAIL,
      payload: "ERROR",
    });
  }
};

export const getSetsTCGbyCategory =
  (idCategory: string) => async (dispatch: Dispatch) => {
    if (apiURL !== "" && token !== "") {
      try {
        dispatch({
          type: SET_LOADING,
        });
        const { data } = await axios.get(`${apiURL}/admin/getSetForTemplate`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            itemTypeId: idCategory,
          },
        });
        dispatch({
          type: SET_LIST,
          payload: {
            sets: data.data,
          },
        });
      } catch (error) {
        console.error(error);

        dispatch({
          type: SET_FAIL,
          payload: "Error occurred while fetching sets.",
        });
      }
    } else {
      dispatch({
        type: SET_FAIL,
        payload: "ERROR",
      });
    }
  };


/**
 * Get a excel file from the BE.
 * @param {number} categoryId
 * @param {string} setName
 */
export const getSetTemplate =
  (categoryId: number | string, setName: string) =>
  async (dispatch: Dispatch<BannerActionsTypes>) => {
    if (apiURL !== "" && token !== "") {
      try {
        dispatch({
          type: SETTINGS_LOADING,
          payload: "EXCEL_FILE",
        });

        const headers: object = {
          ContentType: 'application/octet-stream',
          accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          Authorization: 'Bearer ' + token
        }


        const requestPath = `${apiURL}/admin/generateXLSTemplate`;

        const res = await axios.get(requestPath, {
          params: {itemTypeId: categoryId, setName: setName},
          responseType: 'blob',
          headers: headers,
        });

        if (res.status === 200) {
          const filename = res.headers['content-disposition']
            .split(';')
            .find((n: string) => n.includes('filename='))
            .replace('filename=', '')
            .trim();
          var blob = new Blob([res.data]);
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();

          dispatch({
            type: GET_XLSX_FILE_DATA,
            payload: "status.success-get-file",
          });
        } else {
          dispatch({
            type: SETTINGS_FAIL,
            payload: "status.get-error",
          });
        }
      } catch (e) {
        dispatch({
          type: SETTINGS_FAIL,
          payload: "status.get-error",
        });
      }
    } else {
      dispatch({
        type: SETTINGS_FAIL,
        payload: "status.get-error",
      });
    }
  };
