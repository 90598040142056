/**
 *****************************************
 *********** Dispatch Types **************
 *****************************************
 */
export const WH_LOADING = "WH_LOADING";
export const WH_FAIL = "WH_FAIL";
export const WH_LIST = "WH_LIST";
export const WH_ADD_QUANTITY = "WH_ADD_QUANTITY";

/**
 *****************************************
 ************* Object Types **************
 *****************************************
 */
export type WarehouseType = {
  id: number,
  name: string,
  description: string,
  isDiscount: boolean,
  isDeleted: boolean
};

export type WarehouseList = {
  first: boolean
  last: boolean
  number: number
  size: number
  totalPages: number
  warehoueses: WarehouseType[]
};

/**
 *****************************************
 *********** Interfaces Types ************
 *****************************************
 */
export interface WarehouseLoading {
  type: typeof WH_LOADING
  payload: string
};

export interface WarehouseFail {
  type: typeof WH_FAIL
};

export interface WarehouseListType {
  type: typeof WH_LIST,
  payload: WarehouseList
};

export interface WarehouseAddQuantity {
  type: typeof WH_ADD_QUANTITY,
};

// Export types.
export type WarehouseDispatchTypes = WarehouseLoading | WarehouseFail | WarehouseListType | WarehouseAddQuantity;
