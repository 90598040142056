// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldInput: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    actionsWrapper: {
      '& button': {
        marginBottom: '22px', 
      },
      '& button:last-of-type': {
        marginBottom: '0', 
      }
    },
    formControl: {
      width: '100%',
    },
    modalPaper: {
      padding: theme.spacing(6, 4),
      position: 'absolute',
      width: '400px',
      height: 'auto',
      top: 'calc(45% - 100px)',
      left: 'calc(50% - 200px)',
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(6, 2),
        width: '90%',
        left: '5%'
      },
      '& .MuiTypography-root': {
        marginBottom: theme.spacing(2),
      },
      '& .MuiButtonBase-root': {
        marginLeft: theme.spacing(2),
      },
      '& div > form': {
        padding: theme.spacing(0)
      },
      '& .MuiTypography-h5': {
        textAlign: 'center',
      }
    },
    modalPaperConfirm: {
      textAlign: 'center'
    },
    closeModal: {
      position: 'absolute',
      right: '0',
      top: '0'
    },
  }),
);

export default useStyles;
