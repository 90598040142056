import {
  WH_FAIL,
  WH_LOADING,
  WH_LIST,
  WH_ADD_QUANTITY,
  WarehouseDispatchTypes,
  WarehouseList
} from "../actions/warehouseActions/WarehouseActionsTypes";

// Default state interface.
interface DefaultStateI {
  loading: string,
  warehouseList?: WarehouseList
}

// Default state.
const defaultState: DefaultStateI = {
  loading: '',
};

const warehouseReducer = (state: DefaultStateI = defaultState, action: WarehouseDispatchTypes) : DefaultStateI => {
  switch (action.type) {
    case WH_FAIL:
      return {
        ...state,
        loading: '',
      }
    case WH_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case WH_LIST:
      return {
        ...state,
        loading: '',
        warehouseList: action.payload
      }
    case WH_ADD_QUANTITY:
      return {
        ...state,
        loading: ''
      }
    default:
      return state
  }
};


export default warehouseReducer;
