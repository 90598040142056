// React.
import React from 'react';
import {useParams} from "react-router-dom";
import { useSelector } from 'react-redux';
import { RootStore } from '../../../Store';


// Material UI.
import { Container } from "@material-ui/core";

// Components.
import EventEdit from '../events/EventEdit';
import AddPlayers from '../events/AddPlayers';
import PlayerList from '../events/PlayerList';
import PrizesSummary from '../prize/PrizesSummary';

type ParamsType = {
  id: string;
};

const EventDetailPage = () => {

  //ReduxState

  const reduxEventState = useSelector((state: RootStore) => state.events);

  // Page metatags info.
  const { id } = useParams<ParamsType>();
  return (
    <div>
      <Container maxWidth="lg">
        <EventEdit  id={id} />
        <AddPlayers id={id} />
        { (reduxEventState.eventDetail !== undefined && reduxEventState.eventDetail.status === "Finalizado") && <PrizesSummary/> }
        <PlayerList id={id}/>
      </Container>
    </div>
  )
}

export default EventDetailPage;