// React.
import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';

// Components.
import OrderDetailPrintable from '../printable/OrderDetailPrintable';
import ImageAvatar from '../utils/ImageAvatar';
import NumberFormat from '../utils/NumberFormat';
import OrderNotes from './OrderNotes';

// Material UI.
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/Close';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import { getItems } from "../../../actions/itemActions/ItemActions";
import {
  addRefund,
  addRefundBundle,
  updateOrder,
  updateOrderForAdminSingles
} from "../../../actions/orderActions/OrderActions";

// Translations.
import { useTranslation } from 'react-i18next';

// Global Types.
import { OrderBundleType, OrderItemType, OrderType } from '../models/ModelTypes';

// Styles.
import defaultImage from '../../../images/default-img.jpg';
import useStyles from './OrderEditStyles';

// Redux
import { getVendors } from '../../../actions/vendorActions/VendorActions';

//  JWT decode token.
import jwtDecode from 'jwt-decode';

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';
import {IconButton, Input } from '@material-ui/core';
import TextMaskIdentification from '../utils/TextMaskIdentification';


// Component interface.
interface OrderEditProps {
  order: OrderType;
  onlyRead?: boolean;
};

const OrderEdit = ({ order, onlyRead = false }: OrderEditProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();
  const decodedToken: any = localStorage.token !== undefined && localStorage.token !== '' ? jwtDecode(localStorage.token) : false;
  const [pinId, setPinId] = useState<string>('');
  const [isPinModalVisible, setIsPinModalVisible] = useState<boolean>(false)

  const isAsociateUser = () => {
    if (decodedToken) {
      if (decodedToken.auth.includes('ROLE_SELLER_ASSOCIATE')) {
        return true;
      }
    }
    return false;
  };

  const isAdminSinglesUser = () => {
    if (decodedToken) {
      if (decodedToken.auth.includes('ROLE_ADMIN_SINGLES')) {
        return true;
      }
    }
    return false;
  };

  const isSellerUser = () => {
    if (decodedToken) {
      if (decodedToken.auth === 'ROLE_ADMIN' || decodedToken.auth === 'ROLE_SELLER' 
        || decodedToken.auth === 'ROLE_SUPER_SELLER' || decodedToken.auth === 'ROLE_ADMIN_SINGLES') {
        return true;
      }
    }
    return false;
  };

  const isSeller = () => {
    if(decodedToken && decodedToken.auth === 'ROLE_SELLER') {
      return true;
    }
    return false;
  };


  // Define local state for the order.
  const [editing, setEditing] = useState<boolean | false>(false);
  const [openModal, setOpenModal] = useState(false);
  const [refundItemId, setRefundItemId] = useState<string>('');
  const [refundQuantity, setRefundQuantity] = useState<string>('');
  const [refundMax, setRefundMax] = useState<string>('');
  const [refundButton, setRefundButton] = useState<boolean>(true);
  const [refundItems, setRefundItems] = useState<Array<any>>([]);
  const [refundBundles, setRefundBundles] = useState<Array<any>>([]);
  const [updatedItems, setUpdatedItems] = useState<Array<any>>([]);
  const [status, setStatus] = useState<string>(order.status);
  const [statusList, setStatusList] = useState<Array<string>>([]);
  const [purchaseValidation, setPurchaseValidation] = useState<string>(order.purchaseValidation ? order.purchaseValidation : '');
  const [trackingNumber, setTrackingNumber] = useState<string>(order.trackingNumber ? order.trackingNumber : '');
  const [pickupId, setPickupId] = useState<string>(order.pickupId ? order.pickupId : '');
  const [identificationPickUpError, setIdentificationPickUpError] = useState<boolean>(false)
  // Redux state.
  const orderState = useSelector((state: RootStore) => state.order);
  const loading = orderState.loading;
  const reduxVendors = useSelector((state: RootStore) => state.vendors);
  const { vendors } = reduxVendors;

  const getVendorNameById = (id: number) => {
    const vendor = vendors.find(vendor => vendor.id === id)
    return vendor?.name
  }

  const getVendorIdByName = (name: string) => {
    const vendor = vendors.find(vendor => vendor.name.toLocaleUpperCase() === name.toLocaleUpperCase())
    return (vendor?.id !== undefined) ? vendor?.id : 0;
  }

  useEffect(() => {
    if (String(refundQuantity) <= String(refundMax) && String(refundQuantity) !== '0') {
      setRefundButton(false);
    } else {
      setRefundButton(true);
    }

    // eslint-disable-next-line
  }, [refundQuantity]);

  useEffect(() => {
    setStatus(order.status);
    getStatusListByStatus(order.status);

    // eslint-disable-next-line
  }, [order.status]);

  const getStatusListByStatus = async (status: string) => {
    const apiURL: string | undefined = process.env.REACT_APP_API_URL;
    const token: string | undefined = localStorage.token;

    const headers: object = {
      ContentType: 'application/json',
      Authorization: 'Bearer ' + token
    }

    const rolePath = isAdminSinglesUser() ? 'admin-singles' : 'seller';
    const requestPath = `${apiURL}/${rolePath}/getAllOrderStatus/${status}`;

    const res = await axios.get(requestPath, {
      headers: headers
    });

    if (res.status === 200) {
      var queryData = res.data.data.orderStatuses;

      // For orders with "Recoger en Tienda - Inmediato"
      if (order.shipping.id === 1) {
        queryData = queryData.filter((status: string) => status !== 'Enviado');
      }
      // For orders with "Correos de Costa Rica"
      if (order.shipping.id === 2) {
        queryData = queryData.filter((status: string) => status !== 'Listo para Recoger');
      }
      
      setStatusList(queryData);
    }
  }

  const handleOpenModal = (orderItem: any) => {
    setOpenModal(true);
    let quantity = isItemRefund(orderItem.item.id) ? (orderItem.normal_quantity - getNewRefundQauntity(orderItem.item.id, orderItem.normal_quantity)) : orderItem.normal_quantity;
    setRefundQuantity(quantity);
    const refundMax = orderItem.normal_quantity - getNewRefundQauntity(orderItem.item.id, orderItem.normal_quantity);
    setRefundMax(`${refundMax}`);
    setRefundItemId(orderItem.item.id);
    setRefundButton(true);

    // Get the select item information.
    let filter = { id: orderItem.item.id };
    dispatch(getItems(filter, 'seller'));
  };

  const handleBundleOpenModal = (orderItem: any) => {
    setOpenModal(true);
    let quantity = isBundleRefund(orderItem.bundle.id) ? (orderItem.quantity - getNewRefundBundleQauntity(orderItem.bundle.id, orderItem.quantity)) : orderItem.quantity;
    setRefundQuantity(quantity);
    const refundMax = orderItem.quantity - getNewRefundBundleQauntity(orderItem.bundle.id, orderItem.quantity);
    setRefundMax(`${refundMax}`);
    setRefundItemId(orderItem.bundle.id);
    setRefundButton(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const editOnClick = () => {
    setEditing(true);
  };

  const cancelOnClick = () => {
    setEditing(false);
    setStatus(order.status);
    setPurchaseValidation(order.purchaseValidation);
    setTrackingNumber(order.trackingNumber);
  };

  const handleRefund = () => {
    const currentRefund = refundItems;
    const currentRefundBundles = refundBundles;
    const currentUpdated = updatedItems;
    currentRefund.push({
      itemId: refundItemId,
      quantity: refundQuantity
    });
    currentRefundBundles.push({
      bundleId: refundItemId,
      quantity: refundQuantity
    });
    currentUpdated.push({
      itemId: refundItemId,
      quantity: refundQuantity,
    });
    setRefundItems(currentRefund);
    setRefundBundles(currentRefundBundles);
    setUpdatedItems(currentUpdated);
    handleCloseModal();
  };

  const refundQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== '') {
      const newValue = Number(event.target.value);
      const max = parseInt(refundMax) ?? 0
      const min = 1;
      if (newValue >= min && newValue <= max) {
        setRefundQuantity(event.target.value);
      }
    }
  };

  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as string);
  };

  const handleChangePurchaseValidation = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPurchaseValidation(event.target.value as string);
  };

  const handleChangeTrackingNumber = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTrackingNumber(event.target.value as string);
  };

  const handleChangePickUpId = (
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    const newValue = String(e.target.value)
    setPickupId(newValue)

    const isIdentificationFormat = /^[0-9\s]+$/.test(newValue)
    if (newValue === "" || !isIdentificationFormat) {
      setIdentificationPickUpError(true)
    } else {
      setIdentificationPickUpError(false)
    }
  }

  const isItemRefund = (id: number) => {
    var result = false;
    // Check for existing refunds.
    const refundItemsPrevious = order.refundItems;
    for (var i = 0; i < refundItemsPrevious.length; i++) {
      if (refundItemsPrevious[i].item.id === id) {
        result = true;
      }
    }

    // Chceck for current refund actions.
    for (var j = 0; j < refundItems.length; j++) {
      if (refundItems[j].itemId === id) {
        result = true;
      }
    }
    return result;
  };

  const isBundleRefund = (id: number) => {
    var result = false;
    // Check for existing refunds.
    const refundBundlesPrevious = order?.refundBundles!;
    for (var i = 0; i < refundBundlesPrevious.length; i++) {
      if (refundBundlesPrevious[i].bundle.id === id) {
        result = true;
      }
    }

    // Chceck for current refund actions.
    for (var j = 0; j < refundBundles.length; j++) {
      if (refundBundles[j].bundleId === id) {
        result = true;
      }
    }
    return result;
  };

  const getRefundDifferenceQuantity = (id: number) => {
    var refundNumber = 0;
    // Check for existing refunds.
    const refundItemsPrevious = order.refundItems;
    for (var i = 0; i < refundItemsPrevious.length; i++) {
      if (refundItemsPrevious[i].item.id === id) {
        refundNumber = refundItemsPrevious[i].normal_quantity;
      }
    }

    // Chceck for current refund actions. 2
    for (var j = 0; j < refundItems.length; j++) {
      if (refundItems[j].itemId === id) {
        refundNumber = refundItems[j].quantity;
      }
    }
    return refundNumber;
  };

  const getRefundBundleDifferenceQuantity = (id: number) => {
    var refundNumber = 0;
    // Check for existing refunds.
    const refundBundlesPrevious = order?.refundBundles!;
    for (var i = 0; i < refundBundlesPrevious.length; i++) {
      if (refundBundlesPrevious[i].bundle.id === id) {
        refundNumber = refundBundlesPrevious[i].quantity;
      }
    }

    // Chceck for current refund actions.
    for (var j = 0; j < refundBundles.length; j++) {
      if (refundBundles[j].bundleId === id) {
        refundNumber = refundBundles[j].quantity;
      }
    }
    return refundNumber;
  };

  const getItemImage = (orderItem: any) => {
    var image = defaultImage;
    if (orderItem.images[0] !== undefined) {
      image = orderItem.images[0].url;
    }
    return image;
  };

  const getNewRefundQauntity = (itemId: number, originalQty: number) => {
    var refundNumber = 0;
    // Check for existing refunds.
    const refundItemsPrevious = order.refundItems;
    for (var i = 0; i < refundItemsPrevious.length; i++) {
      if (refundItemsPrevious[i].item.id === itemId) {
        refundNumber = originalQty - refundItemsPrevious[i].normal_quantity;
      }
    }

    // Chceck for current refund actions.
    for (var j = 0; j < refundItems.length; j++) {
      if (refundItems[j].itemId === itemId) {
        refundNumber = originalQty - refundItems[j].quantity;
      }
    }
    return refundNumber;
  };

  const getNewRefundBundleQauntity = (itemId: number, originalQty: number) => {
    var refundNumber = 0;
    // Check for existing refunds.
    const refundBundlesPrevious = order?.refundBundles!;
    for (var i = 0; i < refundBundlesPrevious.length; i++) {
      if (refundBundlesPrevious[i].bundle.id === itemId) {
        refundNumber = originalQty - refundBundlesPrevious[i].quantity;
      }
    }

    // Chceck for current refund actions.
    for (var j = 0; j < refundBundles.length; j++) {
      if (refundBundles[j].bundleId === itemId) {
        refundNumber = originalQty - refundBundles[j].quantity;
      }
    }
    return refundNumber;
  };

  const purchaseValidationError = () => {
    let prevStatus = order.status.toLocaleLowerCase();
    const newSatus = status.toLocaleLowerCase();
    if ((prevStatus === 'procesando' || prevStatus === 'listo para recoger' || prevStatus === 'enviado')) {
      if (!purchaseValidation && newSatus === 'completado') {
        return true;
      }
      if (!purchaseValidation && newSatus === 'listo para recoger') {
        return true;
      }
      if (!purchaseValidation && newSatus === 'enviado') {
        return true;
      }
    }
    return false;
  };

  const trackingNumberError = () => {
    let prevStatus = order.status.toLocaleLowerCase();
    const newSatus = status.toLocaleLowerCase();
    if (prevStatus === 'procesando' && !trackingNumber && (newSatus === 'enviado')) {
      return true;
    }
    return false;
  };


  const handleSaveOnClick = () => {
    if ((refundItems.length >= 0 || refundBundles.length >= 0 || updatedItems.length > 0 || purchaseValidation !== '' || trackingNumber !== '') && status !== '') {
      // Check if there is refund to save.
      if (refundItems.length > 0) {
        let refundData = {
          orderId: order.id,
          orderItems: refundItems
        }
        dispatch(addRefund(refundData));
      }
      // Check if there is bundle refund to save.
      if (refundBundles.length > 0) {
        let refundData = {
          orderId: order.id,
          orderBundles: refundBundles
        }
        dispatch(addRefundBundle(refundData));
      }
      //Validate pin before completing it
      if (status.toLocaleLowerCase() === 'completado' && order.user !== null) {
        setIsPinModalVisible(true);
        return;
      }
      // create payload
      const updateOrderData = {
        orderId: order.id,
        purchaseValidation: purchaseValidation,
        status: status,
        trackingNumber: trackingNumber,
        pickupId: pickupId
      }
      // Save the order data.
      saveOrderData(updateOrderData)
    }
  };

  const handleSaveCompleteOrder = () => {
    if (pinId !== '') {
      const updateOrderData = {
        orderId: order.id,
        purchaseValidation: purchaseValidation,
        status: status,
        trackingNumber: trackingNumber,
        pickupId: pickupId,
        pinId: pinId
      }
      // Save the order data.
      saveOrderData(updateOrderData)
    }
  }
  const saveOrderData = (updateOrderData: {
    orderId: number;
    purchaseValidation: string;
    status: string;
    trackingNumber: string;
    pickupId: string;
    pinId?: string;
  }) => {
    if (!purchaseValidationError() && !trackingNumberError()) {
      if (isAdminSinglesUser()) {
        dispatch(updateOrderForAdminSingles(updateOrderData)) // review for deletion later
      } else {
        dispatch(updateOrder(updateOrderData))
        setIsPinModalVisible(false)
        setPinId('')
      }
      dispatch(getVendors())
      setEditing(false)
      // ;
    }
  }

  const getUserPhoneNumber = () => {
    var result = t('orders.not-available');
    if (order.address.phoneNumber !== null && order.address.phoneNumber !== '') {
      result = order.address.phoneNumber;
    }

    if (order.user !== null) {
      if (order.user.phoneNumber !== null && order.user.phoneNumber !== '') {
        result = order.user.phoneNumber;
      }
    }
    return result;
  };

  const getBundlePrice = (orderBundle: OrderBundleType) => {
    var bundlePrice = orderBundle.price + ((orderBundle.price * orderBundle.tax) / 100);
    return bundlePrice;
  };

  const getPrice = (orderItem: OrderItemType, hasDiscount = false) => {
    var singlePrice = orderItem.normal_price + ((orderItem.normal_price * orderItem.itemTypeTax) / 100);
    if (hasDiscount) {
      singlePrice = orderItem.discount_price + ((orderItem.discount_price * orderItem.itemTypeTax) / 100);
    }
    return singlePrice;
  };

  const getSinglePrice = (orderItem: OrderItemType) => {
    let singlePrice = orderItem.normal_price + (orderItem.normal_price * orderItem.administrativeCost) - (orderItem.normal_price * orderItem.conditionCost)
    let roundedPrice = Math.ceil(singlePrice / 50) * 50;
    return roundedPrice;
  };

  const getUserAddress = () => {
    var result = t('orders.not-available');
    if (order.address !== null && order.address !== undefined) {
      result = `${order.address.province}, ${order.address.canton}, ${order.address.district}, ${order.address.district}`;
      result += order.address.fullAddress ? `, ${order.address.fullAddress}` : '';
      result += order.address.postalCode ? `, ${order.address.postalCode}.` : '.';
    }

    return result;
  };

  const getVendorClass = (vendorId: number) => {
    switch (vendorId) {
      case 1:
        return classes.bgYellow;
      case 2:
        return classes.bgRed;
      case 3:
        return classes.bgBlue;
      case 4:
        return classes.bgGray;
      case 5:
        return classes.bgGreen;
      default:
        return classes.bgGray;
    }
  };

  const getStatusClass = (status:string) => {
    switch (status.toLocaleLowerCase()) {
      case 'procesando':
        return classes.bgYellow;
      case 'cancelado':
        return classes.bgRed;
      case 'orden recibida':
      case 'pago pendiente':
        return classes.bgOrange;
      case 'completado':
      case 'listo para recoger':
      case 'enviado':
        return classes.bgGreen;
      default:
        return classes.bgGray;
    }
  };

  const findNumberonHtml = (html: string, item: any) => {
    var matchNumber:string = '';
    if(html === undefined || html === null) return matchNumber = `<strong>Number: </strong>NA</div>`;
    if (item.itemType.name === 'Magic: The Gathering') {
      const regexFindNumberSingleMTG = /#: <\/strong>(\d+)<\/span>/;
      const match = html.match(regexFindNumberSingleMTG);
      matchNumber = match ? match[1] : '';
      matchNumber = `<strong>Number: </strong>`+ matchNumber + `</div>`
    } else {
      // Trun string to HTML object.
      var htmlObject = document.createElement('div');
      htmlObject.innerHTML = html;
      // Find the element which contains the number.
      for (var i = 0; i < htmlObject.querySelectorAll('span').length; i++) {
        const span = htmlObject.querySelectorAll('span')[i];
        if (span?.textContent?.includes('Number')) {
          matchNumber = span.innerHTML;
          break;
        }
      }
    }

    return matchNumber;
  };

  
  const handleClosePinModal = () => {
    setIsPinModalVisible(false);
  }

  const handleChangePin = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 4) {
      setPinId(e.target.value);
    }
  }

  return (
    <Fragment>
        <Modal
          open={isPinModalVisible}
          onClose={handleClosePinModal}
          aria-labelledby="Confirm Modal"
        >
          <Paper elevation={3} className={classes.modalPaper} style={{ height: 'auto', padding: '24px', width: '400px' }}>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton component="span" onClick={handleClosePinModal}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {t('orders.pin-code-modal-message')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="pin-modal-input"
                  name="pinId"
                  value={pinId}
                  onChange={handleChangePin}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveCompleteOrder}
                  disabled={pinId.length !== 4}
                >
                  {t('orders.complete-order')}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Modal>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          {order?.orderBundleItems?.map((orderItem, index) =>
            <Fragment key={`${orderItem.bundle.id + index}`}>
              {orderItem.quantity > 0 &&
                <div className={classes.itemCard} key={orderItem.bundle.id}>
                  <Grid container className={classes.leftColumn}>
                    <Grid item xs={3} sm={3} md={2}>
                      <ImageAvatar src={getItemImage(orderItem.bundle)} alt="Bundle main image." />
                    </Grid>
                    <Grid item xs={9} sm={9} md={7}>
                      <Typography variant="caption" color="textSecondary">{t('orders.item-name')} <span className={classes.type}>{t('bundles.bundle')}</span></Typography>
                      <Typography>{orderItem.bundle.name}</Typography>
                      {isBundleRefund(orderItem.bundle.id) &&
                        <Typography variant="caption" color="secondary">
                          {t('orders.refund-message', { 'refundNumber': getRefundBundleDifferenceQuantity(orderItem.bundle.id) })}
                        </Typography>
                      }
                      <div className={classes.secondaryInfo}>
                        <div className="o-info">
                          <Typography variant="caption" color="textSecondary">{t('orders.add-item-quantity')}</Typography>
                          <Typography>{isBundleRefund(orderItem.bundle.id) ? getNewRefundBundleQauntity(orderItem.bundle.id, orderItem.quantity) : orderItem.quantity}</Typography>
                        </div>
                        <div className="o-info">
                          <Typography variant="caption" color="textSecondary">{t('orders.item-price')}</Typography>
                          <Typography>{order.currency.symbol}{<NumberFormat number={getBundlePrice(orderItem)} />}</Typography>
                        </div>
                        <div className="o-info">
                          <Typography variant="caption" color="textSecondary">{t('orders.seller')}</Typography>
                          <Typography>
                            {order.vendor.name}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    {(editing && !onlyRead) &&
                      <Grid item xs={12} sm={12} md={3} className={classes.refundWrapper}>
                        <Button startIcon={<AutorenewIcon />} variant="outlined" color="default" onClick={() => handleBundleOpenModal(orderItem)}>{t('orders.refund')}</Button>
                      </Grid>
                    }
                  </Grid>
                </div>
              }
            </Fragment>
          )}
          {order.orderItems.map((orderItem, index) =>
            <Fragment key={`${orderItem.item.id + index}-1`}>
              {orderItem.normal_quantity > 0 &&
                <div className={classes.itemCard} key={orderItem.item.id + index}>
                  <Grid container className={classes.leftColumn}>
                    <Grid item xs={3} sm={3} md={2}>
                      <ImageAvatar src={getItemImage(orderItem.item)} alt="Item main image." />
                    </Grid>
                    <Grid item xs={9} sm={9} md={(orderItem.item.category !== 'Single') ? 7 : 10}>
                      <Typography variant="caption" color="textSecondary">
                        {t('orders.item-name')}
                        {(orderItem.item.category === 'Single') &&
                          <span className={classes.typeSingle}>{orderItem.item.category}</span>
                        }
                      </Typography>
                      <Typography>
                        {orderItem.item.name}
                        {(orderItem.item.category === 'Single') &&
                          <span className={classes.variationSingle}>{orderItem.id.customReference}</span>
                        }
                      </Typography>
                      <Typography variant="caption" color="textSecondary">{t('orders.item-code')}</Typography>
                      <Typography>{orderItem.item.code}</Typography>
                      {isItemRefund(orderItem.item.id) &&
                        <Typography variant="caption" color="secondary">
                          {t('orders.refund-message', { 'refundNumber': getNewRefundQauntity(orderItem.item.id, orderItem.normal_quantity) })}
                        </Typography>
                      }
                      <div className={classes.secondaryInfo}>
                        <div className="o-info">
                          <Typography variant="caption" color="textSecondary">{t('orders.add-item-quantity')}</Typography>
                          <Typography>{isItemRefund(orderItem.item.id) ? getRefundDifferenceQuantity(orderItem.item.id) : orderItem.normal_quantity}</Typography>
                        </div>
                        <div className="o-info">
                          <Typography variant="caption" color="textSecondary">{t('orders.item-price')}</Typography>
                          <Typography>
                            {order.currency.symbol}{<NumberFormat number={(orderItem.item.category === 'Single') ? getSinglePrice(orderItem) : getPrice(orderItem)} />}
                          </Typography>
                        </div>
                        <div className="o-info">
                          <Typography variant="caption" color="textSecondary">{t('orders.seller')}</Typography>
                          <Typography>
                            {order.vendor.name}
                          </Typography>
                        </div>
                        {(orderItem.item.category === 'Single') &&
                          <div className="o-info">
                            {findNumberonHtml(orderItem.item?.additionalInfo!, orderItem.item) !== null &&
                              <Typography variant="caption" color="textSecondary">
                                <div dangerouslySetInnerHTML={{ __html: findNumberonHtml(orderItem.item?.additionalInfo!, orderItem.item) }}></div>
                              </Typography>
                            }
                            <Typography variant="caption" color="textSecondary">
                              <strong>{t('items.labels')}: </strong><br/>
                            </Typography>
                            <Typography>
                              {orderItem.item?.labelValues?.map((labelValue, index) =>
                                <span key={labelValue.id}>
                                  {index !== 0 ? ', ' : ''}{labelValue.value}
                                </span>
                              )}
                            </Typography>
                          </div>
                        }
                      </div>
                    </Grid>
                    {(editing && orderItem.item.category !== 'Single') &&
                      <Grid item xs={12} sm={12} md={3} className={classes.refundWrapper}>
                        <Button startIcon={<AutorenewIcon />} variant="outlined" color="default" onClick={() => handleOpenModal(orderItem)}>{t('orders.refund')}</Button>
                      </Grid>
                    }
                  </Grid>
                </div>
              }
              {orderItem.discount_quantity > 0 &&
                <div className={classes.itemCard}  key={`${orderItem.item.id + index}-2`}>
                  <Grid container className={classes.leftColumn}>
                    <Grid item xs={3} sm={3} md={2}>
                      <ImageAvatar src={getItemImage(orderItem.item)} alt="Item main image." />
                    </Grid>
                    <Grid item xs={9} sm={9} md={7}>
                      <Typography variant="caption" color="textSecondary">{t('orders.item-name')}</Typography>
                      <Typography>{orderItem.item.name}</Typography>
                      <Typography variant="caption" color="textSecondary">{t('orders.item-code')}</Typography>
                      <Typography>{orderItem.item.code}</Typography>
                      {isItemRefund(orderItem.item.id) &&
                        <Typography variant="caption" color="secondary">
                          {t('orders.refund-message', { 'refundNumber': getNewRefundQauntity(orderItem.item.id, orderItem.normal_quantity) })}
                        </Typography>
                      }
                      <div className={classes.secondaryInfo}>
                        <div className="o-info">
                          <Typography variant="caption" color="textSecondary">{t('orders.add-item-quantity')}</Typography>
                          <Typography>{isItemRefund(orderItem.item.id) ? getRefundDifferenceQuantity(orderItem.item.id) : orderItem.discount_quantity}</Typography>
                        </div>
                        <div className="o-info">
                          <Typography variant="caption" color="textSecondary">{t('orders.item-price')}</Typography>
                          <Typography className={classes.discountLabel}>{order.currency.symbol}{<NumberFormat number={getPrice(orderItem)} />}</Typography>
                          <Typography>{order.currency.symbol}{<NumberFormat number={orderItem.item.discountPriceWithTaxes !== undefined ? getPrice(orderItem, true) : 0} />}</Typography>
                        </div>
                        <div className="o-info">
                          <Typography variant="caption" color="textSecondary">{t('orders.seller')}</Typography>
                          <Typography>
                            {getVendorNameById(order.vendor.id)}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    {editing &&
                      <Grid item xs={12} sm={12} md={3} className={classes.refundWrapper}>
                        <Button startIcon={<AutorenewIcon />} variant="outlined" color="default" onClick={() => handleOpenModal(orderItem)}>{t('orders.refund')}</Button>
                      </Grid>
                    }
                  </Grid>
                </div>
              }
            </Fragment>
          )}

          {isSellerUser() &&
            <OrderNotes orderId={order.id} orderNotes={order?.orderNotes!} />
          }
        </Grid>
        <Grid item xs={12} sm={4} className={classes.rightColumn}>
          <div className={classes.clientSection}>
            <Typography color="textSecondary" className={classes.textBold}>{t('orders.order')} {order.orderCode}</Typography><br />
            {!isAsociateUser() &&
              <Fragment>
                <Typography variant="body1" color="primary">{t('orders.client-information')}</Typography>
                <div>
                  {order.user !== null ?
                    <Fragment>
                      <Typography variant="caption" color="textSecondary">{t('orders.client-name')}</Typography>
                      <Typography>{order.user.firstName} {order.user.lastName}</Typography>
                      {order.address !== null &&
                        <Fragment>
                          <Typography variant="caption" color="textSecondary">{t('orders.phone')}</Typography>
                          <Typography>{getUserPhoneNumber()}</Typography>
                        </Fragment>
                      }
                    </Fragment> :
                    t('orders.not-available')
                  }
                </div>
              </Fragment>
            }
          </div>
          {editing ?
            <div>
              <div className={classes.trackingSection}>
                <Typography variant="body1" color="primary">{t('orders.tracking-details')}</Typography>
                <Typography variant="caption" color="textSecondary">{t('orders.delivery-method')}</Typography>
                <Typography>{order.shipping.name} - {order.shipping.description}</Typography>
                {!isAsociateUser() &&
                  <Fragment>
                    <Typography variant="caption" color="textSecondary">Vendedor</Typography>
                    <Typography>{order.vendor.name}</Typography>
                    <Typography variant="caption" color="textSecondary">{ order.shipping.id === 1 ? t('orders.pickup-at') : t('orders.send-to') }</Typography>
                    <Typography>{order.addressName}</Typography>
                    {(order.shipping.id === 2) &&
                      <Fragment>
                        <Typography variant="caption" color="textSecondary">{t('orders.client-address')}</Typography>
                        <Typography>{getUserAddress()}</Typography>
                      </Fragment>
                    }
                  </Fragment>
                }
              </div>
              <div className={classes.paymentSection}>
                <Typography variant="body1" color="primary">{t('orders.payment-info')}</Typography>
                <Typography variant="caption" color="textSecondary">{t('orders.payment-method')}</Typography>
                <Typography>{order.paymentMethod}</Typography>
                <Typography variant="caption" color="textSecondary">{getProjectConfig().STORE_CREDITS_NAME} {t('orders.used-points')}</Typography>
                <Typography>{(order.points) ? order.points : '0'}</Typography>
                <FormControl>
                  <InputLabel id="order-status-label">{t('orders.status')}</InputLabel>
                  <Select
                    labelId="order-status-label"
                    id="order-status"
                    name="status"
                    value={status}
                    onChange={handleChangeStatus}
                    error={status === ''}
                    disabled={statusList.length === 0}
                  >
                    <MenuItem value={status}>
                      <em>{`${status === 'Orden Recibida' ? status : t('orders.none') }`}</em>
                    </MenuItem>
                    {statusList !== undefined &&
                      statusList.map((statusMap) =>
                        <MenuItem key={statusMap} value={statusMap}>{statusMap}</MenuItem>
                      )
                    }
                    {statusList !== undefined &&
                      statusList.length === 0 &&
                      <MenuItem key={status} value={status}>{status}</MenuItem>
                    }
                  </Select>
                </FormControl>
                <Typography variant="caption" color="textSecondary">{t('orders.purchase-validation')}</Typography>
                <TextField
                  id="o-purchase-validation"
                  name="purchaseValidation"
                  value={purchaseValidation}
                  onChange={handleChangePurchaseValidation}
                  error={purchaseValidationError()}
                  helperText={purchaseValidationError() ? t('users.field-required') : ''}
                />
                <Typography variant="caption" color="textSecondary">{t('orders.tracking-number')}</Typography>
                <TextField
                  id="o-tracking-number"
                  name="trackingNumber"
                  value={trackingNumber}
                  onChange={handleChangeTrackingNumber}
                  error={trackingNumberError()}
                  helperText={trackingNumberError() ? t('users.field-required') : ''}
                />
                {
                  !isSeller() && (
                    <>
                      <Typography variant='caption' color='textSecondary'>{t('orders.pickUpId')}</Typography>
                        <Input
                        id="identificationPickUp"
                        name="identificationPickUp"
                        value={pickupId}
                        onChange={handleChangePickUpId}
                        inputComponent={TextMaskIdentification as any}
                        error={identificationPickUpError}
                        placeholder="000000000000000"
                        className={classes.inputPickUpId}
                        />
                    </>
                  )
                }
              </div>
            </div>
            :
            <div>
              <div className={classes.trackingSection}>
                <Typography variant="body1" color="primary">{t('orders.tracking-details')}</Typography>
                <Typography variant="caption" color="textSecondary">{t('orders.delivery-method')}</Typography>
                <Typography>{order.shipping.name} - {order.shipping.description}</Typography>
                {!isAsociateUser() &&
                  <Fragment>
                    {order.shipping.id === 1 ? 
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Typography variant="caption" color="textSecondary">Vendedor</Typography>
                          <Typography className={`${classes.vendorBox} ${getVendorClass(order.vendor.id)}`}>{order.vendor.name}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="caption" color="textSecondary">{t('orders.pickup-at')}</Typography>
                          <Typography className={`${classes.vendorBox} ${getVendorClass(getVendorIdByName(order.addressName))}`}>{order.addressName}</Typography>
                        </Grid>
                      </Grid>
                      :
                      <Fragment>
                        <Typography variant="caption" color="textSecondary">Vendedor</Typography>
                        <Typography className={`${classes.vendorBox} ${getVendorClass(order.vendor.id)}`}>{order.vendor.name}</Typography>
                        <Typography variant="caption" color="textSecondary">{t('orders.send-to')}</Typography>
                        <Typography>{getUserAddress()}</Typography>
                      </Fragment>
                    }
                  </Fragment>
                }
              </div>
              <div className={classes.paymentSection}>
                <Typography variant="body1" color="primary">{t('orders.payment-info')}</Typography>
                <Typography variant="caption" color="textSecondary">{t('orders.payment-method')}</Typography>
                <Typography>{order.paymentMethod}</Typography>
                <Typography variant="caption" color="textSecondary">{getProjectConfig().STORE_CREDITS_NAME} {t('orders.used-points')}</Typography>
                <Typography>{(order.points) ? order.points : '0'}</Typography>
                {order.pin && (
                  <>
                    <Typography variant="caption" color="textSecondary">
                    {t('orders.pin-form-label')}
                    </Typography>
                    <Typography>{order.pin}</Typography>
                  </>
                )}
                <Typography variant="caption" color="textSecondary">{t('orders.status')}</Typography>
                <Typography>
                  <span className={`${classes.vendorBox} ${getStatusClass(status !== '' ? status : order.status) }`}>
                    {status !== '' ? status.toUpperCase() : order.status.toUpperCase()}
                  </span>
                </Typography>
                <Typography variant="caption" color="textSecondary">{t('orders.purchase-validation')}</Typography>
                {
                  purchaseValidation ?
                    <Typography className={classes.textSuccess}><span>{purchaseValidation}</span></Typography>
                    :
                    <Typography className={classes.textDanger}><span>{t('orders.not-specified')}</span></Typography>
                }
                <Typography variant="caption" color="textSecondary">{t('orders.tracking-number')}</Typography>
                <Typography>{trackingNumber ? trackingNumber : t('orders.not-specified')}</Typography>
                <Typography variant="caption" color="textSecondary">{t('orders.pickUpId')}</Typography>
                <Typography>{order.pickupId && (order.pickupId !== "" || order.pickupId !== null) ? order.pickupId : t('orders.not-specified')}</Typography>
              </div>
            </div>
          }
          <Grid item xs={12}>
          {(refundItems.length > 0 && editing) &&
            <Typography variant="caption" color="secondary">{t('orders.unsaved-refunds')}</Typography>
          }
          <FormControl className={classes.formControl}>
            {editing && (
              <div className={classes.actionsWrapper}>
                {(loading === 'UPDATE_ORDER' || loading === 'ADD_REFUND') && <CircularProgress />}
                <Button variant="text" color="primary" onClick={handleSaveOnClick} disabled={trackingNumberError() || purchaseValidationError()}>
                  {t('orders.save')}
                </Button>
                <Button variant="contained" color="secondary" onClick={cancelOnClick}>
                  {t('orders.cancel')}
                </Button>
                <Button variant="contained" color="primary" disabled>
                  {t('orders.edit-order')}
                </Button>
              </div>

            )}
            {(!isAsociateUser() && !editing && !onlyRead) && (
              <div className={classes.actionsWrapper}>
                {(loading === 'LIST') && <CircularProgress size={30}/>}
                <OrderDetailPrintable order={order} />
                <Button variant="contained" color="primary" onClick={editOnClick}>
                  {t('orders.edit-order')}
                </Button>
              </div>
            )}
          </FormControl>
        </Grid>
        </Grid>
        
      </Grid>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="Refund Modal"
        aria-describedby="Refund items from current order"
      >
        <div>
          <Paper elevation={3} className={classes.modalPaper}>
            <div className={classes.modalHeading}>
              <AutorenewIcon color="primary" fontSize="large" />
              <Typography variant="h5" color="primary">{t('orders.refund')}</Typography>
            </div>
            <div className={classes.modalFields}>
              <div className={classes.modalField}>
                <Typography variant="subtitle1" color="textSecondary">{t('orders.refund-quantity-label')}</Typography>
                <TextField id="o-quantity" name="refund_quantity" type="number"
                  value={refundQuantity}
                  onChange={refundQuantityChange}
                  onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                />
              </div>
              <div className={classes.modalField}>
                <Typography variant="subtitle1" color="textSecondary">{t('orders.max-refund-label')}</Typography>
                <Typography variant="subtitle1" color="textSecondary">{refundMax}</Typography>
              </div>
            </div>
            <div className={classes.modalActions}>
              <Button variant="text" color="secondary" onClick={handleCloseModal}>
                {t('orders.cancel')}
              </Button>
              <Button variant="contained" color="primary" onClick={handleRefund} disabled={refundButton}>
                {t('orders.process-refund')}
              </Button>
            </div>
          </Paper>
        </div>
      </Modal>
    </Fragment>
  )
}

export default OrderEdit;
