import {Dispatch} from "redux";
import axios from 'axios';

import {
  GET_BANNERS, GET_BOXES, CREATE_BANNER, UPDATE_BANNER, GET_HOMEPAGE, SETTINGS_LOADING,
  CREATE_ORDER_CONFIRMATION, UPDATE_ORDER_CONFIRMATION, GET_ORDER_CONFIRMATIONS, DELETE_ORDER_CONFIRMATION,
  SETTINGS_FAIL, REGISTER_ITEMS_BY_XLSX, VALIDATION_ITEMS_BY_XLSX, CREATE_BOX, SETTINGS_FAIL_DETAIL, GET_VARIABLES, 
  UPDATE_VARIABLES, GET_ALL_XLSX_FILES, GET_XLSX_FILE_DATA, OrderConfirmation, BannerActionsTypes } from "./SettingsActionsTypes";

const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;

/**
 * Get a list of banners from the BE.
 */
 export const getBanners = (strict:boolean) => async (dispatch: Dispatch<BannerActionsTypes>) => {
  if (apiURL !== '') {
    try {
      dispatch({
        type: SETTINGS_LOADING,
        payload: 'LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      const requestPath = !token ? `${apiURL}/guest/getAllBanners` : `${apiURL}/user/getAllBanners`;

      const res = await axios.get(requestPath, {
        headers: headers
       });

       if (res.status === 200) {
          const queryData = res.data.data.content;
          const banners:any = [];
         

        for(var i = 0; i < queryData.length; i++) {
          const banner = queryData[i];
          if (strict) {
            if (!banner.isDisable) {
              banners.push({
                id: banner.id,
                position: banner.position,
                imageUrl: banner.imageUrl,
                imageKey: banner.imageKey,
                isGradient: banner.isGradient,
                text: banner.text,
                ctaText: banner.ctaText,
                ctaLink: banner.ctaLink,
                isDisable: banner.isDisable
              });
            }
          } else {
            banners.push({
              id: banner.id,
              position: banner.position,
              imageUrl: banner.imageUrl,
              imageKey: banner.imageKey,
              isGradient: banner.isGradient,
              text: banner.text,
              ctaText: banner.ctaText,
              ctaLink: banner.ctaLink,
              isDisable: banner.isDisable
            });
          }
        }

        dispatch({
          type: GET_BANNERS,
          payload: banners
        })
       } else {
        dispatch({
          type: SETTINGS_FAIL,
          payload: 'status.get-error'
        })
       }
    } catch(e) {
      dispatch({
        type: SETTINGS_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: SETTINGS_FAIL,
      payload: 'status.get-error'
    })
  }
};


/**
 * Create a new slider banner on the BE.
 * @param {string} position
 * @param {any} file
 * @param {any} extraData
 */
 export const addBanner = (position: string, file:any, extraData:any) => async (dispatch: Dispatch<BannerActionsTypes>) => {
  try {
    dispatch({
      type: SETTINGS_LOADING,
      payload: 'ADD_BANNER'
    })
    var bodyFormData = new FormData();
    file && bodyFormData.append("file", file, file.name);
    position && bodyFormData.append('position', position);
    bodyFormData.append('isGradient', extraData.gradient);
    bodyFormData.append('text', extraData.title);
    bodyFormData.append('ctaText', extraData.ctaText);
    bodyFormData.append('ctaLink', extraData.ctaLink);
  
    const configPOST = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
      }
    };
    const res = await axios.post(`${ apiURL }/admin/registerBanner`, bodyFormData, configPOST);
    if (res.status === 200) {
      dispatch({
        type: CREATE_BANNER,
        payload: 'status.success-create'
      })
    } else {
      dispatch({
        type: SETTINGS_FAIL,
        payload: 'status.error-create'
      })
    }

  } catch(e) {
    dispatch({
      type: SETTINGS_FAIL,
      payload: 'status.error-create'
    })
  }
};

/**
 * Update slider banner on the BE.
 * @param {string} currentPosition
 * @param {string} newPosition
 * @param {boolean} isDisable
 * @param {any} file
 * @param {any} extraData
 */
 export const updateBanner = (currentPosition: string, newPosition: string, isDisable:boolean, file:any, extraData:any) => async (dispatch: Dispatch<BannerActionsTypes>) => {
  try {
    dispatch({
      type: SETTINGS_LOADING,
      payload: 'UPDATE_BANNER'
    })

    var bodyFormData = new FormData();
    file.name !== undefined && bodyFormData.append("file", file, file.name);
    bodyFormData.append('currentPosition', currentPosition);
    bodyFormData.append('newPosition', newPosition);
    bodyFormData.append('isDisable', String(isDisable));
    bodyFormData.append('isGradient', extraData.gradient);
    bodyFormData.append('text', extraData.title);
    bodyFormData.append('ctaText', extraData.ctaText);
    bodyFormData.append('ctaLink', extraData.ctaLink);
  
    const configPUT = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
      }
    };
    const res = await axios.put(`${ apiURL }/admin/updateBanner`, bodyFormData, configPUT);
    if (res.status === 200) {
      dispatch({
        type: UPDATE_BANNER,
        payload: 'status.success-update'
      })
    } else {
      dispatch({
        type: SETTINGS_FAIL,
        payload: 'status.error-update'
      })
    }

  } catch(e) {
    dispatch({
      type: SETTINGS_FAIL,
      payload: 'status.error-update'
    })
  }
};

/**
 * Create a new featured box on the BE.
 * @param {Array<any>} items
 * @param {number} position
 * @param {string} title
 */
 export const addFeaturedBox = (items: Array<any>, position: number, title: string) => async (dispatch: Dispatch<BannerActionsTypes>) => {
  try {
    dispatch({
      type: SETTINGS_LOADING,
      payload: 'ADD_BANNER'
    })
   
    const bodyForm = {
      "items": items,
      "position": position,
      "title": title,
      "isDisable": "false"
    };
  
    const configPOST = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    };
    const res = await axios.post(`${ apiURL }/admin/registerFeatureBox`, bodyForm, configPOST);
    if (res.status === 200) {
      dispatch({
        type: CREATE_BOX,
        payload: 'status.success-create'
      })
    } else {
      dispatch({
        type: SETTINGS_FAIL,
        payload: 'status.create-error'
      })
    }

  } catch(e) {
    dispatch({
      type: SETTINGS_FAIL,
      payload: 'status.create-error'
    })
  }
};

/**
 * CUpdate a featured box on the BE.
 * @param {number} id
 * @param {Array<any>} items
 * @param {number} newPosition
 * @param {string} title
 * @param {boolean} isDisable
 */
 export const updateFeaturedBox = (id: number, items: Array<any>, newPosition: number, title: string, isDisable: boolean) => async (dispatch: Dispatch<BannerActionsTypes>) => {
  try {
    dispatch({
      type: SETTINGS_LOADING,
      payload: 'ADD_BANNER'
    })
   
    const bodyForm = {
      "id": id,
      "isDisable": isDisable,
      "items": items,
      "newPosition": newPosition,
      "title": title
    };
  
    const configPOST = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    };
    const res = await axios.put(`${ apiURL }/admin/updateFeatureBox`, bodyForm, configPOST);
    if (res.status === 200) {
      dispatch({
        type: CREATE_BOX,
        payload: 'status.success-update'
      })
    } else {
      dispatch({
        type: SETTINGS_FAIL,
        payload: 'status.create-error'
      })
    }

  } catch(e) {
    dispatch({
      type: SETTINGS_FAIL,
      payload: 'status.create-error'
    })
  }
};

/**
 * Get a list of featured boxes from the BE.
 */
 export const getFeaturedBox = () => async (dispatch: Dispatch<BannerActionsTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: SETTINGS_LOADING,
        payload: 'LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      const requestPath = !token ? `${apiURL}/guest/getAllFeatureBox` : `${apiURL}/user/getAllFeatureBox`;

      const res = await axios.get(requestPath, {
        headers: headers
       });

       if (res.status === 200) {
          const queryData = res.data.data.content;
          const boxes:any = [];
         

        for(var i = 0; i < queryData.length; i++) {
          const box = queryData[i];
        
          boxes.push({
            id: box.id,
            title: box.title,
            position: box.position,
            items: box.items,
            isDisable: box.isDisable
          });
        }

        dispatch({
          type: GET_BOXES,
          payload: boxes
        })
       } else {
        dispatch({
          type: SETTINGS_FAIL,
          payload: 'status.get-error'
        })
       }
    } catch(e) {
      dispatch({
        type: SETTINGS_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: SETTINGS_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Get the homepage information objects.
 */
export const getHomepageInformation = () => async (dispatch: Dispatch<BannerActionsTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: SETTINGS_LOADING,
        payload: 'LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      const requestPath = `${apiURL}/guest/getHomepageInformation`;

      const res = await axios.get(requestPath, {
        headers: headers
      });

      if (res.status === 200) {
        const queryData = res.data.data;

        dispatch({
          type: GET_HOMEPAGE,
          payload: queryData
        })
      } else {
        dispatch({
          type: SETTINGS_FAIL,
          payload: 'status.get-error'
        })
      }
    } catch (e) {
      dispatch({
        type: SETTINGS_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: SETTINGS_FAIL,
      payload: 'status.get-error'
    })
  }
};


/**
 * Register single items for all associate vendors using a excel file.
 * @param {file} file
 * @param {boolean} isOnlyVerification
 */
export const uploadExcelFile = (file: any, isOnlyVerification = true) => async (dispatch: Dispatch<BannerActionsTypes>) => {
  try {
    dispatch({
      type: SETTINGS_LOADING,
      payload: 'UPLOADING'
    })
    var bodyFormData = new FormData();
    file && bodyFormData.append("file", file, file.name);
    bodyFormData.append("isOnlyVerification", String(isOnlyVerification));

    const configPOST = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (data:any) => {
        dispatch({
          type: SETTINGS_LOADING,
          payload: `PROCESSING`
        })
      },
    };
    const res = await axios.post(`${apiURL}/admin/registerItemByXlsx`, bodyFormData, configPOST);
    if (res.status === 200) {
      if (isOnlyVerification) {
        let resObj = {
          itemList: res.data.data[0].itemList,
          vendorId: res.data.data[0].vendorId,
          vendorName: res.data.data[0].vendorName 
        };
        dispatch({
          type: VALIDATION_ITEMS_BY_XLSX,
          payload: resObj
        })
      } else {
        dispatch({
          type: REGISTER_ITEMS_BY_XLSX,
          payload: 'status.success-upload-file'
        })
      }
    } else {
      dispatch({
        type: SETTINGS_FAIL_DETAIL,
        payload: res.data
      })
      dispatch({
        type: SETTINGS_FAIL,
        payload: 'status.create-error'
      })
    }
    return res.data;

  } catch (e: any) {
    dispatch({
      type: SETTINGS_FAIL_DETAIL,
      payload: (e.response !== undefined) ? e.response : e.message
    })
    dispatch({
      type: SETTINGS_FAIL,
      payload: 'status.create-error'
    })
  }
};

/**
 * Get a list of files names from the BE.
 */
export const getAllUploadedExcels = () => async (dispatch: Dispatch<BannerActionsTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: SETTINGS_LOADING,
        payload: 'EXCELS_LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      const requestPath = `${apiURL}/admin/getAllUploadedExcel`;

      const res = await axios.get(requestPath, {
        headers: headers
      });

      if (res.status === 200) {
        const queryData = res.data.data;

        dispatch({
          type: GET_ALL_XLSX_FILES,
          payload: queryData
        })
      } else {
        dispatch({
          type: SETTINGS_FAIL,
          payload: 'status.get-error'
        })
      }
    } catch (e) {
      dispatch({
        type: SETTINGS_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: SETTINGS_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Get a excel file from the BE.
 * @param {string} excelName
 */
export const getUploadedExcelData = (excelName:string) => async (dispatch: Dispatch<BannerActionsTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: SETTINGS_LOADING,
        payload: 'EXCEL_FILE'
      })

      const headers: object = {
        ContentType: 'application/octet-stream',
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        Authorization: 'Bearer ' + token
      }

      const requestPath = `${apiURL}/admin/getUploadedExcelData`;

      const res = await axios.get(requestPath, {
        params: { excelName: excelName },
        responseType: 'blob',
        headers: headers
      });

      if (res.status === 200) {
        var blob = new Blob([res.data]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = excelName;
        link.click();

        dispatch({
          type: GET_XLSX_FILE_DATA,
          payload: 'status.success-get-file'
        })
      } else {
        dispatch({
          type: SETTINGS_FAIL,
          payload: 'status.get-error'
        })
      }
    } catch (e) {
      dispatch({
        type: SETTINGS_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: SETTINGS_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Get a list of extra variables from the BE.
 */
export const getAllExtraVariables = () => async (dispatch: Dispatch<BannerActionsTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: SETTINGS_LOADING,
        payload: 'LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      const requestPath = `${apiURL}/admin/getAllExtraVariables`;

      const res = await axios.get(requestPath, {
        headers: headers
      });

      if (res.status === 200) {
        const queryData = res.data.data.content;
        const variables: any = [];

        for (var i = 0; i < queryData.length; i++) {
          const variable = queryData[i];

          variables.push({
            id: variable.id,
            name: variable.name,
            value: variable.value
          });
        }

        dispatch({
          type: GET_VARIABLES,
          payload: variables
        })
      } else {
        dispatch({
          type: SETTINGS_FAIL,
          payload: 'status.get-error'
        })
      }
    } catch (e) {
      dispatch({
        type: SETTINGS_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: SETTINGS_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * CUpdate a featured box on the BE.
 * @param {number} id
 * @param {string} value
 */
export const updateExtraVariable = (id: number, value: string) => async (dispatch: Dispatch<BannerActionsTypes>) => {
  try {
    dispatch({
      type: SETTINGS_LOADING,
      payload: 'UPDATE_VARIABLE'
    })

    const bodyForm = {
      "id": id,
      "value": value
    };

    const configPOST = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    };
    const res = await axios.put(`${apiURL}/admin/updateExtraVariable`, bodyForm, configPOST);
    if (res.status === 200) {
      dispatch({
        type: UPDATE_VARIABLES,
        payload: 'status.success-update'
      })
    } else {
      dispatch({
        type: SETTINGS_FAIL,
        payload: 'status.error-update'
      })
    }

  } catch (e) {
    dispatch({
      type: SETTINGS_FAIL,
      payload: 'status.error-update'
    })
  }
};


/**
 * CUpdate a single extra variable
 * @param {number} id
 * @param {string} value
 */export const updateSingleExtraVariable = (id: number, value: string) => async (dispatch: Dispatch<BannerActionsTypes>) => {
  try {
    dispatch({
      type: SETTINGS_LOADING,
      payload: 'UPDATE_VARIABLE'
    })

    const bodyForm = {
      "id": id,
      "value": value
    };

    const configPOST = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    };
    const res = await axios.put(`${apiURL}/admin/updateExtraVariable`, bodyForm, configPOST);
    if (res.status === 200) {
      dispatch({
        type: GET_VARIABLES,
        payload:[res.data.data]
      })
      dispatch({
        type: UPDATE_VARIABLES,
        payload: 'status.success-update'
      })
    } else {
      dispatch({
        type: SETTINGS_FAIL,
        payload: 'status.error-update'
      })
    }

  } catch (e) {
    dispatch({
      type: SETTINGS_FAIL,
      payload: 'status.error-update'
    })
  }
};

/**
 * Delete a given OrderConfirmationEntity.
 * @param {number} id
 */
export const deleteOrderConfirmation = (id: number) => async (dispatch: Dispatch<BannerActionsTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: SETTINGS_LOADING,
        payload: 'DELETE_ORDER_CONFIRMATION'
      })

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };

      const deleteRes = await axios.delete(`${apiURL}/admin/deleteCustomOrderConfirmation/${id}`, configPUT);

      if (deleteRes.status === 200) {
        dispatch({
          type: DELETE_ORDER_CONFIRMATION,
          payload: 'status.success-delete-item'
        })
      }

    } catch (e) {
      dispatch({
        type: SETTINGS_FAIL,
        payload: 'status.delete-error-item'
      })
    }
  } else {
    dispatch({
      type: SETTINGS_FAIL,
      payload: 'status.delete-error-item'
    })
  }
};

/**
 * Create a new OrderConfirmationEntity on the BE.
 * @param {OrderConfirmation} orderConfirmation
 */
export const registerOrderConfirmation = (orderConfirmation: OrderConfirmation) => async (dispatch: Dispatch<BannerActionsTypes>) => {
  try {
    dispatch({
      type: SETTINGS_LOADING,
      payload: 'ADD_ORDER_CONFIRMATION'
    })

    const configPOST = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    };
    const res = await axios.post(`${apiURL}/admin/registerCustomOrderConfirmation`, orderConfirmation, configPOST);
    if (res.status === 200) {
      dispatch({
        type: CREATE_ORDER_CONFIRMATION,
        payload: 'status.success-create'
      })
    } else {
      dispatch({
        type: SETTINGS_FAIL,
        payload: 'status.create-error'
      })
    }

  } catch (e) {
    dispatch({
      type: SETTINGS_FAIL,
      payload: 'status.create-error'
    })
  }
};

/**
 * Update an OrderConfirmationEntity on the BE.
 * @param {OrderConfirmation} orderConfirmation
 */
export const updateOrderConfirmation = (orderConfirmation: OrderConfirmation) => async (dispatch: Dispatch<BannerActionsTypes>) => {
  try {
    dispatch({
      type: SETTINGS_LOADING,
      payload: 'UPDATE_ORDER_CONFIRMATION'
    })

    const configPOST = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    };

    const res = await axios.put(`${apiURL}/admin/updateCustomOrderConfirmation`, orderConfirmation, configPOST);
    if (res.status === 200) {
      dispatch({
        type: UPDATE_ORDER_CONFIRMATION,
        payload: 'status.success-update'
      })
    } else {
      dispatch({
        type: SETTINGS_FAIL,
        payload: 'status.update-error'
      })
    }

  } catch (e) {
    dispatch({
      type: SETTINGS_FAIL,
      payload: 'status.update-error'
    })
  }
};

/**
 * Get a list of OrderConfirmations from the BE.
 */
export const getOrderConfirmations = () => async (dispatch: Dispatch<BannerActionsTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: SETTINGS_LOADING,
        payload: 'LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }

      const res = await axios.get(`${apiURL}/user/getAllCustomOrderConfirmation`, {
        headers: headers
      });

      if (res.status === 200) {
        dispatch({
          type: GET_ORDER_CONFIRMATIONS,
          payload: res.data.data
        })
      } else {
        dispatch({
          type: SETTINGS_FAIL,
          payload: 'status.get-error'
        })
      }
    } catch (e) {
      dispatch({
        type: SETTINGS_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: SETTINGS_FAIL,
      payload: 'status.get-error'
    })
  }
};
