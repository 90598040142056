/**
*****************************************
*********** Dispatch Types **************
*****************************************
*/
export const LOG_LOADING = "LOG_LOADING";
export const LOG_FAIL = "LOG_FAIL";
export const LOG_LIST = "LOG_LIST";
export const LOG_REFRESH = "LOG_REFRESH";
export const LOG_SET_FILTER = "LOG_SET_FILTER";
export const GET_CHANGE_LOGS_TYPES = "GET_CHANGE_LOGS_TYPES";

/**
*****************************************
************* Object Types **************
*****************************************
*/
export interface Log {
  additionalInfo: string;
  creationDate: string,
  customMessage: string,
  id: number,
  newValue: string,
  objectAttributeName: string,
  objectId: string,
  objectType: string,
  oldValue: string,
  user: string
}

export type LogsList = {
  first: boolean
  last: boolean
  number: number
  size: number
  totalPages: number
  logs: Log[]
  totalElements: number
};

/**
*****************************************
*********** Interfaces Types ************
*****************************************
*/

export interface LogRefresh {
  type: typeof LOG_REFRESH,
  payload: boolean
};

export interface LogSetFilter {
  type: typeof LOG_SET_FILTER,
  payload: object
};

export interface LogLoading {
  type: typeof LOG_LOADING
  payload: string
}

export interface LogFail {
  type: typeof LOG_FAIL,
  payload: string
}

export interface LogList {
  type: typeof LOG_LIST,
  payload: LogsList
}

export interface ChangeLogTypesList {
  type: typeof GET_CHANGE_LOGS_TYPES,
  payload: string[]
}

export type LogDispatchTypes = LogRefresh | LogSetFilter | LogLoading | LogFail | LogLoading | LogList | ChangeLogTypesList;