// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'white',
      padding: theme.spacing(1),
      '& .MuiTypography-root': {
        minHeight: '64px',
        textAlign: 'center',
        fontFamily: 'VisbyCF-Bold',
        fontWeight: 'bold'
      }
    },
    imgWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      minHeight: '316px',
      '& a': {
        width: 'calc(50% - 4px)',
        height: '150px',
        marginBottom: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
          height: '140px'
        },
      },
      '& img': {
        width: '100%',
        height: 'auto',
        maxHeight: '150px',
        objectFit: 'contain',
        [theme.breakpoints.down('xs')]: {
          maxHeight: '140px',
        },
      },
      '&.imgSingleColumn': {
        '& a': {
          width: '100%',
          height: '308px',
          [theme.breakpoints.down('xs')]: {
            height: '228px'
          },
        },
        '& img': {
          maxHeight: '228px',
          [theme.breakpoints.down('xs')]: {
            maxHeight: '228px',
          },
        },
      }
    }
  }),
);

export default useStyles;
