// React.
import React, { useState, useEffect } from 'react';

// Components.
import TextMaskPhone from '../utils/TextMaskPhone';

// Material UI.
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, Modal, Paper, Typography } from '@material-ui/core';

// Redux
import {useDispatch, useSelector} from "react-redux";
import { getUsers } from "../../../actions/userActions/UserActions";
import { registerAnonymousPlayer, updateEventPlayer } from '../../../actions/eventActions/eventActions';
import {RootStore} from "../../../Store";

// Global Types.
import { PlayerType } from '../models/ModelTypes';

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './RegisterAnonymousPlayerFormStyles';



interface PlayerEditProps {
  player?: PlayerType;
};
const RegisterAnonymousPlayerForm = ({ player } : PlayerEditProps) => {
  const [t] = useTranslation('global');
  const classes = useStyles();

  // Default stage object.
  let defaulUser:PlayerType = player? player : { 
    id: 0, 
    email: '', 
    firstName: '', 
    lastName: '', 
    phoneNumber: ''
  };

  // Redux state.
  const dispatch = useDispatch();
  const reduxState = useSelector((state: RootStore) => state.user);
  const reduxEventState:any = useSelector((state: RootStore) => state.events);
  
  const loading = reduxEventState.loading;
  const existingUser = reduxState.userList;

  // Local component state.
  const [userState, setUserState] = useState<PlayerType>(defaulUser);
  const [errorArray, setErrorArray] = useState<Array<string>>([]);
  const [saveTriggered, setSaveTriggered] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(()=>{
    if (loading === '' && saveTriggered) {
      setSaveTriggered(false);
      saveOnClick();
    }
  // eslint-disable-next-line
  },[loading, saveTriggered])

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof userState;
    var inputValue = String(event.target.value);

    if (name === 'firstName' || name === 'lastName') {
      inputValue = inputValue.replace(/[\d,-,!,@,#,$,%,^,&,*,(,),-,_,=,+,:,;,",',?,{,}]/g, '');
    }
    
    setUserState({
      ...userState,
      [name]: inputValue,
    });

    // Validate and add errors.
    const errors = [...errorArray];
    const nameString = '' + [name]
    if (event.target.value === '' && name !== 'phoneNumber') {
      errors.push(nameString);
      setErrorArray(errors);
    } else {
      var newErrors = errors.filter(function(value){ 
        return value !== nameString;
      });
      if (nameString === 'email') {
        newErrors = newErrors.filter(function(value){ 
          return value !== 'taken-email';
        });
      }
      setErrorArray(newErrors);
    }
  };

  const validateFields = () => {
    var result = true;
    const errors = [...errorArray];
    if (userState.firstName === '') {
      errors.push('firstName');
      result = false;
    }
    if (userState.lastName === '') {
      errors.push('lastName');
      result = false;
    }
    if (userState.email === '') {
      errors.push('email');
      result = false;
    } else {
      if (userState.email !== undefined) {
        // eslint-disable-next-line
        let emailMask  = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/); 
        if (!emailMask.test(userState.email)) { 
          errors.push('email');
          result = false;  
        }
      }
      if (existingUser !== undefined && existingUser!.users.length > 0) {
        errors.push('taken-email');
        result = false;  
      }
    }
    if (userState.phoneNumber === '') {
    } else {
      if (userState.phoneNumber !== undefined) {
        let phoneMask  = new RegExp(/\(?([0-9]{4})\)?([ ]?)([0-9]{4})/);
        if (!phoneMask.test(userState.phoneNumber)) {
          errors.push('phoneNumber');
          result = false;
        }
      }
    }
    
    setErrorArray(errors);

    return result;
  };

  const handleChangeValidation = (inputName: string) => {
    var result = false;
    errorArray.forEach((key) => {
      if (key === inputName) {
        result = true;
      }
    });

    return result;
  };

  const getErrorMessageEmail = () => {
    var message = '';
    if (handleChangeValidation('email')) {
      message = t('users.field-required-email');
    }
    if ( handleChangeValidation('taken-email')) {
      message = t('users.email-taken');
    }
    return message;
  };

  const validateEmailOnDB = () => {
      // See if the email is already used.
      if (userState.email !== '') {
        const qParams = {
          email: userState.email
        };
        dispatch(getUsers(qParams));
      }
  };

  const saveOnClick = () => {
    // Save the user on the BE.
    if (loading === '' ) {
      const validation = validateFields();
      if (errorArray.length === 0 && validation) {
        //Update current user.
        dispatch(updateEventPlayer(userState, userState.id.toString()));
      }
    } else {
      setSaveTriggered(true);
    }
  };

  const registerPlayerOnClick = () => {
    // Save the user on the BE.
    if (loading === '' ) {
      const validation = validateFields();
      if (errorArray.length === 0 && validation) {
        //Register user current user.
        dispatch(registerAnonymousPlayer(userState.id.toString()));
        handleCloseModal();
      }
    } else {
      setSaveTriggered(true);
    }
  }

  const openModalregisterPlayerOnClick = () => {
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <form noValidate autoComplete="off" className={`add-form`}>
      <CssBaseline />
      <div className="left-side">
        <TextField 
          id="user-email" 
          name="email"
          type="email"
          value={ userState.email } 
          label={ t('addUserForm.email-placeholder') }
          color="primary" 
          fullWidth
          autoFocus
          onChange={ handleChange }
          onBlur={ validateEmailOnDB }
          error={ handleChangeValidation('email') || handleChangeValidation('taken-email') }
          helperText={ getErrorMessageEmail() }
          className={ classes.fieldInput }
        />
        <TextField 
          id="user-name" 
          name="firstName"
          value={ userState.firstName } 
          label={ t('addUserForm.user-name') }  
          color="primary" 
          fullWidth 
          onChange={ handleChange } 
          error={ handleChangeValidation('firstName') }
          helperText={handleChangeValidation('firstName') ? t('users.field-required'): ''}
          className={ classes.fieldInput }
        />
        <TextField 
          id="user-lastname" 
          name="lastName"
          value={ userState.lastName } 
          label={ t('addUserForm.user-lastname') }  
          color="primary" 
          fullWidth 
          onChange={ handleChange }
          error={handleChangeValidation('lastName')}
          helperText={handleChangeValidation('lastName') ? t('users.field-required') : ''}
          className={ classes.fieldInput }
        />
        <FormControl className={ classes.formControl }>
          <InputLabel htmlFor="formatted-text-mask-input">{ t('addUserForm.user-phone') }</InputLabel>
          <Input
            name="phoneNumber"
            value={ userState.phoneNumber } 
            onChange={ handleChange }
            id="formatted-text-mask-input"
            inputComponent={TextMaskPhone as any}
            color="primary" 
            fullWidth
            error={ handleChangeValidation('phoneNumber') }
            className={ classes.fieldInput }
          />
        </FormControl>
      </div>
      <div className="right-side">
        
      </div>
      <div className={classes.actionsWrapper + ` form-controls` }>
        { loading === 'ADD_USER' && <CircularProgress /> }
        <Button variant="contained" color="primary" onClick={ saveOnClick } disabled={errorArray.length !== 0}> { t('players.update') } </Button>
        <Button variant="outlined" color="primary" onClick={ openModalregisterPlayerOnClick } disabled={errorArray.length !== 0 || (defaulUser.email === '' ||  defaulUser.firstName === '' || defaulUser.lastName === '')}>{ t('players.register-anonymous') }</Button>
      </div>
      <Modal
        open={ openModal }
        onClose={ handleCloseModal }
        aria-labelledby="Confirm Modal"
        >
        <Paper elevation={3} className={ classes.modalPaper + ` ` + classes.modalPaperConfirm }>
          <IconButton className={ classes.closeModal } aria-label="close modal" component="span" onClick={ () => handleCloseModal() }>
            <CloseIcon/>
          </IconButton>
          <Typography>{ t('players.register-anonymus-confirm-text') }</Typography>
          <Button variant="contained" color="primary" onClick={ () => handleCloseModal() }> { t('players.no') } </Button>
          <Button variant="contained" color="secondary" onClick={ () => registerPlayerOnClick() }> { t('players.yes') } </Button>
        </Paper>
      </Modal>
    </form>
  )
}

export default RegisterAnonymousPlayerForm;
