// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignContent: 'space-around',
      height: '100%',
      '& > *': {
        margin: theme.spacing(2, 1, 2, 4),
        padding: theme.spacing(4, 4, 4),
        width: '100%',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2, 2, 2),
          margin: theme.spacing(2, 1),
        },
      },
      '& > .MuiSvgIcon-colorPrimary': {
        color: theme.palette.primary.main,
        cursor: 'pointer'
      },
    },
    rootTotal: {
      '& > *': {
        margin: theme.spacing(2, 4, 2, 1),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2, 2, 2),
          margin: theme.spacing(2, 1),
        },
      },
    },
    sectionTitle: {
      flexGrow: 1,
      marginBottom: theme.spacing(2),
      textTransform: 'uppercase',
      textAlign: 'center',
      borderBottom: '2px solid'
    },
    formControl: {
      display: 'block',
      '& > *': {
        display: 'block',
      }
    },
    formField: {
      width: '100%',
      '& .Mui-disabled:before': {
        borderBottom: 0
      },
      '&.Mui-disabled:before': {
        borderBottom: 0
      },
      '& svg': {
        display: 'none'
      },
      '& > div': {
        width: '100%',
      },
    },
    actionsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: '22px',
      '& button': {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          marginLeft: theme.spacing(1),
        },
      }
    },
    actionsWrapperFull: {
      width: '100%',
      '& button': {
        width: '100%',
      }
    },
    quantityWrapper: {
      display: 'flex',
      alignItems: 'center',
      margin: `auto auto ${theme.spacing(2)}px auto`,
      maxWidth: '220px',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        maxWidth: '300px',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        display: 'block',
        '& .MuiInputBase-root:before': {
          display: 'none'
        }
      },
      '& .totalQty': {
        backgroundColor: theme.palette.grey[100],
        padding: '6px 9px',
        width: '50%',
      }
    },
    inputQty: {
      border: `1px solid ${theme.palette.grey[300]}`,
      padding: '0 3px',
      width: '58px',
      margin: '0 auto',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '38px',
      },
      '& .MuiSelect-icon': {
        right: '-3px'
      },
      '& .MuiSelect-select': {
        paddingRight: '8px'
      }
    },
    paperAlign: {
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'space-between',
    },
    modalPaper: {
      padding: theme.spacing(6, 4),
      position: 'absolute',
      width: '400px',
      height: 'auto',
      top: 'calc(30% - 100px)',
      left: 'calc(50% - 200px)',
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(6, 2),
        width: '90%',
        left: '5%'
      },
      '& .MuiTypography-root': {
        marginBottom: theme.spacing(2),
      },
      '& .MuiButtonBase-root': {
        marginLeft: theme.spacing(2),
      },
      '& div > form': {
        padding: theme.spacing(0)
      },
      '& .MuiTypography-h5': {
        textAlign: 'center',
      }
    },
    modalPaperConfirm: {
      textAlign: 'center'
    },
    closeModal: {
      position: 'absolute',
      right: '0',
      top: '0'
    },
    circularProgress: {
      textAlign: 'center',
    },
    paddingBottom: {
      paddingBottom: theme.spacing(1)
    },
    totalAwards: {
      display: 'flex',
      alignItems: 'center',
      margin: `auto auto ${theme.spacing(2)}px auto`,
      maxWidth: '300px',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        display: 'block',
        '& .MuiInputBase-root:before': {
          display: 'none'
        }
      },
      '& .totalQty': {
        backgroundColor: theme.palette.grey[100],
        padding: '6px 9px',
        width: '50%',
      }
    },
    centerText: {
      display: 'block',
      textAlign: 'center',
    }
  }),
);

export default useStyles;