// Material UI
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
      "& > *": {
        margin: theme.spacing(1),
        width: "100%",
        height: "auto",
      },
    },
    filters: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },

    },
  })
)

export default useStyles
