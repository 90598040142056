// React.
import React, {Fragment} from 'react';

// Material UI.
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

// Components.
import FeaturedCard from './FeaturedCard';

// Redux
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";

// Styles.
import useStyles from './FeaturedCardsStyles';

const FeaturedCards = () => {
  const classes = useStyles();

  // Redux
  const settingsState = useSelector((state: RootStore) => state.settings);
  const { homepageData } = settingsState;

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container spacing={3}>
        {homepageData !== undefined && 
          (homepageData?.featureBoxes?.map((box, index:number) => 
          <Fragment key={index}>
            {!box.disable && 
              <Grid 
              key={index} item 
              xs={12} 
              sm={6}
              md={4}
                  lg={homepageData?.featureBoxes?.length > 6 ? 3 : 4}> 
                <FeaturedCard box={box}/> 
              </Grid>
            }
          </Fragment>
        ))}
      </Grid>
    </Container>
  )
}

export default FeaturedCards;
