// React.
import React, {useEffect, useState, Fragment} from 'react';

// Material UI.
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

// Components.
import NumberFormat from '../../admin/utils/NumberFormat';

// Redux
import { useDispatch } from "react-redux";
import { addBundleToCart, deleteBundleFromCart, deleteItemToCartLocal, addItemToCartLocal } from "../../../actions/cartActions/CartActions";

// Styles.
import useStyles from './BundleCartStyles';
import defaultImage from '../../../images/default-img.jpg'; 

// Translations.
import { useTranslation } from 'react-i18next';

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

interface BundleCartProps {
  cartBundle: any;
};
const BundleCart = ({ cartBundle } : BundleCartProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();

  // Validate if the item is still available.
  useEffect(() => {
    if (cartBundle.quantity < 1) {
      deleteCartItem(cartBundle.bundle.id, '0');
    }
  // eslint-disable-next-line
  }, [])

  const getNormalQuantity = () => {
    return cartBundle.quantity;
  };

  const [quantity, setQuantity] = useState(getNormalQuantity());
  const [deleteQuantity, setDeleteQuantity] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setQuantity(getNormalQuantity());

  // eslint-disable-next-line
  }, [cartBundle])

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = (deleteQty:number) => {
    setOpenModal(true);
    setDeleteQuantity(deleteQty);
  }
  
  const handleChangeSelect = (event: React.ChangeEvent<{ value: unknown }>, item: any) => {
    let value = event.target.value as string;
    setQuantity(value);

    // Update cart on BE.
    let totalQty = Number(value);
    updateCartItem(item, totalQty);
  };

  const updateCartItem = (bundle: any, qty: number) => {
    if (qty > 0) {
      let newQty = qty - cartBundle.quantity;
      if (localStorage.token) {
        // If user is logged.
        dispatch(addBundleToCart(bundle.id, String(newQty)));
      } else {
        // If user is anonimous.
        var cart = localStorage.anonymousBundleCart !== undefined ? JSON.parse(localStorage.anonymousBundleCart) : [];
        // validate if already exists.
        const index = cart.findIndex((obj:any) => obj.bundle.id === bundle.id);
        if (index !== -1) {
          cart[index] = { bundle: bundle, quantity: qty };
        } else {
          cart.push({ bundle: bundle, quantity: qty });
        }
        localStorage.removeItem('anonymousBundleCart');
        localStorage.setItem('anonymousBundleCart', JSON.stringify(cart));
        dispatch(addItemToCartLocal());
      }
    }
  };

  const deleteCartItem = (bundleId:string, quantity:string) => {
    if (localStorage.token) {
      // If user is logged.
      dispatch(deleteBundleFromCart(bundleId, quantity));
    } else {
      // If user is anonimous.
      var cart = localStorage.anonymousBundleCart !== undefined ? JSON.parse(localStorage.anonymousBundleCart) : [];
      const index = cart.findIndex((obj:any) => obj.bundle.id === bundleId);
      const currentQty = cart[index].quantity;
      const diff = currentQty - Number(quantity);
      if (diff < 1) {
        cart = cart.filter((item:any) => item.bundle.id !== bundleId);
      } else {
        cart[index].quantity = diff;
      }
      localStorage.removeItem('anonymousBundleCart');
      localStorage.setItem('anonymousBundleCart', JSON.stringify(cart));
      dispatch(deleteItemToCartLocal());
    }
    handleCloseModal();
  };

  // Quantities to render.
  const items:any = [];
  let totalQty = cartBundle.bundle.totalQuantity;
  for(var i = 1; i <= totalQty; i++) {
    items.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
  }


  const getTotalPerRow = (quantity:number, hasDiscount:boolean = false) => {
    return cartBundle.bundle.priceWithTaxes * quantity;
  };

  return (
    <Fragment>
      {quantity > 0 &&
        <Card className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={2} md={2} className={classes.colImg}>
              <Avatar variant="rounded" alt="" src={cartBundle.bundle.images[0] !== undefined ? cartBundle.bundle.images[0].url : defaultImage}/>
            </Grid>
            <Grid item xs={10} sm={8} md={8} className={classes.customPadding}>
              <Typography variant="caption" color="textSecondary">
                { cartBundle.bundle.name } <span className={classes.type}>{ t('bundles.bundle') }</span>
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={3}>
                  <FormControl fullWidth>
                    <InputLabel id="select-label">{ t('bundles.quantity') }</InputLabel>
                    <Select
                      labelId="select-label"
                      id="quantity-select"
                      value={ quantity }
                      label={ t('bundles.quantity') }
                      onChange={ (e) => handleChangeSelect(e, cartBundle.bundle) }
                      className={ classes.select }
                    >
                      { items }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} md={4}>
                  <Typography variant="caption" color="textSecondary">{ t('items.seller') }</Typography>
                  <div>{ cartBundle.bundle.vendor.name }</div>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <Typography variant="caption" color="textSecondary">{ t('cart.init-price') }</Typography>
                  <div>
                    <Typography color="primary" className={classes.priceCol}>
                      {getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={cartBundle.bundle.priceWithTaxes}/>}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Typography variant="caption" color="textSecondary">Total</Typography>
                  <Typography color="primary" className={classes.priceCol}>
                    {getProjectConfig().CURRENCY_SYMBOL}<NumberFormat number={ getTotalPerRow(quantity) }/>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={2} className={classes.colButton}>
              <IconButton color="primary" aria-label="Delete item from cart" component="span" onClick={ () => handleOpenModal(quantity) }>
                <DeleteIcon color="secondary"/>
              </IconButton>
            </Grid>
          </Grid>
        </Card>
      }
      <Modal
        open={ openModal }
        onClose={ handleCloseModal }
        aria-labelledby="Confirm Modal"
        >
        <Paper elevation={3} className={ classes.modalPaper }>
          <IconButton className={ classes.closeModal } aria-label="close modal" component="span" onClick={ () => handleCloseModal() }>
            <CloseIcon/>
          </IconButton>
          <Typography>{ t('cart.delete-confirm-text', { 'itemName': cartBundle.bundle.name }) }</Typography>
          <Button variant="contained" color="primary" onClick={ () => handleCloseModal() }> { t('cart.no') } </Button>
          <Button variant="contained" color="secondary" onClick={ () => deleteCartItem(cartBundle.bundle.id, String(deleteQuantity)) }> { t('cart.yes') } </Button>
        </Paper>
      </Modal>
  </Fragment>
  )
}

export default BundleCart;
