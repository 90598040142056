// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      padding: theme.spacing(3, 6, 2),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3, 2, 2),
      },
      '& form': {
        padding: '0',
        width: '100%'
      },
      '& .MuiFormControl-root': {
        width: '100%',
        marginBottom: theme.spacing(2),
      },
      '& .MuiTypography-h5': {
        marginBottom: theme.spacing(2),
      },
      '& a': {
        color: theme.palette.primary.main
      },
      '& .MuiButton-text': {
        marginLeft: '0 !important'
      }
    },
    formControls: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(2),
      '& button': {
        marginLeft: '15px',
        [theme.breakpoints.down('xs')]: {
          marginLeft: 0
        },
      }
    },
  }),
);

export default useStyles;
