// React.
import React from 'react';
import { Link } from 'react-router-dom';


// Metarial UI.
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

// Styles.
import useStyles from './ResultsPaginationStyles';

// Component interface.
interface PaginationProps {
  path: string;
  pagesNumber: number;
  current?: number;
};

const ResultsPagination = ({path, pagesNumber, current} : PaginationProps) => {
  const classes = useStyles();
  const params = new URLSearchParams(window.location.search);
  let key = params.get('key');
  let navAction = params.get('navAction');
  let subNavAction = params.get('subNavAction');
  let onlyBundles = params.get('onlyBundles');
  let labelValueId = params.get('labelValueId');
  let itemTypeId = params.get('itemTypeId');
  let sort = params.get('sort');
  let category = params.get('category');
  let itemTypeIds = params.get('itemTypeIds');
  let labelValuesIds = params.get('labelValuesIds');
  let isDiscount = params.get('isDiscount');
  let status = params.get('status');
  const getUrlParameters = (item:any) => {
    var result = '';
    if (item.page > 1) {
      if(status){
        result += `?status=${status}&page=${ item.page }`
      } else {
        result += `?page=${ item.page }`;
        if (key) {
          result += `&key=${ key }`;
        }
        if (sort) {
          result += `&sort=${sort}`;
        }
        if (navAction) {
          result += `&navAction=${navAction }`;
        }
        if (subNavAction) {
          result += `&subNavAction=${subNavAction}`;
        }
        if (labelValueId) {
          result += `&labelValueId=${labelValueId}`;
        }
        if (itemTypeId) {
          result += `&itemTypeId=${itemTypeId}`;
        }
        if (onlyBundles) {
          result += `&onlyBundles=true`;
        }
        if (category) {
          result += `&category=${category}`;
        }
        if (itemTypeIds) {
          result += `&itemTypeIds=${itemTypeIds}`;
        }
        if (labelValuesIds) {
          result += `&labelValuesIds=${labelValuesIds}`;
        }
        if (isDiscount) {
          result += `&isDiscount=${isDiscount}`;
        }
      }
    } else {
      if (key) {
        result += `?key=${ key }`;
      }
      if (sort) {
        result += result !== '' ? `&sort=${sort}` : `?sort=${sort}`;
      }
      if (navAction) {
        result += result !== '' ? `&navAction=${navAction}` : `?navAction=${navAction }`;
      }
      if (subNavAction) {
        result += result !== '' ? `&subNavAction=${subNavAction}` : `?subNavAction=${subNavAction}`;
      }
      if (labelValueId) {
        result += result !== '' ? `&labelValueId=${labelValueId}` : `?labelValueId=${labelValueId}`;
      }
      if (itemTypeId) {
        result += result !== '' ? `&itemTypeId=${itemTypeId}` : `?itemTypeId=${itemTypeId}`;
      }
      if (onlyBundles) {
        result += result !== '' ? `&onlyBundles=true` : `?onlyBundles=true`;
      }
      if (category) {
        result += result !== '' ? `&category=${category}` : `?category=${category}`;
      }
      if (itemTypeIds) {
        result += result !== '' ? `&itemTypeIds=${itemTypeIds}` : `?itemTypeIds=${itemTypeIds}`;
      }
      if (labelValuesIds) {
        result += result !== '' ? `&labelValuesIds=${labelValuesIds}` : `?labelValuesIds=${labelValuesIds}`;
      }
      if (isDiscount) {
        result += result !== '' ? `&isDiscount=${isDiscount}` : `?isDiscount=${isDiscount}`;
      }
    }
    return result;
  };

  return (
    <div className={classes.root}>
      <Pagination
        page={current}
        count={ pagesNumber }
        renderItem={(item) => (
          <PaginationItem
            component={ Link }
            to={ path + getUrlParameters(item) }
            { ...item }
          />
        )}
      />
    </div>
  );
}

export default ResultsPagination;