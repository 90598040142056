import React from 'react';
import logoImg from '../../../logo.png';

interface LogoProps {
  altLogo?: string | undefined;
}

const Logo = (props:LogoProps ): JSX.Element => {
  const logo = props.altLogo ?? logoImg;
  return (
    <div>
      <a href="/" aria-label="Inicio">
        <img src={ logo } alt="StoreFront Logo" width="230"/>
      </a>
    </div>
  )
}

export default Logo;
