import {Dispatch} from "redux";
import axios from 'axios';
import {
  ITEM_CATEGORY_FAIL,
  ITEM_CATEGORY_LOADING,
  ITEM_CATEGORY_LIST,
  ItemCategoryDispatchTypes,
  CategoryTypeList} from "./categoryActionsType";
import jwtDecode from "jwt-decode";

const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;

/**
 * Gets all item categories from BE.
 *
 */
export const getItemCategory = () => async (dispatch: Dispatch<ItemCategoryDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: ITEM_CATEGORY_LOADING,
        payload: 'LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }
      const decodedToken:any = jwtDecode(String(token));
      const pathRole = decodedToken.auth.includes('ROLE_ADMIN') ? 'admin' : 'seller'; 
      const res = await axios.get(`${apiURL}/${pathRole}/getAllItemCategories`, {
        headers: headers
       });

      if (res.status === 200) {
        const queryData = res.data.data;
        const categories: CategoryTypeList[] = [];
        for(var i = 0; i < queryData.length; i++) {
          categories.push({
            name: queryData[i]
          });
        }
        const data:any = [
          ...categories
        ];
        dispatch({
          type: ITEM_CATEGORY_LIST,
          payload: data
        })
      }else {
        dispatch({
          type: ITEM_CATEGORY_FAIL,
          payload: 'status.get-error'
        })
       }
    } catch(e) {
      dispatch({
        type: ITEM_CATEGORY_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: ITEM_CATEGORY_FAIL,
      payload: 'status.get-error'
    })
  }
};
