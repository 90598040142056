// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inOnDrag: {
      cursor: 'grabbing !important',
      '& *': {
        cursor: 'grabbing !important'
      }
    },
    rowMain: {
      backgroundColor: process.env.REACT_APP_THEME_COLOR_EXTRA_LIGHT_GRAY,
      borderRadius: '4px',
      paddingTop: 0,
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(1),
      '& form': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
          flexWrap: 'wrap'
        },
      }
    },
    dragWrapper: {
      '& .MuiListItemIcon-root': {
        [theme.breakpoints.down('xs')]: {
          display: 'initial'
        }
      }
    },
    leftContent: {
      width: '75%',
      padding: theme.spacing(1, 0),
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    rightContent: {
      width: '25%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    rowTitle: {
      width: '100%',
      marginBottom: theme.spacing(1),
      '& .MuiFormControl-root': {
        width: '90%',
        marginTop:  0,
      }
    },
    rowAddItems: {
      display: 'flex',
      flexWrap: 'wrap',
      '& .MuiAvatar-root': {
        marginRight:  theme.spacing(2),
      },
      '& .item-wrapper': {
        display: 'flex',
        alignItems: 'center',
        width: '45%',
        marginBottom: '5px',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
        '& .MuiTypography-root': {
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
          [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            marginRight: theme.spacing(1),
          },
        },
        '& .MuiAutocomplete-root': {
          flexGrow: 1,
          '& .MuiFormControl-root': {
            marginTop: 0,
            marginBottom: 0
          }
        }
      }     
    },
    bannerImg: {
      width: '45px',
      height: '45px',
      marginBottom:  0,
      [theme.breakpoints.down('xs')]: {
        marginBottom:  theme.spacing(1),
      },
    },
    marginBottom: {
      marginBottom:  theme.spacing(2),
    },
    deleteButton: {
      marginLeft:  theme.spacing(2),
      cursor: 'pointer',
      verticalAlign: 'middle'
    },
    bottomSection: {
      borderTop: '1px solid',
      borderColor: process.env.REACT_APP_THEME_COLOR_EXTRA_LIGHT_GRAY,
      margin:  theme.spacing(2, 0),
      textAlign: 'right',
      '& .MuiCircularProgress-root': {
        width: '20px !important',
        height: '20px !important',
        marginLeft: theme.spacing(1)
      }
    },
  }),
);

export default useStyles;
