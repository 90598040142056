// Material UI
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "16px",
      height: "100%",
    },
    cardTitle: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "200px",
      marginBottom: "4px",
      fontSize: "1rem",
      fontFamily: "VisbyCF-Bold"
    },
    typographySubTitle: {
      fontSize: "1rem",
    },
    typographySubTitleTotal: {
      fontSize: "1rem",
      fontFamily: "VisbyCF-Bold",
    },
    typographyValues: {
      fontSize: "1rem",
    },
    typographyValuesBold: {
      fontSize: "1rem",
      fontFamily: "VisbyCF-Bold",
    }
  })
);

export default useStyles;
