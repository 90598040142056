// React.
import { useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

// Analitycs
import ReactGA from 'react-ga';

// Components.
import AddBundle from "./components/admin/pages/AddBundle";
import AddItem from "./components/admin/pages/AddItem";
import AddOrder from './components/admin/pages/AddOrder';
import AddUser from './components/admin/pages/AddUser';
import AddVendor from './components/admin/pages/AddVendor';
import Admin from './components/admin/pages/Admin';
import AppVariablesSettingsPage from './components/admin/pages/AppVariablesSettingsPage';
import BulkItemsUpdate from './components/admin/pages/BulkItemsUpdate';
import Bundles from './components/admin/pages/Bundles';
import EventDetailPage from './components/admin/pages/EventDetailPage';
import FeaturedBoxSettingsPage from './components/admin/pages/FeaturedBoxSettingsPage';
import ItemXlsxSettingsPage from './components/admin/pages/ItemXlsxSettingsPage';
import Items from './components/admin/pages/Items';
import LabelsSettingsPage from './components/admin/pages/LabelsSettingsPage';
import Logs from './components/admin/pages/Logs';
import Navigation from './components/admin/pages/Navigation';
import NavigationIcons from './components/admin/pages/NavigationIcons';
import OrderConfirmationSettingsPage from './components/admin/pages/OrderConfirmationSettingsPage';
import Orders from './components/admin/pages/Orders';
import OrdersToBeDelivered from './components/admin/pages/OrdersToBeDelivered';
import PendingOrdersReport from './components/admin/pages/PendingOrdersReport';
import PrizePoolCalculator from './components/admin/pages/PrizePoolCalculator';
import Reports from './components/admin/pages/Reports';
import ReportsAssociate from './components/admin/pages/ReportsAssociate';
import SliderSettingsPage from './components/admin/pages/SliderSettingsPage';
import StorePoints from './components/admin/pages/StorePoints';
import TypeSettingsPage from './components/admin/pages/TypeSettingsPage';
import Users from './components/admin/pages/Users';
import Tournament from './components/admin/pages/tournament';
import Login from './components/authentication/Login';
import Register from './components/authentication/Register';
import ResetPasswordTemporal from './components/authentication/ResetPasswordTemporal';
import ValidateAccount from './components/authentication/ValidateAccount';
import AboutUs from './components/client/pages/AboutUs';
import BasePage from './components/client/pages/BasePage';
import BundleDetailPage from './components/client/pages/BundleDetailPage';
import Cart from './components/client/pages/Cart';
import Checkout from './components/client/pages/Checkout';
import CheckoutPaymeError from './components/client/pages/CheckoutPaymeError';
import CheckoutThanks from './components/client/pages/CheckoutThanks';
import Home from './components/client/pages/Home';
import ItemDetailPage from './components/client/pages/ItemDetailPage';
import ItemSearch from './components/client/pages/ItemSearch';
import ItemSearchVendor from './components/client/pages/ItemSearchVendor';
import UserOrders from './components/client/pages/Orders';
import UseTerms from './components/client/pages/UseTerms';
import User from './components/client/pages/User';
import WishList from './components/client/pages/WishList';
import AccessDenied from './components/errors/AccessDenied';
import NotFound from './components/errors/NotFound';

// Custom Routes.
import AdminRoute from './components/authentication/routes/AdminRoute';
import PrivateRoute from './components/authentication/routes/PrivateRoute';
import SellerRoute from './components/authentication/routes/SellerRoute';

// Redux
import { Provider } from "react-redux";
import store from "./Store";
import { CheckAuthentication } from './components/authentication/routes/CheckAuthentication';

// Translations.
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import global_en from './translations/en/global.json';
import global_es from './translations/es/global.json';

// CSS
import './App.css';

// Config.
import { getProjectConfig } from './getProjectConfig';

// Material UI
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Looker } from './components/admin/looker/Looker';
import { PointOfSaleOrder } from './components/admin/orders/PointOfSaleOrder';
import AdminLooker from './components/admin/pages/AdminLooker';
import CreateInventoryRequest from './components/admin/pages/CreateInventoryRequest';
import CreateSinglesTemplatePage from './components/admin/pages/CreateSinglesTemplatePage';
import InventoryRequests from './components/admin/pages/InventoryRequests';
import PrizeLabelsSettingsPage from './components/admin/pages/PrizeLabelsSettingsPage';
import Vendors from './components/admin/pages/Vendors';
import DeliveriesPage from './components/client/pages/DeliveriesPage';

// Define the theme settings.
const theme = createTheme({
  palette: {
    primary: {
      light: process.env.REACT_APP_THEME_COLOR_PRIMARY_LIGHT,
      main: `${process.env.REACT_APP_THEME_COLOR_PRIMARY_MAIN}`,
      dark: process.env.REACT_APP_THEME_COLOR_PRIMARY_DARK,
      contrastText: process.env.REACT_APP_THEME_COLOR_PRIMARY_TEXT,
    },
    secondary: {
      light: process.env.REACT_APP_THEME_COLOR_SECONDARY_LIGHT,
      main: `${process.env.REACT_APP_THEME_COLOR_SECONDARY_MAIN}`,
      dark: process.env.REACT_APP_THEME_COLOR_SECONDARY_DARK,
      contrastText: process.env.REACT_APP_THEME_COLOR_SECONDARY_TEXT,
    },
    text: {
      primary: process.env.REACT_APP_THEME_COLOR_PRIMARY_DARK,
      secondary: process.env.REACT_APP_THEME_COLOR_PRIMARY_DARK,
      disabled: process.env.REACT_APP_THEME_COLOR_EXTRA_MEDIUM_GRAY
    },
  },
  typography: {
    "fontFamily": `"VisbyCF", sans-serif`,
  }
});

// Initilize translations.
i18next.init({
  interpolation: { escapeValue: false },
  lng: getProjectConfig().LAN,
  resources: {
    es: {
      global: global_es
    },
    en: {
      global: global_en
    }
  }
});

const App = () => {
  // initialize Analitycs Tracking
  const TEMPgoogleAnalitycsTrackingId = 'UA-234004502-1';
  ReactGA.initialize(TEMPgoogleAnalitycsTrackingId);
  ReactGA.pageview(window.location.pathname + window.location.search);

  // Checks if the user is authenticated.
  useEffect(() => {
    CheckAuthentication();
  }, []);

  return (
    <Provider store={ store }>
      <I18nextProvider i18n={i18next}>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <SellerRoute exact path='/admin' component={Admin} />
              <AdminRoute exact path='/admin/users' component={() => <Admin content={ <Users/> } />} />
              <SellerRoute exact path='/admin/items' component={() => <Admin content={ <Items/> } />} />
              <SellerRoute exact path='/admin/orders' component={() => <Admin content={ <Orders/> } />} />
              <SellerRoute exact path='/admin/orders-to-be-delivered' strict={true} component={() => <Admin content={ <OrdersToBeDelivered />} />} />
              <AdminRoute exact path='/admin/bundles' component={() => <Admin content={ <Bundles/> } />} />
              <AdminRoute exact path='/admin/bulk-item-update' component={() => <Admin content={<BulkItemsUpdate />} />} />
              <AdminRoute exact path='/admin/add-user' component={() => <Admin content={ <AddUser/> } />} />
              <AdminRoute exact path='/admin/add-item' component={() => <Admin content={ <AddItem/> } />} />
              <AdminRoute exact path='/admin/add-bundle' component={() => <Admin content={ <AddBundle/> } />} />
              <SellerRoute exact path='/admin/add-order' strict={true} component={() => <Admin content={ <AddOrder/> } />} />
              <SellerRoute exact path='/admin/add-point-of-sale-order' strict={true} component={() => <Admin content={ <PointOfSaleOrder/> } />} />
              <AdminRoute exact path='/admin/add-vendor' component={() => <Admin content={<AddVendor/>} /> } />
              <AdminRoute exact path='/admin/vendors' component={() => <Admin content={<Vendors/>} /> } />
              <AdminRoute exact path='/admin/slider-settings' component={() => <Admin content={ <SliderSettingsPage/> } />} />
              <AdminRoute exact path='/admin/featured-boxes-settings' component={() => <Admin content={ <FeaturedBoxSettingsPage/> } />} />
              <AdminRoute exact path='/admin/manage-singles' component={() => <Admin content={<ItemXlsxSettingsPage />} />} />
              <AdminRoute exact path='/admin/app-variables-settings' component={() => <Admin content={<AppVariablesSettingsPage />} />} />
              <AdminRoute exact path='/admin/tag-manager' component={() => <Admin content={ <LabelsSettingsPage/> } />} />
              <SellerRoute exact path='/admin/user/details' component={() => <Admin content={ <User/> }/> } />
              <AdminRoute exact path='/admin/reports' component={() => <Admin content={ <Reports/> }/> } />
              <SellerRoute exact path='/admin/reports-associate' component={() => <Admin content={<ReportsAssociate />} />} />
              <AdminRoute exact path='/admin/reports-pending-orders' component={() => <Admin content={<PendingOrdersReport />} />} />
              <AdminRoute exact path='/admin/looker-analytics' component={() => <AdminLooker content={<Looker/> } />} />
              <AdminRoute exact path='/admin/type-settings' component={() => <Admin content={ <TypeSettingsPage/> } />} />
              <AdminRoute exact path='/admin/order-confirmation-settings' component={() => <Admin content={<OrderConfirmationSettingsPage />} />} />
              <AdminRoute exact path='/admin/logs' component={() => <Admin content={<Logs />} />} />
              <AdminRoute exact path='/admin/tournament-prizes' component={() => <Admin content={<PrizeLabelsSettingsPage />} />} />
              <AdminRoute exact path='/admin/manage-navigation' component={() => <Admin content={<Navigation />} />} />
              <AdminRoute exact path='/admin/manage-navigation/icons' component={() => <Admin content={<NavigationIcons />} />} />
              <AdminRoute exact path='/admin/store-points' component={() => <Admin content={<StorePoints />} />} />
              <AdminRoute exact path='/admin/create-singles-template' component={() => <Admin content={<CreateSinglesTemplatePage />} />} />
              <SellerRoute exact path='/admin/create-inventory-request' component={() => <Admin content={<CreateInventoryRequest />} />} />
              <SellerRoute exact path='/admin/inventory-requests' component={() => <Admin content={<InventoryRequests />} />} />
              <SellerRoute exact path='/admin/tournament/:id' component={() => <Admin content={ <EventDetailPage/> } />} />
              <SellerRoute exact path='/admin/tournament' component={() => <Admin content={<Tournament/>} />} />
              <SellerRoute exact path='/admin/tournament/prize-pool-calculator/:eventId' component={() => <Admin content={<PrizePoolCalculator/>} />} />
              <PrivateRoute exact path='/user/details' component={() => <BasePage content={ <User/> }/> } />
              <PrivateRoute exact path='/checkout-process' component={() => <BasePage content={ <Checkout/> }/> } />
              <PrivateRoute exact path='/checkout-thanks' component={() => <BasePage content={ <CheckoutThanks/> }/> } />
              <PrivateRoute exact path='/checkout-payment-issues' component={() => <BasePage content={ <CheckoutPaymeError/> }/> } />
              <PrivateRoute exact path='/user/orders' component={() => <BasePage content={ <UserOrders/> }/> } />
              <PrivateRoute exact path='/user/deliveries' component={() => <BasePage content={ <DeliveriesPage/> }/> } />
              <Route exact path='/' component={() => <BasePage content={ <Home/> }/> } />
              <Route exact path='/items' component={() => <BasePage content={ <ItemSearch/> }/> } />
              <Route exact path='/items/vendor/:vendorId/:vendorName' component={() => <BasePage content={<ItemSearchVendor />} />} />
              <Route exact path='/item/:code' component={() => <BasePage content={ <ItemDetailPage/> }/> } />
              <Route exact path='/bundle/:id' component={() => <BasePage content={ <BundleDetailPage/> }/> } />
              <Route exact path='/user/cart' component={() => <BasePage content={ <Cart/> }/> } />
              <Route exact path='/user/wishlists' component={() => <BasePage content={ <WishList/> }/> } />
              <Route exact path='/user/login' component={() => <Login />} />
              <Route exact path='/user/register' component={() => <Register />} />
              <Route exact path='/user/validate-account' component={() => <ValidateAccount />} />
              <Route exact path='/user/reset-password-temporal' component={() => <ResetPasswordTemporal />} />
              <Route exact path='/use-terms' component={() => <UseTerms />} />
              <Route exact path='/about-us' component={() => <AboutUs />} />

              <Route exact path='/error-403' component={() => <AccessDenied />} />
              <Route component={() => <NotFound />} />
            </Switch>
          </Router>
        </ThemeProvider>
      </I18nextProvider>
    </Provider>
  );
}

export default App;

