// React.
import React, {useEffect} from 'react';

// Material UI
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaymentIcon from '@material-ui/icons/Payment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// Redux.
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import { getAllPaymentMethods } from "../../../actions/userPaymentMethodsActions/UserPaymentMethodsActions";

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './AddOrderPaymentStyles';
 
interface AddOrderPaymentProps {
  setSelectedPayment: Function;
  selectedPayment: string;
};

const AddOrderPayment = ({setSelectedPayment, selectedPayment}: AddOrderPaymentProps) => {
  const [t] = useTranslation('global');
  const dispatch = useDispatch();
  const classes = useStyles();

    // Redux state.
    const paymentState = useSelector((state: RootStore) => state.paymentMethods);
    const paymentMethodsList = paymentState.paymentMethodsList;

  useEffect(() => {
    dispatch(getAllPaymentMethods());

    // eslint-disable-next-line
  }, []);
  
  return (
    <div className={ classes.root }>
    <Paper elevation={3}>
      <div className={ classes.boxTop }>
        <PaymentIcon color="primary" fontSize="large"/>
        <Typography variant="h5" color="primary">
          { t('orders.select-payment') }
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={12} className={classes.paymentMethodsWrapper}>
            {paymentMethodsList !== undefined && 
            paymentMethodsList.map((paymentMethod:string, index:number) =>
            <Card key={index} className={`${selectedPayment === paymentMethod ? 'selected' : ''}`} onClick={ () => setSelectedPayment(paymentMethod) }>
              <CardContent>
                <Typography>{paymentMethod}</Typography>
              </CardContent>
            </Card>
            )}
          </Grid>
        </Grid>
      </div>
    </Paper>
  </div>
  )
}

export default AddOrderPayment;