import React from 'react';
import useStyles from './TotalsInformationStyles';
import { Paper, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { getProjectConfig } from '../../getProjectConfig';
import { useTranslation } from 'react-i18next';

interface TotalsInformationProps {
    totalWeb?: number;
    totalSeller?: number;
    finalTotal?: number;
    totalPackage?: number;
}

const TotalsInformation = ({
    totalWeb,
    totalSeller,
    finalTotal,
    totalPackage
  }: TotalsInformationProps) => {
    const classes = useStyles();
    const [t] = useTranslation("global");
    return <Paper className={classes.paper}>
          <Typography className={classes.Typography}>
            <span className={classes.totalLabel}>
              {t("totals.webTotal")}
            </span>
            <NumberFormat
              value={totalWeb ?? 0}
              prefix={getProjectConfig().CURRENCY_SYMBOL}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Typography>
          <Typography className={classes.Typography}>
            <span className={classes.totalLabel}>
              {t("totals.deliveriesTotal")}
            </span>
            <NumberFormat
              value={totalPackage ?? 0}
              prefix={getProjectConfig().CURRENCY_SYMBOL}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Typography>
          <Typography className={classes.Typography}>
            <span className={classes.totalLabel}>
              {t("totals.storeTotal")}
            </span>
            <NumberFormat
              value={totalSeller ?? 0}
              prefix={getProjectConfig().CURRENCY_SYMBOL}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Typography>
          <Typography className={classes.Typography}>
            <span className={classes.totalLabel}>
              {t("totals.grandTotal")}
            </span>
            <NumberFormat
              value={finalTotal ?? 0}
              prefix={getProjectConfig().CURRENCY_SYMBOL}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Typography>
    </Paper>
  }


  export default TotalsInformation;
