// React.
import React from 'react';

// Material UI
import Paper from '@material-ui/core/Paper';
import PeopleIcon from '@material-ui/icons/People';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// Components.
import BundleAccordion from './BundleAccordion';

// Redux.
import {RootStore} from "../../../Store";
import {useSelector} from "react-redux";

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './BundleListStyles';

const BundleList = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');

  const bundleState = useSelector((state: RootStore) => state.bundle);
  const { loading } = bundleState;

  return (
    <div className={ classes.root }>
      <Paper elevation={3}>
        <div className={ classes.boxTop }>
          <PeopleIcon color="primary" fontSize="large"/>
          <Typography variant="h5" color="primary" className={ classes.sectionTitle }>
            { t('bundles.search-results') } {loading === 'LIST' && <CircularProgress/>}
          </Typography>
        </div>
        <div>
          <BundleAccordion />
        </div>
      </Paper>
    </div>
  )
}

export default BundleList;
