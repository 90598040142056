import {Dispatch} from "redux";
import axios from 'axios';
import { ADD_TO_WISHLIST, ADD_FROM_WISHLIST_FAIL, ADD_FROM_WISHLIST_LOADING, GET_WISHLIST, WishlistActionsTypes } from "./wishlistActionsTypes";

const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;


/**
 * Add an item to the cart.
 * @param {string} code
 * @param {string} quantity
 * @param {string} customReference
 */
export const addToWishList = (itemCode: string, customReference:string = 'NA', removeItem: boolean = false) => async (dispatch: Dispatch<WishlistActionsTypes>) => {
  if (apiURL !== '' && token !== '') {

    if(customReference === 'noreference'){
      dispatch({
        type: ADD_FROM_WISHLIST_FAIL,
        payload: 'wishlist.error-custom-reference'
      })
      return
    }
    let response:any;
    try {
      dispatch({
        type: ADD_FROM_WISHLIST_LOADING,
        payload: 'ADD_TO_WISHLIST'
      })

      const configPOST = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };

      const bodyForm = {
        "code": itemCode,
        "removeWishlistItem": removeItem,
        ...(customReference) && { "customReference": customReference }
      };

      response = await axios.put(`${apiURL}/user/manageItemToWishlist`,bodyForm, configPOST);
      if (response.status === 200) {
        if(removeItem){
          dispatch({
            type: ADD_TO_WISHLIST,
            payload: 'wishlist.delete-from-wishlist'
          })
        }else{
          dispatch({
            type: ADD_TO_WISHLIST,
            payload: 'wishlist.add-to-wishlist'
          })
        }
      } 
    } catch(e:any) { 
      if(e.response.status === 422){
        dispatch({
          type: ADD_FROM_WISHLIST_FAIL,
          payload: 'wishlist.add-to-wishlist-exists'
        })
      }else{
        dispatch({
          type: ADD_FROM_WISHLIST_FAIL,
          payload: 'wishlist.wishlist-error'
        })
      }
    }
  } else {
    dispatch({
      type: ADD_FROM_WISHLIST_FAIL,
      payload: 'wishlist.wishlist-error'
    })
  }
};

export const addToCartFromWishList = (code: string) => async (dispatch: Dispatch<WishlistActionsTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      dispatch({
        type: ADD_FROM_WISHLIST_LOADING,
        payload: 'ADD_TO_WISHLIST'
      })

      const configPOST = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };

      const response = await axios.post(`${apiURL}/user/addFromWishlist/${code}`,{}, configPOST);
      if (response.status === 200) {
        dispatch({
          type: ADD_TO_WISHLIST,
          payload: 'status.add-cart'
        })
      }
       
    } catch(e) {
      dispatch({
        type: ADD_FROM_WISHLIST_FAIL,
        payload: 'status.cart-errorr'
      })
    }
  } else {
    dispatch({
      type: ADD_FROM_WISHLIST_FAIL,
      payload: 'status.cart-error'
    })
  }

};

export const getWishlist = () => async (dispatch: Dispatch<WishlistActionsTypes>) => {
  if (apiURL !== '' && token !== '') {

    try {
      const configGET = {
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };

      const response = await axios.get(`${apiURL}/user/getWishList`, configGET);
      if (response.status === 200) {
        dispatch({
          type: GET_WISHLIST,
          payload: response.data.data
        })
      }
    } catch(e) {
      dispatch({
        type: ADD_FROM_WISHLIST_FAIL,
        payload: 'wishlist.error-getting-wishlist'
      })
    }
  } else {
    dispatch({
      type: ADD_FROM_WISHLIST_FAIL,
      payload: 'wishlist.error-getting-wishlist'
    })
  }
};
