// React.
import React, { Fragment, useEffect, useState } from 'react';

// Material UI.
import { Button, CircularProgress, Grid, IconButton, Modal, Paper, Switch, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

// Styles.
import useStyles from './PrizeTableStyles';

// Translations.
import { useTranslation } from 'react-i18next';

// Components.
import AssignPrizes from './AssignPrizes';

// Redux.
import { useDispatch, useSelector } from "react-redux";
import { updateEventPlayer } from '../../../actions/eventActions/eventActions';
import { getEventPrizeDetails } from '../../../actions/prizeActions/PrizeActions';
import { RootStore } from "../../../Store";

//Model.
import { AwardType, PrizeType } from '../../../actions/prizeActions/PrizeActionsTypes';

interface EventEditProps {
  eventId: string;
};

const PrizeTable = ({ eventId }: EventEditProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();

  // Redux state.
  const reduxPrizeState = useSelector((state: RootStore) => state.prize);
  const { eventPrizeDetails, loading } = reduxPrizeState;
  const reduxEventState = useSelector((state: RootStore) => state.events);
  const { actionStatus} = reduxEventState;

  // Define local state for the list of players.
  const defaultPrize:PrizeType[] = [{
    itemList: [],
    prizeName: '',
    realQuantity: 0,
    suggestedQuantity: 0
  }];
  const defaultPlayer:AwardType =  {
    anonymousPlayer: false,
    coinsToRedeem: 0,
    playerId: 0,
    playerName: '',
    position: 0,
    prizeList: defaultPrize,
    redeemCoins: false
  }
  // Local variables.
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [currentPlayer, setCurrentPlayer] = useState<AwardType>(defaultPlayer);
  
  useEffect(() => {  
    if (actionStatus !== undefined) {
      if (actionStatus.message === 'status.success-update' && actionStatus.status === 'success') {
        // Get the players list again.
        dispatch(getEventPrizeDetails(eventId));
      }   
    }

    // eslint-disable-next-line
  }, [actionStatus]);

  const convertCardinalToOrdinal = (numberCardinal: number) => {
    const plurals = new Intl.PluralRules('en-US', { type: 'ordinal' });

    let pluralMapping: any = {
      one:   "ero", //st.
      two:   "do",  //nd.
      few:   "ero", //rd.
      other: "o",   //th.
    }

    let getPlural = plurals.select(numberCardinal);
    let pluralEnding = pluralMapping[getPlural];
    
    return pluralEnding;
  }

  // The player can redeem OC if they have prizes with promo === false.
  const canRedeemOC = (prizeList: any) => {
    let output: boolean = false;
    if (eventPrizeDetails?.baseAwardsData) {
      eventPrizeDetails?.baseAwardsData.forEach((award: any, index: number) => {
        if (award.promo === false) {
          prizeList.forEach((prize: any) => {
            if ((prize.prizeName === award.prizeName) && prize.realQuantity > 0) {
              output = true;
            }
          });
        }
      });
    }

    return output;
  }

  const renderAwardsRows = (prizeList: any) => {
    const items: any[] = [];
    prizeList.forEach((prize: any, i: number) => {
      if (eventPrizeDetails?.baseAwardsData.find((award: any) => ((award.prizeName === prize.prizeName) && award.customQuantity > 0))) {
        items.push(
          <Fragment key={i}>
            <div className="cell d10">{prize.realQuantity}</div>
            <div className="cell d10">{prize.suggestedQuantity}</div>
          </Fragment>
        );
      }
    });

    return items;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const redeemPoints: any = {
      "redeemPoints": event.target.checked
    };

    dispatch(updateEventPlayer(redeemPoints, event.target.name));
  };

  const handleOpenModal = (player: AwardType) => {
    setCurrentPlayer(player);
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  if (eventPrizeDetails?.awardsLists === null) {
    return (
      <></>
    )
 }
  return (
    <>
      <div className={ classes.root }>
        <Paper elevation={3} square={false} variant="elevation">
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Typography variant="h5" color="primary" className={ classes.sectionTitle }>
                { t('prizes.title-prizes-table') } {(loading === 'PRIZE_EVENT_DETAIL' || loading === 'MANAGE_PRIZE_TO_EVENT') && <CircularProgress/>} 
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <div className={classes.customTable} aria-label="Variations table">
                <div className="header">
                  <div className="cell d10 m10">{t('prizes.position-table')}</div>
                  <div className="cell d20 m20">{t('prizes.name-table')}</div>
                  <div className="cell d12">{t('prizes.redeem-oc-table')}</div>
                  <div className="cell d12">{t('prizes.oc-table')}</div>
                  { eventPrizeDetails?.baseAwardsData !== undefined &&
                    eventPrizeDetails?.baseAwardsData.filter((award: any) => award.customQuantity > 0).map((award: any, i: number) =>
                    <Fragment key={i}>
                      <div className="cell d10">{award.prizeName + ' ' + t('prizes.assigned-table')}</div>
                      <div className="cell d10">{award.prizeName + ' ' + t('prizes.suggested-table')}</div>
                    </Fragment>)
                  }
                </div>
                <div className="body">
                  {eventPrizeDetails?.awardsLists !== undefined &&
                    (eventPrizeDetails?.awardsLists.map((player: AwardType, i: number) =>
                      <Fragment key={i}>
                        <div className="row">
                          {player.playerId ?
                            <div onClick={(e) => handleOpenModal(player)} className={classes.itemClickable + ` cell d10 m10`}>
                              {player.position}<sup>{convertCardinalToOrdinal(player.position)}</sup>
                            </div>  
                          : 
                            <div className="cell d10 m10">
                              {player.position}<sup>{convertCardinalToOrdinal(player.position)}</sup>
                            </div> 
                          }
                          <div className="cell d20 m20">
                            <div className={classes.playerName}>
                              {player.playerName}{player.playerName === null && 
                              <Typography color="primary" variant='body2'>{ t('prizes.no-name-table') }</Typography> }
                            </div>
                          </div>
                          <div className="cell d12 small priceCol">
                            <Switch
                              className={classes.switch}
                              checked={player.redeemCoins}
                              onChange={handleChange}
                              name={player.playerId ? player.playerId.toString() : 'generic-name'}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              disabled={!!player.anonymousPlayer || player.playerId === null || !canRedeemOC(player.prizeList)}
                            />
                          </div>
                          <div className="cell d12 m12">
                            {player.coinsToRedeem ? player.coinsToRedeem : '-' }
                          </div>
                          {renderAwardsRows(player.prizeList)}
                        </div>
                      </Fragment>
                    ))
                  }
                </div>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
      <Modal
        open={ openModal }
        onClose={ handleCloseModal }
        aria-labelledby="Module of Assigning Prizes"
        className='modal-form'
      >
        <Paper elevation={3} className={ classes.modalPaper }>
          <IconButton className={ classes.closeModal } aria-label="close modal" component="span" onClick={ () => handleCloseModal() }>
            <CloseIcon/>
          </IconButton>
          {}
          <Typography variant="h5" color="primary">
            { t('prizes.assigning-prizes-title-1') + ` `+ currentPlayer?.position } <sup>{convertCardinalToOrdinal(currentPlayer?.position ? currentPlayer?.position : 5)}</sup> { ` ` + t('prizes.assigning-prizes-title-2')} {(loading === 'EVENT_REGISTER_PRIZE_TO_PLAYER') && <CircularProgress/>}
          </Typography>
          <AssignPrizes player={currentPlayer}/>
          <div className={ classes.modalCloseBtn }>
            <Button variant="contained" color="primary" onClick={ () => handleCloseModal() }> { t('prizes.close-modal') } </Button>
          </div>
        </Paper>
      </Modal>
    </>
  );
}

export default PrizeTable;