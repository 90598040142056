/* eslint-disable no-eval */
/* eslint-disable no-script-url */

/**
 * Generate order and HTML form in dom for the payme modal to read from
 *  @param {bodyForm} for generating an order in the api
 *  @param {requestFields} for building the form needed by the PAYME modal
 */

 import axios from 'axios';
 import jsSHA from "jssha";
 
 const calcHash = (input:string) => {
   const hashObj = new jsSHA(
     'SHA-512',
     'TEXT',
     {
       numRounds: 1
     }
   );
   hashObj.update(input);
   return hashObj.getHash('HEX');
 };
 
 interface OrderReqFields {
   addressId: number | undefined
   isTransfer: boolean
   ld: string
   lk: string
   shippingId: number | undefined
   vendorIdShipping: string
   usingBalancePoints: boolean
 }
 
 interface PaymeFlowProps {
   total: string
   firstName: string
   lastName: string
   email: string
 }
 
 export const paymeFlow = async (requestFields: OrderReqFields, bodyForm:PaymeFlowProps):Promise<void> => {
   const apiURL: string | undefined = process.env.REACT_APP_API_URL;
   const token: string | undefined = localStorage.token;
   try {
     const configPOST = {
       headers: {
         'Accept': '*/*',
         'Authorization': 'Bearer ' + token,
         'Content-Type': 'application/json',
       }
     };
     const addRes = await axios.post(`${apiURL}/user/checkoutCart`, requestFields, configPOST);
     const orderCreatedTotal =  addRes.data.data.reduce((acc:any, item:any) => acc + item.actualCost, 0);
     const formattedPaymeTotal = orderCreatedTotal.toLocaleString('en-US').replaceAll(',', '');
     if (addRes.status === 200) {
       // create form
       let form = document.createElement('form');
       form.name = 'f1';
       form.id="f1";
       form.action="#";
       form.method="post";
       form.classList.add("alignet-form-vpos2");
     
       // create payme form object
       const formItems:any = {
         acquirerId: process.env.REACT_APP_PAYME_ACQUIRER_ID ?? 'missing acquirerId',
         idCommerce: process.env.REACT_APP_PAYME_ID_COMMERCE ?? 'missing idCommerce',
         purchaseAmount: `${orderCreatedTotal}00`,
         purchaseCurrencyCode: process.env.REACT_APP_PAYME_PURCHASE_CURRENCY_CODE ?? 'missing purchaseCurrencyCode',
         purchaseOperationNumber: addRes.data.data[0].externalOrderId,
         language: 'SP',
         shippingFirstName: bodyForm.firstName,
         shippingLastName: bodyForm.lastName,
         shippingEmail: bodyForm.email,
         shippingAddress: "shippingAddress",
         shippingZIP: "123",
         shippingCity: "shippingCity",
         shippingState: "shippingState",
         shippingCountry: "CR",
         userCommerce: 'userCommerce',
         userCodePayme: 'userCodePayme',
         descriptionProducts: 'descriptionProducts',    
         programmingLanguage: 'PHP',
         purchaseVerification: calcHash(`${process.env.REACT_APP_PAYME_ACQUIRER_ID}${process.env.REACT_APP_PAYME_ID_COMMERCE}${addRes.data.data[0].externalOrderId}${formattedPaymeTotal}00${process.env.REACT_APP_PAYME_PURCHASE_CURRENCY_CODE}${process.env.REACT_APP_PAYME_PASSCODE}`)
       }
       
       // create formfields
       for ( let field in formItems ) {
         let input = document.createElement('input');
         input.setAttribute('type', 'text');
         input.setAttribute('value', formItems[field]);
         input.name = field;
         form.append(input);
       }
 
       //append form to DOM
       document.getElementById('root')?.append(form);

       // open payme modal
       if (process.env.REACT_APP_PAYME_HOST === undefined || process.env.REACT_APP_PAYME_HOST === 'prod'  ) {
        eval(`javascript:AlignetVPOS2.openModal('','3')`);
        return;
       }
       eval(`javascript:AlignetVPOS2.openModal('${process.env.REACT_APP_PAYME_HOST}')`);
     }
   } catch (e) {
     console.error(e);
   }
 }