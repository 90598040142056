import {
  GET_USER,
  AUTH_FAIL,
  AUTH_LOADING,
  REGISTER_USER,
  LOGIN_USER,
  LOGOUT_USER,
  VALIDATE_ACCOUNT,
  IS_VALID_EMAIL,
  RESEND_CODE,
  FORGOT_PASSWORD,
  GET_USER_SUMMARY,
  AuthActionsTypes,
  UserSummaryType
} from "../actions/authActions/AuthActionsTypes";

// Default state interface.
interface DefaultStateI {
  loading: string,
  loginObject?: any,
  loggedUser?: any,
  authenticated: boolean,
  isValidEmail?: boolean,
  actionStatus?: any,
  userSummary?: UserSummaryType,
}

// Default state.
const defaultState: DefaultStateI = {
  loading: '',
  authenticated: false,
};

const authReducer = (state: DefaultStateI = defaultState, action: AuthActionsTypes) : DefaultStateI => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        loading: '',
        loggedUser: action.payload
      }
    case GET_USER_SUMMARY:
      return {
        ...state,
        loading: '',
        userSummary: action.payload
      }
    case AUTH_FAIL:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload.msg, action: action.payload.action, status: 'error'}
      }
    case AUTH_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case LOGIN_USER:
      return {
        ...state,
        loading: '',
        authenticated: true,
        loginObject: action.payload
      }
    case LOGOUT_USER:
      return {
        ...state,
        loading: '',
        loginObject: undefined,
        loggedUser: undefined,
        authenticated: false
      }
    case REGISTER_USER:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, action: '', status: 'success'}
      }
    case FORGOT_PASSWORD:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, action: '', status: 'success'}
      }
    case VALIDATE_ACCOUNT:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, action: '', status: 'success'}
      }
    case RESEND_CODE:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, action: '', status: 'success'}
      }
    case IS_VALID_EMAIL:
      return {
        ...state,
        loading: '',
        isValidEmail: action.payload
      }
    default:
      return state
  }
};


export default authReducer;
