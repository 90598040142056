import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2.5),
      marginBottom: theme.spacing(2.5),
      minHeight: '350px'
    }
  })
);

export default useStyles;