// React.
import React, { Fragment, useState, useEffect } from 'react';
import ShowMoreText from "react-show-more-text";

// Material UI.
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// Components.
import ImageAvatar from '../utils/ImageAvatar';

// Redux
import {useDispatch, useSelector} from "react-redux";
import { getBundles, refreshBundleView, updateBundle, deleteBundle, updateBundlePublished } from "../../../actions/bundleActions/BundleActions";
import { RootStore } from "../../../Store";

//  JWT decode token.
import jwtDecode from 'jwt-decode';

// Translations.
import { useTranslation } from 'react-i18next';

// Global Types.
import { BundleType } from '../models/ModelTypes';
import defaultImage from '../../../images/default-img.jpg';

// Styles.
import useStyles from './BundleEditStyles';

interface AddedItemType {
  itemId: number;
  name: string;
  typeName: string;
  vendorName: string;
  quantity: number;
  imgUrl: string;
}

interface BundleEditProps {
  bundle: BundleType;
  isExpanded: boolean;
};

const BundleEdit = ({bundle, isExpanded} : BundleEditProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();
  const authToken = localStorage.token;
  var loggedUserRole = '';
  if (authToken !== undefined && authToken !== '') {
    const decodedToken:any = jwtDecode(authToken);
    loggedUserRole = decodedToken.auth;
  }

  // Define local state for the bundle.
  const [bundleState, setBundleState] = useState<BundleType>(bundle);
  const [uniqueBundles, setUniqueBundles] = useState<BundleType[]>();
  const [imagesIdToRemove, setImagesIdToRemove] = useState<string>('');
  const [editing, setEditing] = useState<boolean | false>(false);
  const [openModal, setOpenModal] = useState(false);
  const [addedItems, setAddedItems] = useState<AddedItemType[]>([]);
  const [errorArray, setErrorArray] = useState<Array<string>>([]);
  const [sellPrice, setSellPrice] = React.useState<string>(String(bundle.priceWithTaxes));

  // Redux state.
  const reduxState = useSelector((state: RootStore) => state.bundle);
  const loading = reduxState.loading;
  const bundleListUnique = reduxState.bundleListUnique;
  const actionStatus = reduxState.actionStatus;

  useEffect(() => {
    // Get all vendor info only if the accordion is expanded.
    if (isExpanded) {
      const qParams = {
        bundleCode: bundleState.bundleCode
      };
      dispatch(getBundles(qParams, 'seller', true));
    }
    
    // eslint-disable-next-line
  }, [isExpanded]);

  useEffect(() => {
    // Save the unique bundles on local state.
    if (bundleListUnique?.bundles !== undefined && isExpanded) {
      setUniqueBundles(bundleListUnique?.bundles);
    }
    
    // eslint-disable-next-line
  }, [bundleListUnique]);

  useEffect(() => {
    var currentItems:AddedItemType[] = [];
    bundle.items.forEach((item) =>  {
      currentItems.push({
        itemId: item.item.id,
        name: item.item.name,
        typeName: `${item.item.itemType?.name}`,
        vendorName: item.item?.vendor?.name!,
        quantity: item.quantity,
        imgUrl: item.item!.images![0] !== undefined ? item.item!.images![0]!.url : defaultImage
      });
    });

    setAddedItems(currentItems);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (actionStatus !== undefined) {
      if (actionStatus.menssage === 'status.success-delete-bundle' || actionStatus.menssage === 'status.success-update') {
        dispatch(refreshBundleView(true));
      }
    }
  // eslint-disable-next-line
  }, [actionStatus])

  // Handles the field changes to update the state.
  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    // Use the HTML name atribute to know what property update.
    const name = event.target.name as keyof typeof bundleState;
    var inputValue = String(event.target.value);

    // Filter incorrect numbers
    if (name === 'price') {
      const re = /^[0-9\b]+$/;
      const newValue = String(inputValue);
      if (!re.test(newValue)) {
        inputValue = '';
      }
    }

    setBundleState({
      ...bundleState,
      [name]: inputValue,
    });

    // Validate and add errors.
    const errors = [...errorArray];
    const nameString = '' + [name]
    if (inputValue === '') {
      errors.push(nameString);
      setErrorArray(errors);
    } else {
      const newErrors = errors.filter(function(value) { 
        return value !== nameString;
      });
      setErrorArray(newErrors);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  const handleUploadClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesList = event.target.files as File[] | null;
    const imgs = bundleState.images;
    const files = bundleState.files ? bundleState.files : [];
    for (var i = 0; i < filesList!.length; i++) {
      let tempURL = URL.createObjectURL(filesList![i]);
      const uniqueId = (new Date().getTime()) + i;
      const newImg = {
        id: uniqueId,
        url: tempURL,
        key: tempURL
      };
      imgs[0] = newImg;
      files[0] = {fid: uniqueId, file: filesList![i]};
    }

    setBundleState({
      ...bundleState,
      images: imgs,
      files: files
    });

    // Set the image id to remove.
    let originalId = `${bundle.images[0] !== undefined ? bundle.images[0].id : ''}`;
    setImagesIdToRemove(originalId);

    // Remove posible errors.
    const errors = [...errorArray];
    const newErrors = errors.filter(function(value) { 
      return value !== 'image';
    });
    setErrorArray(newErrors);
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBundleState({
      ...bundleState,
      isPublished: event.target.checked,
    });

    // Save the published status right away.
    dispatch(updateBundlePublished(`${event.target.checked}`, `${bundleState.bundleCode}`));
  };

  const handleChangeValidation = (inputName: string) => {
    var result = false;
    errorArray.forEach((key) => {
      if (key === inputName) {
        result = true;
      }
    });

    return result;
  };

  const changeSellPrice = (event: React.ChangeEvent<{value: unknown }>) => {
    setSellPrice(String(event.target.value));
    updatePriceState(String(event.target.value));

    // Validate and add errors.
    const errors = [...errorArray];
    if (String(event.target.value) === '') {
      errors.push('price');
      setErrorArray(errors);
    } else {
      const newErrors = errors.filter(function(value) { 
        return value !== 'price';
      });
      setErrorArray(newErrors);
    }
  };

  const updatePriceState = (value:string) => {
    setBundleState({
      ...bundleState,
      price: postProcessPrice(Number(value))
    });

    // Update errors list.
    const errors = [...errorArray];
    const newErrors = errors.filter(function(value) { 
      return value !== 'price';
    });
    setErrorArray(newErrors);
  };

  const postProcessPrice = (price:number) => {
    var tax = bundle.currentTax;
    return Number(((price * 100) / (100 + tax)).toFixed(2));
  };

  const validateFields = () => {
    var result = true;
    const errors = [...errorArray];
    if (bundleState.name === '') {
      errors.push('name');
      result = false;
    }
    if (bundleState.price < 1) {
      errors.push('price');
      result = false;
    }
    if (bundleState.description === '') {
      errors.push('description');
      result = false;
    }

    if (bundleState.images[0].url === undefined || bundleState.images[0].url === '') {
      errors.push('image');
      result = false;
    }

    setErrorArray(errors);

    return result;
  };

  const editOnClick = () => {
    setEditing(true);
  };

  const cancelOnClick = () => {
    setEditing(false);

    // Set user state to original values.
    setBundleState(bundle);
    setErrorArray([]);
    setImagesIdToRemove('');
  };

  const saveOnClick = () => {
    // Save the bundle on the BE.
    const validation = validateFields();
    if (errorArray.length === 0 && validation) {
      let requestObject = {
        bundleCode: bundleState.bundleCode,
        name: bundleState.name,
        description: bundleState.description,
        files: bundleState.files !== undefined ? bundleState.files : [],
        price: bundleState.price,
        isPublished: bundleState.isPublished
      };
      dispatch(updateBundle(requestObject, imagesIdToRemove));
    }
    setEditing(false);
  };

  const deleteBundleOnClick = () => {
    if (bundleState.bundleCode !== '') {
      dispatch(deleteBundle(bundleState.bundleCode));
    }

    handleCloseModal();
  };

  // Get diferent bg class depeding on the warehouse index.
  const getVenderClass = (index:number) => {
    if (index === 0) return classes.bgYellow;
    else if (index === 1) return classes.bgRed;
    else if (index === 2) return classes.bgBlue;
    else return classes.bgDefault;
  };

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3} md={2}>
          <div className={ classes.leftColumnWrapper }>
            <div className={ classes.imgWrapper }>
              <Avatar variant="rounded" alt="Bundle image" src={ bundleState.images[0]?.url } className={ classes.userImg }/>
              {editing && (
                <div className={ classes.editImgWrapper }>
                  <input accept="image/*" id="icon-button-file" type="file" onChange={ handleUploadClick }/>
                  <label htmlFor="icon-button-file">
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </div>
              )}
            </div>
            { editing ? <LockOpenIcon fontSize="large"/> : <LockIcon fontSize="large" color="disabled" /> }
          </div>
        </Grid>
        <Grid item xs={12} sm={9} md={5}>
          <form>
            <FormControl className={classes.formControl}>
              <Typography variant="caption">{ t('bundles.bundle-name') }</Typography>
              <TextField 
                className={classes.formField} 
                id="b-name" name="name" 
                value={ bundleState.name } 
                onChange={ handleChange } 
                disabled={!editing}
                autoFocus
                error={ handleChangeValidation('name') }
                helperText={handleChangeValidation('name') ? t('bundles.field-required'): ''}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Typography variant="caption">{ t('bundles.bundle-description') }</Typography>
              {editing ?
                <TextField
                  multiline
                  maxRows={8}
                  className={classes.formField} 
                  id="b-description" name="description" 
                  value={ bundleState.description } 
                  onChange={ handleChange } 
                  disabled={!editing}
                  error={ handleChangeValidation('description') }
                  helperText={handleChangeValidation('description') ? t('bundles.field-required'): ''}
                />:
                <Typography className={ classes.showMore } color="textSecondary">
                  <ShowMoreText
                    lines={3}
                    more={ t('items.see-more') }
                    less={false}
                    expanded={false}
                    truncatedEndingComponent={"... "}
                  >
                    { bundleState.description }
                  </ShowMoreText>
                </Typography>
              }
            </FormControl>
            <FormControl className={classes.formControl}>
              <Typography variant="caption">{ t('items.sell-price') }</Typography>
              <TextField 
                className={classes.formField} 
                id="b-sellprice" name="sellprice" 
                value={ sellPrice } 
                onChange={ changeSellPrice } 
                disabled={!editing}
                type="number"
                error={ handleChangeValidation('price') }
                helperText={handleChangeValidation('price') ? t('bundles.field-required'): ''}
                onKeyDown={ (evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault() }
              />
            </FormControl>
            {editing &&
              <FormControl className={classes.formControl}>
                <Typography variant="caption">{ t('bundles.bundle-price') }</Typography>
                <TextField 
                  className={classes.formField} 
                  id="b-price" name="price" 
                  value={ bundleState.price } 
                  onChange={ handleChange } 
                  disabled
                  type="number"
                />
              </FormControl>
            }
            <FormControl className={classes.formControl}>
              <Typography variant="caption">{ t('bundles.store') }</Typography>
              <TextField 
                className={classes.formField} 
                id="b-vendor" name="vendor" 
                value={ bundleState.vendor?.name } 
                onChange={ handleChange } 
                disabled
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormControlLabel control={<Checkbox checked={ bundleState.isPublished } onChange={ handleChangeCheckbox } name="isPublished" color="primary"/>} label={ t('bundles.published') } /> 
            </FormControl>
          </form>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <div className={ classes.addedItemsContainer }>
            <Typography variant="caption" className={ classes.marginB }>{ t('bundles.select-items-label-update') }</Typography>
            {addedItems.map((element:AddedItemType, index:number) => 
              <Paper key={index} elevation={1} className={classes.addedItem}>
                <ImageAvatar src={ element.imgUrl } alt="Item main image." />
                {element.name} <br/> 
                ({ t('bundles.quantity') }: {element.quantity}) ({ t('items.category') } {element.typeName})
              </Paper>
            )}
          </div>
          <div className={ classes.addedItemsContainer }>
            <Typography variant="caption" className={ classes.marginB }>{ t('bundles.quantity-per-vendor') }</Typography>
            { uniqueBundles ? uniqueBundles.map((uniqueBundle:BundleType, index:number) => {
              return <Grid item xs={12} sm={12} md={12} key={index} className={ getVenderClass(index) }>
                <div className={classes.formControl}>
                  <Typography color="initial" className={classes.vendorName}>{uniqueBundle?.vendor?.name}</Typography>
                </div>
                <div className={classes.bundleItemList}>
                  <Typography variant="caption">{ t('bundles.bundles-available') }: {uniqueBundle.totalQuantity}</Typography>
                </div>
              </Grid>
            }) : null }
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <FormControl className={ classes.formControl }>
            {editing && (
              <div className={ classes.actionsWrapper }>
                {loading === 'USER_UPDATE' && <CircularProgress/> }
                <Button variant="text" color="primary" onClick={ saveOnClick } disabled={errorArray.length !== 0}>
                  { t('bundles.save') }
                </Button>
                <Button variant="contained" color="secondary" onClick={ cancelOnClick }>
                  { t('bundles.cancel') }
                </Button>
                <Button variant="contained" color="primary" disabled>
                  { t('bundles.edit-bundle') }
                </Button>
              </div>
              
            )}
            {!editing && (
              <div className={ classes.actionsWrapper }>
                {(loggedUserRole.includes('ROLE_ADMIN') || loggedUserRole.includes('ROLE_SUPER_SELLER')) &&
                  <Button variant="contained" color="secondary" startIcon={<DeleteIcon />} onClick={handleOpenModal}>
                    { t('bundles.delete') }
                  </Button>
                }
                <Button variant="contained" color="primary" onClick={ editOnClick }>
                  { t('bundles.edit-bundle') }
                </Button>
              </div>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Modal
        open={ openModal }
        onClose={ handleCloseModal }
        aria-labelledby="Confirm Modal"
        >
        <Paper elevation={3} className={ classes.modalPaper }>
          <IconButton className={ classes.closeModal } aria-label="close modal" component="span" onClick={ () => handleCloseModal() }>
            <CloseIcon/>
          </IconButton>
          <Typography>{ t('bundles.delete-confirm-text', { 'itemName': bundleState.name }) }</Typography>
          <Button variant="contained" color="primary" onClick={ () => handleCloseModal() }> { t('bundles.no') } </Button>
          <Button variant="contained" color="secondary" onClick={ () => deleteBundleOnClick() }> { t('bundles.yes') } </Button>
        </Paper>
      </Modal>
    </Fragment>
  )
}

export default BundleEdit;
