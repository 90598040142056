// React.
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components.
import Pagination from '../utils/ResultsPagination';
import ImageAvatar from '../utils/ImageAvatar';
import OrderEdit from './OrderEdit';
import NumberFormat from '../utils/NumberFormat';

// Material UI
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {getOrders, refreshOrderView} from "../../../actions/orderActions/OrderActions";

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';
// Moment.
import Moment from 'moment';

// Translations.
import { useTranslation } from 'react-i18next';

//  JWT decode token.
import jwtDecode from 'jwt-decode';

// Styles.
import { useStyles, Accordion, AccordionSummary, AccordionDetails } from './OrderAccordionStyles';
import defaultImage from '../../../images/default-img.jpg';
import { getVendors } from '../../../actions/vendorActions/VendorActions';
import scrollToTop from '../../utils/scrollToTop';

const today = Moment().format('YYYY-MM-DD');

const UserAccordion = () => {
  const [t] = useTranslation('global');
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [initialDateRange,] = useState(()=> today);
  const [endDateRange,] = useState(()=> today);
  const url = window.location.href;

  // Redux state.
  const orderState = useSelector((state: RootStore) => state.order);
  const authState = useSelector((state: RootStore) => state.auth);
  const {loading, actionStatus, orderList, refresh, orderFilters: filters} = orderState;
  const loggedUser = authState.loggedUser;
  const decodedToken: any = localStorage.token !== undefined && localStorage.token !== '' ? jwtDecode(localStorage.token) : false;

  // Scroll top when pager update url.
  useEffect(() => {
    return history.listen((location) => { 
      scrollToTop();
    }) 
 }, [history]) 

  const getOrderData = () => {
    if (refresh) setFirstLoad(true);
    const params = new URLSearchParams(window.location.search);
    let page = params.get('page');
    let pStatus = params.get('status');
    const pNumber = Number(page);

    if (page && page !== null) {
      const qParams = {
        initialDateRange,
        endDateRange,
        ...filters,
        page: pNumber - 1,
        size: getProjectConfig().PAGES_SIZE,
        paged: true
      };
      if (pStatus) {
        if (filters!) dispatch(getOrders(qParams));
      } else {
        dispatch(getOrders(qParams));
      }
      setCurrentPage(pNumber);
    } else {
      const qParams = {
        initialDateRange,
        endDateRange,
        ...filters,
        page: 0,
        size: getProjectConfig().PAGES_SIZE,
        paged: true
      };
      if (pStatus) {
        if (filters!) dispatch(getOrders(qParams));
      } else {
        dispatch(getOrders(qParams));
      }
      setCurrentPage(1);
    }

    dispatch(refreshOrderView(false));
    dispatch(getVendors());
  };

  useEffect(() => {
    getOrderData();

    // eslint-disable-next-line
  }, [url, refresh]);

  useEffect(() => {
    if (actionStatus !== undefined) {
      if (actionStatus.menssage === 'status.success-update') {
        getOrderData();
        setFirstLoad(false);
      }
    }

    // eslint-disable-next-line
  }, [actionStatus]);

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (orderList?.orders.length === 0 && !loading) {
    setTimeout(function () {
      return (
        <div className={classes.circularProgress}>
          <Typography color="textSecondary">{t('orders.no-orders')}</Typography>
        </div>
      )
    }, 1000);
  }

  if (loading === 'LIST' && firstLoad) {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    )
  }

  const getOrderImage = (order:any) => {
    const orderItems = order.orderItems;
    const orderBundles = order?.orderBundleItems!;
    var image = defaultImage;
    if (orderItems[0] !== undefined) {
      if (orderItems[0].item.images[0] !== undefined) {
        image = orderItems[0].item.images[0].url;
      }
    }
    if (orderBundles[0] !== undefined) {
      if (orderBundles[0].bundle.images[0] !== undefined) {
        image = orderBundles[0].bundle.images[0].url;
      }
    }
    return image;
  };

  const getUserOrder = (order:any) => {
    return (order.user === null) ? order.seller.email : order.user.email;
  };

  const getOrderTotalQuantity = (order:any) => {
    const orderItems = order.orderItems;
    const orderBundles = order?.orderBundleItems!;
    var total = 0;
    orderItems.forEach((item:any) => {
      if (item.normal_quantity !== undefined) {
        total = total + item.normal_quantity
      }
      if (item.discount_quantity > 0) {
        total = total + item.discount_quantity
      }
    });

    orderBundles.forEach((bundle:any) => {
      if (bundle.quantity !== undefined) {
        total = total + bundle.quantity
      }
    });

    return total;
  };

  return (
    <Fragment>
        {
          orderList?.orders !== undefined && 
          (orderList!.orders!.map(order => 
            <Accordion key={ order.id } square expanded={expanded === `panel-u-${ order.id }`} onChange={handleChange(`panel-u-${ order.id }`)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={2} className={ classes.firstWrapper }>
                    {
                      decodedToken.auth !== 'ROLE_SELLER' && decodedToken.auth !== 'ROLE_SELLER_ASSOCIATE' ? (
                      <Typography variant="subtitle1" color="primary">
                        <strong >Pin: {order.pin}</strong>
                      </Typography>
                    ) : (<ImageAvatar src={getOrderImage(order)} alt="Order image." />)
                    }
                  </Grid>
                  <Grid item xs={8} sm={3}>
                    <Typography variant="subtitle1">{ getOrderTotalQuantity(order) }x { t('orders.order') } { order.orderCode}</Typography>
                    {!loggedUser?.roles.includes('ROLE_SELLER_ASSOCIATE') &&
                      <Typography variant="caption" color="textSecondary"> {getUserOrder(order)} </Typography>
                    }
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography variant="subtitle1" color="primary">Total: { order.currency.symbol }{ <NumberFormat number={order.totalCost}/> }</Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography variant="subtitle1">{ t('orders.status') } { order.status }</Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} className={ classes.lastWrapper }>
                    <Typography variant="caption" color="textSecondary">{'Created: '}{ Moment(order.creationDate).format('MM/DD/YYYY HH:mm') }</Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <OrderEdit order={ order }/>
              </AccordionDetails>
            </Accordion>
          ))
        }
        {
          (orderList?.totalPages !== undefined && orderList?.totalPages > 1) 
          ? (<Pagination current={currentPage} path="/admin/orders" pagesNumber={ orderList!.totalPages }/>) : ''
        }
    </Fragment>
  );
}

export default UserAccordion;
