import {
  GET_NAV,
  NAV_FAIL,
  NAV_LOADING,
  SET_NAV_FILTERS,
  REGISTER_MENU,
  UPDATE_MENU,
  DELETE_MENU,
  REGISTER_SUBMENU,
  UPDATE_SUBMENU,
  DELETE_SUBMENU,
  GET_ALL_ICONS_URL,
  UPDATE_NAV_ADMIN,
  NavItemType,
  NavSubItemType,
  NavFiltersType,
  NavDispatchTypes,
} from "../actions/navActions/NavActionsTypes";

// Default state interface.
interface DefaultStateI {
  navLoading: string,
  navFilters?: NavFiltersType,
  actionStatus?: any,
  navItems?: NavItemType[],
  createdNavItem?: NavItemType,
  updatedNavItem?: NavItemType,
  createdSubNavItem?: NavSubItemType,
  updatedSubNavItem?: NavSubItemType
  allIconsUrl?: Array<string>,
  mustUpdateAdminNav: boolean,
}

// Default state.
const defaultState: DefaultStateI = {
  navLoading: '',
  mustUpdateAdminNav: true
};

const navReducer = (state: DefaultStateI = defaultState, action: NavDispatchTypes) : DefaultStateI => {
  switch (action.type) {
    case NAV_FAIL:
      return {
        ...state,
        navLoading: '',
        actionStatus: { menssage: 'status.error-processing', status: 'error' }
      }
    case NAV_LOADING:
      return {
        ...state,
        navLoading: action.payload
      }
    case GET_NAV:
      return {
        ...state,
        navLoading: '',
        navItems: action.payload
      }
    case SET_NAV_FILTERS:
      return {
        ...state,
        navLoading: '',
        navFilters: action.payload
      }
    case UPDATE_NAV_ADMIN:
      return {
        ...state,
        navLoading: '',
        mustUpdateAdminNav: action.payload
      }
    case REGISTER_MENU:
      return {
        ...state,
        navLoading: '',
        createdNavItem: action.payload,
        actionStatus: { menssage: 'status.success-create', status: 'success' }
      }
    case UPDATE_MENU:
      return {
        ...state,
        navLoading: '',
        updatedNavItem: action.payload,
        actionStatus: { menssage: 'status.success-update', status: 'success' }
      }
    case DELETE_MENU:
      return {
        ...state,
        navLoading: '',
        actionStatus: { menssage: action.payload, status: 'success' }
      }
    case REGISTER_SUBMENU:
      return {
        ...state,
        navLoading: '',
        createdSubNavItem: action.payload,
        actionStatus: { menssage: 'status.success-create', status: 'success' }
      }
    case UPDATE_SUBMENU:
      return {
        ...state,
        navLoading: '',
        updatedSubNavItem: action.payload,
        actionStatus: { menssage: 'status.success-update', status: 'success' }
      }
    case DELETE_SUBMENU:
      return {
        ...state,
        navLoading: '',
        actionStatus: { menssage: action.payload, status: 'success' }
      }
    case GET_ALL_ICONS_URL:
      return {
        ...state,
        navLoading: '',
        allIconsUrl: action.payload
      }
    default:
      return state
  }
};


export default navReducer;
