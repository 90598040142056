// React.
import React from 'react';

// Components.
import PrizeItemSearchModal from './PrizeItemSearchModal';
import AssignPrizeDetailsModal from './AssignPrizeDetailsModal';

// Global Types.
import { AwardType } from '../../../actions/prizeActions/PrizeActionsTypes';
interface AwardEditProps {
  player: AwardType;
};
const AssignPrizes = ({ player } : AwardEditProps) => {  
  return (
    <>
      <PrizeItemSearchModal player={player} />
      <AssignPrizeDetailsModal player={player} />
    </>
  )
}

export default AssignPrizes;