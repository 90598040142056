// React.
import React from 'react';

// Components.
import VendorList from '../vendor/VendorList';

const Vendors = () => {
  return (
    <div>
      <VendorList />
    </div>
  )
}

export default Vendors;
