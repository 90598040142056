import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiAvatar-root': {
        maxHeight: '40px'
      },
      '& .MuiAvatar-img': {
        height: 'auto',
        maxHeight: '40px',
        objectFit: 'contain'
      }
    }
  })
);