//Material UI Import
import {
  Button,
  CircularProgress,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";

//Translations
import { useTranslation } from "react-i18next";

//Redux
import {  useSelector } from "react-redux";
import { RootStore } from "../../../Store";

//Interface for the Props
interface LabelEditModalProps {
  closeModal: () => void;
  newEditLabelValue:any;
  handleLabelEdit:()=>void;
  handleEditlabelValue:(event:any)=>void
}

const LabelEditModal: React.FC<LabelEditModalProps> = ({
  closeModal,
  newEditLabelValue,
  handleLabelEdit,
  handleEditlabelValue,
}) => {
  const [t] = useTranslation("global");

  //Redux State
  const prizesState = useSelector((state: RootStore) => state.prize);
  const loading = prizesState.loading;

  return(
    <div>
    <InputLabel style={{marginBottom:'20px'}}>
      <Typography variant="h5" color="primary">{t('prizes.edit-value')}:</Typography>
      </InputLabel>
      {t('prizes.new-label-name')}
      <TextField type="text" name="name" value={newEditLabelValue} onChange={(event)=>{handleEditlabelValue(event)}}/>
      <div style={{display:'flex', gap:'10px', marginTop:'10px'}}>
      <Button variant="contained" color="default" onClick={()=>{handleLabelEdit()}} disabled={newEditLabelValue?.length<3}>
        {loading? 
        <CircularProgress/>:
        `${t('prizes.change')}` 
       }
      </Button>
      <Button variant="contained" color="default" onClick={closeModal}>{t('prizes.cancel')}</Button>
      </div>
    </div>
  )
};

export default LabelEditModal;
