import { Dispatch } from "redux";
import axios from 'axios';
import {
  Log,
  LogDispatchTypes,
  LOG_FAIL,
  LOG_LIST,
  LOG_REFRESH,
  LOG_SET_FILTER,
  LOG_LOADING,
  GET_CHANGE_LOGS_TYPES
} from "./LogActionsTypes";

const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;

/**
 * Update the refresh logs view state.
 * @param {boolean} value
 */
export const refreshLogsView = (value: boolean) => async (dispatch: Dispatch<LogDispatchTypes>) => {
  dispatch({
    type: LOG_REFRESH,
    payload: value
  })
};

/**
 * Update the logs view filters state.
 * @param {object} filters
 */
export const logsSetFilters = (filters: object) => async (dispatch: Dispatch<LogDispatchTypes>) => {
  dispatch({
    type: LOG_SET_FILTER,
    payload: filters
  })
};

/**
 * Get a list of vendors from the BE, it can be filtered.
 * @param {object|null} qParams
 */
export const getLogs = (qParams: object | null) => async (dispatch: Dispatch<LogDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: LOG_LOADING,
        payload: 'LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }
      const res = await axios.get(`${apiURL}/admin/getAllChangeLogs`, {
        params: qParams,
        headers: headers
      });
      if (res.status === 200) {
        const queryData = res.data.data.content;
        const logs: Log[] = [];

        for (var i = 0; i < queryData.length; i++) {
          const log = queryData[i];

          logs.push({
            additionalInfo: log.additionalInfo,
            creationDate: log.creationDate,
            customMessage: log.customMessage,
            id: log.id,
            newValue: log.newValue,
            objectAttributeName: log.objectAttributeName,
            objectId: log.objectId,
            objectType: log.objectType,
            oldValue: log.oldValue,
            user: log.user
          });
        }

        const data: any = {
          first: res.data.data.first,
          last: res.data.data.last,
          number: res.data.data.number,
          size: res.data.data.size,
          totalPages: res.data.data.totalPages,
          totalElements: res.data.data.totalElements,
          logs: logs
        };

        dispatch({
          type: LOG_LIST,
          payload: data
        })
      } else {
        dispatch({
          type: LOG_FAIL,
          payload: 'status.get-error'
        })
      }
    } catch (e) {
      dispatch({
        type: LOG_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: LOG_FAIL,
      payload: 'status.get-error'
    })
  }
};

/**
 * Get a list of log types.
 */
export const getAllChangeLogsType = () => async (dispatch: Dispatch<LogDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: LOG_LOADING,
        payload: 'LOG_TYPES_LIST'
      })

      const headers: object = {
        ContentType: 'application/json',
        Authorization: 'Bearer ' + token
      }
      const res = await axios.get(`${apiURL}/admin/getAllChangeLogsType`, {
        headers: headers
      });
      if (res.status === 200) {
        dispatch({
          type: GET_CHANGE_LOGS_TYPES,
          payload: res.data.data.changeLogTypes
        })
      } else {
        dispatch({
          type: LOG_FAIL,
          payload: 'status.get-error'
        })
      }
    } catch (e) {
      dispatch({
        type: LOG_FAIL,
        payload: 'status.get-error'
      })
    }
  } else {
    dispatch({
      type: LOG_FAIL,
      payload: 'status.get-error'
    })
  }
};
