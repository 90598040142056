// Material UI.
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      display: 'block',
      '& > *': {
        display: 'block',
      }
    },
    formControlAddress: {
      margin: theme.spacing(0),
      '& .MuiFormLabel-root': {
        color: grey[500]
      }
    },
    omegaCoinsButton:{
      display:"block",
      opacity:'80%',
      cursor: 'default',
      backgroundColor: theme.palette.primary.main,
      color:'#ffffff',
      padding:'6px 16px',
      borderRadius:'4px',
      textTransform:'uppercase',
    },
    addressWrapper: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      },
    },
    secondSection:{
      display:"flex",
      flexDirection:"column",
      gap:"30px"
    },
    addressGroup: {
      borderRadius: '4px',
      border: '1px solid',
      borderColor: grey[400],
      padding: theme.spacing(2, 1),
      width: '48%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    addressLabel: {
      position: 'absolute',
      top: '-9px',
      background: 'white',
      padding: theme.spacing(0, 1)
    },
    mobileView:{
      [theme.breakpoints.down(800)]:{
        display:"none"
      }
    },
    tableWrapper: {
      borderCollapse: 'separate',
      '& .MuiTableHead-root .MuiTableCell-root': {
        backgroundColor: theme.palette.grey[600],
        color: 'white',
        lineHeight: '1rem'
      },
    },
    userImg: {
      width: '100%',
      height: '214px',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '229px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '102px',
        height: '102px',
        marginBottom: 0,
      },
    },
    imgWrapper: {
      position: 'relative',
    },
    editImgWrapper: {
      position: 'absolute',
      top: 'calc(50% - 22px)',
      left: 'calc(50% - 22px)',
      '& #icon-button-file': {
        display: 'none'
      }
    },
    leftColumnWrapper: {
      textAlign: 'center',
      paddingTop: '50px',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 0,
      },
    },
    formField: {
      width: '100%',
      '& .Mui-disabled:before': {
        borderBottom: 0
      },
      '&.Mui-disabled:before': {
        borderBottom: 0
      },
      '& svg': {
        display: 'none'
      },
      '& > div': {
        width: '100%',
      },
    },
    noAddress: {
      margin: theme.spacing(2, 1)
    },
    addAddress: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      margin: theme.spacing(3, 1)
    },
    actionsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      '& button': {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          marginLeft: theme.spacing(1),
        },
      }
    },
    upperInfo:{
      display: "flex", 
      flexDirection: "row", 
      justifyContent:"space-between",
      [theme.breakpoints.down('xs')]: {
        display:'flex',
        flexDirection:'column-reverse',
        alignItems:'flex-start',
        width:'100%',
        minWidth:'200px',
      }
    },
    upperPoints:{
      marginRight:"4rem",
      [theme.breakpoints.down('xs')]: {
        marginRight:"0",
      }
    },
    Reason:{
      overflowY:'scroll',
      whiteSpace:'pre-line',
      maxHeight:'60px',
      maxWidth:'160px'

    },
    modalPaper: {
      padding: theme.spacing(2, 4),
      position: 'absolute',
      width: '600px',
      height: '330px',
      top: 'calc(50% - 134px)',
      left: 'calc(50% - 300px)',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2, 1),
        width: '90%',
        left: '5%'
      },
    },
    
    modalField: {
      margin: theme.spacing(2, 0)
    },
    modalButton: {
      marginTop: theme.spacing(2),
      float: 'right'
    },
    points: {
      textAlign: 'center'
    },
    pointsTitle: {
      fontFamily: 'VisbyCF-Bold',
      fontSize: "24px",
      marginBottom: theme.spacing(2)
    },
    wide: {
      width: '100%'
    },
    pointsNumber: {
      display: 'flex',
      marginBottom:'30px',
      alignItems: 'center',
      justifyContent: 'center',
      gap:'5px',
      '& div': {
        marginTop: '5px'
      }
    },
    pointsNumberButton: {
      display: 'flex',
      marginBottom:'4px',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize:'2rem',
      gap:'5px',
      '& div': {
        marginTop: '5px'
      }
    },
    historialButton:{
      textDecoration:'none',
      color: 'inherit',
      fontSize:'1rem',
      textTransform:'none'
    },
    wishlistButton: {
      marginTop: '20px',
      marginBottom: '20px',
      color: theme.palette.primary.main,
      display:"block",
      cursor: 'pointer',
      padding:'6px 16px',
      borderRadius:'6px',
      border: '2px solid lightgray ',
      textTransform:'uppercase',
      paddingTop: '10px',
      paddingBottom: '10px',
      transition: 'background-color 0.3s ease, color 0.3s ease',
      '&:hover': {
        backgroundColor: 'rgba(228, 58, 0, 0.04)', 
      },
    },
    heart: {
      color: theme.palette.primary.main,
      marginTop: '10px',
      fontSize: '2rem !important'
    }
    
    
  }),
);

export default useStyles;