// React.
import React from 'react';

// Material UI
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Components.
import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';
import Slide from './Slide';

// Styles.
import useStyles from './SliderStyles';

// Redux
import { useSelector } from "react-redux";
import {RootStore} from "../../../Store";

const Slider = () => {
  const classes = useStyles();

  // Redux state.
  const settingsState = useSelector((state: RootStore) => state.settings);
  const { homepageData } = settingsState;

  return (
    <div className={classes.root}>
      {homepageData !== undefined &&
        <Carousel
          showArrows={ true }
          interval={ 7500 }
          transitionTime={ 700 }
          autoPlay={ true }
          showThumbs={ false }
          showIndicators={ true }
          emulateTouch={ true }
          infiniteLoop={ true }
          showStatus={ false }
          selectedItem={ 0 }
          renderArrowPrev={ (onClickHandler, hasPrev, label) => hasPrev && (<RightArrow onClickHandler={onClickHandler}/>) }
          renderArrowNext={ (onClickHandler, hasNext, label) => hasNext && (<LeftArrow  onClickHandler={onClickHandler}/>) }
        >
          { homepageData?.banners?.map( (banner:any, i:number) => <div key={i}><Slide key={i} item={banner} /></div> ) }
        </Carousel>
      }
      <span className={classes.gradient}></span>
    </div>
  )
}

export default Slider;
