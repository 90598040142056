// React.
import React from "react";

// Components.
import BulkItemTitle from "../items/BulkItemTitle";
import BulkItemForm from "../items/BulkItemForm";

const BulkItemsUpdate = () => {
	return (
		<div>
			<BulkItemTitle />
			<BulkItemForm />
		</div>
	);
};

export default BulkItemsUpdate;
