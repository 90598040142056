// React.
import React, {useState, useEffect} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Material UI
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import DateAdapter from '@mui/lab/AdapterMoment';

// Moment.
import Moment from 'moment';

// Translations.
import { useTranslation } from 'react-i18next';

// Redux
import {RootStore} from "../../../Store";
import {useDispatch, useSelector} from "react-redux";
import { logsSetFilters, getAllChangeLogsType, refreshLogsView} from "../../../actions/logActions/LogActions";

// Styles.
import useStyles from './LogsSearchStyles';
import { Grid } from '@material-ui/core';

const OrderSearch = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [t] = useTranslation('global');
  const history = useHistory();
  const location = useLocation();

  const startDate = Moment().format('YYYY-MM-DD');
  const endDate = Moment().add(1, 'days').format('YYYY-MM-DD');

  // Local component state.
  const [search, setSearch] = useState<string>(() => '');
  const [type, setType] = React.useState('');
  const [userEmail, setUserEmail] = React.useState('');
  const [initialDateRange, setInitialDateRange] = useState(startDate);
  const [endDateRange, setEndDateRange] = useState(endDate);
  const [userList, setUserList] = useState<Array<string>>([]);

  // Redux state.
  const logState = useSelector((state: RootStore) => state.log);
  const loading = logState.loading;
  const logList = logState.logsList?.logs;
  const changeLogTypes = logState.changeLogTypes;

  useEffect(() => {
    if (changeLogTypes === undefined) {
      dispatch(getAllChangeLogsType());
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (logList !== undefined) {
      getUserEmailList();
    }

    // eslint-disable-next-line
  }, [logList]);

  const handleChangeSearch = (event: React.ChangeEvent<{ value: string }>) => {
    setSearch(event.target.value);
    if (event.target.value === '') {
      handleClear();
    }
  };

  const handleChangeType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setType(event.target.value as string);
  };

  const handleChangeUserEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUserEmail(event.target.value as string);
  };

  const getUserEmailList = () => {
    var newUserList: Array<string> = [];
    logList?.forEach((log) => {
      if (!newUserList.includes(log.user)) {
        newUserList.push(log.user);
      }
    });
    setUserList(newUserList);
  };

  const handleClick = () => {
    // When filter changes go to the root page.
    const queryParams = new URLSearchParams(location.search)

    if (queryParams.has('page')) {
      queryParams.delete('page')
      history.push({
        search: queryParams.toString(),
      });
    }

    const sendFilter = {
      ...(search !== '') && {searchTerm: search},
      ...(type !== '') && {objectType: type},
      ...(userEmail !== '') && {userId: userEmail},
      ...(initialDateRange) && {initialDateRange},
      ...(endDateRange) && {endDateRange},
    };
    dispatch(logsSetFilters(sendFilter));
    dispatch(refreshLogsView(true));
  };

  const handleClear = () => {
    const sendFilter = {};
    dispatch(logsSetFilters(sendFilter));
    dispatch(refreshLogsView(true));
    setSearch('');
    setUserEmail('');
    setType('');
  };

  const changeInitialDate = (startDate:any) => {
    let newDateString = Moment(startDate).format('YYYY-MM-DD');
    setInitialDateRange(newDateString)
    if (endDateRange === null || newDateString > endDateRange) {
      setEndDateRange(Moment(startDate).add(1, 'days').format('YYYY-MM-DD'));
    }
  };

  const changeEndDate = (endDate:any) => {
    let newDateString = Moment(endDate).format('YYYY-MM-DD');
    setEndDateRange(newDateString)
    if (initialDateRange === null || newDateString < initialDateRange) {
      setInitialDateRange(Moment(endDate).subtract(1, 'days').format('YYYY-MM-DD'));
    }
  };

  return (
  <LocalizationProvider dateAdapter={DateAdapter}>
    <div className={ classes.root }>
        <Paper elevation={3}>
          <div className={ classes.boxTop }>
            <SearchIcon color="primary" fontSize="large"/>
            <Typography variant="h5" color="primary">
              {t('logs.search-logs') }
            </Typography>
          </div>
          <div>
            <form className={ classes.boxForm }>
              <TextField 
                id="log-name"
                name="log_name"  
                value={ search }
                label={t('logs.log-title') }
                className={ classes.fieldFirst }
                onChange={ handleChangeSearch }
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleClick();
                  }
                }}
              />
              <FormControl className={classes.fieldSecond}>
                <InputLabel id="log-type-label">{t('logs.object-type')}</InputLabel>
                <Select
                  labelId="log-type-label"
                  id="log-type"
                  name="log_type"
                  value={type}
                  onChange={handleChangeType }
                >
                  <MenuItem value="">
                    <em>{ loading === 'STATUS_LIST' ? t('logs.loading') : t('logs.none') }</em>
                  </MenuItem>
                  {changeLogTypes !== undefined &&
                    changeLogTypes.map((type) => 
                      <MenuItem key={type} value={type}>{type }</MenuItem>
                    )
                  }
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="log-user-label">{t('logs.user')}</InputLabel>
                <Select
                  labelId="log-user-label"
                  id="user-email"
                  name="user_email"
                  value={userEmail}
                  onChange={handleChangeUserEmail}
                >
                  <MenuItem value="">
                    <em>{loading === 'STATUS_LIST' ? t('logs.loading') : t('logs.none')}</em>
                  </MenuItem>
                  {userList !== undefined &&
                    userList.map((userEmail) =>
                      <MenuItem key={userEmail} value={userEmail}>{userEmail}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </form>
          </div>

          <Grid container spacing={1} direction={'row'}
            style={{
              marginTop: 10,
              marginBottom: 10,
              }}
            >
            <Grid item >
              <DatePicker
                label={t("logs.log-from-date")}
                value={initialDateRange}
                onChange={(newValue:any) => {
                  changeInitialDate(newValue)
                }}
                renderInput={(params:any) => <TextField {...params}/>}
              />
            </Grid>
            <Grid item >
              <DatePicker
                label={t("logs.log-to-date")}
                value={endDateRange}
                onChange={(newValue:any) => {
                  changeEndDate(newValue)
                }}
                renderInput={(params:any) => <TextField {...params} />}
              />
            </Grid>
          </Grid>

          <div className={ classes.buttons }>
            <Button onClick={ handleClear } variant="contained" color="secondary">
              {t('logs.clear') }
            </Button>
            <Button onClick={ handleClick } variant="contained" color="primary" className={ classes.buttonsLast }>
              {t('logs.search') }
            </Button>
          </div>
        </Paper>
    </div>
  </LocalizationProvider>
  )
}

export default OrderSearch;
