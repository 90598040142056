// Material UI.
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(2, 1),
        padding: theme.spacing(4, 4, 4),
        width: '100%',
        height: 'auto',
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(4, 2, 2),
        },
      },
    },
    formControl: {
      // margin: theme.spacing(1),
      display: 'block',
      '& > *': {
        display: 'block',
      }
    },
    formControlAddress: {
      margin: theme.spacing(0),
      '& .MuiFormLabel-root': {
        color: grey[500]
      }
    },
    userImg: {
      width: '100%',
      height: '168px',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '126px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '102px',
        height: '102px',
        marginBottom: 0,
      },
    },
    imgWrapper: {
      position: 'relative',
    },
    editImgWrapper: {
      position: 'absolute',
      top: 'calc(50% - 22px)',
      left: 'calc(50% - 22px)',
      '& #icon-button-file': {
        display: 'none'
      }
    },
    leftColumnWrapper: {
      textAlign: 'center',
      paddingTop: '50px',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 0,
      },
    },
    formField: {
      width: '100%',
      '& .Mui-disabled:before': {
        borderBottom: 0
      },
      '&.Mui-disabled:before': {
        borderBottom: 0
      },
      '& svg': {
        display: 'none'
      },
      '& > div': {
        width: '100%',
      },
    },
    noAddress: {
      margin: theme.spacing(2, 1)
    },
    actionsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: '22px',
      marginBottom: '22px',
      '& button': {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          marginLeft: theme.spacing(1),
        },
      }
    },
    actionsWrapperFull: {
      width: '100%',
      '& button': {
        width: '100%',
      }
    },
    modalPaper: {
      padding: theme.spacing(6, 4),
      position: 'absolute',
      width: '400px',
      height: '200px',
      top: 'calc(50% - 100px)',
      left: 'calc(50% - 200px)',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(6, 2),
        width: '90%',
        left: '5%'
      },
      '& .MuiTypography-root': {
        marginBottom: theme.spacing(2),
      },
      '& .MuiButtonBase-root': {
        marginLeft: theme.spacing(2),
      }
    },
    closeModal: {
      position: 'absolute',
      right: '0',
      top: '0'
    },
    addedItemsContainer: {
      '& .MuiPaper-root': {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        display: 'flex',
        alignItems: 'center'
      },
      '& .MuiAvatar-root': {
        marginRight: theme.spacing(2)
      }
    },
    addedItem: {
      position: 'relative',
      paddingRight: '35px'
    },
    deleteItemBtn: {
      position: 'absolute',
      right: '8px'
    },
    vendorName: {
      fontFamily: 'VisbyCF-Bold'
    },
    // Light yellow Backgroud color.
    bgYellow: {
      backgroundColor: '#FEF9E7',
      border: '1px solid #FAD7A0',
      borderRadius: '6px',
      marginBottom: theme.spacing(1)
    },
    // Light red Backgroud color.
    bgRed: {
      backgroundColor: '#F9EBEA',
      border: '1px solid #F1948A',
      borderRadius: '6px',
      marginBottom: theme.spacing(1)
    },
    // Light blue Backgroud color.
    bgBlue: {
      backgroundColor: '#EBF5FB',
      border: '1px solid #85C1E9',
      borderRadius: '6px',
      marginBottom: theme.spacing(1)
    },
    // Light gray Backgroud color.
    bgDefault: {
      backgroundColor: '#F2F4F4',
      border: '1px solid #ABB2B9',
      borderRadius: '6px',
      marginBottom: theme.spacing(1)
    },
    bundleItemList: {
      padding: theme.spacing(0, 1),
      '& .MuiTypography-caption': {
        display: 'block',
        marginBottom: '5px'
      }
    },
    marginB: {
      marginBottom: theme.spacing(1),
      display: ' block'
    },
    showMore: {
      '& a': {
        color: theme.palette.primary.main
      }
    },
    sectionTitle: {
      flexGrow: 1,
    },
    detailText: {
      '& p': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        display:'flex',
        justifyContent:'space-between',
      },
      '& p:first-of-type': {
        marginTop: '2px'
      },
      '& p:last-of-type': {
        marginBottom: theme.spacing(2)
      }
    },
    summaryText: {
      '& p': {
        textAlign: 'right',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      '& p:first-of-type': {
        marginTop: '2px'
      },
      '& p:last-of-type': {
        marginBottom: theme.spacing(2)
      }
    },
    statusText: {
      color: '#7BBC41',
      fontFamily: 'VisbyCF-Bold'
    },
    boxTop: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      textAlign:'center',
      borderBottom:'solid',
      borderBottomWidth:'thin',
      paddingBottom:'10px',
      '& > *': {
        marginRight: theme.spacing(2),
      },
    },
    boxForm: {
      '& > *': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%'
      },
    },
  }),
);

export default useStyles;