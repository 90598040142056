import { Dispatch } from 'redux';
import axios from 'axios';

import { 
  PRIZE_FAIL, 
  PRIZE_LOADING, 
  PRIZE_EVENT_DETAIL, 
  PRIZE_EVENT_PLAYER_PRIZES,
  ADD_PRIZE_EVENT,
  UPDATE_PRIZE_EVENT,
  DELETE_PRIZE_EVENT,
  MANAGE_PRIZE_TO_EVENT,
  PRIZE_ITEMS_FOR_PRIZE,
  EVENT_REGISTER_PRIZE_TO_PLAYER,
  PrizeDispatchTypes, 
  EVENT_GET_ALL_PRIZES,
  EVENT_GET_ALL_PRIZES_CATEGORIES
} from './PrizeActionsTypes';

const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;

/**
 * Retrieves the event prize details by event ID.
 * @param { string } eventId
 */
export const getEventPrizeDetails = (eventId: string) => async (dispatch:Dispatch<PrizeDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: PRIZE_LOADING,
        payload: 'PRIZE_EVENT_DETAIL'
      });
      const headers: object = {
        ContentType: "application/json",
        Authorization: "Bearer " + token,
      };
      
      const requestPath = `${apiURL}/seller/getEventPrizeDetails/${eventId}`;
      
      const res = await axios.get(requestPath, {
        headers: headers,
      });

      if (res.status === 200) {
        const queryData = res.data.data;
        dispatch({
          type: PRIZE_EVENT_DETAIL,
          payload: queryData,
        });
      } else {
        dispatch({
          type: PRIZE_FAIL,
          payload: 'status.get-error',
        });
      }

    } catch(error: any) {
      if (error.response && error.response.data.status_code === 422) {
				//Showing the error message when something went wrong during the BE validation.
				dispatch({
					type: PRIZE_FAIL,
					payload: error.response.data.message,
				});
			} else {
        dispatch({
          type: PRIZE_FAIL,
          payload: 'status.get-error'
        });
      }
    }  
  } else {
    dispatch({
      type: PRIZE_FAIL,
      payload: 'status.get-error'
    });
  }
}

/**
 * Retrieves the event prize details.
 * @param { number } eventPlayerId
 */
export const getEventPlayerPrizes = (eventPlayerId: number) => async (dispatch:Dispatch<PrizeDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: PRIZE_LOADING,
        payload: 'PRIZE_EVENT_PLAYER_PRIZES'
      });
      const headers: object = {
        ContentType: "application/json",
        Authorization: "Bearer " + token,
      };
      
      const requestPath = `${apiURL}/seller/getEventPlayerPrizes/${eventPlayerId}`;
      
      const res = await axios.get(requestPath, {
        headers: headers,
      });

      if (res.status === 200) {
        const queryData = res.data.data;
        dispatch({
          type: PRIZE_EVENT_PLAYER_PRIZES,
          payload: queryData,
        });
      } else {
        dispatch({
          type: PRIZE_FAIL,
          payload: 'status.get-error',
        });
      }

    } catch(error: any) {
      if (error.response && error.response.data.status_code === 422) {
				//Showing the error message when something went wrong during the BE validation.
				dispatch({
					type: PRIZE_FAIL,
					payload: error.response.data.message,
				});
			} else {
        dispatch({
          type: PRIZE_FAIL,
          payload: 'status.get-error'
        });
      }
    }  
  } else {
    dispatch({
      type: PRIZE_FAIL,
      payload: 'status.get-error'
    });
  }
}
    
/**
 * Create an event prize Label. 
 * @param { any } eventPrizeLabel
 */
export const registerEventPrizeLabel = (eventPrizeLabel: any) => async (dispatch:Dispatch<PrizeDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: PRIZE_LOADING,
        payload: 'ADD_PRIZE_EVENT'
      });

      const configPOST = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
      
      const addRes = await axios.post(`${apiURL}/admin/registerLabelValues`, eventPrizeLabel, configPOST);
      
      if (addRes.status === 200) {
        dispatch({
          type: ADD_PRIZE_EVENT,
          payload: 'status.success-create-label'
        });
      }

    } catch(error: any) {
      if (error.response && error.response.data.status_code === 422) {
				//Showing the error message when something went wrong during the BE validation.
				dispatch({
					type: PRIZE_FAIL,
					payload: error.response.data.message,
				});
			} else {
        dispatch({
          type: PRIZE_FAIL,
          payload: 'status.create-error'
        });
      }
    }

  } else {
    dispatch({
      type: PRIZE_FAIL,
      payload: 'status.create-error'
    });
  }
}

/**
 * Update Label Values. 
 * @param { any } eventPrizeLabel
 */
export const updateLabelValues = (eventPrizeLabel: any) => async (dispatch:Dispatch<PrizeDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: PRIZE_LOADING,
        payload: 'ADD_PRIZE_EVENT'
      });

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
      
      const addRes = await axios.put(`${apiURL}/admin/updateLabelValues`, eventPrizeLabel, configPUT);
      
      if (addRes.status === 200) {
        dispatch({
          type: ADD_PRIZE_EVENT,
          payload: 'status.success-update-label-values'
        });
      }

    } catch(error: any) {
      if (error.response && error.response.data.status_code === 422) {
				//Showing the error message when something went wrong during the BE validation.
				dispatch({
					type: PRIZE_FAIL,
					payload: error.response.data.message,
				});
			} else {
        dispatch({
          type: PRIZE_FAIL,
          payload: 'status.create-error'
        });
      }
    }

  } else {
    dispatch({
      type: PRIZE_FAIL,
      payload: 'status.create-error'
    });
  }
}

/**
 * Create an event prize. 
 * @param { any } eventPrize
 */
export const registerEventPrize = (eventPrize: any) => async (dispatch:Dispatch<PrizeDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: PRIZE_LOADING,
        payload: 'ADD_PRIZE_EVENT'
      });

      const configPOST = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
      
      const addRes = await axios.post(`${apiURL}/admin/registerEventPrize`, eventPrize, configPOST);
      
      if (addRes.status === 200) {
        dispatch({
          type: ADD_PRIZE_EVENT,
          payload: 'status.success-create-prize'
        });
      }

    } catch(error: any) {
      if (error.response && error.response.data.status_code === 422) {
				//Showing the error message when something went wrong during the BE validation.
				dispatch({
					type: PRIZE_FAIL,
					payload: error.response.data.message,
				});
			} else {
        dispatch({
          type: PRIZE_FAIL,
          payload: 'status.create-error'
        });
      }
    }

  } else {
    dispatch({
      type: PRIZE_FAIL,
      payload: 'status.create-error'
    });
  }
}

/**
 * Get All Prizes and details.
 */

export const getAllPrizes = () => async (dispatch:Dispatch<PrizeDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: PRIZE_LOADING,
        payload: 'UPDATE_PRIZE_EVENT'
      });

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
      
      const addRes = await axios.get(`${apiURL}/admin/getAllLabelsForPrizes/`, configPUT);
      
      if (addRes.status === 200) {
        dispatch({
          type: EVENT_GET_ALL_PRIZES_CATEGORIES,
          payload: addRes.data.data
        });
      }

    } catch(error: any) {
      if (error.response && error.response.data.status_code === 422) {
				//Showing the error message when something went wrong during the BE validation.
				dispatch({
					type: PRIZE_FAIL,
					payload: error.response.data.message,
				});
			} else {
        dispatch({
          type: PRIZE_FAIL,
          payload: 'status.error-update'
        });
      }
    }

  } else {
    dispatch({
      type: PRIZE_FAIL,
      payload: 'status.error-update'
    });
  }
}


/**
 * Update the information of an event prize.
 * @param { any } eventPrize
 * @param { string } eventPrizeId
 */
export const updateEventPrize = (eventPrize: any, eventPrizeId: string) => async (dispatch:Dispatch<PrizeDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: PRIZE_LOADING,
        payload: 'UPDATE_PRIZE_EVENT'
      });

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
      
      const addRes = await axios.put(`${apiURL}/admin/updateEventPrize/${eventPrizeId}`, eventPrize, configPUT);
      
      if (addRes.status === 200) {
        dispatch({
          type: UPDATE_PRIZE_EVENT,
          payload: 'status.success-update'
        });
      }

    } catch(error: any) {
      if (error.response && error.response.data.status_code === 422) {
				//Showing the error message when something went wrong during the BE validation.
				dispatch({
					type: PRIZE_FAIL,
					payload: error.response.data.message,
				});
			} else {
        dispatch({
          type: PRIZE_FAIL,
          payload: 'status.error-update'
        });
      }
    }

  } else {
    dispatch({
      type: PRIZE_FAIL,
      payload: 'status.error-update'
    });
  }
}

/**
 * Deletes an event prize
 * @param { string } eventPrizeId
 */
export const deleteEventPrize = (eventPrizeId: string) => async (dispatch:Dispatch<PrizeDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: PRIZE_LOADING,
        payload: 'DELETE_PRIZE_EVENT'
      });

      const configDELETE = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
      
      const deleteRes = await axios.delete(`${apiURL}/admin/deleteEventPrize/${eventPrizeId}`, configDELETE);
      
      if (deleteRes.status === 200) {
        dispatch({
          type: DELETE_PRIZE_EVENT,
          payload: 'status.success-delete-event-prize'
        });
      }

    } catch(error: any) {
      if (error.response && error.response.data.status_code === 422) {
				//Showing the error message when something went wrong during the BE validation.
				dispatch({
					type: PRIZE_FAIL,
					payload: error.response.data.message,
				});
			} else {
        dispatch({
          type: PRIZE_FAIL,
          payload: 'status.delete-error'
        });
      }
    }

  } else {
    dispatch({
      type: PRIZE_FAIL,
      payload: 'status.delete-error'
    });
  }
}

/**
 * Deletes a value from a Label
 * @param { string } labelValueId
 */
export const deleteLabelValue = (labelValueId: string) => async (dispatch:Dispatch<PrizeDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: PRIZE_LOADING,
        payload: 'DELETE_PRIZE_EVENT'
      });

      const configDELETE = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
      
      const deleteRes = await axios.delete(`${apiURL}/admin/deleteLabelValues/${labelValueId}`, configDELETE);
      
      if (deleteRes.status === 200) {
        dispatch({
          type: DELETE_PRIZE_EVENT,
          payload: 'status.success-delete-label-value'
        });
      }

    } catch(error: any) {
      if (error.response && error.response.data.status_code === 422) {
				//Showing the error message when something went wrong during the BE validation.
				dispatch({
					type: PRIZE_FAIL,
					payload: error.response.data.message,
				});
			} else {
        dispatch({
          type: PRIZE_FAIL,
          payload: 'status.delete-error'
        });
      }
    }

  } else {
    dispatch({
      type: PRIZE_FAIL,
      payload: 'status.delete-error'
    });
  }
}


/**
 * Register prizes to an Event.
 * @param { any[] } prizesList
 * @param { string } eventPrizeId
 */
export const managePrizeToEvent = (prizesList: any[], eventPrizeId: string) => async (dispatch:Dispatch<PrizeDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: PRIZE_LOADING,
        payload: 'MANAGE_PRIZE_TO_EVENT'
      });

      const prizesToAddOnEvent:any = {
        eventId : eventPrizeId,
        prizesList: prizesList
      };

      const configPOST = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
      
      const addRes = await axios.post(`${apiURL}/seller/managePrizeToEvent`, prizesToAddOnEvent, configPOST);
      
      if (addRes.status === 200) {
        dispatch({
          type: MANAGE_PRIZE_TO_EVENT,
          payload: 'status.success-update'
        });
      }

    } catch(error: any) {
      if (error.response && error.response.data.status_code === 422) {
				//Showing the error message when something went wrong during the BE validation.
				dispatch({
					type: PRIZE_FAIL,
					payload: error.response.data.message,
				});
			} else {
        dispatch({
          type: PRIZE_FAIL,
          payload: 'status.error-update'
        });
      }
    }

  } else {
    dispatch({
      type: PRIZE_FAIL,
      payload: 'status.error-update'
    });
  }
}

/**
 * Retrieves the event prize details.
 * @param { number } eventId
 * @param { string } itemName
 */
export const getItemsForPrize = (eventId: number, itemName: string) => async (dispatch:Dispatch<PrizeDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: PRIZE_LOADING,
        payload: 'PRIZE_ITEMS_FOR_PRIZE'
      });
      
      const headers: object = {
        ContentType: "application/json",
        Authorization: "Bearer " + token,
      };
      
      const requestPath = `${apiURL}/seller/getItemsForPrize/${eventId}`;
      
      const res = await axios.get(requestPath, {
        headers: headers,
        params: { itemName: itemName },
      });

      if (res.status === 200) {
        const queryData = res.data.data;
        dispatch({
          type: PRIZE_ITEMS_FOR_PRIZE,
          payload: queryData,
        });
      } else {
        dispatch({
          type: PRIZE_FAIL,
          payload: 'status.get-error',
        });
      }

    } catch(error: any) {
      if (error.response && error.response.data.status_code === 422) {
				//Showing the error message when something went wrong during the BE validation.
				dispatch({
					type: PRIZE_FAIL,
					payload: error.response.data.message,
				});
			} else {
        dispatch({
          type: PRIZE_FAIL,
          payload: 'status.get-error'
        });
      }
    }
  } else {
    dispatch({
      type: PRIZE_FAIL,
      payload: 'status.get-error'
    });
  }
}

/**
 * Getting all the Prizes by category
 * @param { number } categoryId
 */

export const getAllEventPrizes =  (categoryId:number) => async (dispatch:Dispatch<PrizeDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: PRIZE_LOADING,
        payload: 'EVENT_REGISTER_PRIZE_TO_PLAYER'
      });
      const configPOST = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
      
      const addRes = await axios.get(`${apiURL}/admin/getAllEventPrizes?itemTypeId=${categoryId}`, configPOST);
      
      if (addRes.status === 200) {
        dispatch({
          type: EVENT_GET_ALL_PRIZES,
          payload: addRes.data.data.content
        });
      }

    } catch(error: any) {
      if (error.response && error.response.data.status_code === 422) {
				//Showing the error message when something went wrong during the BE validation.
				dispatch({
					type: PRIZE_FAIL,
					payload: error.response.data.message,
				});
			} else {
        dispatch({
          type: PRIZE_FAIL,
          payload: 'status.error-update'
        });
      }
    }
  } else {
    dispatch({
      type: PRIZE_FAIL,
      payload: 'status.error-update'
    });
  }
}

/**
 * Register an event prize to a player.
 * @param { number } eventPlayerId
 * @param { number } eventPrizeId
 * @param { number } itemId
 * @param { number } quantity
 */
export const registerEventPrizeToPlayer = (eventPlayerId: number, eventPrizeId: number, itemId: number, quantity: number ) => async (dispatch:Dispatch<PrizeDispatchTypes>) => {
  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: PRIZE_LOADING,
        payload: 'EVENT_REGISTER_PRIZE_TO_PLAYER'
      });

      const eventPrizeToPlayer:any = {
        eventPlayerId: eventPlayerId,
        eventPrizeId: eventPrizeId,
        itemId: itemId,
        quantity: quantity
      };

      const configPOST = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };
      
      const addRes = await axios.post(`${apiURL}/seller/registerEventPrizeToPlayer`, eventPrizeToPlayer, configPOST);
      
      if (addRes.status === 200) {
        dispatch({
          type: EVENT_REGISTER_PRIZE_TO_PLAYER,
          payload: 'status.success-update'
        });
      }

    } catch(error: any) {
      if (error.response && error.response.data.status_code === 422) {
				//Showing the error message when something went wrong during the BE validation.
				dispatch({
					type: PRIZE_FAIL,
					payload: error.response.data.message,
				});
			} else {
        dispatch({
          type: PRIZE_FAIL,
          payload: 'status.error-update'
        });
      }
    }
  } else {
    dispatch({
      type: PRIZE_FAIL,
      payload: 'status.error-update'
    });
  }
}
