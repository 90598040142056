import { ReportsProvider } from '../reports/ReportsContext';
import ReportsComponent from '../reports/ReportsComponent';

const Reports = ():JSX.Element => {
  return (
    <ReportsProvider>
      <ReportsComponent/>
    </ReportsProvider>
  )
}

export default Reports;