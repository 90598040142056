// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(2, 1),
        padding: theme.spacing(4, 4, 1),
        width: '100%',
        height: 'auto',
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(4, 2, 1),
        },
      },
    },
    boxBottom:{
      margin:'0',
      padding:'0',
      display:'flex',
      justifyContent:'space-around'
    },
    boxForm: {
      width:'70%',
      '& > *': {
        marginBottom: theme.spacing(2),
        width: '100%'
      },
    },
    fieldClass: {
      marginRight: theme.spacing(2),
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
    formControl: {
      width: '100%',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems:'flex-end',
      alignSelf:'auto',
      height:'60px',
      width:'20%',
      margin:'0',
      padding:'0',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    buttonsLast: {
      marginRight: 0
    }
  }),
);

export default useStyles;
