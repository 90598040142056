import grey from "@material-ui/core/colors/grey"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(2, 1),
        padding: theme.spacing(4, 4, 1),
        width: "100%",
        height: "auto",
        [theme.breakpoints.down("xs")]: {
          padding: theme.spacing(4, 2, 1),
        },
      },
    },
    containerButtons: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(2, 1),
        padding: theme.spacing(4, 4, 5),
        width: "100%",
        height: "auto",
        [theme.breakpoints.down("xs")]: {
          padding: theme.spacing(4, 2, 1),
        },
      },
    },
    boxTop: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      "& > *": {
        marginRight: theme.spacing(2),
      },
    },
    boxForm: {
      marginRight: '-24px',
      "& > *": {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: "100%",
      },
    },
    boxBottom: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        marginBottom: theme.spacing(2),
      },
    },
    fieldClass: {
      marginRight: theme.spacing(2),
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
      },
    },
    formControl: {
      width: "100%",
    },
    buttons: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      gap: theme.spacing(2),
    },
    viewProduct: {
      display: "flex",
      justifyContent: "end",
      marginTop: theme.spacing(2),
    },
    buttonsLast: {
      marginRight: 0,
    },
    summaryCardItem: {
      background: grey[100],
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.25)",
      borderRadius: "6px",
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
      marginRight: theme.spacing(1),
      [theme.breakpoints.down("md")]: {
        marginRight: 0,
      },
      "& .MuiAvatar-root": {
        width: "80px",
        height: "auto",
        margin: "0 auto",
        [theme.breakpoints.down("xs")]: {
          width: "50px",
          height: "50px",
        },
      },
    },
    modalProductInformation: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    containerModal: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "50%",
      maxWidth: "90%", // Opcional: limita el ancho máximo
      padding: "16px",
      margin: "auto", // Centra el modal
      borderRadius: "8px",
      overflowY: "auto",
      overflowX: "hidden",
      backgroundColor: "#fff",
    },
    productInformationPaper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      alignItems: "center",
    },
    imageProduct: {
      width: "100px",
      height: "100px", // Ajustar según necesidad
      borderRadius: "8px",
    },
    tableContainer: {
      maxHeight: "300px",
      overflowY: "auto",
      overflowX: "auto",
      paddingBottom: theme.spacing(2),
      width: "100%",
    },
    tableHeaderCell: {
      backgroundColor: theme.palette.primary.main,
      fontWeight: "bold",
      textAlign: "center",
      color: "#fff",
    },
    tableCell: {
      textAlign: "center",
    },
    quantityInput: {
      width: "100px",
    },
    buttonBottomModal: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    buttonsCard: {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      gap: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
    progressContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
    },
    suggestedItemsContainer: {
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing(4),
    },
    sumarryTop: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
  })
)
