import React,  { Fragment, useEffect } from 'react';

// Material UI
import ListAltIcon from '@material-ui/icons/ListAlt';
import Typography from '@material-ui/core/Typography';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getEventPlayerPrizes } from "../../../actions/prizeActions/PrizeActions";
import { RootStore } from "../../../Store";

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './AssignPrizeDetailsModalStyles';

// Model types.
import { AwardType } from '../../../actions/prizeActions/PrizeActionsTypes';

// Component,
import AssignPrizeAccordionModal from './AssignPrizeAccordionModal';

interface AwardPrizeItemProps {
  player: AwardType;
}

const AssignPrizeDetailsModal = ({ player } : AwardPrizeItemProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();

  // Redux state.
  const prizeReduxState = useSelector((state: RootStore) => state.prize);
  const { actionStatus, eventPlayerPrizes } = prizeReduxState;

  useEffect(() => {
    dispatch(getEventPlayerPrizes(player.playerId));
    
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (actionStatus !== undefined) {
      if (actionStatus.message === 'status.success-update' && actionStatus.status === 'success') {
        dispatch(getEventPlayerPrizes(player.playerId));
      }
    }

    // eslint-disable-next-line
  }, [actionStatus]);

  return (
    <div className={ classes.wrapperDetails }>
      {eventPlayerPrizes !== undefined && eventPlayerPrizes.map((prizes: any, i: number) => {
        return(
          <Fragment key={i}>
            <div className={classes.root}>
              <div className={classes.boxTop}>
                <ListAltIcon color="primary" fontSize="medium" />
                <Typography color="primary" className={classes.sectionTitle}>
                  {prizes.prizeName} : {prizes.realQuantity} {t("prizes.of")} {prizes.suggestedQuantity}
                </Typography>
              </div>
              <div className={classes.boxBottom}>
                <AssignPrizeAccordionModal itemList={prizes.itemList} player={player} prizeId={prizes.prizeId} />
              </div>
            </div>
          </Fragment>
        );
      })}
    </div>
  )
}

export default AssignPrizeDetailsModal;
