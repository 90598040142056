import {Dispatch} from "redux";
import axios from 'axios';
import {
    PAYMENT_METHODS_LOADING,
    PaymentDispatchTypes,
    PAYMENT_METHODS_LIST,
    CardTokenEcrypted,
    CardRegistration,
    PAYMENT_METHOD_REGISTER,
    PAYMENT_METHOD_UNREGISTER,
    GET_ALL_PAYMENT_METHODS
} from './UserPaymentMethodsActionsTypes';

import aesjs from 'aes-js';
import JSEncrypt from "jsencrypt";

const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;

let encrypt = new JSEncrypt();
encrypt.setPublicKey(process.env.REACT_APP_GREENPAY_TOKEN ?? 'no-token');

export const getPaymentMethods = () => async (dispatch: Dispatch<PaymentDispatchTypes>) => {
    const sessionToken = localStorage.token;

    if (apiURL !== '' && token !== '') {
        dispatch({
            type: PAYMENT_METHODS_LOADING,
            payload: 'PAYMENT_METHODS_LOADING_STARTED'
        })
        const headers: object = {
            ContentType: 'application/json',
            Authorization: 'Bearer ' + sessionToken
        }
        let requestPath = `${apiURL}/user/getCards`;

        try {
            const res = await axios.get(
                requestPath,
                {
                    headers,
                }
            );
            if (res.status === 200) {
                dispatch({
                    type: PAYMENT_METHODS_LIST,
                    payload: res.data.data
                })
                dispatch({
                    type: PAYMENT_METHODS_LOADING,
                    payload: 'PAYMENT_METHODS_LOADING_COMPLETED'
                })
            }
        } catch (e) {
            dispatch({
                type: PAYMENT_METHODS_LOADING,
                payload: 'PAYMENT_METHODS_LOADING_FAILED'
            })
        }
    } else {
        dispatch({
            type: PAYMENT_METHODS_LOADING,
            payload: 'PAYMENT_METHODS_LOADING_FAILED'
        })
      }
}

export const resetRegister = () => (dispatch: Dispatch<PaymentDispatchTypes>) => dispatch({
    type: PAYMENT_METHOD_REGISTER,
    payload: 'PAYMENT_METHOD_REGISTRATION_IDLE'
})

export const registerPaymentMethod = (cardInfo:CardRegistration) => async (dispatch: Dispatch<PaymentDispatchTypes>) => {
    const sessionToken = localStorage.token;
    if (apiURL !== '' && token !== '') {
        dispatch({
            type: PAYMENT_METHOD_REGISTER,
            payload: 'PAYMENT_METHOD_REGISTERING',
        })
        const encryptedCardData:CardTokenEcrypted = pack(cardInfo, undefined);
        const configPUT = {
            headers: {
              'Accept': '*/*',
              'Authorization': 'Bearer ' + sessionToken,
              'Content-Type': 'application/json',
            }
        };
        let requestPath = `${apiURL}/user/registerCard`;

        try {
            const res = await axios.post(
                requestPath,
                encryptedCardData,
                configPUT
            );
            if (res.status === 200) {
                dispatch({
                    type: PAYMENT_METHOD_REGISTER,
                    payload: 'PAYMENT_METHOD_SUCCESSFULLY_REGISTERED',
                })
                //'PAYMENT_METHOD_REGISTRATION_IDLE'
                dispatch({
                    type: PAYMENT_METHOD_REGISTER,
                    payload: 'PAYMENT_METHOD_REGISTRATION_IDLE',
                })
            } else {
                dispatch({
                    type: PAYMENT_METHOD_REGISTER,
                    payload: 'PAYMENT_METHOD_REGISTRATION_FAILED'
                })
            }
        } catch(e) {
            dispatch({
                type: PAYMENT_METHOD_REGISTER,
                payload: 'PAYMENT_METHOD_REGISTRATION_FAILED'
            }) 
        }
    } else {
        dispatch({
          type: PAYMENT_METHOD_REGISTER,
          payload: 'PAYMENT_METHOD_REGISTRATION_FAILED'
        })
    }
}

export const unregisterPaymentMethod = (cardToken:string) => async (dispatch: Dispatch<PaymentDispatchTypes>) => {
    const sessionToken = localStorage.token;
    if (apiURL !== '' && token !== '') {
        dispatch({
            type: PAYMENT_METHOD_UNREGISTER,
            payload: 'UNREGISTER_START',
        })

        const configPUT = {
            headers: {
              'Accept': '*/*',
              'Authorization': 'Bearer ' + sessionToken,
              'Content-Type': 'application/json',
            }
          };

        try {
            const res = await axios.delete(`${apiURL}/user/deleteCard/${cardToken}`,configPUT);
            if (res.status === 200) {
                dispatch({
                    type: PAYMENT_METHOD_UNREGISTER,
                    payload: 'UNREGISTER_DONE',
                })
            }
        } catch(e) {
            dispatch({
                type: PAYMENT_METHOD_UNREGISTER,
                payload: 'UNREGISTER_ERROR',
            })
        }
    
    } else {
        dispatch({
          type: PAYMENT_METHOD_UNREGISTER,
          payload: 'status.error'
        })
    }
}

function pack(obj:any, pair_:any) {

    const generateAESPairs = () => {
        let key = []
        let iv = 0;
        for (let k = 0; k < 16; k++) {
            key.push(Math.floor(Math.random() * 255))
        }
        for (let k = 0; k < 16; k++) {
            iv = Math.floor(Math.random() * 255)
        }
        return {
            k: key,
            s: iv
        }
    }

    var pair = (pair_ !== undefined) ? pair_ : generateAESPairs();
    var textBytes = aesjs.utils.utf8.toBytes(JSON.stringify(obj));
    var aesCtr = new aesjs.ModeOfOperation.ctr(pair.k, new aesjs.Counter(pair.s));
    var encryptedBytes = aesCtr.encrypt(textBytes);
    var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
    var returnObj = {
        ld:encryptedHex,
        lk:encrypt.encrypt(JSON.stringify(pair))
    };
    return returnObj;
}

/**
 * Get a list of all payment methods availables.
 */
 export const getAllPaymentMethods = () => async (dispatch: Dispatch<PaymentDispatchTypes>) => {

    if (apiURL !== '' && token !== '') {
      try {
        dispatch({
          type: PAYMENT_METHODS_LOADING,
          payload: 'GETTING_LIST'
        })
  
        const headers: object = {
          ContentType: 'application/json',
          Authorization: 'Bearer ' + token
        }
  
        const res = await axios.get(`${apiURL}/seller/getAllPaymentMethods`, {
          headers: headers
         });
  
         if (res.status === 200) {
          const queryData = res.data.data.paymentMethods;
  
          dispatch({
            type: GET_ALL_PAYMENT_METHODS,
            payload: queryData
          })
         } else {
          dispatch({
            type: PAYMENT_METHODS_LOADING,
            payload: 'GET_FAIL'
          })
         }
      } catch(e) {
        dispatch({
          type: PAYMENT_METHODS_LOADING,
          payload: 'GET_FAIL'
        })
      }
    } else {
      dispatch({
        type: PAYMENT_METHODS_LOADING,
        payload: 'GET_FAIL'
      })
    }
  };