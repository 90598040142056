// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(2),
      '& > *': {
        margin: theme.spacing(1),
        padding: theme.spacing(4, 4),
        width: '100%',
        height: 'auto',
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(4, 2),
        },
      },
      '& .MuiCircularProgress-root': {
        width: '26px !important',
        height: '26px !important'
      },
      '& .MuiSvgIcon-colorPrimary': {
        color: theme.palette.primary.main,
      },
      '& .MuiSvgIcon-fontSizeLarge': {
        fontSize: '2.1875rem',
      }
    },
    sectionTitle: {
      flexGrow: 1,
    },
    sectionTitleDisable: {
      color: '#828282',
    },
    sectionTitleEnable : {
      color: theme.palette.primary.main,
    },
    boxTop: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      '& > *': {
        marginRight: theme.spacing(2),
      },
    },
    boxForm: {
      '& > *': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%'
      },
    },
    actionsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(4),
      '& button': {
        marginLeft: theme.spacing(0),
        [theme.breakpoints.down('xs')]: {
          marginLeft: theme.spacing(1),
        },
      }
    },
    formControl: {
      margin: theme.spacing(1),
      display: 'block',
      '& > *': {
        display: 'block',
      },
      '& .MuiFormGroup-root': {
        padding: theme.spacing(1, 0),
      }
    },
    descripLabel: {
      marginBottom: '5px'
    },
    formField: {
      width: '100%',
      '& .Mui-disabled:before': {
        borderBottom: 0
      },
      '&.Mui-disabled:before': {
        borderBottom: 0
      },
      '& svg': {
        display: 'none'
      },
      '& > div': {
        width: '100%',
      },
    },
    modalPaper: {
      padding: theme.spacing(6, 4),
      position: 'absolute',
      width: '400px',
      height: 'auto',
      top: 'calc(30% - 100px)',
      left: 'calc(50% - 200px)',
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(6, 2),
        width: '90%',
        left: '5%'
      },
      '& .MuiTypography-root': {
        marginBottom: theme.spacing(2),
      },
      '& .MuiButtonBase-root': {
        marginLeft: theme.spacing(2),
      },
      '& div > form': {
        padding: theme.spacing(0)
      },
      '& .MuiTypography-h5': {
        textAlign: 'center',
      },
      '& .MuiSvgIcon-colorPrimary': {
        color: theme.palette.primary.main,
      },
      '& .MuiSvgIcon-fontSizeLarge': {
        fontSize: '2.1875rem',
      }
    },
    modalPaperConfirm: {
      textAlign: 'center'
    },
    closeModal: {
      position: 'absolute',
      right: '0',
      top: '0'
    },
    circularProgress: {
      textAlign: 'center',
    },
    backdrop: {
      zIndex: 1301,
      color: '#fff',
      '& .MuiPaper-root': {
        minWidth: '300px',
        padding: theme.spacing(2, 1),
        textAlign: 'center'
      }
    }
  }),
);

export default useStyles;
