/**
*****************************************
*********** Dispatch Types **************
*****************************************
*/
export const VENDOR_LOADING = "VENDOR_LOADING";
export const VENDOR_FAIL = "VENDOR_FAIL";
export const VENDOR_LIST = "VENDOR_LIST";
export const VENDOR_LIST_USER = "VENDOR_LIST_USER";
export const ALL_VENDORS= "ALL_VENDORS";
export const ADD_VENDOR = "ADD_VENDOR";
export const VENDOR_PUT = "VENDOR_PUT";
export const VENDOR_REFRESH = "VENDOR_REFRESH";
export const VENDOR_SET_FILTER = "VENDOR_SET_FILTER";
export const VENDORS_BY_SELLER = "VENDORS_BY_SELLER";

/**
*****************************************
************* Object Types **************
*****************************************
*/

export interface AddVendorObject {
  adminOnly: boolean,
  name: string,
  phoneNumber: string,
  vendorCode: string,
  address: AddressType,
  type?: string,
  vendorCategories?: Array<string>
}
export interface AddressType {
  canton: string,
  district: string,
  fullAddress: string,
  phoneNumber?: string,
  postalCode: string,
  province: string
}
export interface Vendor {
  id: number,
  name: string,
  coordinates?: string,
  phoneNumber?: string,
  address: AddressType,
  warehouses: [{
    warehousesitems: WarehousesItems[]
  }],
  vendorCategories?: string[],
  type?: string
}
export interface categoryName {
  category: string
}
export interface categoryItem {
  id: categoryName
}
export interface VendorListComplete {
  id: number,
  name: string,
  isAdminOnly: boolean,
  phoneNumber: string,
  vendorCode?: string,
  sequence: number,
  address: AddressType,
  type?: string,
  categoriesArray?: string[],
  vendorCategories?: categoryItem[],
  vendorCategoriesToAdd?: string,
  vendorCategoriesToDelete?: string
}

export interface VendorListResponse {
  vendors: VendorListComplete[],
  first: number,
  last: number,
  number: number,
  size: number,
  totalPages: number,
}

export type VendorPutResponse = {
  message: string
  vendor: AddVendorObject
};
export interface WarehousesItems {
  id: {
    warehouseId: number
    itemId: number
  },
  quantity:number
}

export type VendorBySellerResponse = {
  addressId: number,
  name: string,
  vendorId: number
};

/**
*****************************************
*********** Interfaces Types ************
*****************************************
*/
export interface VendorLoading {
  type: typeof VENDOR_LOADING
  payload: string
}

export interface VendorFail {
  type: typeof VENDOR_FAIL,
  payload: string
}

export interface VendorList {
  type: typeof VENDOR_LIST,
  payload: Vendor[]
}

export interface VendorListUser {
  type: typeof VENDOR_LIST_USER,
  payload: Vendor[]
}

export interface AllVendors {
  type: typeof ALL_VENDORS,
  payload: VendorListResponse
}

export interface VendorAdd {
  type: typeof ADD_VENDOR,
  payload: string,
  vendor: AddVendorObject
}
export interface vendorUpdate {
  type: typeof VENDOR_PUT,
  payload: VendorPutResponse
};

export interface VendorRefresh {
  type: typeof VENDOR_REFRESH,
  payload: boolean
}

export interface VendorSetFilter {
  type: typeof VENDOR_SET_FILTER,
  payload: object
}

export interface GetVendorsBySeller {
  type: typeof VENDORS_BY_SELLER,
  payload: VendorBySellerResponse[]
}

export type VendorDispatchTypes = VendorLoading | vendorUpdate | VendorFail | VendorLoading 
  | AllVendors | VendorList | VendorAdd | VendorRefresh | VendorSetFilter | VendorListUser | GetVendorsBySeller;
