import {Dispatch} from "redux";
import axios from 'axios';
import {ADDRESS_FAIL, ADDRESS_PUT, ADDRESS_LOADING, ADD_ADDRESS, ADD_CUSTOM_ADDRESS, UserAddressDispatchTypes} from "./UserAddressActionsTypes";

//  JWT decode token.
import jwtDecode from 'jwt-decode';

const apiURL: string | undefined = process.env.REACT_APP_API_URL;
const token: string | undefined = localStorage.token;

/**
 * Register a new user address on BE.
 * @param {any} address
 * @param {number} index
 */
export const addUserAddress = (address: any, index: number = 0) => async (dispatch: Dispatch<UserAddressDispatchTypes>) => {

  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: ADDRESS_LOADING,
        payload: 'ADD_ADDRESS'
      })

      const bodyForm = {
        "canton": address.canton,
        "district": address.district,
        "fullAddress": address.fullAddress,
        "phoneNumber": address.phoneNumber,
        "postalCode": address.postalCode,
        "province": address.province,
        "userEmail": address.userEmail,
      };

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };

      const decodedToken:any = jwtDecode(String(token));
      let requestPath = (decodedToken.auth.includes('ROLE_USER') || decodedToken.auth.includes('ROLE_SUPER_SELLER') || decodedToken.auth.includes('ROLE_SELLER')) ? 
                        `${apiURL}/user/registerAddress` : `${apiURL}/admin/registerUserAddress`;
    
      const addRes = await axios.post(requestPath, bodyForm, configPUT);
      if (addRes.status === 200) {
        dispatch({
          type: ADD_ADDRESS,
          payload: { id: addRes.data.data.addresses[0].id, message: 'status.success-create', index: index}
        })
      }
       
    } catch(e) {
      dispatch({
        type: ADDRESS_FAIL,
        payload: 'status.create-error'
      })
    }
  } else {
    dispatch({
      type: ADDRESS_FAIL,
      payload: 'status.create-error'
    })
  }
};

/**
 * Update a user address on BE.
 * @param {any} address
 */
export const updateUserAddress = (address: any) => async (dispatch: Dispatch<UserAddressDispatchTypes>) => {
  try {
    dispatch({
      type: ADDRESS_LOADING,
      payload: 'ADDRESS_UPDATE'
    })

    const bodyForm = {
      "canton": address.canton,
      "district": address.district,
      "fullAddress": address.fullAddress,
      "id": address.id,
      "phoneNumber": address.phoneNumber,
      "postalCode": address.postalCode,
      "province": address.province,
    };

    const configPUT = {
      headers: {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    };

    const decodedToken:any = jwtDecode(String(token));
    let requestPath = (decodedToken.auth.includes('ROLE_USER') || decodedToken.auth.includes('ROLE_SUPER_SELLER') || decodedToken.auth.includes('ROLE_SELLER')) ?
                      `${apiURL}/user/updateAddress` : `${apiURL}/admin/updateUserAddress`;
  
    const res = await axios.put(requestPath, bodyForm, configPUT);
    if (res.status === 200) {
      dispatch({
        type: ADDRESS_PUT,
        payload: 'status.success-update'
      })
    } else {
      dispatch({
        type: ADDRESS_FAIL,
        payload: 'status.error-update'
      })
    }

  } catch(e) {
    dispatch({
      type: ADDRESS_FAIL,
      payload: 'status.error-update'
    })
  }
};

/**
 * Register a new custom address on BE.
 * @param {any} address
 */
 export const addCustomAddress = (address: any) => async (dispatch: Dispatch<UserAddressDispatchTypes>) => {

  if (apiURL !== '' && token !== '') {
    try {
      dispatch({
        type: ADDRESS_LOADING,
        payload: 'ADD_ADDRESS'
      })

      const bodyForm = {
        "canton": address.canton,
        "district": address.district,
        "fullAddress": address.fullAddress,
        "phoneNumber": address.phoneNumber,
        "postalCode": address.postalCode,
        "province": address.province,
        "userEmail": address.userEmail,
      };

      const configPUT = {
        headers: {
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      };

      let requestPath = `${apiURL}/seller/registerCustomAddress`
    
      const addRes = await axios.post(requestPath, bodyForm, configPUT);

      if (addRes.status === 200) {
        dispatch({
          type: ADD_CUSTOM_ADDRESS,
          payload: addRes.data.data.id
        })
      }
       
    } catch(e) {
      dispatch({
        type: ADDRESS_FAIL,
        payload: 'status.create-error'
      })
    }
  } else {
    dispatch({
      type: ADDRESS_FAIL,
      payload: 'status.create-error'
    })
  }
};

