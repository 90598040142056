import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: '2px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& img': {
        width: '100%',
        height: '100%'
      },
      '&.color-gray': {
        backgroundColor: theme.palette.primary.dark,
      }
    },
  }),
);

export default useStyles;