import React, { Fragment, useState } from 'react';
import ShowMoreText from "react-show-more-text";

// Material UI.
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import StarsIcon from '@material-ui/icons/Stars';
import Paper from '@material-ui/core/Paper';

// Components.
import NumberFormat from '../../admin/utils/NumberFormat';
import AddToWishList from '../utils/AddToWishlist';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { addItemToCart, addItemToCartLocal } from "../../../actions/cartActions/CartActions";
import { getUserInformation } from '../../../actions/authActions/AuthActions';
import { RootStore } from "../../../Store";

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

// Styles.
import useStyles from './ItemCardStyles';

// Translations.
import { useTranslation } from 'react-i18next';
import defaultImage from '../../../images/default-img.jpg';

// Types interfase.
interface ItemCardProps {
  item: any;
};
const ItemCard = ({ item }: ItemCardProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();

  // Redux state.
  const userReduxState = useSelector((state: RootStore) => state.auth);
  const cartReduxState = useSelector((state: RootStore) => state.cart);
  const cartLoading = cartReduxState.loading;

  const [quantities, setQuantities] = useState<any>({});
  var countAvailable = -1;

  const imgURL = (item.imageURL) ? item.imageURL : defaultImage;

  const handleQuantityChange = (id: string, value: unknown) => {
    if (id !== undefined && value !== undefined) {
      const newValues = quantities;
      newValues[id] = value;
      if (Number(value) >= 0) {
        setQuantities({ ...quantities, newValues });
      }
    }
  }

  const addToCart = (itemChild: any) => {
    let quantity = quantities[itemChild.itemId] > 0 ? quantities[itemChild.itemId] : 1;
    if (localStorage.token) {
      // If user is logged.
      dispatch(addItemToCart(itemChild.itemId, quantity));
      dispatch(getUserInformation());
    } else {
      // If user is anonimous.
      var cart = localStorage.anonymousCart !== undefined ? JSON.parse(localStorage.anonymousCart) : [];
      // validate if already exists.
      const index = cart.findIndex((obj: any) => obj.item.itemId === itemChild.itemId);
      if (index !== -1) {
        let currentQty = cart[index].quantity;
        cart[index] = { item: itemChild, quantity: (currentQty + quantity), itemName: item.name, isFull: false };
      } else {
        let images = [{ url: imgURL }];
        let itemCopy = { ...itemChild, images: images }
        cart.push({ item: itemCopy, quantity: quantity, itemName: item.name, isFull: false });
      }
      localStorage.removeItem('anonymousCart');
      localStorage.setItem('anonymousCart', JSON.stringify(cart));
      dispatch(addItemToCartLocal());
    }

    // Re-Set the state value.
    const newValues = quantities;
    newValues[itemChild.itemId] = getAllWarehousesQuantity(itemChild) > 0 ? 1 : 0;
    setQuantities({ ...quantities, newValues });
  };

  // Gets the total quantity from all vendor warehouses.
  const getAllWarehousesQuantity = (currentItem: any) => {
    var result = Number(currentItem.totalQuantity);
    
    // Check if there is already items added of this.
    if (userReduxState.loggedUser?.cart !== undefined) {
      userReduxState.loggedUser.cart.forEach((cartItem: any) => {
        if (cartItem.id.itemId === currentItem.itemId) {
          result = result - cartItem.quantity;
        }
      });
    }

    // Check items added for anonimous users.
    var cartLocal = localStorage.anonymousCart !== undefined ? JSON.parse(localStorage.anonymousCart) : [];
    if (cartLocal.length > 0) {
      const index = cartLocal.findIndex((obj: any) => obj.item.itemId === currentItem.itemId);
      if (index !== -1) {
        let currentQty = cartLocal[index].quantity;
        result = result - currentQty;
      }
    }

    // Check if there is already items of this bundle added.
    if (userReduxState.loggedUser?.bundleCart !== undefined) {
      userReduxState.loggedUser?.bundleCart.forEach((bundleItem: any) => {
        const matchedItems = bundleItem.bundle.items.filter((cartItem: any) => cartItem.item.itemId === currentItem.itemId);
        if (matchedItems.length > 0) {
          result = result - (bundleItem.quantity * matchedItems[0].quantity);
        }
      });
    }

    // Check if there is already items of this bundle added for anonimous users.
    var bundleCartLocal = localStorage.anonymousBundleCart !== undefined ? JSON.parse(localStorage.anonymousBundleCart) : [];
    if (bundleCartLocal.length > 0) {
      bundleCartLocal.forEach((bundleItem: any) => {
        const matchedItems = bundleItem.bundle.items.filter((cartItem: any) => cartItem.item.itemId === currentItem.itemId);
        if (matchedItems.length > 0) {
          result = result - (bundleItem.quantity * matchedItems[0].quantity);
        }
      });
    }

    if (result !== 0) {
      countAvailable = countAvailable + result;
    }
    return result;
  };

  // Quantities to render.
  const renderOptions = (quantity: number) => {
    const items = [];
    for (var i = 1; i <= quantity; i++) {
      items.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
    }
    if (items.length === 0) {
      items.push(<MenuItem key={1} value={1}>{1}</MenuItem>)
    }
    return items;
  };

  const getPriceInfo = (items: any) => {
    for(let i = 0; i < items.length; i++) {
      const hasDiscounts = 
      items[i].typeOfWarehouse === 'Discount'
        && items[i]?.quantity !== undefined
        && items[i]?.quantity > 0;
      if (hasDiscounts) {
        return (
          <>
            <Typography color="secondary" className={classes.lineThrough}>
              {getProjectConfig().CURRENCY_SYMBOL}
              <NumberFormat number={items[i].finalPrice} />
            </Typography>
            <Typography color="primary">
              {getProjectConfig().CURRENCY_SYMBOL}
              <NumberFormat number={items[i].discountFinalPrice} />
            </Typography>
          </>
        );
      }
      return (
        <Typography color="primary">
          {getProjectConfig().CURRENCY_SYMBOL}
          {<NumberFormat number={items[i].finalPrice} />}
        </Typography>
      );
    };
  };

  return (
    <Card className={classes.root}>
      <CardActionArea href={`/item/${item.code}`} type="button">
        { item?.isFeature! &&
          <div className={classes.cardFeatured}>
            <StarsIcon fontSize="large" />
          </div>
        }
        <div className={classes.cardTop}>
          <div className={classes.cardMedia}>
            <div className={classes.image}>
              <img src={imgURL} alt="Product media" loading="lazy" />
            </div>
            <div className={classes.wishlistWrapper}>
              <AddToWishList item={item}/>
            </div>
          </div>
          <div className={classes.cardDescription}>
            <Typography gutterBottom variant="h5" component="h2" color="primary" className={classes.cardTitle}>
              <strong>{item.name}</strong>
            </Typography>
            {(item.setName) &&
              <Typography variant="caption" color="textSecondary">Set: {item.setName}</Typography>
            }
            <Typography variant="body2" color="textSecondary" component="div" className="item-description">
              <ShowMoreText
                lines={3}
                more={t('items.see-more')}
                less={false}
                expanded={false}
                truncatedEndingComponent={"... "}
              >
                <div dangerouslySetInnerHTML={{ __html: item.notes }}></div>
              </ShowMoreText>
            </Typography>
          </div>
        </div>
      </CardActionArea>
      <Paper>
        <Table size="small" aria-label="a dense table" className={classes.tableWrapper}>
          <TableHead>
            <TableRow>
              <TableCell>{t('items.seller')}</TableCell>
              <TableCell>{t('items.price')}</TableCell>
              <TableCell>{t('items.quantity')}</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              item.itemSearchVendorData !== undefined &&
              (item.itemSearchVendorData.map((item: any, index:number) => 
                <Fragment key={`v-${index}`}>
                  {getAllWarehousesQuantity(item) > 0 &&
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {item.name}
                      {getAllWarehousesQuantity(item) === 0 ? t('items.out-of-stock') : ''}
                    </TableCell>
                    <TableCell className={classes.priceCol}>
                      {getPriceInfo(item.itemQuantityData)}
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <Select
                          id={`${item.itemId}`}
                          value={quantities[item.itemId] !== undefined ? quantities[item.itemId] : 1}
                          label={t('items.quantity')}
                          onChange={(e) => handleQuantityChange(item.itemId, e.target.value)}
                          disabled={getAllWarehousesQuantity(item) < 1}
                          className={classes.inputQty}
                          onOpen={() => document.body.classList.add('no-overflow')}
                          onClose={() => document.body.classList.remove('no-overflow')}
                        >
                          {renderOptions(getAllWarehousesQuantity(item))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton color="primary" aria-label="Add to cart" component="span" onClick={() => addToCart(item)} disabled={getAllWarehousesQuantity(item) === 0 || cartLoading !== ''}>
                        <AddShoppingCartIcon color={getAllWarehousesQuantity(item) > 0 ? 'secondary' : 'disabled'} />
                      </IconButton>
                    </TableCell>
                  </TableRow>}
                </Fragment>
              ))
            }
          </TableBody>
        </Table>
        {(countAvailable <= 0 && item.itemSearchVendorData !== undefined) &&
          <div className={classes.noStockMsg}>
            {t('items.no-available')}
          </div>
        }
      </Paper>
    </Card>
  );
}

export default ItemCard;
