import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiAvatar-root.MuiAvatar-rounded .MuiAvatar-img': {
        objectFit:'fill',
        height:'45px',
        width:'48.65px',
        justifyContent:'center'
      },
      
    }
  })
);