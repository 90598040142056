// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cartWrapper: {
      paddingTop: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(16),
        '&.no-items': {
          paddingTop: theme.spacing(4)
        }
      },
    },
    paperWrapper: {
      padding: theme.spacing(2),
      minHeight: '560px',
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(2),
      },
    },
    stickyContainer: {
      position: 'fixed',
      width: '17%',
      zIndex: 1,
      [theme.breakpoints.down('md')]: {
        width: '22%',
      },
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100% - 32px)',
        top: '262px'
      },
    },
    leftSidebar: {
      textAlign: 'left',
      padding: '12px',
      '& .MuiTypography-root': {
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
          marginBottom: 0,
          '&.MuiTypography-h5': {
            fontSize: '1.3rem',
            fontFamily: 'VisbyCF-Bold'
          }
        }
      },
      '& a': {
        marginBottom: theme.spacing(1),
      },
      [theme.breakpoints.down('xs')]: {
        textAlign: 'right',
        display: 'flex',
        alignItems: 'center',
        '& a': {
          textAlign: 'center',
          margin: '0 5px'
        }
      }
    },
    bottomWrapper: {
      display: 'block',
      minHeight: '38px'
    },
    btnClear: {
      float: 'left',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
    },
    modalPaper: {
      padding: theme.spacing(6, 4),
      position: 'absolute',
      width: '400px',
      height: '210px',
      top: 'calc(50% - 105px)',
      left: 'calc(50% - 200px)',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(6, 2),
        width: '90%',
        left: '5%'
      },
      '& .MuiTypography-root': {
        marginBottom: theme.spacing(2),
      },
      '& .MuiButtonBase-root': {
        marginLeft: theme.spacing(2),
      }
    },
    closeModal: {
      position: 'absolute',
      right: '0',
      top: '0'
    }
  }),
);

export default useStyles;
