// React.
import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

// Components.
import Header from '../layout/Header';
import ActionAlert from '../utils/ActionAlert';

// Material UI.
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

// Styles.
import useStyles from './AdminStyles';

// Types interfase.
interface AdminProps {
  content?: React.ReactNode;
};


const Admin = ({ content } : AdminProps) => {
  let history = useHistory();

  if (content === undefined) {
    history.push('/admin/orders');
  }

  const classes = useStyles();

  return (
    <Fragment>
      <Header />
      <CssBaseline />
      <Container maxWidth="lg" className={classes.root}>
        <ActionAlert />
        <div>
          { content }
        </div>
      </Container>
    </Fragment>
  )
}

export default Admin;
