// React.
import React from 'react';

// Components.
import NavigationSettings from '../settings/NavigationSettings';

// Styles
import useStyles from './NavigationStyles';

const Navigation = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <NavigationSettings />
    </div>
  )
}

export default Navigation;
