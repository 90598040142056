// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      alignItems: 'center',
      '& .MuiFormControl-root': {
        width: '100%'
      },
      '& .MuiFormGroup-root': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& .MuiFormControl-root': {
          width: '45%'
        }
      },
      '& .MuiSvgIcon-colorPrimary': {
        width: '100%',
        height: '69px'
      }
    },
    formField: {
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      '& .Mui-disabled:before': {
        borderBottom: 0
      },
      '&.Mui-disabled:before': {
        borderBottom: 0
      },
      '& svg': {
        display: 'none'
      },
      '& > div': {
        width: '100%',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
    },
    actionsWrapper: {
      marginTop: 20,
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
    },
    paymentMethodItem: {
      width: 'fit-content!important',
      border: 1,
      cursor: 'pointer!important',
      padding: 10,
      borderColor: grey[400],
      borderRadius: 4,
      borderStyle: 'solid',
    },
    paymentMethodSpacing: {
      marginLeft: 5,
      marginRight: 5,
    },
    selectedPaymentMethod: {
      borderColor:theme.palette.primary.main
    },
    addPaymentMethodForm: {
      borderRadius: '4px',
      border: '1px solid',
      borderColor: grey[400],
      padding: theme.spacing(2, 1),
      width: '48%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    registrationErrorLabel: {
      height: 210,
    },
    registrationLoadingLabel: {
      height: 240,
    },
    inputLabelPosition: {
      position: 'relative',
      top: 15,
    },
    formControl: {
      margin: theme.spacing(1),
      display: 'block',
      '& > *': {
        display: 'block',
      }
    },
    paymentMethod: {
      borderRadius: '4px',
      border: '1px solid',
      borderColor: grey[400],
      padding: theme.spacing(2, 1),
      width: '48%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    offsetLabel: {
      position: 'absolute',
      top: '-9px',
      background: 'white',
      padding: theme.spacing(0, 1)
    },
    paymentMethodsWrapper: {
      width: '100%',
      padding: theme.spacing(3, 0),
      display: 'flex',
      flexWrap: 'wrap',
      '& .MuiCard-root': {
        backgroundColor: '#f1f1f1',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1 ),
        cursor: 'pointer',
        height: 'fit-content',
        [theme.breakpoints.down('sm')]: {
          width: '90%',
        },
      },
      '& .selected': {
        border: '2px solid',
        borderColor: theme.palette.primary.main
      },
      '& .MuiCardContent-root': {
          padding: theme.spacing(2, 2)
      },
      '& .wide': {
        width: '100%'
      }
    },
    paymentMethodWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap'
      },
      '& .check-wrapper': {
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          width: '40%',
        },
        [theme.breakpoints.down('sm')]: {
          width: '18%',
        },
      },
      '& .copy-wrapper': {
        width: '65%',
        [theme.breakpoints.down('sm')]: {
          width: '70%',
        },
      },
      '& .payme-logo-wrapper': {
        width: '20%',
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          width: '50%',
        },
        '& img': {
          width: '100%',
          [theme.breakpoints.down('sm')]: {
            maxWidth: '105px'
          },
        },
        '& .MuiSvgIcon-fontSizeLarge': {
          fontSize: '48px'
        }
      },
      '& .logos-wrapper': {
        width: '15%',
        padding: '10px 25px 0',
        [theme.breakpoints.down('sm')]: {
          width: '28%',
          padding: '0',
        },
        '& img': {
          width: '100%',
          marginBottom: theme.spacing(1),
          [theme.breakpoints.down('sm')]: {
            maxWidth: '88px',
            display: 'block',
            marginLeft: '10px'
          },
        }
      },
      '& strong': {
        fontFamily: 'VisbyCF-Bold'
      }
    },
    termsCheckbox: {
      paddingLeft: 0,
      [theme.breakpoints.down('lg')]: {
        paddingLeft: 'inherit',
      },
    },
    termsCheckboxText: {
      maxWidth: '88%',
    },
    pointsSummary: {
      display: 'flex',
      alignItems: 'center'
    },
    paymentNotes: {
      width: '100%',
      display: 'flex',
      margin: theme.spacing(2),
      alignItems: 'center',
      padding: theme.spacing(1)
    },
    paymentNotesText: {
      color: process.env.REACT_APP_THEME_COLOR_PRIMARY_MAIN,
      textAlign: 'justify'
    }
  }),
);

export default useStyles;
