import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
          margin: theme.spacing(2, 1),
          padding: theme.spacing(4, 4, 1),
          width: '100%',
          height: 'auto',
          [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(4, 2, 1),
          },
        },
      },
      boxTop: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        '& > *': {
          marginRight: theme.spacing(2),
        },
      },
      boxForm: {
        display: 'flex',
        justifyContent: 'space-between',
        '& > *': {
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
          width: '48%',
          [theme.breakpoints.down('xs')]: {
            width: '100%',
          },
        },
        [theme.breakpoints.down('xs')]: {
          flexWrap: 'wrap'
        },
      },
      fieldFirst: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          marginRight: 0,
        },
      },
      buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > *': {
          margin: theme.spacing(1),
        },
      },
      setButton:{
        '&.Mui-disabled': {
          opacity: 0.5,
          backgroundColor: '#fff',
        }
      },
      buttonsLast: {
        marginRight: 0
      },
      setDropdown:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ' & > *': {
          width: '80%',
          
        }
      },
      progressIcon:{
        marginTop: '18px'
      },
    }),
);

export default useStyles;