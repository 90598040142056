import {
  ADD_ITEM_LIST_UPDATE,
  ADD_ORDER,
  ADD_REFUND,
  ASSOCIATE_ORDER_REPORT,
  COMPLETED_ORDERS_AMMOUNT,
  CREATED_ORDERS_AMMOUNT,
  GET_SHIPPINGS,
  ItemAddedListType,
  ORDER_FAIL,
  ORDER_LIST,
  ORDER_LOADING,
  ORDER_PENDING_LIST,
  ORDER_REFRESH,
  ORDER_SET_FILTER,
  ORDER_STATUS_LIST,
  ORDER_TO_DELIVER_LIST,
  OrderAssociateReportType,
  OrderDispatchTypes,
  OrderList,
  OrderPendingList,
  OrderStatusType,
  OrderToDeliverList,
  OrderType,
  PENDING_ORDER_LIST,
  REGISTER_INTERNAL_ORDER,
  REGISTER_ORDER_COST,
  REGISTER_ORDER_NOTE,
  ShippingType,
  UPDATE_ORDER,
  UPDATE_ORDER_DELIVER,
} from "../actions/orderActions/OrderActionsTypes"

// Default state interface.
interface DefaultStateI {
  loading: string
  addedInOrder?: ItemAddedListType
  order?: Array<OrderType>
  refresh?: boolean
  orderFilters?: object
  orderList?: OrderList
  pendingOrderList?: OrderList
  totalPriceOrdersCompleted?: string
  totalPriceOrdersCreated?: string
  orderPedingList?: OrderPendingList
  orderStatusList?: OrderStatusType
  actionStatus?: any
  shippings?: Array<ShippingType>
  associateReport?: OrderAssociateReportType
  ordersToDeliver?: OrderToDeliverList
  totalCost?: number
}

// Default state.
const defaultState: DefaultStateI = {
  loading: "",
  addedInOrder: undefined,
}

const OrderReducer = (
  state: DefaultStateI = defaultState,
  action: OrderDispatchTypes
): DefaultStateI => {
  switch (action.type) {
    case ORDER_FAIL:
      return {
        ...state,
        loading: "",
        actionStatus: { menssage: action.payload, status: "error" },
      }
    case ORDER_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case REGISTER_INTERNAL_ORDER:
      return {
        ...state,
        loading: "",
        actionStatus: { menssage: action.payload, status: "success" },
      }
    case ADD_ITEM_LIST_UPDATE:
      return {
        ...state,
        addedInOrder: action.payload,
      }
    case ADD_ORDER:
      return {
        ...state,
        loading: "",
        order: action.payload.order,
        actionStatus: { menssage: action.payload.msg, status: "success" },
      }
    case ADD_REFUND:
      return {
        ...state,
        loading: "",
        actionStatus: { menssage: action.payload, status: "success" },
      }
    case UPDATE_ORDER:
      return {
        ...state,
        loading: "",
        actionStatus: { menssage: action.payload, status: "success" },
      }
    case ORDER_LIST:
      return {
        ...state,
        orderList: action.payload,
        loading: "",
      }
    case ORDER_TO_DELIVER_LIST:
      return {
        ...state,
        ordersToDeliver: action.payload,
        loading: "",
      }
    case UPDATE_ORDER_DELIVER:
      return {
        ...state,
        loading: "",
        actionStatus: { menssage: action.payload, status: "success" },
      }
    case PENDING_ORDER_LIST:
      return {
        ...state,
        loading: "",
        pendingOrderList: action.payload,
      }
    case COMPLETED_ORDERS_AMMOUNT:
      return {
        ...state,
        totalPriceOrdersCompleted: action.payload,
      }
    case CREATED_ORDERS_AMMOUNT:
      return {
        ...state,
        totalPriceOrdersCreated: action.payload,
      }
    case ORDER_PENDING_LIST:
      return {
        ...state,
        loading: "",
        orderPedingList: action.payload,
      }
    case ORDER_STATUS_LIST:
      return {
        ...state,
        loading: "",
        orderStatusList: action.payload,
      }
    case ORDER_REFRESH:
      return {
        ...state,
        refresh: action.payload,
      }
    case ORDER_SET_FILTER:
      return {
        ...state,
        orderFilters: action.payload,
      }
    case GET_SHIPPINGS:
      return {
        ...state,
        loading: "",
        shippings: action.payload,
      }
    case ASSOCIATE_ORDER_REPORT:
      return {
        ...state,
        loading: "",
        associateReport: action.payload,
      }
    case REGISTER_ORDER_NOTE:
      return {
        ...state,
        loading: "",
        actionStatus: { menssage: action.payload, status: "success" },
      }
    case REGISTER_ORDER_COST:
      return {
        ...state,
        totalCost: action.payload,
      }
    default:
      return state
  }
}

export default OrderReducer
