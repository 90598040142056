// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom:  theme.spacing(2),
      padding:  theme.spacing(2),
      backgroundColor:  process.env.REACT_APP_THEME_COLOR_EXTRA_LIGHT_GRAY,
      position: 'relative',
      '& .MuiTypography-root': {
        display: 'block',
        marginBottom: theme.spacing(1)
      },
      '& .MuiList-root': {
        maxHeight: '220px'
      }
    },
    dolarPrice: {
      marginLeft: theme.spacing(1),
      color: theme.palette.grey[700]
    },
    priceCol: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px'
      },
    },
    select: {
      maxWidth: '60px',
      '& .MuiList-root': {
        maxWidth: '60px',
      }
    },
    colImg: {
      alignSelf: 'center',
      '& .MuiAvatar-root': {
        width: '126px',
        height: 'auto',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
          width: '100%'
        },
        [theme.breakpoints.down('xs')]: {
          width: '126px',
        },
      }
    },
    colButton: {
      alignSelf: 'center'
    },
    lineThrough: {
      textDecoration: 'line-through'
    },
    priceWrapper: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    modalPaper: {
      padding: theme.spacing(6, 4),
      position: 'absolute',
      width: '400px',
      height: '200px',
      top: 'calc(50% - 100px)',
      left: 'calc(50% - 200px)',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(6, 2),
        width: '90%',
        left: '5%'
      },
      '& .MuiTypography-root': {
        marginBottom: theme.spacing(2),
      },
      '& .MuiButtonBase-root': {
        marginLeft: theme.spacing(2),
      }
    },
    closeModal: {
      position: 'absolute',
      right: '0',
      top: '0'
    },
    type: {
      padding: '2px 5px 4px',
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '4px',
      marginLeft: '10px'
    },
    customPadding: {
      padding: '6px 12px !important'
    }
  }),
);

export default useStyles;
