// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperWrapper: {
      paddingTop: theme.spacing(6),
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(12),
      },
      '& .MuiPaper-root': {
        padding: theme.spacing(4),
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(1),
          marginBottom: theme.spacing(2)
        },
      }
    },
  }),
);

export default useStyles;
