import { SellerSummary } from "../reports/utils/reportsInterfaces";

export const mergeDuplicateSellerSummaries = (sellerSummaries: SellerSummary[]):SellerSummary[] => {
  const summaryMap: { [key: string]: SellerSummary } = {};
  sellerSummaries.forEach((summary) => {
    const { vendorName, totalWeb, totalSeller, totalSellerSummary, totalPackage, finalTotal } = summary;
    if(vendorName.toLocaleLowerCase() === 'total') return;
    if (!summaryMap[vendorName]) {
      summaryMap[vendorName] = {
        vendorName,
        totalWeb,
        totalPackage,
        totalSeller,
        totalSellerSummary,
        finalTotal
      };
    } else {
      summaryMap[vendorName].totalWeb += totalWeb;
      summaryMap[vendorName].totalSeller += totalSeller;
    }
  });
  const uniqueSummaries = Object.values(summaryMap);
  return uniqueSummaries
}