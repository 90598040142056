// React.
import React, { useState, useEffect } from "react";

// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

// Components.
import PointsIcon from '../../utils/PointsIcon';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersForPoints, updateUserPoints } from "../../../actions/userActions/UserActions";
import { RootStore } from "../../../Store";

// Model Types.
import { UserType } from '../models/ModelTypes';

// Styles.
import useStyles from './ManageStorePointsStyles';

// Translations.
import { useTranslation } from 'react-i18next';

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

interface AutoCompleteType {
  email: string;
  points: number;
  firstName: string;
  lastName: string;
  phone: string | undefined;
}

const ManageStorePoints = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation('global');

  // Redux state.
  const userState = useSelector((state: RootStore) => state.user);
  const { loading, userList, actionStatus } = userState;

  // Local state.
  const [open, setOpen] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const [selectedItem, setSelectedItem] = useState<AutoCompleteType | null>(null);
  const [options, setOptions] = useState<AutoCompleteType[]>([]);
  const [adjustValue, setAdjustValue] = useState<string>('0');
  const [reason, setReason] = useState<string>('');

  const getUserData = () => {
    const delayDebounceFn = setTimeout(() => {
      if (!loading) {
        const qParams = { searchTerm: autocompleteValue, page: 0, size: 15, paged: true };
        dispatch(getAllUsersForPoints(qParams));
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  };

  // Get the users list.
  useEffect(() => {
    if(autocompleteValue.length>2){
      getUserData();
    }
    // eslint-disable-next-line
  }, [autocompleteValue]);

  // Refresh the users list when an update is done. 
  useEffect(() => {
    if (actionStatus?.menssage === 'status.success-update-points') {
      getUserData();
      // Clear values.
      setReason('');
      setAdjustValue('0');
    }

    // eslint-disable-next-line
  }, [actionStatus]);

  useEffect(() => {
    if (userList !== undefined) {
      var newUsersList: Array<AutoCompleteType> = [];
      userList?.users.forEach((user: UserType) => {
        let currentUser = {
          email: user.email,
          points: (user.points) ? user.points : 0,
          firstName: user.name,
          lastName: user.lastName,
          phone: user.phone
        };
        newUsersList.push(currentUser);
        if (selectedItem) {
          if (user.email === selectedItem.email) setSelectedItem(currentUser);
        }
      });
      setOptions(newUsersList);
    }
    // eslint-disable-next-line
  }, [userList]);

  const autocompleteChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAutocompleteValue(String(event.target.value));
    setSelectedItem(null);
  };

  const handleReasonOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setReason(String(event.target.value));
  };

  const handleAdjustOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    var newValue = String(event.target.value);
    const re = /^[0-9\b]+$/;
    if (re.test(newValue) || newValue === '') {
      if (newValue !== '0') {
        // Removes leading zeros.
        newValue = String(Number(newValue));
      }
      newValue = newValue !== '' ? newValue : '0';
      setAdjustValue(newValue);
    }
  };

  const addCredits = () => {
    if (selectedItem === undefined || adjustValue === '0' || !reason) return;
    // Save the new value.
    dispatch(updateUserPoints(Number(adjustValue), reason, selectedItem?.email!));
  };

  const removeCredits = () => {
    if (selectedItem === undefined || adjustValue === '0' || !reason) return;
    // Save the new value.
    var newValue = Number(adjustValue);
    if (newValue > selectedItem?.points!) newValue = selectedItem?.points!;
    newValue = (newValue * -1);
    dispatch(updateUserPoints(newValue, reason, selectedItem?.email!));
  };

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h4" color="primary" className={classes.pageTitle}>
            <PointsIcon width={'35'} />
            &nbsp;{t('settings.manage')} {getProjectConfig().STORE_CREDITS_NAME}&nbsp;
            {loading !== '' && <CircularProgress size={28} />}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Autocomplete
            id="asynchronous-items"
            className={classes.autocomplete}
            options={options}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            value={selectedItem}
            onChange={(event: any, newValue: AutoCompleteType | null) => {
              setSelectedItem(newValue);
            }}
            getOptionSelected={(option, value) => option.email === value.email}
            getOptionLabel={(option) => 
              `${option.firstName} ${option.lastName} | ${option.email} | ${option.phone}`
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('users.user-search')}
                value={autocompleteValue}
                onChange={autocompleteChange}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Divider className={classes.marginBottom} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography color="primary" className={classes.marginBottom}>
            {t('users.selected-user')}
          </Typography>
          <Card className={classes.card}>
            <CardContent>
              {(!selectedItem) &&
                <Typography>{t('users.no-selected-user')}</Typography>
              }
              {(selectedItem) &&
                <>
                  <Typography className={classes.largeText}>
                    <span className={classes.bold}>{getProjectConfig().STORE_CREDITS_NAME}:</span>&nbsp; 
                    {selectedItem?.points} {loading && <span>...</span>}
                  </Typography>
                  <Typography><span className={classes.bold}>Email:</span> {selectedItem?.email}</Typography>
                  <Typography><span className={classes.bold}>{t('users.name')}:</span> {selectedItem?.firstName} {selectedItem?.lastName}</Typography>
                  <Typography><span className={classes.bold}>{t('users.phone-number')}:</span> {selectedItem?.phone}</Typography>

                </>
              }
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography color="primary">
            {t('users.adjust-points-title')}
          </Typography>
          {(selectedItem) &&
            <FormControl className={`${classes.formControlAdjustQty}`}>
              <Typography variant="caption" color="initial">{t('users.adjust-reason')}</Typography>
              <TextField
                className="wide"
                multiline
                type="text"
                name="reason"
                placeholder={t('users.reason')}
                color="primary"
                value={reason}
                onChange={(e) => handleReasonOnChange(e)}
                inputProps={{ maxLength: 500 }}
              />
              <Typography variant="caption" color="initial">{t('users.adjust-points')}</Typography>
              <TextField
                type="text"
                name="adjustQuantity"
                color="primary"
                value={adjustValue}
                onChange={(e) => handleAdjustOnChange(e)}
                onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
              />
              <Button
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<AddCircleOutlineIcon />}
                onClick={addCredits}
                disabled={adjustValue === '0' || !reason}>
                {t('items.add')}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<RemoveCircleOutlineIcon />}
                onClick={removeCredits}
                disabled={adjustValue === '0' || !reason}>
                {t('items.remove')}
              </Button>
            </FormControl>
          }
        </Grid>
      </Grid>
    </Paper>

  );
};

export default ManageStorePoints;
