// React.
import React, {Fragment, useState, useEffect} from 'react';
import { Redirect, useLocation, useHistory } from 'react-router-dom';

// Material UI.
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { updatePasswordFromTemporal } from "../../actions/userActions/UserActions";
import { RootStore } from "../../Store";

// Styles.
import useStyles from './LoginFormStyles';

// Translations.
import { useTranslation } from 'react-i18next'

//  JWT decode token.
import jwtDecode from 'jwt-decode';

const ResetPasswordTemporalForm = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const location = useLocation() as any;
  let history = useHistory();

  // Redux state.
  const dispatch = useDispatch();
  const reduxState = useSelector((state: RootStore) => state.auth);
  const loading = reduxState.loading;
  const loginObject = reduxState.loginObject;
  const actionStatus = reduxState.actionStatus;
  const userState = useSelector((state: RootStore) => state.user);
  const userActionStatus = userState.actionStatus;

  // Local State.
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [passwordConfirmError, setPasswordConfirmError] = useState<boolean>(false);
  const [passwordDifferentError, setPasswordDifferentError] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<string>('');

  useEffect(() => {
    // Check if the user is already logged in.
    const authToken = localStorage.token;
    if (authToken !== undefined && authToken !== '') {
      const decodedToken:any = jwtDecode(authToken);
      setLoggedIn(decodedToken.sub);
    }
    
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userActionStatus !== undefined) {
      if (userActionStatus.status === 'success') {
        history.push('/');
      }
    }
    
    // eslint-disable-next-line
  }, [userActionStatus]);

  // Redirect to the page after login.
  if (loginObject !== undefined) {
    const user = loginObject.user;
    if (user.roles === 'ROLE_USER') {
      const toReturn = location.state !== undefined ? <Redirect to={ String(location.state.from.pathname) } /> :  <Redirect to='/' />;
      return toReturn;
    } else {
      return <Redirect to='/admin' />
    }
  }

  const passwordChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPassword(String(event.target.value));
    setPasswordError(false);
  }
  const passwordConfirmChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPasswordConfirm(String(event.target.value));
    setPasswordError(false);
    setPasswordDifferentError(false);
  }

  const validateLoginFields = () => {
    var result = true;
    if (password === '') {
      result = false;
      setPasswordError(true);
    }
    if (passwordConfirm === '') {
      result = false;
      setPasswordConfirmError(true);
    }
    if (password !== passwordConfirm) {
      result = false;
      setPasswordDifferentError(true);
    }
    return result;
  };

  const getConfirmErrorMessage = () => {
    var msg = '';
    if (passwordError) {
      msg = t('users.field-required');
    }
    if (passwordDifferentError) {
      msg = t('users.password-no-match');
    }

    return msg;
  };

  const loginOnClick = () => {
    if (validateLoginFields()) {
      dispatch(updatePasswordFromTemporal(password));
      setPasswordConfirm('');
      setPassword('');
    }
  };

  return (
    <div className={ classes.formWrapper }>
      <Typography variant="h5" color="primary">{ t('users.new-pass-title') }</Typography>
      <form noValidate autoComplete="off">
        <CssBaseline />
        {loggedIn !== '' ?
          <Fragment>
            <div className="form-fields">
              <TextField 
                id="user-password" 
                name="password"
                value={password}
                label={ t('authentication.password') } 
                color="primary" 
                type="password"
                fullWidth 
                autoFocus
                onChange={ passwordChange } 
                error={ passwordError }
                helperText={passwordError ? t('users.field-required'): ''}
              />
              <TextField 
                id="user-password-confirm" 
                name="password-confirm"
                value={passwordConfirm}
                label={ t('users.re-enter-password') } 
                color="primary" 
                type="password"
                fullWidth 
                onChange={ passwordConfirmChange } 
                error={ passwordConfirmError || passwordDifferentError }
                helperText={ getConfirmErrorMessage() }
              />
              { actionStatus?.status === 'error' &&  
                <Typography variant="subtitle1" color="secondary">{ t('authentication.incorrect-data') }</Typography>
              }
            </div>
            <div className={classes.formControls}>
              { loading === 'LOGIN_USER' && <CircularProgress /> }
              <Button variant="contained" color="primary" onClick={ loginOnClick }> { t('authentication.continue') } </Button>
            </div>
          </Fragment>
          :
          <Fragment>
            <Typography variant="subtitle1" color="primary">{ t('users.please-login') } </Typography>
          </Fragment>
        }
      </form>
    </div>
  )
}

export default ResetPasswordTemporalForm;
