// React.
import React, { useState, useEffect, useRef } from 'react';

// Material UI
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import { registerOrderNote } from "../../../actions/orderActions/OrderActions";

// Global Types.
import { OrderNoteType } from '../models/ModelTypes';

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './OrderNotesStyles';

// Moment.
import Moment from 'moment';

//  JWT decode token.
import jwtDecode from 'jwt-decode';

// Component interface.
interface OrderNotesProps {
  orderId: number;
  orderNotes: OrderNoteType[];
};

const OrderNotes = ({ orderId, orderNotes }: OrderNotesProps) => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();
  const notesRef = useRef<null | HTMLDivElement>(null);
  const decodedToken: any = localStorage.token !== undefined && localStorage.token !== '' ? jwtDecode(localStorage.token) : false;

  // Redux state.
  const orderState = useSelector((state: RootStore) => state.order);
  const { loading, actionStatus } = orderState;

  // Local State.
  const [note, setNote] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const [updatedList, setUpdatedList] = useState<boolean>(false);
  const [localNotes, setLocalNotes] = useState<OrderNoteType[]>(orderNotes ? [...orderNotes] : []);

  useEffect(() => {
    if (actionStatus !== undefined) {
      if (actionStatus.menssage === 'status.success-create-note' && updatedList) {
        const listCopy = [...localNotes];
        listCopy.push({
          content: note,
          creationDate: Moment().format('MM/DD/YYYY HH:mm'),
          id: 0,
          userEmail: (decodedToken) ? decodedToken.sub : '',
        });
        setLocalNotes(listCopy);
        setNote('');
      }
    }

    // eslint-disable-next-line
  }, [actionStatus]);

  useEffect(() => {
    // Scroll to bottom every time notes change.
    if (updatedList) {
      setTimeout(() =>
        notesRef.current?.lastElementChild?.scrollIntoView()
      , 1000);
      setUpdatedList(false);
    }

    // eslint-disable-next-line
  }, [localNotes]);

  const handleNoteChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setNote(event.target.value as string);
    setShowError(false);
  };

  const registerNote = () => {
    if (note) {
      setUpdatedList(true);
      dispatch(registerOrderNote(note, orderId));
    } else {
      setShowError(true);
    }
  };

  return (
    <div>
      <Typography color="primary" className={classes.notesTitle}>
        {t('orders.notes')}
      </Typography>
      <div className={classes.notesList} ref={notesRef} id={`note-${orderId}`}>
        {localNotes.map((note, index) =>
          <div key={`${orderId}-${index}`} className={classes.noteRow}>
            <Typography variant='caption'>
              <span>{t('orders.author')}: {note.userEmail} </span>
              <span>{Moment(note.creationDate).format('MM/DD/YYYY HH:mm')}</span>
            </Typography>
            <Typography>{note.content}</Typography>
          </div>
        )}
      </div>
      <div className={classes.notesForm}>
        <TextField
          id="add-note"
          name="add-note"
          multiline={true}
          maxRows={3}
          placeholder={t('orders.note-content')}
          value={note}
          onChange={handleNoteChange}
          error={(!note && showError)}
          helperText={(!note && showError) ? t('users.field-required') : ''}
        />
        <Button variant="contained" color="secondary" onClick={registerNote} disabled={(loading !== '' || !note)}>
          {t('orders.add-note')}
        </Button>
      </div>
    </div>
  )
}

export default OrderNotes;
