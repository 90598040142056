// React.
import React, {Fragment} from 'react';


// Material UI.
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import CloudOffIcon from '@material-ui/icons/CloudOff';

// Styles.
import useStyles from './UseTermsStyles';

const CheckoutPaymeError = () => {
  const classes = useStyles();

  return (
    <Fragment>
    <CssBaseline />
    <Container maxWidth="md">
      <Paper elevation={3} square={false} variant="elevation" className={classes.errorWrapper}
        style={{
          marginTop: 20
        }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <h1>Ooops... Also salio mal.</h1>
          <span style={{
            fontSize: 190
          }}>
          <CloudOffIcon
            fontSize={'inherit'}
          />
          </span>
          <p style={{
            fontSize: 24
          }}>Por favor inténtelo más tarde.</p>
          
          
        </div>
      </Paper>
    </Container>
  </Fragment>
  )
}

export default CheckoutPaymeError;