import React, { Fragment, useState, useEffect } from 'react';

// Material UI.
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Radio from '@material-ui/core/Radio';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

// Redux
import { useDispatch, useSelector } from "react-redux";

// Components.
import TextMaskCardNumber from '../../admin/utils/TextMaskCardNumber';
import NumberFormat from '../../admin/utils/NumberFormat';
import PointsIcon from '../../utils/PointsIcon';

// Moment.
import Moment from 'moment';

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

// Translations.
import { useTranslation } from 'react-i18next';

// Model
import { ShippingType } from '../../admin/models/ModelTypes';

// Styles.
import useStyles from './CheckoutPaymentStyles';
import { getPaymentMethods, registerPaymentMethod, resetRegister } from '../../../actions/userPaymentMethodsActions/UserPaymentMethodsActions';
import { RootStore } from '../../../Store';
import { CardRegistration } from '../../../actions/userPaymentMethodsActions/UserPaymentMethodsActionsTypes';
import { Checkbox, CircularProgress, Link } from '@material-ui/core';
import UseTerms from '../pages/UseTerms';

// Local Images.
import paymeLogo from '../../../images/payme-logo.png';
import mastercardLogo from '../../../images/mastercard-logo.png';
import visaLogo from '../../../images/visa-logo.png';
import sinpeLogo from '../../../images/sinpe-movil-logo.png';

// Types interfase.
interface CheckoutPaymentProps {
  isSummary: boolean;
  setSelectedCard: Function;
  setSelectedPayment: Function;
  selectedPayment: string;
  acceptTerms: boolean;
  setAcceptTerms: Function;
  setIsUsingPoints: Function;
  isUsingPoints: boolean;
  usingPointsDiff: number;
  selectedShipping: ShippingType | undefined;
}
const defaultPaymentMethodFormState: CardRegistration = {
  card: {
    cardHolder: '',
    expirationDate: {
      month: 1,
      year: 21
    },
    cardNumber: '',
    nickname: '',
  }
}

const CheckoutPayment = ({ isSummary, setSelectedCard, setSelectedPayment, selectedPayment, selectedShipping,
  acceptTerms, setAcceptTerms, setIsUsingPoints, isUsingPoints, usingPointsDiff }: CheckoutPaymentProps) => {

  const [t] = useTranslation('global');
  const classes = useStyles();
  const dispatch = useDispatch();

  // Define local state for the user.
  const [toggleAddPaymentMethod, setToggleAddPaymentMethod] = useState(false)
  const [paymentMethodForm, setPaymentMethodForm] = useState<CardRegistration>(defaultPaymentMethodFormState);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>('')
  const [expirationYears,] = useState(() => {
    const currentYear = Moment().year() - 2000;
    const years = 20
    let yearsArray: number[] = [];
    for (let i = 0; i < years; i++) {
      yearsArray.push(currentYear + i)
    }
    return yearsArray
  })

  // Redux
  const paymentMethodsState = useSelector((state: RootStore) => state.paymentMethods);
  const reduxState = useSelector((state: RootStore) => state.auth);
  const loggedUser = reduxState.loggedUser;

  const handleRegisterCard = () => {
    const paymentInfoToRegister = {
      card: {
        cardHolder: paymentMethodForm.card.cardHolder,
        cardNumber: paymentMethodForm.card.cardNumber,
        nickname: 'card-' + paymentMethodForm.card.cardNumber.substring(paymentMethodForm.card.cardNumber.length - 4),
        expirationDate: {
          month: paymentMethodForm.card.expirationDate.month,
          year: paymentMethodForm.card.expirationDate.year
        }
      }
    }
    setPaymentMethodForm(defaultPaymentMethodFormState);
    dispatch(registerPaymentMethod(paymentInfoToRegister));
  }

  useEffect(() => {
    if (paymentMethodsState.register === 'PAYMENT_METHOD_SUCCESSFULLY_REGISTERED') {
      dispatch(getPaymentMethods());
      setToggleAddPaymentMethod(false);
      setPaymentMethodForm(defaultPaymentMethodFormState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethodsState.register])

  useEffect(() => {
    setSelectedCard(selectedPaymentMethod)
  }, [setSelectedCard, selectedPaymentMethod])

  const handleSetSelectedPayment = (payment: string) => {
    setSelectedPayment(payment);

    // Unset the points selection.
    if (usingPointsDiff === 0) setIsUsingPoints(false);
    
  };

  const handleSetIsUsingPoints = (value:boolean) => {
    setIsUsingPoints(value);
    setSelectedPayment('');
  };
  

  const renderSummary = () => {
    return (
      <Fragment>
        <Typography variant="h6">{t('checkout.step-3')}</Typography>
        {(loggedUser?.points! > 0) &&
          <Typography className={classes.pointsSummary}>
            (<PointsIcon width={'15'} gray={true}/> {loggedUser?.points!} {getProjectConfig().STORE_CREDITS_NAME} {t('checkout.availables')})
          </Typography>
        }
        {(!isUsingPoints || usingPointsDiff > 0)}
        {selectedPayment === 'transferencia' ?
          <Typography variant="caption" color="textSecondary">{t('checkout.payment-transfer')}</Typography> :
          <Typography variant="caption" color="textSecondary">{t('checkout.card-info')}</Typography>
        }
      </Fragment>
    );
  };

  const renderFullContent = () => {
    return (
      <Fragment>
        <Typography variant="h6">{t('checkout.step-3')}</Typography><br />
        <Grid container className={classes.formWrapper}>
          <Grid item xs={12} sm={12} className={classes.paymentMethodsWrapper}>
            <Card className={`${selectedPayment === 'payme' ? 'selected' : ''}`} onClick={() => handleSetSelectedPayment('payme')}>
              <CardContent className={classes.paymentMethodWrapper}>
                <div className="check-wrapper">
                  <Radio
                    checked={selectedPayment === 'payme'}
                  />
                </div>
                <div className="payme-logo-wrapper">
                  <img src={paymeLogo} alt="Payme logo" />
                </div>
                <div className="copy-wrapper">
                  <Typography><strong>{t('checkout.payme-title')}</strong></Typography>
                  <Typography>{t('checkout.payme-copy')}</Typography>
                </div>
                <div className="logos-wrapper">
                  <img src={visaLogo} alt="VISA logo" />
                  <img src={mastercardLogo} alt="Mastercard logo" />
                </div>
              </CardContent>
            </Card>
            <Card className={`${selectedPayment === 'transferencia' ? 'selected' : ''}`} onClick={() => handleSetSelectedPayment('transferencia')}>
              <CardContent className={classes.paymentMethodWrapper}>
                <div className="check-wrapper">
                  <Radio
                    checked={selectedPayment === 'transferencia'}
                  />
                </div>
                <div className="payme-logo-wrapper">
                  <AccountBalanceIcon fontSize="large" />
                </div>
                <div className="copy-wrapper">
                  <Typography><strong>{t('checkout.payment-transfer')}</strong></Typography>
                </div>
                <div className="logos-wrapper">
                  <img src={sinpeLogo} alt="SINPE Movil logo" />
                </div>
              </CardContent>
            </Card>
            {(selectedPayment === 'transferencia') &&
              <div className={classes.paymentNotes}>
                <Typography className={classes.paymentNotesText}> <strong>{t('checkout.transfer-payment-reminder')}</strong></Typography>
              </div>
            }
            {(loggedUser?.points! > 0) &&
              <Card className={`wide ${isUsingPoints ? 'selected' : ''}`} onClick={() => handleSetIsUsingPoints(!isUsingPoints)}>
                <CardContent className={classes.paymentMethodWrapper}>
                  <div className="check-wrapper">
                    <Radio
                      checked={isUsingPoints}
                    />
                  </div>
                  <div className="payme-logo-wrapper">
                    <PointsIcon width={'45'} gray={true} />
                  </div>
                  <div className="copy-wrapper">
                    <Typography>
                      <strong>{t('checkout.pay-points', { name: getProjectConfig().STORE_CREDITS_NAME })} </strong>
                      ({loggedUser?.points!} {getProjectConfig().STORE_CREDITS_NAME} {t('checkout.availables')})
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            }
            <div className={classes.paymentNotes}>
              {(isUsingPoints) &&
                <Typography className={classes.paymentNotesText}><strong>{t('checkout.cash-out-points')}</strong></Typography>
              }
              {(isUsingPoints && selectedShipping?.id === 2) &&
                <Typography>{t('checkout.points-no-shipping', { name: getProjectConfig().STORE_CREDITS_NAME })}</Typography>
              }
              {(isUsingPoints && usingPointsDiff > 0 && !selectedPayment) &&
                <>
                  <Typography>{t('checkout.points-no-total', { name: getProjectConfig().STORE_CREDITS_NAME })}</Typography>
                  <Typography>{t('checkout.pending-amount')} {getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={usingPointsDiff} />}.</Typography>
                  <Typography>{t('checkout.select-another-payment')}</Typography>
                </>
              }
            </div>
          </Grid>

          <div style={{
            height: 230,
            paddingBottom: 25,
            overflow: 'auto',
          }}>
            <UseTerms />
          </div>
          <Checkbox
            onClick={
              () => setAcceptTerms(!acceptTerms)
            }
            className={classes.termsCheckbox}
            checked={acceptTerms}
          />
          <p className={classes.termsCheckboxText}>Leí y acepto los términos de uso y condiciones en este enlace: <Link color="inherit" href="/use-terms">Términos y Condiciones</Link></p>
          {
            selectedPayment === 'tarjeta' &&
            <Fragment>
              {/** loading available payment methods */
                paymentMethodsState.loading === 'PAYMENT_METHODS_LOADING_STARTED' &&
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent='center'
                >
                  <Typography className={''} color="secondary">
                    {t('paymentMethods.loading-available')}
                  </Typography>
                  <CircularProgress />
                </Grid>
              }

              {/** No payment methods registered */
                toggleAddPaymentMethod === false &&
                paymentMethodsState.cardsResponse.length === 0 &&
                paymentMethodsState.loading === 'PAYMENT_METHODS_LOADING_COMPLETED' &&
                <Typography className={'classes.noAddress'} color="secondary">
                  {t('paymentMethods.no-payment-methods-registered')}
                </Typography>
              }

              {/** Available payment methods */
                toggleAddPaymentMethod === false &&
                paymentMethodsState.cardsResponse.length > 0 &&
                <Grid container direction={'row'}>
                  {paymentMethodsState.cardsResponse.map(e => <Grid item className={classes.paymentMethodSpacing} key={e.lastDigits} >
                    <FormControl className={`${classes.paymentMethodItem} ${e.token === selectedPaymentMethod ? classes.selectedPaymentMethod : ''}`}
                      onClick={() => { setSelectedPaymentMethod(e.token) }}
                    >
                      <Typography variant="caption" className={classes.offsetLabel}>{t('paymentMethods.card-type')}</Typography>
                      <TextField
                        className={classes.formField}
                        value={`${e.brand} ${t('paymentMethods.card-summary')} ${e.lastDigits}`}
                        disabled={true}
                      />

                    </FormControl>
                  </Grid>)}
                </Grid>
              }

              {/** error loading payment methods */}
              {paymentMethodsState.loading === 'PAYMENT_METHODS_LOADING_FAILED' &&
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent='center'
                >
                  <Typography className={'classes.warn'} color="secondary">
                    {t('paymentMethods.loading-error')}
                  </Typography>
                  <Button color="primary" variant="contained"
                    onClick={() => {
                      setPaymentMethodForm(defaultPaymentMethodFormState);
                      dispatch(resetRegister())
                      dispatch(getPaymentMethods())
                    }}
                    style={{
                      marginTop: 20
                    }}>
                    {t('paymentMethods.loading-retry')}
                  </Button>
                </Grid>}

              {/** payment registrarion form */
                toggleAddPaymentMethod === true &&
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <FormControl className={classes.paymentMethodItem}>

                    {/** Payment method register idle */
                      paymentMethodsState.register === 'PAYMENT_METHOD_REGISTRATION_IDLE' &&
                      <>
                        <Typography variant="caption" className={classes.offsetLabel}>{t('paymentMethods.add-card-form-label')}</Typography>
                        <InputLabel htmlFor="card-number-label" className={classes.inputLabelPosition} >{t('checkout.card-number')}</InputLabel>
                        <Input
                          id="card-number-label"
                          name="cardNumber"
                          inputComponent={TextMaskCardNumber as any}
                          className={classes.formField}
                          aria-describedby="component-error-text"
                          value={paymentMethodForm.card.cardNumber}
                          onChange={
                            (e) => {
                              const unmaskedInput = e.target.value.replace(/\D/g, '')// return only numbers
                              const updatedPaymentMethod: CardRegistration = {
                                card: {
                                  ...paymentMethodForm.card,
                                  cardNumber: unmaskedInput,
                                }
                              }
                              setPaymentMethodForm(updatedPaymentMethod)
                            }
                          }
                        />
                        <TextField
                          className={classes.formField}
                          id="u-cardHolder"
                          name="Cardholder's name:"
                          label={t('paymentMethods.card-name')}
                          value={paymentMethodForm.card.cardHolder}
                          onChange={(e) => {
                            const formatedName = e.target.value.replace(/[^a-zA-Z .]/g, ""); // return only utf-8's, whitespaces and periods.
                            const updatedPaymentMethod: CardRegistration = {
                              card: {
                                ...paymentMethodForm.card,
                                cardHolder: formatedName,
                              }
                            }
                            setPaymentMethodForm(updatedPaymentMethod)
                          }}
                          error={false}
                        />
                        <FormControl fullWidth>
                          <InputLabel id="registering-card-month">Month:</InputLabel>
                          <Select
                            style={{
                              width: 100
                            }}
                            labelId="registering-card-month"
                            id="expirationDateMonth"
                            value={paymentMethodForm.card.expirationDate.month}
                            label="month"
                            onChange={(e) => {
                              const month: number = e.target.value as number;
                              const updatedPaymentMethod: CardRegistration = {
                                card: {
                                  cardHolder: paymentMethodForm.card.cardHolder,
                                  cardNumber: paymentMethodForm.card.cardNumber,
                                  nickname: '',
                                  expirationDate: {
                                    month: month,
                                    year: paymentMethodForm.card.expirationDate.year
                                  }
                                }
                              }
                              setPaymentMethodForm(updatedPaymentMethod)
                            }}
                          >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={11}>11</MenuItem>
                            <MenuItem value={12}>12</MenuItem>
                          </Select>
                        </FormControl>

                        <FormControl fullWidth>

                          <InputLabel id="registering-card-year">Year:</InputLabel>
                          <Select
                            labelId="registering-card-year"
                            id="demo-simple-select"
                            style={{
                              width: 100
                            }}
                            value={paymentMethodForm.card.expirationDate.year}
                            label="year"
                            onChange={(e) => {
                              const year: number = e.target.value as number;
                              const updatedPaymentMethod: CardRegistration = {
                                card: {
                                  cardHolder: paymentMethodForm.card.cardHolder,
                                  cardNumber: paymentMethodForm.card.cardNumber,
                                  nickname: '',
                                  expirationDate: {
                                    month: paymentMethodForm.card.expirationDate.month,
                                    year
                                  }
                                }
                              }
                              setPaymentMethodForm(updatedPaymentMethod)
                            }}
                          >
                            {expirationYears.map(e => <MenuItem value={e} key={e}>{e}</MenuItem>)}
                          </Select>

                        </FormControl>

                        <Button color="primary" variant="contained"
                          onClick={() => handleRegisterCard()}
                          style={{
                            marginTop: 20
                          }}
                        >
                          {t('paymentMethods.register')}
                        </Button>

                        <Button color="primary" variant="contained"
                          onClick={() => {
                            setToggleAddPaymentMethod(false);
                            setPaymentMethodForm(defaultPaymentMethodFormState);
                          }}
                          style={{
                            marginTop: 20
                          }}>
                          {t('paymentMethods.cancel')}
                        </Button>
                      </>
                    }

                    {/** Payment method register in progress */
                      paymentMethodsState.register === 'PAYMENT_METHOD_REGISTERING' &&
                      <Grid
                        container
                        direction="column"
                      >
                        <CircularProgress />
                        <Typography variant="caption" className={classes.registrationLoadingLabel}>{t('paymentMethods.register-inprogress')}</Typography>
                      </Grid>
                    }

                    {/** Payment method register failure */
                      paymentMethodsState.register === 'PAYMENT_METHOD_REGISTRATION_FAILED' && <>
                        <Typography variant="caption" className={classes.registrationErrorLabel}>{t('paymentMethods.register-error')}</Typography>
                        <Button color="primary" variant="contained"
                          onClick={() => {
                            setPaymentMethodForm(defaultPaymentMethodFormState);
                            dispatch(resetRegister())
                          }}
                          style={{
                            marginTop: 20
                          }}
                        >
                          {t('paymentMethods.loading-retry')}
                        </Button>
                        <Button color="primary" variant="contained"
                          onClick={() => {
                            setToggleAddPaymentMethod(false);
                            setPaymentMethodForm(defaultPaymentMethodFormState);
                            dispatch(resetRegister())
                          }}
                          style={{
                            marginTop: 20
                          }}
                        >
                          {t('paymentMethods.cancel')}
                        </Button>
                      </>
                    }
                  </FormControl>
                </Grid>
              }

              {/** Add and continue button group */
                toggleAddPaymentMethod === false &&
                <Grid className={classes.actionsWrapper}>

                  {/** Payment method register form toggle */
                    paymentMethodsState.cardsResponse.length < 2 &&
                    paymentMethodsState.loading === 'PAYMENT_METHODS_LOADING_COMPLETED' &&
                    <Button variant="contained" color="primary" style={{ marginRight: 10 }}
                      onClick={() => setToggleAddPaymentMethod(true)}
                      disabled={false}>
                      {'Agregar'}
                    </Button>
                  }

                  <Button variant="contained" color="primary" disabled={selectedPaymentMethod === ''}
                    onClick={() => 'nextStep'}>
                    {t('checkout.next-step')}
                  </Button>

                </Grid>
              }
            </Fragment>
          }
        </Grid>

      </Fragment>
    );
  };

  return (
    <div>
      {isSummary ? renderSummary() : renderFullContent()}
    </div>
  )
}

export default CheckoutPayment;
