// React.
import React, { useState, useEffect, Fragment } from "react";

// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

// Editor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { 
  getOrderConfirmations,
  updateOrderConfirmation,
  deleteOrderConfirmation,
  registerOrderConfirmation
} from "../../../actions/settingsActions/SettingsActions";
import {RootStore} from "../../../Store";

// Model Types.
import { OrderConfirmation } from '../models/ModelTypes';

// Styles.
import useStyles from './OrderConfirmationSettingsStyles';

// Translations.
import { useTranslation } from 'react-i18next';

export type LocalConfirmationStateType = {
  confirmationTexts: Array<OrderConfirmation>
};

const OrderConfirmationSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation('global');

  // Redux state.
  const settingsState = useSelector((state: RootStore) => state.settings);
  const {loading, orderConfirmations, actionStatus} = settingsState;

  // Local state.
  const [localOrderConfirmations, setLocalOrderConfirmations] = useState<LocalConfirmationStateType>();
  const [disabledAddButton, setDisabledAddButton] = useState<boolean>(false);
  const [editingIndex, setEditingIndex] = useState<number>(-1);

  useEffect(() => {
    dispatch(getOrderConfirmations());

    // eslint-disable-next-line
  }, [actionStatus])

  useEffect(() => {
    if (orderConfirmations !== undefined) {
      setLocalOrderConfirmations({
        ...localOrderConfirmations,
        confirmationTexts: orderConfirmations
      });
      setDisabledAddButton(false);
    }

    // eslint-disable-next-line
  }, [orderConfirmations])

  const handleConfirmationTitleChange = (event: React.ChangeEvent<{ value: unknown }>, index:number) => {
    let newValue = String(event.target.value);
    let copyLocalOrderConfirmations = [...localOrderConfirmations?.confirmationTexts!];
    copyLocalOrderConfirmations[index].title = newValue;
    setLocalOrderConfirmations({
      ...localOrderConfirmations,
      confirmationTexts: copyLocalOrderConfirmations
    });
  };

  const addNewOrderConfirmationForm = () => {
    var maxSequense = 0;
    if (localOrderConfirmations?.confirmationTexts) {
      maxSequense = Math.max(...localOrderConfirmations?.confirmationTexts?.map(item => item.sequence));
    }
    const newSequense = maxSequense + 1;
    const copyLocalOrderConfirmations = [...localOrderConfirmations?.confirmationTexts!];
    copyLocalOrderConfirmations.push({
      sequence: (newSequense && Number.isInteger(newSequense)) ? newSequense : 1,
      title: '',
      value:''
    });
    setLocalOrderConfirmations({
      ...localOrderConfirmations,
      confirmationTexts: copyLocalOrderConfirmations
    });
    setDisabledAddButton(true);
    setEditingIndex(copyLocalOrderConfirmations.length - 1);
  };

  // For updartes.
  const saveOrderConfirmation = (index:number) => {
    if (localOrderConfirmations?.confirmationTexts[index] !== undefined) {
      dispatch(updateOrderConfirmation(localOrderConfirmations?.confirmationTexts[index]));
      setEditingIndex(-1);
    }
  };

  // For creations.
  const saveNewOrderConfirmation = (index: number) => {
    if (localOrderConfirmations?.confirmationTexts[index] !== undefined) {
      dispatch(registerOrderConfirmation(localOrderConfirmations?.confirmationTexts[index]));
      setEditingIndex(-1);
    }
  };

  const handleDeleteOrderConfirmation = (index:number) => {
    if (localOrderConfirmations?.confirmationTexts[index] !== undefined) {
      if (localOrderConfirmations?.confirmationTexts[index].id !== undefined) {
        const removeId = localOrderConfirmations?.confirmationTexts[index]?.id!;
        dispatch(deleteOrderConfirmation(removeId));

        // Remove from local state.
        let copyLocalOrderConfirmations = [...localOrderConfirmations.confirmationTexts];

        setLocalOrderConfirmations({
          ...localOrderConfirmations,
          confirmationTexts: copyLocalOrderConfirmations.filter(ele => ele.id !== removeId)
        });
      }
    }
  };

  const handleCancelBtn = () => {
    // Set initial values.
    setEditingIndex(-1);
    setDisabledAddButton(false);
    dispatch(getOrderConfirmations());
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h4" color="primary">
          {t('settings.manage-confirmation-title')} {loading !== '' && <CircularProgress/>}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <div className={classes.formItemWrapper}>
          {localOrderConfirmations?.confirmationTexts.map((oConfirmation, index) =>
            <div key={index} className={classes.formItem}>
              <TextField
                id={`confirmation-title-${index}`}
                name="confirmation-title"
                value={oConfirmation.title}
                type="text"
                color="primary"
                placeholder={t('settings.confirm-title')}
                onChange={(e) => handleConfirmationTitleChange(e, index)}
                disabled={editingIndex !== index}
              />
              <CKEditor
                editor={ClassicEditor}
                id={`confirmation-text-${index}`}
                data={oConfirmation.value}
                config={{ toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|', 'undo', 'redo'] }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  let copyLocalOrderConfirmations = [...localOrderConfirmations.confirmationTexts];
                  copyLocalOrderConfirmations[index].value = data;
                  setLocalOrderConfirmations({
                    ...localOrderConfirmations,
                    confirmationTexts: copyLocalOrderConfirmations
                  });
                }}
                disabled={editingIndex !== index}
              />
              {(editingIndex === index) ?
                <Fragment>
                  <Button variant="outlined" color="primary" size="small"
                    onClick={() => (
                      oConfirmation.id === undefined ?
                      saveNewOrderConfirmation(index) : saveOrderConfirmation(index)
                    )}
                    disabled={(loading !== '') || (oConfirmation.value === '') || (oConfirmation.title === '')}
                  >
                    {t('settings.save')}
                  </Button>
                  <Button variant="text" color="primary" size="small"
                    onClick={() => handleCancelBtn()}
                  >
                    {t('settings.cancel')}
                  </Button>
                </Fragment>
                :
                <Fragment>
                  <Button variant="contained" color="primary" size="small"
                    onClick={() => setEditingIndex(index)}
                    disabled={loading !== ''}
                  >
                    {t('settings.edit')}
                  </Button>
                  <Button variant="outlined" color="secondary" size="small"
                    onClick={() => handleDeleteOrderConfirmation(index)}
                    disabled={oConfirmation.id === undefined || (loading !== '')}
                    className={classes.right}
                  >
                    {t('settings.delete')}
                  </Button>
                </Fragment>
              }
            </div>
          )}
        </div>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Button variant="contained" color="default" 
          onClick={addNewOrderConfirmationForm}
          disabled={disabledAddButton || (loading !== '')}
        >
          {t('settings.confirm-add')}
        </Button>
      </Grid>
    </Grid>
  );
};


export default OrderConfirmationSettings;
