//Material UI
import { Button, CircularProgress, InputLabel, TextField, Typography } from "@material-ui/core"

//Translations
import { useTranslation } from 'react-i18next';

//Redux
import { useSelector } from "react-redux";
import { RootStore } from "../../../Store";

interface addLabelModalProps {
  closeModal: () => void;
  handleCreateLabel:()=> void;
  handleNewLabel:(event:any)=>void;
  handleNewLabelDescription:(event:any)=>void;
  newLabel:string;
}

const CreateLabel:React.FC<addLabelModalProps> = ({ closeModal, handleCreateLabel, handleNewLabel,newLabel,handleNewLabelDescription}) => {
  const [t] = useTranslation('global');

  //Redux State
  const prizesState = useSelector((state:RootStore)=> state.prize)
  const loading = prizesState.loading;

  return (
      <div>
      <InputLabel style={{marginBottom:'20px'}}>
        <Typography variant="h5" color="primary">{t('prizes.create-label')}:</Typography>
        </InputLabel>
        {t('prizes.label-new-name')}
        <TextField type="text" name="name" onChange={(event)=>{handleNewLabel(event)}}/>
        {"Descripcion"}
        <TextField type="text" name="name" onChange={(event)=>{handleNewLabelDescription(event)}}/>
        <div style={{display:'flex', gap:'10px', marginTop:'10px'}}>
        <Button variant="contained" color="default" onClick={()=>{handleCreateLabel()}} disabled={newLabel.length<3}>
          {loading? 
          <CircularProgress/>:
          `${t('prizes.create')}` 
         }
        </Button>
        <Button variant="contained" color="default" onClick={closeModal}>{t('prizes.cancel')}</Button>
        </div>
      </div>
    )
}

export default CreateLabel;


