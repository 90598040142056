// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkoutWrapper: {
      paddingTop: theme.spacing(8),
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(12),
      },
    },
    paperWrapper: {
      padding: theme.spacing(2),
      minHeight: '450px',
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(2),
      },
    },
    bottomWrapper: {
      marginTop: theme.spacing(3),
      textAlign: 'right',
      '& .MuiTypography-root': {
        marginBottom: theme.spacing(2),
      }
    }
  }),
);

export default useStyles;
