import {
  CATEGORY_LIST,
  CATEGORY_LOADING,
  CATEGORY_FAIL,
  SET_LIST,
  SET_LOADING,
  SET_FAIL,
  TemplateActionsTypes,
  CategoryType,
  SetType,
} from "../actions/templateActions/TemplateActionsTypes";

interface CategoryState {
  loading: boolean;
  categories?: Array<CategoryType>;
  sets?: Array<SetType>;
}

const initialState: CategoryState = {
  loading: false,
};

const TemplateReducer = (
  state: CategoryState = initialState,
  action: TemplateActionsTypes
) => {
  switch (action.type) {
    case CATEGORY_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CATEGORY_LIST:
      return {
        ...state,
        loading: false,
        categories: action.payload.categories,
        error: null,
      };
    case CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case SET_LOADING:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case SET_LIST:
        return {
          ...state,
          loading: false,
          sets: action.payload.sets,
          error: null,
        };
      case SET_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
    default:
      return state;
  }
};

export default TemplateReducer;
