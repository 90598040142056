// React.
import React from 'react';
import { GoogleOAuthProvider} from '@react-oauth/google';

// Material UI.
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

// Components.
import ActionAlert from '../admin/utils/ActionAlert';
import LoginForm from './LoginForm';
import Logo from '../client/utils/Logo';

// Styles.
import useStyles from './LoginStyles';

// Alt logo
import altLogo from '../../alt-logo-1.png';

const Login = () => {
  const classes = useStyles();
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  return (
    <GoogleOAuthProvider clientId={`${clientId}`}>
      <CssBaseline />
      <Container maxWidth="sm">
        <ActionAlert />
        <div className={ classes.logo }>
          <Logo altLogo={altLogo}/>
        </div>
        <Paper elevation={3} square={false} variant="elevation">
          <LoginForm />
        </Paper>
      </Container>
    </GoogleOAuthProvider>
  )
}

export default Login
