// React.
import React, { Fragment, useEffect, useState } from 'react';

// Components.
import Pagination from '../utils/ResultsPagination';
import ImageAvatar from '../utils/ImageAvatar';
import UserEdit from './UserEdit';

// Material UI
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {getUsers, refreshUserView} from "../../../actions/userActions/UserActions";

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import { useStyles, Accordion, AccordionSummary, AccordionDetails } from './UserAccordionStyles';
import scrollToTop from '../../utils/scrollToTop';

const UserAccordion = () => {
  const [t] = useTranslation('global');
  const classes = useStyles();
  const dispatch = useDispatch();

  // Redux state.
  const userState = useSelector((state: RootStore) => state.user);
  const {loading, userList, refresh, userFilters: filters} = userState;


  // Local component state.
  const [currentPage, setCurrentPage] = useState<number>(1);
  const url = window.location.href;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let page = params.get('page');
    const pNumber = Number(page);
    if (page && page !== null) {
      const qParams = {
        ...filters,
        page: pNumber - 1,
        size: getProjectConfig().PAGES_SIZE,
        paged: true
      };
      dispatch(getUsers(qParams));
      setCurrentPage(pNumber);
      scrollToTop();
    } else {
      const qParams = {
        ...filters,
        page: 0,
        size: getProjectConfig().PAGES_SIZE,
        paged: true
      };
      dispatch(getUsers(qParams));
      setCurrentPage(1);
      scrollToTop();
    }

    dispatch(refreshUserView(false));
    
    // eslint-disable-next-line
  }, [url, refresh]);

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (loading === 'LIST') {
    return (
      <div className={ classes.circularProgress }>
        <CircularProgress/>
      </div>
    )
  }

  if (userList?.users.length === 0) {
    return (
      <div className={ classes.circularProgress }>
        <Typography color="textSecondary">{ t('users.no-users') }</Typography>
      </div>
    )
  }

  return (
    <Fragment>
        {
          userList?.users !== undefined && 
          (userList!.users!.map(user => 
            <Accordion key={ user.id } square expanded={expanded === `panel-u-${ user.id }`} onChange={handleChange(`panel-u-${ user.id }`)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={1} className={ classes.userImgSmall }>
                    <ImageAvatar src={ user.profileImageUrl ? user.profileImageUrl : '' } alt="User profile image." />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Typography variant="subtitle1">{ user.name } { user.lastName }</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="caption" color="textSecondary">{ user.email }</Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <UserEdit user={ user } userAddress={ user.address! }/>
              </AccordionDetails>
            </Accordion>
          ))
        }
        {
          userList?.totalPages !== undefined && userList?.totalPages !== 1 
          ? (<Pagination current={currentPage} path="/admin/users" pagesNumber={ userList!.totalPages }/>) : ''
        }
    </Fragment>
  );
}

export default UserAccordion;
