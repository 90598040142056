import {
  INVENTORY_REQUESTS_FAIL,
  INVENTORY_REQUESTS_LIST,
  INVENTORY_REQUESTS_LOADING,
  INVENTORY_REQUESTS_REFRESH,
  INVENTORY_REQUESTS_SET_FILTER,
  InventoryRequestContent,
  InventoryRequestsData,
  InventoryRequestsDispatchTypes,
  UPDATE_INVENTORY_REQUESTS,
} from "../actions/inventoryRequestsActions/InventoryRequestsActionsTypes"

// Default state interface.
interface DefaultStateI {
  loading: string
  inventoryRequests?: Array<InventoryRequestContent>
  refresh?: boolean
  inventoryRequestsFilters?: object
  inventoryRequestsList?: InventoryRequestsData
  pendinginventoryRequestsList?: InventoryRequestsData
  actionStatus?: any
}

// Default state.
const defaultState: DefaultStateI = {
  loading: "",
}

const InventoryRequestReducer = (
  state: DefaultStateI = defaultState,
  action: InventoryRequestsDispatchTypes
): DefaultStateI => {
  switch (action.type) {
    case INVENTORY_REQUESTS_FAIL:
      return {
        ...state,
        loading: "",
        actionStatus: { menssage: action.payload, status: "error" },
      }
    case INVENTORY_REQUESTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case INVENTORY_REQUESTS_LIST:
      return {
        ...state,
        inventoryRequestsList: action.payload,
        loading: "",
      }
    case INVENTORY_REQUESTS_SET_FILTER:
      return {
        ...state,
        inventoryRequestsFilters: action.payload,
      }
    case INVENTORY_REQUESTS_REFRESH:
      return {
        ...state,
        refresh: action.payload,
      }
    case UPDATE_INVENTORY_REQUESTS:
      return {
        ...state,
        loading: "",
        actionStatus: { menssage: action.payload, status: "success" },
      }
    default:
      return state
  }
}

export default InventoryRequestReducer
