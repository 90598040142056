/**
 *****************************************
 *********** Dispatch Types **************
 *****************************************
 */
export const ADDRESS_LOADING = "ADDRESS_LOADING";
export const ADDRESS_FAIL = "ADDRESS_FAIL";
export const ADDRESS_PUT = "ADDRESS_PUT";
export const ADD_ADDRESS = "ADD_ADDRESS";
export const ADD_CUSTOM_ADDRESS = "ADD_CUSTOM_ADDRESS";

/**
 *****************************************
 ************* Object Types **************
 *****************************************
 */
export type AddressUserType = {
  id?: number,
  userEmail?: string,
  province?: string,
  canton?: string,
  district?: string,
  fullAddress?: string,
  phoneNumber?: string,
  postalCode?: string
}

export type AddAddressResponseType = {
  id: number,
  message: string,
  index: number
}

/**
 *****************************************
 *********** Interfaces Types ************
 *****************************************
 */
export interface AddressUserLoading {
  type: typeof ADDRESS_LOADING
  payload: string
}

export interface AddressUserFail {
  type: typeof ADDRESS_FAIL
  payload: string
}

export interface AddressUserUpdate {
  type: typeof ADDRESS_PUT,
  payload: string
}

export interface AddressUserAdd {
  type: typeof ADD_ADDRESS,
  payload: AddAddressResponseType;
}

export interface AddressCustomAdd {
  type: typeof ADD_CUSTOM_ADDRESS,
  payload: number
}

// Export types.
export type UserAddressDispatchTypes = AddressUserLoading | AddressUserFail | AddressUserUpdate | AddressUserAdd | AddressCustomAdd;