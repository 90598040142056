// React.
import React, { Fragment, useEffect, useState } from "react"

// Components.
import Pagination from "../../admin/utils/ResultsPagination"
import DeliveryRow from "./DeliveryRow"

// Material UI
import CircularProgress from "@material-ui/core/CircularProgress"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { RootStore } from "../../../Store"

// Global config.
import { getProjectConfig } from "../../../getProjectConfig"

// Moment.

// Translations.
import { useTranslation } from "react-i18next"

// Styles.
import { Chip } from "@material-ui/core"
import moment from "moment"
import {
  getUserDeliveries,
  refreshDeliveryView,
} from "../../../actions/deliveryActions/DeliveryActions"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useStyles,
} from "./DeliveryAccordionStyles"

interface DeliveryAccordionProps {
  filterTab: number
  pageByTab: { [key: number]: number }
}

const DeliveryAccordion: React.FC<DeliveryAccordionProps> = ({
  filterTab,
  pageByTab,
}) => {
  const [t] = useTranslation("global")
  const classes = useStyles()
  const dispatch = useDispatch()

  const [currentPage, setCurrentPage] = useState<number>(1)
  const url = window.location.href

  // Redux state.
  const deliveryState = useSelector((state: RootStore) => state.delivery)
  const {
    loading,
    deliveryList,
    refresh,
    deliveryFilters: filters,
  } = deliveryState

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    let page = params.get("page")
    const pNumber = Number(page)
    const currentPage = Number(page) || 1

    // Update the state with the current page for the selected tab.
    setCurrentPage(pageByTab[filterTab] || currentPage)
    if (page && page !== null) {
      const qParams = {
        ...filters,
        page: pNumber - 1,
        size: getProjectConfig().PAGES_SIZE,
        paged: true,
      }
      dispatch(getUserDeliveries(qParams, filterTab))
      setCurrentPage(pNumber)
      scrollToTop()
    } else {
      const qParams = {
        ...filters,
        page: 0,
        size: getProjectConfig().PAGES_SIZE,
        paged: true,
      }
      dispatch(getUserDeliveries(qParams, filterTab))
      setCurrentPage(1)
      scrollToTop()
    }
    dispatch(refreshDeliveryView(false))
    // eslint-disable-next-line
  }, [url, refresh, filterTab, pageByTab])

  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  if (loading === "LIST") {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    )
  }
  if (deliveryList?.deliveries.length === 0) {
    return (
      <div className={classes.circularProgress}>
        <Typography color="textSecondary">{t("orders.no-orders")}</Typography>
      </div>
    )
  }

  return (
    <Fragment>
      {deliveryList?.deliveries !== undefined &&
        deliveryList!.deliveries!.map((delivery) => (
          <Accordion
            key={delivery.packageCode}
            square
            expanded={expanded === `panel-u-${delivery.packageCode}`}
            onChange={handleChange(`panel-u-${delivery.packageCode}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
            >
              <Grid container spacing={1} className={classes.summaryContainer}>
                <Grid item xs={12} sm={2}>
                  <Typography variant="subtitle1">
                    {t("deliveries.code")}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {" "}
                    {delivery.packageCode}{" "}
                  </Typography>

                  {filterTab === 1 ? (
                    <>
                      {delivery.refundStatus === "NA" && <Typography variant="subtitle1">
                        {t("deliveries.delivery-code")}
                      </Typography>}
                      {delivery.refundStatus === "NA" && <Typography variant="caption" color="textSecondary">
                        {delivery.deliverCode}
                      </Typography>}

                      {delivery.refundStatus !== "NA" && (
                        <Chip
                          label={t("deliveries.refunded-package")}
                          color="primary"
                          size="small"
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {delivery.refundStatus !== "NA" && <Typography variant="subtitle1">
                        {t("deliveries.delivery-code")}
                      </Typography>}
                      {delivery.refundStatus !== "NA" && <Typography variant="caption" color="textSecondary">
                        {delivery.refundCode}
                      </Typography>}
                      {delivery.refundStatus !== "NA" && (
                        <Chip
                          label={t("deliveries.refunded-package")}
                          color="primary"
                          size="small"
                        />
                      )}
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={3} className={classes.nameContainer}>
                  {filterTab === 0 ? (
                    <>
                      <Typography variant="subtitle1">
                        {t("deliveries.receiver")}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {" "}
                        {delivery.clientUserReceiverFullName}{" "}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {" "}
                        {delivery.clientUserReceiverEmail}{" "}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="subtitle1">
                        {t("deliveries.sender")}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {" "}
                        {delivery.clientUserSenderFullName}{" "}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {" "}
                        {delivery.clientUserSenderEmail}{" "}
                      </Typography>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={3} className={classes.lastWrapper}>
                  <Typography variant="subtitle1">
                    {t("deliveries.dateCreated")}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {" "}
                    {moment(delivery.creationDate).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              {filterTab === 1 && delivery.refundStatus !== "NA" ? (
                <>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    style={{ paddingLeft: "32px" }}
                  >
                    {t("deliveries.refund-alert-receiver")}
                  </Typography>
                </>
              ) : (
                <>
                  <DeliveryRow delivery={delivery} isSender={filterTab} />
                </>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      {deliveryList?.totalPages !== undefined &&
      deliveryList?.totalPages !== 1 ? (
        <Pagination
          current={currentPage}
          path="/user/deliveries"
          pagesNumber={deliveryList!.totalPages}
        />
      ) : (
        ""
      )}
    </Fragment>
  )
}

export default DeliveryAccordion
