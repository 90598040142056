// React.
import React, {Fragment, useState} from 'react';

// Components.
import OrderItemSearch from '../orders/OrderItemSearch';
import AddOrderShipping from '../orders/AddOrderShipping';
import AddOrderPayment from '../orders/AddOrderPayment';
import AddOrderDetails from '../orders/AddOrderDetails';

// Material UI.
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import { useStyles } from './AddOrderStyles';

// Model types.
import { ShippingType, AddressUserType } from '../../admin/models/ModelTypes';

const AddOrder = () => {
  const [t] = useTranslation('global');
  const classes = useStyles();

  const defaultAddressValues = {
    province: '',
    canton: '',
    district: '',
    fullAddress: '',
    phoneNumber: '',
    postalCode: ''
  }

  const [selectedShipping, setSelectedShipping] = useState<ShippingType>();
  const [selectedPayment, setSelectedPayment] = useState<string>('');
  const [selectedAddressId, setSelectedAddressId] = useState<number>(0);
  const [selectedUserEmail, setSelectedUserEmail] = useState<string>('');
  const [customAddress, setCustomAddress] = useState<AddressUserType>(defaultAddressValues);
  const [validateAddress, setValidateAddress] = useState<boolean>(false);
  const [radioValue, setRadioValue] = useState<string>('custom');
  const [quickOrder, setQuickOrder] = useState<boolean>(false);
  const [selectedVendor, setSelectedVendor] = useState<string>('');

  const quickOrderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuickOrder(event.target.checked);
    setSelectedShipping(undefined);
  };

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} className={ classes.quickOrderWrapper }>
          <FormControlLabel
            control={
              <Switch
                checked={quickOrder}
                onChange={quickOrderChange}
                name="checkedB"
                color="primary"
              />
            }
            label={ t('orders.quick-order') }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <AddOrderShipping 
            selectedShipping={ selectedShipping }
            setSelectedShipping={ setSelectedShipping } 
            selectedAddressId={ selectedAddressId }
            setSelectedAddressId={ setSelectedAddressId }
            setCustomAddress={ setCustomAddress }
            customAddress={ customAddress }
            setValidateAddress={setValidateAddress}
            validateAddress={ validateAddress }
            setRadioValue={ setRadioValue }
            radioValue={ radioValue }
            quickOrder={ quickOrder }
            setSelectedUserEmail={ setSelectedUserEmail }
            selectedVendor={selectedVendor}
            setSelectedVendor={setSelectedVendor}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <OrderItemSearch />
        </Grid>
        <Grid item xs={12} sm={12}>
          <AddOrderPayment 
            setSelectedPayment={ setSelectedPayment }
            selectedPayment={ selectedPayment }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <AddOrderDetails 
            selectedShipping={ selectedShipping }
            selectedPayment={ selectedPayment }
            selectedAddressId={ selectedAddressId }
            customAddress={ customAddress }
            setValidateAddress = { setValidateAddress }
            radioValue= { radioValue }
            quickOrder={ quickOrder }
            selectedUserEmail={ selectedUserEmail }
            selectedVendor={selectedVendor}
          />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default AddOrder;
