/**
 *****************************************
 *********** Dispatch Types **************
 *****************************************
 */
 export const CART_FAIL = "CART_FAIL";
 export const CART_LOADING = "CART_LOADING";
 export const DELETE_CART = "DELETE_CART";
 export const ADD_CART = "ADD_CART";
 export const CHECKOUT_CART = "CHECKOUT_CART";
 
 /**
  *****************************************
  *********** Interfaces Types ************
  *****************************************
  */

export type CheckoutCartResponse = {
  message: string
  orderIds: Array<number>
};

 export interface CartLoading {
   type: typeof CART_LOADING
   payload: string
 }
 export interface CartFail {
   type: typeof CART_FAIL,
   payload: string
 }
 export interface CartDelete {
   type: typeof DELETE_CART,
   payload: string
 }
 export interface CartAdd {
   type: typeof ADD_CART,
   payload: string
 }

 export interface CheckoutCart {
  type: typeof CHECKOUT_CART,
  payload: CheckoutCartResponse
}
 
 // Export types.
 export type CartActionsTypes = CartLoading | CartFail | CartDelete | CartAdd | CheckoutCart;
 