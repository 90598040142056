// Material UI
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		sectionWrapper: {
			padding: theme.spacing(2, 2),
			minHeight: "550px",
			[theme.breakpoints.down("md")]: {
				paddingTop: theme.spacing(2, 2),
			},
			[theme.breakpoints.down("xs")]: {
				paddingTop: theme.spacing(11),
			},
			"& .MuiPaper-root": {
				padding: theme.spacing(2),
			},
			"& .MuiCircularProgress-root": {
				position: "absolute",
				left: "49%",
				top: "50%",
				zIndex: 1,
			},
		},
		vendorName: {
			fontFamily: "VisbyCF-Bold",
			textAlign: "center",
			marginBottom: theme.spacing(3),
		},
		sectionWrapperFilters: {
			[theme.breakpoints.down("sm")]: {
				"&.MuiPaper-root": {
					padding: "0",
				},
			},
		},
	})
);

export default useStyles;
