import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(12),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 1),
      },
    },
    circularProgress: {
      textAlign: 'center',
    },
  })
);

export default useStyles;