import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

const useReportsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(12),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 1),
      },
      '& .MuiCircularProgress-root': {
        zIndex: 1,
      },
      '& .MuiInput-underline:after': {
        borderBottom: '1px',
        borderBottomColor: '#515151',
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: '#515151',
      },
      '& .MuiDataGrid-cell button': {
        color: theme.palette.primary.main,
        cursor: 'pointer'
      },
    },
    leftMargin: {
      marginLeft: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        marginLeft: 0
      },
    },
    formControl: {
      width: '214px',
      [theme.breakpoints.down('xs')]: {
        width: '180px'
      },
      '& .MuiInputBase-root': {
        maxHeight: '32px'
      }
    },
    modalPaper: {
      padding: theme.spacing(6, 4),
      position: 'absolute',
      width: '65%',
      height: '70%',
      top: 'calc(15%)',
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      overflowY: 'auto',
      [theme.breakpoints.down('md')]: {
        width: '85%'
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(6, 2),
        width: '90%',
      },
      '& .MuiTypography-root': {
        marginBottom: theme.spacing(2),
      },
      '& .MuiButtonBase-root': {
        marginLeft: theme.spacing(2),
      }
    },
    closeModal: {
      position: 'absolute',
      right: '0',
      top: '0'
    },
    orderRow: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      marginBottom: theme.spacing(2)
    },
    borderTop: {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
    loadIcon: {
      margin: '0 auto',
      display: 'block'
    }
  })
);

export default useReportsStyles;