import {
  BUNDLE_LOADING, BUNDLE_FAIL, ADD_BUNDLE, BUNDLE_PUT, BUNDLE_LIST, BUNDLE_LIST_UNIQUE, BUNDLE_REFRESH, 
  BUNDLE_SET_FILTER, DELETE_BUNDLE, BundleType, BundleList, BundleActionsTypes
} from "../actions/bundleActions/BundleActionsTypes";

// Default state interface.
interface DefaultStateI {
  loading: string,
  bundle?: BundleType 
  bundleList?: BundleList,
  bundleListUnique?: BundleList,
  actionStatus?: any,
  refresh?: boolean,
  bundleFilters?: object,
}

// Default state.
const defaultState: DefaultStateI = {
  loading: '',
};

const bundleReducer = (state: DefaultStateI = defaultState, action: BundleActionsTypes) : DefaultStateI => {
  switch (action.type) {
    case BUNDLE_FAIL:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'error'}
      }
    case BUNDLE_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case ADD_BUNDLE:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'success'},
        bundle: action.bundle
      }
    case BUNDLE_PUT:
      return {
        ...state,
        loading: '',
        bundle: action.payload.bundle,
        actionStatus: {menssage: action.payload.message, status: 'success'}
      }
    case DELETE_BUNDLE:
      return {
        ...state,
        loading: '',
        actionStatus: {menssage: action.payload, status: 'success'}
      }
    case BUNDLE_LIST:
      return {
        ...state,
        loading: '',
        actionStatus: undefined,
        bundleList: action.payload
      }
    case BUNDLE_LIST_UNIQUE:
      return {
        ...state,
        loading: '',
        actionStatus: undefined,
        bundleListUnique: action.payload
      }
    case BUNDLE_REFRESH:
      return {
        ...state,
        refresh: action.payload
      }
    case BUNDLE_SET_FILTER:
      return {
        ...state,
        bundleFilters: action.payload
      }
    default:
      return state
  }
};

export default bundleReducer;
