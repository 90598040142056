// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkoutStep: {
      padding: theme.spacing(3, 1),
      display: 'flex'
    },
    linesStep: {
      borderTop: '2px solid',
      borderTopColor: '#aaa',
      borderBottom: '2px solid',
      borderBottomColor: '#aaa'
    },
    elementContent: {
      width: '80%',
      [theme.breakpoints.down('xs')]: {
        width: '70%',
      },
      '&.full': {
        width: '100%'
      }
    },
    elementCta: {
      width: '20%',
      textAlign: 'right',
      [theme.breakpoints.down('xs')]: {
        width: '30%',
      },
    },
    bottomWrapper: {
      marginTop: theme.spacing(3),
      textAlign: 'right',
      '& .MuiTypography-root': {
        marginBottom: theme.spacing(2),
      }
    },
    coinsUsed:{
      display:'flex',
      justifyContent:'end',
      alignItems:'center',
      gap:'5px'
    }
  }),
);

export default useStyles;
