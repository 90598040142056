// React.
import React, { useEffect, useState, Fragment } from 'react';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import { getAllAssociateOrdersForReports, getOrders } from "../../../actions/orderActions/OrderActions";
import { getVendors } from "../../../actions/vendorActions/VendorActions";

// Material UI
import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  Select,
  ListItemText,
  InputLabel,
  Input,
  Modal,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import DateAdapter from '@mui/lab/AdapterMoment';
import CloseIcon from '@material-ui/icons/Close';

// Moment.
import Moment from 'moment';

// Styles
import useReportsStyles from './ReportsStyles';

// Translations
import { useTranslation } from 'react-i18next';

// Global config.
import { getProjectConfig } from '../../../getProjectConfig'

// Components
import { generateAssociateReport } from '../utils/downloadOrdersReport';

const ReportsAssociate = () => {
  const [t] = useTranslation('global');
  const classes = useReportsStyles();
  const dispatch = useDispatch();

  const currencyFormatter = (e: any) => {
    if (e.value === undefined || e.value === '' || e.value === null) return 'N/A';
    return ` ${getProjectConfig().CURRENCY_SYMBOL}${Math.round(e.value).toLocaleString('en-US')}`;
  };

  const columns: GridColDef[] = [
    {
      field: 'vendorName',
      headerName: t('orders.seller'),
      type: 'string',
      width: 200,
      filterable: true
    },
    {
      field: 'totalOrdersProcessed',
      headerName: t('orders.total-orders-completed'),
      type: 'string',
      width: 260,
      filterable: true,
    },
    {
      field: 'totalCommision',
      headerName: t('orders.total-commission'),
      type: 'string',
      width: 200,
      filterable: true,
      valueFormatter: currencyFormatter,
    },
    {
      field: 'totalToPay',
      headerName: t('orders.total-to-pay'),
      type: 'string',
      width: 190,
      filterable: true,
      valueFormatter: currencyFormatter,
    },
    {
      field: 'totalPriceOrders',
      headerName: t('orders.total-sold-associate'),
      type: 'string',
      width: 190,
      filterable: true,
      valueFormatter: currencyFormatter,
    },
    {
      field: "details",
      headerName: " ",
      type: 'string',
      width: 130,
      renderCell: (params) => (
        <Button variant="text" size="small" onClick={() => changeSelectedVendorTable(String(params.value))}>{t('orders.details')}</Button>
      )
    }
  ];

  const orderState = useSelector((state: RootStore) => state.order);
  const { associateReport, loading, orderList } = orderState;
  const reduxVendorsState = useSelector((state: RootStore) => state.vendors);
  const vendorsList = reduxVendorsState.vendors;
  const reduxAuthState = useSelector((state: RootStore) => state.auth);
  const loggedUser = reduxAuthState.loggedUser;

  const [initialDateRange, setInitialDateRange] = useState<string>(`${Moment().startOf('month').format('YYYY-MM-DD')}`);
  const [endDateRange, setEndDateRange] = useState<string>(`${Moment().endOf('month').format('YYYY-MM-DD')}`);
  const [selectedVendor, setSelectedVendor] = useState<string>('');
  const [selectedVendorTable, setSelectedVendorTable] = useState<string>('');
  const [selectedVendorNameTable, setSelectedVendorNameTable] = useState<string>('');
  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedVendorTable('');
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  useEffect(() => {
    let filters = {
      initialDateRange: Moment().startOf('month').format('YYYY-MM-DD'),
      endDateRange: Moment().endOf('month').add(1, 'days').format('YYYY-MM-DD')
    };
    dispatch(getAllAssociateOrdersForReports(filters));
    dispatch(getVendors({ paged: false, isAssociate: true }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedVendorTable !== '') {
      let filters = {
        initialDateRange: initialDateRange,
        endDateRange: Moment(endDateRange).add(1, 'days').format('YYYY-MM-DD'),
        vendorId: selectedVendorTable
      };
      dispatch(getOrders(filters));
      handleOpenModal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVendorTable]);

  const changeSelectedVendorTable = (vendorName:string) => {
    if (loggedUser?.roles.includes('ROLE_SELLER_ASSOCIATE')) {
      setSelectedVendorTable(loggedUser?.sellerProfile?.vendors[0].id);
    } else {
      setSelectedVendorTable(getVendorIdByName(vendorName));
    }
    setSelectedVendorNameTable(vendorName);
  };

  const searchOrdersClick = () => {
    let filters = {
      ...(initialDateRange !== '') && { initialDateRange: initialDateRange },
      ...(endDateRange !== '') && { endDateRange: Moment(endDateRange).add(1, 'days').format('YYYY-MM-DD') },
      ...(selectedVendor !== '') && { vendorId: selectedVendor }
    };

    dispatch(getAllAssociateOrdersForReports(filters));
  };

  const saveCsvToDesktop = () => {
    let filters = {
      ...(initialDateRange !== '') && { initialDateRange: initialDateRange },
      ...(endDateRange !== '') && { endDateRange: Moment(endDateRange).add(1, 'days').format('YYYY-MM-DD') },
      ...(selectedVendor !== '') && { vendorId: selectedVendor }
    };

    let role = (loggedUser?.roles.includes('ROLE_ADMIN')) ? 'admin' : 'seller';
    generateAssociateReport(filters, role);
  };

  const getVendorIdByName = (vendorName: string) => {
    if (vendorsList !== undefined) {
      const currentVendor = vendorsList.filter((vendor) => vendor.name.toLowerCase() === vendorName.toLowerCase());
      if (currentVendor.length > 0) return String(currentVendor[0].id);
    }
    return '';
  };

  return (
    <div className={classes.root}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Paper elevation={3} style={{ marginBottom: 30 }}>
          <Container style={{ paddingTop: 20, paddingBottom: 20 }}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Typography variant="h5" color="primary">
                  {t("orders.report-page-associate-title")}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2} direction={'row'} style={{marginTop: 10, marginBottom: 10 }}>
              <Grid item>
                <DatePicker
                  label={t("orders.order-from-date")}
                  value={initialDateRange}
                  onChange={(newValue: any) => {
                    setInitialDateRange(newValue.format('YYYY-MM-DD'))
                  }}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </Grid>
              <Grid item >
                <DatePicker
                  label={t("orders.order-to-date")}
                  value={endDateRange}
                  onChange={(newValue: any) => {
                    setEndDateRange(newValue.format('YYYY-MM-DD'))
                  }}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </Grid>
              <Grid item >
                <FormControl className={classes.formControl}>
                  <InputLabel id="languages-checkbox-label">{t('orders.seller')}</InputLabel>
                  <Select
                    labelId="languages-checkbox-label"
                    id="languages-checkbox"
                    input={<Input />}
                    value={selectedVendor}
                    onChange={(e) => setSelectedVendor(`${e.target.value}`)}
                  >
                    {vendorsList?.map((vendor) =>
                      <MenuItem key={vendor.id} value={vendor.id}>
                        <ListItemText primary={vendor.name} />
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} direction={'row'} justifyContent='flex-end'>
              <Grid item >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={ searchOrdersClick }
                >
                  {t('orders.search')}
                </Button>
              </Grid>

              <Grid item >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveCsvToDesktop}
                >
                  {t('orders.save')}
                </Button>
              </Grid>

            </Grid>

          </Container>
        </Paper>

        <Paper elevation={3} style={{ minHeight: 500 }}>
          <Container>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12}>
                <Typography style={{ marginBottom: 10 }}>
                  {t('orders.total-sold-associate')}:
                  <Typography variant="caption" color="textSecondary">{currencyFormatter({ value: associateReport?.totalPriceOrders })}</Typography>
                </Typography>
                <Typography style={{ marginBottom: 10 }}>
                  {t('orders.total-commission')}:
                  <Typography variant="caption" color="textSecondary">{currencyFormatter({ value: associateReport?.totalCommission })}</Typography>
                </Typography>
                <Typography style={{ marginBottom: 10 }}>
                  {t('orders.total-to-pay')}:
                  <Typography variant="caption" color="textSecondary">{currencyFormatter({ value: associateReport?.totalToPay })}</Typography>
                </Typography>
                <DataGrid
                  rows={associateReport?.associateReportDataList ?? []}
                  columns={columns}
                  checkboxSelection={false}
                  hideFooter={true}
                  loading={loading === "ASSOCIATE_LIST"}
                  autoHeight
                />
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </LocalizationProvider>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="Confirm Modal"
      >
        <Paper elevation={3} className={classes.modalPaper}>
          <IconButton className={classes.closeModal} aria-label="close modal" component="span" onClick={() => handleCloseModal()}>
            <CloseIcon />
          </IconButton>
          {(orderList === undefined || loading !== '') ?
            <CircularProgress className={classes.loadIcon} /> :
            <Fragment>
              <Typography variant="h5" color="primary">
                {t('orders.order-list')} ({selectedVendorNameTable})
              </Typography><br/>
              {orderList.orders.map((order, index) =>
                <Grid key={index} container spacing={4} className={`${classes.orderRow} ${index === 0 ? classes.borderTop : ''}`}>
                  <Grid item xs={6} sm={3}>
                    <Grid item xs={12}><Typography variant="caption">Id</Typography></Grid>
                    <Grid item xs={12}>{order.id}</Grid>
                    <Grid item xs={12}><Typography variant="caption">{t('orders.code')}</Typography></Grid>
                    <Grid item xs={12}>{order.orderCode}</Grid>
                    <Grid item xs={12}><Typography variant="caption">{t('orders.seller')}</Typography></Grid>
                    <Grid item xs={12}>{order.vendor.name}</Grid>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Grid item xs={12}><Typography variant="caption">{t('orders.status')}</Typography></Grid>
                    <Grid item xs={12}>{order.status}</Grid>
                    <Grid item xs={12}><Typography variant="caption">{t('orders.items')}</Typography></Grid>
                    <Grid item xs={12}>
                      {order.orderItems.map((orderItem, index) => 
                        <div>{orderItem.item.name} ({orderItem.normal_quantity})</div>
                      )}
                    </Grid>
                    <Grid item xs={12}><Typography variant="caption">{t('orders.tracking')}</Typography></Grid>
                    <Grid item xs={12}>{order.trackingNumber ? order.trackingNumber : 'N/A'}</Grid>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Grid item xs={12}><Typography variant="caption">{t('orders.sell-type')}</Typography></Grid>
                    <Grid item xs={12}>{order.type}</Grid>
                    <Grid item xs={12}><Typography variant="caption">{t('orders.payment-method')}</Typography></Grid>
                    <Grid item xs={12}>{order.paymentMethod}</Grid>
                    <Grid item xs={12}><Typography variant="caption">{t('orders.total-cost')}</Typography></Grid>
                    <Grid item xs={12}>{currencyFormatter({ value: order.totalCost })}</Grid>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Grid item xs={12}><Typography variant="caption">{t('orders.created')}</Typography></Grid>
                    <Grid item xs={12}>{Moment(order.creationDate).format('MM/DD/YYYY')}</Grid>
                    <Grid item xs={12}><Typography variant="caption">{t('orders.last-update')}</Typography></Grid>
                    <Grid item xs={12}>{Moment(order.updatedDate).format('MM/DD/YYYY')}</Grid>
                    <Grid item xs={12}><Typography variant="caption">{t('orders.processed-date')}</Typography></Grid>
                    <Grid item xs={12}>{order.processedDate ? Moment(order.processedDate).format('MM/DD/YYYY') : 'N/A'}</Grid>
                    <Grid item xs={12}><Typography variant="caption">{t('orders.completed-date')}</Typography></Grid>
                    <Grid item xs={12}>{order.completedDate ? Moment(order.completedDate).format('MM/DD/YYYY') : 'N/A'}</Grid>
                  </Grid>
                </Grid>
              )}
              {(orderList.orders.length < 1) &&
                <Typography>
                  {t('orders.no-orders')}
                </Typography>
              }
            </Fragment>
          }
        </Paper>
      </Modal>
    </div>
  )
}

export default ReportsAssociate;