/**
 *****************************************
 *********** Dispatch Types **************
 *****************************************
 */
export const READ_NOTIFICATION = "READ_NOTIFICATION";
export const NOTIFICATION_LOADING = "NOTIFICATION_LOADING";
export const NOTIFICATION_FAIL = "NOTIFICATION_FAIL";
export const GET_USER_NOTIFICATIONS = "GET_USER_NOTIFICATIONS";
export const GET_ORDER_NOTIFICATIONS = "GET_ORDER_NOTIFICATIONS";

export type UserNotificationType = {
  id: number,
  message: string,
  read: boolean,
  releaseDate: string,
  title: string
};

export type UserNotificationDataType = {
  content: UserNotificationType[],
  empty: boolean,
  first: boolean,
  last: boolean,
  number: number,
  numberOfElements: number,
  size: number,
  totalElements: number,
  totalPages: number
};

export type OrderNotificationType = {
  ordersActive: number,
  ordersReceived: number
};

/**
 *****************************************
 ************* Object Types **************
 *****************************************
 */

export interface NotificationLoading {
  type: typeof NOTIFICATION_LOADING
  payload: string
}
  
export interface NotificationFail {
  type: typeof NOTIFICATION_FAIL,
  payload: string
}

export interface ReadNotificationType {
  type: typeof READ_NOTIFICATION,
  payload: string
};

export interface GetUserNotifications {
  type: typeof GET_USER_NOTIFICATIONS,
  payload: UserNotificationDataType
}

export interface GetOrderNotifications {
  type: typeof GET_ORDER_NOTIFICATIONS,
  payload: OrderNotificationType
}

// Export types.
export type NotificationDispatchTypes = ReadNotificationType | NotificationLoading | NotificationFail | GetUserNotifications | GetOrderNotifications;
