// React.
import React, { Fragment, useEffect, useState } from "react";

// Components.
import ImageAvatar from "../../utils/ImageAvatar";
import NumberFormat from "../../utils/NumberFormat";

// Material UI
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../Store";

// Translations.
import { useTranslation } from "react-i18next";

// Global config.
import { getProjectConfig } from "../../../../getProjectConfig";

// Styles.
import {
  useStyles,
  Accordion,
  AccordionSummary,
} from "./AddBoosterAccordionStylesModal";
import { getCurrentPlayerOrder, registerItem } from "../../../../actions/eventActions/eventActions";

const AddOrderAccordion = () => {
  const [t] = useTranslation("global");
  const classes = useStyles();
  const dispatch = useDispatch();

  //local state
  const [currentPlayerOrder, setCurrentPlayerOrder] = useState<any>();


  // Redux state.
  const eventState = useSelector((state: RootStore) => state.events);

  const deleteAddedItem = (id: any) => {
    const payload = 
      {
        eventPlayerId: eventState.currentPlayerOrderId?.id,
        itemId: id,
        quantity: -100,
        isParticipation:false
      }    
    dispatch(registerItem(payload))
  };

  const getUnitPrice = (item: any) => {
    const unitPrice = item.totalPrice / item.quantity;
    return (
      <div>
        <Typography color="primary" className={classes.modalListStyle}>
          {getProjectConfig().CURRENCY_SYMBOL}
          {<NumberFormat number={unitPrice} />}
        </Typography>
      </div>
    );
  };
  useEffect(() => {
    setCurrentPlayerOrder(eventState.currentPlayerOrder);
  }, [eventState.currentPlayerOrder]);

  useEffect(() => {
    dispatch(getCurrentPlayerOrder(eventState.currentPlayerOrderId));
  }, [eventState.currentPlayerOrderId, dispatch,eventState.actionStatus]);

  return (
    <Fragment>
      {currentPlayerOrder?.itemsOrder.map((element: any) => {
        return (
          <Accordion key={element.id} square expanded={false}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
            >
              <Grid container spacing={2}>
                <Grid item xs={2} sm={2} className={classes.userImgSmall}>
                  <ImageAvatar
                    src={element.images ? element.image.imageURL : ""}
                    alt="The main item image."
                  />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <Typography className={classes.modalListStyle}>
                    {element.name}
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <Typography
                    className={classes.modalListStyle}
                    color="textSecondary"
                  >
                    {t("orders.item-unit-price")}
                  </Typography>
                  <div>{getUnitPrice(element)}</div>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <Typography
                    className={classes.modalListStyle}
                    color="textSecondary"
                  >
                    {t("orders.item-quantity")}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    className={classes.modalListStyle}
                  >
                    {element.quantity}
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <Typography
                    className={classes.modalListStyle}
                    color="textSecondary"
                  >
                    {t("orders.total")}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    className={classes.modalListStyle}
                  >
                    {getProjectConfig().CURRENCY_SYMBOL}
                    {<NumberFormat number={element.totalPrice} />}
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={1}>
                  <IconButton
                    aria-label="delete"
                    onClick={() => deleteAddedItem(element.id)}
                  >
                    <DeleteIcon color="action" />
                  </IconButton>
                </Grid>
              </Grid>
            </AccordionSummary>
          </Accordion>
        );
      })}
    </Fragment>
  );
};

export default AddOrderAccordion;
