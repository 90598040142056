// React.
import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import DocumentMeta from 'react-document-meta';


// Material UI.
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

// Component.
import BundleDetail from '../bundles/BundleDetail';


// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

// Redux
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {getBundles} from "../../../actions/bundleActions/BundleActions";
import { getUserInformation } from '../../../actions/authActions/AuthActions';

// Styles.
import useStyles from './BundleDetailPageStyles';

type ParamsType = {
  id: string;
};

const BundleDetailPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const authToken: string | undefined = localStorage.token;
  // Page metatags info.
  const meta = getProjectConfig().METATAGS.ITEM_DETAIL;
  const { id } = useParams<ParamsType>();

  // Redux state.
  const bundleProductState = useSelector((state: RootStore) => state.bundle);
  const { bundleList } = bundleProductState;
  const authState = useSelector((state: RootStore) => state.auth);
  const { loggedUser } = authState;


  // Get user information (Neded for Cart data).
  useEffect(() => {
    if (authToken !== undefined && authToken !== '') {
      if (loggedUser === undefined) dispatch(getUserInformation());
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const qParams = {
      id: id,
      page: 0,
      size: getProjectConfig().PAGES_SIZE,
      paged: true
    };
    dispatch(getBundles(qParams));

    // eslint-disable-next-line
  }, []);

  return (
    <DocumentMeta {...meta}>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.paperWrapper}>
        <Paper elevation={3} square={false} variant="elevation">
          {bundleList?.bundles[0] !== undefined && 
            <BundleDetail bundle={bundleList?.bundles[0]}/>
          }
        </Paper>
      </Container>
    </DocumentMeta>
  )
}

export default BundleDetailPage;
