// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(2, 1),
        padding: theme.spacing(4, 4, 1),
        width: '100%',
        height: 'auto',
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(4, 2, 1),
        },
      },
      '& .address-box': {
        margin: theme.spacing(2, 0),
        backgroundColor: '#f1f1f1',
        cursor: 'pointer',
        height: 'fit-content',
        '&.selected': {
          border: '2px solid',
          borderColor: theme.palette.primary.main
        },  
        '& .MuiCardContent-root': {
          paddingBottom: '14px'
        },  
      }
    },
    boxTop: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      '& > *': {
        marginRight: theme.spacing(2),
      },
    },
    shippingOptionsWrapper: {
      width: '100%',
      padding: theme.spacing(3, 0),
      display: 'flex',
      flexWrap: 'wrap',
      '& .MuiCard-root': {
        backgroundColor: '#f1f1f1',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1 ),
        cursor: 'pointer',
        height: 'fit-content',
        [theme.breakpoints.down('sm')]: {
          width: '90%',
        },
      },
      '& .selected': {
        border: '2px solid',
        borderColor: theme.palette.primary.main
      },
      '& .disabled': {
        pointerEvents: 'none',
        boxShadow: 'none'
      },
      '& .MuiCardContent-root': {
        padding: theme.spacing(3, 5)
      }
    },
    addressRadioWrapper: {
      '& .MuiFormGroup-root': {
        flexDirection: 'row'
      }
    },
    modalPaper: {
      padding: theme.spacing(2, 4),
      position: 'absolute',
      width: '600px',
      height: 'auto',
      top: 'calc(50% - 134px)',
      left: 'calc(50% - 300px)',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2, 1),
        width: '90%',
        left: '5%'
      },
    },
    formControl: {
      margin: theme.spacing(1),
      display: 'block',
      '& > *': {
        display: 'block',
      }
    },
    formField: {
      width: '100%',
      '& > div': {
        width: '100%',
      },
    },
    formControlVendor: {
      width: '304px',
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        width: '234px'
      },
      '& .MuiInputBase-root': {
        maxHeight: '32px'
      }
    }
  }),
);

export default useStyles;
