// React.
import React, {useState} from 'react';

// Material UI
import Paper from '@material-ui/core/Paper';
import StoreIcon from '@material-ui/icons/Store';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SortIcon from '@material-ui/icons/Sort';
import CircularProgress from '@material-ui/core/CircularProgress';

// Components.
import ItemAccordion from './ItemAccordion';

// Redux
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {itemSetFilters, refreshItemView} from "../../../actions/itemActions/ItemActions";

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './ItemListStyles';
import { getProjectConfig } from '../../../getProjectConfig';

const ItemList = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();

  // Local state.
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sort, setSort] = useState<string>(t('items.relevance'));
  const open = Boolean(anchorEl);

  // Redux state.
  const itemProductState = useSelector((state: RootStore) => state.item);
  const {itemFilters: filters, loading} = itemProductState;
  const vendorsState = useSelector((state: RootStore) => state.vendors);
  const vendorLoading = vendorsState.loading;

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelected = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    if (event.currentTarget.getAttribute('value') !== '') {
      const params = new URLSearchParams(window.location.search);
      let page = params.get('page');
      const pNumber = Number(page);
      const sendFilter = {
        ...filters,
        page: pNumber - 1,
        size: getProjectConfig().PAGES_SIZE,
        paged: true,
        sort: `${event.currentTarget.getAttribute('value')}`,
        isUniquePerCode: true
      };
      setSort(`${event.currentTarget.getAttribute('value')}`);
      dispatch(itemSetFilters(sendFilter));
      dispatch(refreshItemView(true));
    }
  };

  const getSortSelectedLabel = () => {
    var result = sort;
    switch (sort) {
      case 'id,desc':
        result = t('items.relevance');
        break;
      case 'name,asc':
        result = 'A-Z';
        break;
      case 'name,desc':
        result = 'Z-A';
        break;
    }
    return result;
  };

  return (
    <div className={ classes.root }>
      <Paper elevation={3}>
        <div className={ classes.boxTop }>
          <StoreIcon color="primary" fontSize="large"/>
          <Typography variant="h5" color="primary" className={ classes.sectionTitle }>
            { t('users.search-results') }
            {(loading !== '' || vendorLoading !== '') && 
              <span className={ classes.loading }><CircularProgress/></span>
            }
          </Typography>
          <Button variant="text" color="primary" size="medium" onClick={handleMenu} startIcon={<SortIcon />}>
            {t('items.sort')} ({getSortSelectedLabel()})
          </Button>
          <Menu id="filter-users" anchorEl={ anchorEl }
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={ open }
            onClose={handleClose}
          >
            <MenuItem onClick={handleSelected} value="id,desc" selected={sort === 'id,desc'}>{t('items.relevance')}</MenuItem>
            <MenuItem onClick={handleSelected} value="name,asc" selected={sort === 'name,asc'}>{ 'A-Z' }</MenuItem>
            <MenuItem onClick={handleSelected} value="name,desc" selected={sort === 'name,desc'}>{ 'Z-A' }</MenuItem>
          </Menu>
        </div>
        <div>
          <ItemAccordion />
        </div>
      </Paper>
    </div>
  )
}

export default ItemList
