// React.
import React, { useState, useEffect, Fragment } from 'react';

// Material UI
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../Store";
import { getItems } from "../../../../actions/itemActions/ItemActions";

// Global config.
import { getProjectConfig } from '../../../../getProjectConfig';

// Models.
import { ItemWarehouseType } from '../../models/ModelTypes';

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './OrderItemSearchModalStyles';

// Model types.
import { ItemType, BundleType } from '../../models/ModelTypes';
import { getVendors } from '../../../../actions/vendorActions/VendorActions';
import { registerItem } from '../../../../actions/eventActions/eventActions';

const OrderItemSearchModal = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();

  // Local component state.
  const [currentItem, setCurrentItem] = useState<ItemType | undefined>(undefined);
  const [currentBundle, setCurrentBundle] = useState<BundleType | undefined>(undefined);
  const [typingValue, setTypingValue] = useState<string>('');
  const [clearSearch, setClearSearch] = useState<boolean>(false);
  const [itemQuantity, setItemQuantity] = useState<number | undefined>(undefined);
  const [quantityError, setQuantityError] = useState(false);
  const [searchOptions, setSearchOptions] = useState<Array<any>>([]);
  const [currentCustomReference, setCurrentCustomReference] = useState<string>('');

  // Redux state.
  const orderState = useSelector((state: RootStore) => state.order);
  const itemsAdded = orderState.addedInOrder?.itemAddedList;
  const bundlesAdded = orderState.addedInOrder?.bundleAddedList;
  const itemState = useSelector((state: RootStore) => state.item);
  const itemList = itemState.itemList;
  const bundleState = useSelector((state: RootStore) => state.bundle);
  const bundleList = bundleState.bundleList;
  const eventState = useSelector((state: RootStore) => state.events);

  useEffect(() => {
    dispatch(getVendors());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (typingValue !== '') {
      const delayDebounceFn = setTimeout(
        () => triggerSearchItemsAutocomplete(),
        500
      );
      return () => clearTimeout(delayDebounceFn)
    }
    // eslint-disable-next-line
  }, [typingValue]);

  // Update the search options array.
  useEffect(() => {
    if (itemList !== undefined || bundleList !== undefined) {
      var optionsList: Array<any> = [];
      // Add bundle results first.
      bundleList?.bundles.forEach((option: any) => {
        optionsList.unshift({ element: option, type: 'bundle' });
      });
      // Add items results.
      itemList?.items.forEach((option: any) => {
        optionsList.push({ element: option, type: 'item' });
      });
      setSearchOptions(optionsList);
    }
    // eslint-disable-next-line
  }, [itemList, bundleList]);
  const triggerSearchItemsAutocomplete = () => {
    const qParamsItems = { name: typingValue, page: 0, size: getProjectConfig().PAGES_SIZE, paged: true, vendorId:eventState.eventDetail?.vendorId };
    // Search normal items.
    dispatch(getItems(qParamsItems, 'seller'));
  };
  const handleCustomReferenceChange = (value: unknown) => {
    if (value !== undefined) {
      setCurrentCustomReference(`${value}`);
    }
  }
  const handleChangeItemName = (event: any, selectedItem: any) => {
    if (selectedItem.type === 'bundle') {
      setCurrentBundle(selectedItem.element);
      setCurrentItem(undefined);
    } else {
      setCurrentItem(selectedItem.element);
      setCurrentBundle(undefined);
    }
    if (selectedItem.element.totalQuantity !== undefined && selectedItem.element.totalQuantity > 0) {
      setItemQuantity(1);
      setQuantityError(false);
    } else {
      setItemQuantity(undefined);
    }
  };
  const handleChangeTyping = (event: React.ChangeEvent<{ value: string }>) => {
    setTypingValue(event.target.value);
    if (event.target.value === '') {
      setCurrentItem(undefined);
      setCurrentBundle(undefined);
      setItemQuantity(undefined);
    }
  };
  const getCustomReferenceQty = (referenceName: string, item: ItemType) => {
    var quantity = 0;
    const priceObj = item.warehousesCustomQuantities?.filter((customQty) => customQty.id.customReference === referenceName);
    if (priceObj?.length! > 0) quantity = priceObj![0]?.quantity;
    return quantity;
  };
  const handleChangeItemQuantity = (event: React.ChangeEvent<{ value: string }>) => {
    if (event.target.value) {
      var vendorStock = 0;
      if (currentBundle !== undefined) {
        vendorStock = currentBundle.totalQuantity;
      } else {
        if (currentItem?.category === 'Single') {
          vendorStock = getCustomReferenceQty(currentCustomReference, currentItem);
        } else {
          vendorStock = getStockQuantity(currentItem?.warehousesQuantities) ?? 0;
        }
      }
      // Items in cart.
      let itemsInCart = 0;
      if (orderState.addedInOrder?.itemAddedList) {
        orderState.addedInOrder.itemAddedList.forEach(i => {
          if (i.item.id === currentItem?.id) {
            itemsInCart = i.quantity
          }
        }
        )
      }
      // Bundles in cart.
      if (orderState.addedInOrder?.bundleAddedList) {
        orderState.addedInOrder.bundleAddedList.forEach(i => {
          if (i.bundle.id === currentBundle?.id) {
            itemsInCart = i.quantity
          }
        }
        )
      }
      const quantity = Number(event.target.value);
      const available = vendorStock - itemsInCart;
      if ((quantity > available) || (quantity < 1)) {
        setQuantityError(true);
        setItemQuantity(quantity);
      } else {
        setItemQuantity(quantity);
        setQuantityError(false);
      }
    } else {
      setItemQuantity(undefined);
    }
  };
  const getStockQuantity = (quantities: Array<ItemWarehouseType> | undefined) => {
    var total = 0;
    if (quantities !== undefined) {
      quantities?.forEach((element: ItemWarehouseType) => {
        total += element.quantity;
      });
    }
    return total;
  };

  const numberFormat = (value: number) => {
    var roundedNumber = Math.round(value);
    return roundedNumber.toLocaleString('en-US')
  }
  const getVendorOptionLabel: any = (option: any): string => {
    if (option.type !== undefined) {
      const symbol = option.element.currency.symbol;
      if (option.type === 'item') {
        const price = option.element.price ? `${t('items.normal-price')}: ${symbol}` + numberFormat(option.element.price) : '';
        const priceFoil = (option.element.additionalPrice ? `${t('items.foil-price')}: ${symbol}` + numberFormat(option.element.additionalPrice) : '');
        let priceString = `${price} ${priceFoil}`;
        return `${option.element.name} | Stock: ${option.element.totalQuantity} | ${priceString} | (${option.element.category})`;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
  const addItemOrder = () => {
    const currentListItems = itemsAdded !== undefined ? itemsAdded : [];
    const currentListBundles = bundlesAdded !== undefined ? bundlesAdded : [];
    if (itemQuantity !== undefined) {
      // Validate if the item is already added.
      // For normal items.
      if (currentItem !== undefined) {
        let payload= {
         eventPlayerId:eventState.currentPlayerOrderId?.id,
         itemId:currentItem.id,
         quantity:itemQuantity,
         isParticipation:false 
        }
        dispatch(registerItem(payload))
      }
      if (currentListItems !== undefined || currentListBundles !== undefined) {
        // Clear fields
        setCurrentItem(undefined);
        setCurrentBundle(undefined);
        setTypingValue('');
        setItemQuantity(undefined); 
        setCurrentCustomReference('');

        setClearSearch(true);
        setTimeout(
          () => setClearSearch(false),
          1000
        )
      }
    }
  };
  return (
    <div className={classes.root}>
        <div className={classes.boxTop}>
          <ConfirmationNumberIcon color="primary" fontSize="medium" />
          <Typography color="primary">
          {t('tournament.add-items')}
          </Typography>        
        </div>
        <div className={classes.boxBottom}>
          <form className={classes.boxForm}>
            <Grid container spacing={3}>
              <Grid item xs={10} sm={10}>
                <Autocomplete
                  freeSolo
                  disableClearable
                  id="item-name-autocomplete"
                  value={clearSearch && ''}
                  onChange={handleChangeItemName}
                  options={searchOptions}
                  getOptionLabel={getVendorOptionLabel}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="search-name"
                      name="search-name"
                      label={t('orders.add-item-name')}
                      value={typingValue !== '' ? typingValue : ''}
                      onChange={handleChangeTyping}
                      className={classes.fieldClass}
                      InputProps={{ ...params.InputProps, type: 'search' }}
                    />
                  )}
                />
                {(currentItem?.category === 'Single') &&
                  <Fragment>
                    <br />
                    <Typography variant="caption">{t('orders.select-variation')}</Typography>
                    <br/>
                    <FormControl fullWidth>
                      <InputLabel id="reference-select-label">{t('orders.variation')}</InputLabel>
                      <Select
                        labelId="reference-select-label"
                        value={currentCustomReference}
                        label='slecione la variacion'
                        onChange={(e) => handleCustomReferenceChange(e.target.value)}
                      >
                        {currentItem?.warehousesCustomQuantities?.map((customItem, index) =>
                          <MenuItem key={index} value={customItem.id.customReference} >
                            {customItem?.id?.customReference?.replace(/-/g, " | ")}
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Fragment>
                }
              </Grid>
              <Grid item xs={2} sm={2}>
                <TextField
                  id="item-quantity"
                  name="quantity"
                  type="number"
                  value={itemQuantity !== undefined ? itemQuantity : ''}
                  label={t('orders.add-item-quantity')}
                  className={classes.fieldClass}
                  onChange={handleChangeItemQuantity}
                  error={quantityError}
                  helperText={quantityError ? t('orders.quantity-greater-error') : ''}
                  onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                  disabled={(currentItem?.category === 'Single' && !currentCustomReference)}
                />
              </Grid>
            </Grid>
          </form>
          <div className={classes.buttons}>
          <Button onClick={addItemOrder} variant="contained" color="primary" className={classes.buttonsLast} disabled={quantityError || itemQuantity === undefined}>
            {t('tournament.add')}
          </Button>
          </div>
          </div>       
        </div>
  )
}

export default OrderItemSearchModal;