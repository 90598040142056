// React.
import React from 'react';

// Components.
import LogsSearch from '../logs/LogsSearch';
import LogsList from '../logs/LogsList';

// Styles
import useStyles from './LogsStyles';

const Logs = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LogsSearch />
      <LogsList />
    </div>
  )
}

export default Logs;
