// React.
import React, { Fragment, useEffect, useState } from 'react';

// Material UI.
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// Components.
import ImageAvatar from '../utils/ImageAvatar';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getItems } from "../../../actions/itemActions/ItemActions";
import { addBundle } from "../../../actions/bundleActions/BundleActions";
import { getVendors } from "../../../actions/vendorActions/VendorActions";
import { RootStore } from "../../../Store";

// Global Types.
import { BundleType, ItemType } from '../models/ModelTypes';

// Translations.
import { useTranslation } from 'react-i18next';

// Styles.
import useStyles from './AddBundleFormStyles';
import defaultImage from '../../../images/default-img.jpg';
import axios from 'axios';

interface AutoCompleteType {
  name: string;
  itemId: number;
  typeName: string;
  taxPercentage: number;
  itemCode: string;
  imgUrl: string;
}

interface AddedItemType {
  itemId: number;
  name: string;
  typeName: string;
  taxPercentage: number;
  quantity: number;
  imgUrl: string;
}

const AddBundleForm = () => {

  const [t] = useTranslation('global');
  const classes = useStyles();
  const dispatch = useDispatch();

  const defaultBundleValues:BundleType = {
    id: 0,
    bundleCode: '',
    creationDate: '',
    currentTax: 0,
    description: '',
    images: [],
    isDeleted: false,
    isPublished: false,
    items:[],
    name:	'',
    price: 0,
    priceWithTaxes: 0,
    totalQuantity: 0
  };

  // Redux stage.
  const reduxItemState = useSelector((state: RootStore) => state.item);
  const itemsList = reduxItemState.itemList;
  const reduxBundleState = useSelector((state: RootStore) => state.bundle);
  const loading = reduxBundleState.loading;
  const reduxVendorsState = useSelector((state: RootStore) => state.vendors);
  const vendorsList = reduxVendorsState.vendors;

  // Local component state.
  const [bundleState, setBundleState] = useState<BundleType>(defaultBundleValues);
  const [addedItems, setAddedItems] = useState<AddedItemType[]>([]);
  const [currentQty, setCurrentQty] = useState<string>('0');
  const [currentMaxQty, setCurrentMaxQty] = useState<number>(0);
  const [errorArray, setErrorArray] = useState<Array<string>>([]);
  const [open, setOpen] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const [selectedItem, setSelectedItem] = useState<AutoCompleteType|null>(null);
  const [options, setOptions] = useState<AutoCompleteType[]>([]);
  const [currentVendor, setCurrentVendor] = React.useState('');
  const [sellPrice, setSellPrice] = React.useState<string>('0');

  useEffect(() => {
    dispatch(getVendors());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (vendorsList !== undefined) {
      let firstVendor = vendorsList[0] !== undefined ? vendorsList[0].id : 0;
      setCurrentVendor(String(firstVendor));
    }

    // eslint-disable-next-line
  }, [vendorsList]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (currentVendor && currentVendor !== '') {
        const qParams = {name: autocompleteValue, page: 0, size: 15, paged: true, isUniquePerCode: true};
        dispatch(getItems(qParams, 'seller'));
      }
    }, 300)

    return () => clearTimeout(delayDebounceFn);

    // eslint-disable-next-line
  }, [autocompleteValue]);

  useEffect(() => {
    if (itemsList !== undefined) {
      var newItemsList:Array<AutoCompleteType> = [];
      itemsList?.items.forEach((item:ItemType) => {
        if (item?.vendor?.id === Number(currentVendor)) {
          newItemsList.push({ 
            name: item.name,
            typeName: `${item.itemType?.name}`,
            taxPercentage: Number(item.itemType?.taxPercentage),
            itemId: item.id,
            itemCode: `${item.code}`,
            imgUrl: item.images![0] !== undefined ? item.images![0].url : defaultImage 
          });
        }
      });
      setOptions(newItemsList);
    }

    // eslint-disable-next-line
  }, [itemsList]);

  useEffect(() => {
    if (selectedItem?.itemCode !== undefined) {
      getTotalQuantityAllWarehouses(selectedItem?.itemCode);
    }

    // eslint-disable-next-line
  }, [selectedItem, currentVendor]);

  useEffect(() => {
    let currentPrice = String(sellPrice);
    if (currentPrice !== '') {
      updatePriceState(currentPrice);
    }

    // eslint-disable-next-line
  }, [addedItems]);

  // Gets the max quantity.
  const getTotalQuantityAllWarehouses = async (code:string) => {
    const apiURL: string | undefined = process.env.REACT_APP_API_URL;
    const token: string | undefined = localStorage.token;

    const headers: object = {ContentType: 'application/json', Authorization: 'Bearer ' + token}
    const requestPath = `${apiURL}/seller/getAllItems?vendorId=${currentVendor}`;

    const res = await axios.get(requestPath, {
      params: {code: code},
      headers: headers
    });

    if (res.status === 200) {
      const queryData = res.data.data.content;
      var total = 0;

      // Sum all warehouses quantities.
      for(var i = 0; i < queryData.length; i++) {
        const item = queryData[i];

        let warehousesQuantities = item.warehousesQuantities;
        // eslint-disable-next-line
        warehousesQuantities?.forEach((whQuantity:any) => {
          total = total + Number(whQuantity.quantity);
        });
      }
      setCurrentMaxQty(total);
   }
  }

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof bundleState;
    var currentValue = event.target.value;

    // Filter incorrect numbers
    if (name === 'price') {
      const re = /^[0-9\b]+$/;
      const newValue = String(currentValue);
      if (!re.test(newValue)) {
        currentValue = '';
      }
    }
  
    setBundleState({
      ...bundleState,
      [name]: currentValue
    });

    // Validate and add errors.
    const errors = [...errorArray];
    const nameString = '' + [name]
    if (currentValue === '') {
      errors.push(nameString);
      setErrorArray(errors);
    } else {
      const newErrors = errors.filter(function(value) { 
        return value !== nameString;
      });
      setErrorArray(newErrors);
    }
  };

  const changeSellPrice = (event: React.ChangeEvent<{value: unknown }>) => {
    setSellPrice(String(event.target.value));
    updatePriceState(String(event.target.value));
  };

  const updatePriceState = (value:string) => {
    setBundleState({
      ...bundleState,
      price: postProcessPrice(Number(value))
    });

    // Update errors list.
    const errors = [...errorArray];
    const newErrors = errors.filter(function(value) { 
      return value !== 'price';
    });
    setErrorArray(newErrors);
  };

  const postProcessPrice = (price:number) => {
    var tax = 0;
    if (addedItems.length > 0) {
      addedItems.forEach((addItem) => {
        if (addItem.taxPercentage > tax) {
          tax = addItem.taxPercentage;
        }
      });
    }
    return Number(((price * 100) / (100 + tax)).toFixed(2));
  };


  const handleUploadClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesList = event.target.files as File[] | null;
    const imgs = bundleState.images;
    const files = bundleState.files ? bundleState.files : [];
    for (var i = 0; i < filesList!.length; i++) {
      let tempURL = URL.createObjectURL(filesList![i]);
      const uniqueId = (new Date().getTime()) + i;
      const newImg = {
        id: uniqueId,
        url: tempURL,
        key: tempURL
      };
      imgs[0] = newImg;
      files[0] = {fid: uniqueId, file: filesList![i]};
    }
    setBundleState({
      ...bundleState,
      images: imgs,
      files: files
    });

    // Remove posible errors.
    const errors = [...errorArray];
    const newErrors = errors.filter(function(value) { 
      return value !== 'image';
    });
    setErrorArray(newErrors);
  };

  const handleDeleteImg = (id: number) => {
    const newImages = bundleState.images!.filter((image) => image.id !== id)
    const newFiles = bundleState.files!.filter((file) => file.fid !== id)
    if (newImages !== undefined) {
      setBundleState({
        ...bundleState,
        images: newImages,
        files: newFiles
      });
    }
  };

  const autocompleteChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAutocompleteValue(String(event.target.value));
  };

  const currentItemQtyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let newValue = String(event.target.value);
    if (Number(newValue) > 0) {
      const re = /^[0-9\b]+$/;
      if (re.test(newValue) || newValue === '') {
        setCurrentQty(String(Number(newValue)));
      } else {
        setCurrentQty('0');
      }
    }
  };

  const validateFields = () => {
    var result = true;
    const errors = [...errorArray];
    if (bundleState.name === '') {
      errors.push('name');
      result = false;
    }
    if (bundleState.price < 1) {
      errors.push('price');
      result = false;
    }
    if (bundleState.description === '') {
      errors.push('description');
      result = false;
    }

    if (addedItems.length < 1) {
      errors.push('items');
      result = false;
    }

    if (bundleState.files === undefined) {
      errors.push('image');
      result = false;
    } else {
      if (bundleState.files!.length < 1) {
        errors.push('image');
        result = false;
      }
    }

    setErrorArray(errors);

    return result;
  };

  const handleChangeValidation = (inputName: string) => {
    var result = false;
    errorArray.forEach((key) => {
      if (key === inputName) {
        result = true;
      }
    });

    return result;
  };
  const addItemToList = () => {
    const currentItems = [...addedItems];
    if (selectedItem !== undefined && selectedItem !== null) {
      currentItems.push({
        name: selectedItem!.name, 
        itemId: selectedItem!.itemId, 
        typeName: selectedItem!.typeName, 
        taxPercentage: selectedItem!.taxPercentage,
        quantity: Number(currentQty),
        imgUrl: selectedItem!.imgUrl
      });

      // Remove posible errors.
      const errors = [...errorArray];
      const newErrors = errors.filter(function(value) { 
        return value !== 'items';
      });
      setErrorArray(newErrors);
    }
    setAddedItems(currentItems);
    setAutocompleteValue('');
    setSelectedItem(null);
    setCurrentQty('0');
    setCurrentMaxQty(0);
  };

  const saveOnClick = () => {
    // Save the bundle on the BE.
    const validation = validateFields();
    if (errorArray.length === 0 && validation) {
      // Send the first vendor's id.
      let vendorId = vendorsList[0] !== undefined ? vendorsList[0].id : 0;
      let requestObject = {
        name: bundleState.name,
        description: bundleState.description,
        files: bundleState.files !== undefined ? bundleState.files : [],
        bundleItems: [...addedItems],
        price: bundleState.price,
        vendorId: vendorId
      };
      dispatch(addBundle(requestObject));
    }
  };

  // Clear fields when success.
  useEffect(() => {
    if (reduxBundleState.actionStatus !== undefined) {
      if (reduxBundleState.actionStatus.status === 'success') {
        handleClear();
      }
    }

    // eslint-disable-next-line
  }, [reduxBundleState.actionStatus]);

  const handleClear = () => {
    setBundleState(defaultBundleValues);
    setAddedItems([]);
    setSellPrice('0');
  };

  return (
    <form noValidate autoComplete="off" className="add-form">
      <CssBaseline /> 
      <div className="left-side">
        <TextField 
          id="bundle-name" label={ t('bundles.bundle-name') } 
          name="name" 
          color="primary" 
          value={ bundleState.name }
          fullWidth 
          autoFocus
          onChange={ handleChange } 
          error={ handleChangeValidation('name') }
          helperText={handleChangeValidation('name') ? t('bundles.field-required'): ''}
        />
        <TextField 
          id="bundle-description" 
          multiline
          maxRows={8}
          name="description"
          label={ t('bundles.bundle-description') }  
          value={ bundleState.description }
          color="primary" 
          fullWidth
          onChange={ handleChange } 
          error={ handleChangeValidation('description') }
          helperText={handleChangeValidation('description') ? t('bundles.field-required'): ''}
        />
        <TextField 
          id="bundle-sellprice"
          name="sellprice"
          label={ t('items.sell-price') }  
          value={ sellPrice }
          type="number"
          color="primary" 
          fullWidth
          onChange={ changeSellPrice } 
          error={ handleChangeValidation('price') }
          helperText={handleChangeValidation('price') ? t('users.field-required'): ''}
          onKeyDown={ (evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault() }
        />
        <TextField 
          id="bundle-price"
          name="price" 
          label={ t('bundles.bundle-price') }  
          value={ bundleState.price }
          onChange={ handleChange }
          disabled
          type="number"
          color="primary" 
          fullWidth
        />
        <div className={ classes.addedItemsContainer }>
          <Typography>{ t('bundles.select-items-label') } ({addedItems.length} { t('bundles.selected') })</Typography>
          {handleChangeValidation('items') &&
            <Typography color="error">{ t('bundles.items-error') }</Typography>
          }
          <div>
            {addedItems.map((element:AddedItemType, index:number) => 
              <Paper key={index} elevation={1}>
                <ImageAvatar src={ element.imgUrl } alt="Item main image." />
                {element.name} <br/> 
                ({ t('bundles.quantity') }: {element.quantity}) ({ t('items.category') } {element.typeName})
              </Paper>
            )}
          </div>
          <div className={ classes.addItemWrapper }>
            <Autocomplete
              id="asynchronous-items" open={open}
              className={ classes.autocomplete }
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              value={ selectedItem }
              onChange={(event: any, newValue: AutoCompleteType | null) => {
                setSelectedItem(newValue);
              }}
              disabled={(!currentVendor || currentVendor === '')}
              getOptionSelected={(option, value) => option.itemId === value.itemId}
              getOptionLabel={(option) => option.name} options={options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={ t('bundles.select-item') }
                  value={ autocompleteValue }
                  onChange={ autocompleteChange }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            <TextField 
              id="itemQty"
              className={ classes.currentQty }
              name="itemQty" 
              value={currentQty}
              label={ `${t('bundles.quantity')} ${selectedItem ? '(Stock: ' + currentMaxQty + ')' : ''}`}
              onChange={ currentItemQtyChange }
              error={ handleChangeValidation('itemQty') }
              helperText={handleChangeValidation('itemQty') ? t('users.field-required'): ''}
              onKeyDown={ (evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault() }
              color="primary"
              disabled={(!currentVendor || currentVendor === '')}
              fullWidth
            />
            <Button 
              className={ classes.currentBtn }
              variant="contained"
              color="primary"
              size="small"
              onClick={ addItemToList }
              disabled={ Number(currentQty) < 1 || currentQty === '' || selectedItem === undefined || selectedItem === null }>
              { t('bundles.add-item') }
            </Button>
          </div>
        </div>
      </div>
      <div className="right-side">
        <div className={ classes.imgWrapper }>
            <div>
              {bundleState.images![0] && 
                <DeleteIcon color="secondary" className={ classes.deleteIcon } onClick={() => handleDeleteImg(bundleState.images![0].id) }/>
              }
              <Avatar variant="rounded" alt="Item image" src={ bundleState.images![0] ? bundleState.images![0].url : defaultImage } className={ classes.mainImg }/>
            </div>
            <div className={ classes.editImgWrapper }>
                <input accept="image/*" id="icon-button-file" type="file" onChange={ handleUploadClick }/>
                <label htmlFor="icon-button-file">
                  <Button variant="contained" color="primary" component="span">
                    { t('items.add-image') }
                  </Button>
                </label>
                {handleChangeValidation('image') &&
                  <Fragment>
                    <br/>
                    <Typography color="error">{ t('bundles.image-error') }</Typography>
                  </Fragment>
                }
            </div>
        </div>
      </div>
      <div className="form-controls">
        { (loading === 'ADD_BUNDLE') && <CircularProgress /> }
        <Button variant="outlined" color="secondary" onClick={ handleClear } disabled={loading !== ''}> { t('items.clear') } </Button>
        <Button variant="contained" color="primary" onClick={ saveOnClick } disabled={ errorArray.length !== 0 || loading !== '' }> { t('items.save') } </Button>
      </div>
    </form>
  )
}

export default AddBundleForm;
