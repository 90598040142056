import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(6),
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    },
  }),
);

export default useStyles;