// Material UI
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notesTitle: {
      marginBottom: theme.spacing(1)
    },
    notesList: {
      marginBottom: theme.spacing(1),
      maxHeight: '320px',
      overflowY: 'auto',
      [theme.breakpoints.down('xs')]: {
        maxHeight: '200px',
      },
    },
    noteRow: {
      padding: theme.spacing(1),
      background: theme.palette.grey[200],
      borderLeft: `3px solid ${theme.palette.grey[400]}`,
      borderRadius: '4px',
      marginRight: theme.spacing(4),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
      '& span': {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
          flexFlow: 'column',
        },
      }
    },
    notesForm: {
      '& .MuiTextField-root': {
        width: 'calc(100% - 32px)',
        marginRight: theme.spacing(4),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
          marginRight: 0,
        },
      },
      '& .MuiButtonBase-root': {
        marginLeft: '0 !important'
      }
    }
  }),
);

export default useStyles;
