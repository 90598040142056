// React,
import React, {Fragment, useEffect, useState} from 'react';
import DocumentMeta from 'react-document-meta';

// Material UI.
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

// Redux
import { useSelector, useDispatch } from "react-redux";
import {RootStore} from "../../../Store";
import { deleteItemFromCart, deleteBundleFromCart, deleteItemToCartLocal } from "../../../actions/cartActions/CartActions";
import { getDolarPrice } from "../../../actions/utilityActions/UtilityActions";
import { getUserInformation } from "../../../actions/authActions/AuthActions";

// Components.
import ItemCart from '../items/ItemCart';
import ItemSingleCart from '../items/ItemSingleCart';
import BundleCart from '../bundles/BundleCart';
import NumberFormat from '../../admin/utils/NumberFormat';

// Styles.
import useStyles from './CartStyles';

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

// Translations.
import { useTranslation } from 'react-i18next';


const Cart = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');
  const dispatch = useDispatch();
  const authToken: string | undefined = localStorage.token;

  // Page metatags info.
  const meta = getProjectConfig().METATAGS.CART;
  const anonymousCart = localStorage.anonymousCart !== undefined ? JSON.parse(localStorage.anonymousCart) : [];
  const anonymousBundleCart = localStorage.anonymousBundleCart !== undefined ? JSON.parse(localStorage.anonymousBundleCart) : [];

  // Redux state.
  const reduxState = useSelector((state: RootStore) => state.auth);
  const loggedUser = reduxState.loggedUser;
  const cartState = useSelector((state: RootStore) => state.cart);
  const utilityState = useSelector((state: RootStore) => state.utility);

  // Local state.
  const [cart, setCart] = useState<Array<any>>([]);
  const [bundleCart, setBundleCart] = useState<Array<any>>([]);
  const [total, setTotal] = useState<number>(0);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (utilityState.dolarPrice === undefined) {
      dispatch(getDolarPrice());
    }

    if (authToken !== undefined && authToken !== '') {
      // Get users information with the cart data.
      dispatch(getUserInformation());
    }

    // eslint-disable-next-line
  }, [cartState.actionStatus]);

  useEffect(() => {
    if (loggedUser !== undefined) {
      // Get user cart items.
      setCart(loggedUser.cart);
      setBundleCart(loggedUser.bundleCart);
      setTotal(calculateTotal(loggedUser));
      setSubTotal(calculateSubTotal(loggedUser));
    } else {
      if (localStorage.anonymousCart !== undefined || localStorage.anonymousBundleCart !== undefined) {
        setCart(anonymousCart);
        setBundleCart(anonymousBundleCart);
        setTotal(calculateTotal(null, true));
        setSubTotal(calculateSubTotal(null, true));
      } else {
        setCart([]);
        setBundleCart([]);
      }
    }
  // eslint-disable-next-line
  }, [loggedUser, cartState])

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  const getMaxDiscountQty = (cartItem:any) => {
    if (cartItem.item.itemQuantityData !== undefined) {
      let discountRow = cartItem.item.itemQuantityData.filter((vendorRow: any) => vendorRow.typeOfWarehouse === 'Discount');
      if (discountRow.length > 0) return discountRow[0].quantity;
    }
    const dis = cartItem.item.warehousesQuantities.find((wh:any) => wh.warehouse.isDiscount === true);
    return dis !== undefined ? Number(dis.quantity) : 0;
  };

  const getItemDiscountQty = (cartItem:any) => {
    const maxDis = getMaxDiscountQty(cartItem);
    if (cartItem.quantity < Number(maxDis)) {
      return cartItem.quantity;
    }
    return maxDis;
  };

  const getItemNormalQty = (cartItem:any) => {
    const maxDis = getMaxDiscountQty(cartItem);
    if (cartItem.quantity > Number(maxDis)) {
      return cartItem.quantity -  Number(maxDis);
    }
    return 0;
  };

  const isItemWithDiscount = (cartItem:any, isAnonimous:boolean = false) => {
    if (cartItem.item.discount !== null && cartItem.item.discount !== undefined  && !isAnonimous) {
      const discountQty = getMaxDiscountQty(cartItem);
      if (discountQty > 0 && cartItem.item.discount.pricePercentage > 0) {
        return true;
      }
    }
    if (cartItem.item.discounts !== null && cartItem.item.discounts !== undefined && isAnonimous) {
      const discountQty = getMaxDiscountQty(cartItem);
      if (discountQty > 0 && cartItem.item.discounts.pricePercentage > 0) {
        return true;
      }
    }

    if (cartItem.item.itemQuantityData !== undefined) {
      let discountRow = cartItem.item.itemQuantityData.filter((vendorRow: any) => vendorRow.typeOfWarehouse === 'Discount');
      if (discountRow.length > 0) return true;
    }

    return false;
  };

  const calculateSubTotal = (user:any = null, isAnonimous:boolean = false) => {
    var total = calculateTotal(user, isAnonimous);
    return total;
  };

  const calculateTotal = (user:any = null, isAnonimous:boolean = false) => {
    const cart = (user !== null && user !== undefined) ? user.cart : anonymousCart;
    const bundleCart = (user !== null && user !== undefined) ? user.bundleCart : anonymousBundleCart;

    var total = 0;
    cart.forEach((cartItem:any) => {
      if (cartItem.item.category === 'Single' || cartItem.customReference) {
        let customReference = (user !== null && user !== undefined) ? cartItem.id.customReference : cartItem.customReference;
        total = Number((total + (getSinglePriceInfo(cartItem, customReference, isAnonimous) * cartItem.quantity)).toFixed(2));
      } else {
        if (isItemWithDiscount(cartItem, isAnonimous)) {
          const disQty = getItemDiscountQty(cartItem);
          const normalQty = getItemNormalQty(cartItem);
          total = Number((total + (getPriceWithTaxesInfo(cartItem.item, true) * disQty)).toFixed(2))
          total = Number((total + (getPriceWithTaxesInfo(cartItem.item) * normalQty)).toFixed(2))
        } else {
          total = Number((total + (getPriceWithTaxesInfo(cartItem.item) * cartItem.quantity)).toFixed(2))
        }
      }
    });
    bundleCart.forEach((cartItem:any) => {
      total = Number((total + (getPriceWithTaxesInfo(cartItem.bundle) * cartItem.quantity)).toFixed(2))
    });
    return total;
  };

  const getSinglePriceInfo = (cartItem: any, customReference: string, isAnonimous:boolean = false) => {
    if (!isAnonimous || cartItem.isFull) {
      var price = 0;
      const priceObj = cartItem.item.customPrices?.filter((customPrice: any) => customPrice.customReference === customReference);
      if (priceObj?.length! > 0) price = priceObj![0]?.price;
      return price;
    }

    // If anonimous.
    return cartItem.item.itemQuantityData.finalPrice;
  };

  const getPriceWithTaxesInfo = (item:any, hasDiscount:boolean = false) => {
    // For new data structure.
    if (item.itemQuantityData !== undefined) {
      let itemQuantityData = item.itemQuantityData;
      if (hasDiscount) {
        let discountRow = itemQuantityData.filter((vendorRow: any) => vendorRow.typeOfWarehouse === 'Discount');
        if (discountRow.length > 0) return discountRow[0].discountFinalPrice;
        return 0;
      } else {
        return itemQuantityData[0].finalPrice;
      }
    } else {
      if (hasDiscount) {
        const discount = item.discounts !== undefined ? item.discounts.pricePercentage : item.discount.pricePercentage;
        const savings = Number(((discount / 100) * item.priceWithTaxes).toFixed(2));
        const newPrice = (item.priceWithTaxes - savings);
        return newPrice;
      } else {
        return item.priceWithTaxes;
      }
    }
  };

  const getTotalQuantity = () => {
    var total = 0;
    cart.forEach((cartItem:any) => 
      total += cartItem.quantity
    );
    bundleCart.forEach((cartItem:any) => 
      total += cartItem.quantity
    );
    return total;
  };

  const clearCart = () => {
    if (localStorage.token) {
      // If user is logged.
      loggedUser.cart.forEach((cartItem:any) => {
        dispatch(deleteItemFromCart(cartItem.item.id, cartItem.quantity, cartItem.id.customReference));
      });
      loggedUser.bundleCart.forEach((cartItem:any) => {
        dispatch(deleteBundleFromCart(cartItem.bundle.id, cartItem.quantity));
      });
    } else {
      // If user is anonimous.
      localStorage.removeItem('anonymousCart');
      localStorage.removeItem('anonymousBundleCart');
      dispatch(deleteItemToCartLocal());
    }
    handleCloseModal();
  };

  return (
    <DocumentMeta {...meta}>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.cartWrapper}>
        <Grid container spacing={3} className={`${classes.cartWrapper} ${(cart.length === 0 && bundleCart.length === 0) ? 'no-items' : ''}`}>
          <Grid item xs={12} sm={9}>
            <Paper elevation={3} square={false} variant="elevation" className={classes.paperWrapper}>
              <Typography gutterBottom variant="h4" component="h1" color="primary">
                {t('cart.shopping-cart')}
              </Typography>
              {(loggedUser !== undefined && loggedUser.cart.length === 0 && loggedUser.bundleCart.length === 0) && <Typography>{t('cart.shopping-cart-empty')}</Typography>}
              <div>
                {
                  bundleCart.length > 0 &&
                  (bundleCart.map((item: any, index: number) =>
                    <BundleCart key={index} cartBundle={item} />
                  ))
                }
              </div>
              <div>
                {
                  cart.length > 0 &&
                  (cart.map((cartItem: any) =>
                    <Fragment>
                      {(cartItem.item.category === 'Single' || cartItem.customReference) ?
                        <ItemSingleCart
                          key={cartItem.item.id}
                          cartItem={cartItem}
                          customReference={(loggedUser && loggedUser !== undefined) ? cartItem.id.customReference : cartItem.customReference}
                        /> :
                        <ItemCart
                          key={cartItem.item.id}
                          cartItem={cartItem}
                          customReference={(loggedUser && loggedUser !== undefined) ? cartItem.id.customReference : cartItem.customReference}
                        />
                      }
                    </Fragment>
                  ))
                }
              </div>
              <div className={classes.bottomWrapper}>
                {
                  (cart.length > 0 || bundleCart.length > 0) &&
                  <Fragment>
                    <Button variant="outlined" color="secondary" className={classes.btnClear} onClick={() => handleOpenModal()} startIcon={<DeleteIcon />}>{t('cart.clear-cart')}</Button>
                  </Fragment>
                }
              </div>
            </Paper>
          </Grid>
          {(cart.length > 0 || bundleCart.length > 0) &&
            <Grid item xs={12} sm={3}>
              <Paper elevation={3} square={false} variant="elevation" className={classes.stickyContainer}>
                <div className={classes.leftSidebar}>
                  <Fragment>
                    <Grid item xs={6} sm={12}>
                      <Button variant="contained" color="secondary" href="/checkout-process">{t('cart.proceed-to-checkout')}</Button>
                    </Grid>
                    <Grid item xs={6} sm={12}>
                      <Typography>({getTotalQuantity()} {t('cart.items')})</Typography>
                      <Typography>Subtotal: {getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={subTotal} />}</Typography>
                      <Typography variant="h5" component="h3">Total: {getProjectConfig().CURRENCY_SYMBOL}{<NumberFormat number={total} />}</Typography>
                    </Grid>
                  </Fragment>
                </div>
              </Paper>
            </Grid>
          }
        </Grid>
      </Container>
      <Modal
        open={ openModal }
        onClose={ handleCloseModal }
        aria-labelledby="Confirm Modal"
        >
        <Paper elevation={3} className={ classes.modalPaper }>
          <IconButton className={ classes.closeModal } aria-label="close modal" component="span" onClick={ () => handleCloseModal() }>
            <CloseIcon/>
          </IconButton>
          <Typography>{ t('cart.clear-cart-msg') }</Typography>
          <Button variant="contained" color="primary" onClick={ () => handleCloseModal() }> { t('cart.no') } </Button>
          <Button variant="contained" color="secondary" onClick={ () => clearCart() }> { t('cart.yes') } </Button>
        </Paper>
      </Modal>
    </DocumentMeta>
  )
}

export default Cart;
