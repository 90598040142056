// React.
import React, { Fragment } from 'react';

// Props interface.
interface NumberFormatProps {
  number: number;
  decimal?: boolean;
};

const NumberFormat = ({ number, decimal = false}: NumberFormatProps) => {
  var roundedNumber = Math.round(number);
  if (decimal) {
    let twoDecimals = (Math.round(number * 100) / 100);
    roundedNumber = (Math.ceil(twoDecimals / 0.05) * 0.05);
  }
  return (
    <Fragment>
      {decimal ? roundedNumber.toFixed(2) : roundedNumber.toLocaleString('en-US') }
    </Fragment>
  )
}

export default NumberFormat;